import { AxiosError, AxiosResponse } from "axios";
import { destroyCookie } from "nookies";
import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import routes, { RoutesDfs } from "src/assets/routes";
import useApiError from "src/hooks/apis/useApiError";
import { ROLE, UserRole } from "src/types";
import { Authority, AuthorityArea, RWD } from "src/types/authority";
import API from "src/utils/api";
import { createAuthority, initAuthority, initAuthorityRWD } from "src/utils/auth-helper";

interface Params {
  userId: "me" | number;
}

interface Response {
  code: 200 | 401 | 404;
  data?: { authority: Authority; role: UserRole };
  text: "ok" | "unauthorized" | "not-found";
}

/*
  계정별 역할 및 권한 조회
*/
const useGetUserAuthority = ({ userId }: Params) => {
  const { handleError } = useApiError({
    400: (_error: AxiosError) => null,
    404: (_error: AxiosError) => {
      destroyCookie(null, "access_token", { path: "/" });
      destroyCookie(null, "expires", { path: "/" });
      destroyCookie(null, "viewerCompany", { path: "/" });
      localStorage.clear();
    },
  });
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["users/useGetUserAuthority", userId],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/users/${userId}/authority`);
      return response.data.data;
    },
    { enabled: !!userId, onError: handleError }
  );

  const authorityRWD = useMemo(
    () => (data ? createAuthority(data.authority) : initAuthorityRWD),
    [data]
  );

  const isAccessible = useCallback(
    (menu: string, accessAuthority: AuthorityArea[]) => {
      if (accessAuthority.length === 0) return true;

      return accessAuthority.some((auth) => {
        // 메인 권한만 있는 경우 ex) operation
        if (authorityRWD[menu]["read"]) {
          return !!(authorityRWD[menu] as RWD)["read"];
        } else {
          // 서브 권한 있는 경우
          if ((authorityRWD[menu] as Record<string, RWD>)[auth])
            return !!(authorityRWD[menu] as Record<string, RWD>)[auth]["read"];
          return false;
        }
      });
    },
    [authorityRWD]
  );

  const initialRoute = useMemo(() => {
    let redirect;
    const notRootAndNotLoginRoutes = RoutesDfs(routes).filter(
      (v) => !v.subMenu && !v.href.includes("login")
    );
    // .sort((a, b) => (a.href < b.href ? -1 : 1));

    if (data && data.authority) {
      redirect = notRootAndNotLoginRoutes.find((v) => {
        return isAccessible(v.href.split("/")[1] || "home", v.authority);
      })?.href;
    }
    return redirect || "/";
  }, [data, isAccessible]);

  const result = useMemo(() => {
    if (data) return { authority: data.authority, authorityRWD: authorityRWD, role: data.role };

    return {
      authority: initAuthority,
      authorityRWD: initAuthorityRWD,
      role: ROLE.UNREGISTERED,
    };
  }, [authorityRWD, data]);

  return { data: result, isAccessible, initialRoute, ...rest };
};

export default useGetUserAuthority;
