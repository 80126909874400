import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  paymentId: number;
}

interface Response {
  code: 200 | 400 | 401 | 500;
  data?: {
    payment_id: number;
    tax_bill_date: number;
    tax_bill_user_name: string;
  };
  text: "ok" | "bad-request" | "unauthorized" | "internal-server-error";
}

// 세금계산서 발행확인
// 발행확인은 트리를 펼치기 전 합산데이터에서 처리
const usePutConfirm = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const { data, ...rest } = useMutation<Response, AxiosError, Params>(
    async ({ paymentId }) => {
      const response: AxiosResponse<Response> = await API.default.put(
        `/payments/${paymentId}/confirm`
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("발행 완료 처리되었습니다.");
        invalidate([
          "payments/useGetCriteria",
          "payments/useGetPaymentDetail",
          "payments/useGetPayments",
        ]);
      },
      onError: handleError,
    }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            payment_id: 0,
            tax_bill_date: 0,
            tax_bill_user_name: "",
          },
    [data]
  );

  return { data: result, ...rest };
};

export default usePutConfirm;
