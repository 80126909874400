import { array, mixed, number, object, string } from "yup";
import moment from "moment";

import {
  DATA_TOOLS_REPORT_TYPE,
  DataToolsReportType,
  PROCEDURE,
  PROCEDURE_RANGE,
  PROCEDURE_TIME_RANGE,
  Procedure,
  ProcedureRange,
  ProcedureTimeRange,
  SEARCH_DATE,
  SearchDate,
} from "src/types/datatools";

type DataToolsReportFormValues = {
  reportType: DataToolsReportType;
  range: ProcedureRange;
  since: moment.Moment;
  until: moment.Moment;

  // CREATIVE 리포트
  adid?: string;
  mediaKey?: string;
  placementId?: string;

  // 입찰 보고서(기간 선택)
  thirdpartyIds?: number[];

  // NPAY 리포트
  limit?: 300 | 1000 | -1;

  // 나머지 리포트
  timely?: ProcedureTimeRange;
  procedure?: Procedure;
  dateQuickMenu?: SearchDate;
};

export const defaultFormValuesByReportType: Record<DataToolsReportType, DataToolsReportFormValues> =
  {
    [DATA_TOOLS_REPORT_TYPE.APP]: {
      reportType: DATA_TOOLS_REPORT_TYPE.APP,
      procedure: PROCEDURE.APP_PLACEMENT,

      range: PROCEDURE_RANGE.DAILY,
      since: moment().subtract(1, "d"),
      until: moment().subtract(1, "d"),
      dateQuickMenu: SEARCH_DATE.YESTERDAY,
    },
    [DATA_TOOLS_REPORT_TYPE.WEB]: {
      reportType: DATA_TOOLS_REPORT_TYPE.WEB,
      procedure: PROCEDURE.WEB_PLACEMENT,

      range: PROCEDURE_RANGE.DAILY,
      since: moment().subtract(1, "d"),
      until: moment().subtract(1, "d"),
      dateQuickMenu: SEARCH_DATE.YESTERDAY,
    },
    [DATA_TOOLS_REPORT_TYPE.COUPANG]: {
      reportType: DATA_TOOLS_REPORT_TYPE.COUPANG,
      procedure: PROCEDURE.COUPANG_POSTBACK_DAILY,

      range: PROCEDURE_RANGE.DAILY,
      since: moment().subtract(1, "d"),
      until: moment().subtract(1, "d"),
      dateQuickMenu: SEARCH_DATE.YESTERDAY,
    },
    [DATA_TOOLS_REPORT_TYPE.BIDDING]: {
      reportType: DATA_TOOLS_REPORT_TYPE.BIDDING,
      procedure: PROCEDURE.APP_DSP_BIDDING_REPORT_DAILY,

      range: PROCEDURE_RANGE.DAILY,
      since: moment().subtract(1, "d"),
      until: moment().subtract(1, "d"),
    },
    [DATA_TOOLS_REPORT_TYPE.CREATIVE]: {
      reportType: DATA_TOOLS_REPORT_TYPE.CREATIVE,
      range: PROCEDURE_RANGE.DAILY,
      since: moment(),
      until: moment(),
      adid: "",
      mediaKey: "",
      placementId: "",
    },
    [DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING]: {
      reportType: DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING,
      procedure: PROCEDURE.APP_DSP_BIDDING_REPORT_DAILY,
      range: PROCEDURE_RANGE.DAILY,
      since: moment().subtract(1, "d"),
      until: moment().subtract(1, "d"),
      thirdpartyIds: [],
    },
    [DATA_TOOLS_REPORT_TYPE.NPAY]: {
      reportType: DATA_TOOLS_REPORT_TYPE.NPAY,
      procedure: PROCEDURE.NPAY_USN,
      range: PROCEDURE_RANGE.DAILY,
      since: moment().subtract(1, "d"),
      until: moment().subtract(1, "d"),
      limit: 300, // 300 | 1000
    },
  };

const adidRegex =
  /^([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})|([0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12})$/;

export const dataToolsReportFormValidationSchema = object({
  reportType: number()
    .required()
    .oneOf([
      DATA_TOOLS_REPORT_TYPE.APP,
      DATA_TOOLS_REPORT_TYPE.WEB,
      DATA_TOOLS_REPORT_TYPE.COUPANG,
      DATA_TOOLS_REPORT_TYPE.BIDDING,
      DATA_TOOLS_REPORT_TYPE.CREATIVE,
      DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING,
      DATA_TOOLS_REPORT_TYPE.NPAY,
    ]),
  range: number().required().oneOf([PROCEDURE_RANGE.DAILY, PROCEDURE_RANGE.HOURLY]),
  timely: number().when(["reportType", "range"], {
    is: (reportType: DataToolsReportType, range: ProcedureRange) =>
      reportType === DATA_TOOLS_REPORT_TYPE.BIDDING && range === PROCEDURE_RANGE.HOURLY,
    then: number().required().oneOf([PROCEDURE_TIME_RANGE.AM, PROCEDURE_TIME_RANGE.PM]),
    otherwise: number().nullable(),
  }),
  procedure: number().when("reportType", {
    is: (reportType: DataToolsReportType) =>
      [DATA_TOOLS_REPORT_TYPE.CREATIVE, DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING].includes(
        reportType
      ),
    then: number().nullable(),
    otherwise: number().required(),
  }),
  dateQuickMenu: number().when("reportType", {
    is: (reportType: DataToolsReportType) =>
      [
        DATA_TOOLS_REPORT_TYPE.BIDDING,
        DATA_TOOLS_REPORT_TYPE.CREATIVE,
        DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING,
        DATA_TOOLS_REPORT_TYPE.NPAY,
      ].includes(reportType),
    then: number().nullable(),
    otherwise: number().required(),
  }),
  since: mixed()
    .required()
    .test(
      "isValidSinceDate",
      "전체 DSP 조회 시에는 최대 8일까지 선택 가능합니다.",
      (value: moment.Moment, context) => {
        const { until, reportType, thirdpartyIds = [] } = context.parent;

        if (reportType === DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING && thirdpartyIds.length === 0) {
          return until.diff(value, "d") <= 7;
        }

        return true;
      }
    )
    .test("isValidSinceDate", "최대 7일까지 선택 가능합니다.", (value: moment.Moment, context) => {
      const { until, reportType } = context.parent;

      if (reportType === DATA_TOOLS_REPORT_TYPE.NPAY) {
        return until.diff(value, "d") <= 6;
      }

      return true;
    }),
  until: mixed()
    .required()
    .test(
      "isValidUntilDate",
      "전체 DSP 조회 시에는 최대 8일까지 선택 가능합니다.",
      (value: moment.Moment, context) => {
        const { since, reportType, thirdpartyIds = [] } = context.parent;

        if (reportType === DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING && thirdpartyIds.length === 0) {
          return since.diff(value, "d") <= 7;
        }

        return true;
      }
    )
    .test("isValidUntilDate", "최대 7일까지 선택 가능합니다.", (value: moment.Moment, context) => {
      const { since, reportType } = context.parent;

      if (reportType === DATA_TOOLS_REPORT_TYPE.NPAY) {
        return since.diff(value, "d") <= 6;
      }

      return true;
    }),
  adid: string().when("reportType", {
    is: DATA_TOOLS_REPORT_TYPE.CREATIVE,
    then: string()
      .required("값을 입력해 주세요.")
      .test("isValidFormat", "ADID/IDFA 형식에 맞게 입력해 주세요.", (value) => {
        return (
          typeof value === "string" &&
          value !== "000000-0000-0000-0000-00000000" &&
          adidRegex.test(value)
        );
      }),
    otherwise: string().nullable(),
  }),
  mediaKey: string().when("reportType", {
    is: DATA_TOOLS_REPORT_TYPE.CREATIVE,
    then: string().required("값을 입력해 주세요."),
    otherwise: string().nullable(),
  }),
  placementId: string().nullable(),
  thirdpartyIds: mixed().when("reportType", {
    is: DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING,
    then: array().of(number()).max(10),
    otherwise: mixed().nullable(),
  }),
  limit: number().when("reportType", {
    is: DATA_TOOLS_REPORT_TYPE.NPAY,
    then: number().required().oneOf([300, 1000, -1]),
    otherwise: number().nullable(),
  }),
});
