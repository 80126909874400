import { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  accountNo: number;
  subId: string;
}

interface Response {
  code: 200 | 400 | 422 | 500;
  text: "ok" | "bad-request" | "field required" | "internal-server-error";
}

// 쿠팡 서브 ID 삭제
// 주의) 자주 콜하면 limit 걸림.
const useDeleteCoupangSubId = ({ accountNo, subId }: Params) => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const {
    data,
    refetch: mutate,
    ...rest
  } = useQuery<Response, AxiosError>(
    ["get/DeleteCoupangSubId", accountNo, subId],
    async () => {
      const response: AxiosResponse<Response> = await API.integration.delete(
        `/operation/management/coupang/subid/DeleteCoupangSubId`,
        { params: { account_no: accountNo, subid: subId } }
      );
      return response.data;
    },
    {
      enabled: false,
      onError: handleError,
      onSuccess: () => {
        toast.success("Sub ID를 삭제하였습니다.");
        invalidate("coupang/useGetCoupangSubIds");
      },
    }
  );

  return { mutate, ...rest };
};

export default useDeleteCoupangSubId;
