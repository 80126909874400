import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Status, WebTag } from "src/types";
import API from "src/utils/api";

interface Params {
  mediaKeys?: string[];
  name?: string;
  status?: Status;
  pageNo?: number;
  pageSize?: number;
  orders?: string[];
}

export interface WebPlacement {
  bidfloor: number;
  bidfloor_currency: string;
  bidfloor_status: Status;
  cpc_bidfloor: number;
  cpc_bidfloor_currency: string;
  cpc_bidfloor_status: Status;
  created_at: number;
  custom_id: string;
  id: string;
  is_web_mediation: boolean;
  media_key: string;
  name: string;
  passback_script: string;
  size_type: number;
  status: Status;
  tag_type: WebTag;
  updated_at: number;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    pages: number;
    placements: WebPlacement[];
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 플레이스먼트 리스트 조회
const useGetWebPlacementList = ({
  mediaKeys,
  name,
  status,
  pageNo = 1,
  pageSize = 100000,
  orders,
}: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["placements/useGetWebPlacementList", mediaKeys, name, pageNo, pageSize, orders],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/placements/web`, {
        params: {
          media_keys: mediaKeys?.join(","),
          name,
          status,
          page_no: pageNo,
          page_size: pageSize,
          orders: orders?.join(","),
        },
      });
      return response.data.data;
    },
    { enabled: !!mediaKeys && mediaKeys.length > 0, onError: handleError }
  );

  const result = useMemo(() => (data ? data : { pages: 0, placements: [] }), [data]);

  return { data: result, ...rest };
};

export default useGetWebPlacementList;
