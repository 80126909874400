import { Box, Grid, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import { useCallback, useState } from "react";
import { BasicTable, DateField } from "src/components/commons";
import useGetAdminTopLevel from "src/hooks/apis/dashboard/useGetAdminTopLevel";
import { DASHBOARD_REPORT, MEDIA } from "src/types";
import { getColumnDefs } from "./PublisherDashboard";
import { publisherStyle } from "./styles";

const PublisherRequestReport = () => {
  const [date, setDate] = useState(moment().subtract(1, "d"));

  const { data, range, total } = useGetAdminTopLevel({
    mediaType: MEDIA.APP,
    reportType: DASHBOARD_REPORT.REQUEST,
    date: date,
  });

  const onChangeDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setDate(value);
    }
    return;
  }, []);

  return (
    <Box css={publisherStyle}>
      <Typography className="title" variant="h5" gutterBottom>
        Request 리포트
      </Typography>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={4}>
          <DateField
            label="날짜"
            value={date}
            onChange={onChangeDate}
            maxDate={moment().subtract(1, "d")}
          />
        </Grid>
      </Grid>
      <BasicTable
        className="ag-theme-balham"
        domLayout="autoHeight"
        columnDefs={getColumnDefs(DASHBOARD_REPORT.REQUEST, range)}
        rowData={data}
        onGridColumnsChanged={(e) => {
          const lastCol = e.columnApi.getAllDisplayedColumns().slice(-3)[0];
          e.api.ensureColumnVisible(lastCol);
          total && e.api.setPinnedTopRowData([total]);
        }}
        getRowStyle={(params) => {
          if (params.node.rowPinned) {
            return { fontWeight: "bold" };
          }
        }}
      />
    </Box>
  );
};

export default PublisherRequestReport;
