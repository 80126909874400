import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { AppPlatform, APP_PLATFORM } from "src/types";
import API from "src/utils/api";

interface Params {
  csNo?: string;
}

interface Response {
  code?: 200 | 400 | 401 | 404 | 422;
  data: {
    cs_info: {
      campaign_name: string;
      trigger_revenue: number;
      partner_no: number;
      partner_name: string;
      subids: string;
      cs_no: number;
      media_key: string;
      campaign_key: string;
      adid: string;
      idfa: string;
      usn: string;
      cs_message: string;
      reply_message: string;
      status: number;
      email: string;
      sdk_version: string;
      platform: AppPlatform;
      regist_datetime: string;
      response_datetime: string;
    };
    participation_info: {
      cps_event_no: number;
      event_datetime: string;
      usn: string;
      adid: string;
      idfa: string;
      sdk_version: string;
      event_name: string;
      price: number;
      quantity: number;
      platform: string;
    }[];
    total_order: number;
    total_cancel: number;
    total: number;
  };
  text?: "ok" | "bad-request" | "unauthorized" | "not-found" | "unprocessable-entity";
}

/*
  쿠팡 CS 상세 조회
*/
const useGetCustomerService = ({ csNo }: Params) => {
  const { handleError } = useApiError();
  const { data: response, ...rest } = useQuery<Response, AxiosError>(
    ["coupang/useGetCustomerService", csNo],
    async () => {
      const response: AxiosResponse<Response> = await API.integration.get(
        `/operation/management/coupang/cs/${csNo}`
      );
      return response.data;
    },
    { enabled: !!csNo, onError: handleError }
  );

  const result = useMemo(() => {
    if (response && response.data)
      return {
        question: {
          ...response.data.cs_info,
          participations: response.data.participation_info,
          total_order: response.data.total_order,
          total_cancel: response.data.total_cancel,
          total: response.data.total,
        },
      };
    return {
      question: {
        campaign_name: "",
        trigger_revenue: 0,
        partner_no: 0,
        partner_name: "",
        subids: "",
        cs_no: 0,
        media_key: "",
        campaign_key: "",
        adid: "",
        idfa: "",
        usn: "",
        cs_message: "",
        reply_message: "",
        status: 0,
        email: "",
        sdk_version: "",
        platform: APP_PLATFORM.ANDROID,
        regist_datetime: "",
        response_datetime: "",
        participations: [
          {
            cps_event_no: 0,
            event_datetime: "",
            usn: "",
            adid: "",
            idfa: "",
            sdk_version: "",
            event_name: "",
            price: 0,
            quantity: 0,
            platform: "",
          },
        ],
        total_order: 0,
        total_cancel: 0,
        total: 0,
      },
    };
  }, [response]);

  return { data: result, ...rest };
};

export default useGetCustomerService;
