export const COMPANY = {
  CORPORATION: 1,
  INDIVIDUAL: 2,
  SYNDICATION: 3,
  SYNDICATION_CLIENT_CORPORATION: 4,
  SYNDICATION_CLIENT_INDIVIDUAL: 5,
  ALL: 10,
  PUBLIC_ALL: 11,
} as const;

export type Company = Union<typeof COMPANY>;

export const COMPANY_ALIAS = {
  [COMPANY.CORPORATION]: "기업",
  [COMPANY.INDIVIDUAL]: "개인",
  [COMPANY.SYNDICATION]: "신디케이션",
  [COMPANY.SYNDICATION_CLIENT_CORPORATION]: "신디케이션 고객사(기업)",
  [COMPANY.SYNDICATION_CLIENT_INDIVIDUAL]: "신디케이션 고객사(개인)",
  [COMPANY.ALL]: "전체",
  [COMPANY.PUBLIC_ALL]: "전체",
};
