export enum JobStatus {
  시작 = 1,
  "진행 중" = 2,
  완료 = 3,
  실패 = 99,
}

export type DataToolsHistory = {
  report_id: number;
  user_name: string;
  report_name: string;
  file_url: string;
  job_status: JobStatus;
  regist_datetime: string;
};
