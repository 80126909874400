import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useUser from "src/hooks/useUser";
import API from "src/utils/api";

export interface History {
  history_id: number;
  user_id: string;
  updated_at: number;
  type: "campaign" | "bidfloor" | "dsp" | "mediation" | "headerbidding";
}

interface Params {
  placementId: string;
  type?: "campaign" | "bidfloor" | "dsp" | "mediation" | "headerbidding";
  pageNo?: number;
  pageSize?: number;
}

interface Response {
  code: 200 | 400 | 401 | 404;
  histories?: History[];
  pages?: number;
  text: "ok" | "bad-request" | "unauthorized" | "not-found";
}

// 플레이스먼트별 히스토리 내역 조회
// type를 전달하지 않을 경우 전체 조회
const useGetHistory = ({ placementId, type, pageNo = 1, pageSize = 10 }: Params) => {
  const { handleError } = useApiError();
  const { isAdmin } = useUser();
  const { data, ...rest } = useQuery<Response, AxiosError>(
    ["histories/useGetHistory", placementId, pageNo, pageSize],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/histories/${placementId}`, {
        params: {
          type: type,
          page_no: pageNo,
          page_size: pageSize,
        },
      });
      return response.data;
    },
    { enabled: !!placementId, onError: handleError }
  );

  const result = useMemo(() => {
    if (data && data.histories && data.pages)
      return {
        histories: data.histories.filter((history) => {
          if (
            !isAdmin &&
            (history.type === "mediation" ||
              history.type === "dsp" ||
              history.type === "headerbidding")
          ) {
            return false;
          }
          return true;
        }),
        pages: data.pages,
      };
    return { histories: [], pages: 0 };
  }, [data, isAdmin]);

  return { data: result, ...rest };
};

export default useGetHistory;
