import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  domainListId: number;
  domainId: number;
}

interface Response {
  code: 200 | 401;
  text: "ok" | "unauthorized";
}

// 차단 도메인 삭제
const useDeleteDomain = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ domainListId, domainId }) => {
      const response: AxiosResponse<Response> = await API.default.delete(
        `/blocklist/domains/${domainListId}/domain/${domainId}`
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("삭제되었습니다.");
        invalidate(["blocklist/useGetDomain", "blocklist/useGetDomains"]);
      },
    }
  );

  return result;
};

export default useDeleteDomain;
