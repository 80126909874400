import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { AppPlatform } from "src/types";
import API from "src/utils/api";

interface Params {
  search: string;
}

interface Response {
  code: 200 | 400 | 401 | 500;
  data?: {
    apps: {
      developer: string;
      free: boolean;
      icon: string;
      id: number;
      name: string;
      package_name: string;
      platform_type: AppPlatform;
      score: number;
      url: string;
    }[];
  };
  text: "ok" | "bad-request" | "unauthorized" | "internal-server-error";
}

// Google Play 또는 AppStore에서 앱을 검색
// 시간이 너무 오래 걸려서 최대 10개까지만 검색
const useGetAppStore = ({ search }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["media/useGetAppStore", search],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/media/app/store`, {
        params: {
          q: search,
        },
      });
      return response.data.data;
    },
    { enabled: !!search, onError: handleError }
  );

  const result = useMemo(() => (data ? data : { apps: [] }), [data]);

  return { data: result, ...rest };
};

export default useGetAppStore;
