import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { SettlementHistoryType } from "src/types";
import API from "src/utils/api";

export interface History {
  after_revenue: number;
  before_revenue: number;
  created_at: number;
  month: string;
  payment_id: number;
  reason: string;
  type: SettlementHistoryType;
  user_id: number;
  user_name: string;
}

interface Params {
  paymentId: number;
  type?: SettlementHistoryType;
}

interface Response {
  code: 200 | 400 | 401;
  data?: History[];
  text: "ok" | "bad-request" | "unauthorized";
}

// 세금계산서 발행액 변경내역 및 보류내역 조회
const useGetPaymentHistory = ({ paymentId, type }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["payments/useGetPaymentHistory", paymentId, type],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(
        `/payments/${paymentId}/histories`,
        {
          params: {
            type,
          },
        }
      );
      return response.data.data;
    },
    { onError: handleError }
  );

  const result = useMemo(
    () => (data ? data.sort((a, b) => b.created_at - a.created_at) : []),
    [data]
  );

  return { data: result, ...rest };
};

export default useGetPaymentHistory;
