import { CSSProperties, useMemo } from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { useRecoilState } from "recoil";
import COLORS from "src/assets/colors";
import { viewerCompany } from "src/atoms/viewerCompany";
import useGetRevenue from "src/hooks/apis/dashboard/useGetRevenue";
import MediationTooltip from "./MediationTooltip";
import { chartStyle } from "./styles";

const MediationChart = () => {
  const [company] = useRecoilState(viewerCompany);
  const { data: revenueData } = useGetRevenue({ companyKey: company.key });
  const mediationData = useMemo(() => {
    if (revenueData)
      return revenueData.report.map((report) => {
        const thirdparty = revenueData.thirdparties.find(
          (v) => v.thirdparty_id === report.thirdparty_id
        );
        return {
          name: thirdparty?.thirdparty_name || "undefined",
          value: report.revenue,
        };
      });
  }, [revenueData]);

  const total = useMemo(() => {
    if (revenueData) return revenueData.report.reduce((acc, cur) => acc + cur.revenue, 0);
    return 0;
  }, [revenueData]);

  const legendStyle = useMemo<CSSProperties>(
    () => ({
      maxHeight: 150,
      maxWidth: 180,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "auto",
    }),
    []
  );

  return (
    <ResponsiveContainer height={200} width={"100%"} css={chartStyle}>
      <PieChart>
        <Pie data={mediationData} innerRadius={60} outerRadius={80} dataKey="value">
          {mediationData?.map((_, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          iconSize={10}
          align="left"
          verticalAlign="middle"
          layout="vertical"
          wrapperStyle={legendStyle}
        />
        <Tooltip
          label="name"
          content={(params) => <MediationTooltip {...params} total={total} />}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default MediationChart;
