import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, FocusEvent, SyntheticEvent, useCallback, useMemo, useState } from "react";
import { TextField } from "src/components/commons";
import useGetAppThirdpartyList, {
  MatchKeyInfo,
  ThirdpartyInfo,
} from "src/hooks/apis/placements/useGetAppThirdpartyList";
import usePostAppThirdparty from "src/hooks/apis/placements/usePostAppThirdparty";
import { THIRDPARTY } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { matchingInfoModalStyle } from "./styles";

interface AddLineItemModalProps {
  onClose: () => void;
  open: { key: string; isOpen: boolean };
}

const AddLineItemModal = ({ onClose, open }: AddLineItemModalProps) => {
  const [mediation, setMediation] = useState<ThirdpartyInfo | null>(null);
  const { data } = useGetAppThirdpartyList({
    placementId: open.key,
    type: THIRDPARTY.APP_SDK_MEDIATION,
  });
  const { mutate: updateMatchKeyInfo } = usePostAppThirdparty();

  const makeDefaultValue = useCallback(
    (type: "string" | "float" | "integer" | "number" | "boolean") => {
      if (type === "string") return "";
      if (type === "boolean") return false;
      return "";
    },
    []
  );
  const formInfo = useMemo(() => {
    if (mediation?.match_key_info && mediation?.match_key_info.length > 0) {
      return mediation.match_key_info.reduce((acc: MatchKeyInfo, cur) => {
        return { ...acc, ...cur };
      }, {});
    }
    return;
  }, [mediation?.match_key_info]);

  const initialValues = useMemo(() => {
    if (formInfo)
      return Object.entries(formInfo).reduce(
        (acc: Record<string, unknown>, [key, condition]) => {
          acc[key] = makeDefaultValue(condition.type);
          return acc;
        },
        { bidfloor: undefined }
      );
    return { bidfloor: undefined };
  }, [formInfo, makeDefaultValue]);

  const validationData = useMemo(() => {
    if (formInfo)
      return Object.entries(formInfo).reduce(
        (acc: Record<string, yup.BaseSchema>, [key, condition]) => {
          const { type, required } = condition;
          if (type === "float" || type === "integer" || type === "number") {
            acc = {
              ...acc,
              [key]: yup.string().test(key, "숫자를 입력해주세요.", (value = "") => !isNaN(+value)),
            };
          } else {
            acc = { ...acc, [key]: yup[type]() };
          }
          if (required) {
            acc = { ...acc, [key]: acc[key].required(`${key}을(를) 설정해주세요.`) };
          }
          return acc;
        },
        {
          bidfloor: yup.number().min(0, "0보다 큰 값을 입력해주세요."),
        }
      );
    return {
      bidfloor: yup.number().min(0, "0보다 큰 값을 입력해주세요."),
    };
  }, [formInfo]);

  const validationSchema = yup.object(validationData);

  const { getFieldProps, handleSubmit, touched, errors, values, setFieldValue, handleBlur } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: ({ bidfloor, ...values }, { resetForm }) => {
        if (mediation) {
          updateMatchKeyInfo({
            placementId: open.key,
            thirdpartyId: mediation.id,
            matchKey: values,
            matchNo: mediation.match_no,
            bidfloor: parseInt(bidfloor as string),
          });
          resetForm();
          onClose();
        }
      },
    });

  const onChangeMediation = useCallback(
    (_: SyntheticEvent<unknown>, v: ThirdpartyInfo | null) => {
      setMediation(v);
      setFieldValue("mediation", v?.id);
    },
    [setFieldValue]
  );

  const onChangeBidfloor = useCallback(
    (field: "bidfloor" | "cpcBidfloor") => (e: ChangeEvent<HTMLInputElement>) => {
      const value =
        e.target.value.replace(
          REG_EXP.bidfloor,
          e.target.value.substring(0, e.target.value.length - 1)
        ) || 0;
      if (+(value || 0) < 1000000) {
        setFieldValue(field, value);
      }
    },
    [setFieldValue]
  );

  const onBlurBidfloor = useCallback(
    (e: FocusEvent) => {
      setFieldValue("bidfloor", parseFloat(`${values.bidfloor || 0}`));
      handleBlur(e);
    },
    [handleBlur, setFieldValue, values]
  );

  return (
    <Dialog
      css={matchingInfoModalStyle}
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-dsp-matching-info"
      aria-describedby="set dsp matching info"
    >
      <DialogTitle id="dialog-title">라인아이템 추가</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                className="field"
                size="small"
                options={data.thirdparties.filter(
                  (mediation, index, callback) =>
                    // ap_ssp 및 라인아이템 제외
                    mediation.id !== 0 &&
                    mediation.id !== 101 &&
                    index === callback.findIndex((t) => t.id === mediation.id)
                )}
                getOptionLabel={(mediation) => mediation.name}
                renderInput={({ InputLabelProps, ...params }) => (
                  <TextField
                    {...params}
                    label="미디에이션 네트워크 선택"
                    placeholder="미디에이션 네트워크를 선택해주세요."
                    required
                    error={shouldErrorShows("mediation", touched, errors)}
                    helperText={getHelperText("mediation", touched, errors)}
                  />
                )}
                value={mediation}
                onChange={onChangeMediation}
              />
            </Grid>
            {formInfo &&
              Object.keys(formInfo).map((key) => {
                if (formInfo[key].type === "boolean")
                  return (
                    <Grid key={key} item xs={12}>
                      <FormControlLabel
                        className="switch"
                        control={<Switch color="primary" />}
                        label={key}
                        labelPlacement="start"
                        {...getFieldProps(key)}
                      />
                    </Grid>
                  );
                return (
                  <Grid key={key} item xs={12}>
                    <TextField
                      className="field"
                      label={key}
                      placeholder={`${key}을(를) 입력하세요.`}
                      required
                      {...getFieldProps(key)}
                      error={shouldErrorShows(key, touched, errors)}
                      helperText={getHelperText(key, touched, errors)}
                    />
                  </Grid>
                );
              })}
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography>Bidfloor (Optional)</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                placeholder="0"
                prefix={<span className="ssp-input-currency-prefix">USD</span>}
                {...getFieldProps("bidfloor")}
                onChange={onChangeBidfloor("bidfloor")}
                onBlur={onBlurBidfloor}
                error={shouldErrorShows("bidfloor", touched, errors)}
                helperText={getHelperText("bidfloor", touched, errors)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLineItemModal;
