import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { RowClickedEvent, SortChangedEvent, ValueFormatterParams } from "ag-grid-community";
import { useFormik } from "formik";
import moment from "moment";
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import useGetCompanies from "src/hooks/apis/companies/useGetCompanies";
import useGetUserDetail from "src/hooks/apis/users/useGetUserDetail";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useUser from "src/hooks/useUser";
import { Company, COMPANY, COMPANY_ALIAS, STATUS } from "src/types";
import { BasicTable } from "..";
import { SearchField } from "../SearchFiled";
import { companyModalStyle } from "./styles";

interface CompanyModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const CompanyModal = ({ isOpen, onClose }: CompanyModalProps) => {
  const { isAdmin } = useUser();
  const invalidate = useInvalidateQueries();
  const [type, setType] = useState<Company>(COMPANY.ALL);
  const [name, setName] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [orders, setOrders] = useState<string[]>([]);

  const setCompany = useSetRecoilState(viewerCompany);
  const { data: userData } = useGetUserDetail({ userId: "me" });
  const { data } = useGetCompanies({
    types:
      type === COMPANY.ALL
        ? [
            COMPANY.CORPORATION,
            COMPANY.INDIVIDUAL,
            COMPANY.SYNDICATION,
            COMPANY.SYNDICATION_CLIENT_CORPORATION,
            COMPANY.SYNDICATION_CLIENT_INDIVIDUAL,
          ]
        : [type],
    name,
    status: STATUS.ACTIVE,
    pageSize,
    pageNo,
    orders,
    syndicationId: isAdmin ? undefined : userData.user.company_id,
  });

  const onChangeType = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setType(parseInt(e.target.value) as Company);
    setPageNo(1);
  }, []);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data?.pages || 0, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [10, 20, 30],
      },
    }),
    [data?.pages, onChangePage, onChangePageSize, pageNo, pageSize]
  );

  const { getFieldProps, handleSubmit, submitForm } = useFormik({
    initialValues: { name: "" },
    onSubmit: (value: { name: string }) => {
      setName(value.name);
      setPageNo(1);
    },
  });

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit(e);
    },
    [handleSubmit]
  );

  const onClickSearchButton = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const onClickCompany = (e: RowClickedEvent) => {
    e.event?.preventDefault();
    invalidate();
    setCompany({ id: e.data.id, key: e.data.key });
    onClose();
  };

  const onSortChanged = useCallback((e: SortChangedEvent) => {
    const sortInfo = e.columnApi
      .getColumnState()
      .filter((column) => !!column.sort)
      .map(({ colId, sort }) => {
        if (sort === "desc") return `-${colId}`;
        return `+${colId}`;
      });
    setOrders(sortInfo);
  }, []);

  return (
    <Dialog
      css={companyModalStyle}
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-company"
      aria-describedby="change company"
    >
      <DialogTitle id="dialog-title">업체 리스트</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <FormControl component="fieldset" fullWidth>
            {isAdmin && (
              <RadioGroup
                row
                className="filter"
                aria-label="filter"
                name="filter"
                value={type}
                onChange={onChangeType}
              >
                <FormControlLabel
                  value={COMPANY.ALL}
                  control={<Radio />}
                  label={COMPANY_ALIAS[COMPANY.ALL]}
                />
                <FormControlLabel
                  value={COMPANY.CORPORATION}
                  control={<Radio />}
                  label={COMPANY_ALIAS[COMPANY.CORPORATION]}
                />
                <FormControlLabel
                  value={COMPANY.SYNDICATION}
                  control={<Radio />}
                  label={COMPANY_ALIAS[COMPANY.SYNDICATION]}
                />
              </RadioGroup>
            )}
            <SearchField
              className="search"
              label="업체명"
              placeholder="업체명을 입력해주세요."
              onClickSearchButton={onClickSearchButton}
              {...getFieldProps("name")}
            />
          </FormControl>
        </form>
        <BasicTable
          columnDefs={columnDefs}
          rowData={data?.companies}
          {...paginationInfo}
          onRowClicked={onClickCompany}
          onSortChanged={onSortChanged}
        />
      </DialogContent>
    </Dialog>
  );
};

const columnDefs = [
  { headerName: "업체명", field: "name", sortable: true },
  {
    headerName: "구분",
    field: "type",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      return COMPANY_ALIAS[param.value as Company];
    },
  },
  {
    headerName: "등록일",
    field: "created_at",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const createdAt = param.value * 1000;
      return moment(createdAt).format("YYYY-MM-DD");
    },
  },
];

export default CompanyModal;
