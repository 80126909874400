import { AxiosError, AxiosResponse } from "axios";
import moment, { Moment } from "moment";
import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Thirdparty } from "src/types";
import API from "src/utils/api";
import { getEcpm } from "src/utils/calculator";

export type DataKey =
  | "click"
  | "ecpm"
  | "impression"
  | "request"
  | "response"
  | "revenue"
  | "adv_revenue";

interface Params {
  dataKey: DataKey;
  type: Thirdparty;
  since?: Moment;
  until?: Moment;
  isTest?: boolean;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    report: {
      click: number;
      ecpm: number;
      impression: number;
      report_date: string;
      request: number;
      response: number;
      revenue: number;
      thirdparty_id: number;
      adv_revenue?: number;
    }[];
    thirdparties: {
      thirdparty_id: number;
      thirdparty_name: string;
    }[];
    thirdparty_type: Thirdparty;
    total: {
      click: number;
      ecpm: number;
      impression: number;
      request: number;
      response: number;
      revenue: number;
    };
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 관리자 대시보드 (하단 일별 데이터)
// is_test=true 전달 시 mockup 데이터 전달
// since, until을 전달하지 않을 경우 -7일 ~ -1일 조회
// App DSP, Web DSP의 request 데이터 없음
const useGetAdminDaily = ({ isTest = false, dataKey, type, since, until }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["dashboard/useGetAdminDaily", type, since, until, isTest],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/dashboard/admin/daily`, {
        params: {
          type,
          since: since?.format("YYYY-MM-DD"),
          until: until?.format("YYYY-MM-DD"),
          is_test: isTest,
        },
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  // 날짜 세팅
  const range = useMemo(() => {
    const arr = [];
    for (let d = moment(since); d.isSameOrBefore(until); d.add(1, "days")) {
      arr.push(d.format("YYYY-MM-DD"));
    }
    return arr;
  }, [since, until]);

  // DOD, WOW 계산기
  const calculator = useCallback((values: (number | undefined)[]) => {
    if (!values[0]) return null;
    if (values[1] == null) return null;
    return ((values[1] - values[0]) / values[0]) * 100;
  }, []);

  const result = useMemo(() => {
    if (data) {
      const cardData = data.report.reduce(
        (acc, cur) => {
          acc.response += cur.response;
          acc.impression += cur.impression;
          acc.click += cur.click;
          acc.revenue += cur.revenue;
          return acc;
        },
        {
          response: 0,
          impression: 0,
          click: 0,
          ecpm: 0,
          revenue: 0,
        }
      );

      cardData.ecpm = getEcpm(cardData.impression, cardData.revenue);

      const reportedThirdparty =
        data.report.reduce((acc, cur) => {
          acc.add(cur.thirdparty_id);
          return acc;
        }, new Set<number>()) ?? new Set<number>();

      const result = [...reportedThirdparty].map((id) => {
        const filteredData = data?.report?.filter((v) => v.thirdparty_id === id) ?? [];
        const now = range.map(
          (date) =>
            filteredData.find((data) => moment(data.report_date).isSame(moment(date))) || {
              click: 0,
              ecpm: 0,
              impression: 0,
              request: 0,
              response: 0,
              revenue: 0,
              adv_revenue: 0,
            }
        );

        return {
          key: id,
          name: data?.thirdparties.find((v) => v.thirdparty_id === id)?.thirdparty_name ?? "",
          data: now.map((v) => v[dataKey]),
          total: now.reduce((acc, cur) => acc + (cur[dataKey] || 0), 0),
          dod: calculator(now.map((v) => v[dataKey]).slice(-2)),
          wow: calculator([now[0][dataKey], now[now.length - 1][dataKey]]),
        };
      });

      // 리포트 데이터 정렬
      result.sort(({ data: adata }, { data: bdata }) =>
        (adata.slice(-1)[0] ?? 0) > (bdata.slice(-1)[0] ?? 0)
          ? -1
          : (adata.slice(-1)[0] ?? 0) < (bdata.slice(-1)[0] ?? 0)
          ? 1
          : 0
      );

      return {
        data: result,
        range,
        cardData,
      };
    }

    return {
      data: [],
      range: [],
      cardData: {
        response: 0,
        impression: 0,
        click: 0,
        ecpm: 0,
        revenue: 0,
      },
    };
  }, [calculator, data, dataKey, range]);

  return { ...result, calculator, ...rest };
};

export default useGetAdminDaily;
