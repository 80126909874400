import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { MutationFunction, useMutation, UseMutationOptions } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";

export type CreateNpayPlacementsValues = {
  media_key: string;
  ids: string[];
};

type NpayAppMutationKey = "operation/npay/create-placements";
type NpayAppMutationFn = MutationFunction<AxiosResponse, CreateNpayPlacementsValues>;

type NpayAppMutationOptions = Omit<
  UseMutationOptions<AxiosResponse, AxiosError, CreateNpayPlacementsValues>,
  "mutationKey" | "mutationFn"
>;

export default function useCreateNpayPlacements(options?: NpayAppMutationOptions) {
  const { handleError } = useApiError();

  const mutationKey = useMemo<NpayAppMutationKey>(() => "operation/npay/create-placements", []);

  const mutateFn = useCallback<NpayAppMutationFn>((values) => {
    const { media_key, ...rest } = values;
    const cookie = parseCookies();

    return API.consoleV3.post(`/v3/naverpay/media/${media_key}/placements`, rest, {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
    });
  }, []);

  const mutation = useMutation(mutationKey, mutateFn, {
    onError: handleError,
    ...options,
  });

  return mutation;
}
