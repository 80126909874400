import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { SortChangedEvent } from "ag-grid-community";
import { ColDef, ColGroupDef, ValueFormatterParams } from "ag-grid-enterprise";
import { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import DataFormat from "src/assets/formats";
import { viewerCompany } from "src/atoms/viewerCompany";
import { BasicTable } from "src/components/commons";
import { SearchField } from "src/components/commons/SearchFiled";
import useGetBidfloors from "src/hooks/apis/dashboard/useGetBidfloors";
import useGetExcel from "src/hooks/apis/dashboard/useGetExcel";
import useGetAdFormatInfo, { AdFormat } from "src/hooks/apis/info/useGetAdFormatInfo";
import { Campaign, CAMPAIGN, CAMPAIGN_ALIAS, Media, MEDIA, MEDIA_ALIAS } from "src/types";

const Bidfloor = () => {
  const [company] = useRecoilState(viewerCompany);
  const [search, setSearch] = useState({ temp: "", value: "" });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [orders, setOrders] = useState<string[]>([]);
  const { data } = useGetBidfloors({
    companyKey: company.key,
    type: MEDIA.APP,
    search: search.value,
    pageNo,
    pageSize,
    orders,
  });

  const { data: adFormatData } = useGetAdFormatInfo({
    types: [
      CAMPAIGN.Banner,
      CAMPAIGN.Interstitial,
      CAMPAIGN.FrontVideo,
      CAMPAIGN.Native,
      CAMPAIGN.RewardVideo,
      CAMPAIGN.InterstitialVideo,
    ],
  });

  const { fetch } = useGetExcel({
    companyKey: company.key,
    type: MEDIA.APP,
    search: search.value,
    pageNo,
    pageSize,
    orders,
    exports: true,
  });

  const onChangeSearchWord = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearch({ ...search, temp: e.target.value });
    },
    [search]
  );

  const onSearchBidfloor = useCallback(() => {
    setSearch({ ...search, value: search.temp });
    setPageNo(1);
  }, [search]);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data?.pages || 0, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [10, 20, 30],
      },
    }),
    [data?.pages, onChangePage, onChangePageSize, pageNo, pageSize]
  );

  const onSortChanged = useCallback((e: SortChangedEvent) => {
    const sortInfo = e.columnApi
      .getColumnState()
      .filter((column) => !!column.sort)
      .map(({ colId, sort }) => {
        if (sort === "desc") return `-${colId}`;
        return `+${colId}`;
      });
    setOrders(sortInfo);
  }, []);

  const onExport = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      fetch();
    },
    [fetch]
  );

  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        Bidfloor
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid className="ssp-tools" container>
          <Grid item xs={3}>
            <SearchField
              label="검색어"
              placeholder="매체명 / 플레이스먼트명"
              value={search.temp}
              onChange={onChangeSearchWord}
              onClickSearchButton={onSearchBidfloor}
            />
          </Grid>
          <Grid item xs={9} className="download">
            <Button variant="outlined" onClick={onExport}>
              엑셀 다운로드
            </Button>
          </Grid>
        </Grid>
        <BasicTable
          rowData={data?.bidfloors}
          columnDefs={getColumnDefs(adFormatData.formats)}
          onSortChanged={onSortChanged}
          {...paginationInfo}
        />
      </Paper>
    </Container>
  );
};

export const getColumnDefs = (adFormats: AdFormat[]): (ColDef | ColGroupDef)[] => [
  {
    headerName: "매체 종류",
    field: "media_type",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const type = param.value as Media;
      return MEDIA_ALIAS[type];
    },
  },
  { headerName: "매체 명", field: "media_name", sortable: true },
  { headerName: "플레이스먼트", field: "placement_name", sortable: true },
  {
    headerName: "사이즈",
    field: "size_type",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const type = param.value;
      const format = adFormats.find((format) => format.id === type);
      if (format && format.width && format.height) return `${format.width}x${format.height}`;
      return "-";
    },
  },
  {
    headerName: "광고 형식",
    field: "campaign_type",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const type = param.value as Campaign;
      return CAMPAIGN_ALIAS[type];
    },
  },
  {
    headerName: "Bid Floor",
    field: "bidfloor",
    sortable: true,
    valueFormatter: (params: ValueFormatterParams) => {
      return DataFormat.bidfloor.formatter(params.value);
    },
  },
];

export default Bidfloor;
