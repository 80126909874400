import { Grid, MenuItem, SelectChangeEvent, Switch } from "@mui/material";
import { CellClickedEvent, ColDef, ColGroupDef, ValueFormatterParams } from "ag-grid-community";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import { BasicTable, Select } from "src/components/commons";
import useGetCategories, { BlockCategory } from "src/hooks/apis/blocklist/useGetCategories";
import usePutCategories from "src/hooks/apis/blocklist/usePutCategories";
import useGetWebMediaList, { WebMedia } from "src/hooks/apis/media/useGetWebMediaList";
import useGetWebPlacementList, {
  WebPlacement,
} from "src/hooks/apis/placements/useGetWebPlacementList";
import useOpenModal from "src/hooks/useOpenModal";
import { STATUS } from "src/types";
import EditCategoryModal from "./EditCategoryModal";
import { blockCategoryBoardStyle } from "./styles";

interface BlockCategorySwitchProps {
  data: BlockCategory;
  placementId: WebPlacement["id"];
}

const BlockCategoryBoard = () => {
  const [media, setMedia] = useState<WebMedia["key"]>("");
  const [placement, setPlacement] = useState<WebPlacement["id"]>("");

  const [openEditModal, onShowEditModal, onCloseEditModal] = useOpenModal(null);
  const [selectedCategory, setSelectedCategory] = useState<BlockCategory | null>(null);
  const [company] = useRecoilState(viewerCompany);

  const { data: mediaData } = useGetWebMediaList({ companyKey: company.key });
  const { data: placementData } = useGetWebPlacementList({
    mediaKeys: media ? [media] : undefined,
  });
  const { data: categoryData } = useGetCategories({ placementId: placement });

  // 초기 매체 데이터 세팅
  useEffect(() => {
    if (mediaData.media[0]) setMedia(mediaData.media[0].key);
  }, [mediaData.media]);

  // 초기 플레이스먼트 데이터 세팅
  useEffect(() => {
    if (placementData.placements[0]) setPlacement(placementData.placements[0].id);
  }, [placementData.placements]);

  const onChangeMedia = useCallback((event: SelectChangeEvent<WebMedia["key"]>) => {
    setMedia(event.target.value);
  }, []);

  const onChangePlacement = useCallback((event: SelectChangeEvent<WebPlacement["id"]>) => {
    setPlacement(event.target.value);
  }, []);

  const onShowEditCategoryModal = useCallback(
    (e: CellClickedEvent) => {
      if (e.colDef.field !== "status") {
        onShowEditModal();
        setSelectedCategory(e.data);
      }
    },
    [onShowEditModal]
  );

  return (
    <>
      <Grid container className="ssp-tools" spacing={2} css={blockCategoryBoardStyle}>
        <Grid item xs={3}>
          <Select
            label="매체"
            placeholder="매체를 선택해주세요."
            onChange={onChangeMedia}
            value={media}
          >
            {mediaData.media.map((media) => (
              <MenuItem key={media.key} value={media.key}>
                {media.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Select
            label="플레이스먼트"
            placeholder="플레이스먼트를 선택해주세요."
            onChange={onChangePlacement}
            value={placement}
          >
            {placementData.placements.map((placement) => (
              <MenuItem key={placement.id} value={placement.id}>
                {placement.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <BasicTable
        getRowId={(params) => params.data.code}
        animateRows
        rowData={categoryData}
        columnDefs={getColumnDefs(placement)}
        onCellClicked={onShowEditCategoryModal}
      />
      {openEditModal.isOpen && (
        <EditCategoryModal
          open={openEditModal.isOpen}
          placementId={placement}
          blockCategory={selectedCategory}
          onClose={onCloseEditModal}
        />
      )}
    </>
  );
};

const getColumnDefs = (placementId: WebPlacement["id"]): (ColDef | ColGroupDef)[] => [
  { headerName: "카테고리 이름", field: "name", sortable: true },
  {
    headerName: "하위 카테고리 수",
    field: "sub_categories",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const subCategories = param.data.sub_categories as BlockCategory["sub_categories"];
      if (subCategories)
        return `${subCategories.filter((v) => v.status === STATUS.ACTIVE).length} / ${
          subCategories.length
        }`;
      return "0 / 0";
    },
  },
  {
    headerName: "상태",
    field: "status",
    sortable: true,
    cellRenderer: (params: { data: BlockCategory }) => (
      <BlockCategorySwitch {...params} placementId={placementId} />
    ),
  },
];

function BlockCategorySwitch({ data, placementId }: BlockCategorySwitchProps) {
  const toggleValue = useMemo(() => (data.status === STATUS.ACTIVE ? true : false), [data.status]);
  const { mutate } = usePutCategories();

  const onUpdateCategoryStatus = useCallback(
    (d: BlockCategory) => {
      mutate({
        placementId: placementId,
        categories: [d],
      });
    },
    [mutate, placementId]
  );

  const onChangeStatus = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const value = checked ? STATUS.ACTIVE : STATUS.SUSPEND;
      onUpdateCategoryStatus({ ...data, status: value });
    },
    [data, onUpdateCategoryStatus]
  );

  return <Switch checked={toggleValue} onChange={onChangeStatus} />;
}

export default BlockCategoryBoard;
