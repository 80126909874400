import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { STATUS, Status } from "src/types";
import API from "src/utils/api";

interface Params {
  companyId: number;
  message?: string;
  status: Status;
}

interface Response {
  code: 200 | 400 | 401 | 404;
  text: "ok" | "bad-request" | "unauthorized" | "not-found";
}

// 관리자 승인 및 거절
const usePutCompanyApproval = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  let resultMessage = "";
  const result = useMutation<Response, AxiosError, Params>(
    async ({ companyId, message, status }) => {
      if (status === STATUS.ACTIVE) {
        resultMessage = "승인되었습니다";
      } else {
        resultMessage = "거절되었습니다.";
      }
      const response: AxiosResponse<Response> = await API.default.put(
        `/companies/${companyId}/admin`,
        {
          message,
          status,
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success(resultMessage);
        invalidate("companies/useGetAdminRequests");
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePutCompanyApproval;
