import { Stack, Box, SelectChangeEvent, MenuItem, Button } from "@mui/material";
import { ColDef, ICellRendererParams, SortChangedEvent } from "ag-grid-community";
import { useFormik } from "formik";
import moment, { Moment } from "moment";
import { parse, stringify } from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DateField, BasicTable, MultipleSelect, Select, TextField } from "src/components/commons";
import useNpayAppList, { NpayApp } from "src/hooks/apis/npay/use-npay-app-list";
import useNpayCsList, {
  NpayCsFields,
  NpayCsListParams,
  NpayCsStatus,
  NpayCsType,
} from "src/hooks/apis/npay/use-npay-cs-list";
import useToast from "src/hooks/useToast";
import { STATUS } from "src/types";
import { shouldErrorShows } from "src/utils/form-helper";
import { MAX_DATE, MIN_DATE, SEARCH_PARAMS_VALIDATOR, TYPE_OPTIONS } from "../const";
import { object, number, mixed, array, string } from "yup";

const searchParamsValidationSchema = object({
  status: string().oneOf(["0", ...Object.values(NpayCsStatus).map((value) => value.toString())]),
  since: string().test("isValidSince", SEARCH_PARAMS_VALIDATOR.since),
  until: string().test("isValidUntil", SEARCH_PARAMS_VALIDATOR.until),
  type: string().oneOf(["0", ...Object.values(NpayCsType).map((value) => value.toString())]),
  media_keys: mixed().test("isValidMediaKeys", SEARCH_PARAMS_VALIDATOR.orders),
  query: string(),
  page_no: string().test("isValidPageNo", SEARCH_PARAMS_VALIDATOR.pageNo),
  page_size: string().test("isValidPageSize", SEARCH_PARAMS_VALIDATOR.pageSize),
  orders: mixed().test("isValidMediaKeys", SEARCH_PARAMS_VALIDATOR.orders),
});

const defaultSearchParams: NpayCsListParams = {
  status: 0,
  since: moment().subtract(7, "d").format("YYYYMMDD"),
  until: moment().subtract(1, "d").format("YYYYMMDD"),
  type: 0,
  media_keys: [],
  query: "",
  page_no: 1,
  page_size: 20,
};

type FormValues = Pick<NpayCsListParams, "media_keys" | "query" | "status" | "type"> & {
  since: Moment | null;
  until: Moment | null;
};

const formValidationSchema = object({
  status: number().required(),
  since: mixed().required(),
  until: mixed().required(),
  type: number().required(),
  media_keys: array().of(string()),
  query: string(),
});

const STATUS_OPTIONS = [
  {
    label: "전체 상태",
    value: 0,
  },
  {
    label: "처리 중(접수)",
    value: NpayCsStatus.InProgress,
  },
  {
    label: "처리 완료",
    value: NpayCsStatus.Completed,
  },
];

export const columnDef: ColDef[] = [
  {
    headerName: "NO",
    field: "id",
    sortable: true,
  },
  {
    headerName: "문의 일자",
    field: "created_at",
    sortable: true,
    valueFormatter: (params) => {
      return moment.unix(params.value).format("YYYY/MM/DD HH:mm:ss");
    },
  },
  {
    headerName: "앱 매체 명",
    field: "media_name",
    sortable: true,
  },
  {
    headerName: "업체 명",
    field: "company_name",
    sortable: true,
  },
  {
    headerName: "네이버 ID",
    field: "naver_id",
    sortable: true,
  },
  {
    headerName: "문의항목",
    field: "type",
    sortable: true,
    cellRenderer: (params: ICellRendererParams) => {
      const { id } = params.data;
      const text = TYPE_OPTIONS.find((option) => option.value === params.value)?.label || "";

      return <Link to={`/operation/npay/cs/${id}`}>{text}</Link>;
    },
  },
  {
    headerName: "USN",
    field: "usn",
    sortable: true,
  },
  {
    headerName: "Email",
    field: "email",
    sortable: true,
  },
  {
    headerName: "문의내용",
    field: "response_content",
    sortable: false,
  },
];

export default function CS() {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();

  // cs 목록 query params
  const [params, setParams] = useState(() => {
    const parsedSearch = parse(location.search, {
      ignoreQueryPrefix: true,
      comma: true,
    });

    try {
      const validatedParams = searchParamsValidationSchema.validateSync(parsedSearch);

      return {
        status: validatedParams.status
          ? Number(validatedParams.status)
          : defaultSearchParams.status,
        since: validatedParams.since
          ? moment(validatedParams.since).format("YYYYMMDD")
          : defaultSearchParams.since,
        until: validatedParams.until
          ? moment(validatedParams.until).format("YYYYMMDD")
          : defaultSearchParams.until,
        type: validatedParams.type ? Number(validatedParams.type) : defaultSearchParams.type,
        media_keys: Array.isArray(validatedParams.media_keys)
          ? validatedParams.media_keys
          : typeof validatedParams.media_keys === "string"
          ? validatedParams.media_keys.split(",")
          : defaultSearchParams.media_keys,
        query: validatedParams.query || defaultSearchParams.query,
        page_no: validatedParams.page_no
          ? Number(validatedParams.page_no)
          : defaultSearchParams.page_no,
        page_size: validatedParams.page_size
          ? Number(validatedParams.page_size)
          : defaultSearchParams.page_size,
        orders: Array.isArray(validatedParams.orders)
          ? validatedParams.orders
          : typeof validatedParams.orders === "string"
          ? validatedParams.orders.split(",")
          : defaultSearchParams.orders,
      };
    } catch (error) {
      return defaultSearchParams;
    }
  });

  // cs 목록 query 조회 여부
  const [enableQuery, setEnableQuery] = useState(true);

  const initialFormValues = useMemo<FormValues>(() => {
    const { media_keys, query, status, type } = params;

    return {
      media_keys,
      query,
      status,
      type,
      since: moment(params.since).startOf("d"),
      until: moment(params.until).startOf("d"),
    };
  }, [params]);

  // 조회하기 버튼 핸들러
  const handleSubmitForm = (values: FormValues) => {
    setParams((prev) => ({
      ...prev,
      ...values,
      since: values.since?.format("YYYYMMDD") || "",
      until: values.until?.format("YYYYMMDD") || "",
      page_no: 1,
    }));

    setEnableQuery(true);
  };

  // formik
  const {
    setValues,
    setFieldValue,
    values: formValues,
    isValid,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialFormValues,
    onSubmit: handleSubmitForm,
    validationSchema: formValidationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const query = useNpayCsList(params, {
    enabled: enableQuery,
    onSuccess: () => {
      setEnableQuery(false);
    },
  });

  // 앱 매체 목록 query (select 용도)
  const npayAppListQuery = useNpayAppList(
    {
      page_no: 1,
      page_size: 10000,
      status: STATUS.ACTIVE,
    },
    {
      onSuccess: (response) => {
        const { rows } = response.data.data;

        setParams((prev) => {
          const allMediaKeys = rows.map((app) => app.key);

          return {
            ...prev,
            media_keys:
              (prev.media_keys || []).length <= 0
                ? allMediaKeys
                : allMediaKeys.filter((key) => prev.media_keys?.includes(key)),
          };
        });
      },
    }
  );

  useEffect(() => {
    const searchParams = stringify(params, {
      addQueryPrefix: true,
      arrayFormat: "comma",
    });

    navigate("/operation/npay/cs" + searchParams, {
      replace: true,
    });
  }, [navigate, params]);

  const handleChangeDates = useCallback(
    (field: "since" | "until") => (value: Moment | null) => {
      setValues((prev) => {
        if (value === null) {
          toast.error("날짜를 선택해 주세요.");

          return {
            ...prev,
            [field]: value,
          };
        }

        if (!value.isValid()) {
          return prev;
        }

        const withinRange = value.isSameOrAfter(MIN_DATE) && value.isSameOrBefore(MAX_DATE);

        if (!withinRange) {
          toast.error("선택 가능한 범위를 확인해 주세요.");
          return prev;
        }

        const isEndDate = field === "until";
        const isEndDateBeforeStartDate = isEndDate && value.isBefore(prev.since);
        const isStartDateAfterEndDate = !isEndDate && value.isAfter(prev.until);

        if (isEndDateBeforeStartDate) {
          const newStartDate = moment(value).subtract(6, "d");

          return {
            ...prev,
            since: newStartDate.isBefore(MIN_DATE) ? MIN_DATE : newStartDate,
            until: value,
          };
        }

        if (isStartDateAfterEndDate) {
          const newEndDate = moment(value).add(6, "d");

          return {
            ...prev,
            since: value,
            until: newEndDate.isAfter(MAX_DATE) ? MAX_DATE : newEndDate,
          };
        }

        return {
          ...prev,
          [field]: value,
        };
      });
    },
    [setValues, toast]
  );

  const handleChangeApp = useCallback(
    (values: NpayApp[]) => {
      setFieldValue(
        "media_keys",
        values.map((app) => app.key)
      );
    },
    [setFieldValue]
  );

  const handleChangeStatus = useCallback(
    (e: SelectChangeEvent<NpayCsStatus>) => {
      const { value } = e.target;

      setFieldValue("status", Number(value));
    },
    [setFieldValue]
  );

  const handleChangeType = useCallback(
    (e: SelectChangeEvent<NpayCsType>) => {
      const { value } = e.target;

      setFieldValue("type", Number(value));
    },
    [setFieldValue]
  );

  const handleChangePage = useCallback((page: number) => {
    setParams((prev) => ({ ...prev, page_no: page }));
    setEnableQuery(true);
  }, []);

  const handleChangePageSize = useCallback((pageSize: number) => {
    setParams((prev) => ({ ...prev, page_no: 1, page_size: pageSize }));
    setEnableQuery(true);
  }, []);

  const handleSortChange = useCallback((e: SortChangedEvent) => {
    const sortedColumns = e.columnApi
      .getColumnState()
      .filter((column) => Boolean(column.sort))
      .map(({ colId, sort }) => (sort === "desc" ? `-${colId}` : colId)) as NpayCsFields[];

    setParams((prev) => ({ ...prev, page_no: 1, orders: sortedColumns }));
    setEnableQuery(true);
  }, []);

  const selectedApps = npayAppListQuery.data.rows.filter((app) =>
    (formValues.media_keys || []).includes(app.key)
  );

  const paginationInfo = useMemo(
    () => ({
      pagination: {
        page: params.page_no as number,
        count: query.data.pages,
        onChange: handleChangePage,
      },
      pageSize: {
        size: params.page_size as number,
        onChange: handleChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [handleChangePage, handleChangePageSize, query.data.pages, params.page_no, params.page_size]
  );

  return (
    <Stack mt="2rem" mb="1rem" spacing={2}>
      <Stack component="form" onSubmit={handleSubmit} spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          {/* 시작일 */}
          <Box sx={{ width: 300 }}>
            <DateField
              label="시작일"
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              value={formValues.since}
              onChange={handleChangeDates("since")}
              InputProps={{
                onBlur: handleBlur("since"),
                error: shouldErrorShows("since", touched, errors),
              }}
              disabled={query.isFetching}
            />
          </Box>
          {/* 종료일 */}
          <Box sx={{ width: 300 }}>
            <DateField
              label="종료일"
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              value={formValues.until}
              onChange={handleChangeDates("until")}
              InputProps={{
                onBlur: handleBlur("until"),
                error: shouldErrorShows("until", touched, errors),
              }}
              disabled={query.isFetching}
            />
          </Box>
          {/* 앱 매체 명 */}
          <Box sx={{ width: 300 }}>
            <MultipleSelect
              options={npayAppListQuery.data.rows}
              label="앱 매체 명"
              placeholder="앱 매체 선택"
              getOptionLabel={(app) => app.name}
              getOptionValue={(app) => app.key}
              onChange={handleChangeApp}
              value={selectedApps}
              disabled={query.isFetching || npayAppListQuery.isFetching}
            />
          </Box>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2}>
          {/* filters */}
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* 상태 항목 */}
            <Box sx={{ width: 300 }}>
              <Select
                label="상태"
                onChange={handleChangeStatus}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value={formValues.status}
                disabled={query.isFetching}
              >
                {STATUS_OPTIONS.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            {/* 문의 항목 */}
            <Box sx={{ width: 300 }}>
              <Select
                label="문의항목"
                onChange={handleChangeType}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value={formValues.type}
                disabled={query.isFetching}
              >
                {TYPE_OPTIONS.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            {/* 검색 */}
            <TextField
              sx={{ width: 300, flexShrink: 0 }}
              label="검색"
              placeholder="검색어를 입력해 주세요."
              value={formValues.query}
              onChange={handleChange("query")}
              onBlur={handleBlur("query")}
              disabled={query.isFetching}
            />
          </Stack>

          {/* 조회 버튼 */}
          <Button type="submit" variant="contained" disabled={!isValid || query.isFetching}>
            조회하기
          </Button>
        </Stack>
      </Stack>

      <BasicTable
        getRowId={(params) => params.data.id}
        animateRows
        rowData={query.data.rows}
        columnDefs={columnDef}
        {...paginationInfo}
        onSortChanged={handleSortChange}
      />
    </Stack>
  );
}
