import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { STATUS, Status } from "src/types";
import API from "src/utils/api";

interface Params {
  rewardCSNo?: string;
}

interface Response {
  code: 200 | 401 | 404;
  data?: {
    item: {
      adid: string;
      company_key: string;
      company_name: string;
      created_at: number;
      email: string;
      idfv: string;
      media_key: string;
      media_name: string;
      response: string;
      reward_cs_no: number;
      sdk_mediation_thirdparty_no: number;
      status: Status;
      text: string;
      updated_at: number;
      usn: string;
    };
  };
  text: "ok" | "unauthorized" | "not-found";
}

// 상세정보 조회
const useGetRewardCSDetail = ({ rewardCSNo }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["rewardCS/useGetRewardCSDetail", rewardCSNo],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/reward-cs/${rewardCSNo}`);
      return response.data.data;
    },
    { enabled: !!rewardCSNo, onError: handleError }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            item: {
              adid: "",
              company_key: "",
              company_name: "",
              created_at: 0,
              email: "",
              idfv: "",
              media_key: "",
              media_name: "",
              response: "",
              reward_cs_no: 0,
              sdk_mediation_thirdparty_no: 0,
              status: STATUS.SUSPEND,
              text: "",
              updated_at: 0,
              usn: "",
            },
          },
    [data]
  );

  return { data: result, ...rest };
};

export default useGetRewardCSDetail;
