import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { Status, UserRole } from "src/types";
import API from "src/utils/api";

interface Params {
  userId: number;
  department?: string;
  name?: string;
  phoneNo?: string;
  role?: UserRole;
  status?: Status;
}

interface Response {
  code: 200 | 404 | 500;
  text: "ok" | "not-found" | "internal-server-error";
}

/*
  사용자 정보 저장
*/
const usePatchUser = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ userId = "me", department, name, phoneNo, role, status }) => {
      const response: AxiosResponse<Response> = await API.default.patch(`/users/${userId}`, {
        department,
        name,
        phone_no: phoneNo,
        role,
        status,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("사용자 정보가 수정되었습니다.");
        invalidate();
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePatchUser;
