import { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

interface Params {
  month: string;
}

// 엑셀 내보내기
const useGetExcel = ({ month }: Params) => {
  const { handleError } = useApiError();
  const { refetch, ...rest } = useQuery<any, AxiosError>(
    ["payments/useGetExcel", month],
    async () => {
      const response: AxiosResponse<any> = await API.default.get(`/payments/excel/${month}`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `정산(${month}).xlsx`);
      link.click();
    },
    { enabled: false, onError: handleError }
  );

  return { fetch: refetch, ...rest };
};

export default useGetExcel;
