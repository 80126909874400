import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { ColDef, ColGroupDef, RowDragEvent, ValueFormatterParams } from "ag-grid-community";
import { MouseEvent, SyntheticEvent, useCallback, useMemo, useState } from "react";
import DataFormat from "src/assets/formats";
import { BasicTable } from "src/components/commons";
import useGetAppPlacementDetail from "src/hooks/apis/placements/useGetAppPlacementDetail";
import useGetAppThirdpartyList, {
  ThirdpartyInfo,
} from "src/hooks/apis/placements/useGetAppThirdpartyList";
import usePutAppThirdpartyList from "src/hooks/apis/placements/usePutAppThirdpartyList";
import useOpenModal from "src/hooks/useOpenModal";
import useToast from "src/hooks/useToast";
import { STATUS, THIRDPARTY } from "src/types";
import AddLineItemModal from "./AddLineItemModal";
import MediationMatchingInfoModal from "./MediationMatchingInfoModal";
import { editMediationModalStyle } from "./styles";

interface EditMediationModalProps {
  isSdkMediation: boolean;
  onClose: () => void;
  open: { key: string; isOpen: boolean };
}

const EditMediationModal = ({ isSdkMediation, onClose, open }: EditMediationModalProps) => {
  const toast = useToast();
  const [openLineItemModal, onShowLineItemModal, onCloseLineItemModal] = useOpenModal(open.key);
  const [mediationStatus, setMediationStatus] = useState(isSdkMediation);
  const [mediationList, setMediationList] = useState<ThirdpartyInfo[]>([]);
  const { mutate } = usePutAppThirdpartyList();

  const { refetch: refetchAppPlacementDetail } = useGetAppPlacementDetail({
    placementId: open.key,
  });

  const { refetch: refetchThirdpartyList, isPreviousData } = useGetAppThirdpartyList(
    {
      placementId: open.key,
      type: THIRDPARTY.APP_SDK_MEDIATION,
    },
    {
      onSuccess: (data) => {
        if (data && data.thirdparties) setMediationList(data.thirdparties);
      },
    }
  );

  const onCloseModal = useCallback(() => {
    setMediationList([]);
    onClose();
  }, [onClose]);

  const onRowDragEnd = useCallback((e: RowDragEvent) => {
    const nodeId = e.node.id;
    setMediationList((prev) => {
      const idx = prev.findIndex((v) => `${v.match_no}` === nodeId);
      const removed = prev?.splice(idx, 1);
      prev.splice(e.node.rowIndex ?? 0, 0, ...removed);
      return [...prev];
    });
  }, []);

  const onChangeMediationStatus = useCallback(
    (_event: SyntheticEvent<Element, Event>, checked: boolean) => {
      // 미디에이션 상태 OFF 변경 시 모든 미디에이션 아이템 상태를 STATUS.SUSPEND로 변경
      const thirdparties = checked
        ? mediationList
        : mediationList.map((mediation) => ({ ...mediation, status: STATUS.SUSPEND }));

      mutate(
        {
          placementId: open.key,
          isSdkMediation: checked,
          thirdparties,
          type: THIRDPARTY.APP_SDK_MEDIATION,
        },
        {
          onSuccess: async () => {
            // 상위 모달(EditPlacementModal) 저장 버튼 클릭 시 appPlacementDetail.is_sdk_mediation 값이 같아지도록 appPlacementDetail 최신화
            await refetchAppPlacementDetail();

            if (checked && isPreviousData) {
              // 미디에이션 테이블 최신화
              await refetchThirdpartyList();
            }

            setMediationStatus(checked);
          },
        }
      );
    },
    [
      mediationList,
      mutate,
      open.key,
      refetchThirdpartyList,
      isPreviousData,
      refetchAppPlacementDetail,
    ]
  );

  const onSave = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      if (
        mediationList
          .filter(
            (mediation, index, callback) =>
              // ap_ssp 및 라인아이템 제외
              index !==
              callback.findIndex(
                (t) =>
                  t.id === mediation.id &&
                  t.bidfloor === mediation.bidfloor &&
                  t.status === mediation.status
              )
          )
          .some((mediation) => mediation.status === STATUS.ACTIVE)
      ) {
        toast.error("해당 네트워크는 이미 동일한 Bid Floor가 활성화되었습니다.");
        return;
      }

      mutate(
        {
          placementId: open.key,
          // 미디에이션 상태가 ON이어도 모든 미디에이션 아이템 상태가 STATUS.SUSPEND인 경우 isSdkMediation 값은 true일 수 없음
          isSdkMediation: mediationList.some((mediation) => mediation.status === STATUS.ACTIVE),
          thirdparties: mediationList,
          type: THIRDPARTY.APP_SDK_MEDIATION,
        },
        {
          onSuccess: async () => {
            // appPlacementDetail.is_sdk_mediation 갱신
            await refetchAppPlacementDetail();
          },
        }
      );
    },
    [mediationList, mutate, open.key, toast, refetchAppPlacementDetail]
  );

  const onChangeIndividualStatus = useCallback((matchNo: number, checked: boolean) => {
    setMediationList((prev) => {
      return prev.map((v) =>
        v.match_no === matchNo ? { ...v, status: checked ? STATUS.ACTIVE : STATUS.SUSPEND } : v
      );
    });
  }, []);

  return (
    <Dialog
      css={editMediationModalStyle}
      fullWidth
      open={open.isOpen}
      onClose={onCloseModal}
      aria-labelledby="representative-mediation"
      aria-describedby="edit mediation"
    >
      <DialogTitle id="dialog-title">미디에이션 설정</DialogTitle>
      <DialogContent className="dialog-content">
        <Box className="mediation-tools">
          <FormControlLabel
            className="switch"
            control={<Switch color="primary" />}
            label="미디에이션"
            labelPlacement="start"
            checked={mediationStatus}
            onChange={onChangeMediationStatus}
          />
          {mediationStatus && <Button onClick={onShowLineItemModal}>+ 라인아이템 추가</Button>}
        </Box>
        {mediationStatus && (
          <BasicTable
            getRowId={(params) => params.data.match_no}
            animateRows
            rowData={mediationList}
            columnDefs={getColumnDefs(open.key, onChangeIndividualStatus, mediationList)}
            rowDragManaged
            onRowDragEnd={onRowDragEnd}
            onRowDragLeave={onRowDragEnd}
          />
        )}
      </DialogContent>
      {mediationStatus && (
        <DialogActions sx={{ marginBottom: "1rem" }}>
          <Button onClick={onCloseModal} color="inherit">
            취소
          </Button>
          <Button onClick={onSave}>저장</Button>
        </DialogActions>
      )}
      {openLineItemModal.isOpen && (
        <AddLineItemModal open={openLineItemModal} onClose={onCloseLineItemModal} />
      )}
    </Dialog>
  );
};

const getColumnDefs = (
  placementId: string,
  onChangeStatus: (matchNo: number, checked: boolean) => void,
  mediations: ThirdpartyInfo[]
): (ColDef | ColGroupDef)[] => [
  {
    headerName: "미디에이션 명",
    field: "name",
    sortable: false,
    rowDrag: true,
  },
  {
    headerName: "매칭정보",
    sortable: false,
    cellRenderer: (params: { data: ThirdpartyInfo }) => {
      return params.data.match_key_info && params.data.match_key_info.length > 0 ? (
        <MatchingButton data={params.data} placementId={placementId} mediations={mediations} />
      ) : null;
    },
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
  },
  {
    headerName: "Bid Floor",
    field: "bidfloor",
    sortable: false,
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value) return DataFormat.bidfloor.formatter(params.value);
      return "";
    },
  },
  {
    headerName: "상태",
    field: "status",
    sortable: false,
    cellRenderer: (params: { data: ThirdpartyInfo }) => (
      <StatusSwitch data={params.data} placementId={placementId} onChange={onChangeStatus} />
    ),
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

function MatchingButton({
  data,
  placementId,
  mediations,
}: {
  data: ThirdpartyInfo;
  placementId: string;
  mediations: ThirdpartyInfo[];
}) {
  const [openMatchingInfoModal, onShowMatchingInfoModal, onCloseMatchingInfoModal] = useOpenModal(
    data.match_no
  );

  const isInit = useMemo(
    () => !(data.match_key && Object.values(data.match_key).some((v) => Boolean(v))),
    [data.match_key]
  );
  const label = useMemo(() => (isInit ? "등록하기" : "변경하기"), [isInit]);

  return (
    <>
      <Button
        variant="text"
        size="small"
        color={isInit ? "inherit" : "primary"}
        onClick={onShowMatchingInfoModal}
      >
        {label}
      </Button>
      {openMatchingInfoModal.isOpen && (
        <MediationMatchingInfoModal
          placementId={placementId}
          open={openMatchingInfoModal}
          onClose={onCloseMatchingInfoModal}
          mediations={mediations}
          {...data}
        />
      )}
    </>
  );
}

function StatusSwitch({
  data,
  onChange,
}: {
  data: ThirdpartyInfo;
  placementId: string;
  onChange: (matchNo: number, checked: boolean) => void;
}) {
  const [checked, setChecked] = useState(data.status === STATUS.ACTIVE);
  const onChangeStatus = useCallback(
    (matchNo: number) => (_event: SyntheticEvent<Element, Event>, checked: boolean) => {
      setChecked(checked);
      onChange(matchNo, checked);
    },
    [onChange]
  );

  return <Switch onChange={onChangeStatus(data.match_no)} checked={checked}></Switch>;
}

export default EditMediationModal;
