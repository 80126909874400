import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  // IconButton,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { ChangeEvent, FormEvent, Fragment, MouseEvent, useCallback, useState } from "react";
// import { AiOutlineDelete } from "react-icons/ai";
import { Select, TextField } from "src/components/commons";
// import useDeleteOfferwall from "src/hooks/apis/partners/useDeleteOfferwall";
import { Record } from "src/hooks/apis/partners/useGetOfferwallList";
import usePostOfferwall from "src/hooks/apis/partners/usePostOfferwall";
import { AppPlatform, APP_PLATFORM, APP_PLATFORM_ALIAS, PARTNER_ALIAS } from "src/types";
import { v4 as uuidv4 } from "uuid";
import { addExternalReportModalStyle } from "./styles";

interface EditExternalReportModalProps {
  onClose: () => void;
  isOpen: boolean;
  select: Record;
}

const EditExternalReportModal = ({ isOpen, onClose, select }: EditExternalReportModalProps) => {
  const [apps, setApps] = useState<
    {
      key: string | number;
      partnerAppId?: number;
      osType: AppPlatform;
      partnerAppName: string;
      partnerAppKey: string;
    }[]
  >(
    select.partner_apps.map(({ os_type, partner_app_id, partner_app_key, partner_app_name }) => ({
      key: partner_app_id,
      partnerAppId: partner_app_id,
      osType: os_type,
      partnerAppName: partner_app_name,
      partnerAppKey: partner_app_key,
    }))
  );

  const { mutate } = usePostOfferwall();
  // const { mutate: deleteApp } = useDeleteOfferwall();

  const onSelectPlatform = useCallback(
    (key: string | number) => (e: SelectChangeEvent<AppPlatform>) => {
      const targetIdx = apps.findIndex((v) => v.key === key);
      setApps((prev) => {
        if (targetIdx >= 0) {
          prev[targetIdx].osType = +e.target.value as AppPlatform;
        }
        return [...prev];
      });
    },
    [apps]
  );

  const onEditApp = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.preventDefault();
    setApps((prev) => [
      ...prev,
      { key: uuidv4(), osType: APP_PLATFORM.ANDROID, partnerAppName: "", partnerAppKey: "" },
    ]);
  }, []);

  // const onDeleteApp = useCallback(
  //   (key: string | number) => (e: MouseEvent) => {
  //     e.preventDefault();
  //     const app = apps.find((v) => v.key === key);
  //     if (app?.partnerAppId) {
  //       setApps((prev) => prev.filter((v) => v.key !== key));
  //       deleteApp({ id: app.partnerAppId });
  //     }
  //   },
  //   [apps, deleteApp]
  // );

  const onChangeName = useCallback(
    (key: string | number) => (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setApps((prev) => {
        const idx = prev.findIndex((app) => app.key === key);
        if (idx >= 0) {
          prev[idx]["partnerAppName"] = e.target.value;
        }
        return [...prev];
      });
    },
    []
  );

  const onChangeKey = useCallback(
    (key: string | number) => (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setApps((prev) => {
        const idx = prev.findIndex((app) => app.key === key);
        if (idx >= 0) {
          prev[idx]["partnerAppKey"] = e.target.value;
        }
        return [...prev];
      });
    },
    []
  );

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      mutate({ companyKey: select.company_key, partnerApps: apps, partnerId: select.partner_id });
      onClose();
    },
    [apps, mutate, onClose, select.company_key, select.partner_id]
  );

  return (
    <Dialog
      css={addExternalReportModalStyle}
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-external-report"
      aria-describedby="add external report"
    >
      <DialogContent className="dialog-content">
        <form id="edit-form" onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="리포트 업체"
                value={PARTNER_ALIAS[select.partner_id]}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField className="field" label="계약 업체" value={select.company_name} disabled />
            </Grid>
            <Grid item xs={12} className="guide">
              <Typography variant="subtitle1" gutterBottom>
                연동할 APP 이름과 APP Key를 입력해주세요.
              </Typography>
              <Typography className="description" variant="body2" gutterBottom>
                {`(리포트 업체에서 발급되는 APP Key입니다. 해당 APP Key는 설정한 계약 업체의 리포트와 연동합니다.)`}
              </Typography>
            </Grid>

            {apps.map(({ key, osType, partnerAppName, partnerAppKey }) => {
              return (
                <Fragment key={key}>
                  <Grid item xs={2}>
                    <Select
                      label="플랫폼"
                      placeholder="플랫폼"
                      onChange={onSelectPlatform(key)}
                      value={osType}
                    >
                      <MenuItem value={APP_PLATFORM.ANDROID}>
                        {APP_PLATFORM_ALIAS[APP_PLATFORM.ANDROID]}
                      </MenuItem>
                      <MenuItem value={APP_PLATFORM.iOS}>
                        {APP_PLATFORM_ALIAS[APP_PLATFORM.iOS]}
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      className="field"
                      label="APP 이름"
                      value={partnerAppName}
                      placeholder="App 이름을 입력하세요."
                      required
                      onChange={onChangeName(key)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      className="field"
                      label="APP Key"
                      value={partnerAppKey}
                      placeholder="해당 APP Key를 입력하세요."
                      required
                      onChange={onChangeKey(key)}
                    />
                  </Grid>
                  {/* NOTE: 2023/5/4 임시 불능 처리 (https://www.notion.so/adpopcorn/a2c4dd6ecbd248d29c5b5b51b1ded57f?pvs=4) */}
                  {/* <Grid item xs={1}>
                    <IconButton aria-label="delete" onClick={onDeleteApp(key)}>
                      <AiOutlineDelete className="ico-delete" />
                    </IconButton>
                  </Grid> */}
                </Fragment>
              );
            })}
            <Grid item xs={11}>
              <Button onClick={onEditApp}>+ APP 정보 추가하기</Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditExternalReportModal;
