import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Autocomplete,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import { SelectionChangedEvent, ColDef } from "ag-grid-community";
import { useState, useCallback, SyntheticEvent } from "react";
import { BasicTable, TextField } from "src/components/commons";
import useCreateNpayPlacements from "src/hooks/apis/npay/use-create-npay-placements";
import useNpayAppList, { NpayApp } from "src/hooks/apis/npay/use-npay-app-list";
import { NpayPlacement } from "src/hooks/apis/npay/use-npay-placements";
import usePlacementList from "src/hooks/apis/placements/use-placement-list";
import useOpenModal from "src/hooks/useOpenModal";
import useToast from "src/hooks/useToast";
import { STATUS, CAMPAIGN, CAMPAIGN_ALIAS, Campaign } from "src/types";

const columnDef: ColDef[] = [
  {
    headerName: "플레이스먼트 명",
    field: "name",
    lockPosition: "left",
    pinned: true,
    checkboxSelection: true,
  },
  {
    headerName: "플레이스먼트 ID",
    field: "id",
  },
  {
    headerName: "광고 형식",
    field: "ad_type",
    valueFormatter: (params) => {
      return CAMPAIGN_ALIAS[params.value as Campaign];
    },
  },
];

export default function CreateNpayPlacementModal(props: {
  onUpdate: () => Promise<void>;
  onClose: VoidFunction;
}) {
  const { onUpdate, onClose } = props;

  const toast = useToast();
  const [selectedApp, setSelectedApp] = useState<NpayApp | null>(null);
  const [selectedPlcmts, setSelectedPlcmts] = useState<NpayPlacement[]>([]);

  const npayAppListQuery = useNpayAppList({
    page_no: 1,
    page_size: 10000,
    status: STATUS.ACTIVE,
  });

  const placementListQuery = usePlacementList(
    {
      media_key: selectedApp?.key || "",
      page_no: 1,
      page_size: 10000,
      is_naverpay: false,
    },
    {
      enabled: selectedApp !== null,
    }
  );

  const { mutate: createNpayPlacements, isLoading: isCreating } = useCreateNpayPlacements();

  const onChangeApp = useCallback((_: SyntheticEvent<unknown>, v: NpayApp | null) => {
    setSelectedApp(v);
  }, []);

  const handleSelectionChanged = useCallback((e: SelectionChangedEvent) => {
    const selectedRows = e.api.getSelectedRows() as NpayPlacement[];
    setSelectedPlcmts(selectedRows);
  }, []);

  const disabled = !selectedApp || selectedPlcmts.length <= 0;

  const handleCreatePlacements = () => {
    if (disabled) {
      return;
    }

    createNpayPlacements(
      {
        media_key: selectedApp.key,
        ids: selectedPlcmts.map(({ id }) => id),
      },
      {
        onSuccess: () => {
          toast.success("등록되었습니다.");
          onUpdate();
        },
      }
    );
  };

  const [openConfirmModal, onShowConfirmModal, onCloseConfirmModal] = useOpenModal(null);
  const hasNonRvCampaign = selectedPlcmts.some(({ ad_type }) => ad_type !== CAMPAIGN.RewardVideo);

  return (
    <>
      <Dialog fullWidth open onClose={onClose}>
        <DialogTitle id="create-npay-plcmt-modal-title">
          네이버페이 적립 플레이스먼트 등록
        </DialogTitle>
        <DialogContent className="create-npay-plcmt-modal-dialog-content">
          <Stack pt="20px" spacing={2}>
            {/* 업체 명 */}
            <Autocomplete
              options={npayAppListQuery.data.rows}
              getOptionLabel={(app) => app.name}
              renderInput={({ InputLabelProps, ...params }) => (
                <TextField
                  {...params}
                  label="앱 매체 명"
                  placeholder="앱 매체를 선택하시면 하위 플레이스먼트가 테이블에 출력됩니다."
                  required
                />
              )}
              value={selectedApp}
              onChange={onChangeApp}
            />
            <Stack spacing={1}>
              <Typography alignSelf="end" variant="body2">
                총 {selectedPlcmts.length}개의 플레이스먼트가 선택되었습니다.
              </Typography>

              {/* 플레이스먼트 by 업체 */}
              <BasicTable
                getRowId={(params) => params.data.id}
                animateRows
                rowData={placementListQuery.data.rows}
                columnDefs={columnDef}
                rowSelection="multiple"
                onSelectionChanged={handleSelectionChanged}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "1rem" }}>
          <Button type="button" onClick={onClose} color="inherit" disabled={isCreating}>
            취소
          </Button>
          <Button
            type="button"
            onClick={hasNonRvCampaign ? onShowConfirmModal : handleCreatePlacements}
            disabled={disabled || isCreating}
          >
            등록
          </Button>
        </DialogActions>
      </Dialog>
      {openConfirmModal.isOpen && (
        <Dialog open onClose={onCloseConfirmModal}>
          <DialogContent className="confirm-npay-plcmt-modal-dialog-content">
            <Stack pt="20px" spacing={1}>
              <Typography variant="body1">
                선택하신 플레이스먼트 중 리워드 비디오 타입이 아닌 플레이스먼트가 포함되어 있습니다.
                등록을 진행하시겠습니까?
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ marginBottom: "1rem" }}>
            <Button
              type="button"
              onClick={onCloseConfirmModal}
              color="inherit"
              disabled={isCreating}
            >
              취소
            </Button>
            <Button
              type="button"
              onClick={handleCreatePlacements}
              disabled={disabled || isCreating}
            >
              등록
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
