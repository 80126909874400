import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { TextField } from "src/components/commons";
import useGetCustomerService from "src/hooks/apis/coupang/useGetCustomerService";
import usePatchCustomerService from "src/hooks/apis/coupang/usePatchCustomerService";
import { STATUS } from "src/types";
import { getHelperText, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { responseFormStyle } from "./styles";

const ResponseForm = () => {
  const { id } = useParams();
  const { data: clientData } = useGetCustomerService({ csNo: id });
  const { mutate } = usePatchCustomerService();

  const initialValues = useMemo(
    () => ({
      question: clientData.question.cs_message,
      response: clientData.question.reply_message || "",
    }),
    [clientData.question.cs_message, clientData.question.reply_message]
  );

  const validationSchema = yup.object({
    response: yup.string().required("답변을 입력하세요."),
  });

  const { getFieldProps, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      id && mutate({ csNo: id, answer: values.response });
    },
  });

  return (
    <Container component="section" className="ssp-section" css={responseFormStyle} maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        문의 내용 및 답변
      </Typography>
      <Paper className="content" elevation={2}>
        <form id="service-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="문의 내용"
                required
                readOnly
                {...getFieldProps("question")}
              />
            </Grid>
            <Grid item xs={12} className="response">
              <TextField
                multiline
                minRows={8}
                className="field"
                label="답변"
                placeholder="답변을 입력해주세요."
                readOnly={clientData.question.status === STATUS.DELETED}
                required
                {...getFieldProps("response")}
                error={shouldErrorShows("response", touched, errors)}
                helperText={getHelperText("response", touched, errors)}
              />
            </Grid>
            {clientData.question.status !== STATUS.DELETED && (
              <Grid item xs={12} className="submit">
                <Button type="submit">전송</Button>
              </Grid>
            )}
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default ResponseForm;
