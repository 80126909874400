import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

interface Params {
  name: string;
  companyId: string;
}

interface Response {
  code: 200 | 400;
  data?: {
    account_ids: string[];
  };
  text: "ok" | "bad-request";
}

// 같은 회사의 모든 사용자 이메일 리스트 조회
const useGetActId = ({ name, companyId }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    "auth/useGetActId",
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/auth/actid`, {
        params: { user_name: name, company_id: companyId },
      });
      return response.data.data;
    },
    { enabled: !!name && !!companyId, onError: handleError }
  );

  const result = useMemo(() => (data ? data : { account_ids: [] }), [data]);

  return { data: result, ...rest };
};

export default useGetActId;
