import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { companySettingStyle } from "./styles";
import { useRecoilValue } from "recoil";
import { uid2Agreement as uid2AgreementState } from "src/atoms/uid2Agreement";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import usePatchUid2Agreement from "src/hooks/apis/companies/usePatchUid2Agreement";
import { viewerCompany } from "src/atoms/viewerCompany";
import useToast from "src/hooks/useToast";

export default function UpdateUid2Agreement() {
  const company = useRecoilValue(viewerCompany);
  const uid2Agreement = useRecoilValue(uid2AgreementState);
  const [agreement, setAgreement] = useState(uid2Agreement.value);
  const { mutate } = usePatchUid2Agreement(company.id);
  const toast = useToast();

  useEffect(() => {
    setAgreement(uid2Agreement.value);
  }, [uid2Agreement]);

  const toggleUid2Agreement = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    setAgreement(value === "true");
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (agreement === null) {
      return;
    }

    mutate(
      { uid2Agreement: agreement },
      {
        onSuccess: () => {
          toast.success("수정되었습니다.");
        },
      }
    );
  };

  return (
    <Paper
      className="content"
      elevation={2}
      css={companySettingStyle}
      style={{ marginTop: "2rem" }}
    >
      <Typography className="title" variant="h6" gutterBottom>
        UID 2.0 활용 동의
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              애드팝콘은 사용자 정보 기반 타겟팅 광고 및 광고 효율 향상을 목적으로 UID 2.0 솔루션을
              적용하여 앱 사용자의 개인 정보를 수집 및 활용 할 수 있습니다. 수집된 개인정보는 암호화
              처리 예정이며, 광고 송출 외의 목적으로 사용되지 않습니다.
              <a
                href="https://www.adpopcorn.com/policy/privacy-optional"
                target="_blank"
                rel="noreferrer"
              >
                개인정보보호 약정서
              </a>
              를 확인하시고 동의 진행 부탁 드립니다. 해당 내용에 동의하지 않으실 경우, 애드팝콘 연동
              가이드 내 setUIDIdentifier API는 활용 불가능 합니다.
            </Typography>
            <ul style={{ paddingLeft: "20px", marginTop: "12px" }}>
              <li style={{ marginBottom: "12px" }}>
                <Typography
                  component="a"
                  target="_blank"
                  href="https://adpopcornssp.gitbook.io/ssp-sdk/android/undefined#id-6-uid-2.0-optional"
                  rel="noreferrer"
                  variant="body1"
                >
                  Android UID 설정 연동 가이드 바로가기
                </Typography>
              </li>
              <li>
                <Typography
                  component="a"
                  href="https://adpopcornssp.gitbook.io/ssp-sdk/ios/undefined#id-5-uid-2.0"
                  rel="noreferrer"
                  variant="body1"
                >
                  iOS UID 설정 연동 가이드 바로가기
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} justifyItems="center" mt={2}>
            <RadioGroup
              row
              className="field"
              aria-labelledby="uid-agreement-group"
              value={agreement}
              sx={{ width: "100%", justifyContent: "center" }}
              onChange={toggleUid2Agreement}
            >
              <FormControlLabel
                value={true} // str
                control={<Radio />}
                label="동의합니다."
                sx={{ width: "240px" }}
              />
              <FormControlLabel
                value={false} // str
                control={<Radio />}
                label="동의하지 않습니다."
                sx={{ width: "240px" }}
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Box className="submit">
          <Button type="submit" variant="text" disabled={agreement === null}>
            저장
          </Button>
        </Box>
      </form>
    </Paper>
  );
}
