import { css } from "@emotion/react";

export const toastContainerStyle = css`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 9999;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 1rem;
  white-space: pre-line;
`;

export const toastStyle = css`
  width: 400px;

  .close-btn {
    margin: auto 0;
    min-width: 0;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;
