import { blue, red } from "@mui/material/colors";
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import moment from "moment";
import { DashboardReport, DASHBOARD_REPORT } from "src/types";
import { numberFormatter } from "src/utils/form-helper";
import PublisherRequestReport from "./PublisherRequestReport";
import PublisherRevenueReport from "./PublisherRevenueReport";

const PublisherDashboard = () => {
  return (
    <>
      <PublisherRequestReport />
      <PublisherRevenueReport />
    </>
  );
};

export const getColumnDefs = (type: DashboardReport, range?: string[]): (ColDef | ColGroupDef)[] =>
  range
    ? [
        {
          headerName: "일자",
          field: "name",
          minWidth: 250,
          pinned: true,
          cellStyle: (param) => {
            if (param.data.name === "총 합계") {
              return {
                color: red[500],
                background: "rgb(233,236,239,0.5)",
              };
            }
          },
        },
        ...Array.from(range).map((date, idx) => ({
          headerName: date,
          field: "data",
          colId: `${moment(date).day()}`,
          minWidth: 150,
          maxWidth: 150,
          type: "rightAligned",
          valueFormatter: (params: ValueFormatterParams) => {
            try {
              if (type === DASHBOARD_REPORT.REVENUE) {
                if (params.value > 0 && params.value < 1) {
                  return `$ ${numberFormatter(params.value, 2)}`;
                } else {
                  return `$ ${numberFormatter(params.value, 0)}`;
                }
              } else {
                return numberFormatter(params.value, 0);
              }
            } catch {
              return "-";
            }
          },
          valueGetter: (param: ValueGetterParams) => {
            return param.data.data[idx];
          },
          cellStyle: (params: CellClassParams) => {
            if (params.data.name === "총 합계") {
              return {
                background: "rgb(233,236,239,0.5)",
              };
            }
          },
        })),
        {
          headerName: "DOD",
          field: "dod",
          minWidth: 110,
          maxWidth: 110,
          type: "rightAligned",
          pinned: "right",
          valueFormatter: (params: ValueFormatterParams) => {
            try {
              if (Number.isNaN(Number.parseFloat(params.value))) {
                throw new Error("@value should be a number or string presenting a number.");
              }
              if (params.value > -1 && params.value < 1) {
                return `${Math.floor(Math.abs(params.value))} %`;
              } else {
                return `${numberFormatter(params.value, 0)} %`;
              }
            } catch {
              return "-";
            }
          },
          cellStyle: (params) => {
            const style: { [key: string]: string } = {
              color: red[500],
              background: "none",
              fontWeight: "bold",
            };
            if (params.value > -1 && params.value < 1) {
              style["color"] = "black";
            }
            if (params.value <= -1 && params.value > -20) {
              style["color"] = blue[500];
              style["background"] = "none";
              style["fontWeight"] = "bold";
            }
            if (params.data.name === "총 합계") {
              style["background"] = "rgb(233,236,239,0.5)";
            }
            if (params.value <= -20) {
              style["color"] = "black";
              style["background"] = red[50];
              style["fontWeight"] = "bold";
            }
            return style;
          },
        },
        {
          headerName: "WOW",
          field: "wow",
          minWidth: 110,
          maxWidth: 110,
          type: "rightAligned",
          pinned: "right",
          valueFormatter: (params: ValueFormatterParams) => {
            try {
              if (Number.isNaN(Number.parseFloat(params.value))) {
                throw new Error("@value should be a number or string presenting a number.");
              }
              if (params.value > -1 && params.value < 1) {
                return `${Math.floor(Math.abs(params.value))} %`;
              } else {
                return `${numberFormatter(params.value, 0)} %`;
              }
            } catch {
              return "-";
            }
          },
          cellStyle: (params: CellClassParams) => {
            const style: { [key: string]: string } = {
              color: red[500],
              background: "none",
              fontWeight: "bold",
            };
            if (params.value > -1 && params.value < 1) {
              style["color"] = "black";
            }
            if (params.value <= -1 && params.value > -20) {
              style["color"] = blue[500];
              style["background"] = "none";
              style["fontWeight"] = "bold";
            }
            if (params.data.name === "총 합계") {
              style["background"] = "rgb(233,236,239,0.5)";
            }
            if (params.value <= -20) {
              style["color"] = "black";
              style["background"] = red[50];
              style["fontWeight"] = "bold";
            }
            return style;
          },
        },
      ]
    : [];

export default PublisherDashboard;
