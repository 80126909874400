import { Container, Paper, Tab, Tabs, Typography } from "@mui/material";
import { SyntheticEvent, useCallback } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const NpayLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleChangeValue = useCallback(
    (_: SyntheticEvent, value: string) => {
      navigate(value);
    },
    [navigate]
  );

  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        네이버페이 관리
      </Typography>
      <Paper className="content" elevation={2}>
        <Tabs sx={{ mb: "2rem" }} value={pathname} onChange={handleChangeValue}>
          <Tab label="적립 앱 관리" value="/operation/npay/apps" />
          <Tab label="플레이스먼트 설정" value="/operation/npay/placements" />
          <Tab label="CS 관리" value="/operation/npay/cs" />
          <Tab label="거래 내역 확인" value="/operation/npay/transactions" />
          <Tab label="리포트" value="/operation/npay/report" />
          <Tab label="거래 탐지" value="/operation/npay/failed-transactions" />
        </Tabs>
        <Outlet />
      </Paper>
    </Container>
  );
};

export default NpayLayout;
