import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, FocusEvent, MouseEvent, useCallback, useMemo } from "react";
import { AiOutlineHistory } from "react-icons/ai";
import { BsPlusSquare } from "react-icons/bs";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import { TextField } from "src/components/commons";
import useGetAdFormatInfo from "src/hooks/apis/info/useGetAdFormatInfo";
import useGetWebMediaList from "src/hooks/apis/media/useGetWebMediaList";
import useGetWebPlacementDetail from "src/hooks/apis/placements/useGetWebPlacementDetail";
import usePutWebPlacement from "src/hooks/apis/placements/usePutWebPlacement";
import useOpenModal from "src/hooks/useOpenModal";
import useUser from "src/hooks/useUser";
import { CAMPAIGN, STATUS, WEB_TAG, WEB_TAG_ALIAS } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import EditDspModal from "./EditDspModal";
import EditHeaderBiddingModal from "./EditHeaderBiddingModal";
import EditMediationModal from "./EditMediationModal";
import HistoryModal from "./HistoryModal";
import { placementModalStyle } from "./styles";

interface EditPlacementModalProps {
  onClose: () => void;
  open: { key: string; isOpen: boolean };
}

const EditPlacementModal = ({ onClose, open }: EditPlacementModalProps) => {
  const { isAdmin } = useUser();
  const [openHistoryModal, onShowHistory, onCloseHistoryModal] = useOpenModal<string>("");
  const [openDspModal, onShowEditDsp, onCloseEditDspModal] = useOpenModal<string>("");
  const [openHeaderBiddingModal, onShowEditHeaderBidding, onCloseEditHeaderBiddingModal] =
    useOpenModal<string>("");
  const [openMediationModal, onShowEditMediation, onCloseEditMediationModal] =
    useOpenModal<string>("");

  const [company] = useRecoilState(viewerCompany);
  const { data: placementData } = useGetWebPlacementDetail({ placementId: open.key });
  const { data: mediaData } = useGetWebMediaList({ companyKey: company.key });
  const { data: adFormatData } = useGetAdFormatInfo({ types: [CAMPAIGN.WebBanner] });
  const { mutate } = usePutWebPlacement();

  const initialValues = useMemo(() => {
    const { placement } = placementData;
    const { media } = mediaData;
    return {
      name: placement.name,
      id: placement.id,
      mediaKey: placement.media_key,
      mediaName:
        media.find((media) => media.key === placement.media_key)?.name || placement.media_name,
      tagType: placement.tag_type,
      sizeType: adFormatData.formats.find((format) => format.id === placement.size_type)?.label,
      bidfloorStatus: placement.bidfloor_status === STATUS.ACTIVE ? true : false,
      bidfloor: placement.bidfloor,
      cpcBidfloorStatus: placement.cpc_bidfloor_status === STATUS.ACTIVE ? true : false,
      cpcBidfloor: placement.cpc_bidfloor,
    };
  }, [placementData, mediaData, adFormatData.formats]);

  const validationSchema = yup.object({
    name: yup.string().required("플레이스먼트 명을 입력해주세요."),
    bidfloorStatus: yup.boolean(),
    bidfloor: yup.number().when("bidfloorStatus", {
      is: true,
      then: yup.number().required("값을 입력해주세요.").moreThan(0, "값을 입력해주세요."),
    }),
    cpcBidfloorStatus: yup.boolean(),
    cpcBidfloor: yup.number().when("cpcBidfloorStatus", {
      is: true,
      then: yup.number().required("값을 입력해주세요.").moreThan(0, "값을 입력해주세요."),
    }),
    checkViewability: yup.boolean(),
  });

  const { getFieldProps, handleSubmit, touched, errors, setFieldValue, values, handleBlur } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: (values) => {
        const { placement } = placementData;
        mutate({
          // 기존 placement 정보
          placementId: open.key,
          bidfloorCurrency: placement.bidfloor_currency,
          cpcBidfloorCurrency: placement.cpc_bidfloor_currency,
          mediaKey: placement.media_key,
          sizeType: placement.size_type,
          tagType: placement.tag_type,
          // 수정한 placement 정보
          name: values.name,
          bidfloor: +values.bidfloor,
          bidfloorStatus: values.bidfloorStatus ? STATUS.ACTIVE : STATUS.SUSPEND,
          cpcBidfloor: +values.cpcBidfloor,
          cpcBidfloorStatus: values.cpcBidfloorStatus ? STATUS.ACTIVE : STATUS.SUSPEND,
        });
        onClose();
      },
    });

  const onShowHistoryModal = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      onShowHistory(e, placementData.placement.id);
    },
    [onShowHistory, placementData.placement.id]
  );

  const onShowEditDspModal = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      onShowEditDsp(e, placementData.placement.id);
    },
    [onShowEditDsp, placementData.placement.id]
  );

  const onShowEditMediationModal = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      onShowEditMediation(e, placementData.placement.id);
    },
    [onShowEditMediation, placementData.placement.id]
  );

  const onShowEditHeaderBiddingModal = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      onShowEditHeaderBidding(e, placementData.placement.id);
    },
    [onShowEditHeaderBidding, placementData.placement.id]
  );

  const onChangeBidfloor = useCallback(
    (field: "bidfloor" | "cpcBidfloor") => (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replace(
        REG_EXP.bidfloor,
        e.target.value.substring(0, e.target.value.length - 1)
      );
      if (+(value || 0) < 1000000) {
        setFieldValue(field, value);
      }
    },
    [setFieldValue]
  );

  const onBlurBidfloor = useCallback(
    (field: "bidfloor" | "cpcBidfloor") => (e: FocusEvent) => {
      setFieldValue(field, parseFloat(`${values[field] || 0}`));
      handleBlur(e);
    },
    [handleBlur, setFieldValue, values]
  );

  const onClickScriptDownload = useCallback(() => {
    const { id, name, custom_id, media_key } = placementData.placement;
    const data = {
      [WEB_TAG.IFRAME]: {
        blob: `<!DOCTYPE html><html lang="en"><head><script async data-publisher-id="${media_key}" src="//ssp.igaw.io/sdk/js/headerbidding.js" type="text/javascript"></script></head><body><div id="div-igaw-ad-${id}"></div></body></html>`,
        filename: `[IFRAME]${name}_${id}${custom_id ? `(${custom_id})` : ""}.html`,
      },
      [WEB_TAG.SCRIPT]: {
        blob: `<script async data-publisher-id="${media_key}" src="//ssp.igaw.io/sdk/js/headerbidding.js" type="text/javascript"></script><div id="div-igaw-ad-${id}"></div>`,
        filename: `[JSSCRIPT]${name}_${id}${custom_id ? `(${custom_id})` : ""}.html`,
      },
    };
    const blob = new Blob([data[values.tagType].blob], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = data[values.tagType].filename;
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  }, [placementData.placement, values.tagType]);

  return (
    <Dialog
      css={placementModalStyle}
      fullWidth
      maxWidth="md"
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-placement"
      aria-describedby="edit placement"
    >
      <DialogTitle id="dialog-title">{placementData.placement.name}</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="edit-form" onSubmit={handleSubmit}>
          <Grid container mt={1} spacing={2}>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="매체 명"
                required
                disabled
                {...getFieldProps("mediaName")}
                error={shouldErrorShows("mediaName", touched, errors)}
                helperText={getHelperText("mediaName", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="매체 키"
                required
                disabled
                {...getFieldProps("mediaKey")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="플레이스먼트 명"
                placeholder="플레이스먼트 명을 입력하세요."
                required
                {...getFieldProps("name")}
                error={shouldErrorShows("name", touched, errors)}
                helperText={getHelperText("name", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="플레이스먼트 ID"
                required
                disabled
                {...getFieldProps("id")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="광고 형식"
                placeholder="광고 형식을 설정해주세요."
                disabled
                {...getFieldProps("sizeType")}
              />
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="space-between">
              <RadioGroup
                row
                className="field"
                aria-labelledby="tag-group-label"
                {...getFieldProps("tagType")}
                onChange={(e, v) => {
                  e.preventDefault();
                  setFieldValue("tagType", +v);
                }}
              >
                <FormControlLabel
                  value={WEB_TAG.IFRAME}
                  control={<Radio />}
                  label={WEB_TAG_ALIAS[WEB_TAG.IFRAME]}
                />
                <FormControlLabel
                  value={WEB_TAG.SCRIPT}
                  control={<Radio />}
                  label={WEB_TAG_ALIAS[WEB_TAG.SCRIPT]}
                />
              </RadioGroup>
              <Button variant="text" onClick={onClickScriptDownload}>
                태그 다운로드
              </Button>
            </Grid>
          </Grid>
          <Grid container mt={1} spacing={2}>
            <Grid item xs={4}>
              <FormControlLabel
                className="switch"
                control={<Switch color="primary" />}
                label="BidFloor"
                labelPlacement="start"
                {...getFieldProps("bidfloorStatus")}
                checked={values.bidfloorStatus}
              />
            </Grid>
            <Grid item xs={2}>
              {values.bidfloorStatus && (
                <TextField
                  className="field"
                  required
                  prefix={
                    <span className="ssp-input-currency-prefix">
                      {placementData.placement.bidfloor_currency}
                    </span>
                  }
                  {...getFieldProps("bidfloor")}
                  onChange={onChangeBidfloor("bidfloor")}
                  onBlur={onBlurBidfloor("bidfloor")}
                  error={shouldErrorShows("bidfloor", touched, errors)}
                  helperText={getHelperText("bidfloor", touched, errors)}
                />
              )}
            </Grid>
            {isAdmin && (
              <Grid item xs={6} className="remote-tools">
                <Button variant="contained" size="small" onClick={onShowEditDspModal}>
                  <BsPlusSquare />
                  DSP
                </Button>
                <Button variant="contained" size="small" onClick={onShowEditMediationModal}>
                  <BsPlusSquare />
                  미디에이션
                </Button>
              </Grid>
            )}
            {!isAdmin && (
              <Grid item xs={6} className="remote-tools">
                <Button variant="outlined" size="small" onClick={onShowHistoryModal}>
                  <AiOutlineHistory />
                  히스토리
                </Button>
              </Grid>
            )}
            {isAdmin && (
              <>
                {/* <Grid item xs={4}>
                  <FormControlLabel
                    className="switch"
                    control={<Switch color="primary" />}
                    label="CPC BidFloor"
                    labelPlacement="start"
                    {...getFieldProps("cpcBidfloorStatus")}
                    checked={values.cpcBidfloorStatus}
                  />
                </Grid>
                <Grid item xs={2}>
                  {values.cpcBidfloorStatus && (
                    <TextField
                      className="field"
                      required
                      prefix={
                        <span className="ssp-input-currency-prefix">
                          {placementData.placement.cpc_bidfloor_currency}
                        </span>
                      }
                      {...getFieldProps("cpcBidfloor")}
                      onChange={onChangeBidfloor("cpcBidfloor")}
                      error={shouldErrorShows("cpcBidfloor", touched, errors)}
                      helperText={getHelperText("cpcBidfloor", touched, errors)}
                    />
                  )}
                </Grid> */}
                <Grid item xs={6} className="remote-tools">
                  <Button variant="contained" size="small" onClick={onShowEditHeaderBiddingModal}>
                    <BsPlusSquare />
                    헤더비딩
                  </Button>
                  <Button variant="outlined" size="small" onClick={onShowHistoryModal}>
                    <AiOutlineHistory />
                    히스토리
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-form" type="submit">
          저장
        </Button>
      </DialogActions>
      {openHistoryModal.isOpen && (
        <HistoryModal open={openHistoryModal} onClose={onCloseHistoryModal} />
      )}
      {openDspModal.isOpen && <EditDspModal open={openDspModal} onClose={onCloseEditDspModal} />}
      {openMediationModal.isOpen && (
        <EditMediationModal
          isWebMediation={placementData.placement.is_web_mediation}
          open={openMediationModal}
          onClose={onCloseEditMediationModal}
        />
      )}
      {openHeaderBiddingModal.isOpen && (
        <EditHeaderBiddingModal
          open={openHeaderBiddingModal}
          onClose={onCloseEditHeaderBiddingModal}
        />
      )}
    </Dialog>
  );
};

export default EditPlacementModal;
