import { ROLE, UserRole } from "src/types";
import { Authority, AuthorityAreaRWD, AUTHORITY_AREA, AUTH_TYPE } from "src/types/authority";

export const initAuthority: Authority = {
  id: 0,
  type: AUTH_TYPE.DEFAULT,
  home: {
    dashboard: "000",
    adm_dashboard: "000",
    ent_dashboard: "000",
    syndication: "000",
  },
  app: {
    home: "000",
    media: "000",
    placement: "000",
    block_domain: "000",
  },
  web: {
    home: "000",
    media: "000",
    placement: "000",
    block_domain: "000",
  },
  report: {
    app: "000",
    web: "000",
    partners: "000",
  },
  settlement: {
    dashboard: "000",
    tx_history: "000",
    setting: "000",
  },
  setting: {
    manager: "000",
    demo_test: "000",
    account: "000",
    members: "000",
    company: "000",
  },
  operation: "000",
};

export const initAuthorityRWD: AuthorityAreaRWD = {
  app: {
    [AUTHORITY_AREA.blockDomain]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.home]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.media]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.placement]: {
      read: false,
      write: false,
      delete: false,
    },
  },
  home: {
    [AUTHORITY_AREA.adminDashboard]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.dashboard]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.enterpriseDashboard]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.syndication]: {
      read: false,
      write: false,
      delete: false,
    },
  },
  operation: {
    read: false,
    write: false,
    delete: false,
  },
  report: {
    [AUTHORITY_AREA.app]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.web]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.partners]: {
      read: false,
      write: false,
      delete: false,
    },
  },
  setting: {
    [AUTHORITY_AREA.account]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.company]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.demoTest]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.manager]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.members]: {
      read: false,
      write: false,
      delete: false,
    },
  },
  settlement: {
    [AUTHORITY_AREA.dashboard]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.setting]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.transactionHistory]: {
      read: false,
      write: false,
      delete: false,
    },
  },
  web: {
    [AUTHORITY_AREA.blockDomain]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.home]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.media]: {
      read: false,
      write: false,
      delete: false,
    },
    [AUTHORITY_AREA.placement]: {
      read: false,
      write: false,
      delete: false,
    },
  },
};

const setAuthority = (value: Permission) => {
  const bool: Record<string, boolean> = { "0": false, "1": true };
  return {
    read: bool[value[0]],
    write: bool[value[1]],
    delete: bool[value[2]],
  };
};

export const createAuthority = <T>(authorityObj: T) => {
  if (!authorityObj || typeof authorityObj !== "object") return initAuthorityRWD;
  return Object.entries(authorityObj).reduce((acc: Record<string, unknown>, [key, v]) => {
    if (!Object.values(AUTHORITY_AREA).some((v) => v === key)) {
      return acc;
    }
    if (v !== null && typeof v === "object") {
      const value = v as Record<string, Permission>;
      acc[key] = createAuthority(value);
    } else {
      const value = v as Permission;
      acc[key] = setAuthority(value);
    }
    return acc;
  }, {}) as AuthorityAreaRWD;
};

// 하위 역할을 가져옴.
export const getSubRoles = (role: UserRole) => {
  switch (role) {
    // IGAWorks 관리자
    case ROLE.IGAWorksAdministrator:
      return [
        ROLE.IGAWorksAdministrator,
        ROLE.IGAWorksOperator,
        ROLE.PublisherAdministrator,
        ROLE.PublisherOperator,
        ROLE.SyndicationAdministrator,
        ROLE.SyndicationOperator,
        ROLE.SyndicationClient,
      ];
    // IGAWorks 운영자
    case ROLE.IGAWorksOperator:
      return [
        ROLE.IGAWorksOperator,
        ROLE.PublisherAdministrator,
        ROLE.PublisherOperator,
        ROLE.SyndicationAdministrator,
        ROLE.SyndicationOperator,
        ROLE.SyndicationClient,
      ];
    // 퍼블리셔 관리자
    case ROLE.PublisherAdministrator:
      return [ROLE.PublisherOperator];
    // 퍼블리셔 운영자
    case ROLE.PublisherOperator:
      return [ROLE.PublisherOperator];
    // 신디케이션 관리자
    case ROLE.SyndicationAdministrator:
      return [ROLE.SyndicationAdministrator, ROLE.SyndicationOperator, ROLE.SyndicationClient];
    // 신디케이션 운영자
    case ROLE.SyndicationOperator:
      return [ROLE.SyndicationOperator, ROLE.SyndicationClient];
    // 신디케이션 고객사
    case ROLE.SyndicationClient:
      return [ROLE.SyndicationClient];
    default:
      return [];
  }
};
