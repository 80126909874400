import BlockManagement from "./BlockManagement";

const WebBlocklist = () => {
  return (
    <>
      <BlockManagement />
    </>
  );
};

export default WebBlocklist;
