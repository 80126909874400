import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  partnerNo: number;
}

interface Response {
  code: 204 | 400 | 401 | 404 | 422;
  text:
    | "Successful Response"
    | "bad-request"
    | "unauthorized"
    | "not-found"
    | "unprocessable-entity";
}

// 쿠팡 파트너 삭제
const useDeletePartner = () => {
  const toast = useToast();
  const { handleError } = useApiError({
    400: (_error: AxiosError) =>
      toast.error(`해당 파트너에 Sub ID가 존재합니다.
      Sub ID를 삭제해주세요.`),
    403: (_error: AxiosError) =>
      toast.error(`해당 파트너에 Sub ID가 존재합니다.
      Sub ID를 삭제해주세요.`),
  });
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ partnerNo }) => {
      const response: AxiosResponse<Response> = await API.integration.delete(
        `/operation/management/coupang/partner/${partnerNo}`
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("파트너가 삭제되었습니다.");
        invalidate("coupang/useGetPartnerList");
      },
    }
  );

  return result;
};

export default useDeletePartner;
