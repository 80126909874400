import { Button, Grid } from "@mui/material";
import { CellClickedEvent, SortChangedEvent, ValueFormatterParams } from "ag-grid-community";
import { ICellRendererParams } from "ag-grid-enterprise";
import moment from "moment";
import qs from "qs";
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import DataFormat from "src/assets/formats";
import { BasicTable, DeleteButton, SearchField } from "src/components/commons";
import useDeleteCampaign from "src/hooks/apis/coupang/useDeleteCampaign";
import useGetCampaignList from "src/hooks/apis/coupang/useGetCampaignList";
import useOpenModal from "src/hooks/useOpenModal";
import AddCampaignModal from "./AddCampaignModal";
import EditCampaignModal from "./EditCampaignModal";

const CampaignBoard = () => {
  const { search: queryString } = useLocation();
  const [search, setSearch] = useState({ temp: "", value: "" });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [orders, setOrders] = useState<string[]>([]);
  const [openAddModal, onShowAddModal, onCloseAddModal] = useOpenModal(null);
  const [openEditModal, onShowEdit, onCloseEditModal] = useOpenModal("");

  const { data } = useGetCampaignList({ search: search.value, pageNo, pageSize, orders });

  // 초기 매체필터 데이터 세팅
  useEffect(() => {
    if (queryString) {
      const queryData = qs.parse(queryString, { ignoreQueryPrefix: true });
      if (typeof queryData.partner === "string")
        setSearch({ temp: queryData.partner || "", value: queryData.partner || "" });
    }
  }, [queryString]);

  const onChangeSearchWord = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearch({ ...search, temp: e.target.value });
    },
    [search]
  );

  const onSearchPartner = useCallback(() => {
    setSearch({ ...search, value: search.temp });
    setPageNo(1);
  }, [search]);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const onShowEditModal = useCallback(
    (e: CellClickedEvent) => {
      if (e.colDef.headerName !== "Delete") {
        onShowEdit(e, e.data.campaign_key);
      }
    },
    [onShowEdit]
  );

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, data.pages, onChangePage, pageSize, onChangePageSize]
  );

  const onSortChanged = useCallback((e: SortChangedEvent) => {
    const sortInfo = e.columnApi
      .getColumnState()
      .filter((column) => !!column.sort)
      .map(({ colId, sort }) => {
        if (sort === "desc") return `-${colId}`;
        return `+${colId}`;
      });
    setOrders(sortInfo);
  }, []);

  return (
    <>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={3}>
          <SearchField
            label="검색어"
            placeholder="업체명 / 파트너명 / 캠페인명"
            value={search.temp}
            onChange={onChangeSearchWord}
            onClickSearchButton={onSearchPartner}
          />
        </Grid>
        <Grid item xs={9} className="register">
          <Button variant="outlined" onClick={onShowAddModal}>
            신규 캠페인 등록
          </Button>
        </Grid>
      </Grid>
      <BasicTable
        animateRows
        rowData={data.campaigns}
        columnDefs={columnDefs}
        onSortChanged={onSortChanged}
        onCellClicked={onShowEditModal}
        {...paginationInfo}
      />
      {openAddModal.isOpen && (
        <AddCampaignModal isOpen={openAddModal.isOpen} onClose={onCloseAddModal} />
      )}
      {openEditModal.isOpen && (
        <EditCampaignModal open={openEditModal} onClose={onCloseEditModal} />
      )}
    </>
  );
};

const columnDefs = [
  { headerName: "캠페인명", field: "campaign_name", sortable: true },
  { headerName: "파트너명", field: "partner_name", sortable: true },
  {
    headerName: "이벤트 최소 금액",
    field: "trigger_revenue",
    sortable: true,
    valueFormatter: (params: ValueFormatterParams) => {
      return `${DataFormat.purchaseRevenue.formatter(params.value)}`;
    },
  },
  {
    headerName: "캠페인 생성 일시",
    field: "regist_datetime",
    sortable: true,
    valueFormatter: (params: ValueFormatterParams) => {
      return moment(params.value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    headerName: "Delete",
    field: "campaign_key",
    sortable: true,
    cellRenderer: (params: ICellRendererParams) => (
      <RemoveButton campaignKey={params.data.campaign_key} />
    ),
  },
];

function RemoveButton({ campaignKey }: { campaignKey: string }) {
  const { mutate } = useDeleteCampaign();

  const onClickDeleteButton = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      mutate({ campaignKey });
    },
    [campaignKey, mutate]
  );

  return (
    <DeleteButton onConfirm={onClickDeleteButton}>
      정말 해당 캠페인을 제거하시겠습니까?
    </DeleteButton>
  );
}

export default CampaignBoard;
