import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import moment, { Moment } from "moment";
import {
  MouseEvent,
  MouseEventHandler,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { DASHBOARD_LABEL } from "src/assets/alias/dashboard";
import DataFormat from "src/assets/formats";
import { DateField } from "src/components/commons";
import useGetAdminDashboard from "src/hooks/apis/dashboard/useGetAdminDashboard";
import { Media, MEDIA, MEDIA_ALIAS } from "src/types";
import PlacementChart from "./PlacementChart";
import PlatformChart from "./PlatformChart";
import { cardStyle, chartStyle, dashboardStyle } from "./styles";

export type DataKey = "request" | "response" | "impression" | "click" | "ecpm" | "rpr" | "revenue";

const Dashboard = () => {
  const [type, setType] = useState<Media>(MEDIA.APP);

  const [since, setSince] = useState({
    temp: moment().subtract(7, "d"),
    current: moment().subtract(7, "d"),
  });

  const [until, setUntil] = useState({
    temp: moment().subtract(1, "d"),
    current: moment().subtract(1, "d"),
  });

  const [dataKey, setDataKey] = useState<DataKey>("revenue");
  const { data } = useGetAdminDashboard({ type, since: since.current, until: until.current });

  const onChangeTab = useCallback((e: SyntheticEvent<Element, Event>, value: Media) => {
    e.preventDefault();
    setType(value);
  }, []);

  const onChangeSinceDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setSince((prev) => ({ ...prev, temp: value }));
    }
    return;
  }, []);

  const onChangeUntilDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setUntil((prev) => ({ ...prev, temp: value }));
    }
    return;
  }, []);

  const onClickSearch = useCallback<MouseEventHandler>(() => {
    setSince((prev) => ({ ...prev, current: prev.temp }));
    setUntil((prev) => ({ ...prev, current: prev.temp }));
  }, []);

  const onClickCard = (value: DataKey) => (e: MouseEvent) => {
    e.preventDefault();
    setDataKey(value);
  };

  const totalData: { key: DataKey; label: string; value: string }[] = useMemo(() => {
    const { click, ecpm, impression, request, response, revenue, rpr } = data.total;
    return [
      {
        key: "request",
        label: DASHBOARD_LABEL["request"],
        value: DataFormat.request.formatter(request),
      },
      {
        key: "response",
        label: DASHBOARD_LABEL["response"],
        value: DataFormat.response.formatter(response),
      },
      {
        key: "impression",
        label: DASHBOARD_LABEL["impression"],
        value: DataFormat.impression.formatter(impression),
      },
      {
        key: "click",
        label: DASHBOARD_LABEL["click"],
        value: DataFormat.click.formatter(click),
      },
      {
        key: "ecpm",
        label: DASHBOARD_LABEL["ecpm"],
        value: DataFormat.ecpm.formatter(ecpm),
      },
      {
        key: "rpr",
        label: DASHBOARD_LABEL["rpr"],
        value: DataFormat.rpr.formatter(rpr),
      },
      {
        key: "revenue",
        label: DASHBOARD_LABEL["revenue"] + "(TWDD)",
        value: `${DataFormat.revenue.formatter(revenue)} (${DataFormat.revenue.formatter(
          data.twdd_revenue
        )})`,
      },
    ];
  }, [data.total, data.twdd_revenue]);

  return (
    <Container css={dashboardStyle} component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        대시보드
      </Typography>
      <Paper className="content" elevation={2}>
        <Tabs className="tabs" value={type} onChange={onChangeTab}>
          <Tab label={MEDIA_ALIAS[MEDIA.APP]} value={MEDIA.APP} />
          <Tab label={MEDIA_ALIAS[MEDIA.WEB]} value={MEDIA.WEB} />
        </Tabs>
        <Grid container className="ssp-tools" spacing={2}>
          <Grid item xs={4}>
            <DateField
              label="시작일"
              value={since.temp}
              maxDate={until.temp}
              onChange={onChangeSinceDate}
            />
          </Grid>
          <Grid item xs={4}>
            <DateField
              label="종료일"
              value={until.temp}
              minDate={since.temp}
              maxDate={moment().subtract(1, "d")}
              onChange={onChangeUntilDate}
            />
          </Grid>
          <Grid item xs={2}>
            <Button type="button" variant="contained" onClick={onClickSearch}>
              검색
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {totalData.map(({ key, label, value }, i) => (
            <Grid key={label} item xs={i < 4 ? 3 : 4}>
              <Card css={cardStyle} elevation={2} onClick={onClickCard(key)}>
                <CardHeader className="card-title" title={label} />
                <CardContent>
                  <Typography className="card-content" variant="h5" gutterBottom>
                    {value}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} css={chartStyle}>
            <PlatformChart type={type} data={data?.platform} dataKey={dataKey} />
          </Grid>
          <Grid item xs={6} css={chartStyle}>
            <PlacementChart data={data?.report} dataKey={dataKey} />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Dashboard;
