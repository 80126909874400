import { Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import { ICellRendererParams, ValueFormatterParams } from "ag-grid-enterprise";
import moment, { Moment } from "moment";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { BasicTable, DateField, SearchField, Select } from "src/components/commons";
import useGetCustomerServiceList from "src/hooks/apis/coupang/useGetCustomerServiceList";
import { AppPlatform, APP_PLATFORM, APP_PLATFORM_ALIAS, Status, STATUS } from "src/types";
import { serviceLinkStyle } from "./styles";

const ServiceBoard = () => {
  const [status, setStatus] = useState<Status>(STATUS.REJECTED);
  const [platform, setPlatform] = useState<AppPlatform>(APP_PLATFORM.ALL);
  const [search, setSearch] = useState({ temp: "", value: "" });
  const [since, setSince] = useState(moment().subtract(7, "d"));
  const [until, setUntil] = useState(moment().subtract(1, "d"));
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { data } = useGetCustomerServiceList({
    status,
    platform,
    since,
    until,
    search: search.value,
    pageNo,
    pageSize,
  });

  const onChangeStatus = useCallback((event: SelectChangeEvent<Status>) => {
    const value = +event.target.value as Status;
    setStatus(value);
  }, []);

  const onChangePlatform = useCallback((event: SelectChangeEvent<AppPlatform>) => {
    const value = +event.target.value as AppPlatform;
    setPlatform(value);
  }, []);

  const onChangeSearchWord = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearch({ ...search, temp: e.target.value });
    },
    [search]
  );

  const onSearchCS = useCallback(() => {
    setSearch({ ...search, value: search.temp });
    setPageNo(1);
  }, [search]);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, data.pages, onChangePage, pageSize, onChangePageSize]
  );

  const onChangeSinceDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setSince(value);
    }
    return;
  }, []);

  const onChangeUntilDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setUntil(value);
    }
    return;
  }, []);

  return (
    <>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={2}>
          <Select
            label="상태"
            placeholder="상태를 선택해주세요."
            onChange={onChangeStatus}
            value={status}
          >
            <MenuItem value={STATUS.REJECTED}>전체</MenuItem>
            <MenuItem value={STATUS.ACTIVE}>읽지 않음</MenuItem>
            <MenuItem value={STATUS.SUSPEND}>읽음</MenuItem>
            <MenuItem value={STATUS.DELETED}>답변 완료</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2}>
          <Select
            label="플랫폼"
            placeholder="플랫폼을 선택해주세요."
            onChange={onChangePlatform}
            value={platform}
          >
            <MenuItem value={APP_PLATFORM.ALL}>{APP_PLATFORM_ALIAS[APP_PLATFORM.ALL]}</MenuItem>
            <MenuItem value={APP_PLATFORM.ANDROID}>
              {APP_PLATFORM_ALIAS[APP_PLATFORM.ANDROID]}
            </MenuItem>
            <MenuItem value={APP_PLATFORM.iOS}>{APP_PLATFORM_ALIAS[APP_PLATFORM.iOS]}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2.5}>
          <DateField label="시작일" value={since} maxDate={until} onChange={onChangeSinceDate} />
        </Grid>
        <Grid item xs={2.5}>
          <DateField
            label="종료일"
            value={until}
            minDate={since}
            maxDate={moment().subtract(1, "d")}
            onChange={onChangeUntilDate}
          />
        </Grid>
        <Grid item xs={3}>
          <SearchField
            label="검색어"
            placeholder="검색어를 입력해주세요."
            value={search.temp}
            onChange={onChangeSearchWord}
            onClickSearchButton={onSearchCS}
          />
        </Grid>
      </Grid>
      <BasicTable rowData={data.questions} columnDefs={columnDefs} {...paginationInfo} />
    </>
  );
};

const columnDefs = [
  { headerName: "NO", field: "cs_no", sortable: true, minWidth: 75 },
  {
    headerName: "문의 일자",
    field: "regist_datetime",
    sortable: true,
    minWidth: 120,
    valueFormmater: (params: ValueFormatterParams) => {
      return moment(params.value * 1000).format("YYYY-MM-DD");
    },
  },
  {
    headerName: "캠페인명",
    field: "campaign_name",
    sortable: true,
    minWidth: 150,
  },
  {
    headerName: "USN",
    field: "usn",
    sortable: true,
    minWidth: 200,
  },
  {
    headerName: "Email",
    field: "email",
    sortable: true,
    minWidth: 200,
  },
  {
    headerName: "상태",
    field: "status",
    sortable: true,
    minWidth: 180,
    valueFormatter: (params: ValueFormatterParams) => {
      const status = params.value as Status;
      if (status === STATUS.ACTIVE) return "읽지 않음";
      if (status === STATUS.SUSPEND) return "읽음";
      if (status === STATUS.DELETED) return "답변완료";
      return "";
    },
  },
  {
    headerName: "USN",
    field: "usn",
    sortable: true,
    minWidth: 200,
  },

  {
    headerName: "문의내용",
    field: "cs_message",
    sortable: true,
    minWidth: 300,
    cellRenderer: (params: ICellRendererParams) => {
      const { cs_no, cs_message } = params.data;
      return (
        <Link css={serviceLinkStyle} to={`/operation/coupang/service/detail/${cs_no}`}>
          {cs_message}
        </Link>
      );
    },
  },
];

export default ServiceBoard;
