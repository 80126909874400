import { AxiosError, AxiosResponse } from "axios";
import moment, { Moment } from "moment";
import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { DashboardReport, DASHBOARD_REPORT, Media } from "src/types";
import API from "src/utils/api";

interface Params {
  mediaType: Media;
  reportType: DashboardReport;
  date?: Moment;
  count?: number;
}

interface Response {
  code: 200 | 401;
  data?: {
    media_type: Media;
    orders: {
      key: string;
      name: string;
      rank: number;
    }[];
    report: {
      advertise_cost: string;
      media_cost: string;
      media_key: string;
      request_value: number;
      ymd: string;
    }[];
    report_type: DashboardReport;
  };
  text: "ok" | "unauthorized";
}

// 특정일자 기준 상위 20개 매체의 최근 일주일 요청수 및 수익 레포트
const useGetAdminTopLevel = ({ mediaType, reportType, date, count }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    [`dashboard/useGetAdminTopLevel`, mediaType, reportType, date, count],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/dashboard/admin/top`, {
        params: {
          date: date?.format("YYYY-MM-DD"),
          count,
          media_type: mediaType,
          report_type: reportType,
        },
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  // 날짜 세팅
  const range = useMemo(() => {
    const startDate = moment(date).subtract(7, "days");
    const arr = [];
    for (let d = moment(startDate); d.isSameOrBefore(date); d.add(1, "days")) {
      arr.push(d.format("YYYY-MM-DD"));
    }
    return arr;
  }, [date]);

  // DOD, WOW 계산기
  const calculator = useCallback((values: (number | null)[]) => {
    if (!values[0]) return null;
    if (values[1] === null) return null;
    return ((values[1] - values[0]) / values[0]) * 100;
  }, []);

  const result = useMemo(() => {
    if (data) {
      const key = reportType === DASHBOARD_REPORT.REQUEST ? "request_value" : "media_cost";

      const reportData = data.orders.map((media) => {
        const filteredData = data.report.filter((v) => v.media_key === media.key) || [];
        const now = range.map((date) => ({
          media_cost: null,
          request_value: null,
          ...filteredData.find((data) => moment(data.ymd).isSame(moment(date))),
        }));

        return {
          name: media.name ?? "",
          key: media.key,
          data: now.map((v) => +(v[key] || 0)),
          total: now.reduce((acc, cur) => acc + +(cur[key] || 0), 0),
          dod: calculator(now.map((v) => +(v[key] || 0)).slice(-2)),
          wow: calculator([+(now[0][key] || 0), +(now[now.length - 1][key] || 0)]),
        };
      });

      // 리포트 데이터 정렬
      reportData.sort(({ data: adata }: { data: number[] }, { data: bdata }: { data: number[] }) =>
        (adata.slice(-1)[0] ?? 0) > (bdata.slice(-1)[0] ?? 0)
          ? -1
          : (adata.slice(-1)[0] ?? 0) < (bdata.slice(-1)[0] ?? 0)
          ? 1
          : 0
      );

      const publisherTotalData = reportData.reduce(
        (acc, cur) => acc.map((v, idx) => v + (cur.data[idx] ?? 0)),
        Array.from(range).map(() => 0)
      );

      const publisherTotal = {
        name: "총 합계",
        key: "",
        data: reportData.reduce(
          (acc, cur) => acc.map((v, idx) => v + (cur.data[idx] ?? 0)),
          Array.from(range).map(() => 0)
        ),
        total: 0,
        dod: calculator(publisherTotalData.slice(-2)),
        wow: calculator([publisherTotalData[0], publisherTotalData[publisherTotalData.length - 1]]),
      };

      return { data: reportData, range, total: publisherTotal };
    }

    return { data: undefined, range: undefined, total: undefined };
  }, [calculator, data, range, reportType]);

  return { ...result, ...rest };
};

export default useGetAdminTopLevel;
