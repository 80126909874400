import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { TextField } from "src/components/commons";
import usePostSignIn from "src/hooks/apis/auth/usePostSignIn";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { signInFormStyle } from "./styles";

const SignInForm = () => {
  const { mutate, error: signInError } = usePostSignIn();
  const cookie = parseCookies();

  const initialValues = useMemo(
    () => ({
      email: cookie["user_id"] || "",
      password: "",
      remember: !!cookie["user_id"],
    }),
    [cookie]
  );

  const validationSchema = yup.object({
    email: yup
      .string()
      .matches(REG_EXP.email, "이메일을 확인해 주세요.")
      .required("이메일을 입력해 주세요."),
    password: yup.string().required("비밀번호를 입력해 주세요."),
  });

  const { getFieldProps, handleSubmit, errors, touched, values, setFieldError } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values.remember) {
        setCookie(null, "user_id", values.email, {
          path: "/",
          expires: moment().add(1, "y").toDate(),
        });
      } else {
        destroyCookie(null, "user_id", { path: "/" });
      }
      mutate({ email: values.email, password: values.password });
    },
  });

  useEffect(() => {
    if (signInError?.response?.status === 403) {
      setFieldError("password", "비밀번호가 다릅니다.");
    }
  }, [setFieldError, signInError]);

  return (
    <section className="login-form-section" css={signInFormStyle}>
      <div className="form-box">
        <h2 className="title">Login</h2>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <TextField
                type="email"
                label="Email"
                variant="standard"
                placeholder="Email"
                {...getFieldProps("email")}
                error={shouldErrorShows("email", touched, errors)}
                helperText={getHelperText("email", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                label="Password"
                variant="standard"
                placeholder="Password"
                {...getFieldProps("password")}
                error={shouldErrorShows("password", touched, errors)}
                helperText={getHelperText("password", touched, errors)}
              />
            </Grid>
          </Grid>
          <FormControlLabel
            label="Remember me"
            className="remember"
            control={<Checkbox {...getFieldProps("remember")} checked={values.remember} />}
          />
          <Button className="submit" type="submit" variant="outlined">
            Sign In
          </Button>
          <div className="sign-up">
            <span>{`Don't have an account?`}</span>
            <Link to="/login/register">Sign Up</Link>
          </div>
          <Box className="forgot">
            <Link to="/login/find/email">Forgot Email?</Link>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Link to="/login/find/password">Forgot Password?</Link>
          </Box>
        </form>
        <Box className="footer">
          <MuiLink
            href="https://www.adpopcorn.com/policy/media"
            target="_blank"
            rel="noopener noreferrer"
          >
            서비스 이용약관
          </MuiLink>
          <Divider orientation="vertical" variant="middle" flexItem />
          <MuiLink
            href="https://www.adpopcorn.com/policy/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            개인정보처리방침
          </MuiLink>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Typography variant="body1" className="copyright">
            © 2021 adpopcorn
          </Typography>
        </Box>
      </div>
    </section>
  );
};

export default SignInForm;
