import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Header } from "src/components/commons";
import { Footer } from "../Footer";
import { mainStyle } from "./styles";
import Uid2Agreement from "../Uid2Agreement";

const Layout: React.FC = () => {
  return (
    <>
      <Header />
      <main css={mainStyle}>
        <Container className="container" maxWidth="xl">
          <Outlet />
        </Container>
      </main>
      <Footer />
      <Uid2Agreement />
    </>
  );
};

export default Layout;
