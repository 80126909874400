import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Media, Status, WebPlatform, WEB_PLATFORM } from "src/types";
import API from "src/utils/api";
import useGetUserDetail from "../users/useGetUserDetail";

interface Params {
  companyKey?: string;
  name?: string;
  status?: Status;
  platform?: WebPlatform;
  pageNo?: number;
  pageSize?: number;
  orders?: string[];
  disabled?: boolean;
}

export interface WebMedia {
  company_key: string;
  created_at: number;
  domain: string;
  key: string;
  name: string;
  placement_count: number;
  platform_type: WebPlatform;
  script_updated_at: number;
  status: Status;
  type: Media;
  updated_at: number;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    media: WebMedia[];
    pages: number;
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 웹 매체 리스트 조회
// 플레이스먼트는 갯수를 전달한다. (ID 리스트 전달 시 시간이 오래걸리거나 및 길이 초과로 데이터가 누락됨)
const useGetWebMediaList = ({
  companyKey,
  name,
  status,
  platform,
  pageNo = 1,
  pageSize = 100000,
  orders,
  disabled = false,
}: Params) => {
  const { handleError } = useApiError();
  const { data: userData } = useGetUserDetail({ userId: "me" });
  companyKey = companyKey || userData.user.company_key;
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    [
      "media/useGetWebMediaList",
      companyKey,
      name,
      status,
      pageNo,
      pageSize,
      platform,
      disabled,
      orders,
    ],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/media/web`, {
        params: {
          company_key: companyKey,
          name: name,
          status: status,
          platform_type:
            platform === WEB_PLATFORM.ALL
              ? [WEB_PLATFORM.PC, WEB_PLATFORM.MOBILE].join(",")
              : platform,
          page_no: pageNo,
          page_size: pageSize,
          orders: orders?.join(","),
        },
      });
      return response.data.data;
    },
    { enabled: !disabled && !!companyKey, onError: handleError }
  );

  const result = useMemo(() => (data ? data : { media: [], pages: 0 }), [data]);

  return { data: result, ...rest };
};

export default useGetWebMediaList;
