import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { STATUS, Status, WebTag, WEB_TAG } from "src/types";
import API from "src/utils/api";

interface Params {
  bidfloor?: number;
  bidfloorCurrency?: string;
  bidfloorStatus?: Status;
  cpcBidfloor?: number;
  cpcBidfloorCurrency?: string;
  cpcBidfloorStatus?: Status;
  customId?: string;
  mediaKey: string;
  name: string;
  sizeType?: number;
  tagType: WebTag;
}

interface Response {
  code: 200 | 400 | 401 | 409 | 500;
  data?: {
    placement: {
      bidfloor: number;
      bidfloor_currency: string;
      bidfloor_status: Status;
      cpc_bidfloor: number;
      cpc_bidfloor_currency: string;
      cpc_bidfloor_status: Status;
      created_at: number;
      custom_id: string;
      id: string;
      is_web_mediation: true;
      media_key: string;
      name: string;
      passback_script: string;
      size_type: number;
      status: Status;
      tag_type: WebTag;
      updated_at: number;
    };
  };
  text: "ok" | "bad-request" | "unauthorized" | "conflict" | "internal-server-error";
}

// 플레이스먼트 신규 생성
// 매체에 같은 이름의 플레이스먼트가 있을 경우 이름 뒤에 _{EpochTime}을 붙인다.
const usePostWebPlacement = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const { data, ...rest } = useMutation<Response, AxiosError, Params>(
    async ({
      bidfloor,
      bidfloorCurrency,
      bidfloorStatus,
      cpcBidfloor,
      cpcBidfloorCurrency,
      cpcBidfloorStatus,
      customId,
      mediaKey,
      name,
      sizeType,
      tagType,
    }) => {
      const response: AxiosResponse<Response> = await API.default.post(`/placements/web`, {
        bidfloor: bidfloor,
        bidfloor_currency: bidfloorCurrency,
        bidfloor_status: bidfloorStatus,
        cpc_bidfloor: cpcBidfloor,
        cpc_bidfloor_currency: cpcBidfloorCurrency,
        cpc_bidfloor_status: cpcBidfloorStatus,
        custom_id: customId,
        media_key: mediaKey,
        name: name,
        size_type: sizeType,
        tag_type: tagType,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("플레이스먼트가 등록되었습니다.");
        invalidate("placements/useGetWebPlacementList");
      },
      onError: handleError,
    }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            placement: {
              bidfloor: 0,
              bidfloor_currency: "",
              bidfloor_status: STATUS.SUSPEND,
              cpc_bidfloor: 0,
              cpc_bidfloor_currency: "",
              cpc_bidfloor_status: STATUS.SUSPEND,
              created_at: 0,
              custom_id: "",
              id: "",
              is_web_mediation: false,
              media_key: "",
              name: "",
              passback_script: "",
              size_type: 0,
              status: STATUS.SUSPEND,
              tag_type: WEB_TAG.IFRAME,
              updated_at: 0,
            },
          },
    [data]
  );

  return { data: result, ...rest };
};

export default usePostWebPlacement;
