import cx from "classnames";
import styles from "./styles";

const NotFound: React.FC = () => {
  return (
    <div css={styles}>
      <p className={cx("status")}>404</p>
      <p>페이지를 찾을 수 없습니다.</p>
    </div>
  );
};

export default NotFound;
