import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  placementId: string;
  thirdpartyId: number;
  matchKey: Record<string, unknown>;
}

interface Response {
  code: 200 | 400 | 401 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "internal-server-error";
}

// 서드파티 매칭 정보 등록 및 수정
// 이미 사용중인 키값이 있는 경우 409 오류와 해당 키값을 사용하는 매체명, 플레이스먼트명을 반환한다.
// {"code":409,"text":"registered-match-info","data":{"media_name":"foo","placement_name":"bar"}}
const usePutWebThirdparty = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ placementId, thirdpartyId, matchKey }) => {
      const response: AxiosResponse<Response> = await API.default.put(
        `/placements/web/${placementId}/thirdparties/${thirdpartyId}`,
        { match_key: matchKey }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("저장되었습니다.");
        invalidate("placements/useGetWebThirdpartyList");
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePutWebThirdparty;
