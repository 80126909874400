import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import { ChangeEvent, useCallback, useMemo } from "react";
import { TextField } from "src/components/commons";
import useGetUserDetail from "src/hooks/apis/users/useGetUserDetail";
import usePatchUser from "src/hooks/apis/users/usePatchUser";
import useOpenModal from "src/hooks/useOpenModal";
import { ROLE, ROLE_ALIAS } from "src/types";
import { getSubRoles } from "src/utils/auth-helper";
import { getHelperText, phoneFormatter, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import EditMemberRoleModal from "./EditMemberRoleModal";
import { addMemberModalStyle } from "./styles";

interface EditMemberModalProps {
  onClose: () => void;
  open: { key: number; isOpen: boolean };
}

const EditMemberModal = ({ open, onClose }: EditMemberModalProps) => {
  const [openEditRoleModal, onShowEditRoleModal, onCloseEditRoleModal] = useOpenModal(open.key);
  const { data: userData } = useGetUserDetail({ userId: "me" });
  const { data: memberData } = useGetUserDetail({ userId: open.key });
  const { mutate } = usePatchUser();

  // 로그인한 사용자 역할이 해당 역할을 수정할 수 있는지 체크
  const isEditiableRole = useMemo(
    () => getSubRoles(userData.user.role).some((role) => role === memberData.user.role),
    [memberData.user.role, userData.user.role]
  );

  const initialValues = useMemo(
    () => ({
      email: memberData.user.account_id || "",
      name: memberData.user.name || "",
      department: memberData.user.department || "",
      role: ROLE_ALIAS[memberData.user.role] || ROLE_ALIAS[ROLE.UNREGISTERED],
      phoneNo: phoneFormatter(memberData.user.phone_no || "") || "",
      createdAt: moment((memberData.user.created_at || 0) * 1000).format("YYYY-MM-DD"),
    }),
    [
      memberData.user.account_id,
      memberData.user.created_at,
      memberData.user.department,
      memberData.user.name,
      memberData.user.phone_no,
      memberData.user.role,
    ]
  );

  const validationSchema = yup.object({
    email: yup
      .string()
      .matches(REG_EXP.email, "올바른 이메일을 입력해주세요.")
      .required("이메일을 입력해주세요."),
    name: yup.string().required("이름을 입력해주세요."),
    department: yup.string().required("부서명을 입력해주세요."),
    role: yup.string(),
    phoneNo: yup
      .string()
      .required("연락처를 입력해주세요.")
      .matches(REG_EXP.phone, "올바른 연락처를 입력해주세요."),
    message: yup.string(),
  });

  const { getFieldProps, setFieldValue, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate({
        userId: open.key,
        name: values.name,
        phoneNo: values.phoneNo.replace(/-/g, ""),
        department: values.department,
      });
      onClose();
    },
  });

  const onChangePhoneNo = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replace(/-/g, "");
      setFieldValue("phoneNo", phoneFormatter(value));
    },
    [setFieldValue]
  );

  return (
    <Dialog
      css={addMemberModalStyle}
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-member"
      aria-describedby="edit member"
    >
      <DialogTitle id="dialog-title">{memberData.user.account_id}</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="edit-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="이메일"
                placeholder="이메일을 입력해주세요."
                required
                disabled
                {...getFieldProps("email")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                className="field"
                label="이름"
                placeholder="이름을 입력해주세요."
                required
                {...getFieldProps("name")}
                error={shouldErrorShows("name", touched, errors)}
                helperText={getHelperText("name", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="부서"
                placeholder="부서명을 입력해주세요."
                required
                {...getFieldProps("department")}
                error={shouldErrorShows("department", touched, errors)}
                helperText={getHelperText("department", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="역할"
                placeholder="역할을 설정해주세요."
                required
                disabled={!isEditiableRole}
                suffix={
                  <Button color="primary" onClick={onShowEditRoleModal} disabled={!isEditiableRole}>
                    설정하기
                  </Button>
                }
                readOnly
                {...getFieldProps("role")}
                error={shouldErrorShows("role", touched, errors)}
                helperText={getHelperText("role", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="연락처"
                placeholder="연락처를 입력해주세요. ('-'제외)"
                required
                {...getFieldProps("phoneNo")}
                onChange={onChangePhoneNo}
                error={shouldErrorShows("phoneNo", touched, errors)}
                helperText={getHelperText("phoneNo", touched, errors)}
              />
            </Grid>
            <Grid item xs={12} className="createdAt">
              <TextField
                className="field"
                label="가입일"
                disabled
                {...getFieldProps("createdAt")}
                error={shouldErrorShows("createdAt", touched, errors)}
                helperText={getHelperText("createdAt", touched, errors)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-form" type="submit">
          저장
        </Button>
      </DialogActions>
      {openEditRoleModal.isOpen && (
        <EditMemberRoleModal open={openEditRoleModal} onClose={onCloseEditRoleModal} />
      )}
    </Dialog>
  );
};

export default EditMemberModal;
