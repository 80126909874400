import { Box, Button, Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import { CellClickedEvent, ValueFormatterParams } from "ag-grid-community";
import { useCallback, useMemo, useState } from "react";
import { BasicTable, Select } from "src/components/commons";
import useGetMediationList, { Mediation } from "src/hooks/apis/thirdparties/useGetMediationList";
import useOpenModal from "src/hooks/useOpenModal";
import { Media, MEDIA, MEDIA_ALIAS } from "src/types";
import AppPlacementModal from "./AppPlacementModal";
import EditMediationModal from "./EditMediationModal";
import { partnerBoardStyle } from "./styles";
import WebPlacementModal from "./WebPlacementModal";

const MediationBoard = () => {
  const [media, setMedia] = useState<Media>(MEDIA.APP);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedMediation, setSelectedMediation] = useState<Mediation>();
  const [openEditModal, onShowEditModal, onCloseEditModal] = useOpenModal(null);

  const { data } = useGetMediationList({ type: media, pageNo, pageSize });

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const onChangeMedia = useCallback((event: SelectChangeEvent<Media>) => {
    const value = +event.target.value as Media;
    setMedia(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, data.pages, onChangePage, pageSize, onChangePageSize]
  );

  const onCellClicked = useCallback(
    (e: CellClickedEvent) => {
      e.event?.preventDefault();
      if (e.colDef.field !== "placement_count") {
        onShowEditModal(e.event, null);
        setSelectedMediation(e.data);
      }
    },
    [onShowEditModal]
  );

  return (
    <Box css={partnerBoardStyle}>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={3}>
          <Select
            label="미디어"
            placeholder="미디어를 선택해주세요."
            onChange={onChangeMedia}
            value={media}
          >
            <MenuItem value={MEDIA.APP}>{MEDIA_ALIAS[MEDIA.APP]}</MenuItem>
            <MenuItem value={MEDIA.WEB}>{MEDIA_ALIAS[MEDIA.WEB]}</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <BasicTable
        getRowId={(params) => params.data.id}
        animateRows
        rowData={data.thirdparties}
        columnDefs={columnDefs}
        {...paginationInfo}
        onCellClicked={onCellClicked}
      />
      {openEditModal.isOpen && (
        <EditMediationModal
          mediation={selectedMediation}
          isOpen={openEditModal.isOpen}
          onClose={onCloseEditModal}
        />
      )}
    </Box>
  );
};

const columnDefs = [
  { headerName: "미디에이션 명", field: "name", sortable: true },
  {
    headerName: "활성 플레이스먼트",
    field: "placement_count",
    minWidth: 350,
    sortable: true,
    cellRenderer: (params: { data: Mediation }) => <ComapanyButton {...params.data} />,
  },
  {
    headerName: "시스템 수수료",
    field: "system_fee",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const systemFee = param.value;
      return `${systemFee} %`;
    },
  },
];

function ComapanyButton({ id, media_type, placement_count, ...props }: Mediation) {
  const [openPlacementModal, onShowPlacementModal, onClosePlacementModal] = useOpenModal(id);

  return (
    <>
      <Button variant="text" onClick={onShowPlacementModal}>
        {`${placement_count} 개`}
      </Button>
      {media_type === MEDIA.APP
        ? openPlacementModal.isOpen && (
            <AppPlacementModal
              {...props}
              id={id}
              media_type={media_type}
              placement_count={placement_count}
              open={openPlacementModal}
              onClose={onClosePlacementModal}
            />
          )
        : openPlacementModal.isOpen && (
            <WebPlacementModal
              {...props}
              id={id}
              media_type={media_type}
              placement_count={placement_count}
              open={openPlacementModal}
              onClose={onClosePlacementModal}
            />
          )}
    </>
  );
}

export default MediationBoard;
