import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import { MouseEvent, useMemo } from "react";
import { BsPlusSquare } from "react-icons/bs";
import { TextField } from "src/components/commons";
import useGetAppMedia from "src/hooks/apis/media/useGetAppMedia";
import usePatchApp from "src/hooks/apis/media/usePatchApp";
import useOpenModal from "src/hooks/useOpenModal";
import { APP_PLATFORM_ALIAS } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import BlockDomainModal from "./BlockDomainModal";
import { editMediaModalStyle } from "./styles";
import TestDeviceModal from "./TestDeviceModal";

interface EditMediaModalProps {
  onClose: (e: MouseEvent) => void;
  open: { key: string; isOpen: boolean };
}

const EditMediaModal = ({ onClose, open }: EditMediaModalProps) => {
  const [openTestDeviceModal, onShowTestDeviceModal, onCloseTestDeviceModal] = useOpenModal(
    open.key
  );
  const [openBlockDomainModal, onShowBlockDomainModal, onCloseBlockDomainModal] = useOpenModal(
    open.key
  );

  const { data } = useGetAppMedia({ mediaKey: open.key });
  const { mutate } = usePatchApp();

  const initialValues = useMemo(
    () => ({
      name: data.media.name,
      platformType: APP_PLATFORM_ALIAS[data.media.platform_type],
      key: data.media.key,
      placementCount: data.media.placement_count,
      hashKey: data.media.hash_key,
      packageName: data.media.package_name,
      urlScheme: data.media.url_scheme || "",
      urlSchemeStatus: !!data.media.url_scheme,
      testDeviceCount: data.media.test_device_count,
      blockDomainListSet: data.media.block_domain_list_set,
      createdAt: moment(data.media.created_at * 1000).format("YYYY-MM-DD"),
    }),
    [
      data.media.block_domain_list_set,
      data.media.created_at,
      data.media.hash_key,
      data.media.key,
      data.media.name,
      data.media.package_name,
      data.media.placement_count,
      data.media.platform_type,
      data.media.test_device_count,
      data.media.url_scheme,
    ]
  );

  const validationSchema = yup.object({
    name: yup.string().required("앱 매체 명을 입력해주세요."),
    packageName: yup
      .string()
      .required("Package Name을 입력해주세요.")
      .matches(REG_EXP.packageName, "올바른 Package Name 값을 입력해주세요."),
    urlScheme: yup.string().when("urlSchemeStatus", {
      is: true,
      then: yup.string().matches(REG_EXP.urlScheme, "올바른 Deep Link URL을 입력해주세요."),
    }),
  });

  const { getFieldProps, handleSubmit, touched, errors, values } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate({
        mediaKey: values.key,
        name: values.name,
        packageName: values.packageName,
        urlScheme: values.urlScheme,
      });
    },
  });

  return (
    <Dialog
      css={editMediaModalStyle}
      fullWidth
      maxWidth="md"
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-media"
      aria-describedby="edit media"
    >
      <DialogTitle id="dialog-title">{data.media.name}</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="edit-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="앱 매체 명"
                placeholder="등록하실 앱 매체 명을 입력하세요."
                required
                {...getFieldProps("name")}
                error={shouldErrorShows("name", touched, errors)}
                helperText={getHelperText("name", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="플랫폼"
                required
                disabled
                {...getFieldProps("platformType")}
                error={shouldErrorShows("platformType", touched, errors)}
                helperText={getHelperText("platformType", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="매체키"
                required
                disabled
                {...getFieldProps("key")}
                error={shouldErrorShows("key", touched, errors)}
                helperText={getHelperText("key", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="플레이스먼트"
                required
                disabled
                suffix={
                  <Button color="primary" href={`/app/placement?media=${open.key}`}>
                    바로가기
                  </Button>
                }
                {...getFieldProps("placementCount")}
                error={shouldErrorShows("placementCount", touched, errors)}
                helperText={getHelperText("placementCount", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="Hash Key"
                required
                disabled
                {...getFieldProps("hashKey")}
                error={shouldErrorShows("hashKey", touched, errors)}
                helperText={getHelperText("hashKey", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="Package Name"
                {...getFieldProps("packageName")}
                error={shouldErrorShows("packageName", touched, errors)}
                helperText={getHelperText("packageName", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="등록일"
                required
                disabled
                {...getFieldProps("createdAt")}
                error={shouldErrorShows("createdAt", touched, errors)}
                helperText={getHelperText("createdAt", touched, errors)}
              />
            </Grid>
            <Grid item xs={6} className="remote-tools">
              <Button variant="contained" size="small" onClick={onShowTestDeviceModal}>
                <BsPlusSquare />
                테스트 디바이스
              </Button>
              <Button variant="contained" size="small" onClick={onShowBlockDomainModal}>
                <BsPlusSquare />
                차단 URL 그룹 설정
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                className="switch"
                control={<Switch color="primary" />}
                label="Deep Link Url"
                labelPlacement="start"
                {...getFieldProps("urlSchemeStatus")}
                checked={values.urlSchemeStatus}
              />
            </Grid>
            <Grid item xs={6}>
              {values.urlSchemeStatus && (
                <TextField
                  className="field"
                  label="Deep Link Url"
                  placeholder="프로토콜만 입력해 주세요. (ex. https://, http://)"
                  required
                  {...getFieldProps("urlScheme")}
                  error={shouldErrorShows("urlScheme", touched, errors)}
                  helperText={getHelperText("urlScheme", touched, errors)}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-form" type="submit">
          저장
        </Button>
      </DialogActions>
      {openTestDeviceModal.isOpen && (
        <TestDeviceModal open={openTestDeviceModal} onClose={onCloseTestDeviceModal} />
      )}
      {openBlockDomainModal.isOpen && (
        <BlockDomainModal open={openBlockDomainModal} onClose={onCloseBlockDomainModal} />
      )}
    </Dialog>
  );
};

export default EditMediaModal;
