import { Container, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import { useParams } from "react-router-dom";
import useGetRewardCSDetail from "src/hooks/apis/rewardCS/useGetRewardCSDetail";
import { clientInfoStyle } from "./styles";

const ServiceClientInfo = () => {
  const { id } = useParams();
  const { data: clientData } = useGetRewardCSDetail({ rewardCSNo: id });

  return (
    <Container component="section" className="ssp-section" css={clientInfoStyle} maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        문의자 정보
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid container className="info" spacing={4}>
          <Grid item xs={1} className="label">
            NO
          </Grid>
          <Grid item xs={5} className="value">
            {id}
          </Grid>
          <Grid item xs={1} className="label">
            문의 일자
          </Grid>
          <Grid item xs={5} className="value">
            {moment(clientData.item.created_at * 1000).format("YYYY-MM-DD HH:mm:ss")}
          </Grid>
          <Grid item xs={1} className="label">
            미디어
          </Grid>
          <Grid item xs={5} className="value">
            {clientData.item.media_name}
          </Grid>
          <Grid item xs={1} className="label">
            업체
          </Grid>
          <Grid item xs={5} className="value">
            {clientData.item.company_name}
          </Grid>
          <Grid item xs={1} className="label">
            ADID
          </Grid>
          <Grid item xs={5} className="value">
            {clientData.item.adid}
          </Grid>
          <Grid item xs={1} className="label">
            USN
          </Grid>
          <Grid item xs={5} className="value">
            {clientData.item.usn}
          </Grid>
          <Grid item xs={1} className="label">
            Email
          </Grid>
          <Grid item xs={5} className="value">
            {clientData.item.email}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ServiceClientInfo;
