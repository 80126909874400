import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import moment from "moment";
import { MouseEvent, useCallback } from "react";
import usePutConfirm from "src/hooks/apis/payments/usePutConfirm";
import { taxBillModalStyle } from "./styles";

interface TaxBillConfirmationModalProps extends ICellRendererParams {
  onClose: () => void;
  open: { key: number; isOpen: boolean };
  field: "tax_bill_status";
  which?: "confirmation";
}

const TaxBillConfirmationModal = ({ open, onClose, data }: TaxBillConfirmationModalProps) => {
  const { mutate } = usePutConfirm();

  const onClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      mutate({ paymentId: open.key });
      onClose();
    },
    [mutate, onClose, open.key]
  );

  return (
    <Dialog
      css={taxBillModalStyle}
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-taxbill"
      aria-describedby="edit taxbill"
    >
      <DialogTitle id="dialog-title">{`발행 완료 (${moment(`${data.month}01`).format(
        "YYYY.MM"
      )})`}</DialogTitle>
      <DialogContent className="dialog-content">
        <Typography variant="body1">
          해당 내역에 대해 세금계산서 발행을 완료 처리하시겠습니까?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button onClick={onClick}>저장</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaxBillConfirmationModal;
