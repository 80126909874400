import { useCallback, useState } from "react";

export type ModalKey = string | number | undefined | null;

const useOpenModal = <T extends ModalKey>(defaultKey: T) => {
  const [open, setOpen] = useState<{ key: T; isOpen: boolean }>({
    key: defaultKey,
    isOpen: false,
  });

  const onShowModal = useCallback(
    <K,>(_event?: K, key: T = defaultKey) => {
      setOpen({ key: key, isOpen: true });
    },
    [defaultKey]
  );

  const onCloseModal = useCallback(() => {
    setOpen({ key: defaultKey, isOpen: false });
  }, [defaultKey]);

  return [open, onShowModal, onCloseModal] as const;
};

export default useOpenModal;
