import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import qs from "qs";
import { useCallback, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useGetUserInvite from "src/hooks/apis/users/useGetUserInvite";
import usePostUserApprove from "src/hooks/apis/users/usePostUserApprove";
import { inviteModalStyle } from "./styles";

const InviteModal = () => {
  const { search } = useLocation();
  const token = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }).token as string,
    [search]
  );
  const { data } = useGetUserInvite({ token });
  const { mutate } = usePostUserApprove();

  const onApprove = useCallback(() => {
    data.is_registered && mutate({ token });
  }, [data.is_registered, mutate, token]);

  if (!token) return <Navigate to="not-found" replace />;

  return (
    <Dialog
      css={inviteModalStyle}
      fullWidth
      open={true}
      aria-labelledby="representative-invitation"
      aria-describedby="invite member"
    >
      <DialogTitle id="dialog-title">애드팝콘 SSP 콘솔 초대장</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          <Typography variant="body1" gutterBottom>
            <span className="highlight">{data.sender_user_account_id}</span>님이{" "}
            <span className="highlight">{data.company_name}</span>에 회원님을 초대했습니다.
          </Typography>
          <Typography className="account-id" variant="body1">
            <span>계정: </span>
            <span className="highlight">{data.user_account_id}</span>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {data.is_registered ? (
          <Button onClick={onApprove}>승인</Button>
        ) : (
          <Button href={`/login/invite/register?token=${data.token}`}>가입</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default InviteModal;
