import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import {
  ColDef,
  ColGroupDef,
  RowClickedEvent,
  SortChangedEvent,
  ValueFormatterParams,
} from "ag-grid-community";
import moment from "moment";
import qs from "qs";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import DataFormat from "src/assets/formats";
import { viewerCompany } from "src/atoms/viewerCompany";
import { BasicTable, MultipleSelect } from "src/components/commons";
import { SearchField } from "src/components/commons/SearchFiled";
import useGetAdFormatInfo, { AdFormat } from "src/hooks/apis/info/useGetAdFormatInfo";
import useGetWebMediaList, { WebMedia } from "src/hooks/apis/media/useGetWebMediaList";
import useGetWebPlacementList from "src/hooks/apis/placements/useGetWebPlacementList";
import useOpenModal from "src/hooks/useOpenModal";
import { CAMPAIGN, STATUS } from "src/types";
import AddPlacementModal from "./AddPlacementModal";
import EditPlacementModal from "./EditPlacementModal";

const PlacementBoard = () => {
  const { search: queryString } = useLocation();
  const [mediaList, setMediaList] = useState<WebMedia[]>([]);
  const [search, setSearch] = useState({ temp: "", value: "" });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [orders, setOrders] = useState<string[]>([]);
  const [openAddModal, onShowAddPlacementModal, onCloseAddPlacementModal] = useOpenModal(null);
  const [openEditModal, onShowEditPlacement, onCloseEditPlacement] = useOpenModal<string>("");

  const [company] = useRecoilState(viewerCompany);
  const { data: mediaData } = useGetWebMediaList({ companyKey: company.key });
  const { data: placementData } = useGetWebPlacementList({
    mediaKeys: mediaList.map((media) => media.key),
    name: search.value,
    pageNo,
    pageSize,
    orders,
  });
  const { data: adFormatData } = useGetAdFormatInfo({
    types: [CAMPAIGN.WebBanner],
  });

  // 초기 매체필터 데이터 세팅
  useEffect(() => {
    setMediaList(mediaData.media);
    // 리포트키 상세보기에서 플레이스먼트 바로가기 클릭시
    if (queryString) {
      const queryData = qs.parse(queryString, { ignoreQueryPrefix: true });
      setMediaList((prev) => prev.filter((m) => m.key === queryData.media));

      if (
        queryData.placementId &&
        typeof queryData.placementId === "string" &&
        queryData.showPlacement
      ) {
        onShowEditPlacement(undefined, queryData.placementId);
      }
    }
  }, [mediaData.media, onShowEditPlacement, queryString]);

  const onChangeSearchWord = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearch((prev) => ({ ...prev, temp: e.target.value }));
  }, []);

  const onSearchMedia = useCallback(() => {
    setSearch((prev) => ({ ...prev, value: prev.temp }));
    setPageNo(1);
  }, []);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const onShowEditPlacementModal = useCallback(
    (e: RowClickedEvent) => {
      onShowEditPlacement(e, e.data.id);
    },
    [onShowEditPlacement]
  );

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: placementData.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, placementData.pages, onChangePage, pageSize, onChangePageSize]
  );

  const onChangeMediaList = useCallback((values: WebMedia[]) => {
    setMediaList(values);
  }, []);

  const onSortChanged = useCallback((e: SortChangedEvent) => {
    const sortInfo = e.columnApi
      .getColumnState()
      .filter((column) => !!column.sort)
      .map(({ colId, sort }) => {
        if (sort === "desc") return `-${colId}`;
        return `+${colId}`;
      });
    setOrders(sortInfo);
  }, []);
  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        웹 플레이스먼트 관리
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid container className="ssp-tools" spacing={2}>
          <Grid item xs={3}>
            <MultipleSelect
              options={mediaData.media}
              label="매체"
              placeholder="매체 선택"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.key}
              onChange={onChangeMediaList}
              value={mediaList}
            />
          </Grid>
          <Grid item xs={3}>
            <SearchField
              label="검색어"
              placeholder="플레이스먼트명 / ID"
              value={search.temp}
              onChange={onChangeSearchWord}
              onClickSearchButton={onSearchMedia}
            />
          </Grid>
          <Grid item xs={6} className="register">
            <Button variant="outlined" onClick={onShowAddPlacementModal}>
              신규 플레이스먼트
            </Button>
          </Grid>
        </Grid>
        <BasicTable
          rowData={placementData.placements}
          columnDefs={getColumnDefs(adFormatData.formats)}
          onSortChanged={onSortChanged}
          onRowClicked={onShowEditPlacementModal}
          {...paginationInfo}
        />
      </Paper>
      {openAddModal.isOpen && (
        <AddPlacementModal open={openAddModal.isOpen} onClose={onCloseAddPlacementModal} />
      )}
      {openEditModal.isOpen && (
        <EditPlacementModal open={openEditModal} onClose={onCloseEditPlacement} />
      )}
    </Container>
  );
};

const getColumnDefs = (formats: AdFormat[]): (ColDef | ColGroupDef)[] => [
  { headerName: "플레이스먼트 명", field: "name", sortable: true },
  { headerName: "플레이스먼트 ID", field: "id", sortable: true },
  {
    headerName: "사이즈",
    field: "size_type",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const format = formats.find((format) => format.id === param.value);
      return format?.label || "";
    },
  },
  {
    headerName: "Bid Floor",
    field: "bidfloor",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      if (param.data.bidfloor_status === STATUS.ACTIVE)
        return DataFormat.bidfloor.formatter(param.data.bidfloor);
      return "";
    },
  },
  {
    headerName: "등록일",
    field: "created_at",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const createdAt = param.value * 1000;
      return moment(createdAt).format("YYYY-MM-DD");
    },
  },
];

export default PlacementBoard;
