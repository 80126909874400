import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { MouseEvent, useCallback, useMemo, useState } from "react";
import { BasicTable, DeleteButton } from "src/components/commons";
import useDeleteDomain from "src/hooks/apis/blocklist/useDeleteDomain";
import useGetDomain, { Domain } from "src/hooks/apis/blocklist/useGetDomain";
import EditBlockUrlForm from "./EditBlockUrlForm";
import EditGroupNameForm from "./EditGroupNameForm";
import { editBlockURLModalStyle } from "./styles";

interface EditBlockURLModalProps {
  onClose: () => void;
  name: string;
  open: { key: number; isOpen: boolean };
}

const EditBlockURLModal = ({ onClose, open, name = "" }: EditBlockURLModalProps) => {
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { data: domainData } = useGetDomain({ domainListId: open.key, pageNo, pageSize });

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: domainData.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [domainData.pages, onChangePage, onChangePageSize, pageNo, pageSize]
  );

  return (
    <Dialog
      css={editBlockURLModalStyle}
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-block-url"
      aria-describedby="edit block url"
    >
      <DialogTitle id="dialog-title">{name}</DialogTitle>
      <DialogContent className="dialog-content">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EditGroupNameForm id={open.key} name={name} />
          </Grid>
          <Grid item xs={12}>
            <EditBlockUrlForm id={open.key} />
          </Grid>
          <Grid item xs={12}>
            <BasicTable
              rowData={domainData.domains}
              columnDefs={getColumnDefs(open.key)}
              {...paginationInfo}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const getColumnDefs = (domainListId: number): (ColDef | ColGroupDef)[] => [
  { headerName: "URL", field: "domain", sortable: true },
  {
    headerName: "Delete",
    field: "id",
    sortable: true,
    cellRenderer: (params: { data: Domain }) => (
      <RemoveButton domainListId={domainListId} id={params.data.id} />
    ),
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

function RemoveButton({ domainListId, id }: { domainListId: number; id: number }) {
  const { mutate } = useDeleteDomain();

  const onClickDeleteButton = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      mutate({ domainListId: domainListId, domainId: id });
    },
    [domainListId, id, mutate]
  );

  return (
    <DeleteButton onConfirm={onClickDeleteButton}>정말 해당 URL을 삭제하시겠습니까?</DeleteButton>
  );
}

export default EditBlockURLModal;
