import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useFormik } from "formik";
import { useMemo } from "react";
import { TextField } from "src/components/commons";
import { DSP } from "src/hooks/apis/thirdparties/useGetDSPList";
import usePutDSPDetail from "src/hooks/apis/thirdparties/usePutDSPDetail";
import { MEDIA, MEDIA_ALIAS } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { editDSPModalStyle } from "./styles";

interface EditDSPModalProps {
  dsp?: DSP;
  onClose: () => void;
  isOpen: boolean;
}

const EditDSPModal = ({ dsp, onClose, isOpen }: EditDSPModalProps) => {
  const { mutate } = usePutDSPDetail();

  const initialValues = useMemo(
    () => ({
      name: dsp?.name || "",
      mediaType: MEDIA_ALIAS[dsp?.media_type || MEDIA.APP],
      endpoint: dsp?.endpoint || "",
      systemFee: dsp?.system_fee ?? 0,
    }),
    [dsp?.endpoint, dsp?.media_type, dsp?.name, dsp?.system_fee]
  );

  const validationSchema = yup.object({
    endpoint: yup
      .string()
      .required("엔드포인트를 입력해주세요.")
      .matches(REG_EXP.domain, "올바른 엔드포인트를 입력해주세요."),
    systemFee: yup
      .string()
      .test("systemFee", "숫자를 입력해주세요.", (value = "") => !isNaN(+value))
      .required("시스템 수수료를 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (dsp)
        mutate({
          thirdpartyId: dsp.id,
          endpoint: values.endpoint,
          systemFee: +values.systemFee,
        });
    },
  });

  return (
    <Dialog
      css={editDSPModalStyle}
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-dsp"
      aria-describedby="edit dsp"
    >
      <DialogTitle id="dialog-title">{dsp?.name}</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="edit-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="DSP 명"
                required
                disabled
                {...getFieldProps("name")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="매체 종류"
                required
                disabled
                {...getFieldProps("mediaType")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="엔드포인트"
                required
                {...getFieldProps("endpoint")}
                error={shouldErrorShows("endpoint", touched, errors)}
                helperText={getHelperText("endpoint", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="시스템 수수료"
                required
                {...getFieldProps("systemFee")}
                error={shouldErrorShows("systemFee", touched, errors)}
                helperText={getHelperText("systemFee", touched, errors)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDSPModal;
