import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";
import useGetUserDetail from "../users/useGetUserDetail";

interface Params {
  companyKey?: string;
  domainListName: string;
}

interface Response {
  code: 200 | 400 | 401 | 409;
  data?: {
    domain_list_id: number;
  }[];
  text: "ok" | "bad-request" | "unauthorized" | "conflict";
}

// 차단 도메인 그룹 생성
const usePostDomains = () => {
  const toast = useToast();
  const { handleError } = useApiError({
    409: (_error) =>
      toast.error(`해당 그룹명은 이미 존재합니다.
  그룹명을 다시 입력해주세요.`),
  });
  const { data: userData } = useGetUserDetail({ userId: "me" });
  const invalidate = useInvalidateQueries();

  const { data, ...rest } = useMutation<Response["data"], AxiosError, Params>(
    async ({ companyKey, domainListName }) => {
      const response: AxiosResponse<Response> = await API.default.post("/blocklist/domains", {
        company_key: companyKey || userData.user.company_key,
        domain_list_name: domainListName,
      });
      return response.data.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("차단 URL 그룹이 생성되었습니다.");
        invalidate("blocklist/useGetDomains");
      },
    }
  );

  const result = useMemo(() => (data ? data : { domain_list_id: 0 }), [data]);

  return { data: result, ...rest };
};

export default usePostDomains;
