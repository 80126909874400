import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

export interface Campaign {
  partner_no: number;
  partner_name: string;
  subids: string[];
  campaign_no: number;
  campaign_key: string;
  campaign_name: string;
  postback_no: number;
  media_key: string;
  landing_url: string;
  trigger_revenue: number;
  regist_datetime: string;
  update_datetime: string;
}

interface Params {
  campaignKey: string;
}

interface Response {
  code?: 200 | 401 | 404 | 422;
  data?: Campaign;
  text?: "ok" | "unauthorized" | "not-found" | "unprocessable-entity";
}

/*
  쿠팡 캠페인 조회
*/
const useGetCampaign = ({ campaignKey }: Params) => {
  const { handleError } = useApiError();
  const { data: response, ...rest } = useQuery<Response, AxiosError>(
    ["coupang/useGetCampaign", campaignKey],
    async () => {
      const response: AxiosResponse<Response> = await API.integration.get(
        `/operation/management/coupang/campaign/${campaignKey}`
      );
      return response.data;
    },
    { enabled: !!campaignKey, onError: handleError }
  );

  const result = useMemo(() => {
    if (response && response.data)
      return {
        campaign: { ...response.data },
      };
    return {
      campaign: {
        campaign_no: 0,
        campaign_name: "",
        campaign_key: "",
        landing_url: "",
        partner_no: 0,
        media_key: "",
        trigger_revenue: 0,
        partner_item_key: "",
        regist_datetime: "",
        update_datetime: "",
        partner_name: "",
      },
    };
  }, [response]);

  return { data: result, ...rest };
};

export default useGetCampaign;
