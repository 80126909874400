import { AxiosError, AxiosResponse } from "axios";
import moment, { Moment } from "moment";
import { useCallback, useMemo, useState } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Partner, PARTNER_ALIAS, REPORT, Report } from "src/types";
import API from "src/utils/api";
import { getConversionRate, getCtr, getEcpm } from "src/utils/calculator";
import { PartnerApp } from "./useGetPartnerAppList";

export interface ReportChartData {
  date: string;
  impression: number;
  click: number;
  conversion: number;
  cvr: number;
  uniqueUser: number;
  mediaCost: number;
  ctr: number;
  ecpm: number;
}

export interface ReportTableData extends ReportChartData {
  country: string;
  partnerName: string;
  partnerAppName: string;
}

export interface Data {
  report_date: string;
  partner_id: number;
  partner_app_key: string;
  impression: number;
  click: number;
  conversion: number;
  unique_user: number;
  media_cost: number;
  ctr: number;
  cvr: number;
  ecpm: number;
  country: string;
}

interface Params {
  companyKey: string;
  since: Moment;
  until: Moment;
  partnerList: Partner[];
  partnerAppList: PartnerApp[];
  type: Report;
  byCountry: boolean;
}

interface Response {
  since: string;
  until: string;
  dimensions: string[];
  records: Data[];
}

// 파트너 리포트 조회
const usePostOfferwallReport = () => {
  const [reportType, setReportType] = useState<Report>();
  const [appList, setAppList] = useState<PartnerApp[]>([]);
  const { handleError } = useApiError();
  const { data, ...rest } = useMutation<Response, AxiosError, Params>(
    async ({ companyKey, since, until, partnerList, partnerAppList, type }) => {
      setReportType(type);
      setAppList(partnerAppList);

      const reportType = {
        1: "day",
        2: "week",
        3: "month",
      };
      const response: AxiosResponse<Response> = await API.console.post(
        `/report/partners/offerwall`,
        {
          company_key: companyKey,
          since: since.format("YYYYMMDD"),
          until: until.format("YYYYMMDD"),
          partner_id: partnerList,
          partner_app_key: partnerAppList.map((v) => v.partner_app_key),
          dimensions: [reportType[type], "country"],
        }
      );
      return response.data;
    },
    { onError: handleError }
  );

  const getDate = useCallback(
    (date: string) => {
      if (reportType === REPORT.DAILY) {
        return moment(date).format("YYYY-MM-DD");
      }
      if (reportType === REPORT.WEEKLY) {
        return date.replace(/^(\d{4})(\d{2})$/, `$1-w$2`);
      }
      if (reportType === REPORT.MONTHLY) {
        return date.replace(/^(\d{4})(\d{2})$/, `$1-$2`);
      }
      return "";
    },
    [reportType]
  );

  const getTableData = useCallback(
    (report: Data[], byCountry: boolean) => {
      return report.reduce((acc: { [key: string]: ReportTableData }, cur) => {
        const {
          report_date,
          partner_id,
          partner_app_key,
          impression,
          click,
          conversion,
          unique_user,
          media_cost,
          country,
        } = cur;

        const app = appList.find((app) => app.partner_app_key === partner_app_key);

        const key = byCountry
          ? `${report_date}-${country}-${partner_id}-${partner_app_key}`
          : `${report_date}-${partner_id}-${partner_app_key}`;

        if (!acc[key]) {
          acc[key] = {
            date: getDate(report_date),
            country: country,
            partnerName: PARTNER_ALIAS[partner_id],
            partnerAppName: app?.partner_app_name || "",
            impression: +impression,
            click: +click,
            ctr: getCtr(+impression, +click),
            conversion: +conversion,
            cvr: getConversionRate(+conversion, +click),
            uniqueUser: +unique_user,
            mediaCost: +media_cost,
            ecpm: getEcpm(+impression, +media_cost),
          };
        } else {
          acc[key].impression += +impression;
          acc[key].click += +click;
          acc[key].ctr = getCtr(acc[key].impression, acc[key].click);
          acc[key].conversion += +conversion;
          acc[key].cvr = getConversionRate(acc[key].conversion, acc[key].click);
          acc[key].uniqueUser += +unique_user;
          acc[key].mediaCost += +media_cost;
          acc[key].ecpm = getEcpm(acc[key].impression, acc[key].mediaCost);
        }
        return acc;
      }, {});
    },
    [appList, getDate]
  );

  const result = useMemo(() => {
    if (data && data.records && data.records.length > 0) {
      // 차트 데이터
      const chartData = data.records.reduce((acc: { [key: string]: ReportChartData }, cur) => {
        const {
          report_date,
          impression = 0,
          click = 0,
          conversion = 0,
          unique_user = 0,
          media_cost = 0,
        } = cur;
        const key = getDate(report_date);
        if (!acc[key]) {
          acc[key] = {
            date: key,
            impression: +impression,
            click: +click,
            ctr: getCtr(+impression, +click),
            conversion: +conversion,
            cvr: getConversionRate(+conversion, +click),
            uniqueUser: +unique_user,
            mediaCost: +media_cost,
            ecpm: getEcpm(+impression, +media_cost),
          };
        } else {
          acc[key].impression += +impression;
          acc[key].click += +click;
          acc[key].ctr = getCtr(acc[key].impression, acc[key].click);
          acc[key].conversion += +conversion;
          acc[key].cvr = getConversionRate(acc[key].conversion, acc[key].click);
          acc[key].uniqueUser += +unique_user;
          acc[key].mediaCost += +media_cost;
          acc[key].ecpm = getEcpm(acc[key].impression, acc[key].mediaCost);
        }
        return acc;
      }, {});

      // 테이블 데이터
      const tableData = getTableData(data.records, false);
      const countryTableData = getTableData(data.records, true);

      return {
        ...data,
        chart: Object.entries(chartData)
          .map(([_key, value]) => value)
          .sort((a, b) => {
            if (moment(a.date).isAfter(moment(b.date))) return 1;
            if (moment(a.date).isBefore(moment(b.date))) return -1;
            return 0;
          }),
        table: Object.entries(tableData)
          .map(([_key, value]) => value)
          .sort((a, b) => {
            if (moment(b.date).isAfter(moment(a.date))) return 1;
            if (moment(b.date).isBefore(moment(a.date))) return -1;
            if (a.impression < b.impression) return 1;
            if (a.impression > b.impression) return -1;
            return 0;
          }),
        countryTable: Object.entries(countryTableData)
          .map(([_key, value]) => value)
          .sort((a, b) => {
            if (moment(b.date).isAfter(moment(a.date))) return 1;
            if (moment(b.date).isBefore(moment(a.date))) return -1;
            if (a.impression < b.impression) return 1;
            if (a.impression > b.impression) return -1;
            return 0;
          }),
      };
    }
    return {
      chart: [] as ReportChartData[],
      table: [] as ReportTableData[],
      countryTable: [] as ReportTableData[],
      report: [] as Data[],
    };
  }, [data, getTableData, getDate]);

  return { data: result, ...rest };
};

export default usePostOfferwallReport;
