import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  companyKey: string;
  partnerName: string;
  commission: number;
  clientCommission: number;
}

interface Response {
  code: 200 | 201 | 401 | 422;
  text: "ok" | "PARTNER_CREATE_SUCCESS" | "unauthorized" | "unprocessable-entity";
}

// 쿠팡 파트너 생성
const usePostPartner = () => {
  const { handleError } = useApiError({ 409: (_error: AxiosError) => null });
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ partnerName, companyKey, commission, clientCommission }) => {
      const response: AxiosResponse<Response> = await API.integration.post(
        `/operation/management/coupang/partner`,
        {
          partner_name: partnerName,
          company_key: companyKey,
          ap_commission_rate: commission,
          client_commission_rate: clientCommission,
        }
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("파트너가 생성되었습니다.");
        invalidate("coupang/useGetPartnerList");
      },
    }
  );

  return result;
};

export default usePostPartner;
