import { css } from "@emotion/react";

export const publisherBoardStyle = css`
  margin-top: 1rem;
`;

export const publisherModalStyle = css`
  .use-adserver label {
    margin-left: 0.5rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
  }
`;

export const syndicationPublisherBoardStyle = css`
  margin-top: 2rem;
`;

export const syndicationClientBoardStyle = css`
  margin-top: 5rem;
`;

export const syndicationModalStyle = css`
  #company-form {
    margin-top: 1rem;
  }
`;
