import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Authority } from "src/types/authority";
import API from "src/utils/api";

/**
 * isStd가 true이면 roles는 기본인지 커스텀인지 권한의 type을 나타낸다. (기본 권한 다 내려주거나, 커스텀 권한 다 내려준다.)
 * isStd가 false이면 roles는 role id를 나타낸다. (해당하는 역할에 따른 권한을 내려준다.)
 */
interface Params {
  //isStd: boolean; // true: 기본 권한 리스트 조회, false: 수정 가능한 권한 리스트 조회
  type: 1 | 2; // 1: 기본권한 리스트, 2: 커스텀 권한 리스트
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    authorities: Authority[];
  };
  text: "ok" | "bad-request" | "unauthorized";
}

/*
  권한 리스트 조회
  roles을 전달하지 않을 경우 전체 조회
*/
const useGetAuthorityList = ({ type }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["users/useGetAuthorityList", type],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/users/authorities`, {
        params: {
          is_std: true,
          roles: type,
        },
      });
      return response.data.data;
    },
    { enabled: !!type, onError: handleError }
  );

  const result = useMemo(() => (data ? data : { authorities: [] }), [data]);

  return { data: result, ...rest };
};

export default useGetAuthorityList;
