import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Status, Thirdparty } from "src/types";
import API from "src/utils/api";

export type MatchKey = Record<string, string | number | boolean | null>;

export interface MatchKeyInfo {
  [key: string]: {
    related: string[];
    required: boolean;
    type: "string" | "float" | "integer" | "number" | "boolean";
  };
}

export interface ThirdpartyInfo {
  id: number;
  individual_status: Status;
  match_key?: MatchKey;
  match_key_info?: MatchKeyInfo[];
  name: string;
  order: number;
  report_key_info: string[];
  status: Status;
  type: Thirdparty;
  passback_script?: string;
}

interface Params {
  placementId: string;
  type: Thirdparty;
}

interface Response {
  code: 200 | 401 | 404;
  data?: {
    thirdparties: ThirdpartyInfo[];
  };
  text: "ok" | "unauthorized" | "not-found";
}

// 서드파티 정보 조회
const useGetWebThirdpartyList = ({ placementId, type }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    "placements/useGetWebThirdpartyList",
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(
        `/placements/web/${placementId}/thirdparties`,
        {
          params: { type },
        }
      );
      return response.data.data;
    },
    { enabled: !!placementId, onError: handleError }
  );

  const result = useMemo(() => (data ? data : { thirdparties: [] }), [data]);

  return { data: result, ...rest };
};

export default useGetWebThirdpartyList;
