import { Button, Grid } from "@mui/material";
import { CellClickedEvent, ICellRendererParams, ValueGetterParams } from "ag-grid-community";
import { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from "react";
import { BasicTable, DeleteButton, SearchField } from "src/components/commons";
import useDeletePostback from "src/hooks/apis/coupang/useDeletePostback";
import useGetPostbackList from "src/hooks/apis/coupang/useGetPostbackList";
import useOpenModal from "src/hooks/useOpenModal";
import { POSTBACK_ALIAS } from "src/types/coupang";
import AddPostbackModal from "./AddPostbackModal";
import EditPostbackModal from "./EditPostbackModal";

const PostbackBoard = () => {
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [search, setSearch] = useState({ temp: "", value: "" });
  const [openAddModal, onShowAddModal, onCloseAddModal] = useOpenModal(null);
  const [openEditModal, onShowEditModal, onCloseEditModal] = useOpenModal(0);

  const { data } = useGetPostbackList({ search: search.value, pageNo, pageSize });

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, data.pages, onChangePage, pageSize, onChangePageSize]
  );

  const onChangeSearchWord = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearch({ ...search, temp: e.target.value });
    },
    [search]
  );

  const onSearchBidfloor = useCallback(() => {
    setSearch({ ...search, value: search.temp });
    setPageNo(1);
  }, [search]);

  const onShowEditPostbackModal = useCallback(
    (e: CellClickedEvent) => {
      if (e.colDef.headerName !== "delete") {
        onShowEditModal(e.event, e.data.postback_no);
      }
    },
    [onShowEditModal]
  );

  return (
    <>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={3}>
          <SearchField
            label="검색어"
            placeholder="업체명 / 파트너명 / Sub ID"
            value={search.temp}
            onChange={onChangeSearchWord}
            onClickSearchButton={onSearchBidfloor}
          />
        </Grid>
        <Grid item xs={9} className="register">
          <Button variant="outlined" onClick={onShowAddModal}>
            신규 포스트백 등록
          </Button>
        </Grid>
      </Grid>
      <BasicTable
        getRowId={(params) => params.data.postback_no}
        animateRows
        rowData={data.postbacks}
        columnDefs={columnDefs}
        onCellClicked={onShowEditPostbackModal}
        {...paginationInfo}
      />
      {openAddModal.isOpen && (
        <AddPostbackModal isOpen={openAddModal.isOpen} onClose={onCloseAddModal} />
      )}
      {openEditModal.isOpen && (
        <EditPostbackModal open={openEditModal} onClose={onCloseEditModal} />
      )}
    </>
  );
};

const columnDefs = [
  { headerName: "No", field: "postback_no", sortable: true },
  { headerName: "파트너명", field: "partner_name", sortable: true },
  {
    headerName: "Sub ID",
    field: "subids",
    sortable: true,
    valueGetter: (params: ValueGetterParams) =>
      params.data.subids.map((v: { subid_no: number; subid: string }) => v.subid).join(", "),
  },
  {
    headerName: "포스트백 Type",
    field: "postback_type",
    sortable: true,
    valueGetter: (params: ValueGetterParams) => {
      return POSTBACK_ALIAS[params.data.postback_type];
    },
  },
  {
    headerName: "Delete",
    field: "postback_no",
    sortable: true,
    cellRenderer: (params: ICellRendererParams) => (
      <RemoveButton postbackNo={params.data.postback_no} />
    ),
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

function RemoveButton({ postbackNo }: { postbackNo: number }) {
  const { mutate } = useDeletePostback();

  const onClickDeleteButton = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      mutate({ postbackNo });
    },
    [mutate, postbackNo]
  );

  return (
    <DeleteButton onConfirm={onClickDeleteButton}>
      정말 해당 포스트백을 삭제하시겠습니까?
    </DeleteButton>
  );
}

export default PostbackBoard;
