import { Link as MuiLink } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IntroductionImage from "src/assets/images/img-introduction.png";
import SSPLogo from "src/assets/images/ssp-logo.svg";
import { welcomeStyle } from "./styles";

const Introduction: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  return (
    <section className="welcome-section" css={welcomeStyle}>
      <Link to="/" className="logo">
        <img src={SSPLogo} alt="logo" width={140} height={50} />
      </Link>
      <img src={IntroductionImage} className="main-img" alt="cover" />
      <div className={isLoaded ? "description appear" : "description hidden"}>
        <p className="main">국내 최초 인앱 전용 헤더비딩으로 더 많은 수익을 가져가세요.</p>
        <p className="sub">
          ADPOPCORN SSP에서는 헤더비딩 방식을 적용하여 퍼블리셔가 이전보다 더 많은 수익을 가져갈 수
          있습니다.
        </p>
        <MuiLink
          className="detail"
          href="https://ssp.adpopcorn.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          자세히 보기
        </MuiLink>
      </div>
    </section>
  );
};

export default Introduction;
