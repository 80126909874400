import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  postbackNo: number;
}

interface Response {
  code: 204 | 400 | 401 | 404 | 422;
  text:
    | "Successful Response"
    | "bad-request"
    | "unauthorized"
    | "not-found"
    | "unprocessable-entity";
}

// 쿠팡 파트너 삭제
const useDeletePostback = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ postbackNo }) => {
      const response: AxiosResponse<Response> = await API.integration.delete(
        `/operation/management/coupang/postback/${postbackNo}`
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("포스트백이 삭제되었습니다.");
        invalidate("coupang/useGetPostbackList");
      },
    }
  );

  return result;
};

export default useDeletePostback;
