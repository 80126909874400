export const MEDIA = {
  ALL: 0,
  APP: 1,
  WEB: 2,
} as const;

export type Media = Union<typeof MEDIA>;

export const MEDIA_ALIAS = {
  [MEDIA.ALL]: "전체",
  [MEDIA.APP]: "앱",
  [MEDIA.WEB]: "웹",
};
