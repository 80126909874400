import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  department?: string;
  email: string;
  password: string;
  phoneNo?: string;
  promotionAgreement: boolean;
  role?: number;
  token?: string;
  userId?: number;
  username: string;
}

interface Response {
  code: 200 | 400 | 401 | 403 | 409 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "forbidden" | "conflict" | "internal-server-error";
}

// 회원가입
const usePostSignUp = () => {
  const { handleError } = useApiError();
  const navigate = useNavigate();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({
      department,
      email,
      password,
      phoneNo,
      promotionAgreement,
      role,
      token,
      userId,
      username,
    }) => {
      const response: AxiosResponse<Response> = await API.default.post("/auth/signup", {
        department: department,
        email: email,
        password: password,
        phone_no: phoneNo,
        promotion_agreement: promotionAgreement,
        role: role,
        token: token,
        user_id: userId,
        username: username,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("가입이 완료되었습니다.");
        invalidate();
        navigate("/login", { replace: true });
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePostSignUp;
