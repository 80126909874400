import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useFormik } from "formik";
import { useMemo } from "react";
import { TextField } from "src/components/commons";
import useGetPartner from "src/hooks/apis/coupang/useGetPartner";
import usePutPartner from "src/hooks/apis/coupang/usePutPartner";
import { getHelperText, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { editPartnerModalStyle } from "./styles";

interface EditPartnerModalProps {
  onClose: () => void;
  open: { key: number; isOpen: boolean };
}

const EditPartnerModal = ({ onClose, open }: EditPartnerModalProps) => {
  const { data: partnerData } = useGetPartner({ partnerNo: open.key });

  const initialValues = useMemo(
    () => ({
      partnerNo: partnerData.partner.partner_no || 0,
      companyName: partnerData.partner.company_name || "",
      partnerName: partnerData.partner.partner_name || "",
      commission: partnerData.partner.ap_commission_rate || "",
      clientCommission: partnerData.partner.client_commission_rate || "",
    }),
    [
      partnerData.partner.ap_commission_rate,
      partnerData.partner.client_commission_rate,
      partnerData.partner.company_name,
      partnerData.partner.partner_name,
      partnerData.partner.partner_no,
    ]
  );
  const { mutate } = usePutPartner();

  const validationSchema = yup.object({
    partnerName: yup.string().required("파트너명을 입력해주세요."),
    commission: yup
      .string()
      .test("commission", "숫자를 입력해주세요.", (value = "") => !isNaN(+value))
      .required("애드팝콘에 대한 쿠팡 수수료를 선택해주세요."),
    clientCommission: yup
      .string()
      .test("clientCommission", "숫자를 입력해주세요.", (value = "") => !isNaN(+value))
      .required("고객사 리포트에 적용될 고객사에 대한 쿠팡 수수료를 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate({
        partnerNo: values.partnerNo,
        partnerName: values.partnerName,
        commission: +values.commission,
        clientCommission: +values.clientCommission,
      });
      onClose();
    },
  });

  return (
    <Dialog
      css={editPartnerModalStyle}
      fullWidth
      maxWidth="md"
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-coupang-partner"
      aria-describedby="add coupang partner"
    >
      <DialogTitle id="dialog-title">{partnerData.partner.partner_name}</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="edit-form" onSubmit={handleSubmit}>
          <Grid container spacing={2} rowSpacing={4}>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="업체명"
                placeholder="업체를 입력해주세요."
                required
                disabled
                {...getFieldProps("companyName")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="파트너명"
                placeholder="사용하실 파트너명을 입력해주세요."
                required
                {...getFieldProps("partnerName")}
                error={shouldErrorShows("partnerName", touched, errors)}
                helperText={getHelperText("partnerName", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="쿠팡 X 애드팝콘 수수료율"
                placeholder="애드팝콘에 대한 쿠팡 수수료"
                required
                {...getFieldProps("commission")}
                error={shouldErrorShows("commission", touched, errors)}
                helperText={
                  shouldErrorShows("commission", touched, errors)
                    ? getHelperText("commission", touched, errors)
                    : "* 해당 수수료율은 쿠팡에 대한 애드팝콘의 매출을 계산하는데 사용됩니다."
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="쿠팡 X 고객사 수수료율"
                placeholder="고객사 리포트에 적용될 고객사에 대한 쿠팡 수수료"
                required
                {...getFieldProps("clientCommission")}
                error={shouldErrorShows("clientCommission", touched, errors)}
                helperText={
                  shouldErrorShows("clientCommission", touched, errors)
                    ? getHelperText("clientCommission", touched, errors)
                    : "* 해당 수수료율은 고객사의 리포트에 노출될 수익을 계산하는데 사용됩니다."
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPartnerModal;
