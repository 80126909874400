import { css } from "@emotion/react";
import { Container, Typography, Paper, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import useNpayCsDetail from "src/hooks/apis/npay/use-npay-cs-detail";
import { TYPE_OPTIONS } from "../const";

const clientInfoStyle = css`
  .label {
    font-weight: 500;
  }
  .value {
    color: ${grey[600]};
  }
`;

export default function CsClientInfo() {
  const { id } = useParams();
  const navigate = useNavigate();

  const query = useNpayCsDetail(
    { id: id as string },
    {
      onError: (error) => {
        if (error.response?.status === 404) {
          navigate("/404", { replace: true });
        }
      },
    }
  );

  return (
    <Container component="section" className="ssp-section" css={clientInfoStyle} maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        문의자 정보
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid container className="info" spacing={4}>
          <Grid item xs={1} className="label">
            NO
          </Grid>
          <Grid item xs={5} className="value">
            {id}
          </Grid>
          <Grid item xs={1} className="label">
            문의 일자
          </Grid>
          <Grid item xs={5} className="value">
            {query.data ? moment.unix(query.data.created_at).format("YYYY-MM-DD HH:mm:ss") : ""}
          </Grid>
          <Grid item xs={1} className="label">
            앱 매체 명
          </Grid>
          <Grid item xs={5} className="value">
            {query.data ? query.data.media_name : ""}
          </Grid>
          <Grid item xs={1} className="label">
            미디어 키
          </Grid>
          <Grid item xs={5} className="value">
            {query.data ? query.data.media_key : ""}
          </Grid>
          <Grid item xs={1} className="label">
            네이버 ID
          </Grid>
          <Grid item xs={5} className="value">
            {query.data?.naver_id ? query.data.naver_id : ""}
          </Grid>
          <Grid item xs={1} className="label">
            USN
          </Grid>
          <Grid item xs={5} className="value">
            {query.data ? query.data.usn : ""}
          </Grid>
          <Grid item xs={1} className="label">
            문의항목
          </Grid>
          <Grid item xs={5} className="value">
            {/* TODO: convert type -> string */}
            {query.data
              ? TYPE_OPTIONS.find((option) => option.value === query.data?.type)?.label
              : ""}
          </Grid>
          <Grid item xs={1} className="label">
            Email
          </Grid>
          <Grid item xs={5} className="value">
            {query.data ? query.data.email : ""}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
