import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Campaign } from "src/types/campaign";
import API from "src/utils/api";

interface Params {
  types: Campaign[];
}

export interface AdFormat {
  height: number;
  id: number;
  label: string;
  type: Campaign;
  width: number;
}

interface Response {
  code: 200;
  data?: {
    formats: AdFormat[];
  };
  text: "ok";
}

// 주SSP 플레이스먼트(인벤토리) 포맷 리스트 조회
const useGetAdFormatInfo = (params: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["info/useGetAdFormatInfo", params.types],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/info/ad-formats`, {
        params: {
          types: params.types.join(","),
        },
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => (data ? data : { formats: [] }), [data]);

  return { data: result, ...rest };
};

export default useGetAdFormatInfo;
