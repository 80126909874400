import { css } from "@emotion/react";
import { grey, teal } from "@mui/material/colors";

export const dashboardStyle = css`
  .content {
    .tabs {
      margin-bottom: 2rem;
    }
  }
`;

// 카드
export const cardStyle = css`
  cursor: pointer;
  border: 1px solid #fff;
  margin-bottom: 1rem;
  .card-title span {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${teal[500]};
  }
  .card-content {
    text-align: right;
    font-weight: 600;
    color: ${grey[600]};
  }
  &:hover {
    border: 1px solid ${teal[400]};
    .card-title span {
      font-size: 1.2rem;
      font-weight: 600;
      color: ${teal[300]};
    }
    .card-content {
      color: ${teal[300]};
    }
  }
`;

// 도넛 차트
export const chartStyle = css`
  .sub-title {
    font-size: 0.8rem;
    color: ${grey[600]};
  }
  .chart {
    padding: 24px;
  }
`;

// 커스텀 툴팁
export const tooltipStyle = css`
  padding: 1rem;

  .title {
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }
  .content {
    color: ${grey[700]};
    margin-top: 0.5rem;
    padding: 0;
  }
`;

// 퍼블리셔 대시보드
export const publisherStyle = css`
  &:last-child {
    margin-top: 2rem;
  }
`;

// DSP 일자별 리포트
export const dspDailyReportStyle = css``;

// DSP 시간별 대시보드 / 헤더비딩 대시보드 / WEB 미디에이션 대시보드
export const hourlyReportStyle = css`
  margin-top: 2rem;
`;

// DSP 시간별 대시보드 / 헤더비딩 대시보드 / WEB 미디에이션 대시보드 커스텀 툴팁
export const subDashboardTooltipStyle = css`
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  max-width: 500px;

  .title {
    width: 100%;
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }
  .content {
    color: ${grey[700]};
    margin-top: 0.5rem;
    padding: 0;
  }
`;
