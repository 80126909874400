export const DASHBOARD_LABEL = {
  impression: "Impression 노출수",
  click: "Click 클릭수",
  ctr: "CTR",
  ecpm: "eCPM",
  revenue: "Revenue 수입",
  request: "Request 요청수",
  response: "Response 응답수",
  rpr: "RPR",
  adv_revenue: "Revenue 수입",
};
