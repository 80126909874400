import { css } from "@emotion/react";
import { grey, teal } from "@mui/material/colors";

export const dashboardStyle = css`
  margin-top: 3rem;

  .table-container {
    margin-top: 1rem;
  }
`;

// 카드
export const cardStyle = css`
  border: 1px solid #fff;
  margin-bottom: 1rem;
  .card-title span {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${teal[500]};
  }
  .card-content {
    text-align: right;
    font-weight: 600;
    color: ${grey[600]};
  }
`;
