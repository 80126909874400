import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import useGetCompanyApproval from "src/hooks/apis/companies/useGetCompanyApproval";
import usePatchRefusedPopup from "src/hooks/apis/companies/usePatchRefusedPopup";
import useGetUserDetail from "src/hooks/apis/users/useGetUserDetail";
import { refusedModalStyle } from "./styles";

interface RefusedApprovalModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const RefusedApprovalModal = ({ isOpen, onClose }: RefusedApprovalModalProps) => {
  const { data: userData } = useGetUserDetail({ userId: "me" });
  const { data: approvalData } = useGetCompanyApproval({ companyId: userData.user.company_id });
  const { mutate } = usePatchRefusedPopup();

  const onClosePopup = () => {
    onClose();
    mutate({ companyId: userData.user.company_id });
  };

  return (
    <Dialog
      css={refusedModalStyle}
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">관리자 등록 승인 결과</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="body1" gutterBottom>
            관리자 등록 시 아래와 같은 사유로 승인이 거절되었습니다.
          </Typography>
          <Typography className="reason" variant="body1">
            <span>Message: </span>
            <span>{approvalData.reject_message}</span>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClosePopup}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RefusedApprovalModal;
