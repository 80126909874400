import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { Status } from "src/types";
import API from "src/utils/api";

interface Params {
  categories: {
    code: string;
    status: Status; // 차단(10) or 허용(30)
  }[];
  placementId: string;
}

interface Response {
  code: 200 | 400 | 401;
  text: "ok" | "bad-request" | "unauthorized";
}

// 카테고리 광고 허용 및 차단
const usePutCategories = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ categories, placementId }) => {
      const response: AxiosResponse<Response> = await API.default.put("/blocklist/categories", {
        categories,
        placement_id: placementId,
      });
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("수정되었습니다.");
        invalidate("blocklist/useGetCategories");
      },
    }
  );

  return result;
};

export default usePutCategories;
