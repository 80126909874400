import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { AppPlatform, Status } from "src/types";
import API from "src/utils/api";

interface Params {
  mediaKey: string;
  appstoreId?: number;
  blockDomainListSet?: number[];
  name: string;
  packageName: string;
  platformType?: AppPlatform;
  status?: Status;
  urlScheme: string;
}

interface Response {
  code: 200 | 400 | 401 | 404 | 409 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "not-found" | "conflict" | "internal-server-error";
}

// 앱 매체 수정
const usePatchApp = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({
      mediaKey,
      appstoreId,
      blockDomainListSet,
      name,
      packageName,
      platformType,
      status,
      urlScheme,
    }) => {
      const response: AxiosResponse<Response> = await API.default.patch(`/media/app/${mediaKey}`, {
        appstore_id: appstoreId,
        block_domain_list_set: blockDomainListSet,
        name: name,
        package_name: packageName,
        platform_type: platformType,
        status: status,
        url_scheme: urlScheme,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("수정되었습니다.");
        invalidate(["media/useGetAppMedia", "media/useGetAppMediaList"]);
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePatchApp;
