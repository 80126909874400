import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

export interface Partner {
  partner_no: number;
  company_name: string;
  company_key: string;
  partner_name: string;
  ap_commission_rate: number;
  client_commission_rate: number;
  subids: number;
}

interface Params {
  search?: string;
  pageNo: number;
  pageSize: number;
  orders?: string[];
}

interface Response {
  code?: 200 | 401 | 422;
  data?: Partner[];
  pages?: number;
  text?: "ok" | "unauthorized" | "unprocessable-entity";
}

/*
  파트너 전체 조회 및 파트너명 검색
*/
const useGetPartnerList = ({ search, pageNo, pageSize, orders }: Params) => {
  const { handleError } = useApiError();
  const { data: response, ...rest } = useQuery<Response, AxiosError>(
    ["coupang/useGetPartnerList", search, pageNo, pageSize, orders],
    async () => {
      const response: AxiosResponse<Response> = await API.integration.get(
        `/operation/management/coupang/partner`,
        {
          params: { q: search, page_no: pageNo, page_size: pageSize, order: orders?.join(",") },
        }
      );
      return response.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => {
    if (response && response.data)
      return {
        partners: response.data,
        pages: response.pages || 0,
      };
    return { partners: [], pages: 0 };
  }, [response]);

  return { data: result, ...rest };
};

export default useGetPartnerList;
