import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  partnerNo: number;
  partnerName: string;
  commission: number;
  clientCommission: number;
}

interface Response {
  code: 200 | 400 | 401 | 404 | 422;
  text:
    | "Successful Response"
    | "bad-request"
    | "unauthorized"
    | "not-found"
    | "unprocessable-entity";
}

// 쿠팡 파트너 수정
const usePutPartner = () => {
  const toast = useToast();
  const { handleError } = useApiError({
    409: (_error: AxiosError) => toast.error("입력하신 파트너명이 이미 존재합니다."),
  });
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ partnerNo, partnerName, commission, clientCommission }) => {
      const response: AxiosResponse<Response> = await API.integration.put(
        `/operation/management/coupang/partner/${partnerNo}`,
        {
          partner_name: partnerName,
          ap_commission_rate: commission,
          client_commission_rate: clientCommission,
        }
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("파트너가 수정되었습니다.");
        invalidate(["coupang/useGetPartner", "coupang/useGetPartnerList"]);
      },
    }
  );

  return result;
};

export default usePutPartner;
