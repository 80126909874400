import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { RowClickedEvent, SortChangedEvent, ValueFormatterParams } from "ag-grid-community";
import moment from "moment";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import { BasicTable } from "src/components/commons";
import { SearchField } from "src/components/commons/SearchFiled";
import useGetAppMediaList from "src/hooks/apis/media/useGetAppMediaList";
import useOpenModal from "src/hooks/useOpenModal";
import { AppPlatform, APP_PLATFORM_ALIAS, Status, STATUS_ALIAS } from "src/types";
import AddMediaModal from "./AddMediaModal";
import EditMediaModal from "./EditMediaModal";

const MediaBoard = () => {
  const [search, setSearch] = useState({ temp: "", value: "" });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [orders, setOrders] = useState<string[]>([]);

  const [openAddModal, onShowAddMediaModal, onCloseAddMediaModal] = useOpenModal(null);
  const [openEditModal, onShowEditMedia, onCloseEditMediaModal] = useOpenModal("");

  const [company] = useRecoilState(viewerCompany);
  const { data: mediaData } = useGetAppMediaList({
    companyKey: company.key,
    name: search.value,
    pageNo: pageNo,
    pageSize: pageSize,
    orders: orders,
  });

  const onChangeSearchWord = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearch((prev) => ({ ...prev, temp: e.target.value }));
  }, []);

  const onSearchMedia = useCallback(() => {
    setSearch((prev) => ({ ...prev, value: prev.temp }));
    setPageNo(1);
  }, []);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const onShowEditMediaModal = useCallback(
    (e: RowClickedEvent) => {
      onShowEditMedia(e, e.data.key);
    },
    [onShowEditMedia]
  );

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: mediaData.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [onChangePage, onChangePageSize, pageNo, pageSize, mediaData.pages]
  );

  const onSortChanged = useCallback((e: SortChangedEvent) => {
    const sortInfo = e.columnApi
      .getColumnState()
      .filter((column) => !!column.sort)
      .map(({ colId, sort }) => {
        if (sort === "desc") return `-${colId}`;
        return `+${colId}`;
      });
    setOrders(sortInfo);
  }, []);

  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        앱 매체 관리
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid className="ssp-tools" container>
          <Grid item xs={3}>
            <SearchField
              label="검색어"
              placeholder="매체명 / 매체키"
              value={search.temp}
              onChange={onChangeSearchWord}
              onClickSearchButton={onSearchMedia}
            />
          </Grid>
          <Grid item xs={9} className="register">
            <Button variant="outlined" onClick={onShowAddMediaModal}>
              신규 매체 등록
            </Button>
          </Grid>
        </Grid>
        <BasicTable
          rowData={mediaData.media}
          columnDefs={columnDefs}
          onRowClicked={onShowEditMediaModal}
          onSortChanged={onSortChanged}
          {...paginationInfo}
        />
      </Paper>
      {openAddModal.isOpen && (
        <AddMediaModal open={openAddModal.isOpen} onClose={onCloseAddMediaModal} />
      )}
      {openEditModal.isOpen && (
        <EditMediaModal open={openEditModal} onClose={onCloseEditMediaModal} />
      )}
    </Container>
  );
};

const columnDefs = [
  { headerName: "앱 매체 명", field: "name", sortable: true },
  { headerName: "매체키", field: "key", sortable: true },
  {
    headerName: "플랫폼",
    field: "platform_type",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const type = param.data.platform_type as AppPlatform;
      return APP_PLATFORM_ALIAS[type];
    },
  },
  {
    headerName: "상태",
    field: "status",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const status = param.data.status as Status;
      return STATUS_ALIAS[status];
    },
  },
  {
    headerName: "플레이스먼트",
    field: "placement_count",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      return `${param.data.placement_count || 0} 개`;
    },
  },
  {
    headerName: "등록일",
    field: "created_at",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const createdAt = param.data.created_at * 1000;
      return moment(createdAt).format("YYYY-MM-DD");
    },
  },
];

export default MediaBoard;
