import { Paper, Typography } from "@mui/material";
import { useCallback } from "react";
import { TooltipProps } from "recharts";
import DataFormat from "src/assets/formats";
import { tooltipStyle } from "./styles";

interface MediationTooltipProps extends TooltipProps<any, any> {
  total: number;
}

const MediationTooltip = ({ payload, total }: MediationTooltipProps) => {
  const toPercent = useCallback(
    (decimal: number, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`,
    []
  );
  const getPercent = useCallback(
    (value: number, total: number) => {
      const ratio = total > 0 ? value / total : 0;
      return toPercent(ratio, 2);
    },
    [toPercent]
  );

  return (
    <Paper css={tooltipStyle} elevation={2}>
      <Typography className="title" variant="h6">
        미디에이션별 수입
      </Typography>
      {payload?.map((entry, index) => (
        <p className="content" key={index} style={{ color: entry.payload.fill }}>
          {`${entry.name}: ${DataFormat.revenue.formatter(entry.value)} (${getPercent(
            entry.value,
            total
          )})`}
        </p>
      ))}
    </Paper>
  );
};
export default MediationTooltip;
