import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  accountNo: number;
  partnerNo: number;
  subIdAndSites: {
    subId: string;
    websites: string;
  }[];
}

interface Response {
  code?: 201 | 400 | 409 | 422 | 500;
  text?:
    | "CREATE_NEW_SUBID_SUCCESS"
    | "bad-request"
    | "conflict"
    | "field required"
    | "internal-server-error";
}

// 쿠팡 서브 ID 등록
// 주의) 자주 콜하면 limit 걸림.
const usePostCoupangSubIds = () => {
  const toast = useToast();
  const { handleError } = useApiError({
    500: (_error: AxiosError) => null,
  });
  const invalidate = useInvalidateQueries();
  const { data, ...rest } = useMutation<Response, AxiosError, Params>(
    async ({ accountNo, partnerNo, subIdAndSites }) => {
      const response: AxiosResponse<Response> = await API.integration.post(
        `/operation/management/coupang/subid/AddCoupangSubIds`,
        {
          account_no: accountNo,
          partner_no: partnerNo,
          subid_and_sites: subIdAndSites,
        }
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("Sub ID가 등록되었습니다.");
        invalidate("coupang/useGetCoupangSubIds");
      },
    }
  );

  return { ...rest };
};

export default usePostCoupangSubIds;
