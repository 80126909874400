import { AxiosError } from "axios";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useToast from "src/hooks/useToast";

interface Handler {
  [key: string | number]: (error: AxiosError) => void;
}

const useApiError = (handlers?: Handler) => {
  const navigate = useNavigate();
  const toast = useToast();

  const defaultHandlers: Handler = useMemo(
    () => ({
      // 공통 처리 로직 수행
      common: (error: AxiosError) => {
        console.error(error);
      },
      // 어디에서도 정의되지 못한 에러를 처리하는 핸들러
      default: (error: AxiosError) => {
        navigate("/error", {
          state: { message: error.message },
        });
      },
      400: (_error: AxiosError) => {
        toast.error("잘못된 요청입니다. (status: 400)");
      },
      401: (_error: AxiosError) => {
        toast.error("토큰이 만료되었습니다. (status: 401)");
        navigate("/login");
      },
      403: (_error: AxiosError) => {
        toast.error("forbidden (status: 403)");
      },
      404: (_error: AxiosError) => {
        toast.error("not-found (status: 404)");
      },
      409: (_error: AxiosError) => {
        toast.error("conflict (status: 409)");
      },
      422: (_error: AxiosError) => {
        toast.error("unprocessable-entity. (status: 422)");
      },
      500: (_error: AxiosError) => {
        toast.error("internal-server-error. (status: 500)");
      },
      502: (_error: AxiosError) => {
        toast.error("bad-gateway. (status: 502)");
      },
    }),
    [navigate, toast]
  );

  const handleError = useCallback(
    (error: AxiosError) => {
      if (error.response) {
        const httpStatus = error.response.status; // HTTP Status

        if (handlers && !!handlers[httpStatus]) {
          // 우선순위 1. 컴포넌트에서 (HTTP Status) Key로 재정의한 핸들러
          handlers[httpStatus](error);
        } else if (defaultHandlers[httpStatus]) {
          // 우선순위 2. Hook에서 (HTTP Status) Key로 정의한 핸들러
          defaultHandlers[httpStatus](error);
        } else {
          // 우선순위 3. 어디에서도 정의되지 못한 에러를 처리하는 핸들러
          defaultHandlers.default(error);
        }

        // 공통 처리 로직 수행
        defaultHandlers.common(error);
      }
    },
    [defaultHandlers, handlers]
  );

  return { handleError };
};

export default useApiError;
