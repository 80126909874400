import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Media } from "src/types";
import API from "src/utils/api";

export interface DSP {
  activated: boolean;
  campaign_types: number[];
  endpoint: string;
  id: number;
  media_type: Media;
  name: string;
  system_fee: number;
}

interface Params {
  type: Media;
  name?: string;
  pageNo: number;
  pageSize: number;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    pages: number;
    thirdparties: DSP[];
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 파트너 관리 > 타입별 서드파티 리스트 조회 (DSP)
const useGetDSPList = ({ type, name, pageNo = 1, pageSize = 100000 }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["thirdparties/useGetDSPList", type, name, pageNo, pageSize],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/thirdparties/dsps`, {
        params: {
          type,
          name,
          page_no: pageNo,
          page_size: pageSize,
        },
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => (data ? data : { pages: 0, thirdparties: [] }), [data]);

  return { data: result, ...rest };
};

export default useGetDSPList;
