import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { GridApi } from "ag-grid-community";
import { Moment } from "moment";
import { MouseEvent, useCallback, useState } from "react";
import { BasicTable } from "src/components/commons";
import { getColumnDefs } from "./Dashboard";
import { cardStyle, dashboardStyle } from "./styles";

interface AppDashboardProps {
  since: Moment;
  until: Moment;
  data?: {
    click: number;
    ecpm: number;
    impression: number;
    media_key: string;
    media_name: string;
    request: number;
    response: number;
    revenue: number;
  }[];
  cardInfo: {
    key: string;
    label: string;
    value: string;
  }[];
}

const AppDashboard = ({ since, until, data, cardInfo }: AppDashboardProps) => {
  const [requestGridApi, setRequestGridApi] = useState<GridApi>();
  const [revenueGridApi, setRevenueGridApi] = useState<GridApi>();

  const onExportRequest = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (requestGridApi) {
        requestGridApi.exportDataAsExcel({
          columnGroups: true,
          fileName: `전사대시보드_APP_요청수(${since.format("YYYYMMDD")}-${until.format(
            "YYYYMMDD"
          )}).xlsx`,
        });
      }
    },
    [requestGridApi, since, until]
  );

  const onExportRevenue = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (revenueGridApi) {
        revenueGridApi.exportDataAsExcel({
          columnGroups: true,
          fileName: `전사대시보드_APP_수입(${since.format("YYYYMMDD")}-${until.format(
            "YYYYMMDD"
          )}).xlsx`,
        });
      }
    },
    [revenueGridApi, since, until]
  );

  return (
    <Box css={dashboardStyle}>
      <Typography className="title" variant="h5" gutterBottom>
        앱
      </Typography>
      <Grid className="card-container" container spacing={2}>
        {cardInfo.map(({ label, value }) => (
          <Grid key={label} item xs={4}>
            <Card css={cardStyle} elevation={2}>
              <CardHeader className="card-title" title={label} />
              <CardContent>
                <Typography className="card-content" variant="h5" gutterBottom>
                  {value}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid className="table-container" container spacing={2}>
        <Grid container item xs={6}>
          <Grid className="tools download" item xs={12}>
            <Button variant="outlined" onClick={onExportRequest}>
              엑셀 다운로드
            </Button>
          </Grid>
          <BasicTable
            className="ag-theme-balham"
            columnDefs={getColumnDefs("request")}
            rowData={data}
            onGridReady={(e) => setRequestGridApi(e.api)}
          />
        </Grid>
        <Grid container item xs={6}>
          <Grid className="tools download" item xs={12}>
            <Button variant="outlined" onClick={onExportRevenue}>
              엑셀 다운로드
            </Button>
          </Grid>
          <BasicTable
            className="ag-theme-balham"
            columnDefs={getColumnDefs("revenue")}
            rowData={data}
            onGridReady={(e) => setRevenueGridApi(e.api)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppDashboard;
