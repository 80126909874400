import React from "react";
import { useSetRecoilState } from "recoil";
import { Toast, toast } from "src/atoms/toast";
import { v4 as uuidv4 } from "uuid";

const useToast = () => {
  const setToastList = useSetRecoilState(toast);

  const message = (
    type: Toast["type"],
    message: Toast["message"],
    duration: Toast["duration"],
    onClose: Toast["onClose"],
    className: Toast["className"] = ""
  ): void | Element | React.Component => {
    setToastList((prev) => [
      ...prev,
      {
        id: uuidv4(),
        type,
        message,
        duration,
        onClose,
        className,
      },
    ]);
  };

  const toastMsg = {
    success: (
      msg: Toast["message"],
      duration?: Toast["duration"],
      onClose?: Toast["onClose"],
      className?: Toast["className"]
    ) => message("success", msg, duration, onClose, className),
    error: (
      msg: Toast["message"],
      duration?: Toast["duration"],
      onClose?: Toast["onClose"],
      className?: Toast["className"]
    ) => message("error", msg, duration, onClose, className),
    warning: (
      msg: Toast["message"],
      duration?: Toast["duration"],
      onClose?: Toast["onClose"],
      className?: Toast["className"]
    ) => message("warning", msg, duration, onClose, className),
  };

  return toastMsg;
};

export default useToast;
