import { Container, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import DataFormat from "src/assets/formats";
import useGetCustomerService from "src/hooks/apis/coupang/useGetCustomerService";
import { APP_PLATFORM_ALIAS, STATUS } from "src/types";
import { clientInfoStyle } from "./styles";

const ServiceClientInfo = () => {
  const { id } = useParams();
  const { data: clientData } = useGetCustomerService({ csNo: id });

  const status = useMemo(() => {
    if (clientData.question.status === STATUS.ACTIVE) return "읽지 않음";
    if (clientData.question.status === STATUS.SUSPEND) return "읽음";
    if (clientData.question.status === STATUS.DELETED) return "답변 완료";
    return "";
  }, [clientData.question.status]);

  return (
    <Container component="section" className="ssp-section" css={clientInfoStyle} maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        문의자 정보
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid container className="info" spacing={3.5}>
          <Grid item xs={2.5} className="label">
            문의 일자
          </Grid>
          <Grid item xs={3.5} className="value">
            {moment(clientData.question.regist_datetime).format("YYYY-MM-DD HH:mm:ss")}
          </Grid>
          <Grid item xs={2.5} className="label">
            플랫폼
          </Grid>
          <Grid item xs={3.5} className="value">
            {APP_PLATFORM_ALIAS[clientData.question.platform]}
          </Grid>
          <Grid item xs={2.5} className="label">
            파트너명
          </Grid>
          <Grid item xs={3.5} className="value">
            {clientData.question.partner_name}
          </Grid>
          <Grid item xs={2.5} className="label">
            ADID(IDFA)
          </Grid>
          <Grid item xs={3.5} className="value">
            {clientData.question.adid || clientData.question.idfa}
          </Grid>
          <Grid item xs={2.5} className="label">
            캠페인명
          </Grid>
          <Grid item xs={3.5} className="value">
            {clientData.question.campaign_name}
          </Grid>
          <Grid item xs={2.5} className="label">
            SDK 버젼
          </Grid>
          <Grid item xs={3.5} className="value">
            {clientData.question.sdk_version}
          </Grid>
          <Grid item xs={2.5} className="label">
            Sub ID
          </Grid>
          <Grid item xs={3.5} className="value">
            {clientData.question.subids}
          </Grid>
          <Grid item xs={2.5} className="label">
            Email
          </Grid>
          <Grid item xs={3.5} className="value">
            {clientData.question.email}
          </Grid>
          <Grid item xs={2.5} className="label">
            캠페인 최소 금액
          </Grid>
          <Grid item xs={3.5} className="value">
            {`${DataFormat.purchaseRevenue.formatter(clientData.question.trigger_revenue)}`}
          </Grid>
          <Grid item xs={2.5} className="label">
            해당 캠페인 누적 구매 금액
          </Grid>
          <Grid item xs={3.5} className="value">
            {`${DataFormat.purchaseRevenue.formatter(clientData.question.total_order)}`}
          </Grid>
          <Grid item xs={2.5} className="label">
            상태
          </Grid>
          <Grid item xs={3.5} className="value">
            {status}
          </Grid>
          <Grid item xs={2.5} className="label">
            해당 캠페인 누적 취소 금액
          </Grid>
          <Grid item xs={3.5} className="value">
            {`${DataFormat.cancelRevenue.formatter(clientData.question.total_cancel)}`}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ServiceClientInfo;
