import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";
import { NpayCsStatus, NpayCsType } from "./use-npay-cs-list";

export type NpayCsDetailParams = {
  id: string;
};

export type NpayCsDetail = {
  company_key: string;
  company_name: string;
  created_at: number;
  email: string;
  id: number;
  image_url: string;
  inquiry_content: string;
  media_key: string;
  media_name: string;
  naver_id: string | null;
  response_content: string | null;
  sdk_version: string;
  status: NpayCsStatus;
  type: NpayCsType;
  updated_at: number;
  usn: string;
};

type NpayCsDetailData = {
  code: number;
  text: string;
  data: NpayCsDetail | null;
};

type NpayCsDetailQueryKey = ["operation/npay/cs-detail", NpayCsDetailParams];

type NpayCsDetailQueryFn = QueryFunction<AxiosResponse<NpayCsDetailData>, NpayCsDetailQueryKey>;

type NpayCsDetailQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<NpayCsDetailData>,
    AxiosError,
    AxiosResponse<NpayCsDetailData>,
    NpayCsDetailQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: NpayCsDetailData = {
  code: 200,
  text: "OK",
  data: null,
};

export default function useNpayCsDetail(
  params: NpayCsDetailParams,
  options?: NpayCsDetailQueryOptions
) {
  const { handleError } = useApiError();

  const queryKey = useMemo<NpayCsDetailQueryKey>(
    () => ["operation/npay/cs-detail", params],
    [params]
  );

  const queryFn = useCallback<NpayCsDetailQueryFn>((context) => {
    const [, { id }] = context.queryKey;
    const cookie = parseCookies();

    return API.consoleV3.get(`/v3/customer-services/naverpay/${id}`, {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    ...options,
    onError: (error) => {
      handleError(error);

      if (options?.onError) {
        options?.onError(error);
      }
    },
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
