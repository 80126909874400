export const DASHBOARD_REPORT = {
  REQUEST: 1,
  REVENUE: 2,
} as const;

export const REPORT = {
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
} as const;

export type DashboardReport = Union<typeof DASHBOARD_REPORT>;
export type Report = Union<typeof REPORT>;

export const REPORT_ALIAS = {
  [REPORT.DAILY]: "일별 리포트",
  [REPORT.WEEKLY]: "주별 리포트",
  [REPORT.MONTHLY]: "월별 리포트",
};

export const COUPANG_REPORT = {
  COUPANG: 0,
  POSTBACK: 1,
  POSTBACK_PUBLISHER: 2,
};

export type CoupangReport = Union<typeof COUPANG_REPORT>;

export const COUPANG_REPORT_ALIAS = {
  [COUPANG_REPORT.COUPANG]: "쿠팡 리포트",
  [COUPANG_REPORT.POSTBACK]: "포스트백",
  [COUPANG_REPORT.POSTBACK_PUBLISHER]: "포스트백(매체-퍼블리셔)",
};

export const PARTNER = {
  FYBER: 16,
  TAPJOY: 17,
  AyetStudios: 27,
};

export type Partner = Union<typeof PARTNER>;

export const PARTNER_ALIAS = {
  [PARTNER.FYBER]: "Fyber",
  [PARTNER.TAPJOY]: "Tapjoy",
  [PARTNER.AyetStudios]: "AyetStudios",
};
