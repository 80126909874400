import { Key } from "react";
import { atom } from "recoil";

export type ToastMessageType = "success" | "error" | "warning" | "info";

export interface Toast {
  id: Key;
  type: ToastMessageType;
  message: string;
  className?: string;
  duration?: number;
  onClose?: () => void;
}

export const toast = atom<Toast[]>({
  key: "toast",
  default: [],
});
