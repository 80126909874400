import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Status } from "src/types";
import API from "src/utils/api";

export type MatchKey = Record<string, string | number | boolean | null>;

export interface MatchKeyInfo {
  [key: string]: {
    related: string[];
    required: boolean;
    type: "string" | "float" | "integer" | "number" | "boolean";
  };
}

interface Params {
  thirdpartyId: number;
  mediaKeys: string[];
  placementIds: string[];
  matchType?: number;
  status?: Status;
  pageNo: number;
  pageSize: number;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    pages: number;
    thirdparties: {
      media_key: string;
      media_name: string;
      placements: {
        id: string;
        match_key: MatchKey;
        match_key_info: MatchKeyInfo[];
        name: string;
        passback_script: string;
        status: Status;
        type: number;
      }[];
    }[];
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 서드파티별 매칭정보 리스트 조회 (헤더비딩)
// 매체키, 플레이스먼트 고유번호, 매칭정보, 상태 필터는 전달하지 않을 경우 전체 조회
const usePostHeaderBiddingMatch = ({
  thirdpartyId,
  mediaKeys,
  placementIds,
  matchType,
  status,
  pageNo,
  pageSize,
}: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    "thirdparties/usePostHeaderBiddingMatch",
    async () => {
      const response: AxiosResponse<Response> = await API.default.post(
        `/thirdparties/headerbiddings/${thirdpartyId}`,
        {
          media_key: mediaKeys,
          placement_ids: placementIds,
          match_type: matchType,
          status: status,
          page_no: pageNo,
          page_size: pageSize,
        }
      );
      return response.data.data;
    },
    {
      enabled: !!mediaKeys && mediaKeys.length > 0 && !!placementIds && placementIds.length > 0,
      onError: handleError,
    }
  );

  const result = useMemo(() => (data ? data : { pages: 0, thirdparties: [] }), [data]);

  return { data: result, ...rest };
};

export default usePostHeaderBiddingMatch;
