import { Box, Button, ButtonProps, Popover, Typography } from "@mui/material";
import { MouseEvent, ReactNode, useCallback, useState } from "react";
import { buttonStyle, popoverStyle } from "./styles";

interface PopoverButtonProps extends ButtonProps {
  cancelButtonProps: ButtonProps;
  confirmButtonProps: ButtonProps;
  popoverContent: ReactNode;
}

const PopoverButton = ({
  cancelButtonProps,
  confirmButtonProps,
  popoverContent,
  children,
  ...props
}: PopoverButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onOpen = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  }, []);

  const onClose = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAnchorEl(null);
  }, []);

  const onCancel = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setAnchorEl(null);
      cancelButtonProps.onClick && cancelButtonProps.onClick(e);
    },
    [cancelButtonProps]
  );

  const onConfirm = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setAnchorEl(null);
      confirmButtonProps.onClick && confirmButtonProps.onClick(e);
    },
    [confirmButtonProps]
  );

  return (
    <>
      <Button css={buttonStyle} {...props} onClick={onOpen}>
        {children}
      </Button>
      <Popover
        css={popoverStyle}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: "center",
        }}
      >
        <Box className="content">
          <Typography variant="body1" className="message">
            {popoverContent}
          </Typography>
          <Box className="footer">
            <Button {...cancelButtonProps} onClick={onCancel} />
            <Button {...confirmButtonProps} onClick={onConfirm} />
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default PopoverButton;
