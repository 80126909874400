import { Button, Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import moment, { Moment } from "moment";
import { MouseEventHandler, useCallback, useMemo, useState } from "react";
import DataFormat from "src/assets/formats";
import { DateField, Select } from "src/components/commons";
import usePostCoupangReport from "src/hooks/apis/coupang/usePostCoupangReport";
import { CoupangReport, COUPANG_REPORT, COUPANG_REPORT_ALIAS } from "src/types";
import ReportBoard from "./ReportBoard";
import ReportChart from "./ReportChart";

const Report = () => {
  const [type, setType] = useState<CoupangReport>(COUPANG_REPORT.COUPANG);
  const [item, setItem] = useState<string>("click");
  const [since, setSince] = useState({
    temp: moment().subtract(7, "d"),
    current: moment().subtract(7, "d"),
  });

  const [until, setUntil] = useState({
    temp: moment().subtract(1, "d"),
    current: moment().subtract(1, "d"),
  });

  const { data } = usePostCoupangReport({
    coupangReportType: type,
    since: since.current,
    until: until.current,
  });

  const itemOptions = useMemo(
    () => ["click", "purchase", "purchaseRevenue", "cancel", "cancelRevenue", "totalRevenue"],
    []
  );

  const onChangeType = useCallback((event: SelectChangeEvent<string>) => {
    const value = +event.target.value;
    setType(value);
  }, []);

  const onChangeItem = useCallback((event: SelectChangeEvent<string>) => {
    setItem(event.target.value);
  }, []);

  const onChangeSinceDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setSince((prev) => ({ ...prev, temp: value }));
    }
    return;
  }, []);

  const onChangeUntilDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setUntil((prev) => ({ ...prev, temp: value }));
    }
    return;
  }, []);

  const onClickSearch = useCallback<MouseEventHandler>(() => {
    setSince((prev) => ({ ...prev, current: prev.temp }));
    setUntil((prev) => ({ ...prev, current: prev.temp }));
  }, []);

  return (
    <>
      <Grid className="ssp-tools" container spacing={2}>
        <Grid item xs={2}>
          <Select
            label="리포트"
            placeholder="리포트를 선택해주세요."
            onChange={onChangeType}
            value={`${type}`}
          >
            {Object.entries(COUPANG_REPORT_ALIAS).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={2}>
          <Select label="항목" placeholder="항목선택" onChange={onChangeItem} value={item}>
            {itemOptions.map((value) => (
              <MenuItem key={value} value={value}>
                {DataFormat[value].label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={2}>
          <DateField
            label="시작일"
            value={since.temp}
            maxDate={until.temp}
            onChange={onChangeSinceDate}
          />
        </Grid>
        <Grid item xs={2}>
          <DateField
            label="종료일"
            value={until.temp}
            minDate={since.temp}
            maxDate={moment().subtract(1, "d")}
            onChange={onChangeUntilDate}
          />
        </Grid>
        <Grid item xs={2}>
          <Button type="button" variant="contained" onClick={onClickSearch}>
            검색
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReportChart item={item} data={data.chartData} />
        </Grid>
        <Grid item xs={12}>
          <ReportBoard type={type} since={since.current} until={until.current} />
        </Grid>
      </Grid>
    </>
  );
};

export default Report;
