import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  email: string;
}

interface Response {
  code: 200 | 400 | 404;
  data?: {
    name: string;
    temp_password: string;
  };
  text: "ok" | "bad-request" | "not-found";
}

// 임시 비밀번호 생성 및 메일 발송
const useGetPassword = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { handleError } = useApiError({
    404: (_error: AxiosError) => toast.error("본 계정을 찾을 수 없습니다."),
  });
  const invalidate = useInvalidateQueries();
  const { data, ...rest } = useMutation<Response["data"], AxiosError, Params>(
    "auth/useGetPassword",
    async ({ email }) => {
      const response: AxiosResponse<Response> = await API.default.get(`/auth/password`, {
        params: { email },
      });
      return response.data.data;
    },
    {
      onSuccess: () => {
        toast.success("계정 이메일로 임시비밀번호가 발송되었습니다..");
        invalidate();
        navigate("/login");
      },
      onError: handleError,
    }
  );

  const result = useMemo(() => (data ? data : { name: "", temp_password: "" }), [data]);

  return { data: result, ...rest };
};

export default useGetPassword;
