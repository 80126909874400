import { Portal } from "@mui/material";
import { useRecoilValue } from "recoil";
import { toast } from "src/atoms/toast";
import { toastContainerStyle } from "./styles";
import ToastMsg from "./ToastMsg";

const ToastContainer = () => {
  const toastList = useRecoilValue(toast);

  return (
    <Portal>
      <div id="toast-container" css={toastContainerStyle}>
        {toastList.map((toast) => {
          return <ToastMsg key={toast.id} {...toast} />;
        })}
      </div>
    </Portal>
  );
};

export default ToastContainer;
