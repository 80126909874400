import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { ChangeEvent, SyntheticEvent, useCallback, useMemo, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { MultipleSelect } from "src/components/commons";
import useGetHistory from "src/hooks/apis/histories/useGetHistory";
import useGetHistoryDetail from "src/hooks/apis/histories/useGetHistoryDetail";
import { HISTORY, History, HISTORY_ALIAS } from "src/types/history";
import HistoryDetail from "./HistoryDetail";
import {
  accordionDetailStyle,
  accordionStyle,
  accordionSummaryStyle,
  historyModalStyle,
  paginationStyle,
} from "./styles";

interface HistoryModalProps {
  onClose: () => void;
  open: { key: string; isOpen: boolean };
}

const HistoryModal = ({ open, onClose }: HistoryModalProps) => {
  const options = useMemo(
    () =>
      Object.entries(HISTORY_ALIAS)
        .filter(([value]) => value !== HISTORY.HEADER_BIDDING)
        .map(([value, label]) => ({
          label: label,
          value: value as History,
        })),
    []
  );
  const [filter, setFilter] = useState<{ label: string; value: History }[]>(options);
  const [historyId, setHistoryId] = useState<number | undefined>();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const { data: historyData } = useGetHistory({ placementId: open.key, pageNo, pageSize });
  const { data: historyDetailData } = useGetHistoryDetail({ historyId });

  const onChangePage = useCallback((_event: ChangeEvent<unknown>, page: number) => {
    setPageNo(page);
  }, []);

  const onChangePageSize = useCallback((event: SelectChangeEvent<number>) => {
    setPageSize(+event.target.value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: historyData.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, historyData.pages, onChangePage, pageSize, onChangePageSize]
  );

  const onChangeFilter = (values: { label: string; value: History }[]) => {
    setFilter(values);
  };

  const handleChange =
    (panel: number) => (_event: SyntheticEvent<Element, Event>, expanded: boolean) => {
      setHistoryId(expanded ? panel : undefined);
    };

  return (
    <Dialog
      css={historyModalStyle}
      fullWidth
      maxWidth="md"
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-history"
      aria-describedby="view history"
    >
      <DialogTitle id="dialog-title">히스토리</DialogTitle>
      <DialogContent className="dialog-content">
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <MultipleSelect
              className="field"
              options={options}
              label="히스토리"
              placeholder="히스토리 선택"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={onChangeFilter}
              value={filter}
            />
          </Grid>
          <Grid item xs={12}>
            <Box className="history-list-container">
              <Accordion disableGutters elevation={0} square css={accordionStyle} disabled>
                <AccordionSummary css={accordionSummaryStyle}>
                  <Grid container>
                    <Grid item xs={4}>
                      일자
                    </Grid>
                    <Grid item xs={4}>
                      Type
                    </Grid>
                    <Grid item xs={4}>
                      User
                    </Grid>
                  </Grid>
                </AccordionSummary>
              </Accordion>
              <Box className="history-list-box">
                {historyData.histories
                  .filter(({ type }) => filter.some(({ value }) => value === type))
                  .map(({ history_id, user_id, type, updated_at }) => (
                    <Accordion
                      key={history_id}
                      disableGutters
                      elevation={0}
                      square
                      expanded={historyId === history_id}
                      onChange={handleChange(history_id)}
                      css={accordionStyle}
                    >
                      <AccordionSummary
                        css={accordionSummaryStyle}
                        expandIcon={<AiFillCaretDown />}
                        aria-controls="history-content"
                        id="history-header"
                      >
                        <Grid container>
                          <Grid item xs={4}>
                            {moment(updated_at * 1000).format("YYYY-MM-DD HH:mm:ss")}
                          </Grid>
                          <Grid item xs={4}>
                            {HISTORY_ALIAS[type]}
                          </Grid>
                          <Grid item xs={4}>
                            {user_id}
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      {historyId === history_id && (
                        <AccordionDetails css={accordionDetailStyle}>
                          <Grid container>
                            <Grid item xs={6} className="past">
                              <HistoryDetail
                                title="변경 전"
                                type={type}
                                history={historyDetailData.prev_contents}
                              />
                            </Grid>
                            <Grid item xs={6} className="current">
                              <HistoryDetail
                                title="변경 후"
                                type={type}
                                history={historyDetailData.curr_contents}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      )}
                    </Accordion>
                  ))}
              </Box>
            </Box>
            <Box css={paginationStyle}>
              <Pagination {...paginationInfo.pagination} onChange={onChangePage} shape="rounded" />
              <Select
                className="page-size"
                value={paginationInfo.pageSize.size}
                size="small"
                onChange={paginationInfo.pageSize.onChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
              >
                {paginationInfo.pageSize.options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default HistoryModal;
