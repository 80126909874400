export const APP_PLATFORM = {
  ALL: 0,
  iOS: 1,
  ANDROID: 2,
} as const;

export const WEB_PLATFORM = {
  ALL: 0,
  PC: 1,
  MOBILE: 2,
} as const;

export type AppPlatform = Union<typeof APP_PLATFORM>;
export type WebPlatform = Union<typeof WEB_PLATFORM>;

export const APP_PLATFORM_ALIAS = {
  [APP_PLATFORM.ALL]: "전체",
  [APP_PLATFORM.iOS]: "iOS",
  [APP_PLATFORM.ANDROID]: "Android",
};

export const WEB_PLATFORM_ALIAS = {
  [WEB_PLATFORM.ALL]: "전체",
  [WEB_PLATFORM.PC]: "PC",
  [WEB_PLATFORM.MOBILE]: "Mobile",
};
