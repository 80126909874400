import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useFormik } from "formik";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Select, TextField } from "src/components/commons";
import useGetCoupangAccounts from "src/hooks/apis/coupang/useGetCoupangAccounts";
import useGetSubIdPartnerList, { Partner } from "src/hooks/apis/coupang/useGetSubIdPartnerList";
import usePostCoupangSubIds from "src/hooks/apis/coupang/usePostCoupangSubIds";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { addSubIdModalStyle } from "./styles";

interface AddSubIdModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const AddSubIdModal = ({ onClose, isOpen }: AddSubIdModalProps) => {
  const [partner, setPartner] = useState<Partner | null>(null);
  const { data: partnerData } = useGetSubIdPartnerList();
  const { data: accountData } = useGetCoupangAccounts();
  const initialValues = useMemo(
    () => ({
      partnerNo: "",
      accountNo:
        accountData.accountList.length > 0 ? accountData.accountList[0].account_no : undefined,
      subId: "",
      websites: "",
    }),
    [accountData.accountList]
  );
  const { error, isSuccess, mutate } = usePostCoupangSubIds();

  useEffect(() => {
    if (isSuccess) onClose();
  }, [isSuccess, onClose]);

  const validationSchema = yup.object({
    partnerNo: yup.string().matches(REG_EXP.number).required("파트너를 선택해주세요."),
    accountNo: yup.string().matches(REG_EXP.number).required("계정을 선택해주세요."),
    subId: yup
      .string()
      .matches(REG_EXP.subId, "8 ~ 20자의 영문자, 숫자만 입력해 주세요.")
      .required("Sub ID를 입력해주세요."),
    websites: yup.string().required("URL을 입력해주세요."),
  });

  const { getFieldProps, setFieldValue, handleSubmit, touched, errors, values, setFieldError } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: (values) => {
        mutate({
          accountNo: +(values.accountNo || 0),
          partnerNo: +(values.partnerNo || 0),
          subIdAndSites: [{ subId: values.subId, websites: values.websites }],
        });
      },
    });

  useEffect(() => {
    if (error?.response?.status === 500) setFieldError("subId", "이미 등록된 SUB ID 입니다.");
  }, [error, setFieldError]);

  const onChangeAccount = useCallback(
    (event: SelectChangeEvent<string>) => {
      setFieldValue("accountNo", event.target.value);
    },
    [setFieldValue]
  );

  const onChangePartner = useCallback(
    (_: SyntheticEvent<unknown>, v: Partner | null) => {
      setPartner(v);
      setFieldValue("partnerNo", v?.partner_no);
    },
    [setFieldValue]
  );

  return (
    <Dialog
      css={addSubIdModalStyle}
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-coupang-subId"
      aria-describedby="add coupang subId"
    >
      <DialogTitle id="dialog-title">Sub ID 신규 등록</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="add-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select
                label="계정"
                placeholder="계정을 선택해주세요."
                required
                onChange={onChangeAccount}
                value={`${values.accountNo}`}
              >
                {accountData.accountList.map((account) => (
                  <MenuItem key={account.account_no} value={account.account_no}>
                    {account.account_id}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                className="field"
                size="small"
                options={partnerData.partners}
                getOptionLabel={(partner) => partner.partner_name}
                renderInput={({ InputLabelProps, ...params }) => (
                  <TextField
                    {...params}
                    label="파트너"
                    placeholder="파트너를 선택해주세요."
                    required
                    error={shouldErrorShows("partnerNo", touched, errors)}
                    helperText={getHelperText("partnerNo", touched, errors)}
                  />
                )}
                value={partner}
                onChange={onChangePartner}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="Sub ID"
                placeholder="Sub ID를 입력하세요. (8글자 이상 20글자 이하)"
                required
                {...getFieldProps("subId")}
                error={shouldErrorShows("subId", touched, errors)}
                helperText={getHelperText("subId", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="URL"
                placeholder="URL을 입력하세요."
                required
                {...getFieldProps("websites")}
                error={shouldErrorShows("websites", touched, errors)}
                helperText={getHelperText("websites", touched, errors)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="add-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSubIdModal;
