import { Paper, Typography } from "@mui/material";
import { useCallback } from "react";
import { TooltipProps } from "recharts";
import { DASHBOARD_LABEL } from "src/assets/alias/dashboard";
import { numberFormatter } from "src/utils/form-helper";
import { DataKey } from "./Dashboard";
import { tooltipStyle } from "./styles";

interface PlatformTooltipProps extends TooltipProps<any, any> {
  total: number;
  dataKey: DataKey;
}

const PlatformTooltip = ({ payload, total, dataKey }: PlatformTooltipProps) => {
  const toPercent = useCallback(
    (decimal: number, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`,
    []
  );
  const getPercent = useCallback(
    (value: number, total: number) => {
      const ratio = total > 0 ? value / total : 0;
      return toPercent(ratio, 2);
    },
    [toPercent]
  );

  return (
    <Paper css={tooltipStyle} elevation={2}>
      <Typography className="title" variant="h6">
        플랫폼별 {DASHBOARD_LABEL[dataKey]}
      </Typography>
      {payload?.map((entry, index) => (
        <p className="content" key={index} style={{ color: entry.payload.fill }}>
          {`${entry.name}: ${numberFormatter(entry.value)} (${getPercent(entry.value, total)})`}
        </p>
      ))}
    </Paper>
  );
};
export default PlatformTooltip;
