import { css } from "@emotion/react";
import { teal } from "@mui/material/colors";

export const tableStyle = css`
  a {
    text-decoration: none;
    color: ${teal[500]};
  }
`;

export const optionStyle = css`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  position: relative;
  .page-size {
    position: absolute;
    top: 0;
    right: 0;
    & > div {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
`;
