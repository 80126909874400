import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  GridApi,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import moment, { Moment } from "moment";
import { MouseEvent, MouseEventHandler, useCallback, useMemo, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { DASHBOARD_LABEL } from "src/assets/alias/dashboard";
import COLORS from "src/assets/colors";
import DataFormat from "src/assets/formats";
import { BasicTable, DateField } from "src/components/commons";
import useGetAdminDaily, { DataKey } from "src/hooks/apis/dashboard/useGetAdminDaily";
import { THIRDPARTY } from "src/types";
import { cardStyle, hourlyReportStyle } from "./styles";
import SubDashboardTooltip from "./SubDashboardTooltip";

const WebMediationDashboard = () => {
  const [since, setSince] = useState({
    temp: moment().subtract(7, "d"),
    current: moment().subtract(7, "d"),
  });

  const [until, setUntil] = useState({
    temp: moment().subtract(1, "d"),
    current: moment().subtract(1, "d"),
  });
  const [dataKey, setDataKey] = useState<DataKey>("impression");
  const [gridApi, setGridApi] = useState<GridApi>();
  const { data, range, cardData, calculator } = useGetAdminDaily({
    dataKey: dataKey,
    type: THIRDPARTY.WEB_MEDIATION,
    since: since.current,
    until: until.current,
  });

  const onChangeSinceDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setSince((prev) => ({ ...prev, temp: value }));
    }
    return;
  }, []);

  const onChangeUntilDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setUntil((prev) => ({ ...prev, temp: value }));
    }
    return;
  }, []);

  const onClickSearch = useCallback<MouseEventHandler>(() => {
    setSince((prev) => ({ ...prev, current: prev.temp }));
    setUntil((prev) => ({ ...prev, current: prev.temp }));
  }, []);

  const onClickCard = (value: DataKey) => (e: MouseEvent) => {
    e.preventDefault();
    setDataKey(value);
  };

  const chartData = useMemo(() => {
    const result: Record<string, unknown>[] = range.map((d) => ({ name: d })) || [];
    return result.map((item, idx) => {
      data.forEach(({ name, data }) => {
        item[name] = data[idx];
      });
      return item;
    });
  }, [data, range]);

  const cardInfo: { key: DataKey; label: string; value: string }[] = useMemo(() => {
    const { click, ecpm, impression, response, revenue } = cardData;
    return [
      {
        key: "response",
        label: DASHBOARD_LABEL["response"],
        value: DataFormat.response.formatter(response),
      },
      {
        key: "impression",
        label: DASHBOARD_LABEL["impression"],
        value: DataFormat.impression.formatter(impression),
      },
      {
        key: "click",
        label: DASHBOARD_LABEL["click"],
        value: DataFormat.click.formatter(click),
      },
      {
        key: "ecpm",
        label: DASHBOARD_LABEL["ecpm"],
        value: DataFormat.ecpm.formatter(ecpm),
      },
      {
        key: "revenue",
        label: DASHBOARD_LABEL["revenue"],
        value: DataFormat.revenue.formatter(revenue),
      },
    ];
  }, [cardData]);

  const totalRowData = useMemo(() => {
    const totalData = data.reduce(
      (acc, cur) =>
        acc.map((v, idx) => {
          return v + (cur.data[idx] ?? 0);
        }),
      Array.from(range).map(() => 0)
    );
    return {
      name: "총합",
      data: data.reduce(
        (acc, cur) =>
          acc.map((v, idx) => {
            return v + (cur.data[idx] ?? 0);
          }),
        Array.from(range).map(() => 0)
      ),
      total: totalData.reduce((acc, cur) => acc + cur, 0),
      dod: calculator(totalData.slice(-2)),
      wow: calculator([totalData[0], totalData[totalData.length - 1]]),
    };
  }, [calculator, data, range]);

  const onExport = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (gridApi) {
        gridApi.exportDataAsExcel({
          columnGroups: true,
          fileName: `WEB_미디에이션_대시보드(${since.current.format(
            "YYYYMMDD"
          )}-${until.current.format("YYYYMMDD")}).xlsx`,
        });
      }
    },
    [gridApi, since, until]
  );

  return (
    <Box css={hourlyReportStyle}>
      <Typography className="title" variant="h5" gutterBottom>
        미디에이션 대시보드
      </Typography>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={4}>
          <DateField
            label="시작일"
            value={since.temp}
            maxDate={until.temp}
            onChange={onChangeSinceDate}
          />
        </Grid>
        <Grid item xs={4}>
          <DateField
            label="종료일"
            value={until.temp}
            minDate={since.temp}
            maxDate={moment().subtract(1, "d")}
            onChange={onChangeUntilDate}
          />
        </Grid>
        <Grid item xs={2}>
          <Button type="button" variant="contained" onClick={onClickSearch}>
            검색
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {cardInfo.map(({ key, label, value }, i) => (
          <Grid key={label} item xs={i < 3 ? 4 : 6}>
            <Card css={cardStyle} elevation={2} onClick={onClickCard(key)}>
              <CardHeader className="card-title" title={label} />
              <CardContent>
                <Typography className="card-content" variant="h5" gutterBottom>
                  {value}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <ResponsiveContainer height={600}>
        <LineChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 64,
            right: 30,
            left: 20,
            bottom: 64,
          }}
        >
          <text x={"50%"} y={30} fill={grey[600]} textAnchor="middle" dominantBaseline="central">
            <tspan fontSize={16}>{DASHBOARD_LABEL[dataKey]}</tspan>
          </text>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Legend
            wrapperStyle={{
              paddingTop: 32,
            }}
          />
          {data?.map(({ name }, idx) => (
            <Line
              key={name}
              type="monotone"
              dataKey={name}
              stroke={COLORS[idx % COLORS.length]}
              activeDot={{ r: 8 }}
            />
          ))}
          <Tooltip
            label="name"
            content={(params) => <SubDashboardTooltip {...params} dataKey={dataKey} />}
          />
        </LineChart>
      </ResponsiveContainer>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={12} className="download">
          <Button variant="outlined" onClick={onExport}>
            엑셀 다운로드
          </Button>
        </Grid>
      </Grid>
      <BasicTable
        className="ag-theme-balham"
        columnDefs={getColumnDefs(range, dataKey)}
        rowData={data}
        onGridReady={(e) => setGridApi(e.api)}
        onGridColumnsChanged={(e) => {
          totalRowData && e.api.setPinnedTopRowData([totalRowData]);
        }}
        getRowStyle={(params) => {
          if (params.node.rowPinned) {
            return { fontWeight: "bold" };
          }
        }}
      />
    </Box>
  );
};

const getColumnDefs = (
  range?: string[],
  dataKey: DataKey = "impression"
): (ColDef | ColGroupDef)[] =>
  range
    ? [
        {
          headerName: "미디에이션 ⧵ 일자",
          field: "name",
          minWidth: 250,
          pinned: true,
          cellStyle: (param: CellClassParams) => {
            if (param.data.name === "총합") {
              return {
                color: red[500],
                background: "rgb(233,236,239,0.5)",
              };
            }
          },
        },
        {
          headerName: "총합",
          field: "total",
          minWidth: 150,
          maxWidth: 250,
          valueFormatter: (params: ValueFormatterParams) => {
            try {
              if (Number.isNaN(Number.parseFloat(params.value))) {
                throw new Error("@value should be a number or string presenting a number.");
              }
              return `${DataFormat[dataKey].formatter(params.value)}`;
            } catch {
              return "-";
            }
          },
          type: "rightAligned",
          cellStyle: (param: CellClassParams) => {
            if (param.data.name === "총합") {
              return {
                background: "rgb(233,236,239,0.5)",
              };
            }
          },
        },
        ...Array.from(range).map((date, idx) => ({
          headerName: date,
          field: "data",
          minWidth: 150,
          maxWidth: 150,
          type: "rightAligned",
          valueFormatter: (params: ValueFormatterParams) => {
            try {
              if (Number.isNaN(Number.parseFloat(params.value))) {
                throw new Error("@value should be a number or string presenting a number.");
              }
              return `${DataFormat[dataKey].formatter(params.value)}`;
            } catch {
              return "-";
            }
          },
          valueGetter: (param: ValueGetterParams) => {
            return param.data.data[idx];
          },
          cellStyle: (param: CellClassParams) => {
            if (param.data.name === "총합") {
              return {
                background: "rgb(233,236,239,0.5)",
              };
            }
          },
        })),
      ]
    : [];

export default WebMediationDashboard;
