import { destroyCookie, parseCookies, setCookie } from "nookies";
import { AtomEffect } from "recoil";

export const localStorageEffect =
  (key: string): AtomEffect<any> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const cookieEffect =
  (key: string): AtomEffect<any> =>
  ({ setSelf, onSet }) => {
    const cookie = parseCookies();
    const savedValue = cookie[key];
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? destroyCookie(null, key, { path: "/" })
        : setCookie(null, key, JSON.stringify(newValue), { path: "/" });
    });
  };
