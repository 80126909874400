import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { MEDIA, Media } from "src/types";
import API from "src/utils/api";
import useGetUserDetail from "../users/useGetUserDetail";

interface Params {
  companyKey?: string;
  type?: Media;
  isTest?: boolean;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    media_type: Media;
    report: {
      company_key: string;
      revenue: number;
      thirdparty_id: number;
    }[];
    revenue_summary: {
      month: number;
      week: number;
      yesterday: number;
    };
    thirdparties: {
      thirdparty_id: number;
      thirdparty_name: string;
    }[];
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 대시보드 수익 내역 (이번달, 최근 7일, 어제, 미디에이션별) 조회
// 레포트 데이터, 프로시저가 아직 작업 안됨, is_test=true 전달 시 mockup 데이터 전달
const useGetRevenue = ({ companyKey, type, isTest = false }: Params) => {
  const { handleError } = useApiError();
  const { data: userData } = useGetUserDetail({ userId: "me" });
  companyKey = companyKey || userData?.user.company_key;
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["dashboard/useGetRevenue", companyKey, type, isTest],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/dashboard/revenue`, {
        params: {
          company_key: companyKey,
          type: type,
          is_test: isTest,
        },
      });
      return response.data.data;
    },
    {
      enabled: !!companyKey,
      onError: handleError,
    }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            media_type: MEDIA.APP,
            report: [],
            revenue_summary: {
              month: 0,
              week: 0,
              yesterday: 0,
            },
            thirdparties: [],
          },
    [data]
  );

  return { data: result, ...rest };
};

export default useGetRevenue;
