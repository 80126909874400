import {
  Autocomplete,
  Button,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { ColDef, ColGroupDef, ValueFormatterParams } from "ag-grid-community";
import { useFormik } from "formik";
import moment, { Moment } from "moment";
import { ChangeEvent, SyntheticEvent, useCallback, useMemo } from "react";
import { BiDownload, BiRefresh } from "react-icons/bi";

import { BasicTable, DateField, Select, TextField } from "src/components/commons";
import usePostDataTools from "src/hooks/apis/datatools/usePostDataTools";
import { getHelperText, shouldErrorShows } from "src/utils/form-helper";
import {
  PROCEDURE,
  ProcedureRange,
  DataToolsReportType,
  PROCEDURE_ALIAS,
  PROCEDURE_RANGE,
  PROCEDURE_RANGE_ALIAS,
  PROCEDURE_TIME_RANGE,
  PROCEDURE_TIME_RANGE_ALIAS,
  DATA_TOOLS_REPORT_TYPE,
  DATA_TOOLS_REPORT_TYPE_ALIAS,
  SearchDate,
  SEARCH_DATE,
  SEARCH_DATE_ALIAS,
  Procedure,
  ProcedureTimeRange,
} from "src/types/datatools";
import useDataToolsHistory from "src/hooks/apis/datatools/use-data-tools-history";
import { DataToolsHistory, JobStatus } from "src/hooks/apis/datatools/types";
import usePostCreativeReport from "src/hooks/apis/datatools/use-post-creative-report";
import { dataToolsReportFormValidationSchema, defaultFormValuesByReportType } from "./const";
import usePostBidReport from "src/hooks/apis/datatools/use-post-bidreport";
import useGetDSPList, { DSP } from "src/hooks/apis/thirdparties/useGetDSPList";
import { MEDIA } from "src/types";
import useDataToolsNpayReport from "src/hooks/apis/datatools/use-data-tools-npay-report";

const DataTools = () => {
  const { data, refetch, isLoading, isFetching } = useDataToolsHistory();
  const { data: dspListData } = useGetDSPList({
    type: MEDIA.APP,
    pageNo: 1,
    pageSize: 10000,
  });

  const { mutate: postReport } = usePostDataTools();
  const { mutate: postCreativeReport } = usePostCreativeReport();
  const { mutate: postBidReport } = usePostBidReport();
  const { mutate: postNpayReport } = useDataToolsNpayReport();

  const initialValues = useMemo(
    () => defaultFormValuesByReportType[DATA_TOOLS_REPORT_TYPE.APP],
    []
  );

  const {
    getFieldProps,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    values,
    setValues,
    isValid,
  } = useFormik({
    initialValues,
    onSubmit: (values) => {
      // 크리에이티브 확인 리포트 생성
      if (values.reportType === DATA_TOOLS_REPORT_TYPE.CREATIVE) {
        postCreativeReport({
          start_date: values.since.format("YYYYMMDD"),
          end_date: values.until.format("YYYYMMDD"),
          advertising_id: values.adid as string,
          media_key: values.mediaKey as string,
          placement_ids: values.placementId ? [values.placementId] : [],
        });

        return;
      }

      if (values.reportType === DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING) {
        postBidReport({
          start_date: values.since.format("YYYYMMDD"),
          end_date: values.until.format("YYYYMMDD"),
          thirdparty_ids: values.thirdpartyIds || [],
        });

        return;
      }

      if (values.reportType === DATA_TOOLS_REPORT_TYPE.NPAY) {
        postNpayReport({
          start_date: values.since.format("YYYYMMDD"),
          end_date: values.until.format("YYYYMMDD"),
          limit: values.limit as 300 | 1000 | -1,
          procedure: values.procedure as 200 | 201 | 202,
        });

        return;
      }

      // 그 외 리포트 생성
      postReport({
        procedure: values.procedure as Procedure,
        since: values.since,
        until: values.until,
        timely:
          values.reportType === DATA_TOOLS_REPORT_TYPE.BIDDING &&
          values.range === PROCEDURE_RANGE.HOURLY
            ? (values.timely as ProcedureTimeRange)
            : undefined,
      });
    },
    validationSchema: dataToolsReportFormValidationSchema,
  });

  // 데이터 보고서 옵션
  const procedureOptions = useMemo(
    () =>
      Object.entries(PROCEDURE_ALIAS)
        .filter(
          ([, procedureMeta]) =>
            values.reportType === procedureMeta.type && values.range === procedureMeta.range
        )
        .map(([procedure, procedureMeta]) => ({ procedure: Number(procedure), ...procedureMeta })),
    [values.reportType, values.range]
  );

  // 분석 기간 옵션 사용 여부
  const useRangeOption = [
    DATA_TOOLS_REPORT_TYPE.APP,
    DATA_TOOLS_REPORT_TYPE.WEB,
    DATA_TOOLS_REPORT_TYPE.BIDDING,
  ].includes(values.reportType);

  // 상세 분석 기간 옵션 사용 여부
  const useTimelyOption =
    values.reportType === DATA_TOOLS_REPORT_TYPE.BIDDING && values.range === PROCEDURE_RANGE.HOURLY;

  // 데이터 보고서 옵션 사용 여부
  const useProcedureOption = values.reportType !== DATA_TOOLS_REPORT_TYPE.CREATIVE;

  // 데이터 조회 일자 퀵메뉴 사용 여부
  const useDateQuickMenu =
    [
      DATA_TOOLS_REPORT_TYPE.APP,
      DATA_TOOLS_REPORT_TYPE.WEB,
      DATA_TOOLS_REPORT_TYPE.COUPANG,
    ].includes(values.reportType) && values.range === PROCEDURE_RANGE.DAILY;

  // 데이터 조회 종료일 사용 여부
  const useUntilDate =
    values.range === PROCEDURE_RANGE.DAILY && values.reportType !== DATA_TOOLS_REPORT_TYPE.BIDDING;

  // 데이터 조회 최대 시작 날짜
  const maxSinceDate = useMemo(() => {
    const now = moment();
    const yesterday = moment().subtract(1, "d");

    const isCreativeReport = values.reportType === DATA_TOOLS_REPORT_TYPE.CREATIVE;
    const isDailyReport = values.range === PROCEDURE_RANGE.DAILY;

    if (isCreativeReport) {
      return now;
    }

    if (isDailyReport) {
      return yesterday;
    }

    return now;
  }, [values.range, values.reportType]);

  const maxUntilDate = useMemo(() => {
    const now = moment();
    const yesterday = moment().subtract(1, "d");
    const creativeReportMaxEndDate = moment(values.since).add(6, "d");
    const biddingReportMaxEndDate = moment(values.since).add(30, "d");

    const isDailyReport = values.range === PROCEDURE_RANGE.DAILY;
    const isCreativeReport = values.reportType === DATA_TOOLS_REPORT_TYPE.CREATIVE;
    const isBiddingReport = values.reportType === DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING;

    if (isCreativeReport) {
      return creativeReportMaxEndDate.isSameOrBefore(now) ? creativeReportMaxEndDate : now;
    }

    if (isBiddingReport) {
      return biddingReportMaxEndDate.isSameOrBefore(yesterday)
        ? biddingReportMaxEndDate
        : yesterday;
    }

    if (isDailyReport) {
      return yesterday;
    }

    return now;
  }, [values.range, values.reportType, values.since]);

  // 네이버페이 보고서 옵션
  const naverPayReportOptions = useMemo(
    () => [
      {
        label: "300명",
        value: 300,
      },
      {
        label: "1,000명",
        value: 1000,
      },
      {
        label: "전체",
        value: -1,
      },
    ],
    []
  );

  // 보고자 하는 항목 선택
  const onChangeType = useCallback(
    (e: ChangeEvent<HTMLInputElement>, v: unknown) => {
      e.preventDefault();

      const reportType = Number(v) as DataToolsReportType;
      setValues(defaultFormValuesByReportType[reportType]);
    },
    [setValues]
  );

  // 일자별 vs. 시간대별 (앱, 웹, 입찰 보고서에서 사용)
  const onChangeRange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, v: unknown) => {
      e.preventDefault();

      const canUseRangeOption = [
        DATA_TOOLS_REPORT_TYPE.APP,
        DATA_TOOLS_REPORT_TYPE.WEB,
        DATA_TOOLS_REPORT_TYPE.BIDDING,
      ].includes(values.reportType);

      if (!canUseRangeOption) {
        return;
      }

      const range = Number(v) as ProcedureRange;

      setValues((prev) => {
        // 일자별
        if (range === PROCEDURE_RANGE.DAILY) {
          return {
            ...defaultFormValuesByReportType[prev.reportType],
            range: range,
          };
        }

        // 시간대별
        switch (prev.reportType) {
          case DATA_TOOLS_REPORT_TYPE.APP:
            return {
              ...prev,
              range: range,
              since: moment(),
              until: moment(),
              procedure: PROCEDURE.APP_PLACEMENT_HOURLY,
            };

          case DATA_TOOLS_REPORT_TYPE.WEB:
            return {
              ...prev,
              range: range,
              since: moment(),
              until: moment(),
              procedure: PROCEDURE.WEB_PLACEMENT_HOURLY,
            };

          case DATA_TOOLS_REPORT_TYPE.BIDDING:
            return {
              ...prev,
              timely: PROCEDURE_TIME_RANGE.AM,
              range: range,
              since: moment(),
              until: moment(),
              procedure: PROCEDURE.APP_DSP_BIDDING_REPORT_HOURLY,
            };

          default:
            return prev;
        }
      });
    },
    [setValues, values.reportType]
  );

  // 상세 분석 기간 선택 (입찰 보고서 -> 시간대별)
  const onChangeTimely = useCallback(
    (e: ChangeEvent<HTMLInputElement>, v: unknown) => {
      e.preventDefault();

      if (!useTimelyOption) {
        return;
      }

      const timely = Number(v) as ProcedureTimeRange;
      setFieldValue("timely", timely);
    },
    [setFieldValue, useTimelyOption]
  );

  // 데이터 보고서 선택
  const onSelectProcedure = useCallback(
    (
      _: SyntheticEvent<unknown>,
      procedureOption: {
        type: number;
        name: string;
        fileName: string;
        range: number;
        procedure: number;
      } | null
    ) => {
      if (procedureOption) {
        setFieldValue("procedure", procedureOption.procedure);
      }
    },
    [setFieldValue]
  );

  // 데이터 보고서 선택
  const onSelectDsp = useCallback(
    (_: SyntheticEvent<unknown>, dspList: DSP[]) => {
      if (dspList) {
        setValues((prev) => ({
          ...prev,
          thirdpartyIds: dspList.map((dsp) => dsp.id),
        }));
      }
    },
    [setValues]
  );

  // 데이터 조회 일자 퀵메뉴 선택
  const onChangeDateMenu = useCallback(
    (e: ChangeEvent<HTMLInputElement>, v: unknown) => {
      e.preventDefault();
      const dateQuickMenu = Number(v) as SearchDate;

      setValues((prev) => {
        switch (dateQuickMenu) {
          case SEARCH_DATE.YESTERDAY:
            return {
              ...prev,
              dateQuickMenu,
              since: moment().subtract(1, "d"),
              until: moment().subtract(1, "d"),
            };

          case SEARCH_DATE.WEEK:
            return {
              ...prev,
              dateQuickMenu,
              since: moment().subtract(7, "d"),
              until: moment().subtract(1, "d"),
            };

          case SEARCH_DATE.MONTH:
            return {
              ...prev,
              dateQuickMenu,
              since: moment().subtract(30, "d"),
              until: moment().subtract(1, "d"),
            };

          default:
            return {
              ...prev,
              dateQuickMenu,
            };
        }
      });
    },
    [setValues]
  );

  // 데이터 조회 시작 일자 선택
  const onChangeSinceDate = useCallback(
    (newStartDate: Moment | null, _keyboardInputValue?: string) => {
      if (newStartDate && newStartDate.isValid()) {
        setValues((prev) => {
          const endDate = prev.until;
          const creativeReportMaxEndDate = moment(newStartDate).add(6, "d");
          const periodicBiddingReportMaxEndDate = moment(newStartDate).add(30, "d");

          const isValidEndDate = endDate.isSameOrAfter(newStartDate);
          const isCreativeReport = values.reportType === DATA_TOOLS_REPORT_TYPE.CREATIVE;
          const isHourlyReport = values.range === PROCEDURE_RANGE.HOURLY;
          const isBiddingReport = values.reportType === DATA_TOOLS_REPORT_TYPE.BIDDING;
          const isPeriodicBiddingReport =
            values.reportType === DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING;

          return {
            ...prev,
            dateQuickMenu: SEARCH_DATE.CUSTOM,
            since: newStartDate,
            until:
              // 시작 날짜가 종료 날짜보다 이후인 경우
              !isValidEndDate ||
              // 크리에이티브 확인 리포트이면서 종료 날짜가 크리에이티브 확인 리포트 최대 조회 기간 범위를 벗어난 경우
              (isCreativeReport && endDate.isAfter(creativeReportMaxEndDate)) ||
              // 입찰 보고서(기간 선택)이면서 종료 날짜가 보고서 최대 조회 기간 범위를 벗어난 경우
              (isPeriodicBiddingReport && endDate.isAfter(periodicBiddingReportMaxEndDate)) ||
              // 시간별 리포트인경우
              isHourlyReport ||
              // 입찰 보고서인 경우
              isBiddingReport
                ? newStartDate
                : endDate,
          };
        });
      }
    },
    [setValues, values.range, values.reportType]
  );

  // 데이터 조회 종료 일자 선택
  const onChangeUntilDate = useCallback(
    (value: Moment | null, _keyboardInputValue?: string) => {
      if (value && value.isValid()) {
        setValues((prev) => ({
          ...prev,
          dateQuickMenu: SEARCH_DATE.CUSTOM,
          until: value,
        }));
      }
    },
    [setValues]
  );

  const onChangeNpayLimit = useCallback(
    (e: SelectChangeEvent<300 | 1000 | -1>) => {
      setFieldValue("limit", e.target.value);
    },
    [setFieldValue]
  );

  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        데이터 툴즈
      </Typography>
      <Paper className="content" elevation={2}>
        <form onSubmit={handleSubmit}>
          <Grid className="ssp-tools" container spacing={2}>
            {/* 보고서 타입 */}
            <Grid item xs={2}>
              <Typography variant="body1" className="label">
                보고자 하는 항목
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <RadioGroup
                row
                className="field"
                aria-label="filter"
                {...getFieldProps("reportType")}
                onChange={onChangeType}
              >
                <FormControlLabel
                  value={DATA_TOOLS_REPORT_TYPE.APP}
                  control={<Radio />}
                  label={DATA_TOOLS_REPORT_TYPE_ALIAS[DATA_TOOLS_REPORT_TYPE.APP]}
                />
                <FormControlLabel
                  value={DATA_TOOLS_REPORT_TYPE.WEB}
                  control={<Radio />}
                  label={DATA_TOOLS_REPORT_TYPE_ALIAS[DATA_TOOLS_REPORT_TYPE.WEB]}
                />
                <FormControlLabel
                  value={DATA_TOOLS_REPORT_TYPE.COUPANG}
                  control={<Radio />}
                  label={DATA_TOOLS_REPORT_TYPE_ALIAS[DATA_TOOLS_REPORT_TYPE.COUPANG]}
                />
                <FormControlLabel
                  value={DATA_TOOLS_REPORT_TYPE.BIDDING}
                  control={<Radio />}
                  label={DATA_TOOLS_REPORT_TYPE_ALIAS[DATA_TOOLS_REPORT_TYPE.BIDDING]}
                />
                <FormControlLabel
                  value={DATA_TOOLS_REPORT_TYPE.CREATIVE}
                  control={<Radio />}
                  label={DATA_TOOLS_REPORT_TYPE_ALIAS[DATA_TOOLS_REPORT_TYPE.CREATIVE]}
                />
                <FormControlLabel
                  value={DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING}
                  control={<Radio />}
                  label={DATA_TOOLS_REPORT_TYPE_ALIAS[DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING]}
                />
                <FormControlLabel
                  value={DATA_TOOLS_REPORT_TYPE.NPAY}
                  control={<Radio />}
                  label={DATA_TOOLS_REPORT_TYPE_ALIAS[DATA_TOOLS_REPORT_TYPE.NPAY]}
                />
              </RadioGroup>
            </Grid>

            {/* 크리에이티브 확인 리포트 */}
            {values.reportType === DATA_TOOLS_REPORT_TYPE.CREATIVE && (
              <>
                {/* ADID */}
                <Grid item xs={2}>
                  <Typography variant="body1" className="label">
                    ADID/IDFA
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    className="field"
                    label="ADID or IDFA"
                    placeholder="ADID or IDFA 값을 형식에 맞게 입력해주세요."
                    required
                    {...getFieldProps("adid")}
                    error={shouldErrorShows("adid", touched, errors)}
                    helperText={getHelperText("adid", touched, errors) as string}
                  />
                </Grid>
                <Grid item xs={6}></Grid>

                {/* 매체 키 */}
                <Grid item xs={2}>
                  <Typography variant="body1" className="label">
                    매체 키
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    className="field"
                    label="Media key"
                    placeholder="Media key를 입력해주세요."
                    required
                    {...getFieldProps("mediaKey")}
                    error={shouldErrorShows("mediaKey", touched, errors)}
                    helperText={getHelperText("mediaKey", touched, errors) as string}
                  />
                </Grid>
                <Grid item xs={6}></Grid>

                {/* 플레이스먼트 ID */}
                <Grid item xs={2}>
                  <Typography variant="body1" className="label">
                    플레이스먼트 ID
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    className="field"
                    label="Placement ID"
                    placeholder="플레이스먼트 ID를 입력하시면 검색 비용이 줄어듭니다."
                    {...getFieldProps("placementId")}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
              </>
            )}

            {/* 분석 기간 (앱, 웹, 입찰 보고서) */}
            {useRangeOption && (
              <>
                <Grid item xs={2}>
                  <Typography variant="body1" className="label">
                    분석 기간 선택
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <RadioGroup
                    row
                    className="field"
                    aria-label="filter"
                    {...getFieldProps("range")}
                    onChange={onChangeRange}
                  >
                    <FormControlLabel
                      value={PROCEDURE_RANGE.DAILY}
                      control={<Radio />}
                      label={PROCEDURE_RANGE_ALIAS[PROCEDURE_RANGE.DAILY]}
                    />
                    <FormControlLabel
                      value={PROCEDURE_RANGE.HOURLY}
                      control={<Radio />}
                      label={PROCEDURE_RANGE_ALIAS[PROCEDURE_RANGE.HOURLY]}
                    />
                  </RadioGroup>
                </Grid>
              </>
            )}

            {/* 상세 분석기간 (입찰 보고서 -> 시간대별) */}
            {useTimelyOption && (
              <>
                <Grid item xs={2}>
                  <Typography variant="body1" className="label">
                    상세 분석기간 선택
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <RadioGroup
                    row
                    className="field"
                    aria-label="filter"
                    {...getFieldProps("timely")}
                    onChange={onChangeTimely} // override onChange
                  >
                    <FormControlLabel
                      value={PROCEDURE_TIME_RANGE.AM}
                      control={<Radio />}
                      label={PROCEDURE_TIME_RANGE_ALIAS[PROCEDURE_TIME_RANGE.AM]}
                    />
                    <FormControlLabel
                      value={PROCEDURE_TIME_RANGE.PM}
                      control={<Radio />}
                      label={PROCEDURE_TIME_RANGE_ALIAS[PROCEDURE_TIME_RANGE.PM]}
                    />
                  </RadioGroup>
                </Grid>
              </>
            )}

            {/* 데이터 보고서 선택 (앱, 웹, 쿠팡, 입찰 보고서, 네이버페이) */}
            {useProcedureOption && (
              <>
                <Grid item xs={2}>
                  <Typography variant="body1" className="label">
                    데이터 보고서 선택
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    fullWidth
                    className="field"
                    size="small"
                    options={procedureOptions}
                    getOptionLabel={(procedure) => procedure.name}
                    renderInput={({ InputLabelProps, ...params }) => (
                      <TextField
                        {...params}
                        label="데이터보고서"
                        placeholder="데이터보고서를 선택해주세요."
                        required
                        name="procedure"
                        error={shouldErrorShows("procedure", touched, errors)}
                        helperText={getHelperText("procedure", touched, errors) as string}
                      />
                    )}
                    isOptionEqualToValue={(option, value) => option.procedure === value.procedure}
                    value={procedureOptions.find((option) => values.procedure === option.procedure)}
                    onChange={onSelectProcedure}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
              </>
            )}

            {/* "입찰 보고서", "기간 선택" 시 DSP 선택 */}
            {values.reportType === DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING && (
              <>
                <Grid item xs={2}>
                  <Typography variant="body1" className="label">
                    DSP 선택
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    fullWidth
                    className="field"
                    size="small"
                    options={dspListData.thirdparties.sort((a, b) => a.name.localeCompare(b.name))}
                    getOptionLabel={(procedure) => procedure.name}
                    multiple
                    renderInput={({ InputLabelProps, ...params }) => (
                      <TextField
                        {...params}
                        label="DSP"
                        placeholder="최대 10개의 DSP를 선택하실 수 있습니다."
                        name="select-dsp"
                        error={shouldErrorShows("thirdpartyIds", touched, errors)}
                      />
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={dspListData.thirdparties.filter((dsp) =>
                      (values.thirdpartyIds || []).includes(dsp.id)
                    )}
                    onChange={onSelectDsp}
                    disableCloseOnSelect
                  />
                </Grid>
                <Grid item xs={6}></Grid>
              </>
            )}

            {/* NPAY */}
            {values.reportType === DATA_TOOLS_REPORT_TYPE.NPAY && (
              <>
                <Grid item xs={2}>
                  <Typography variant="body1" className="label">
                    상위
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Select value={values.limit} onChange={onChangeNpayLimit}>
                    {naverPayReportOptions.map((option) => (
                      <MenuItem key={option.label} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}></Grid>
              </>
            )}

            {/* 데이터 조회 일자 */}
            <Grid item xs={2}>
              <Typography variant="body1" className="label">
                데이터 조회 일자 선택
              </Typography>
            </Grid>

            {/* 데이터 조회 일자 퀵메뉴 (앱 + 일자별, 웹 + 일자별, 쿠팡) */}
            {useDateQuickMenu && (
              <>
                <Grid item xs={10}>
                  <RadioGroup
                    row
                    className="field"
                    aria-label="filter"
                    {...getFieldProps("dateQuickMenu")}
                    onChange={onChangeDateMenu} // override onChange
                  >
                    <FormControlLabel
                      value={SEARCH_DATE.YESTERDAY}
                      control={<Radio />}
                      label={SEARCH_DATE_ALIAS[SEARCH_DATE.YESTERDAY]}
                    />
                    <FormControlLabel
                      value={SEARCH_DATE.WEEK}
                      control={<Radio />}
                      label={SEARCH_DATE_ALIAS[SEARCH_DATE.WEEK]}
                    />
                    <FormControlLabel
                      value={SEARCH_DATE.MONTH}
                      control={<Radio />}
                      label={SEARCH_DATE_ALIAS[SEARCH_DATE.MONTH]}
                    />
                    <FormControlLabel
                      value={SEARCH_DATE.CUSTOM}
                      control={<Radio />}
                      label={SEARCH_DATE_ALIAS[SEARCH_DATE.CUSTOM]}
                    />
                  </RadioGroup>
                </Grid>

                <Grid item xs={2}></Grid>
              </>
            )}
            <Grid item xs={3}>
              <DateField
                label="시작일"
                value={values.since}
                maxDate={maxSinceDate}
                onChange={onChangeSinceDate}
              />
            </Grid>
            {/* !입찰 보고서 + 일자별 */}
            {useUntilDate && (
              <Grid item xs={3}>
                <DateField
                  label="종료일"
                  value={values.until}
                  minDate={values.since}
                  maxDate={maxUntilDate}
                  onChange={onChangeUntilDate}
                />
              </Grid>
            )}
            <Grid item xs={4} sx={{ display: "flex", gap: "1rem" }}>
              <Button variant="contained" type="submit" disabled={!isValid}>
                리포트 생성
              </Button>
            </Grid>

            {values.reportType === DATA_TOOLS_REPORT_TYPE.PERIODIC_BIDDING && (
              <>
                <Grid item xs={2}></Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    color={errors.since || errors.until || errors.thirdpartyIds ? "red" : "black"}
                  >
                    최대 31일까지 선택 가능하며, 전체 DSP 조회 시에는 최대 8일까지 선택 가능합니다.
                  </Typography>
                </Grid>
              </>
            )}

            {values.reportType === DATA_TOOLS_REPORT_TYPE.NPAY && (
              <>
                <Grid item xs={2}></Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    color={errors.since || errors.until ? "red" : "black"}
                  >
                    최대 7일까지 선택 가능합니다.
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </form>
        <Stack sx={{ mt: "2rem" }} spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h6" component="p">
              리포트 생성 히스토리 &#40;최근 7일&#41;
            </Typography>
            <IconButton
              disabled={isLoading || isFetching}
              onClick={() => refetch()}
              color="primary"
            >
              <BiRefresh />
            </IconButton>
          </Stack>
          <BasicTable rowData={data} columnDefs={getColumnDefs()} />
        </Stack>
      </Paper>
    </Container>
  );
};

export default DataTools;

function getColumnDefs(): (ColDef | ColGroupDef)[] {
  return [
    {
      headerName: "리포트 ID",
      field: "report_id",
      sortable: false,
    },
    {
      headerName: "사용자",
      field: "user_name",
      sortable: false,
    },
    {
      headerName: "리포트명",
      field: "report_name",
      minWidth: 400,
      sortable: false,
    },
    {
      headerName: "상태",
      field: "job_status",
      sortable: false,
      cellRenderer: (params: { data: DataToolsHistory }) => JobStatus[params.data.job_status],
    },
    {
      headerName: "다운로드",
      field: "file_url",
      sortable: false,
      cellRenderer: (params: { data: DataToolsHistory }) => {
        const { file_url, job_status } = params.data;
        const disabled = job_status !== JobStatus.완료;

        return (
          <IconButton
            LinkComponent={Link}
            size="small"
            disabled={disabled}
            download
            href={file_url}
          >
            <BiDownload />
          </IconButton>
        );
      },
    },
    {
      headerName: "생성일",
      field: "regist_datetime",
      sort: "desc",
      sortable: true,
      minWidth: 150,
      valueFormatter: (param: ValueFormatterParams) => {
        const utcDateValue = moment(param.value).valueOf();
        const localDateValue = utcDateValue + 9 * 60 * 60 * 1000;

        return moment(localDateValue).format("YYYY-MM-DD HH:mm");
      },
    },
  ];
}
