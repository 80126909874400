import { Collapse, Drawer, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import cx from "classnames";
import { Fragment, MouseEvent, useCallback, useEffect, useState } from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FiMenu } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import SSPLogo from "src/assets/images/ssp-logo-symbol.svg";
import routes from "src/assets/routes";
import useGetUserAuthority from "src/hooks/apis/users/useGetUserAuthority";
import { REG_EXP } from "src/utils/form-helper";
import { mobileMenuDrawerStyle, mobileMenuStyle } from "./styles";

const MobileMenu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<string>(pathname.split("/")[1]);
  const { isAccessible } = useGetUserAuthority({ userId: "me" });

  // 초기 메인 메뉴 활성화
  useEffect(() => {
    setSelectedMenu(`${pathname.split("/")[1]}`);
  }, [pathname]);

  const onClickBurger = () => {
    setOpen(!open);
  };

  const onClickMainMenu = useCallback(
    (menu: string) => (e: MouseEvent) => {
      e.preventDefault();
      setSelectedMenu(menu);
    },
    []
  );

  const onClickSubMenu = useCallback(
    (href: string) => (e: MouseEvent) => {
      e.preventDefault();
      // 외부 링크
      if (REG_EXP.domain.test(href)) {
        window.open(href, "_blank", "noopener,noreferrer");
        return;
      }
      navigate(href);
      setSelectedMenu(`${href.split("/")[1]}`);
    },
    [navigate]
  );

  return (
    <>
      {/* 모바일 메뉴 */}
      <Box css={mobileMenuStyle} sx={{ display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="mobile menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="default"
          onClick={onClickBurger}
        >
          <FiMenu />
        </IconButton>
        <Drawer css={mobileMenuDrawerStyle} anchor="left" open={open} onClose={onClickBurger}>
          <List sx={{ width: 250 }}>
            {Object.entries(routes).map(([menu, menuInfo]) => {
              if (menu !== "login" && isAccessible(menu, menuInfo.authority))
                return (
                  <Fragment key={menu}>
                    <ListItem disablePadding onClick={onClickMainMenu(menu)}>
                      <ListItemButton>
                        <ListItemText
                          className={cx("main-menu", { "main-active": selectedMenu === menu })}
                          primary={menuInfo.label}
                        />
                        {selectedMenu === menu ? <AiFillCaretUp /> : <AiFillCaretDown />}
                      </ListItemButton>
                    </ListItem>
                    {menuInfo.subMenu && (
                      <Collapse
                        in={selectedMenu === menu}
                        timeout="auto"
                        unmountOnExit
                        className="sub-menu-box"
                      >
                        <List component="div" disablePadding>
                          {Object.entries(menuInfo.subMenu).map(([subMenu, menuInfo]) => {
                            if (isAccessible(selectedMenu, menuInfo.authority))
                              return (
                                <ListItemButton
                                  key={subMenu}
                                  className={cx("sub-menu", {
                                    active: pathname.includes(menuInfo.href),
                                  })}
                                  onClick={onClickSubMenu(menuInfo.href)}
                                >
                                  <ListItemText primary={menuInfo.label} />
                                </ListItemButton>
                              );
                            return null;
                          })}
                        </List>
                      </Collapse>
                    )}
                  </Fragment>
                );
              return null;
            })}
          </List>
        </Drawer>
      </Box>
      {/* 모바일 로고 */}
      <Typography variant="h6" noWrap component="div" sx={{ display: { xs: "flex", md: "none" } }}>
        <img src={SSPLogo} alt="ssp-logo" />
      </Typography>
    </>
  );
};

export default MobileMenu;
