import { AxiosError, AxiosResponse } from "axios";
import { setCookie } from "nookies";
import { useMemo } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { uid2Agreement } from "src/atoms/uid2Agreement";
import { viewerCompany } from "src/atoms/viewerCompany";
import useApiError from "src/hooks/apis/useApiError";
import useToast from "src/hooks/useToast";
import { UserRole } from "src/types";
import { Authority } from "src/types/authority";
import API from "src/utils/api";

export interface Params {
  email: string;
  password: string;
}

interface Response {
  code: 200 | 400 | 401 | 403 | 404;
  data?: {
    access_token: string;
    access_token_expires_at: number;
    user: {
      authority: Authority;
      company_id: number;
      company_key: string;
      role: UserRole;
      tw_company_id: number;
      use_adserver: boolean;
      user_account_id: string;
      user_id: number;
      user_name: string;
      is_uid2_agreement: boolean | null;
    };
  };
  text: "ok" | "bad-request" | "unauthorized" | "forbidden" | "not-found";
}

// 로그인
const usePostSignIn = () => {
  const navigate = useNavigate();
  const { handleError } = useApiError({ 403: (_error: AxiosError) => null });
  const setCompany = useSetRecoilState(viewerCompany);
  const setUid2Agreement = useSetRecoilState(uid2Agreement);
  const toast = useToast();
  const { data, ...rest } = useMutation<Response["data"], AxiosError, Params>(
    async (params) => {
      const response: AxiosResponse<Response> = await API.default.post("/auth/signin", params);
      return response.data.data;
    },
    {
      onError: handleError,
      onSuccess: (data) => {
        if (data) {
          API.default.defaults.headers.common["Authorization"] = `Bearer ${data.access_token}`;
          API.integration.defaults.headers.common["Authorization"] = `Bearer ${data.access_token}`;
          API.console.defaults.headers.common["Authorization"] = `Bearer ${data.access_token}`;

          setCookie(null, "access_token", data.access_token, { path: "/" });
          setCookie(null, "expires", (data.access_token_expires_at * 1000).toString(), {
            path: "/",
          });
          setCompany({ id: data.user.company_id, key: data.user.company_key });
          toast.success("정상적으로 로그인되었습니다.");
          navigate("/home/dashboard", { replace: true });

          setUid2Agreement({
            value: data.user.is_uid2_agreement,
            role: data.user.role,
          });
        }
      },
    }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            access_token: "",
            access_token_expires_at: 0,
            user: {
              authority: {
                id: 0,
                type: 0,
                app: {
                  block_domain: "000",
                  home: "000",
                  media: "000",
                  placement: "000",
                },
                home: {
                  adm_dashboard: "000",
                  dashboard: "000",
                  ent_dashboard: "000",
                  syndication: "000",
                },
                operation: "000",
                report: {
                  app: "000",
                  web: "000",
                },
                setting: {
                  account: "000",
                  company: "000",
                  demo_test: "000",
                  manager: "000",
                  members: "000",
                },
                settlement: {
                  dashboard: "000",
                  setting: "000",
                  tx_history: "000",
                },
                web: {
                  block_domain: "000",
                  home: "000",
                  media: "000",
                  placement: "000",
                },
              },
              company_id: 0,
              role: 0,
              tw_company_id: 0,
              use_adserver: false,
              user_account_id: "",
              user_id: 0,
              user_name: "",
              is_uid2_agreement: null,
            },
          },
    [data]
  );

  return { data: result, ...rest };
};

export default usePostSignIn;
