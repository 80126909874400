import { AxiosError, AxiosResponse } from "axios";
import { Moment } from "moment";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Status } from "src/types";
import API from "src/utils/api";

interface Params {
  since: Moment;
  until: Moment;
  status: Status; // 10: 처리중, 50: 처리완료
  search: string;
  pageNo: number;
  pageSize: number;
  orders?: string[];
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    items: {
      adid: string;
      company_key: string;
      company_name: string;
      created_at: number;
      email: string;
      idfv: string;
      media_key: string;
      media_name: string;
      response: string;
      reward_cs_no: number;
      sdk_mediation_thirdparty_no: number;
      status: Status;
      text: string;
      updated_at: number;
      usn: string;
    }[];
    pages: 0;
  };
  text: "ok" | "bad-request" | "unauthorized";
}

//  리워드CS 리스트 조회
const useGetRewardCSList = ({
  since,
  until,
  status,
  search,
  pageNo = 1,
  pageSize = 30,
  orders,
}: Params) => {
  const { handleError } = useApiError();
  const start = useMemo(() => since.format("YYYY-MM-DD"), [since]);
  const end = useMemo(() => until.format("YYYY-MM-DD"), [until]);
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["rewardCS/useGetRewardCSList", start, end, status, search, pageNo, pageSize, orders],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/reward-cs`, {
        params: {
          since: start,
          until: end,
          status,
          q: search,
          page_no: pageNo,
          page_size: pageSize,
          orders: orders?.join(","),
        },
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => (data ? data : { items: [], pages: 0 }), [data]);

  return { data: result, ...rest };
};

export default useGetRewardCSList;
