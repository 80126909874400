import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import API from "src/utils/api";
import useApiError from "../useApiError";

export type Placement = {
  media_key: string; // 매체키
  media_name: string; // 매체명
  placement_id: string; // 플레이스먼트 ID
  placement_name: string; // 플레이스먼트명
  placement_status: 0 | 1; // 사용여부
  created_at: number; // 등록일자 (UNIX TIMESTAMP)
};

export type PlacementListByReportKeyPayload = {
  thirdparty_id: number;
  report_key: Record<string, string>;
};

export type PlacementListByReportKeyResponse = {
  code: 200 | 401 | 404;
  data: Placement[];
  text: "ok" | "unauthorized" | "not-found";
};

type PlacementListByReportKeyQueryKey = [
  "operation/placement-list-by-report-key",
  PlacementListByReportKeyPayload
];

type PlacementListByReportKeyQueryFn = QueryFunction<
  AxiosResponse<PlacementListByReportKeyResponse>,
  PlacementListByReportKeyQueryKey
>;

type PlacementListByReportKeyQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<PlacementListByReportKeyResponse>,
    AxiosError,
    AxiosResponse<PlacementListByReportKeyResponse>,
    PlacementListByReportKeyQueryKey
  >,
  "queryKey" | "queryFn"
>;

export default function usePlacementListByReportKey(
  payload: PlacementListByReportKeyPayload,
  options?: PlacementListByReportKeyQueryOptions
) {
  const { handleError } = useApiError();

  const queryKey = useMemo<PlacementListByReportKeyQueryKey>(
    () => ["operation/placement-list-by-report-key", payload],
    [payload]
  );

  const queryFn = useCallback<PlacementListByReportKeyQueryFn>(({ queryKey }) => {
    const [, payload] = queryKey;
    return API.default.post("/mgmt/rptkeys/plcmts", payload);
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data.data || [];
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
