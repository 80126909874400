import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { TextField, UploadField } from "src/components/commons";
import useGetCompany from "src/hooks/apis/companies/useGetCompany";
import usePatchCompany from "src/hooks/apis/companies/usePatchCompany";
import { User } from "src/hooks/apis/users/useGetUserDetail";
import useGetUsers from "src/hooks/apis/users/useGetUsers";
import {
  Company,
  COMPANY,
  COMPANY_ALIAS,
  Settlement,
  SETTLEMENT,
  SETTLEMENT_ALIAS,
  STATUS,
} from "src/types";
import { getHelperText, phoneFormatter, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { publisherModalStyle } from "./styles";

interface EditCompanyParams {
  type: Company;
  active: boolean;
  brn?: string;
  bri?: File | string;
  rrn?: string;
  rri?: File | string;
  companyName: string;
  address: string;
  manager?: number;
  settlementCommissionRate: number | string;
  settlementType: Settlement;
  adserver: boolean;
}

interface EditPublisherModalProps {
  onClose: () => void;
  open: { key: number; isOpen: boolean };
}

const EditPublisherModal = ({ open, onClose }: EditPublisherModalProps) => {
  const [manager, setManager] = useState<User | null>(null);
  const { data: companyData } = useGetCompany({ companyId: open.key });
  const { data: managerData } = useGetUsers({ status: STATUS.ACTIVE });
  const { mutate: updateCompany } = usePatchCompany();

  // 초기 매니저 세팅
  useEffect(() => {
    if (companyData.company.manager_uid && managerData.users.length > 0) {
      const selectedManager =
        managerData.users.find((user) => user.id === companyData.company.manager_uid) || null;
      setManager(selectedManager);
    }
  }, [companyData.company.manager_uid, managerData.users]);

  const initialValues: EditCompanyParams = useMemo(
    () => ({
      type: companyData.company.type || COMPANY.INDIVIDUAL,
      active: companyData.company.status === STATUS.ACTIVE ? true : false,
      brn: companyData.company.biz_registration_no || "",
      bri:
        companyData.company.type === COMPANY.CORPORATION
          ? companyData.company.biz_registration_img_url
          : "",
      rrn: companyData.company.registration_no || "",
      rri:
        companyData.company.type === COMPANY.INDIVIDUAL
          ? companyData.company.biz_registration_img_url
          : "",
      companyName: companyData.company.name || "",
      address: companyData.company.address || "",
      manager: companyData.company.manager_uid,
      settlementCommissionRate: companyData.company.settlement_commission_rate || 0,
      settlementType: companyData.company.settlement_type || SETTLEMENT.GROSS,
      adserver: companyData.company.use_adserver || false,
    }),
    [companyData.company]
  );

  const validationSchema = yup.object({
    type: yup.number().required("타입을 선택해주세요."),
    brn: yup.string().when("type", {
      is: COMPANY.CORPORATION,
      then: yup
        .string()
        .test(
          "brn",
          "올바른 사업자등록번호를 입력해주세요.",
          (value) => value?.match(REG_EXP.brn) !== null || value?.match(REG_EXP.number) !== null
        )
        .required("사업자등록번호를 입력해주세요."),
    }),
    bri: yup.mixed().when("type", {
      is: COMPANY.CORPORATION,
      then: yup.mixed().required("사업자 등록증 사본을 업로드해주세요."),
    }),
    rrn: yup.string().when("type", {
      is: COMPANY.INDIVIDUAL,
      then: yup
        .string()
        .test("rrn", "올바른 주민등록번호를 입력해주세요.", (value) =>
          value?.match(REG_EXP.rrn) === null ? false : true
        )
        .required("주민등록번호를 입력해주세요."),
    }),
    rri: yup.mixed().when("type", {
      is: COMPANY.INDIVIDUAL,
      then: yup.mixed().required("주민등록증 사본을 업로드해주세요."),
    }),
    companyName: yup.string().required("업체명을 입력해주세요."),
    address: yup.string().required("업체 주소를 입력해주세요."),
    manager: yup.number().required("관리자를 선택해주세요."),
    settlementCommissionRate: yup
      .string()
      .test("settlementCommissionRate", "숫자를 입력해주세요.", (value = "") => !isNaN(+value))
      .required("운영 수수료를 입력해주세요."),
    settlementType: yup.number().required(),
    adserver: yup.boolean(),
  });

  const { getFieldProps, handleSubmit, setFieldValue, touched, errors, values } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateCompany({
        companyId: open.key,
        companyType: values.type,
        status: values.active ? STATUS.ACTIVE : STATUS.SUSPEND,
        companyName: values.companyName,
        managerUid: values.manager,
        registrationNo: (values.type === COMPANY.CORPORATION ? values.brn : values.rrn) || "",
        address: values.address,
        useAdserver: values.adserver,
        file: (values.type === COMPANY.INDIVIDUAL ? values.bri : values.rri) as File,
        settlementCommissionRate: Number(values.settlementCommissionRate),
        settlementType: values.settlementType,
      });
    },
  });

  const onChangeType = useCallback(
    (e: ChangeEvent<HTMLInputElement>, v: string) => {
      e.preventDefault();
      setFieldValue("type", +v);
    },
    [setFieldValue]
  );

  const onChangeManger = useCallback(
    (_: SyntheticEvent<unknown>, v: User | null) => {
      setManager(v);
      setFieldValue("manager", v?.id);
    },
    [setFieldValue]
  );

  return (
    <Dialog
      css={publisherModalStyle}
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-company"
      aria-describedby="edit company"
    >
      <DialogTitle id="dialog-title">
        {open.key ? companyData.company.name : "신규 업체"}
        <FormControlLabel
          className="switch"
          sx={{
            position: "absolute",
            right: "1.5rem",
            top: "1rem",
            color: (theme) => theme.palette.grey[500],
          }}
          control={<Switch color="primary" />}
          label="활성화"
          labelPlacement="start"
          {...getFieldProps("active")}
          checked={values.active}
        />
      </DialogTitle>
      <DialogContent>
        <form id="edit-company-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioGroup
                row
                className="field"
                aria-label="filter"
                {...getFieldProps("type")}
                onChange={onChangeType}
              >
                <FormControlLabel
                  value={COMPANY.INDIVIDUAL}
                  control={<Radio />}
                  label={COMPANY_ALIAS[COMPANY.INDIVIDUAL]}
                />
                <FormControlLabel
                  value={COMPANY.CORPORATION}
                  control={<Radio />}
                  label={COMPANY_ALIAS[COMPANY.CORPORATION]}
                />
              </RadioGroup>
            </Grid>
            {/* 개인 */}
            {values.type === COMPANY.INDIVIDUAL && (
              <>
                <Grid item xs={12}>
                  <TextField
                    className="field"
                    label="주민등록번호"
                    placeholder="주민등록번호를 입력해주세요."
                    {...getFieldProps("rrn")}
                    error={shouldErrorShows("rrn", touched, errors)}
                    helperText={getHelperText("rrn", touched, errors)}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <UploadField
                    fullWidth
                    className="field"
                    label="주민등록증"
                    required
                    size="small"
                    accept=".jpg, .jpeg, .png, .pdf"
                    preview
                    {...getFieldProps("rri")}
                    value={companyData.company.biz_registration_img_url}
                    onChange={(data: File) => setFieldValue("rri", data)}
                    error={shouldErrorShows("rri", touched, errors)}
                    helperText={getHelperText("rri", touched, errors)}
                  />
                </Grid>
              </>
            )}
            {/* 기업 */}
            {values.type === COMPANY.CORPORATION && (
              <>
                <Grid item xs={12}>
                  <TextField
                    className="field"
                    label="사업자등록번호"
                    placeholder="사업자등록번호를 입력해주세요."
                    {...getFieldProps("brn")}
                    error={shouldErrorShows("brn", touched, errors)}
                    helperText={getHelperText("brn", touched, errors)}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <UploadField
                    fullWidth
                    className="field"
                    label="사업자등록증"
                    required
                    size="small"
                    accept=".jpg, .jpeg, .png, .pdf"
                    preview
                    {...getFieldProps("bri")}
                    value={companyData.company.biz_registration_img_url}
                    onChange={(data: File) => setFieldValue("bri", data)}
                    error={shouldErrorShows("bri", touched, errors)}
                    helperText={getHelperText("bri", touched, errors)}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                className="field"
                label="업체명"
                placeholder="업체명을 입력해주세요."
                {...getFieldProps("companyName")}
                error={shouldErrorShows("companyName", touched, errors)}
                helperText={getHelperText("companyName", touched, errors)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="업체 주소"
                placeholder="업체주소를 입력해주세요."
                {...getFieldProps("address")}
                error={shouldErrorShows("address", touched, errors)}
                helperText={getHelperText("address", touched, errors)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                className="field"
                size="small"
                options={managerData.users}
                getOptionLabel={(manager) => `${manager.name}(${manager.account_id})`}
                renderInput={({ InputLabelProps, ...params }) => (
                  <TextField
                    {...params}
                    label="관리자"
                    placeholder="관리자를 선택해주세요."
                    required
                    name="manager"
                    error={shouldErrorShows("manager", touched, errors)}
                    helperText={getHelperText("manager", touched, errors)}
                  />
                )}
                isOptionEqualToValue={(option) => option.id === values.manager}
                value={manager}
                onChange={onChangeManger}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="관리자 이메일"
                disabled
                value={manager?.account_id || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="관리자 연락처"
                disabled
                value={phoneFormatter(manager?.phone_no || "")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="운영 수수료"
                placeholder="운영 수수료를 입력해주세요."
                suffix="%"
                required
                {...getFieldProps("settlementCommissionRate")}
                error={shouldErrorShows("settlementCommissionRate", touched, errors)}
                helperText={getHelperText("settlementCommissionRate", touched, errors)}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: "0.5rem 0" }}>
              <Typography variant="body2">정산 분류</Typography>
              <RadioGroup
                row
                className="field"
                aria-label="filter"
                {...getFieldProps("settlementType")}
                onChange={(e, v) => {
                  e.preventDefault();
                  setFieldValue("settlementType", +v);
                }}
              >
                {Object.entries(SETTLEMENT_ALIAS).map(([value, label]) => (
                  <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
                ))}
              </RadioGroup>
            </Grid>
            <Grid item xs={6} className="use-adserver">
              <FormControlLabel
                control={<Switch color="primary" />}
                label="ADServer"
                labelPlacement="start"
                {...getFieldProps("adserver")}
                checked={values.adserver}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-company-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPublisherModal;
