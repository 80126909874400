export const ROLE = {
  UNREGISTERED: -100, // 미가입자
  noCompany: 1,
  IGAWorksAdministrator: 2,
  IGAWorksOperator: 3,
  PublisherAdministrator: 4,
  PublisherOperator: 5,
  SyndicationAdministrator: 6,
  SyndicationOperator: 7,
  SyndicationClient: 8,
  DormantAccount: 9,
  InactiveAccount: 10,
} as const;

export type UserRole = Union<typeof ROLE>;

export const ROLE_ALIAS = {
  [ROLE.UNREGISTERED]: "미가입자",
  [ROLE.noCompany]: "최초 가입",
  [ROLE.IGAWorksAdministrator]: "IGAWorks 관리자",
  [ROLE.IGAWorksOperator]: "IGAWorks 운영자",
  [ROLE.PublisherAdministrator]: "퍼블리셔 관리자",
  [ROLE.PublisherOperator]: "퍼블리셔 운영자",
  [ROLE.SyndicationAdministrator]: "신디케이션 관리자",
  [ROLE.SyndicationOperator]: "신디케이션 운영자",
  [ROLE.SyndicationClient]: "신디케이션 고객사",
  [ROLE.DormantAccount]: "휴면 계정",
  [ROLE.InactiveAccount]: "비활성 계정",
};
