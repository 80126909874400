import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

export interface Domain {
  created_at: number;
  domain: string;
  id: number;
}

interface Params {
  domainListId: number;
  pageNo?: number;
  pageSize?: number;
}

interface Response {
  code: 200 | 401;
  data?: {
    domains: Domain[];
    pages: number;
  };
  text: "ok" | "unauthorized";
}

// 차단 도메인 조회
const useGetDomain = ({ domainListId, pageNo = 1, pageSize = 100000 }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["blocklist/useGetDomain", domainListId, pageNo, pageSize],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(
        `/blocklist/domains/${domainListId}`,
        {
          params: { page_no: pageNo, page_size: pageSize },
        }
      );
      return response.data.data;
    },
    { enabled: !!domainListId, onError: handleError }
  );

  const result = useMemo(() => (data ? data : { domains: [], pages: 0 }), [data]);

  return { data: result, ...rest };
};

export default useGetDomain;
