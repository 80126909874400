import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { CellClassParams, ValueFormatterParams } from "ag-grid-community";
import moment from "moment";
import { useParams } from "react-router-dom";
import DataFormat from "src/assets/formats";
import { BasicTable } from "src/components/commons";
import useGetCustomerService from "src/hooks/apis/coupang/useGetCustomerService";
import { AppPlatform, APP_PLATFORM_ALIAS } from "src/types";
import { clientInfoStyle } from "./styles";

const RewardHistory = () => {
  const { id } = useParams();
  const { data: clientData } = useGetCustomerService({ csNo: id });

  return (
    <Container component="section" className="ssp-section" css={clientInfoStyle} maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        CPS 해당 캠페인 참여 내역
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid className="ssp-tools" container>
          <Grid item xs={3}>
            <Typography
              className="total"
              variant="body1"
            >{`합계: ${DataFormat.totalRevenue.formatter(clientData.question.total)}`}</Typography>
          </Grid>
          <Grid item xs={9} className="download">
            <Button variant="outlined">엑셀 다운로드</Button>
          </Grid>
        </Grid>
        <BasicTable rowData={clientData.question.participations} columnDefs={columnDefs} />
      </Paper>
    </Container>
  );
};

const columnDefs = [
  {
    headerName: "NO",
    minWidth: 100,
    field: "participation_no",
  },
  {
    headerName: "발생 일자",
    minWidth: 200,
    field: "event_datetime",
    valueFormatter: (params: ValueFormatterParams) => {
      return moment(params.value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    headerName: "USN",
    field: "usn",
    minWidth: 200,
  },
  {
    headerName: "플랫폼",
    field: "platform",
    minWidth: 150,
    valueFormatter: (params: ValueFormatterParams) => {
      const platform = params.value as AppPlatform;
      return APP_PLATFORM_ALIAS[platform];
    },
  },
  {
    headerName: "SDK 버젼",
    field: "sdk_version",
    minWidth: 150,
  },
  {
    headerName: "ADID(IDFA)",
    minWidth: 300,
    valueFormatter: (param: ValueFormatterParams) => {
      if (param.data.adid) return param.data.adid;
      if (param.data.idfa) return param.data.idfa;
      return "";
    },
  },
  {
    headerName: "금액",
    field: "price",
    minWidth: 200,
    valueFormatter: (param: ValueFormatterParams) => {
      if (param.data.event_name === "cancel")
        return `-${DataFormat.cancelRevenue.formatter(param.data.price)}`;
      return `${DataFormat.purchaseRevenue.formatter(param.data.price)}`;
    },
    cellStyle: (param: CellClassParams) => {
      if (param.data.eventName === "cancel") {
        return {
          color: "#E93131",
        };
      }
    },
  },
  {
    headerName: "수량",
    field: "quantity",
    minWidth: 100,
    valueFormatter: (params: ValueFormatterParams) => {
      const { media_cost, media_cost_currency } = params.data;
      return `${media_cost} ${media_cost_currency}`;
    },
  },
];

export default RewardHistory;
