import moment from "moment";
import { useCallback } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import COLORS from "src/assets/colors";
import DataFormat from "src/assets/formats";

interface DashboardReportChartProps {
  data: {
    click: number;
    ctr: number;
    ecpm: number;
    impression: number;
    report_date: string;
    revenue: number;
  }[];
}

const DashboardReportChart = ({ data }: DashboardReportChartProps) => {
  const tickFormatter = useCallback(
    (key: string) => (v: string | number) => {
      if (key === "report_date") return moment(v).format("YYYY-MM-DD");
      return DataFormat[key].formatter(+v, false, false);
    },
    []
  );
  return (
    <ResponsiveContainer height={600}>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 64,
          right: 30,
          left: 20,
          bottom: 32,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="report_date" tickFormatter={tickFormatter("report_date")} />
        <YAxis
          yAxisId="revenue"
          orientation="left"
          tickFormatter={tickFormatter("revenue")}
          label={{
            value: `(${DataFormat.revenue.unit})`,
            position: {
              x: 60,
              y: -15,
            },
            fill: COLORS[2],
          }}
        />
        <YAxis
          yAxisId="impression"
          orientation="left"
          tickFormatter={tickFormatter("impression")}
          label={{
            value: `(${DataFormat.impression.unit})`,
            position: {
              x: 60,
              y: -15,
            },
            fill: COLORS[0],
          }}
        />
        <YAxis
          yAxisId="ctr"
          orientation="right"
          tickFormatter={tickFormatter("ctr")}
          label={{
            value: `(${DataFormat.ctr.unit})`,
            position: {
              x: 15,
              y: -15,
            },
            fill: COLORS[4],
          }}
        />
        <YAxis
          yAxisId="ecpm"
          orientation="right"
          tickFormatter={tickFormatter("ecpm")}
          label={{
            value: `(${DataFormat.ecpm.unit})`,
            position: {
              x: 15,
              y: -15,
            },
            fill: COLORS[6],
          }}
        />
        <YAxis
          yAxisId="click"
          orientation="right"
          tickFormatter={tickFormatter("click")}
          label={{
            value: `(${DataFormat.click.unit})`,
            position: {
              x: 15,
              y: -15,
            },
            fill: COLORS[8],
          }}
        />
        <Legend
          wrapperStyle={{
            paddingTop: 32,
          }}
        />
        <Line
          type="monotone"
          dataKey="impression"
          yAxisId="impression"
          stroke={COLORS[0]}
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="revenue"
          yAxisId="revenue"
          stroke={COLORS[2]}
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="ctr" yAxisId="ctr" stroke={COLORS[4]} activeDot={{ r: 8 }} />
        <Line
          type="monotone"
          dataKey="ecpm"
          yAxisId="ecpm"
          stroke={COLORS[6]}
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="click"
          yAxisId="click"
          stroke={COLORS[8]}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DashboardReportChart;
