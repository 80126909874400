import { AxiosError, AxiosResponse } from "axios";
import { Moment } from "moment";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { AppPlatform, Status } from "src/types";
import API from "src/utils/api";

interface Params {
  since: Moment;
  until: Moment;
  search: string;
  status: Status;
  platform: AppPlatform;
  pageNo: number;
  pageSize: number;
  orders?: string[];
}

interface Response {
  code?: 200 | 400 | 401 | 422;
  data?: {
    cs_no: number;
    partner_no: number;
    partner_name: string;
    subids: string;
    campaign_key: string;
    adid: string;
    usn: string;
    cs_message: string;
    email: string;
    platform: AppPlatform;
    response_datetime: string;
    media_key: string;
    idfa: string;
    sdk_version: string;
    reply_message: string;
    status: string;
    regist_datetime: string;
    campaign_name: string;
  }[];
  pages?: number;
  text?: "ok" | "bad-request" | "unauthorized" | "unprocessable-entity";
}

/*
  쿠팡 CS 조회
*/
const useGetCustomerServiceList = ({
  since,
  until,
  search,
  status,
  platform,
  pageNo,
  pageSize,
  orders,
}: Params) => {
  const { handleError } = useApiError();
  const start = useMemo(() => since.format("YYYY-MM-DD"), [since]);
  const end = useMemo(() => until.format("YYYY-MM-DD"), [until]);
  const { data: response, ...rest } = useQuery<Response, AxiosError>(
    [
      "coupang/useGetCustomerServiceList",
      since,
      end,
      search,
      status,
      platform,
      pageNo,
      pageSize,
      orders,
    ],
    async () => {
      const response: AxiosResponse<Response> = await API.integration.get(
        `/operation/management/coupang/cs`,
        {
          params: {
            start,
            end,
            q: search,
            status,
            platform,
            page_no: pageNo,
            page_size: pageSize,
            order: orders?.join(","),
          },
        }
      );
      return response.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => {
    if (response && response.data)
      return {
        questions: response.data,
        pages: response.pages || 0,
      };
    return {
      questions: [],
      pages: 0,
    };
  }, [response]);

  return { data: result, ...rest };
};

export default useGetCustomerServiceList;
