import { Grid } from "@mui/material";
import { useMemo } from "react";
import { STATUS } from "src/types";
import {
  BidfloorHistory,
  DSPHistory,
  HeaderBiddingHistory,
  HISTORY,
  History,
  HistoryDetailType,
  MediationHistory,
} from "src/types/history";
import { historyDetailStyle } from "./styles";

interface HistoryDetailProps<T extends HistoryDetailType> {
  title: string;
  type: History;
  history?: T;
}

const HistoryDetail = <T extends HistoryDetailType>({
  title,
  type,
  history,
}: HistoryDetailProps<T>) => {
  const isNone = useMemo(
    () => !history || (Array.isArray(history) && history.length <= 0),
    [history]
  );

  return (
    <Grid container css={historyDetailStyle}>
      <Grid className="header" item xs={12}>
        {title}
      </Grid>
      {/* 데이터가 없는 경우 */}
      {isNone && (
        <Grid item xs={12}>
          No Data
        </Grid>
      )}
      {/* 데이터가 있는 경우 */}
      {!isNone && (
        <>
          {/* 미디에이션 */}
          {type === HISTORY.MEDIATION && (
            <>
              {/* 미디에이션이 모두 꺼진 상태 */}
              {(history as MediationHistory[]).every(
                (mediation) => mediation.status !== STATUS.ACTIVE
              ) && (
                <Grid item xs={12} textAlign="center">
                  OFF
                </Grid>
              )}
              {/* 미디에이션이 켜진 상태 */}
              {(history as MediationHistory[]).some(
                (mediation) => mediation.status === STATUS.ACTIVE
              ) &&
                (history as MediationHistory[])
                  .filter((mediation) => mediation.status === STATUS.ACTIVE)
                  .sort((a, b) => a.order - b.order)
                  .map((mediation) => {
                    return (
                      <Grid item xs={12} key={mediation.thirdparty_id} className={"active"}>
                        {`${mediation.thirdparty_name} (${mediation.order})`}
                      </Grid>
                    );
                  })}
              {/* 미디에이션이 꺼진 상태 */}
              {(history as MediationHistory[]).some(
                (mediation) => mediation.status === STATUS.ACTIVE
              ) &&
                (history as MediationHistory[])
                  .filter((mediation) => mediation.status !== STATUS.ACTIVE) // Inactive
                  .map((mediation) => {
                    return (
                      <Grid item xs={12} key={mediation.thirdparty_id} className={"inactive"}>
                        {`${mediation.thirdparty_name} (OFF)`}
                      </Grid>
                    );
                  })}
            </>
          )}
          {/* DSP */}
          {type === HISTORY.DSP &&
            (history as DSPHistory[]).map((dsp) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={dsp.thirdparty_id}
                  className={dsp.individual_status === STATUS.ACTIVE ? "active" : "inactive"}
                >
                  {`${dsp.thirdparty_name} (${
                    dsp.individual_status === STATUS.ACTIVE ? "ON" : "OFF"
                  })`}
                </Grid>
              );
            })}
          {/* 헤더비딩 */}
          {type === HISTORY.HEADER_BIDDING &&
            (history as HeaderBiddingHistory[]).map((headerBidding) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={headerBidding.thirdparty_id}
                  className={headerBidding.status === STATUS.ACTIVE ? "active" : "inactive"}
                >
                  {`${headerBidding.thirdparty_name} (${
                    headerBidding.status === STATUS.ACTIVE ? "ON" : "OFF"
                  })`}
                </Grid>
              );
            })}
          {/* 광고 형식 */}
          {type === HISTORY.CAMPAIGN && (
            <Grid item xs={12}>
              No Data
            </Grid>
          )}
          {/* BidFloor */}
          {type === HISTORY.BIDFLOOR &&
            ((history as BidfloorHistory).bidfloor_status === STATUS.ACTIVE ? (
              <Grid item xs={12}>{`USD ${(history as BidfloorHistory).bidfloor}`}</Grid>
            ) : (
              <Grid item xs={12}>
                OFF
              </Grid>
            ))}
        </>
      )}
    </Grid>
  );
};

export default HistoryDetail;
