import { css } from "@emotion/react";

export const settlementSettingStyle = css`
  .content {
    display: flex;
    flex-direction: column;
    width: 600px;
    margin: auto;
  }
  .submit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;
