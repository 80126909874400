export const WEB_TAG = {
  IFRAME: 1,
  SCRIPT: 2,
};

export type WebTag = Union<typeof WEB_TAG>;

export const WEB_TAG_ALIAS = {
  [WEB_TAG.IFRAME]: "iframe 태그",
  [WEB_TAG.SCRIPT]: "JS 태그",
};
