import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Select } from "src/components/commons";
import useGetDSPStatus from "src/hooks/apis/thirdparties/useGetDSPStatus";
import usePutDSPStatus from "src/hooks/apis/thirdparties/usePutDSPStatus";
import { CAMPAIGN, Campaign, CAMPAIGN_ALIAS, MEDIA, Media, MEDIA_ALIAS, STATUS } from "src/types";
import { editDSPModalStyle } from "./styles";

interface DSPStatusModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const DSPStatusModal = ({ onClose, isOpen }: DSPStatusModalProps) => {
  const [media, setMedia] = useState<Media>(MEDIA.APP);
  const [type, setType] = useState<Campaign>(CAMPAIGN.Banner);
  const { data } = useGetDSPStatus({ type });
  const { mutate } = usePutDSPStatus();

  useEffect(() => {
    if (media === MEDIA.APP) setType(CAMPAIGN.Banner);
    if (media === MEDIA.WEB) setType(CAMPAIGN.WebBanner);
  }, [media]);

  const campaignOption = useMemo(() => {
    if (media === MEDIA.WEB) return [CAMPAIGN.WebBanner];
    return [
      CAMPAIGN.Banner,
      CAMPAIGN.Interstitial,
      CAMPAIGN.Native,
      CAMPAIGN.RewardVideo,
      CAMPAIGN.InterstitialVideo,
    ];
  }, [media]);

  const onChangeMedia = useCallback((event: SelectChangeEvent<Media>) => {
    const value = +event.target.value as Media;
    setMedia(value);
  }, []);

  const onChangeCampaign = useCallback((event: SelectChangeEvent<Campaign>) => {
    const value = +event.target.value as Campaign;
    setType(value);
  }, []);

  const initialValues = useMemo(
    () =>
      data.thirdparties.reduce((acc: Record<string, boolean>, cur) => {
        if (cur.status === STATUS.ACTIVE) {
          acc[cur.name] = true;
        } else {
          acc[cur.name] = false;
        }
        return acc;
      }, {}),
    [data.thirdparties]
  );

  const { getFieldProps, handleSubmit, values } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (type)
        mutate({
          thirdparties: data.thirdparties.map((thirdparty) => ({
            ...thirdparty,
            status: values[thirdparty.name] ? STATUS.ACTIVE : STATUS.SUSPEND,
          })),
          type: type,
        });
    },
  });

  return (
    <Dialog
      css={editDSPModalStyle}
      maxWidth="md"
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-dsp-status"
      aria-describedby="edit dsp status"
    >
      <DialogTitle id="dialog-title">DSP 상태관리</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="edit-status-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Select
                label="미디어"
                placeholder="미디어를 선택해주세요."
                onChange={onChangeMedia}
                value={media}
              >
                <MenuItem value={MEDIA.APP}>{MEDIA_ALIAS[MEDIA.APP]}</MenuItem>
                <MenuItem value={MEDIA.WEB}>{MEDIA_ALIAS[MEDIA.WEB]}</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={4}>
              <Select
                label="광고형식"
                placeholder="광고형식을 선택해주세요."
                onChange={onChangeCampaign}
                value={type}
              >
                {campaignOption.map((campaign) => (
                  <MenuItem key={campaign} value={campaign}>
                    {CAMPAIGN_ALIAS[campaign]}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}></Grid>
            {data.thirdparties.map((dsp) => (
              <Grid item xs={4} key={dsp.id}>
                <FormControlLabel
                  className="switch"
                  control={<Switch color="primary" />}
                  label={dsp.name}
                  labelPlacement="end"
                  {...getFieldProps(dsp.name)}
                  checked={values[dsp.name]}
                />
              </Grid>
            ))}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-status-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DSPStatusModal;
