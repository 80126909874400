import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { MutationFunction, useMutation, UseMutationOptions } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";

export type CancelNpayTransactionsValues = {
  ad_request_no: string[];
};

type CancelNpayTransactionsMutationKey = "operation/npay/cancel-transactions";
type CancelNpayTransactionsMutationFn = MutationFunction<
  AxiosResponse,
  CancelNpayTransactionsValues
>;

type CancelNpayTransactionsMutationOptions = Omit<
  UseMutationOptions<AxiosResponse, AxiosError, CancelNpayTransactionsValues>,
  "mutationKey" | "mutationFn"
>;

export default function useCancelNpayTransactions(options?: CancelNpayTransactionsMutationOptions) {
  const { handleError } = useApiError();

  const mutationKey = useMemo<CancelNpayTransactionsMutationKey>(
    () => "operation/npay/cancel-transactions",
    []
  );

  const mutateFn = useCallback<CancelNpayTransactionsMutationFn>((values) => {
    const cookie = parseCookies();

    return API.consoleV3.put("/v3/naverpay/transactions/cancel", values, {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
    });
  }, []);

  const mutation = useMutation(mutationKey, mutateFn, {
    onError: handleError,
    ...options,
  });

  return mutation;
}
