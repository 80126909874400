import { Box, Grid, Typography } from "@mui/material";
import { blue, red } from "@mui/material/colors";
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import moment, { Moment } from "moment";
import { useCallback, useMemo, useState } from "react";
import { BasicTable, DateField } from "src/components/commons";
import useGetAdminDaily from "src/hooks/apis/dashboard/useGetAdminDaily";
import { THIRDPARTY } from "src/types";
import { numberFormatter } from "src/utils/form-helper";
import { dspDailyReportStyle } from "./styles";

const DSPDailyReport = () => {
  const [date, setDate] = useState(moment().subtract(1, "d"));
  const { data, range, calculator } = useGetAdminDaily({
    dataKey: "adv_revenue",
    type: THIRDPARTY.APP_DSP,
    since: date.clone().subtract(7, "d"),
    until: date,
  });

  const onChangeDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setDate(value);
    }
    return;
  }, []);

  const dspTwDDTotal = useMemo(() => {
    if (data && range) {
      const dspTwDDTotalData = data.reduce(
        (acc, cur) => acc.map((v, idx) => v + (cur.data[idx] ?? 0)),
        Array.from(range).map(() => 0)
      );
      return {
        name: "총합 (DSP + TWDD)",
        data: data.reduce(
          (acc, cur) => acc.map((v, idx) => v + (cur.data[idx] ?? 0)),
          Array.from(range).map(() => 0)
        ),
        dod: calculator(dspTwDDTotalData.slice(-2)),
        wow: calculator([dspTwDDTotalData[0], dspTwDDTotalData[dspTwDDTotalData.length - 1]]),
      };
    }
    return {
      name: "총합 (DSP + TWDD)",
      data: [],
      dod: 0,
      wow: 0,
    };
  }, [calculator, data, range]);

  const dspTotal = useMemo(() => {
    if (data && range) {
      const dspTotalData = data.reduce(
        (acc, cur) =>
          acc.map((v, idx) => {
            if (cur.name === "TradingWorksDD") return v;
            return v + (cur.data[idx] ?? 0);
          }),
        Array.from(range).map(() => 0)
      );
      return {
        name: "DSP 합계",
        data: data.reduce(
          (acc, cur) =>
            acc.map((v, idx) => {
              if (cur.name === "TradingWorksDD") return v;
              return v + (cur.data[idx] ?? 0);
            }),
          Array.from(range).map(() => 0)
        ),
        dod: calculator(dspTotalData.slice(-2)),
        wow: calculator([dspTotalData[0], dspTotalData[dspTotalData.length - 1]]),
      };
    }
    return {
      name: "DSP 합계",
      data: [],
      dod: 0,
      wow: 0,
    };
  }, [calculator, data, range]);

  const twDD = useMemo(() => data?.find((v) => v.name === "TradingWorksDD"), [data]);

  return (
    <Box css={dspDailyReportStyle}>
      <Typography className="title" variant="h5" gutterBottom>
        DSP 일자별 리포트
      </Typography>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={4}>
          <DateField
            label="날짜"
            value={date}
            onChange={onChangeDate}
            maxDate={moment().subtract(1, "d")}
          />
        </Grid>
      </Grid>
      <BasicTable
        className="ag-theme-balham"
        domLayout="autoHeight"
        columnDefs={getColumnDefs(range)}
        rowData={data}
        onGridColumnsChanged={(e) => {
          const lastCol = e.columnApi.getAllDisplayedColumns().slice(-3)[0];
          e.api.ensureColumnVisible(lastCol);
          dspTwDDTotal && e.api.setPinnedTopRowData([dspTwDDTotal]);
          dspTotal && twDD && e.api.setPinnedBottomRowData([dspTotal, twDD]);
        }}
        getRowStyle={(params) => {
          if (params.node.rowPinned) {
            return { fontWeight: "bold" };
          }
        }}
      />
    </Box>
  );
};

const getColumnDefs = (range?: string[]): (ColDef | ColGroupDef)[] =>
  range
    ? [
        {
          headerName: "일자",
          field: "name",
          minWidth: 250,
          pinned: true,
          cellStyle: (param: CellClassParams) => {
            if (param.data.name === "총합 (DSP + TWDD)" || param.data.name === "DSP 합계") {
              return {
                color: red[500],
                background: "rgb(233,236,239,0.5)",
              };
            }
          },
        },
        ...Array.from(range).map((date, idx) => ({
          headerName: date,
          field: "data",
          colId: `${moment(date).day()}`,
          minWidth: 150,
          maxWidth: 150,
          type: "rightAligned",
          valueFormatter: (params: ValueFormatterParams) => {
            try {
              if (params.value > 0 && params.value < 1) {
                return `$ ${numberFormatter(params.value, 2)}`;
              } else {
                return `$ ${numberFormatter(params.value, 0)}`;
              }
            } catch {
              return "-";
            }
          },
          valueGetter: (param: ValueGetterParams) => {
            return param.data.data[idx];
          },
          cellStyle: (param: CellClassParams) => {
            if (param.data.name === "총합 (DSP + TWDD)" || param.data.name === "DSP 합계") {
              return {
                background: "rgb(233,236,239,0.5)",
              };
            }
          },
        })),
        {
          headerName: "DOD",
          field: "dod",
          minWidth: 110,
          maxWidth: 110,
          type: "rightAligned",
          pinned: "right",
          valueFormatter: (params: ValueFormatterParams) => {
            try {
              if (Number.isNaN(Number.parseFloat(params.value))) {
                throw new Error("@value should be a number or string presenting a number.");
              }
              if (params.value > -1 && params.value < 1) {
                return `${Math.floor(Math.abs(params.value))} %`;
              } else {
                return `${numberFormatter(params.value, 0)} %`;
              }
            } catch {
              return "-";
            }
          },
          cellStyle: (params) => {
            const style: { [key: string]: string } = {
              color: red[500],
              background: "none",
              fontWeight: "bold",
            };
            if (params.value > -1 && params.value < 1) {
              style["color"] = "black";
            }
            if (params.value <= -1 && params.value > -20) {
              style["color"] = blue[500];
              style["background"] = "none";
              style["fontWeight"] = "bold";
            }
            if (params.data.name === "총합 (DSP + TWDD)" || params.data.name === "DSP 합계") {
              style["background"] = "rgb(233,236,239,0.5)";
            }
            if (params.value <= -20) {
              style["color"] = "black";
              style["background"] = red[50];
              style["fontWeight"] = "bold";
            }
            return style;
          },
        },
        {
          headerName: "WOW",
          field: "wow",
          minWidth: 110,
          maxWidth: 110,
          type: "rightAligned",
          pinned: "right",
          valueFormatter: (params: ValueFormatterParams) => {
            try {
              if (Number.isNaN(Number.parseFloat(params.value))) {
                throw new Error("@value should be a number or string presenting a number.");
              }
              if (params.value > -1 && params.value < 1) {
                return `${Math.floor(Math.abs(params.value))} %`;
              } else {
                return `${numberFormatter(params.value, 0)} %`;
              }
            } catch {
              return "-";
            }
          },
          cellStyle: (params: CellClassParams) => {
            const style: { [key: string]: string } = {
              color: red[500],
              background: "none",
              fontWeight: "bold",
            };
            if (params.value > -1 && params.value < 1) {
              style["color"] = "black";
            }
            if (params.value <= -1 && params.value > -20) {
              style["color"] = blue[500];
              style["background"] = "none";
              style["fontWeight"] = "bold";
            }
            if (params.data.name === "총합 (DSP + TWDD)" || params.data.name === "DSP 합계") {
              style["background"] = "rgb(233,236,239,0.5)";
            }
            if (params.value <= -20) {
              style["color"] = "black";
              style["background"] = red[50];
              style["fontWeight"] = "bold";
            }
            return style;
          },
        },
      ]
    : [];

export default DSPDailyReport;
