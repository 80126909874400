import { Grid } from "@mui/material";
import { useMemo } from "react";

import DataFormat from "src/assets/formats";
import { CAMPAIGN, STATUS } from "src/types";
import {
  BidfloorHistory,
  CampaignHistory,
  CompanionHistory,
  DSPHistory,
  HISTORY,
  History,
  HistoryDetailType,
  MediationHistory,
} from "src/types/history";

import useNativeTemplateList, {
  NativeTemplateType,
} from "src/hooks/apis/placements/useNativeTemplateList";

import { historyDetailStyle } from "./styles";

interface HistoryDetailProps<T extends HistoryDetailType> {
  title: string;
  type: History;
  history?: T;
}

const HistoryDetail = <T extends HistoryDetailType>({
  title,
  type,
  history,
}: HistoryDetailProps<T>) => {
  const isNone = useMemo(
    () => !history || (Array.isArray(history) && history.length <= 0),
    [history]
  );

  const isCampaignHistory = type === HISTORY.CAMPAIGN;

  const isCompanionNative =
    isCampaignHistory &&
    !!(history as CompanionHistory)?.parent_p_id &&
    (history as CompanionHistory)?.campaign_type === CAMPAIGN.Native;

  const { nativeTemplateList } = useNativeTemplateList(NativeTemplateType.COMPANION, {
    enabled: isCompanionNative,
  });

  return (
    <Grid container css={historyDetailStyle}>
      <Grid className="header" item xs={12}>
        {title}
      </Grid>
      {/* 데이터가 없는 경우 */}
      {isNone && (
        <Grid item xs={12}>
          No Data
        </Grid>
      )}
      {/* 데이터가 있는 경우 */}
      {!isNone && (
        <>
          {/* 미디에이션 */}
          {type === HISTORY.MEDIATION && (
            <>
              {/* 미디에이션이 모두 꺼진 상태 */}
              {(history as MediationHistory[]).every(
                (mediation) => mediation.status !== STATUS.ACTIVE
              ) && (
                <Grid item xs={12} textAlign="center">
                  OFF
                </Grid>
              )}
              {/* 미디에이션이 켜진 상태 */}
              {(history as MediationHistory[]).some(
                (mediation) => mediation.status === STATUS.ACTIVE
              ) &&
                (history as MediationHistory[])
                  .filter((mediation) => mediation.status === STATUS.ACTIVE)
                  .sort((a, b) => a.order - b.order)
                  .map((mediation) => {
                    return (
                      <Grid item xs={12} key={mediation.thirdparty_id} className={"active"}>
                        {`${mediation.thirdparty_name} (${
                          mediation.order
                        }) (${DataFormat.bidfloor.formatter(mediation.bidfloor)})`}
                      </Grid>
                    );
                  })}
              {/* 미디에이션이 꺼진 상태 */}
              {(history as MediationHistory[]).some(
                (mediation) => mediation.status === STATUS.ACTIVE
              ) &&
                (history as MediationHistory[])
                  .filter((mediation) => mediation.status !== STATUS.ACTIVE) // Inactive
                  .map((mediation) => {
                    return (
                      <Grid item xs={12} key={mediation.thirdparty_id} className={"inactive"}>
                        {`${mediation.thirdparty_name} (OFF)`}
                      </Grid>
                    );
                  })}
            </>
          )}
          {/* DSP */}
          {type === HISTORY.DSP &&
            (history as DSPHistory[]).map((dsp) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={dsp.thirdparty_id}
                  className={dsp.individual_status === STATUS.ACTIVE ? "active" : "inactive"}
                >
                  {`${dsp.thirdparty_name} (${
                    dsp.individual_status === STATUS.ACTIVE ? "ON" : "OFF"
                  })`}
                </Grid>
              );
            })}
          {/* 광고 형식 */}
          {isCampaignHistory && (
            <>
              {/* 리워드 비디오 */}
              {(history as CampaignHistory).campaign_type === CAMPAIGN.RewardVideo && (
                <Grid
                  item
                  xs={12}
                  className={(history as CampaignHistory).mute ? "active" : "inactive"}
                >
                  {`음소거 (${(history as CampaignHistory).mute ? "ON" : "OFF"})`}
                </Grid>
              )}
              {/* 전면 비디오 */}
              {(history as CampaignHistory).campaign_type === CAMPAIGN.InterstitialVideo && (
                <>
                  <Grid item xs={12}>
                    {(history as CampaignHistory).minviewtime
                      ? `${(history as CampaignHistory).minviewtime}초`
                      : "제한없음"}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={(history as CampaignHistory).mute ? "active" : "inactive"}
                  >
                    {`음소거 (${(history as CampaignHistory).mute ? "ON" : "OFF"})`}
                  </Grid>
                </>
              )}
              {/* 전면 */}
              {(history as CampaignHistory).campaign_type === CAMPAIGN.Interstitial && (
                <>
                  <Grid
                    item
                    xs={12}
                    className={(history as CampaignHistory).companion_use ? "active" : "inactive"}
                  >
                    {`컴패니언 네이티브 광고 (${
                      (history as CampaignHistory).companion_use ? "ON" : "OFF"
                    })`}
                  </Grid>
                  <Grid item xs={12}>
                    {(history as CampaignHistory).minviewtime
                      ? `${(history as CampaignHistory).minviewtime}초`
                      : "제한없음"}
                  </Grid>
                </>
              )}
              {/* 컴패니언 네이티브 */}
              {isCompanionNative && (
                <>
                  <Grid item xs={12}>
                    {nativeTemplateList.find(
                      (template) => template.id === (history as CompanionHistory).template_no
                    )?.name || ""}
                  </Grid>
                  <Grid item xs={12}>
                    {(history as CompanionHistory).placement_name}
                  </Grid>
                </>
              )}
              {/* 네이티브 */}
              {(history as CampaignHistory).campaign_type === CAMPAIGN.Native && (
                <>
                  {(history as CampaignHistory).ctatext_required && (
                    <Grid item xs={12}>
                      CTA TITLE
                    </Grid>
                  )}
                  {(history as CampaignHistory).desc_required && (
                    <Grid item xs={12}>
                      TEXT
                    </Grid>
                  )}
                  {(history as CampaignHistory).icon_image_required && (
                    <Grid item xs={12}>
                      APP ICON
                    </Grid>
                  )}
                  {(history as CampaignHistory).main_image_required && (
                    <Grid item xs={12}>
                      IMAGE
                    </Grid>
                  )}
                  {(history as CampaignHistory).title_required && (
                    <Grid item xs={12}>
                      TITLE
                    </Grid>
                  )}
                  {/* 네이티브 컴포넌트 모두 OFF */}
                  {!(history as CampaignHistory).ctatext_required &&
                    !(history as CampaignHistory).desc_required &&
                    !(history as CampaignHistory).icon_image_required &&
                    !(history as CampaignHistory).main_image_required &&
                    !(history as CampaignHistory).title_required && (
                      <Grid item xs={12}>
                        OFF
                      </Grid>
                    )}
                </>
              )}
            </>
          )}
          {/* BidFloor */}
          {type === HISTORY.BIDFLOOR &&
            ((history as BidfloorHistory).bidfloor_status === STATUS.ACTIVE ? (
              <Grid item xs={12}>{`USD ${(history as BidfloorHistory).bidfloor}`}</Grid>
            ) : (
              <Grid item xs={12}>
                OFF
              </Grid>
            ))}
        </>
      )}
    </Grid>
  );
};

export default HistoryDetail;
