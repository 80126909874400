import { Container, Grid, MenuItem, Paper, SelectChangeEvent, Typography } from "@mui/material";
import {
  ColDef,
  ColGroupDef,
  RowClickedEvent,
  SortChangedEvent,
  ValueFormatterParams,
} from "ag-grid-community";
import moment from "moment";
import { ChangeEvent, useCallback, useMemo, useState } from "react";

import { BasicTable, MultipleSelect, SearchField, Select } from "src/components/commons";
import useReportKeyList, { ReportKey } from "src/hooks/apis/report-keys/use-report-key-list";
import useGetMediationList from "src/hooks/apis/thirdparties/useGetMediationList";
import useOpenModal from "src/hooks/useOpenModal";
import { MEDIA, Media } from "src/types";

import EditReportKeyModal, {
  EditReportKeyModalProps,
} from "src/components/operation/report-key/edit-report-key-modal";

const placementCountPerReportKeyOptions = [
  { label: "전체", value: 0 },
  { label: "1개", value: 1 },
  { label: "2개 이상", value: 2 },
];

export type MediationOption = {
  key: number;
  name: string;
};

const ReportKeyPage = () => {
  const [openEditModal, onShowEditReportKey, onCloseEditReportKey] = useOpenModal<string>("");

  /** 리포트키당 지면 개수 */
  const [placementCount, setPlacementCount] = useState(0);

  /** 미디에이션명 */
  const [selectedMediationOptions, setMediationOptions] = useState<MediationOption[]>([]);

  /** 검색어 */
  const [search, setSearch] = useState({ temp: "", value: "" });

  /** 테이블 정렬 기준 */
  const [orders, setOrders] = useState<string[]>([]);

  /** 테이블 페이지 */
  const [pageNo, setPageNo] = useState(1);

  /** 테이블 페이지당 row 개수 */
  const [pageSize, setPageSize] = useState(20);

  /** 리포트키 업데이트 팝업 컴포넌트 props */
  const [editReportKeyModalProps, setEditReportKeyModalProps] =
    useState<EditReportKeyModalProps | null>(null);

  /** 미디에이션 목록 조회 및 미디에이션 옵션 전체 선택 */
  const {
    data: { thirdparties },
    isSuccess: isMediationListSuccess,
  } = useGetMediationList(
    { type: MEDIA.ALL },
    {
      onSuccess: (data) => {
        if (data) {
          setMediationOptions(
            data.thirdparties
              .filter(({ id }) => id !== 0)
              .map(({ id, name }) => ({ key: id, name: name }))
          );
        }
      },
    }
  );

  /** 미디에이션 목록 조회 성공 시 리포트 키 목록 조회 */
  const { data: reportKeyListData } = useReportKeyList(
    {
      placement_count: placementCount,
      thirdparty_id: selectedMediationOptions.map((option) => option.key),
      q: search.value,
      page_no: pageNo,
      page_size: pageSize,
      orders,
    },
    {
      enabled: isMediationListSuccess,
    }
  );

  /** 미디에이션 옵션 목록 */
  const mediationOptions = useMemo<MediationOption[]>(() => {
    return thirdparties
      .filter(({ id }) => id !== 0)
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .map(({ id, name }) => ({ key: id, name: name }));
  }, [thirdparties]);

  const handleChangePlacementCount = useCallback((event: SelectChangeEvent<number>) => {
    const value = event.target.value;

    if (typeof value === "number") {
      setPlacementCount(value);
      setPageNo(1);
    }
  }, []);

  const handleChangeMediationOptions = useCallback((values: MediationOption[]) => {
    setMediationOptions(values);
    setPageNo(1);
  }, []);

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearch((prev) => ({ ...prev, temp: e.target.value }));
  }, []);

  const handleClickSearch = useCallback(() => {
    setSearch((prev) => ({ ...prev, value: prev.temp }));
    setPageNo(1);
  }, []);

  const handleClickRow = useCallback(
    (e: RowClickedEvent) => {
      const { report_key, thirdparty_id, thirdparty_name } = e.data as ReportKey;

      onShowEditReportKey(e, JSON.stringify(report_key));
      setEditReportKeyModalProps({
        thirdparty_id,
        report_key,
        mediaType: thirdparties.find(({ id }) => id === thirdparty_id)?.media_type as Media,
        thirdpartyName: thirdparty_name,
        onClose: onCloseEditReportKey,
      });
    },
    [onCloseEditReportKey, onShowEditReportKey, thirdparties]
  );

  const handleChangeSort = useCallback((e: SortChangedEvent) => {
    const sortInfo = e.columnApi
      .getColumnState()
      .filter((column) => !!column.sort)
      .map(({ colId, sort }) => (sort === "desc" ? `-${colId}` : colId));

    setOrders(sortInfo);
    setPageNo(1);
  }, []);

  const handleChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const handleChangePageSize = useCallback((value: number) => {
    setPageSize(value);
    setPageNo(1);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: reportKeyListData.pages, onChange: handleChangePage },
      pageSize: {
        size: pageSize,
        onChange: handleChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, reportKeyListData.pages, handleChangePage, pageSize, handleChangePageSize]
  );

  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        플레이스먼트 리포트 키 관리
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid container className="ssp-tools" spacing={2}>
          <Grid item xs={3}>
            <Select
              label="리포트키당 지면 개수"
              value={placementCount}
              onChange={handleChangePlacementCount}
            >
              {placementCountPerReportKeyOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <MultipleSelect
              options={mediationOptions}
              label="미디에이션명"
              placeholder="미디에이션 선택"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.key}
              onChange={handleChangeMediationOptions}
              value={selectedMediationOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <SearchField
              label="검색어"
              placeholder="미디에이션 리포트 키값 혹은 매체, 지면 정보를 입력해주세요."
              value={search.temp}
              onChange={handleChangeSearch}
              onClickSearchButton={handleClickSearch}
            />
          </Grid>
        </Grid>
        <BasicTable
          rowData={reportKeyListData.keys}
          columnDefs={getColumnDefs()}
          onRowClicked={handleClickRow}
          onSortChanged={handleChangeSort}
          {...paginationInfo}
        />
      </Paper>
      {openEditModal.isOpen && editReportKeyModalProps && (
        <EditReportKeyModal {...editReportKeyModalProps} />
      )}
    </Container>
  );
};

export default ReportKeyPage;

export const getColumnDefs = (): (ColDef | ColGroupDef)[] => [
  {
    headerName: "미디에이션명",
    field: "thirdparty_name",
    sortable: true,
  },
  {
    headerName: "미디에이션 리포트 키",
    field: "report_key",
    sortable: false,
    valueFormatter: (params: ValueFormatterParams) => {
      const reportKey = params.value as ReportKey["report_key"];
      return JSON.stringify(reportKey);
    },
  },
  {
    headerName: "적용된 플레이스먼트 수",
    field: "placement_count",
    sortable: true,
  },
  {
    headerName: "리포트 매체명",
    field: "media_name",
    sortable: true,
  },
  {
    headerName: "리포트 매체키",
    field: "media_key",
    sortable: true,
  },
  {
    headerName: "리포트 플레이스먼트명",
    field: "placement_name",
    sortable: true,
  },
  {
    headerName: "리포트 플레이스먼트 ID",
    field: "placement_id",
    sortable: true,
  },
  {
    headerName: "최근 변경 일자",
    field: "updated_at",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const updatedAt = param.value * 1000;
      return moment(updatedAt).format("YYYY-MM-DD");
    },
  },
];
