import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { MutationFunction, useMutation, UseMutationOptions } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";
import { NpayPlacement } from "./use-npay-placements";

export type UpdateNpayPlacementValues = Pick<NpayPlacement, "media_key" | "id" | "status">;

type NpayAppMutationKey = "operation/npay/update-placement";
type NpayAppMutationFn = MutationFunction<AxiosResponse, UpdateNpayPlacementValues>;

type NpayAppMutationOptions = Omit<
  UseMutationOptions<AxiosResponse, AxiosError, UpdateNpayPlacementValues>,
  "mutationKey" | "mutationFn"
>;

export default function useUpdateNpayPlacement(options?: NpayAppMutationOptions) {
  const { handleError } = useApiError();

  const mutationKey = useMemo<NpayAppMutationKey>(() => "operation/npay/update-placement", []);

  const mutateFn = useCallback<NpayAppMutationFn>((values) => {
    const { media_key, id, status } = values;
    const cookie = parseCookies();

    return API.consoleV3.put(
      `/v3/naverpay/media/${media_key}/placements/${id}`,
      { status },
      {
        headers: {
          "x-api-type": "1",
          Authorization: `Bearer ${cookie.access_token || ""}`,
        },
      }
    );
  }, []);

  const mutation = useMutation(mutationKey, mutateFn, {
    onError: handleError,
    ...options,
  });

  return mutation;
}
