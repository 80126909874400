import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";
import { NpayTransaction } from "./use-npay-transactions";

export type NpayCsTransactionHistoryParams = {
  id: string;
  since: string;
  until: string;
  page_no?: number;
  page_size?: number;
  orders?: string[];
};

type NpayCsTransactionHistoryData = {
  message: string;
  data: {
    page_no: number;
    page_size: number;
    pages: number;
    rows: NpayTransaction[];
  };
};

type NpayCsTransactionHistoryQueryKey = [
  "operation/npay/cs-transaction-history",
  NpayCsTransactionHistoryParams
];

type NpayCsTransactionHistoryQueryFn = QueryFunction<
  AxiosResponse<NpayCsTransactionHistoryData>,
  NpayCsTransactionHistoryQueryKey
>;

type NpayCsTransactionHistoryQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<NpayCsTransactionHistoryData>,
    AxiosError,
    AxiosResponse<NpayCsTransactionHistoryData>,
    NpayCsTransactionHistoryQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: NpayCsTransactionHistoryData = {
  message: "OK",
  data: {
    page_no: 1,
    page_size: 1,
    pages: 1,
    rows: [],
  },
};

export default function useNpayCsTransactionHistory(
  params: NpayCsTransactionHistoryParams,
  options?: NpayCsTransactionHistoryQueryOptions
) {
  const { handleError } = useApiError();

  const queryKey = useMemo<NpayCsTransactionHistoryQueryKey>(
    () => ["operation/npay/cs-transaction-history", params],
    [params]
  );

  const queryFn = useCallback<NpayCsTransactionHistoryQueryFn>((context) => {
    const [, { id, ...rest }] = context.queryKey;
    const cookie = parseCookies();

    return API.consoleV3.get(`/v3/customer-services/naverpay/${id}/transactions`, {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
      params: rest,
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
