import { Grid } from "@mui/material";
import moment, { Moment } from "moment";
import { ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { DateField, MultipleSelect, SearchField } from "src/components/commons";
import useGetCriteria from "src/hooks/apis/payments/useGetCriteria";
import useToast from "src/hooks/useToast";
import {
  COMPANY,
  Company,
  COMPANY_ALIAS,
  TaxBillStatus,
  TAX_BILL_STATUS,
  TAX_BILL_STATUS_ALIAS,
} from "src/types";

interface SettlementFilterProps {
  search: {
    get: () => {
      temp: string;
      value: string;
    };
    set: ({ temp, value }: { temp: string; value: string }) => void;
  };
  date: {
    get: () => Moment;
    set: (v: Moment) => void;
  };
  taxBillStatus: {
    get: () => number[];
    set: (v: TaxBillStatus[]) => void;
  };
  companyTypes: {
    get: () => Company[];
    set: (v: Company[]) => void;
  };
}

const SettlementFilter = ({ search, date, taxBillStatus, companyTypes }: SettlementFilterProps) => {
  const toast = useToast();
  const { data } = useGetCriteria({ month: date.get().format("YYYYMM") });

  useEffect(() => {
    if (data.not_closing_months && data.not_closing_months.length > 0) {
      date.set(moment(`${data.not_closing_months}01`));
      toast.warning(
        `${moment(`${data.not_closing_months}01`).format(
          "YYYY년 MM월"
        )}의 정산이 마감되지 않았습니다. 해당 월 정산을 먼저 마감해주세요`
      );
    }
  }, [data, date, toast]);

  const taxBillStatusOptions = useMemo(
    () => [TAX_BILL_STATUS.DEFAULT, TAX_BILL_STATUS.CONFIRMED, TAX_BILL_STATUS.CARRIED],
    []
  );

  const companyTypeOptions = useMemo(
    () => [
      COMPANY.INDIVIDUAL,
      COMPANY.CORPORATION,
      COMPANY.SYNDICATION,
      COMPANY.SYNDICATION_CLIENT_CORPORATION,
      COMPANY.SYNDICATION_CLIENT_INDIVIDUAL,
    ],
    []
  );

  const onChangeSearchWord = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      search.set({ ...search.get(), temp: e.target.value });
    },
    [search]
  );

  const onSearchCompany = useCallback(() => {
    search.set({ ...search.get(), value: search.get().temp });
  }, [search]);

  const onChangeDate = useCallback(
    (d: unknown) => {
      const value = d as Moment;
      if (value.isValid()) {
        date.set(value);
      }
      return;
    },
    [date]
  );

  const onChangeTaxBillStatus = useCallback(
    (values: TaxBillStatus[]) => {
      taxBillStatus.set(values.map((v) => +v as TaxBillStatus));
    },
    [taxBillStatus]
  );

  const onChangeCompanyTypes = useCallback(
    (values: Company[]) => {
      companyTypes.set(values.map((v) => +v as Company));
    },
    [companyTypes]
  );

  return (
    <Grid className="ssp-tools" container spacing={2}>
      <Grid item xs={3}>
        <DateField
          views={["month", "year"]}
          inputFormat="YYYY년 MM월"
          mask="____년__월"
          label="날짜"
          value={date.get()}
          maxDate={moment().subtract(1, "M")}
          onChange={onChangeDate}
        />
      </Grid>
      <Grid item xs={3}>
        <MultipleSelect
          className="field"
          options={companyTypeOptions}
          label="업체 분류"
          placeholder="업체 분류"
          getOptionLabel={(option) => COMPANY_ALIAS[option]}
          onChange={onChangeCompanyTypes}
          value={companyTypes.get()}
        />
      </Grid>
      <Grid item xs={3}>
        <MultipleSelect
          className="field"
          options={taxBillStatusOptions}
          label="발행 상태"
          placeholder="발행 상태"
          getOptionLabel={(option) => TAX_BILL_STATUS_ALIAS[option]}
          onChange={onChangeTaxBillStatus}
          value={taxBillStatus.get()}
        />
      </Grid>
      <Grid item xs={3}>
        <SearchField
          label="업체명"
          placeholder="업체명"
          value={search.get().temp}
          onChange={onChangeSearchWord}
          onClickSearchButton={onSearchCompany}
        />
      </Grid>
    </Grid>
  );
};

export default SettlementFilter;
