import { Helmet, HelmetProvider } from "react-helmet-async";
import { NotFound } from "src/components/error";

const NotFoundPage = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>페이지를 찾을 수 없습니다</title>
        </Helmet>
      </HelmetProvider>
      <NotFound />
    </>
  );
};

export default NotFoundPage;
