import {
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import moment, { Moment } from "moment";
import { MouseEvent, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import { DateField, MultipleSelect, Select } from "src/components/commons";
import useGetAppMediaList, { AppMedia } from "src/hooks/apis/media/useGetAppMediaList";
import useGetAppPlacementList, {
  AppPlacement,
} from "src/hooks/apis/placements/useGetAppPlacementList";
import usePostExcel from "src/hooks/apis/report/usePostExcel";
import usePostReport from "src/hooks/apis/report/usePostReport";
import useGetMediationList, { Mediation } from "src/hooks/apis/thirdparties/useGetMediationList";
import useUser from "src/hooks/useUser";
import {
  AppPlatform,
  APP_PLATFORM,
  APP_PLATFORM_ALIAS,
  MEDIA,
  REPORT,
  Report,
  REPORT_ALIAS,
} from "src/types";
import ReportBoard from "./ReportBoard";
import ReportChart from "./ReportChart";

const AppReport = () => {
  const { isAdmin } = useUser();
  const [mediaList, setMediaList] = useState<AppMedia[]>([]);
  const [placementList, setPlacementList] = useState<AppPlacement[]>([]);
  const [mediationList, setMediationList] = useState<Mediation[]>([]);
  const [platform, setPlatform] = useState<AppPlatform>(APP_PLATFORM.ALL);
  const [reportType, setReportType] = useState<Report>(REPORT.DAILY);
  const [since, setSince] = useState(moment().subtract(7, "d"));
  const [until, setUntil] = useState(moment().subtract(1, "d"));
  const [country, setCountry] = useState(false);

  const [company] = useRecoilState(viewerCompany);
  const { data: mediaData } = useGetAppMediaList({ companyKey: company.key, platform: platform });
  const { data: placementData } = useGetAppPlacementList({
    mediaKeys: mediaList.map((m) => m.key),
    forReport: true,
  });
  const { data: mediationData } = useGetMediationList({ type: MEDIA.APP });
  const { mutate: search, data } = usePostReport();
  const { mutate: download } = usePostExcel();

  // 초기 매체필터 데이터 세팅
  useEffect(() => {
    setMediaList(mediaData.media);
  }, [mediaData.media]);

  // 초기 플레이스먼트필터 데이터 세팅
  useEffect(() => {
    setPlacementList(placementData.placements);
  }, [placementData.placements]);

  // 초기 미디에이션필터 데이터 세팅
  useEffect(() => {
    setMediationList(mediationData.thirdparties);
  }, [mediationData.thirdparties]);

  const onChangeMediaList = useCallback((values: AppMedia[]) => {
    setMediaList(values);
  }, []);

  const onChangePlacementList = useCallback((values: AppPlacement[]) => {
    setPlacementList(values);
  }, []);

  const onChangeMediationList = useCallback((values: Mediation[]) => {
    setMediationList(values);
  }, []);

  const onChangePlatform = useCallback((event: SelectChangeEvent<AppPlatform>) => {
    const value = +event.target.value as AppPlatform;
    setPlatform(value);
  }, []);

  const onChangeReportType = useCallback((event: SelectChangeEvent<Report>) => {
    const value = +event.target.value as Report;
    setReportType(value);
  }, []);

  const onChangeSinceDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setSince(value);
    }
    return;
  }, []);

  const onChangeUntilDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setUntil(value);
    }
    return;
  }, []);

  const onClickSearch = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      search({
        byCountries: true,
        mediaList,
        mediaType: MEDIA.APP,
        placementList,
        reportType,
        thirdpartyList: mediationList,
        since,
        until,
      });
    },
    [mediaList, mediationList, placementList, reportType, search, since, until]
  );

  const onExport = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      download({
        byCountries: country,
        mediaList,
        mediaType: MEDIA.APP,
        placementList,
        reportType,
        thirdpartyList: mediationList,
        since,
        until,
      });
    },
    [country, download, mediaList, mediationList, placementList, reportType, since, until]
  );

  const onToggleCountry = useCallback(
    (_event: SyntheticEvent<Element, Event>, checked: boolean) => {
      setCountry(checked);
    },
    []
  );

  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        앱 리포트
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid className="ssp-tools" container spacing={2}>
          <Grid item xs={2}>
            <Select
              label="리포트"
              placeholder="리포트를 선택해주세요."
              onChange={onChangeReportType}
              value={reportType}
            >
              {Object.entries(REPORT_ALIAS).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Select
              label="플랫폼"
              placeholder="플랫폼을 선택해주세요."
              onChange={onChangePlatform}
              value={platform}
            >
              {Object.entries(APP_PLATFORM_ALIAS).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <MultipleSelect
              className="field"
              options={mediaData.media}
              label="매체"
              placeholder="매체 선택"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.key}
              onChange={onChangeMediaList}
              value={mediaList}
            />
          </Grid>
          <Grid item xs={4}>
            <MultipleSelect
              className="field"
              options={placementData.placements}
              label="플레이스먼트"
              placeholder="플레이스먼트 선택"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={onChangePlacementList}
              value={placementList}
            />
          </Grid>
          <Grid item xs={4}>
            <MultipleSelect
              className="field"
              options={mediationData.thirdparties}
              label="미디에이션"
              placeholder="미디에이션 선택"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={onChangeMediationList}
              value={mediationList}
            />
          </Grid>
          <Grid item xs={3}>
            <DateField label="시작일" value={since} maxDate={until} onChange={onChangeSinceDate} />
          </Grid>
          <Grid item xs={3}>
            <DateField
              label="종료일"
              value={until}
              minDate={since}
              maxDate={moment().subtract(1, "d")}
              onChange={onChangeUntilDate}
            />
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" sx={{ width: "100%" }} onClick={onClickSearch}>
              조회하기
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ReportChart defaultType={isAdmin ? "advertiseCost" : "mediaCost"} data={data.chart} />
          </Grid>
          <Grid item xs={6}>
            <ReportChart defaultType="impression" data={data.chart} />
          </Grid>
          <Grid item xs={12}>
            <ReportBoard
              data={country ? data.countryTable : data.table}
              country={country}
              onToggleCountry={onToggleCountry}
              onExport={onExport}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default AppReport;
