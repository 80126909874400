import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
  Radio,
} from "@mui/material";
import { ColDef, ColGroupDef, ValueFormatterParams } from "ag-grid-community";
import moment from "moment";
import { stringify } from "qs";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";

import { BasicTable } from "src/components/commons";
import { MEDIA, Media } from "src/types";
import useToast from "src/hooks/useToast";

import usePlacementListByReportKey, {
  Placement,
  PlacementListByReportKeyPayload,
} from "src/hooks/apis/report-keys/use-placement-list-by-report-key";

import useUpdateReportKey, {
  UpdateReportKeyData,
} from "src/hooks/apis/report-keys/use-update-report-key";

export type EditReportKeyModalProps = PlacementListByReportKeyPayload & {
  mediaType: Media;
  thirdpartyName: string;
  onClose: VoidFunction;
};

const EditReportKeyModal = (props: EditReportKeyModalProps) => {
  const { mediaType, thirdpartyName, onClose, ...payload } = props;
  const [selectedPlacementId, setSelectedPlacementId] = useState("");
  const toast = useToast();

  const { data, queryKey } = usePlacementListByReportKey(payload, {
    onSuccess: (response) => {
      const placementList = response.data.data;
      const activePlacement = placementList.find((placement) => placement.placement_status === 1);

      setSelectedPlacementId((prev) => activePlacement?.placement_id || prev);
    },
  });

  const { mutate } = useUpdateReportKey();
  const queryClient = useQueryClient();

  const handleUpdateReportKey = useCallback(
    (data: UpdateReportKeyData) => {
      if (!selectedPlacementId) return;

      mutate(data, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(queryKey);
          onClose();
          toast.success("수정되었습니다.");
        },
      });
    },
    [selectedPlacementId, mutate, queryClient, queryKey, toast, onClose]
  );

  return (
    <Dialog fullWidth maxWidth="md" open onClose={onClose}>
      <DialogTitle>리포트키 조회</DialogTitle>
      <DialogContent>
        <Grid container className="ssp-tools" spacing={2} mt="4px">
          <Grid item xs={4}>
            <TextField
              className="field"
              label="미디에이션 네트워크명"
              required
              disabled
              value={thirdpartyName}
              sx={{ width: 1 }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className="field"
              label="미디에이션 키"
              required
              disabled
              value={JSON.stringify(payload.report_key)}
              sx={{ width: 1 }}
            />
          </Grid>
        </Grid>
        <BasicTable
          getRowId={(params) => (params.data as Placement).placement_id}
          rowData={data}
          columnDefs={getColumnDefs({
            placementId: selectedPlacementId,
            setPlacementId: setSelectedPlacementId,
            mediaType,
          })}
        />
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button
          type="button"
          disabled={!selectedPlacementId}
          onClick={() =>
            handleUpdateReportKey({
              placement_id: selectedPlacementId,
              ...payload,
            })
          }
        >
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditReportKeyModal;

export const getColumnDefs = ({
  placementId,
  setPlacementId,
  mediaType,
}: {
  placementId: string;
  setPlacementId: Dispatch<SetStateAction<string>>;
  mediaType: Media;
}): (ColDef | ColGroupDef)[] => [
  {
    headerName: "매체명",
    field: "media_name",
    sortable: true,
  },
  {
    headerName: "매체키",
    field: "media_key",
    sortable: true,
  },
  {
    headerName: "플레이스먼트명",
    field: "placement_name",
    sortable: true,
    cellRenderer: (params: { data: Placement }) => {
      const { media_key, placement_id, placement_name } = params.data;

      const queryData = {
        media: media_key,
        placementId: placement_id,
        showPlacement: true,
      };

      const queryString = stringify(queryData);
      const isAppPlacement = mediaType === MEDIA.APP;

      return (
        <Link
          to={`/${isAppPlacement ? "app" : "web"}/placement?${queryString}`}
          target="_blank"
          rel="noreferrer"
        >
          {placement_name}
        </Link>
      );
    },
  },
  {
    headerName: "플레이스먼트 ID",
    field: "placement_id",
    sortable: true,
  },
  {
    headerName: "등록일자",
    field: "created_at",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const createdAt = param.value * 1000;
      return moment(createdAt).format("YYYY-MM-DD");
    },
  },
  {
    headerName: "리포트에 노출할 지면",
    field: "placement_status",
    sortable: false,
    cellRenderer: (params: { data: Placement }) => (
      <Radio
        checked={placementId === params.data.placement_id}
        value={params.data.placement_id}
        onChange={(e) => setPlacementId(e.target.value)}
        sx={{ width: 1 }}
      />
    ),
  },
];
