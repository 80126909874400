import { css } from "@emotion/react";
import { grey, teal } from "@mui/material/colors";

export const headerStyle = css`
  position: relative;
  background: #fff;
  box-shadow: none;
  a.logo {
    color: ${grey[900]};
    text-decoration: none;
    h5 {
      width: 100px;
      img {
        margin-right: 3rem;
      }
    }
  }
`;

export const mainMenuStyle = css`
  display: flex;
  flex-grow: 1;

  a.tab {
    padding: 24px 32px;

    &.hover,
    &:hover {
      color: ${teal[500]};
      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        border-bottom: 4px solid ${teal[500]};
      }
    }
  }
`;

export const profileStyle = css`
  justify-content: flex-end;
  display: flex;

  .avatar {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const profilePopoverStyle = css`
  padding: 1rem;
  ul {
    list-style: none;
    li {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      a {
        text-decoration: none;
        color: ${teal[500]};
      }

      &.company {
        button {
          width: 100%;
        }
      }

      &.logout {
        border-top: 1px solid ${grey[300]};
        padding-top: 1rem;
        margin-top: 1rem;
        text-align: center;
        button {
          width: 100%;
        }
      }
    }
  }
`;

export const subMenuStyle = css`
  position: absolute;
  top: 66px;
  left: 0;
  width: 100%;
  height: 52px;
  background: #fff;
  border-top: 1px solid ${grey[200]};
  border-bottom: 1px solid ${grey[200]};
  box-shadow: 0 4px 8px -4px ${grey[300]};

  .sub-container {
    display: flex;
    &::before {
      content: "";
      width: 100px;
      margin-right: 16px;
    }

    ul {
      display: flex;

      li {
        margin: 0;
        padding: 0;
        background: transparent;
        a {
          text-decoration: none;
          color: ${grey[700]};
          font-size: 0.875rem;
          padding-left: 24px;
          padding-right: 24px;
        }
        &.active a,
        &:hover a {
          font-weight: 500;
          color: ${teal[500]};
          background: transparent;
        }
      }
    }
  }
`;

export const companyModalStyle = css`
  form {
    margin-bottom: 1rem;
    .search {
      margin-top: 1rem;
    }
  }
`;

export const mobileMenuStyle = css`
  flex-grow: 1;
  position: relative;
`;

export const mobileMenuDrawerStyle = css`
  .main-menu > span {
    font-weight: 500;
  }
  .main-active > span {
    color: ${teal[500]};
  }
  .sub-menu-box {
    background: #f8f9fb;
    .sub-menu {
      padding-left: 2rem;
    }
    .sub-menu.active {
      background: ${grey[200]};
      & > div > span {
        color: ${teal[500]};
        font-weight: 500;
      }
    }
  }
`;
