import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useMemo } from "react";
import { BlockCategory } from "src/hooks/apis/blocklist/useGetCategories";
import usePutCategories from "src/hooks/apis/blocklist/usePutCategories";
import { STATUS } from "src/types";

interface EditCategoryModalProps {
  onClose: () => void;
  open: boolean;
  placementId: string;
  blockCategory: BlockCategory | null;
}

const EditCategoryModal = ({
  onClose,
  open,
  placementId,
  blockCategory,
}: EditCategoryModalProps) => {
  const { mutate } = usePutCategories();

  const initialValues = useMemo(
    () =>
      blockCategory?.sub_categories.reduce((acc: Record<string, boolean>, cur) => {
        acc[cur.code] = cur.status === STATUS.ACTIVE ? true : false;
        return acc;
      }, {}) || {},
    [blockCategory?.sub_categories]
  );

  const { getFieldProps, handleSubmit, values } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (blockCategory)
        mutate({
          placementId: placementId,
          categories: blockCategory.sub_categories.map((sub) => ({
            ...sub,
            status: values[sub.code] ? STATUS.ACTIVE : STATUS.SUSPEND,
          })),
        });
    },
  });

  const isChecked = useCallback((code: string) => values[code] || false, [values]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="representative-category"
      aria-describedby="edit category"
    >
      <DialogTitle id="dialog-title">{blockCategory?.name}</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="edit-category-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {blockCategory?.sub_categories.map((category) => (
              <Grid item xs={4} key={category.code}>
                <FormControlLabel
                  className="switch"
                  control={<Switch color="primary" />}
                  label={category.name}
                  labelPlacement="end"
                  {...getFieldProps(category.code)}
                  checked={isChecked(category.code)}
                />
              </Grid>
            ))}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-category-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCategoryModal;
