import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { ColDef, ColGroupDef, RowDragEvent } from "ag-grid-community";
import { MouseEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { BasicTable } from "src/components/commons";
import useGetWebThirdpartyList, {
  ThirdpartyInfo,
} from "src/hooks/apis/placements/useGetWebThirdpartyList";
import usePutWebThirdpartyList from "src/hooks/apis/placements/usePutWebThirdpartyList";
import useOpenModal from "src/hooks/useOpenModal";
import { STATUS, THIRDPARTY } from "src/types";
import MatchingInfoModal from "./MatchingInfoModal";
import { editMediationModalStyle } from "./styles";

interface EditMediationModalProps {
  isWebMediation: boolean;
  onClose: () => void;
  open: { key: string; isOpen: boolean };
}

const EditMediationModal = ({ isWebMediation, onClose, open }: EditMediationModalProps) => {
  const { mutate } = usePutWebThirdpartyList();
  const { data } = useGetWebThirdpartyList({
    placementId: open.key,
    type: THIRDPARTY.WEB_MEDIATION,
  });
  const [mediationStatus, setMediationStatus] = useState(false);
  const [mediationList, setMediationList] = useState<ThirdpartyInfo[]>([]);

  // 초기 미디에이션 데이터 세팅
  useEffect(() => {
    setMediationStatus(isWebMediation);
  }, [isWebMediation]);

  // 초기 미디에이션 데이터 세팅
  useEffect(() => {
    if (data.thirdparties) setMediationList(data.thirdparties);
  }, [data.thirdparties]);

  const onRowDragEnd = useCallback((e: RowDragEvent) => {
    const nodeId = e.node.id;
    setMediationList((prev) => {
      const idx = prev.findIndex((v) => `${v.id}` === nodeId);
      const removed = prev?.splice(idx, 1);
      prev.splice(e.node.rowIndex ?? 0, 0, ...removed);
      return prev;
    });
  }, []);

  const onChangeMediationStatus = useCallback(
    (_event: SyntheticEvent<Element, Event>, checked: boolean) => {
      setMediationStatus(checked);
      mutate({
        placementId: open.key,
        isWebMediation: checked,
        thirdparties: checked ? data.thirdparties : [],
        type: THIRDPARTY.WEB_MEDIATION,
      });
    },
    [data.thirdparties, mutate, open.key]
  );

  const onSave = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      mutate({
        isWebMediation: mediationStatus,
        placementId: open.key,
        thirdparties: mediationList,
        type: THIRDPARTY.WEB_MEDIATION,
      });
    },
    [mediationList, mediationStatus, mutate, open.key]
  );

  const onChangeIndividualStatus = useCallback((id: number, checked: boolean) => {
    setMediationList((prev) => {
      const idx = prev.findIndex((v) => v.id === id);
      prev[idx]["status"] = checked ? STATUS.ACTIVE : STATUS.SUSPEND;
      return [...prev];
    });
  }, []);

  return (
    <Dialog
      css={editMediationModalStyle}
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-mediation"
      aria-describedby="edit mediation"
    >
      <DialogTitle id="dialog-title">미디에이션 설정</DialogTitle>
      <DialogContent className="dialog-content">
        <FormControlLabel
          className="switch"
          control={<Switch color="primary" />}
          label="미디에이션"
          labelPlacement="start"
          checked={mediationStatus}
          onChange={onChangeMediationStatus}
        />
        {mediationStatus && (
          <BasicTable
            getRowId={(params) => params.data.match_no}
            animateRows
            rowData={mediationList}
            columnDefs={getColumnDefs(open.key, onChangeIndividualStatus)}
            rowDragManaged
            onRowDragEnd={onRowDragEnd}
            onRowDragLeave={onRowDragEnd}
          />
        )}
      </DialogContent>
      {mediationStatus && (
        <DialogActions sx={{ marginBottom: "1rem" }}>
          <Button onClick={onClose} color="inherit">
            취소
          </Button>
          <Button onClick={onSave}>저장</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const getColumnDefs = (
  placementId: string,
  onChangeStatus: (matchNo: number, checked: boolean) => void
): (ColDef | ColGroupDef)[] => [
  {
    headerName: "미디에이션 명",
    field: "name",
    sortable: true,
    rowDrag: true,
  },
  {
    headerName: "매칭정보",
    cellRenderer: (params: { data: ThirdpartyInfo }) => {
      return <MatchingButton data={params.data} placementId={placementId} />;
    },
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
  },
  {
    headerName: "상태",
    field: "status",
    sortable: true,
    cellRenderer: (params: { data: ThirdpartyInfo }) => (
      <StatusSwitch data={params.data} placementId={placementId} onChange={onChangeStatus} />
    ),
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

function MatchingButton({ data, placementId }: { data: ThirdpartyInfo; placementId: string }) {
  const [openMatchingInfoModal, onShowMatchingInfoModal, onCloseMatchingInfoModal] = useOpenModal(
    data.id
  );

  const isInit = useMemo(
    () =>
      !(
        (data.match_key && Object.values(data.match_key).some((v) => Boolean(v))) ||
        data.passback_script
      ),
    [data.match_key, data.passback_script]
  );
  const label = useMemo(() => (isInit ? "등록하기" : "변경하기"), [isInit]);

  return (
    <>
      <Button
        variant="text"
        size="small"
        color={isInit ? "inherit" : "primary"}
        onClick={onShowMatchingInfoModal}
      >
        {label}
      </Button>
      {openMatchingInfoModal.isOpen && (
        <MatchingInfoModal
          thirdpartyType={THIRDPARTY.WEB_MEDIATION}
          placementId={placementId}
          open={openMatchingInfoModal}
          onClose={onCloseMatchingInfoModal}
          {...data}
        />
      )}
    </>
  );
}

function StatusSwitch({
  data,
  onChange,
}: {
  data: ThirdpartyInfo;
  placementId: string;
  onChange: (id: number, checked: boolean) => void;
}) {
  const [checked, setChecked] = useState(data.status === STATUS.ACTIVE);
  const onChangeStatus = useCallback(
    (id: number) => (_event: SyntheticEvent<Element, Event>, checked: boolean) => {
      setChecked(checked);
      onChange(id, checked);
    },
    [onChange]
  );

  return <Switch onChange={onChangeStatus(data.id)} checked={checked}></Switch>;
}

export default EditMediationModal;
