import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { UserRole } from "src/types";
import { Authority } from "src/types/authority";
import API from "src/utils/api";
import { createAuthority } from "src/utils/auth-helper";

export interface AuthorityWithRole extends Omit<Authority, "id" | "type"> {
  role: UserRole;
}

/**
 * isStd가 true이면 roles는 기본인지 커스텀인지 권한의 type을 나타낸다. (기본 권한 다 내려주거나, 커스텀 권한 다 내려준다.)
 * isStd가 false이면 roles는 role id를 나타낸다. (해당하는 역할에 따른 권한을 내려준다.)
 */
interface Params {
  //isStd: boolean; // true: 기본 권한 리스트 조회, false: 수정 가능한 권한 리스트 조회
  role: UserRole;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    authorities: AuthorityWithRole[];
  };
  text: "ok" | "bad-request" | "unauthorized";
}

/*
  편집 권한 조회
*/
const useGetEditAuthorityList = ({ role }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["users/useGetEditAuthorityList", role],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/users/authorities`, {
        params: {
          is_std: false,
          roles: role,
        },
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => {
    if (data)
      return {
        authority: data.authorities.map((auth) => createAuthority(auth))[0],
      };
    return { authority: null };
  }, [data]);

  return { data: result, ...rest };
};

export default useGetEditAuthorityList;
