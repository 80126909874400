import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { AppPlatform } from "src/types";
import API from "src/utils/api";

interface Params {
  platformType: AppPlatform;
  scheme: string;
}

interface Response {
  code: 200 | 400 | 401 | 409 | 500;
  data?: {
    scheme_no: number;
  };
  text: "ok" | "bad-request" | "unauthorized" | "conflict" | "internal-server-error";
}

// 스키마 등록
const usePostAppScheme = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const { data, ...rest } = useMutation<Response, AxiosError, Params>(
    async ({ platformType, scheme }) => {
      const response: AxiosResponse<Response> = await API.default.post(`/media/app/schemes`, {
        platform_type: platformType,
        scheme: scheme,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("DeepLink가 등록되었습니다.");
        invalidate("media/useGetAppScheme");
      },
      onError: handleError,
    }
  );

  const result = useMemo(() => (data ? data : { scheme_no: 0 }), [data]);

  return { data: result, ...rest };
};

export default usePostAppScheme;
