import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

export interface SubId {
  account_no: number;
  subid: string;
  websites: string;
  create_datetime: string;
  is_editable: boolean;
  partner_name: string;
}

interface Params {
  accountNo?: number;
  q?: string;
  pageNo: number;
  pageSize?: number;
}

interface Response {
  code?: 200 | 400 | 422 | 500;
  subid_list?: SubId[];
  pages?: number;
  text?: "ok" | "bad-request" | "field required" | "internal-server-error";
}

// 쿠팡 서브 ID 조회
const useGetCoupangSubIds = ({ accountNo, q, pageNo, pageSize }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response, AxiosError>(
    ["coupang/useGetCoupangSubIds", accountNo, q, pageNo, pageSize],
    async () => {
      const response: AxiosResponse<Response> = await API.integration.get(
        `/operation/management/coupang/subid/GetCoupangSubIds`,
        {
          params: { account_no: accountNo, q: q, page_no: pageNo, page_size: pageSize },
        }
      );
      return response.data;
    },
    {
      enabled: accountNo !== undefined,
      onError: handleError,
    }
  );

  const result = useMemo(() => {
    if (data && data.subid_list && data.subid_list.length > 0)
      return {
        subIdList: data.subid_list,
        pages: data.pages || 0,
      };
    return { subIdList: [], pages: 0 };
  }, [data]);

  return { data: result, ...rest };
};

export default useGetCoupangSubIds;
