import { useQueryClient } from "react-query";

const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  const invalidate = async (keys?: string | string[]) => {
    if (typeof keys === "string") {
      await queryClient.invalidateQueries(keys);
      return;
    }
    if (Array.isArray(keys)) {
      const invalidations: Promise<void>[] = [];

      keys.forEach((key) => {
        invalidations.push(queryClient.invalidateQueries(key));
      });

      await Promise.all(invalidations);

      return;
    }
    await queryClient.invalidateQueries();
    return;
  };

  return invalidate;
};

export default useInvalidateQueries;
