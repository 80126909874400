import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import Introduction from "./Introduction";
import { loginStyle } from "./styles";

const LoginLayout = () => {
  return (
    <Grid css={loginStyle} container spacing={2}>
      <Grid item xs={6}>
        <Introduction />
      </Grid>
      <Grid item xs={6}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default LoginLayout;
