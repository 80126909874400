import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

interface Params {
  partnerNo: number;
}

interface Response {
  code?: 200 | 401 | 422;
  data?: {
    ap_commission_rate: string;
    client_commission_rate: string;
    company_key: string;
    company_name: string;
    partner_name: string;
    partner_no: number;
    subids: number;
  };
  text?: "ok" | "unauthorized" | "unprocessable-entity";
}

/*
  쿠팡 파트너 조회
*/
const useGetPartner = ({ partnerNo }: Params) => {
  const { handleError } = useApiError();
  const { data: response, ...rest } = useQuery<Response, AxiosError>(
    ["coupang/useGetPartner", partnerNo],
    async () => {
      const response: AxiosResponse<Response> = await API.integration.get(
        `/operation/management/coupang/partner/${partnerNo}`
      );
      return response.data;
    },
    { enabled: !!partnerNo, onError: handleError }
  );

  const result = useMemo(() => {
    if (response && response.data)
      return {
        partner: { ...response.data },
      };
    return {
      partner: {
        ap_commission_rate: "",
        client_commission_rate: "",
        company_key: "",
        company_name: "",
        partner_name: "",
        partner_no: 0,
        subids: 0,
      },
    };
  }, [response]);

  return { data: result, ...rest };
};

export default useGetPartner;
