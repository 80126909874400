import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

interface Params {
  companyId: number;
}

interface Response {
  code: 200 | 401 | 404;
  data?: {
    company_key: string;
    created_at: number;
    is_popup_closed: boolean;
    reject_message: string;
  };
  text: "ok" | "unauthorized" | "not-found";
}

// 회사 승인/거절 상태 조회
const useGetCompanyApproval = ({ companyId }: Params) => {
  const { handleError } = useApiError({ 404: (_error: AxiosError) => null });
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["companies/useGetCompanyApproval", companyId],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(
        `/companies/${companyId}/status`
      );
      return response.data.data;
    },
    { enabled: !!companyId, onError: handleError }
  );

  const result = useMemo(
    () =>
      data ? data : { company_key: "", created_at: 0, is_popup_closed: true, reject_message: "" },
    [data]
  );

  return { data: result, ...rest };
};

export default useGetCompanyApproval;
