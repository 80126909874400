import { Paper, Typography } from "@mui/material";
import { useMemo } from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { DASHBOARD_LABEL } from "src/assets/alias/dashboard";
import COLORS from "src/assets/colors";
import { DataKey } from "./Dashboard";
import MediationTooltip from "./PlacementTooltip";

interface PlacementChartProps {
  data?: {
    campaign_type: number;
    campaign_type_name: string;
    click: number;
    ecpm: number;
    impression: number;
    request: number;
    response: number;
    revenue: number;
    rpr: number;
    size_type: number;
    size_type_name: string;
  }[];
  dataKey: DataKey;
}

const PlacementChart = ({ data, dataKey = "request" }: PlacementChartProps) => {
  const total = useMemo(() => {
    if (data) return data.reduce((acc, cur) => acc + cur[dataKey], 0);
    return 0;
  }, [dataKey, data]);

  const placementData = useMemo(() => {
    if (data)
      return data.map((placement) => {
        const name = placement.campaign_type_name
          ? placement.size_type_name
            ? `${placement.campaign_type_name} ${placement.size_type_name}`
            : placement.campaign_type_name
          : "undefined";

        return {
          name,
          value: placement[dataKey],
        };
      });
    return [];
  }, [dataKey, data]);

  return (
    <>
      <Typography className="title" variant="h5" gutterBottom>
        플레이스먼트 <small className="sub-title">{`(${DASHBOARD_LABEL[dataKey]})`}</small>
      </Typography>
      <Paper elevation={2} className="chart">
        <ResponsiveContainer height={300}>
          <PieChart>
            <Pie data={placementData} innerRadius={90} outerRadius={120} dataKey={"value"}>
              {placementData?.map((_, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Legend iconSize={10} align="left" verticalAlign="middle" layout="vertical" />
            <Tooltip
              label="name"
              content={(params) => <MediationTooltip {...params} total={total} dataKey={dataKey} />}
            />
          </PieChart>
        </ResponsiveContainer>
      </Paper>
    </>
  );
};

export default PlacementChart;
