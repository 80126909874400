import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

export interface Partner {
  partner_no: number;
  partner_name: string;
}

interface Response {
  code?: 200 | 401;
  data?: Partner[];
  pages?: number;
  text?: "ok" | "unauthorized";
}

/*
  SubId 등록 화면에서 파트너 조회 시 사용
*/
const useGetSubIdPartnerList = () => {
  const { handleError } = useApiError();
  const { data: response, ...rest } = useQuery<Response, AxiosError>(
    ["coupang/useGetSubIdPartnerList"],
    async () => {
      const response: AxiosResponse<Response> = await API.integration.get(
        `/operation/management/coupang/subid/GetCoupangPartners`
      );
      return response.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => {
    if (response && response.data)
      return {
        partners: response.data,
      };
    return { partners: [] };
  }, [response]);

  return { data: result, ...rest };
};

export default useGetSubIdPartnerList;
