import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  FormControlLabel,
  Switch,
  DialogActions,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import { useMemo, useCallback, ChangeEvent } from "react";
import { TextField } from "src/components/commons";
import { NpayPlacement } from "src/hooks/apis/npay/use-npay-placements";
import useUpdateNpayPlacement from "src/hooks/apis/npay/use-update-npay-placement";
import useToast from "src/hooks/useToast";
import { STATUS, CAMPAIGN_ALIAS } from "src/types";
import { object, string, number } from "yup";

type UpdateNpayPlacementFormValues = Pick<
  NpayPlacement,
  "media_name" | "media_key" | "name" | "id" | "ad_type" | "status"
>;

export default function UpdateNpayPlacementModal(props: {
  placement: NpayPlacement;
  onUpdate: () => Promise<void>;
  onClose: VoidFunction;
}) {
  const { placement, onUpdate, onClose } = props;

  const toast = useToast();
  const { mutate: updateNpayPlacement, isLoading: isUpdating } = useUpdateNpayPlacement();

  const initialValues = useMemo<UpdateNpayPlacementFormValues>(
    () => ({
      media_name: placement.media_name,
      media_key: placement.media_key,
      name: placement.name,
      id: placement.id,
      ad_type: placement.ad_type,
      status: placement.status,
    }),
    [placement]
  );

  const handleUpdate = (values: UpdateNpayPlacementFormValues) => {
    updateNpayPlacement(values, {
      onSuccess: () => {
        toast.success("저장되었습니다.");
        onClose();
        onUpdate();
      },
    });
  };

  const { values, handleSubmit, setFieldValue, handleBlur, isValid } = useFormik({
    initialValues,
    onSubmit: handleUpdate,
    validationSchema: object({
      media_name: string().required(),
      media_key: string().required(),
      name: string().required(),
      id: string().required(),
      ad_type: number().required(),
      status: number().required().oneOf([STATUS.ACTIVE, STATUS.SUSPEND]),
    }),
  });

  const toggleStatus = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      setFieldValue("status", checked ? STATUS.ACTIVE : STATUS.SUSPEND);
    },
    [setFieldValue]
  );

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle id="edit-npay-plcmt-modal-title">네이버페이 적립 플레이스먼트 수정</DialogTitle>
      <DialogContent className="edit-npay-plcmt-modal-dialog-content">
        <Stack
          component="form"
          id="edit-npay-plcmt-modal"
          pt="20px"
          spacing={2}
          onSubmit={handleSubmit}
        >
          {/* 업체 명 */}
          <TextField required label="매체 명" value={values.media_name} aria-readonly disabled />
          {/* 플레이스먼트 명 */}
          <TextField required label="플레이스먼트 명" value={values.name} aria-readonly disabled />
          {/* 플레이스먼트 ID */}
          <TextField required label="플레이스먼트 ID" value={values.id} aria-readonly disabled />
          {/* 광고 형식 */}
          <TextField
            required
            label="광고 형식"
            value={CAMPAIGN_ALIAS[values.ad_type]}
            aria-readonly
            disabled
          />
          {/* 상태 */}
          <FormControlLabel
            className="switch"
            sx={{ alignSelf: "start" }}
            control={
              <Switch
                color="primary"
                checked={values.status === STATUS.ACTIVE}
                onChange={toggleStatus}
                onBlur={handleBlur("status")}
              />
            }
            label="상태"
            labelPlacement="start"
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button type="button" onClick={onClose} color="inherit" disabled={isUpdating}>
          취소
        </Button>
        <Button type="submit" form="edit-npay-plcmt-modal" disabled={!isValid || isUpdating}>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}
