import { Container, Paper, Tab, Tabs, Typography } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { coupangStyle } from "./styles";

const CoupangLayout = () => {
  const { pathname } = useLocation();

  return (
    <Container component="section" className="ssp-section" css={coupangStyle} maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        쿠팡 관리
      </Typography>
      <Paper className="content" elevation={2}>
        <Tabs className="tabs" value={pathname}>
          <Tab
            label="파트너 관리"
            value="/operation/coupang/partner"
            href="/operation/coupang/partner"
          />
          <Tab
            label="SUB ID 관리"
            value="/operation/coupang/subId"
            href="/operation/coupang/subId"
          />
          <Tab
            label="포스트백 관리"
            value="/operation/coupang/postback"
            href="/operation/coupang/postback"
          />
          <Tab
            label="캠페인 관리"
            value="/operation/coupang/campaign"
            href="/operation/coupang/campaign"
          />
          <Tab
            label="CS 관리"
            value="/operation/coupang/service"
            href="/operation/coupang/service"
          />
          <Tab label="리포트" value="/operation/coupang/report" href="/operation/coupang/report" />
        </Tabs>
        <Outlet />
      </Paper>
    </Container>
  );
};

export default CoupangLayout;
