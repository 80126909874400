import { css } from "@emotion/react";
import { red } from "@mui/material/colors";

export const addPartnerModalStyle = css`
  .dialog-content {
    #add-form {
      padding-top: 20px;

      .label {
        font-weight: 500;
      }

      .postback-type {
        padding-left: 0.5rem;
      }

      .ico-delete {
        fill: ${red[500]};
      }
    }
  }
`;

export const editPartnerModalStyle = css`
  .dialog-content {
    #edit-form {
      padding-top: 20px;

      .delete {
        padding-left: 0.5rem;
        .ico-delete {
          fill: ${red[500]};
        }
      }
    }
  }
`;
