import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Company, Status } from "src/types";
import API from "src/utils/api";

export interface CompanyData {
  address: string;
  biz_registration_confirmed: boolean;
  biz_registration_img_url: string;
  biz_registration_no: string;
  created_at: number;
  id: number;
  key: string;
  manager_email: string;
  manager_name: string;
  manager_phone_no: string;
  manager_uid: number;
  name: string;
  reject_message: string;
  reject_popup_closed: boolean;
  settlement_commission_rate: number;
  settlement_type: number;
  status: Status;
  sub_company_ids?: number[];
  tw_company_id: number;
  type: number;
  updated_at: number;
  use_adserver: boolean;
}

interface Params {
  types: Company[]; // 1: 기업, 2: 개인, 3: 신디케이션
  name?: string;
  status?: Status; // 10: 활성, 30: 비활성, 50: 등록 미확인
  syndicationId?: number;
  pageNo?: number;
  pageSize?: number;
  orders?: string[]; // 오름차순 '필드명,+필드명’, 내림차순 '-필드명’, 기본값: name
  disabled?: boolean;
}

interface Response {
  code: 200 | 400;
  data?: {
    companies: CompanyData[];
    pages: number;
  };
  text: "ok" | "bad-request";
}

// 전체 회사 리스트 조회
const useGetCompanies = ({
  types,
  name,
  status,
  syndicationId,
  pageNo = 1,
  pageSize,
  orders,
  disabled,
}: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["companies/useGetCompanies", types, name, status, syndicationId, pageNo, pageSize, orders],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/companies`, {
        params: {
          types: types.join(","),
          name,
          status,
          syndication_id: syndicationId,
          page_no: pageNo,
          page_size: pageSize,
          orders: orders?.join(","),
        },
      });
      return response.data.data;
    },
    { enabled: !disabled, onError: handleError }
  );

  const result = useMemo(() => (data ? data : { companies: [], pages: 0 }), [data]);

  return { data: result, ...rest };
};

export default useGetCompanies;
