import { Button, Dialog, DialogContent, DialogTitle, Switch } from "@mui/material";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useCallback, useMemo } from "react";
import { FaCheckCircle, FaCircle } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import { BasicTable } from "src/components/commons";
import useGetAppThirdpartyList, {
  ThirdpartyInfo,
} from "src/hooks/apis/placements/useGetAppThirdpartyList";
import usePutAppThirdpartyStatus from "src/hooks/apis/placements/usePutAppThirdpartyStatus";
import useOpenModal from "src/hooks/useOpenModal";
import { Campaign, STATUS, THIRDPARTY } from "src/types";
import MatchingInfoModal from "./MatchingInfoModal";
import { editDspModalStyle } from "./styles";

export interface CampaignInfo {
  type: Campaign;
  size?: number;
  minviewtime?: number;
  iconImageRequired?: boolean;
  titleRequired?: boolean;
  descRequired?: boolean;
  mainImageRequired?: boolean;
  ctatextRequired?: boolean;
  mute?: boolean;
}

interface EditDspModalProps {
  onClose: () => void;
  open: { key: string; isOpen: boolean };
}

const EditDspModal = ({ onClose, open }: EditDspModalProps) => {
  const { data } = useGetAppThirdpartyList({ placementId: open.key, type: THIRDPARTY.APP_DSP });
  return (
    <Dialog
      css={editDspModalStyle}
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-dsp"
      aria-describedby="edit dsp"
    >
      <DialogTitle id="dialog-title">DSP 설정</DialogTitle>
      <DialogContent className="dialog-content">
        <BasicTable
          getRowId={(params) => params.data.id}
          animateRows
          rowData={data.thirdparties}
          columnDefs={getColumnDefs(open.key)}
        />
      </DialogContent>
    </Dialog>
  );
};

const getColumnDefs = (placementId: string): (ColDef | ColGroupDef)[] => [
  {
    headerName: "DSP 명",
    field: "name",
    sortable: true,
  },
  {
    headerName: "매칭정보",
    cellRenderer: (params: { data: ThirdpartyInfo }) => {
      return params.data.match_key_info && params.data.match_key_info.length > 0 ? (
        <MatchingButton data={params.data} placementId={placementId} />
      ) : null;
    },
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
  },
  {
    headerName: "상태",
    field: "individual_status",
    sortable: true,
    cellRenderer: (params: { data: ThirdpartyInfo }) => (
      <StatusSwitch data={params.data} placementId={placementId} />
    ),
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

function MatchingButton({ data, placementId }: { data: ThirdpartyInfo; placementId: string }) {
  const [openMatchingInfoModal, onShowMatchingInfoModal, onCloseMatchingInfoModal] = useOpenModal(
    data.match_no
  );

  const isInit = useMemo(
    () => !(data.match_key && Object.values(data.match_key).some((v) => Boolean(v))),
    [data.match_key]
  );
  const label = useMemo(() => (isInit ? "등록하기" : "변경하기"), [isInit]);

  return (
    <>
      <Button
        variant="text"
        size="small"
        color={isInit ? "inherit" : "primary"}
        onClick={onShowMatchingInfoModal}
      >
        {label}
      </Button>
      {openMatchingInfoModal.isOpen && (
        <MatchingInfoModal
          placementId={placementId}
          open={openMatchingInfoModal}
          onClose={onCloseMatchingInfoModal}
          {...data}
        />
      )}
    </>
  );
}

function StatusSwitch({ data, placementId }: { data: ThirdpartyInfo; placementId: string }) {
  const { mutate } = usePutAppThirdpartyStatus();
  const onClickIndividual = useCallback(() => {
    mutate({
      placementId: placementId,
      thirdpartyId: data.id,
      status: data.individual_status === STATUS.ACTIVE ? STATUS.SUSPEND : STATUS.ACTIVE,
    });
  }, [data.id, data.individual_status, mutate, placementId]);

  const onClickClose = useCallback(() => {
    mutate({
      placementId: placementId,
      thirdpartyId: data.id,
      status: STATUS.DELETED,
    });
  }, [data.id, mutate, placementId]);

  const onClickDsp = useCallback(() => {
    mutate({
      placementId: placementId,
      thirdpartyId: data.id,
      status: STATUS.ACTIVE,
    });
  }, [data.id, mutate, placementId]);

  if (data.individual_status !== STATUS.DELETED)
    return (
      <>
        <Switch
          onClick={onClickIndividual}
          checked={data.individual_status === STATUS.ACTIVE}
        ></Switch>
        <RiCloseCircleFill onClick={onClickClose} className="back-to-dsp" />
      </>
    );
  if (data.status === STATUS.ACTIVE)
    return <FaCheckCircle onClick={onClickDsp} className="active-dsp" />;
  return <FaCircle onClick={onClickDsp} className="inactive-dsp" />;
}

export default EditDspModal;
