import { css } from "@emotion/react";
import { grey } from "@mui/material/colors";

export const footerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3rem;
  color: ${grey[500]};
`;
