import { css } from "@emotion/react";

export const addPostbackModalStyle = css`
  .dialog-content {
    #add-form,
    #edit-form {
      padding-top: 20px;
    }
  }
`;
