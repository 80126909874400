import { Container, Paper, Tab, Tabs, Typography } from "@mui/material";
import { SyntheticEvent, useCallback, useState } from "react";
import PublisherBoard from "./PublisherBoard";
import SyndicationBoard from "./SyndicationBoard";

const CompanyManagement = () => {
  const [tabValue, setTabValue] = useState(0);

  const onClickMainMenu = useCallback((_event: SyntheticEvent, value: number) => {
    setTabValue(value);
  }, []);

  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        업체 관리
      </Typography>
      <Paper className="content" elevation={2}>
        <Tabs className="tabs" value={tabValue} onChange={onClickMainMenu}>
          <Tab label="퍼블리셔" value={0} />
          <Tab label="신디케이션" value={1} />
        </Tabs>
        {tabValue === 0 && <PublisherBoard />}
        {tabValue === 1 && <SyndicationBoard />}
      </Paper>
    </Container>
  );
};

export default CompanyManagement;
