import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  ChangeEvent,
  FormEvent,
  Fragment,
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { Select, TextField } from "src/components/commons";
import useGetCompanies, { CompanyData } from "src/hooks/apis/companies/useGetCompanies";
import usePostOfferwall from "src/hooks/apis/partners/usePostOfferwall";
import {
  AppPlatform,
  APP_PLATFORM,
  APP_PLATFORM_ALIAS,
  COMPANY,
  Partner,
  PARTNER,
  PARTNER_ALIAS,
  STATUS,
} from "src/types";
import { v4 as uuidv4 } from "uuid";
import { addExternalReportModalStyle } from "./styles";

interface AddExternalReportModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const AddExternalReportModal = ({ isOpen, onClose }: AddExternalReportModalProps) => {
  const partners = useMemo(
    () => [
      { label: PARTNER_ALIAS[PARTNER.FYBER], value: PARTNER.FYBER },
      { label: PARTNER_ALIAS[PARTNER.TAPJOY], value: PARTNER.TAPJOY },
      { label: PARTNER_ALIAS[PARTNER.AyetStudios], value: PARTNER.AyetStudios },
    ],
    []
  );
  const { data: companyData } = useGetCompanies({
    types: [
      COMPANY.CORPORATION,
      COMPANY.INDIVIDUAL,
      COMPANY.SYNDICATION,
      COMPANY.SYNDICATION_CLIENT_CORPORATION,
      COMPANY.SYNDICATION_CLIENT_INDIVIDUAL,
    ],
    status: STATUS.ACTIVE,
  });

  const [partner, setPartner] = useState<{ label: string; value: Partner } | null>(null);
  const [company, setCompany] = useState<CompanyData | null>(null);
  const [apps, setApps] = useState<
    {
      key: string;
      osType: AppPlatform;
      partnerAppName: string;
      partnerAppKey: string;
    }[]
  >([
    {
      key: uuidv4(),
      osType: APP_PLATFORM.ANDROID,
      partnerAppName: "",
      partnerAppKey: "",
    },
  ]);

  const { mutate } = usePostOfferwall();

  const onSelectPartner = useCallback(
    (_: SyntheticEvent<unknown>, v: { label: string; value: Partner } | null) => {
      setPartner(v);
    },
    []
  );

  const onSelectCompany = useCallback((_: SyntheticEvent<unknown>, v: CompanyData | null) => {
    setCompany(v);
  }, []);

  const onSelectPlatform = useCallback(
    (key: string) => (e: SelectChangeEvent<AppPlatform>) => {
      const targetIdx = apps.findIndex((v) => v.key === key);
      setApps((prev) => {
        if (targetIdx >= 0) {
          prev[targetIdx].osType = +e.target.value as AppPlatform;
        }
        return [...prev];
      });
    },
    [apps]
  );

  const onAddApp = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setApps((prev) => [
      ...prev,
      { key: uuidv4(), osType: APP_PLATFORM.ANDROID, partnerAppName: "", partnerAppKey: "" },
    ]);
  }, []);

  const onDeleteApp = useCallback(
    (key: string) => (e: MouseEvent) => {
      e.preventDefault();
      setApps((prev) => prev.filter((v) => v.key !== key));
    },
    []
  );

  const onChangeName = useCallback(
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setApps((prev) => {
        const idx = prev.findIndex((app) => app.key === key);
        if (idx >= 0) {
          prev[idx]["partnerAppName"] = e.target.value;
        }
        return [...prev];
      });
    },
    []
  );

  const onChangeKey = useCallback(
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setApps((prev) => {
        const idx = prev.findIndex((app) => app.key === key);
        if (idx >= 0) {
          prev[idx]["partnerAppKey"] = e.target.value;
        }
        return [...prev];
      });
    },
    []
  );

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (company?.key && partner?.value) {
        mutate({ companyKey: company.key, partnerApps: apps, partnerId: partner.value });
        onClose();
      }
    },
    [apps, company?.key, mutate, onClose, partner?.value]
  );

  return (
    <Dialog
      css={addExternalReportModalStyle}
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-external-report"
      aria-describedby="add external report"
    >
      <DialogTitle id="dialog-title">신규 외부 리포트 연동</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="add-form" onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                className="field"
                size="small"
                options={partners}
                getOptionLabel={(partner) => `${partner.label}`}
                renderInput={({ InputLabelProps, ...params }) => (
                  <TextField
                    {...params}
                    label="리포트 업체"
                    placeholder="연동할 리포트 업체를 선택해주세요."
                    required
                  />
                )}
                value={partner}
                onChange={onSelectPartner}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                className="field"
                size="small"
                options={companyData.companies}
                getOptionLabel={(company) => `${company.name}`}
                renderInput={({ InputLabelProps, ...params }) => (
                  <TextField
                    {...params}
                    label="계약 업체"
                    placeholder="해당 리포트 업체와 연동할 계약 업체를 선택하세요."
                    required
                  />
                )}
                value={company}
                onChange={onSelectCompany}
              />
            </Grid>
            <Grid item xs={12} className="guide">
              <Typography variant="subtitle1" gutterBottom>
                연동할 APP 이름과 APP Key를 입력해주세요.
              </Typography>
              <Typography className="description" variant="body2" gutterBottom>
                {`(리포트 업체에서 발급되는 APP Key입니다. 해당 APP Key는 설정한 계약 업체의 리포트와 연동합니다.)`}
              </Typography>
            </Grid>
            {apps.map(({ key, osType, partnerAppName, partnerAppKey }) => {
              return (
                <Fragment key={key}>
                  <Grid item xs={2}>
                    <Select
                      label="플랫폼"
                      placeholder="플랫폼"
                      onChange={onSelectPlatform(key)}
                      value={osType}
                    >
                      <MenuItem value={APP_PLATFORM.ANDROID}>
                        {APP_PLATFORM_ALIAS[APP_PLATFORM.ANDROID]}
                      </MenuItem>
                      <MenuItem value={APP_PLATFORM.iOS}>
                        {APP_PLATFORM_ALIAS[APP_PLATFORM.iOS]}
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      className="field"
                      label="APP 이름"
                      value={partnerAppName}
                      placeholder="App 이름을 입력하세요."
                      required
                      onChange={onChangeName(key)}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      className="field"
                      label="APP Key"
                      value={partnerAppKey}
                      placeholder="해당 APP Key를 입력하세요."
                      required
                      onChange={onChangeKey(key)}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton aria-label="delete" onClick={onDeleteApp(key)}>
                      <AiOutlineDelete className="ico-delete" />
                    </IconButton>
                  </Grid>
                </Fragment>
              );
            })}
            <Grid item xs={11}>
              <Button onClick={onAddApp}>+ APP 정보 추가하기</Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="add-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddExternalReportModal;
