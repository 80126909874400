import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import API from "src/utils/api";
import useApiError from "../useApiError";
import { DataToolsHistory } from "./types";

type DataToolsHistoryQueryFn = QueryFunction<AxiosResponse<DataToolsHistory[]>, string>;

type DataToolsHistoryQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<DataToolsHistory[]>,
    AxiosError,
    AxiosResponse<DataToolsHistory[]>,
    string
  >,
  "queryKey" | "queryFn"
>;

export default function useDataToolsHistory(options?: DataToolsHistoryQueryOptions) {
  const { handleError } = useApiError();

  const queryKey = useMemo(() => "operation/data-tools-history", []);

  const queryFn = useCallback<DataToolsHistoryQueryFn>(() => {
    return API.integration.get("/operation/management/datatools/v2/report");
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response
      ? response.data.sort((a, b) => {
          const timeA = moment(a.regist_datetime).valueOf();
          const timeB = moment(b.regist_datetime).valueOf();
          return timeB - timeA;
        })
      : [];
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
