import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Status, UserRole } from "src/types";
import API from "src/utils/api";
import { User } from "./useGetUserDetail";

interface Params {
  name?: string;
  roles?: UserRole[];
  companyId?: number;
  syndicationId?: number;
  status?: Status;
  pageNo?: number;
  pageSize?: number;
  orders?: string[];
  invite?: boolean;
}

interface Response {
  code: 200 | 400 | 401 | 404;
  data?: {
    pages: number;
    users: User[];
  };
  text: "ok" | "bad-request" | "unauthorized" | "not-found";
}

// 사용자 리스트 조회
// roles을 전달하지 않을 경우 전체 조회
// page_size이 0 이거나 전달하지 않을 경우 전체 조회
const useGetUsers = ({
  name,
  roles,
  companyId,
  syndicationId,
  status,
  pageNo,
  pageSize,
  orders,
  invite,
}: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    [
      "users/useGetUsers",
      name,
      roles,
      companyId,
      syndicationId,
      status,
      pageNo,
      pageSize,
      orders,
      invite,
    ],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/users`, {
        params: {
          name,
          roles: roles?.join(","),
          company_id: companyId,
          syndication_id: syndicationId,
          status,
          page_no: pageNo,
          page_size: pageSize,
          orders: orders?.join(","),
          invite: invite,
        },
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => (data ? data : { pages: 0, users: [] }), [data]);

  return { data: result, ...rest };
};

export default useGetUsers;
