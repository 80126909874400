import { Button, FormControlLabel, Grid, Link, Radio, RadioGroup } from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useMemo } from "react";
import { TextField, UploadField } from "src/components/commons";
import usePostDomain from "src/hooks/apis/blocklist/usePostDomain";
import { getHelperText, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";

interface EditBlockUrlFormProps {
  id: number;
}

const EditBlockUrlForm = ({ id }: EditBlockUrlFormProps) => {
  const { mutate: addBlockUrl } = usePostDomain();

  const initialValues = useMemo(() => {
    return {
      type: 0,
      url: "",
    };
  }, []);

  const validationSchema = yup.object({
    url: yup.string().when("type", {
      is: (type: number) => type === 0,
      then: yup.string().required("URL을 입력해주세요."),
    }),
  });

  const { getFieldProps, handleSubmit, touched, errors, setFieldValue, values } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.type === 0 && addBlockUrl({ domainListId: id, domain: values.url });
    },
  });

  const onUploadFile = useCallback(
    (data: File) => {
      addBlockUrl({ domainListId: id, file: data });
    },
    [addBlockUrl, id]
  );

  return (
    <form id="edit-url-form" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioGroup
            row
            className="field"
            aria-label="filter"
            {...getFieldProps("type")}
            onChange={(e, v) => {
              e.preventDefault();
              setFieldValue("type", +v);
            }}
          >
            <FormControlLabel value={0} control={<Radio />} label="직접입력" />
            <FormControlLabel value={1} control={<Radio />} label="파일 업로드" />
          </RadioGroup>
        </Grid>
        {values.type === 0 && (
          <Grid item xs={12}>
            <TextField
              className="field"
              label="직접 입력"
              placeholder="차단하려는 URL을 입력하세요."
              suffix={
                <Button color="primary" aria-label="directions" type="submit">
                  추가하기
                </Button>
              }
              {...getFieldProps("url")}
              error={shouldErrorShows("url", touched, errors)}
              helperText={getHelperText("url", touched, errors)}
            />
          </Grid>
        )}
        {values.type === 1 && (
          <Grid item xs={12}>
            <UploadField
              className="field"
              label="파일 업로드"
              size="small"
              accept=".txt, .csv, .xls, .xlsx"
              onChange={onUploadFile}
              helperText={
                <span>
                  차단 URL 등록은 최대 1,000개까지 가능하며,{" "}
                  <Link
                    href="https://screeching-cinema-648.notion.site/ADPOPCorn-SSP-28ed1703fc554a00a2907980c568d36c"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    샘플 양식
                  </Link>
                  을 준수하여 등록하세요.
                </span>
              }
            />
          </Grid>
        )}
      </Grid>
    </form>
  );
};
export default EditBlockUrlForm;
