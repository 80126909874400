import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

export interface Media {
  media_key: string;
  media_name: string;
}

interface Response {
  code?: 200 | 401;
  data?: Media[];
  text?: "ok" | "unauthorized";
}

/*
  포스트백 등록용 매체 검색
*/
const useGetPostbackMediaList = () => {
  const { handleError } = useApiError();
  const { data: response, ...rest } = useQuery<Response, AxiosError>(
    ["coupang/useGetPostbackMediaList"],
    async () => {
      const response: AxiosResponse<Response> = await API.integration.get(
        `/operation/management/coupang/postback/medias`
      );
      return response.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => {
    if (response && response.data)
      return {
        medias: response.data,
      };
    return { medias: [] };
  }, [response]);

  return { data: result, ...rest };
};

export default useGetPostbackMediaList;
