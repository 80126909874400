import { Helmet, HelmetProvider } from "react-helmet-async";
import Error from "src/components/error";

const ErrorPage = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Error</title>
        </Helmet>
      </HelmetProvider>
      <Error />
    </>
  );
};

export default ErrorPage;
