import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MultipleSelect, Select, TextField } from "src/components/commons";
import useGetPostback from "src/hooks/apis/coupang/useGetPostback";
import useGetPostbackMediaList, { Media } from "src/hooks/apis/coupang/useGetPostbackMediaList";
import useGetPostbackPartnerList from "src/hooks/apis/coupang/useGetPostbackPartners";
import usePutPostback from "src/hooks/apis/coupang/usePutPostback";
import { POSTBACK, POSTBACK_ALIAS } from "src/types/coupang";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { addPostbackModalStyle } from "./styles";

interface EditPostbackModalProps {
  onClose: () => void;
  open: { key: number; isOpen: boolean };
}

const EditPostbackModal = ({ onClose, open }: EditPostbackModalProps) => {
  const [media, setMedia] = useState<Media | null>(null);
  const [subIds, setSubIds] = useState<{ subid_no: number; subid: string }[]>([]);
  const { data: postbackData } = useGetPostback({ postbackNo: open.key });
  const { data: partnerData } = useGetPostbackPartnerList();
  const { data: mediaData } = useGetPostbackMediaList();

  // 초기 앱 세팅
  useEffect(() => {
    if (postbackData.postback?.media_key) {
      const app =
        mediaData.medias.find((v) => v.media_key === postbackData.postback?.media_key) || null;
      setMedia(app);
    }
  }, [mediaData.medias, postbackData.postback?.media_key]);

  // 초기 subid 세팅
  useEffect(() => {
    setSubIds(postbackData.postback?.subids || []);
  }, [postbackData.postback?.subids]);

  const initialValues = useMemo(
    () => ({
      partnerNo: postbackData.postback?.partner_no || 0,
      postbackUrl: postbackData.postback?.postback_url || "",
      postbackType: postbackData.postback?.postback_type || POSTBACK.PRODUCT,
      subIds: postbackData.postback?.subids.map((v) => v.subid_no) || [],
      mediaKey: postbackData.postback?.media_key || "",
    }),
    [postbackData.postback]
  );
  const { mutate } = usePutPostback();

  const validationSchema = yup.object({
    partnerNo: yup.string().matches(REG_EXP.number).required("파트너를 선택해주세요."),
    postbackUrl: yup
      .string()
      .matches(REG_EXP.postbackUrl, "올바른 포스트백 주소를 입력해 주세요.")
      .required("포스트백 주소를 입력해주세요."),
    postbackType: yup.string().matches(REG_EXP.number).required("포스트백 Type을 선택해주세요."),
    subIds: yup.array().of(yup.number()).min(1, "Sub ID를 선택해주세요."),
    mediaKey: yup.string().when("postbackType", {
      is: (postbackType: number) => postbackType === POSTBACK.CPS_REWARD,
      then: yup.string().required("APP을 선택해주세요."),
    }),
  });

  const { getFieldProps, setFieldValue, handleSubmit, touched, errors, values } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate({
        postbackNo: open.key,
        partnerNo: +values.partnerNo,
        postbackType: +values.postbackType,
        postbackUrl: values.postbackUrl,
        subIds: values.subIds,
        mediaKey: values.mediaKey,
      });
      onClose();
    },
  });

  const onChangeSubIdList = useCallback(
    (values: { subid_no: number; subid: string }[]) => {
      setSubIds(values);
      setFieldValue(
        "subIds",
        values.map((v) => v.subid_no)
      );
    },
    [setFieldValue]
  );

  const subIdOptions = useMemo(
    () =>
      partnerData.partners.find((partner) => partner.partner_no === values.partnerNo)?.subids || [],
    [partnerData.partners, values.partnerNo]
  );

  return (
    <Dialog
      css={addPostbackModalStyle}
      fullWidth
      maxWidth="md"
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-coupang-postback"
      aria-describedby="edit coupang postback"
    >
      <DialogTitle id="dialog-title">포스트백 수정</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="edit-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Select
                  label="파트너명"
                  placeholder="파트너를 선택해주세요."
                  required
                  disabled
                  value={`${values.partnerNo}`}
                >
                  {partnerData.partners.map((partner) => (
                    <MenuItem key={partner.partner_no} value={partner.partner_no}>
                      {partner.partner_name}
                    </MenuItem>
                  ))}
                </Select>
                {shouldErrorShows("partnerNo", touched, errors) && (
                  <FormHelperText>{getHelperText("partnerNo", touched, errors)}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="포스트백 주소"
                placeholder="포스트백 주소를 입력해주세요."
                required
                {...getFieldProps("postbackUrl")}
                error={shouldErrorShows("postbackUrl", touched, errors)}
                helperText={getHelperText("postbackUrl", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom className="label">
                포스트백 Type
              </Typography>
              <RadioGroup
                row
                className="field postback-type"
                aria-label="filter"
                {...getFieldProps("postbackType")}
              >
                <FormControlLabel
                  value={POSTBACK.PRODUCT}
                  control={<Radio />}
                  label={POSTBACK_ALIAS[POSTBACK.PRODUCT]}
                  disabled
                />
                <FormControlLabel
                  value={POSTBACK.ORDERER}
                  control={<Radio />}
                  label={POSTBACK_ALIAS[POSTBACK.ORDERER]}
                  disabled
                />
                <FormControlLabel
                  value={POSTBACK.CPS_REWARD}
                  control={<Radio />}
                  label={POSTBACK_ALIAS[POSTBACK.CPS_REWARD]}
                  disabled
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <MultipleSelect
                  className="field"
                  options={subIdOptions}
                  label="Sub ID"
                  placeholder="Sub ID 선택"
                  getOptionLabel={(option) => option.subid}
                  getOptionValue={(option) => option.subid_no}
                  onChange={onChangeSubIdList}
                  value={subIds}
                  inputProps={{
                    error: shouldErrorShows("subIds", touched, errors),
                    helperText: getHelperText("subIds", touched, errors),
                  }}
                />
                {shouldErrorShows("subIds", touched, errors) && (
                  <FormHelperText>{getHelperText("subIds", touched, errors)}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            {values.postbackType === POSTBACK.CPS_REWARD && (
              <Grid item xs={6}>
                <Autocomplete
                  className="field"
                  size="small"
                  options={mediaData.medias}
                  getOptionLabel={(media) => media.media_name}
                  renderInput={({ InputLabelProps, ...params }) => (
                    <TextField
                      {...params}
                      label="APP"
                      placeholder="사용하실 APP을 선택해주세요."
                      required
                    />
                  )}
                  value={media}
                  disabled
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPostbackModal;
