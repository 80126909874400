import { Container, Paper, Typography } from "@mui/material";
import { ValueFormatterParams } from "ag-grid-community";
import moment from "moment";
import { useParams } from "react-router-dom";
import { BasicTable } from "src/components/commons";
import useGetRewardCSDetail from "src/hooks/apis/rewardCS/useGetRewardCSDetail";
import useGetRewardHistory from "src/hooks/apis/rewardCS/useGetRewardHistory";
import { AppPlatform, APP_PLATFORM_ALIAS, Campaign, CAMPAIGN_ALIAS } from "src/types";
import { clientInfoStyle } from "./styles";

const RewardHistory = () => {
  const { id } = useParams();
  const { data: clientData } = useGetRewardCSDetail({ rewardCSNo: id });
  const { data: historyData } = useGetRewardHistory({
    since: moment().subtract(1, "week"),
    until: moment(),
    mediaKey: clientData.item.media_key,
    adId: clientData.item.adid,
  });

  return (
    <Container component="section" className="ssp-section" css={clientInfoStyle} maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        리워드 참여 내역
      </Typography>
      <Paper className="content" elevation={2}>
        <BasicTable rowData={historyData} columnDefs={columnDefs} />
      </Paper>
    </Container>
  );
};

const columnDefs = [
  {
    headerName: "날짜",
    minWidth: 180,
    field: "event_datetime",
    valueFormatter: (params: ValueFormatterParams) => {
      const { event_datetime } = params.data;
      return `${moment(event_datetime * 1000).format("YYYY.MM.DD HH:mm:ss")}`;
    },
  },
  {
    headerName: "성공여부",
    minWidth: 120,
    field: "is_complete",
    valueFormatter: (params: ValueFormatterParams) => {
      const { is_complete } = params.data;
      return is_complete ? "O" : "X";
    },
  },
  {
    headerName: "미디어키",
    field: "media_key",
    minWidth: 120,
  },
  {
    headerName: "플레이스먼트 ID",
    field: "placement_id",
    minWidth: 170,
  },
  {
    headerName: "캠페인 타입",
    field: "campaign_type",
    minWidth: 130,
    valueFormatter: (params: ValueFormatterParams) => {
      const campaign = params.value as Campaign;
      return CAMPAIGN_ALIAS[campaign];
    },
  },
  {
    headerName: "리워드 단위",
    field: "reward_quantity",
    minWidth: 130,
  },
  {
    headerName: "광고료",
    field: "advertise_cost",
    minWidth: 120,
    valueFormatter: (params: ValueFormatterParams) => {
      const { advertise_cost, advertise_cost_currency } = params.data;
      return `${advertise_cost} ${advertise_cost_currency}`;
    },
  },
  {
    headerName: "매체료",
    field: "mediaCost",
    minWidth: 120,
    valueFormatter: (params: ValueFormatterParams) => {
      const { media_cost, media_cost_currency } = params.data;
      return `${media_cost} ${media_cost_currency}`;
    },
  },
  {
    headerName: "ADID",
    field: "advertising_id",
    minWidth: 320,
  },
  {
    headerName: "USN",
    field: "usn",
    minWidth: 220,
  },
  {
    headerName: "DSP ID",
    field: "thirdparty_id",
    minWidth: 100,
  },
  {
    headerName: "SDK 미디에이션 NO",
    field: "sdk_mediation_thirdparty_no",
    minWidth: 200,
  },
  {
    headerName: "국가",
    field: "country",
    minWidth: 100,
  },
  {
    headerName: "SDK 버젼",
    field: "sdk_version",
    minWidth: 120,
  },
  {
    headerName: "OS 버젼",
    field: "os_version",
    minWidth: 120,
  },
  {
    headerName: "플랫폼",
    field: "platform",
    minWidth: 100,
    valueFormatter: (params: ValueFormatterParams) => {
      const platform = params.value as AppPlatform;
      return APP_PLATFORM_ALIAS[platform];
    },
  },
];

export default RewardHistory;
