import { AxiosError, AxiosResponse } from "axios";
import { Moment } from "moment";
import { useMutation, useQueryClient } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useUser from "src/hooks/useUser";
import { Procedure, ProcedureTimeRange } from "src/types/datatools";
import API from "src/utils/api";
import useToast from "src/hooks/useToast";

import useDataToolsHistory from "./use-data-tools-history";

interface Params {
  procedure?: Procedure;
  timely?: ProcedureTimeRange;
  since: Moment;
  until: Moment;
}

interface Response {
  code: number;
  text: string;
  detail?: string;
}

/*
  데이터 툴즈 리포트 생성 요청
*/
const usePostDataTools = () => {
  const { handleError } = useApiError();
  const { userId } = useUser();
  const queryClient = useQueryClient();
  const { queryKey } = useDataToolsHistory({ enabled: false });
  const toast = useToast();

  const mutation = useMutation<Response, AxiosError, Params>(
    "datatools/usePostDataTools",
    async ({ procedure, since, until, timely }) => {
      const response: AxiosResponse<Response> = await API.integration.post(
        `/operation/management/datatools/v2/report`,
        {
          procedure,
          start_date: since.format("YYYYMMDD"),
          end_date: until.format("YYYYMMDD"),
          timely,
          customer_no: userId,
        }
      );
      return response.data;
    },
    {
      onSuccess: async () => {
        toast.success("리포트 생성 요청 성공");
        await queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        if (error.response?.data) {
          const msg = error.response.data?.detail || error.response.data.text;
          toast.error(msg);
        } else {
          handleError(error);
        }
      },
    }
  );

  return mutation;
};

export default usePostDataTools;
