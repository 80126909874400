import { css } from "@emotion/react";

export const addMediaModalStyle = css`
  .tabs {
    padding-bottom: 0;
  }
`;

export const addSearchFormStyle = css`
  padding-top: 2rem;
  border-top: thin solid rgba(0, 0, 0, 0.12);
  .search-app-input {
    margin-bottom: 1rem;
  }
`;

export const addDirectFormStyle = css`
  padding-top: 1rem;
  border-top: thin solid rgba(0, 0, 0, 0.12);

  .field {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const editMediaModalStyle = css`
  .dialog-content {
    #edit-form {
      padding-top: 20px;
      .switch {
        margin-left: 0;
      }
      .remote-tools {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
        button {
          min-width: 152px;
          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }
`;

export const testDeviceModalStyle = css`
  .dialog-content {
    #form {
      padding-top: 20px;
    }
  }
`;
