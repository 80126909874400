import { Box, Button, Grid } from "@mui/material";
import {
  RowClickedEvent,
  SortChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import moment from "moment";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { BasicTable, SearchField } from "src/components/commons";
import useGetOfferwallList, { Record } from "src/hooks/apis/partners/useGetOfferwallList";
import useOpenModal from "src/hooks/useOpenModal";
import { PARTNER_ALIAS } from "src/types";
import AddExternalReportModal from "./AddExternalReportModal";
import EditExternalReportModal from "./EditExternalReportModal";
import { partnerBoardStyle } from "./styles";

const ExternalReportBoard = () => {
  const [search, setSearch] = useState({ temp: "", value: "" });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [orders, setOrders] = useState<string[]>([]);
  const [select, setSelect] = useState<Record>();
  const [openAddModal, onShowAddModal, onCloseAddModal] = useOpenModal(null);
  const [openEditModal, onShowEditModal, onCloseEditModal] = useOpenModal("");

  const { data } = useGetOfferwallList({
    search: search.value,
    pageNo,
    pageSize,
    orders,
  });

  const onChangeSearchWord = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearch({ ...search, temp: e.target.value });
    },
    [search]
  );

  const onSearchReport = useCallback(() => {
    setSearch({ ...search, value: search.temp });
    setPageNo(1);
  }, [search]);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data.pages || 0, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [10, 20, 30],
      },
    }),
    [data?.pages, onChangePage, onChangePageSize, pageNo, pageSize]
  );

  const onSortChanged = useCallback((e: SortChangedEvent) => {
    const sortInfo = e.columnApi
      .getColumnState()
      .filter((column) => !!column.sort)
      .map(({ colId, sort }) => {
        if (sort === "desc") return `-${colId}`;
        return `+${colId}`;
      });
    setOrders(sortInfo);
  }, []);

  const onRowClicked = useCallback(
    (e: RowClickedEvent) => {
      e.event?.preventDefault();
      onShowEditModal(e);
      setSelect(e.data);
    },
    [onShowEditModal]
  );

  return (
    <Box css={partnerBoardStyle}>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={3}>
          <SearchField
            label="검색어"
            placeholder="업체명 / 매체명"
            value={search.temp}
            onChange={onChangeSearchWord}
            onClickSearchButton={onSearchReport}
          />
        </Grid>
        <Grid item xs={9} className="register">
          <Button variant="outlined" onClick={onShowAddModal}>
            신규 외부 리포트 연동
          </Button>
        </Grid>
      </Grid>
      <BasicTable
        rowData={data.records}
        columnDefs={columnDefs}
        onSortChanged={onSortChanged}
        onRowClicked={onRowClicked}
        {...paginationInfo}
      />
      {openAddModal.isOpen && (
        <AddExternalReportModal isOpen={openAddModal.isOpen} onClose={onCloseAddModal} />
      )}
      {openEditModal.isOpen && select && (
        <EditExternalReportModal
          isOpen={openEditModal.isOpen}
          onClose={onCloseEditModal}
          select={select}
        />
      )}
    </Box>
  );
};

const columnDefs = [
  {
    headerName: "외부 리포트명",
    field: "company_name",
    sortable: true,
    valueGetter: (params: ValueGetterParams) => PARTNER_ALIAS[params.data.partner_id],
  },
  {
    headerName: "계약업체명",
    field: "company_name",
    sortable: true,
  },
  {
    headerName: "연동된 APP 개수",
    field: "partner_apps",
    sortable: true,
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    valueGetter: (params: ValueGetterParams) => params.data.partner_apps.length || 0,
  },
  {
    headerName: "등록일자",
    field: "created_at",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const createdAt = param.data.created_at * 1000;
      return moment(createdAt).format("YYYY-MM-DD");
    },
  },
  {
    headerName: "수정일자",
    field: "updated_at",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const updatedAt = param.data.updated_at * 1000;
      return moment(updatedAt).format("YYYY-MM-DD");
    },
  },
];

export default ExternalReportBoard;
