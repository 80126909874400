import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { MEDIA, Media } from "src/types";
import API from "src/utils/api";

export interface HeaderBidding {
  id: number;
  media_type: Media;
  name: string;
  placement_count: number;
  system_fee: number;
}

interface Params {
  name?: string;
  pageNo?: number;
  pageSize?: number;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    pages: number;
    thirdparties: HeaderBidding[];
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 파트너 관리 > 타입별 서드파티 리스트 조회 (헤더비딩)
const useGetHeaderBiddingList = ({ name, pageNo, pageSize }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["thirdparties/useGetHeaderBiddingList", name, pageNo, pageSize],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(
        `/thirdparties/headerbiddings`,
        {
          params: { type: MEDIA.WEB, name, page_no: pageNo, page_size: pageSize },
        }
      );
      return response.data.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => (data ? data : { pages: 0, thirdparties: [] }), [data]);

  return { data: result, ...rest };
};

export default useGetHeaderBiddingList;
