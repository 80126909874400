import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useMemo } from "react";
import { Select } from "src/components/commons";
import useGetAdFormatInfo, { AdFormat } from "src/hooks/apis/info/useGetAdFormatInfo";
import { CAMPAIGN } from "src/types";
import * as yup from "yup";
import { addCampaignModalStyle } from "./styles";

interface AddCampaignModalProps {
  onClose: () => void;
  open: boolean;
  onSubmit: (value: number) => void;
}

const AddCampaignModal = ({ onClose, open, onSubmit }: AddCampaignModalProps) => {
  const { data: adFormatData } = useGetAdFormatInfo({ types: [CAMPAIGN.WebBanner] });

  const initialValues = useMemo(
    () => ({
      sizeType: adFormatData.formats.find((format) => format.type === CAMPAIGN.WebBanner)?.id || 0,
    }),
    [adFormatData.formats]
  );

  const validationSchema = yup.object({
    sizeType: yup.number().required("사이즈를 선택해주세요."),
  });

  const { getFieldProps, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit(values.sizeType);
      onClose();
    },
  });

  const onChangeCampaignSize = useCallback(
    (e: SelectChangeEvent<AdFormat["id"]>) => {
      setFieldValue("sizeType", +e.target.value);
    },
    [setFieldValue]
  );

  return (
    <Dialog
      css={addCampaignModalStyle}
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="representative-campaign"
      aria-describedby="add campaign"
    >
      <DialogTitle id="dialog-title">광고 형식 설정</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="add-campaign-form" onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Select
                label="사이즈"
                placeholder="사이즈를 선택해주세요."
                {...getFieldProps("sizeType")}
                onChange={onChangeCampaignSize}
              >
                {adFormatData.formats.map((format) => (
                  <MenuItem key={format.id} value={format.id}>
                    {`[${format.id}] ${format.label}`}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="add-campaign-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCampaignModal;
