import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import { MouseEvent, useCallback, useMemo } from "react";
import { TextField } from "src/components/commons";
import useDeleteMatchInfo from "src/hooks/apis/thirdparties/useDeleteMatchInfo";
import { MatchKeyInfo } from "src/hooks/apis/thirdparties/usePostHeaderBiddingMatch";
import usePutHeaderBiddingMatch from "src/hooks/apis/thirdparties/usePutHeaderBiddingMatch";
import { getHelperText, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { headerBiddingMatchingInfoModalStyle } from "./styles";
import { MatchingPlacement } from "./WebPlacementModal";

interface MatchingInfoModalProps extends MatchingPlacement {
  onClose: () => void;
  open: { key: string; isOpen: boolean };
}

const MatchingInfoModal = ({
  id,
  onClose,
  open,
  match_key,
  match_key_info,
  thirdpartyId,
}: MatchingInfoModalProps) => {
  const { mutate: updateMatchKeyInfo } = usePutHeaderBiddingMatch();
  const { mutate: deleteMatchKeyInfo } = useDeleteMatchInfo();

  const makeDefaultValue = useCallback(
    (type: "string" | "float" | "integer" | "number" | "boolean") => {
      if (type === "string") return "";
      if (type === "boolean") return false;
      return "";
    },
    []
  );
  const formInfo = useMemo(() => {
    if (match_key_info && match_key_info.length > 0) {
      return match_key_info.reduce((acc: MatchKeyInfo, cur) => {
        return { ...acc, ...cur };
      }, {});
    }
    return;
  }, [match_key_info]);

  const initialValues = useMemo(() => {
    if (formInfo)
      return Object.entries(formInfo).reduce((acc: Record<string, unknown>, [key, condition]) => {
        acc[key] = (match_key && match_key[key]) || makeDefaultValue(condition.type);
        return acc;
      }, {});
    return {};
  }, [formInfo, makeDefaultValue, match_key]);

  const validationData = useMemo(() => {
    if (formInfo)
      return Object.entries(formInfo).reduce(
        (acc: Record<string, yup.BaseSchema>, [key, condition]) => {
          const { type, required } = condition;
          if (type === "float" || type === "integer" || type === "number") {
            acc = {
              ...acc,
              [key]: yup.string().test(key, "숫자를 입력해주세요.", (value = "") => !isNaN(+value)),
            };
          } else {
            acc = { ...acc, [key]: yup[type]() };
          }
          if (required) {
            acc = { ...acc, [key]: acc[key].required(`${key}을(를) 설정해주세요.`) };
          }
          // if (related && related.length > 0) {
          //   related.forEach((relation) => {
          //     acc = {
          //       ...acc,
          //       [key]: acc[key].when(relation, {
          //         is: (v: unknown) => !!v,
          //         then: acc[key].required(`${key}은(는) ${relation}과 함께 설정되어야 합니다.`),
          //         otherwise: acc[key],
          //       }),
          //     };
          //   });
          // }
          return acc;
        },
        {}
      );
    return {};
  }, [formInfo]);

  const validationSchema = yup.object(validationData);

  const { getFieldProps, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateMatchKeyInfo({
        placementId: id,
        thirdpartyId,
        matchKey: values,
      });
      onClose();
    },
  });

  const onDelete = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      deleteMatchKeyInfo({
        thirdpartyId,
        placementId: id,
      });
      onClose();
    },
    [deleteMatchKeyInfo, thirdpartyId, onClose, id]
  );

  return (
    <Dialog
      css={headerBiddingMatchingInfoModalStyle}
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-dsp-matching-info"
      aria-describedby="set dsp matching info"
    >
      <DialogTitle id="dialog-title">매칭정보 변경</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {formInfo &&
              Object.keys(formInfo).map((key) => {
                if (formInfo[key].type === "boolean")
                  return (
                    <Grid key={key} item xs={12}>
                      <FormControlLabel
                        className="switch"
                        control={<Switch color="primary" />}
                        label={key}
                        labelPlacement="start"
                        {...getFieldProps(key)}
                      />
                    </Grid>
                  );
                return (
                  <Grid key={key} item xs={12}>
                    <TextField
                      className="field"
                      label={key}
                      placeholder={`${key}을(를) 입력하세요.`}
                      required
                      {...getFieldProps(key)}
                      error={shouldErrorShows(key, touched, errors)}
                      helperText={getHelperText(key, touched, errors)}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        {match_key ? (
          <Button onClick={onDelete} color="error">
            삭제
          </Button>
        ) : (
          <Button onClick={onClose} color="inherit">
            취소
          </Button>
        )}
        <Button form="form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MatchingInfoModal;
