import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { TextField } from "src/components/commons";
import useGetCampaignPartnerList, {
  Partner,
} from "src/hooks/apis/coupang/useGetCampaignPartnerList";
import usePostCampaign from "src/hooks/apis/coupang/usePostCampaign";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { campaignModalStyle, partnerOptionStyle } from "./styles";

interface AddCampaignModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const AddCampaignModal = ({ onClose, isOpen }: AddCampaignModalProps) => {
  const [partner, setPartner] = useState<Partner | null>(null);

  const { data } = useGetCampaignPartnerList();
  const { error, isSuccess, mutate } = usePostCampaign();

  useEffect(() => {
    if (isSuccess) onClose();
  }, [isSuccess, onClose]);

  const initialValues = useMemo(
    () => ({
      postbackNo: 0,
      campaignName: "",
      triggerRevenue: "",
      landingUrl: "",
    }),
    []
  );

  const validationSchema = yup.object({
    postbackNo: yup.string().required("파트너를 선택해주세요."),
    campaignName: yup.string().required("캠페인 이름을 입력해주세요."),
    triggerRevenue: yup
      .string()
      .test("triggerRevenue", "숫자를 입력해주세요.", (value = "") => !isNaN(+value))
      .required("이벤트 최소금액을 입력해주세요."),
    landingUrl: yup
      .string()
      .matches(REG_EXP.domain, "올바른 URL을 입력해주세요.")
      .required("랜딩 URL을 입력해주세요."),
  });

  const { getFieldProps, setFieldValue, handleSubmit, touched, errors, setFieldError } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate({
        postbackNo: +values.postbackNo,
        campaignName: values.campaignName,
        triggerRevenue: +(values.triggerRevenue || 0),
        landingUrl: values.landingUrl,
      });
    },
  });

  useEffect(() => {
    if (error?.response?.status === 409) setFieldError("campaignName", "중복된 캠페인명입니다.");
  }, [error?.response?.status, setFieldError]);

  const onSelectPartner = useCallback(
    (_: SyntheticEvent<unknown>, v: Partner | null) => {
      setFieldValue("postbackNo", v?.postback_no);
      setPartner(v);
    },
    [setFieldValue]
  );

  return (
    <Dialog
      css={campaignModalStyle}
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-coupang-campaign"
      aria-describedby="add coupang campaign"
    >
      <DialogTitle id="dialog-title">신규 캠페인 등록</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="add-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                className="field"
                size="small"
                options={data.partners}
                getOptionLabel={(partner) => `${partner.partner_name}(${partner.subids})`}
                renderOption={(props, partner) => {
                  return (
                    <li {...props} key={partner.postback_no}>
                      <div
                        css={partnerOptionStyle}
                      >{`${partner.partner_name}(${partner.subids})`}</div>
                    </li>
                  );
                }}
                renderInput={({ InputLabelProps, ...params }) => (
                  <TextField
                    {...params}
                    label="파트너 명"
                    placeholder="파트너를 선택해주세요."
                    required
                  />
                )}
                isOptionEqualToValue={(partner, value) => partner.postback_no === value.postback_no}
                value={partner}
                onChange={onSelectPartner}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="캠페인 이름"
                placeholder="캠페인 이름 (중복불가)"
                required
                {...getFieldProps("campaignName")}
                error={shouldErrorShows("campaignName", touched, errors)}
                helperText={getHelperText("campaignName", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="이벤트 최소 금액"
                placeholder="해당 캠페인 이벤트 최소 금액을 입력해주세요."
                suffix={
                  <Typography variant="body2" sx={{ whiteSpace: "pre" }}>
                    원 이상 구매시
                  </Typography>
                }
                required
                {...getFieldProps("triggerRevenue")}
                error={shouldErrorShows("triggerRevenue", touched, errors)}
                helperText={getHelperText("triggerRevenue", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                minRows={5}
                className="field"
                label="랜딩 URL"
                placeholder="캠페인 클릭시 이동할 랜딩 URL을 입력해주세요. https://link.coupang.com/re/AFAPGB?subid= {subid}&subparam={bid}&publisherid= {publisher_id}&adid={adid}&idfa= {idfa}"
                required
                {...getFieldProps("landingUrl")}
                error={shouldErrorShows("landingUrl", touched, errors)}
                helperText={getHelperText("landingUrl", touched, errors)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="add-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCampaignModal;
