import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { HistoryDetailType } from "src/types/history";
import API from "src/utils/api";

interface Params {
  historyId?: number;
}

interface Response {
  code: 200 | 400 | 401 | 404;
  curr_contents?: HistoryDetailType;
  curr_history_id?: number;
  prev_contents?: HistoryDetailType;
  prev_history_id?: number;
  text: "ok" | "bad-request" | "unauthorized" | "not-found";
}

// 플레이스먼트별 히스토리 상세 내역 조회
const useGetHistoryDetail = ({ historyId }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response, AxiosError>(
    ["histories/useGetHistoryDetail", historyId],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(
        `/histories/details/${historyId}`
      );
      return response.data;
    },
    { enabled: !!historyId, onError: handleError }
  );

  const result = useMemo(() => {
    if (data)
      return {
        curr_contents: data.curr_contents,
        curr_history_id: data.curr_history_id,
        prev_contents: data.prev_contents,
        prev_history_id: data.prev_history_id,
      };
    return {
      curr_contents: undefined,
      curr_history_id: undefined,
      prev_contents: undefined,
      prev_history_id: undefined,
    };
  }, [data]);

  return { data: result, ...rest };
};

export default useGetHistoryDetail;
