import { AxiosError, AxiosResponse } from "axios";
import moment, { Moment } from "moment";
import { useCallback, useMemo, useState } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { REPORT, Report as ReportType } from "src/types";
import API from "src/utils/api";

export interface ReportData {
  partnerName: string;
  pubId?: string;
  reportDate: string;
  click: number;
  purchase: number;
  purchaseRevenue: number;
  cancel: number;
  cancelRevenue: number;
  totalRevenue: number;
  clientCommission: number;
  subParam: string;
}

export interface Report {
  partner_name: string;
  report_date: string;
  pub_id: string;
  publisher_id?: string;
  click: number;
  conversion: number;
  conversion_revenue: number;
  cancel: number;
  cancel_revenue: number;
  total_revenue: number;
  client_commission: number;
  sub_param: string | null;
}

interface Params {
  since: Moment;
  until: Moment;
  reportType: ReportType;
  companyKey: string;
}

interface Response {
  code: 200 | 400 | 422 | 500;
  text: "ok" | "bad-request" | "field required" | "internal-server-error";
  report: Report[];
}

// 쿠팡 리포트 데이터 조회
const usePostCoupangReport = () => {
  const [type, setType] = useState<ReportType>();
  const { handleError } = useApiError();

  const { data, ...rest } = useMutation<Response, AxiosError, Params>(
    async ({ since, until, reportType, companyKey }) => {
      setType(reportType);
      const response: AxiosResponse<Response> = await API.integration.post(
        `/operation/management/coupang/report`,
        {
          is_op: false,
          start_date: since.format("YYYYMMDD"),
          end_date: until.format("YYYYMMDD"),
          report_type: reportType,
          company_key: companyKey,
        }
      );
      return response.data;
    },
    {
      onError: handleError,
    }
  );

  const getDate = useCallback(
    (date: string) => {
      if (type === REPORT.DAILY) {
        return moment(date).format("YYYY-MM-DD");
      }
      if (type === REPORT.WEEKLY) {
        return date.replace(/^(\d{4})(\d{2})$/, `$1-w$2`);
      }
      if (type === REPORT.MONTHLY) {
        return date.replace(/^(\d{4})(\d{2})$/, `$1-$2`);
      }
      return "";
    },
    [type]
  );

  const chartData = useMemo(() => {
    const refusedData = data?.report.reduce((acc, cur) => {
      if (acc[cur.report_date]) {
        acc[cur.report_date] = {
          partnerName: cur.partner_name,
          reportDate: getDate(cur.report_date),
          click: acc[cur.report_date].click + cur.click,
          purchase: acc[cur.report_date].purchase + cur.conversion,
          purchaseRevenue: acc[cur.report_date].purchaseRevenue + cur.conversion_revenue,
          cancel: acc[cur.report_date].cancel + cur.cancel,
          cancelRevenue: acc[cur.report_date].cancelRevenue + cur.cancel_revenue,
          totalRevenue: acc[cur.report_date].totalRevenue + cur.total_revenue,
          clientCommission: acc[cur.report_date].clientCommission + cur.client_commission,
          subParam: cur.sub_param || "-",
        };
        return acc;
      }
      acc[cur.report_date] = {
        partnerName: cur.partner_name,
        reportDate: getDate(cur.report_date),
        click: cur.click,
        purchase: cur.conversion,
        purchaseRevenue: cur.conversion_revenue,
        cancel: cur.cancel,
        cancelRevenue: cur.cancel_revenue,
        totalRevenue: cur.total_revenue,
        clientCommission: cur.client_commission,
        subParam: cur.sub_param || "-",
      };
      return acc;
    }, {} as Record<string, ReportData>);

    if (refusedData)
      return Object.values(refusedData).sort((a, b) => +a.reportDate - +b.reportDate);
    return [];
  }, [data?.report, getDate]);

  const result = useMemo(() => {
    if (data && data.report && data.report.length > 0) {
      return {
        chartData: chartData,
        reportData: data.report.map((report) => ({
          partnerName: report.partner_name,
          pubId: report.pub_id,
          reportDate: getDate(report.report_date),
          click: report.click,
          purchase: report.conversion,
          purchaseRevenue: report.conversion_revenue,
          cancel: report.cancel,
          cancelRevenue: report.cancel_revenue,
          totalRevenue: report.total_revenue,
          clientCommission: report.client_commission,
          subParam: report.sub_param || "-",
        })),
      };
    }
    return { chartData: [], reportData: [] };
  }, [data, chartData, getDate]);

  return { data: result, ...rest };
};

export default usePostCoupangReport;
