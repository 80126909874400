import { Global } from "@emotion/react";
import { Box } from "@mui/system";
import { useIsFetching, useIsMutating } from "react-query";
import { useRecoilValue } from "recoil";
import LoadingImage from "src/assets/images/ico-loading.gif";
import { loading } from "src/atoms/loading";
import { globalStyle, loadingStyle } from "./styles";

const Loading = () => {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const isLoading = useRecoilValue(loading);

  return isFetching || isMutating || isLoading ? (
    <>
      <Global styles={globalStyle} />
      <div css={loadingStyle}>
        <Box className="loading-box">
          <img src={LoadingImage} alt="loading" />
          <div className="loading">Loading</div>
        </Box>
      </div>
    </>
  ) : null;
};

export default Loading;
