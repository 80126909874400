export const getEcpm = (impression: number, revenue: number) => {
  if (impression === 0) return 0;
  return (revenue / impression) * 1000;
};

export const getFillRate = (request: number, response: number) => {
  if (request === 0) return 0;
  return (response * 100) / request;
};

export const getCtr = (impression: number, click: number) => {
  if (impression === 0) return 0;
  return (click * 100) / impression;
};

export const getRpr = (mediaCost: number, request: number) => {
  if (request === 0) return 0;
  return (mediaCost * 1000) / request;
};

export const getIR = (impression: number, response: number) => {
  if (response === 0) return 0;
  return (impression * 100) / response;
};

export const getConversionRate = (conversion: number, click: number) => {
  if (click === 0) return 0;
  return conversion / click;
};
