import { css } from "@emotion/react";
import { grey, red } from "@mui/material/colors";

export const partnerBoardStyle = css`
  margin-top: 2rem;
`;

export const addExternalReportModalStyle = css`
  .dialog-content {
    #add-form,
    #edit-form {
      padding-top: 20px;

      .guide {
        padding-top: 2rem;
      }

      .description {
        color: ${grey[500]};
      }

      .example {
        padding-top: 2rem;
        text-align: right;
      }

      .ico-delete {
        fill: ${red[500]};
      }
    }
  }
`;
