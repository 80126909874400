import { Button, FormControlLabel, Grid, Switch } from "@mui/material";
import { ColDef, ColGroupDef, GetGroupRowAggParams, ValueFormatterParams } from "ag-grid-community";
import { MouseEvent, SyntheticEvent, useCallback, useMemo } from "react";
import DataFormat from "src/assets/formats";
import { BasicTable } from "src/components/commons";
import { ReportTableData } from "src/hooks/apis/report/usePostReport";
import useUser from "src/hooks/useUser";
import { getCtr, getEcpm } from "src/utils/calculator";

interface ReportBoardProps {
  country: boolean;
  onToggleCountry: (_event: SyntheticEvent<Element, Event>, checked: boolean) => void;
  onExport: (e: MouseEvent) => void;
  data: ReportTableData[];
}

const ReportBoard = ({ data, country, onToggleCountry, onExport }: ReportBoardProps) => {
  const { isAdmin } = useUser();

  // 테이블 상단 총합
  const totalRowData = useMemo(() => {
    const total = {
      mediationName: "총합",
      impression: data.reduce((acc, cur) => acc + +cur.impression, 0),
      click: data.reduce((acc, cur) => acc + +cur.click, 0),
      ctr: 0,
      advertiseCost: data.reduce((acc, cur) => acc + +cur.advertiseCost, 0),
      mediaCost: data.reduce((acc, cur) => acc + +cur.mediaCost, 0),
      ecpm: 0,
      advertiseCostEcpm: 0,
    };

    return [
      {
        ...total,
        ctr: getCtr(total.impression, total.click),
        ecpm: getEcpm(total.impression, total.mediaCost),
        advertiseCostEcpm: getEcpm(total.impression, total.advertiseCost),
      },
    ];
  }, [data]);

  // 테이블 그룹 합계
  const groupRowData = useCallback(
    (params: GetGroupRowAggParams) =>
      params.nodes.reduce(
        (acc, cur) => {
          const data = cur.group ? cur.aggData : cur.data;

          acc.impression += +data.impression;
          acc.click += +data.click;
          acc.advertiseCost += +data.advertiseCost;
          acc.mediaCost += +data.mediaCost;
          acc.ctr = getCtr(acc.impression, acc.click);
          acc.ecpm = getEcpm(acc.impression, acc.mediaCost);
          acc.advertiseCostEcpm = getEcpm(acc.impression, acc.advertiseCost);
          return acc;
        },
        {
          impression: 0,
          click: 0,
          advertiseCost: 0,
          mediaCost: 0,
          ctr: 0,
          ecpm: 0,
          advertiseCostEcpm: 0,
        }
      ),
    []
  );

  return (
    <>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={12} className="download">
          <FormControlLabel
            className="switch"
            control={<Switch color="primary" />}
            label="국가별 리포트"
            labelPlacement="end"
            checked={country}
            onChange={onToggleCountry}
          />
          <Button variant="outlined" onClick={onExport}>
            엑셀 다운로드
          </Button>
        </Grid>
      </Grid>
      <BasicTable
        rowData={data}
        columnDefs={getColumnDefs(isAdmin, country)}
        animateRows={true}
        getGroupRowAgg={groupRowData}
        autoGroupColumnDef={{
          headerName: "",
          field: "mediationName",
          pinned: "left",
          minWidth: 300,
          cellRendererParams: {
            suppressCount: true,
          },
          cellStyle: (params) => {
            if (params.node.rowPinned) {
              return {
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              };
            }
            return;
          },
        }}
        onGridColumnsChanged={(e) => {
          e.api.sizeColumnsToFit();
        }}
        pinnedTopRowData={totalRowData}
      />
    </>
  );
};

const getColumnDefs = (isAdmin: boolean, country: boolean): (ColDef | ColGroupDef)[] => {
  const columnDefs = [
    {
      field: "mediaName",
      rowGroup: true,
      hide: true,
    },
    {
      field: "placementName",
      rowGroup: true,
      hide: true,
    },
    {
      field: "date",
      rowGroup: true,
      hide: true,
    },
    {
      field: "mediationName",
      hide: true,
    },
    {
      headerName: "국가",
      field: "country",
      sortable: true,
      pinned: "left",
      minWidth: 100,
    },
    {
      headerName: DataFormat.request.label,
      field: "request",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.request.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.response.label,
      field: "response",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.response.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.fillRate.label,
      field: "fillRate",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.fillRate.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.impression.label,
      field: "impression",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.impression.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.impressionRate.label,
      field: "impressionRate",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.impressionRate.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.click.label,
      field: "click",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.click.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.ctr.label,
      field: "ctr",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.ctr.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.advertiseCost.label,
      field: "advertiseCost",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.advertiseCost.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: "순매체비",
      field: "mediaCost",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.mediaCost.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.ecpm.label,
      field: "ecpm",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.ecpm.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: "수입 eCPM",
      field: "advertiseCostEcpm",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.advertiseCostEcpm.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.rpr.label,
      field: "rpr",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.rpr.formatter(params.value);
        }
        return "-";
      },
    },
  ];
  if (!country) columnDefs.splice(4, 1);
  if (!isAdmin)
    return columnDefs.filter(
      (col) => col.field !== "advertiseCost" && col.field !== "advertiseCostEcpm"
    );
  return columnDefs;
};

export default ReportBoard;
