import Bidfloor from "./Bidfloor";
import Dashboard from "./Dashboard";
import DashboardReport from "./DashboardReport";

const WebHome = () => {
  return (
    <>
      <Dashboard />
      <DashboardReport />
      <Bidfloor />
    </>
  );
};

export default WebHome;
