import BlockManagement from "./BlockManagement";

const AppBlocklist = () => {
  return (
    <>
      <BlockManagement />
    </>
  );
};

export default AppBlocklist;
