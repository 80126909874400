export const AUTHORITY_AREA = {
  blockDomain: "block_domain",
  home: "home",
  media: "media",
  placement: "placement",
  adminDashboard: "adm_dashboard",
  dashboard: "dashboard",
  enterpriseDashboard: "ent_dashboard",
  syndication: "syndication",
  app: "app",
  web: "web",
  partners: "partners",
  account: "account",
  company: "company",
  demoTest: "demo_test",
  manager: "manager",
  members: "members",
  setting: "setting",
  transactionHistory: "tx_history",
  operation: "operation",
  report: "report",
  settlement: "settlement",
} as const;

export type AuthorityArea = Union<typeof AUTHORITY_AREA>;

export const AUTHORITY_AREA_ALIAS: Record<string, string> = {
  block_domain: "차단 관리",
  home: "홈",
  media: "매체 관리",
  placement: "플레이스먼트 관리",
  adm_dashboard: "어드민 대시보드",
  dashboard: "대시보드",
  ent_dashboard: "전사 대시보드",
  syndication: "신디케이션 관리",
  app: "앱 매체",
  web: "웹 매체",
  account: "사용자 설정",
  company: "회사 정보",
  demo_test: "연동 가이드",
  manager: "관리자 등록",
  members: "회원 관리",
  setting: "환경설정",
  tx_history: "정산 내역",
  operation: "운영 관리",
  report: "리포트",
  settlement: "정산 관리",
  partners: "파트너 리포트",
} as const;

export interface Authority {
  id: number;
  type: AuthType;
  home: {
    dashboard: Permission;
    adm_dashboard: Permission;
    ent_dashboard: Permission;
    syndication: Permission;
  };
  app: {
    home: Permission;
    media: Permission;
    placement: Permission;
    block_domain: Permission;
  };
  web: {
    home: Permission;
    media: Permission;
    placement: Permission;
    block_domain: Permission;
  };
  report: {
    app: Permission;
    web: Permission;
    partners: Permission;
  };
  settlement: {
    dashboard: Permission;
    tx_history: Permission;
    setting: Permission;
  };
  setting: {
    manager: Permission;
    demo_test: Permission;
    account: Permission;
    members: Permission;
    company: Permission;
  };
  operation: Permission;
}

export interface RWD {
  read?: boolean;
  write?: boolean;
  delete?: boolean;
}

export interface MultiRWD {
  [key: string]: RWD;
}

export interface AuthorityAreaRWD {
  [key: string]: MultiRWD | RWD;
}

export const AUTH_TYPE = {
  DEFAULT: 1,
  CUSTOM: 2,
};

export type AuthType = Union<typeof AUTH_TYPE>;

export const AUTH_TYPE_ALIAS = {
  [AUTH_TYPE.DEFAULT]: "기본 권한",
  [AUTH_TYPE.CUSTOM]: "커스텀 권한",
};
