import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useSetRecoilState } from "recoil";
import { uid2Agreement } from "src/atoms/uid2Agreement";
import useApiError from "src/hooks/apis/useApiError";
import { COMPANY, Company, Settlement, SETTLEMENT, STATUS, Status } from "src/types";
import API from "src/utils/api";

interface Params {
  companyId?: number;
}

interface Response {
  code: 200 | 401 | 404;
  data?: {
    company: {
      address: string;
      biz_registration_confirmed?: boolean;
      biz_registration_img_url?: string;
      biz_registration_no?: string;
      created_at: number;
      registration_confirmed?: true;
      registration_img_url?: string;
      registration_no?: string;
      id: number;
      key: string;
      manager_email: string;
      manager_name: string;
      manager_phone_no: string;
      manager_uid: number;
      name: string;
      reject_message: string;
      reject_popup_closed: boolean;
      settlement_commission_rate: number;
      settlement_type: Settlement;
      status: Status;
      sub_company_ids: number[];
      syndication_id: number;
      tw_company_id: number;
      type: Company;
      updated_at: number;
      use_adserver: boolean;
      is_uid2_agreement: boolean | null;
    };
  };
  text: "ok" | "unauthorized" | "not-found";
}

// 회사 정보 조회
const useGetCompany = ({ companyId }: Params) => {
  const { handleError } = useApiError();
  const setUid2Agreement = useSetRecoilState(uid2Agreement);
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["companies/useGetCompany", companyId],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/companies/${companyId}`);
      return response.data.data;
    },
    {
      enabled: !!companyId,
      onError: handleError,
      onSuccess: (data) => {
        if (data) {
          setUid2Agreement((prev) => ({
            ...prev,
            value: data.company.is_uid2_agreement,
          }));
        }
      },
    }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            company: {
              address: "",
              biz_registration_confirmed: false,
              biz_registration_img_url: "",
              biz_registration_no: "",
              registration_confirmed: false,
              registration_img_url: "",
              registration_no: "",
              created_at: 0,
              id: 0,
              key: "",
              manager_email: "",
              manager_name: "",
              manager_phone_no: "",
              manager_uid: 0,
              name: "",
              reject_message: "",
              reject_popup_closed: false,
              settlement_commission_rate: 0,
              settlement_type: SETTLEMENT.GROSS,
              status: STATUS.SUSPEND,
              sub_company_ids: [],
              syndication_id: 0,
              tw_company_id: 0,
              type: COMPANY.INDIVIDUAL,
              updated_at: 0,
              use_adserver: false,
              is_uid2_agreement: null,
            },
          },
    [data]
  );

  return {
    data: result,
    ...rest,
  };
};

export default useGetCompany;
