import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  companyKey: string;
  partnerApps: {
    partnerAppId?: number;
    osType: number;
    partnerAppKey: string;
    partnerAppName: string;
  }[];
  partnerId: number;
}

/* 오퍼월 파트너 (Fyber, Tapjoy)별 연동 정보 등록 */
const usePostOfferwall = () => {
  const toast = useToast();
  const { handleError } = useApiError();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ companyKey, partnerApps, partnerId }) => {
      const response: AxiosResponse<Response> = await API.console.post(
        `/partners/interop/offerwall`,
        {
          company_key: companyKey,
          partner_apps: partnerApps.map(
            ({ partnerAppId, osType, partnerAppKey, partnerAppName }) => ({
              partner_app_id: partnerAppId || 0,
              os_type: osType,
              partner_app_name: partnerAppName,
              partner_app_key: partnerAppKey,
            })
          ),
          partner_id: partnerId,
        }
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("저장되었습니다.");
        invalidate("partners/useGetOfferwallList");
      },
    }
  );

  return result;
};

export default usePostOfferwall;
