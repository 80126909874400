import { css } from "@emotion/react";
import { grey, teal } from "@mui/material/colors";

export const serviceLinkStyle = css`
  font-weight: 500;
  color: ${teal[500]};
  text-decoration: none;
`;

export const clientInfoStyle = css`
  .label,
  .total {
    font-weight: 500;
  }
  .value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${grey[600]};
  }
`;

export const responseFormStyle = css`
  .response {
    margin-top: 1rem;
  }
  .submit {
    display: flex;
    justify-content: flex-end;
  }
`;
