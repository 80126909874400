import { AxiosError, AxiosResponse } from "axios";
import { Moment } from "moment";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Thirdparty } from "src/types/thirdparty";
import API from "src/utils/api";

export type DataKey = "response" | "impression" | "click" | "ecpm" | "adv_revenue";

interface Params {
  dataKey: DataKey;
  type: Thirdparty;
  since: Moment;
  until: Moment;
  isTest?: boolean;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    report: {
      click: number;
      ecpm: number;
      impression: number;
      report_hour: string;
      request: number;
      response: number;
      adv_revenue: number;
      thirdparty_id: number;
    }[];
    thirdparties: {
      thirdparty_id: number;
      thirdparty_name: string;
    }[];
    thirdparty_type: Thirdparty;
    total: {
      click: number;
      ecpm: number;
      impression: number;
      request: number;
      response: number;
      adv_revenue: number;
    };
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 관리자 대시보드 (하단 시간별 데이터)
// App DSP, Web DSP만 시간별 데이터 조회 가능
// date를 전달하지 않을 경우 오늘 데이터 조회
// request, response 데이터 없음

const getDailyDSP = async ({
  isTest = false,
  type,
  since,
  until,
}: {
  isTest: boolean;
  type: Thirdparty;
  since: string;
  until: string;
}) => {
  const response: AxiosResponse<Response> = await API.default.get(`/dashboard/admin/daily`, {
    params: {
      type,
      since,
      until,
      is_test: isTest,
    },
  });
  return response.data.data;
};

const getHourlyDSP = async ({
  isTest = false,
  type,
  date,
}: {
  isTest: boolean;
  type: Thirdparty;
  date: string;
}) => {
  const response: AxiosResponse<Response> = await API.default.get(`/dashboard/admin/hourly`, {
    params: {
      type,
      date,
      is_test: isTest,
    },
  });
  return response.data.data;
};

const useGetAdminHourly = ({ dataKey, isTest = false, type, since, until }: Params) => {
  const { handleError } = useApiError();
  const start = useMemo(() => since.format("YYYY-MM-DD"), [since]);
  const end = useMemo(() => until.format("YYYY-MM-DD"), [until]);
  const isHourly = useMemo(() => start === end, [end, start]);

  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["dashboard/useGetAdminHourly", type, start, end],
    async () => {
      return isHourly
        ? await getHourlyDSP({ isTest, type, date: start })
        : await getDailyDSP({ isTest, type, since: start, until: end });
    },
    { onError: handleError }
  );

  // 날짜 세팅
  const range = useMemo(() => {
    if (since.diff(until, "days") === 0) {
      return Array.from({ length: 24 }).map((_, idx) => `0${idx}`.slice(-2));
    } else {
      const arr = [];
      for (let d = since.clone(); d.isSameOrBefore(until); d.add(1, "days")) {
        arr.push(d.format("YYYY-MM-DD"));
      }
      return arr;
    }
  }, [since, until]);

  const result = useMemo(() => {
    if (data) {
      const cardData = data.report.reduce(
        (acc, cur) => {
          acc.response += cur.response;
          acc.impression += cur.impression;
          acc.click += cur.click;
          acc.adv_revenue += cur.adv_revenue;
          return acc;
        },
        {
          response: 0,
          impression: 0,
          click: 0,
          ecpm: 0,
          adv_revenue: 0,
        }
      );

      cardData["ecpm"] =
        (cardData.impression === 0 ? 0 : cardData.adv_revenue / cardData.impression) * 1000;

      const reportedThirdparty =
        data.report.reduce((acc, cur) => {
          acc.add(cur.thirdparty_id);
          return acc;
        }, new Set<number>()) ?? new Set<number>();

      const reportData = [...reportedThirdparty].map((id) => {
        const now = data.report.filter((v) => v.thirdparty_id === id);

        return {
          name: data.thirdparties.find((v) => v.thirdparty_id === id)?.thirdparty_name ?? "",
          total: now.reduce((p, c) => p + c[dataKey], 0),
          data: now.map((v) => v[dataKey]),
        };
      });

      // 리포트 데이터 정렬
      reportData.sort(
        ({ total: atotal }: { total: number }, { total: btotal }: { total: number }) =>
          atotal > btotal ? -1 : atotal < btotal ? 1 : 0
      );

      return { data: reportData, range, cardData };
    }

    return {
      data: undefined,
      range: undefined,
      cardData: undefined,
    };
  }, [data, dataKey, range]);

  return { ...result, ...rest };
};

export default useGetAdminHourly;
