import { css } from "@emotion/react";

// 신디케이션 관리
export const syndicationStyle = css`
  .content {
    .ssp-tools {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      .download {
        margin-left: auto;
      }
    }
  }
`;
