import { IconButton, TextField, TextFieldProps } from "@mui/material";
import { KeyboardEvent, MouseEvent, useCallback } from "react";
import { BiSearch } from "react-icons/bi";

type SearchFieldProps = TextFieldProps & {
  onClickSearchButton: () => void;
};

const SearchField = ({
  fullWidth = true,
  size = "small",
  variant = "outlined",
  InputLabelProps,
  InputProps,
  onClickSearchButton,
  ...props
}: SearchFieldProps) => {
  const onClickSearch = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      onClickSearchButton();
    },
    [onClickSearchButton]
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter") onClickSearchButton();
    },
    [onClickSearchButton]
  );

  return (
    <TextField
      fullWidth={fullWidth}
      size={size}
      variant={variant}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      InputProps={{
        endAdornment: (
          <IconButton color="primary" aria-label="directions" onClick={onClickSearch}>
            <BiSearch />
          </IconButton>
        ),
        ...InputProps,
      }}
      onKeyDown={onKeyDown}
      {...props}
    />
  );
};

export default SearchField;
