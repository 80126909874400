import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import { TextField } from "src/components/commons";
import usePostDomains from "src/hooks/apis/blocklist/usePostDomains";
import { Campaign } from "src/types";
import { getHelperText, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { addBlockURLModalStyle } from "./styles";
export interface CampaignInfo {
  type: Campaign;
  size?: number;
  minviewtime?: number;
  iconImageRequired?: boolean;
  titleRequired?: boolean;
  descRequired?: boolean;
  mainImageRequired?: boolean;
  ctatextRequired?: boolean;
  mute?: boolean;
}

interface AddBlockURLModalProps {
  onClose: () => void;
  open: boolean;
}

const AddBlockURLModal = ({ onClose, open }: AddBlockURLModalProps) => {
  const initialValues = useMemo(() => ({ name: "" }), []);
  const [company] = useRecoilState(viewerCompany);
  const { mutate } = usePostDomains();

  const validationSchema = yup.object({
    name: yup.string().required("그룹명을 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      mutate({ domainListName: values.name, companyKey: company.key });
      onClose();
    },
  });

  return (
    <Dialog
      css={addBlockURLModalStyle}
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="representative-block-url"
      aria-describedby="add block url"
    >
      <DialogTitle id="dialog-title">URL 그룹 추가</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="add-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="그룹명"
                placeholder="그룹명을 입력하세요."
                required
                {...getFieldProps("name")}
                error={shouldErrorShows("name", touched, errors)}
                helperText={getHelperText("name", touched, errors)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="add-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddBlockURLModal;
