import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Status, Thirdparty } from "src/types";
import API from "src/utils/api";

export type MatchKey = Record<string, string | number | boolean | null>;

export interface MatchKeyInfo {
  [key: string]: {
    related: string[];
    required: boolean;
    type: "string" | "float" | "integer" | "number" | "boolean";
  };
}

export interface ThirdpartyInfo {
  id: number;
  individual_status: Status;
  match_key?: MatchKey;
  match_key_info?: MatchKeyInfo[];
  match_no: number;
  name: string;
  order: number;
  report_key_info?: string[];
  status: Status;
  type: Thirdparty;
  bidfloor?: number;
}

interface Params {
  placementId: string;
  type: Thirdparty;
}

interface Response {
  code: 200 | 400 | 401 | 404;
  data?: {
    thirdparties: ThirdpartyInfo[];
  };
  text: "ok" | "bad-request" | "unauthorized" | "not-found";
}

type AppThirdpartyListQueryOptions = Omit<
  UseQueryOptions<
    { thirdparties: ThirdpartyInfo[] } | undefined,
    AxiosError,
    { thirdparties: ThirdpartyInfo[] } | undefined,
    QueryKey
  >,
  "queryKey" | "queryFn"
>;

// 서드파티 정보 조회
// 미디에이션 조회 시 플레이스먼트에 매칭된 서드파티가 없는 경우 순서를 최하위로 설정하기 위해 {"order": 99}로 전달
// 매치키가 필요 없는 경우 {"match_key": null, "match_info":null}
// 매칭정보가 입력 안된 경우 {"match_info":null} 또는 {"match_info":{}}
// 서드파티 타입에 따라 데이터가 일부 변경됨
// DSP - {"is_pmp": false, "match_key": null} 또는 {"is_pmp": true, "match_key":[{"key1":{"type":"string","related":[“key2”],"required":[true|false]}}]}
// 미디에이션 - {"match_key": null} 또는 {"match_key": ["key1", “key2”]}
const useGetAppThirdpartyList = (
  { placementId, type }: Params,
  options?: AppThirdpartyListQueryOptions
) => {
  const { handleError } = useApiError();

  const queryKey = useMemo(
    () => ["placements/useGetAppThirdpartyList", placementId, type],
    [placementId, type]
  );

  const queryFn = useCallback(async () => {
    const response: AxiosResponse<Response> = await API.default.get(
      `/placements/app/${placementId}/thirdparties`,
      {
        params: { type },
      }
    );
    return response.data.data;
  }, [placementId, type]);

  const { data, ...rest } = useQuery<Response["data"], AxiosError>(queryKey, queryFn, {
    enabled: !!placementId,
    onError: handleError,
    ...options,
  });

  const result = useMemo(() => (data ? data : { thirdparties: [] }), [data]);

  return { data: result, ...rest };
};

export default useGetAppThirdpartyList;
