import { Button, DialogActions, DialogContent } from "@mui/material";
import { RowSelectedEvent, ValueFormatterParams } from "ag-grid-community";
import { ChangeEvent, MouseEvent, useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import { BasicTable, SearchField } from "src/components/commons";
import useGetAppStore from "src/hooks/apis/media/useGetAppStore";
import usePostApp from "src/hooks/apis/media/usePostApp";
import { AppPlatform, APP_PLATFORM, APP_PLATFORM_ALIAS } from "src/types";
import { addSearchFormStyle } from "./styles";

interface AddSearchFormProps {
  onClose: () => void;
}

const AddSearchForm = ({ onClose }: AddSearchFormProps) => {
  const [company] = useRecoilState(viewerCompany);
  const [search, setSearch] = useState({ temp: "", value: "" });
  const { data } = useGetAppStore({ search: search.value });

  const [app, setApp] = useState({
    companyKey: company.key,
    name: "",
    packageName: "",
    platformType: APP_PLATFORM.ANDROID,
  });
  const { mutate: createMedia } = usePostApp();

  const onChangeSearchWord = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearch((prev) => ({ ...prev, temp: e.target.value }));
  }, []);

  const onSearchApp = useCallback(() => {
    setSearch((prev) => ({ ...prev, value: prev.temp }));
  }, []);

  const onRowSelect = useCallback((e: RowSelectedEvent) => {
    setApp((prev) => ({
      ...prev,
      name: e.data.name,
      packageName: e.data.package_name,
      platformType: e.data.platform_type,
    }));
  }, []);

  const onAddMedia = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      createMedia(app);
      onClose();
    },
    [app, createMedia, onClose]
  );

  return (
    <>
      <DialogContent css={addSearchFormStyle}>
        <SearchField
          className="search-app-input"
          label="Apple App Store 또는 Google Play에서 앱 검색"
          placeholder="앱 이름을 입력해주세요."
          value={search.temp}
          onChange={onChangeSearchWord}
          onClickSearchButton={onSearchApp}
        />
        <BasicTable
          rowSelection="single"
          rowData={data.apps}
          columnDefs={columnDefs}
          onRowSelected={onRowSelect}
        />
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button onClick={onAddMedia}>저장</Button>
      </DialogActions>
    </>
  );
};

const columnDefs = [
  {
    headerName: "앱 매체 명",
    field: "name",
    sortable: true,
    cellRenderer: "agGroupCellRenderer",
    cellRendererParams: { checkbox: true },
  },
  { headerName: "개발자", field: "key", sortable: true },
  {
    headerName: "플랫폼",
    field: "platform_type",
    sortable: true,
    valueFormatter: (params: ValueFormatterParams) => {
      const platform = params.value as AppPlatform;
      return APP_PLATFORM_ALIAS[platform];
    },
  },
];

export default AddSearchForm;
