import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  thirdpartyId: number;
  systemFee: number;
}

interface Response {
  code: 200 | 400 | 401 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "internal-server-error";
}

// 미디에이션 상세 정보 설정
const usePutMediationDetail = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ thirdpartyId, systemFee }) => {
      const response: AxiosResponse<Response> = await API.default.put(
        `/thirdparties/mediations/${thirdpartyId}`,
        { system_fee: systemFee }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("수정되었습니다.");
        invalidate("thirdparties/useGetMediationList");
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePutMediationDetail;
