import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import useApiError from "src/hooks/apis/useApiError";
import { CAMPAIGN, Campaign, STATUS, Status, TEMPLATE, Template } from "src/types";
import API from "src/utils/api";

export type AppPlacementDetail = {
  bidfloor: number;
  bidfloor_currency: string;
  bidfloor_status: Status;
  check_viewability: boolean;
  cpc_bidfloor: number;
  cpc_bidfloor_currency: string;
  cpc_bidfloor_status: Status;
  created_at: number;
  ctatext_required: boolean;
  desc_required: boolean;
  icon_image_required: boolean;
  id: string;
  is_sdk_mediation: boolean;
  main_image_required: boolean;
  maxduration: number;
  media_key: string;
  media_name: string;
  minduration: number;
  minviewtime: number;
  mute: boolean;
  name: string;
  reward_currency: string;
  reward_value: number;
  size_type: number;
  status: Status;
  title_required: boolean;
  type: Campaign;
  updated_at: number;
  // Campaign 1~6
  is_reward_cpc?: boolean;
  // 컴패니언 혹은 배너/전면 네이티브 지면
  parent_p_id?: string;
  template_no?: Template;
  // 컴패니언 네이티브 부모
  companion_use?: boolean;
  companion_p_id?: string;
  companion_p_name?: string;
  // 배너/전면 네이티브 부모
  sub_native_use?: boolean;
  sub_native_p_id?: string;
  sub_native_p_name?: string;
};

interface AppPlacementDetailParams {
  placementId?: string;
}

interface AppPlacementDetailResponse {
  code: 200 | 401 | 404;
  data?: { placement: AppPlacementDetail };
  text: "ok" | "unauthorized" | "not-found";
}

type AppPlacementDetailQueryKey = ["placements/useGetAppPlacementDetail", AppPlacementDetailParams];

type AppPlacementDetailQueryFn = QueryFunction<
  AxiosResponse<AppPlacementDetailResponse>,
  AppPlacementDetailQueryKey
>;

type AppPlacementDetailQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<AppPlacementDetailResponse>,
    AxiosError,
    AxiosResponse<AppPlacementDetailResponse>,
    AppPlacementDetailQueryKey
  >,
  "queryKey" | "queryFn"
>;

// 플레이스먼트 정보 조회
// 타입별로 추가 필드가 있음
// Banner(1), Interstitial(2) - {"size_type":1}
// Native(3) - {"size_type":1,"main_image_required":true,"icon_image_required":true,"title_required": true,"desc_required":true,"ctatext_required":true}
// Reward Video(4) - {"reward_value": 1.0,"reward_currency":"\ubcf4\uae30","minduration": 0,"maxduration":100}
// Interstitial Video(6) - {"size_type":1,"minduration": 0,"maxduration":100,"minviewtime":0}
const useGetAppPlacementDetail = (
  params: AppPlacementDetailParams,
  options?: AppPlacementDetailQueryOptions
) => {
  const { handleError } = useApiError();

  const queryKey = useMemo<AppPlacementDetailQueryKey>(
    () => ["placements/useGetAppPlacementDetail", params],
    [params]
  );

  const queryFn = useCallback<AppPlacementDetailQueryFn>(({ queryKey }) => {
    const [, { placementId }] = queryKey;
    return API.default.get(`/placements/app/${placementId}`);
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    enabled: !!params.placementId,
    ...options,
  });

  const result = useMemo<{ placement: AppPlacementDetail }>(
    () =>
      response?.data.data
        ? response.data.data
        : {
            placement: {
              bidfloor: 0,
              bidfloor_currency: "",
              bidfloor_status: STATUS.SUSPEND,
              check_viewability: false,
              cpc_bidfloor: 0,
              cpc_bidfloor_currency: "",
              cpc_bidfloor_status: STATUS.SUSPEND,
              created_at: 0,
              ctatext_required: false,
              desc_required: false,
              icon_image_required: false,
              id: "",
              is_sdk_mediation: false,
              main_image_required: false,
              maxduration: 0,
              media_key: "",
              media_name: "",
              minduration: 0,
              minviewtime: 0,
              mute: false,
              name: "",
              reward_currency: "",
              reward_value: 0,
              size_type: 0,
              status: STATUS.SUSPEND,
              title_required: false,
              type: CAMPAIGN.Banner,
              updated_at: 0,
              is_reward_cpc: false,
              // 컴패니언 혹은 배너/전면 네이티브 지면
              parent_p_id: "",
              template_no: TEMPLATE.COMPANION_A,
              // 컴패니언 네이티브 부모
              companion_use: false,
              companion_p_id: "",
              companion_p_name: "",
              // 배너/전면 네이티브 부모
              sub_native_use: false,
              sub_native_p_id: "",
              sub_native_p_name: "",
            },
          },
    [response]
  );

  return { data: result, ...rest };
};

export default useGetAppPlacementDetail;
