import { teal } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LicenseManager } from "ag-grid-enterprise";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import {
  AuthRoute,
  CoupangLayout,
  Development,
  Layout,
  Loading,
  LoginLayout,
  NpayLayout,
  PolicyLayout,
  RedirectRoute,
  ToastContainer,
  // UpdateModal,
} from "src/components/commons";
import {
  AccountSettingPage,
  AdminSettlementPage,
  AppBlocklistPage,
  AppHomePage,
  AppMediaPage,
  AppPlacementPage,
  AppReportPage,
  CompanyApprovalPage,
  CompanyManagementPage,
  CompanySettingPage,
  CoupangCampaignPage,
  CoupangPartnerPage,
  CoupangReportPage,
  CoupangServiceDetailPage,
  CoupangServicePage,
  CoupangSubIdPage,
  CustomerServiceDetailPage,
  CustomerServicePage,
  DataToolsPage,
  ErrorPage,
  FindEmailPage,
  FindPasswordPage,
  HomeAdminPage,
  HomeDashboardPage,
  HomeEnterprisePage,
  HomeSyndiationPage,
  InvitationSignUpPage,
  InvitePage,
  LoginPage,
  ManagerSettingPage,
  MemberSettingPage,
  NotFoundPage,
  PartnerManagementPage,
  PartnerReportPage,
  ReportKey,
  SignUpPage,
  WebBlocklistPage,
  WebHomePage,
  WebMediaPage,
  WebPlacementPage,
  WebReportPage,
  PrivacyPolicyPage,
  ServiceTermPage,
  NpayAppsPage,
  NpayPlacementsPage,
  NpayTransactionsPage,
  NpayFailedTransactionsPage,
  NpayCSPage,
  NpayCsDetailPage,
  NpayReportPage,
  // SettlementDashboardPage,
  // SettlementListPage,
  // SettlementSettingPage,
} from "src/pages";
import CoupangPostbackPage from "./pages/operation/coupang/postback";

export const theme = createTheme({
  palette: {
    primary: {
      main: teal[500],
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 0, refetchOnWindowFocus: false } },
});

LicenseManager.setLicenseKey(`${process.env.REACT_APP_AG_GRID_LICENCE}`);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route element={<AuthRoute />}>
                <Route element={<Layout />}>
                  {/* 홈 */}
                  <Route path="home">
                    <Route index element={<Navigate to="dashboard" replace />} />
                    <Route path="dashboard" element={<HomeDashboardPage />} />
                    <Route path="admin" element={<HomeAdminPage />} />
                    <Route path="enterprise" element={<HomeEnterprisePage />} />
                    <Route path="syndication" element={<HomeSyndiationPage />} />
                  </Route>
                  {/* 앱 매체 */}
                  <Route path="app">
                    <Route index element={<Navigate to="home" replace />} />
                    <Route path="home" element={<AppHomePage />} />
                    <Route path="media" element={<AppMediaPage />} />
                    <Route path="placement" element={<AppPlacementPage />} />
                    <Route path="blocklist" element={<AppBlocklistPage />} />
                  </Route>
                  {/* 웹 매체 */}
                  <Route path="web">
                    <Route index element={<Navigate to="home" replace />} />
                    <Route path="home" element={<WebHomePage />} />
                    <Route path="media" element={<WebMediaPage />} />
                    <Route path="placement" element={<WebPlacementPage />} />
                    <Route path="blocklist" element={<WebBlocklistPage />} />
                  </Route>
                  {/* 리포트 */}
                  <Route path="report">
                    <Route index element={<Navigate to="app" replace />} />
                    <Route path="app" element={<AppReportPage />} />
                    <Route path="web" element={<WebReportPage />} />
                    <Route path="partner" element={<PartnerReportPage />} />
                  </Route>
                  {/* 정산관리 */}
                  {/* <Route path="settlement">
                    <Route index element={<Navigate to="dashboard" replace />} />
                    <Route path="dashboard" element={<SettlementDashboardPage />} />
                    <Route path="list" element={<SettlementListPage />} />
                    <Route path="setting" element={<SettlementSettingPage />} />
                  </Route> */}
                  {/* 환경 설정 */}
                  <Route path="setting">
                    <Route index element={<Navigate to="account" replace />} />
                    <Route path="account" element={<AccountSettingPage />} />
                    <Route path="company" element={<CompanySettingPage />} />
                    <Route path="member" element={<MemberSettingPage />} />
                    <Route path="manager" element={<ManagerSettingPage />} />
                  </Route>
                  {/* 운영 관리 */}
                  <Route path="operation">
                    <Route index element={<Navigate to="company" replace />} />
                    <Route path="company" element={<CompanyManagementPage />} />
                    <Route path="approve" element={<CompanyApprovalPage />} />
                    <Route path="partner" element={<PartnerManagementPage />} />
                    <Route path="settlement" element={<AdminSettlementPage />} />
                    <Route path="service">
                      <Route index element={<CustomerServicePage />} />
                      <Route path="detail/:id" element={<CustomerServiceDetailPage />} />
                    </Route>
                    <Route path="report-key" element={<ReportKey />} />
                    {/* 쿠팡 관리 */}
                    <Route path="coupang">
                      <Route path="service/detail/:id" element={<CoupangServiceDetailPage />} />
                      <Route element={<CoupangLayout />}>
                        <Route index element={<Navigate to="partner" replace />} />
                        <Route path="partner" element={<CoupangPartnerPage />} />
                        <Route path="subId" element={<CoupangSubIdPage />} />
                        <Route path="postback" element={<CoupangPostbackPage />} />
                        <Route path="campaign" element={<CoupangCampaignPage />} />
                        <Route path="service" element={<CoupangServicePage />} />
                        <Route path="report" element={<CoupangReportPage />} />
                      </Route>
                    </Route>
                    <Route path="datatools" element={<DataToolsPage />} />

                    {/* 네이버페이 관리 */}
                    <Route path="npay">
                      <Route index element={<Navigate to="apps" replace />} />
                      <Route path="cs/:id" element={<NpayCsDetailPage />} />

                      <Route element={<NpayLayout />}>
                        <Route path="apps" element={<NpayAppsPage />} />
                        <Route path="placements" element={<NpayPlacementsPage />} />
                        <Route path="cs" element={<NpayCSPage />} />
                        <Route path="transactions" element={<NpayTransactionsPage />} />
                        <Route path="report" element={<NpayReportPage />} />
                        <Route
                          path="failed-transactions"
                          element={<NpayFailedTransactionsPage />}
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
              <Route element={<RedirectRoute />}>
                <Route path="login">
                  <Route element={<LoginLayout />}>
                    <Route index element={<LoginPage />} />
                    <Route path="register" element={<SignUpPage />} />
                    <Route path="invite/register" element={<InvitationSignUpPage />} />
                    <Route path="find/email" element={<FindEmailPage />} />
                    <Route path="find/password" element={<FindPasswordPage />} />
                  </Route>
                  <Route path="invite" element={<InvitePage />} />
                </Route>
              </Route>
              {/* 서비스이용약관 및 개인정보처리방침 */}
              <Route element={<PolicyLayout />}>
                <Route path="policy/term" element={<ServiceTermPage />} />
                <Route path="policy/privacy" element={<PrivacyPolicyPage />} />
              </Route>
              <Route path="error" element={<ErrorPage />} />
              <Route path="/" element={<Navigate to="home/dashboard" replace />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
          <Loading />
          <ToastContainer />
          {/* <UpdateModal /> */}
          <Development />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </RecoilRoot>
    </ThemeProvider>
  );
};

export default App;
