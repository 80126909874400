import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { TextField } from "src/components/commons";
import useGetCampaign from "src/hooks/apis/coupang/useGetCampaign";
import usePatchCampaign from "src/hooks/apis/coupang/usePatchCampaign";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { campaignModalStyle } from "./styles";

interface EditCampaignModalProps {
  onClose: () => void;
  open: { key: string; isOpen: boolean };
}

const EditCampaignModal = ({ onClose, open }: EditCampaignModalProps) => {
  const { data } = useGetCampaign({ campaignKey: open.key });
  const { error, mutate } = usePatchCampaign();

  const initialValues = useMemo(
    () => ({
      partnerName: data.campaign.partner_name,
      campaignName: data.campaign.campaign_name,
      triggerRevenue: data.campaign.trigger_revenue,
      landingUrl: data.campaign.landing_url,
    }),
    [
      data.campaign.campaign_name,
      data.campaign.landing_url,
      data.campaign.partner_name,
      data.campaign.trigger_revenue,
    ]
  );

  const validationSchema = yup.object({
    campaignName: yup.string().required("캠페인 이름을 입력해주세요."),
    landingUrl: yup
      .string()
      .matches(REG_EXP.domain, "올바른 URL을 입력해주세요.")
      .required("랜딩 URL을 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, touched, errors, setFieldError } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate({
        campaignKey: open.key,
        campaignName: values.campaignName,
        landingUrl: values.landingUrl,
      });
    },
  });

  useEffect(() => {
    if (error?.response?.status === 409) setFieldError("campaignName", "캠페인명이 중복됩니다");
  }, [error?.response?.status, setFieldError]);

  return (
    <Dialog
      css={campaignModalStyle}
      fullWidth
      maxWidth="md"
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-coupang-campaign"
      aria-describedby="edit coupang campaign"
    >
      <DialogTitle id="dialog-title">{data.campaign.campaign_name}</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="edit-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="파트너명"
                required
                disabled
                {...getFieldProps("partnerName")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="캠페인 이름"
                placeholder="캠페인 이름 (중복불가)"
                required
                {...getFieldProps("campaignName")}
                error={shouldErrorShows("campaignName", touched, errors)}
                helperText={getHelperText("campaignName", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="이벤트 최소 금액"
                placeholder="해당 캠페인 이벤트 최소 금액을 입력해주세요."
                suffix={
                  <Typography variant="body2" sx={{ whiteSpace: "pre" }}>
                    원 이상 구매시
                  </Typography>
                }
                required
                disabled
                {...getFieldProps("triggerRevenue")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                minRows={5}
                className="field"
                label="랜딩 URL"
                placeholder="캠페인 클릭시 이동할 랜딩 URL을 입력해주세요. https://link.coupang.com/re/AFAPGB?subid= {subid}&subparam={bid}&publisherid= {publisher_id}&adid={adid}&idfa= {idfa}"
                required
                {...getFieldProps("landingUrl")}
                error={shouldErrorShows("landingUrl", touched, errors)}
                helperText={getHelperText("landingUrl", touched, errors)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCampaignModal;
