import { Container, Grid, Paper, Typography } from "@mui/material";
import AddEmailForm from "./AddEmailForm";
import EmailBoard from "./EmailBoard";

const EmailManagement = () => {
  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        업체 주소록
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <AddEmailForm />
          </Grid>
          <Grid item xs={6}>
            <EmailBoard />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default EmailManagement;
