import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { Campaign, Status, Template } from "src/types";
import API from "src/utils/api";

interface Params {
  placementId: string;
  bidfloor?: number;
  bidfloorCurrency?: string;
  bidfloorStatus?: Status;
  checkViewability?: boolean;
  cpcBidfloor?: number;
  cpcBidfloorCurrency?: string;
  cpcBidfloorStatus?: Status;
  ctaTextRequired?: boolean;
  descRequired?: boolean;
  iconImageRequired?: boolean;
  isSDKMediation?: boolean;
  mainImageRequired?: boolean;
  maxDuration?: number;
  mediaKey: string;
  minDuration?: number;
  minViewTime?: number;
  mute?: boolean;
  name: string;
  rewardCurrency?: string;
  rewardValue?: number;
  sizeType?: number;
  titleRequired?: boolean;
  type: Campaign;
  // Campaign 1 ~ 6
  isRewardCpc?: boolean;
  // 컴패니언 혹은 전면/배너 네이티브
  parentId?: string;
  // 컴패니언
  companionUse?: boolean;
  templateNo?: Template;
  // 전면/배너 네이티브
  subNativeUse?: boolean;
}

interface Response {
  code: 200 | 401 | 404 | 409;
  text: "ok" | "unauthorized" | "not-found" | "conflict";
}

// 플레이스먼트 정보 수정
// 매체에 같은 플랫폼, 이름의 플레이스먼트가 있을 경우 이름 뒤에 _{EpochTime}을 붙인다.
// 타입별로 필수 데이터가 다름
// Banner(1), Interstitial(2) - {"size_type":1}
// Native(3) - {"size_type":1,"main_image_required":true,"icon_image_required":true,"title_required": true,"desc_required":true,"ctatext_required":true}
// Reward Video(4) - {"reward_value": 1.0,"reward_currency":"\ubcf4\uae30","minduration": 0,"maxduration":100}
// Interstitial Video(6) - {"size_type":1,"minduration": 0,"maxduration":100,"minviewtime":0}
const usePutAppPlacement = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({
      placementId,
      bidfloor,
      bidfloorCurrency,
      bidfloorStatus,
      checkViewability,
      cpcBidfloor,
      cpcBidfloorCurrency,
      cpcBidfloorStatus,
      ctaTextRequired,
      descRequired,
      iconImageRequired,
      isSDKMediation,
      mainImageRequired,
      maxDuration,
      mediaKey,
      minDuration,
      minViewTime,
      mute,
      name,
      rewardCurrency,
      rewardValue,
      sizeType,
      titleRequired,
      type,
      // Campaign 1 ~ 6
      isRewardCpc,
      // 컴패니언 혹은 전면/배너 네이티브
      parentId,
      templateNo,
      // 컴패니언
      companionUse,
      // 전면/배너 네이티브
      subNativeUse,
    }) => {
      const response: AxiosResponse<Response> = await API.default.put(
        `/placements/app/${placementId}`,
        {
          bidfloor: bidfloor,
          bidfloor_currency: bidfloorCurrency,
          bidfloor_status: bidfloorStatus,
          check_viewability: checkViewability,
          cpc_bidfloor: cpcBidfloor,
          cpc_bidfloor_currency: cpcBidfloorCurrency,
          cpc_bidfloor_status: cpcBidfloorStatus,
          ctatext_required: ctaTextRequired,
          desc_required: descRequired,
          icon_image_required: iconImageRequired,
          is_sdk_mediation: isSDKMediation,
          main_image_required: mainImageRequired,
          maxduration: maxDuration,
          media_key: mediaKey,
          minduration: minDuration,
          minviewtime: minViewTime,
          mute: mute,
          name: name,
          reward_currency: rewardCurrency,
          reward_value: rewardValue,
          size_type: sizeType,
          title_required: titleRequired,
          type: type,
          // Campaign 1 ~ 6
          is_reward_cpc: isRewardCpc,
          // 컴패니언 혹은 전면/배너 네이티브
          parent_p_id: parentId,
          template_no: templateNo,
          // 컴패니언
          companion_use: companionUse,
          // 전면/배너 네이티브
          sub_native_use: subNativeUse,
        }
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: async () => {
        await invalidate([
          "placements/useGetAppPlacementDetail",
          "placements/useGetAppPlacementList",
        ]);
        toast.success("수정되었습니다.");
      },
    }
  );

  return result;
};

export default usePutAppPlacement;
