import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ColDef, ColGroupDef, GridApi, ICellRendererParams } from "ag-grid-community";
import { MouseEvent, useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import { BasicTable } from "src/components/commons";
import useGetDomains from "src/hooks/apis/blocklist/useGetDomains";
import useGetWebMedia from "src/hooks/apis/media/useGetWebMedia";
import usePatchWebBlockDomain from "src/hooks/apis/media/usePatchWebBlockDomain";

interface BlockDomainModalProps {
  onClose: () => void;
  open: { key: string; isOpen: boolean };
}

const BlockDomainModal = ({ open, onClose }: BlockDomainModalProps) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [company] = useRecoilState(viewerCompany);
  const { data: mediaData } = useGetWebMedia({ mediaKey: open.key });
  const { data: domainData } = useGetDomains({ companyKey: company.key });
  const { mutate } = usePatchWebBlockDomain();

  const onSave = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      const selectedList = gridApi?.getSelectedRows() || [];
      mutate({
        mediaKey: open.key,
        domainListIds: selectedList.map(({ domain_list_id }) => domain_list_id),
      });
      onClose();
    },
    [gridApi, mutate, onClose, open.key]
  );

  return (
    <Dialog
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-blocklist"
      aria-describedby="edit blocklist"
    >
      <DialogTitle id="dialog-title">차단 URL 그룹 설정</DialogTitle>
      <DialogContent className="dialog-content">
        <BasicTable
          rowData={domainData.domain_list}
          columnDefs={getColumnDefs(mediaData.media.block_domain_list_set)}
          onGridReady={(e) => setGridApi(e.api)}
          rowSelection="multiple"
          suppressRowClickSelection={true}
        />
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button onClick={onSave}>저장</Button>
      </DialogActions>
    </Dialog>
  );
};

const getColumnDefs = (blockDomainList: number[]): (ColDef | ColGroupDef)[] => [
  {
    headerName: "그룹명",
    field: "domain_list_name",
    sortable: true,
    cellRenderer: "agGroupCellRenderer",
    cellRendererParams: {
      checkbox: (params: ICellRendererParams) => {
        if (blockDomainList.includes(params.data.domain_list_id)) {
          params.node.setSelected(true);
        }
        return true;
      },
    },
  },
];

export default BlockDomainModal;
