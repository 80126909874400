import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { MutationFunction, useMutation, UseMutationOptions } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";

export type NpayDailyCap = number;

type NpayDailyCapMutationKey = "operation/npay/update-daily-cap";
type NpayDailyCapMutationFn = MutationFunction<AxiosResponse, NpayDailyCap>;

type NpayDailyCapMutationOptions = Omit<
  UseMutationOptions<AxiosResponse, AxiosError, NpayDailyCap>,
  "mutationKey" | "mutationFn"
>;

export default function useUpdateNpayDailyCap(options?: NpayDailyCapMutationOptions) {
  const { handleError } = useApiError();

  const mutationKey = useMemo<NpayDailyCapMutationKey>(() => "operation/npay/update-daily-cap", []);

  const mutateFn = useCallback<NpayDailyCapMutationFn>((value) => {
    const cookie = parseCookies();

    return API.consoleV3.put(
      "/v3/naverpay/dailycap",
      {
        value,
      },
      {
        headers: {
          "x-api-type": "1",
          Authorization: `Bearer ${cookie.access_token || ""}`,
        },
      }
    );
  }, []);

  const mutation = useMutation(mutationKey, mutateFn, {
    onError: handleError,
    ...options,
  });

  return mutation;
}
