import { Box, Button, TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { focusManager } from "react-query";
import { previewStyle, uploadFieldStyle } from "./styles";

type UploadFieldProps = Omit<TextFieldProps, "onChange"> & {
  onChange?: (data: File) => void;
  accept?: string;
  value?: File | string;
  preview?: boolean;
};

const UploadField = ({
  InputLabelProps,
  InputProps,
  size,
  onChange,
  accept,
  value,
  className,
  preview = false,
  disabled,
  ...props
}: UploadFieldProps) => {
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState("");
  const uploadInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (typeof value === "string") {
      setFileName(value);
      return;
    }
    setFileName("");
  }, [value]);

  useEffect(() => {
    focusManager.setFocused(false);
    return () => focusManager.setFocused(undefined);
  }, []);

  const onClickButton = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    uploadInputRef.current && uploadInputRef.current.click();
  }, []);

  const onChangeFileField = useCallback(
    (e: ChangeEvent) => {
      e.preventDefault();
      if (
        uploadInputRef.current &&
        uploadInputRef.current.files &&
        uploadInputRef.current.files.length > 0
      ) {
        setFile(uploadInputRef.current.files[0]);
        onChange && onChange(uploadInputRef.current.files[0]);
      }
    },
    [onChange]
  );

  const url = useMemo(() => (file ? window.URL.createObjectURL(file) : fileName), [file, fileName]);

  return (
    <Box className={className}>
      <TextField
        fullWidth
        css={uploadFieldStyle}
        className="file-upload"
        type={disabled ? "text" : "file"}
        inputRef={uploadInputRef}
        onChange={onChangeFileField}
        InputLabelProps={{
          shrink: true,
          ...InputLabelProps,
        }}
        inputProps={{
          accept: accept,
        }}
        InputProps={{
          className: file || fileName ? "loaded" : "empty",
          readOnly: true,
          size: size,
          startAdornment: (
            <span className="file-name">
              {file ? file.name : fileName?.split(".").slice(-2).join(".")}
            </span>
          ),
          endAdornment: (
            <Button size={size} variant="contained" onClick={onClickButton} disabled={disabled}>
              Upload
            </Button>
          ),
          disabled: disabled,
          ...InputProps,
        }}
        disabled={disabled}
        {...props}
      />
      {preview && url && (
        <Box css={previewStyle}>
          {url?.toLowerCase().includes(".pdf") || file?.type === "application/pdf" ? (
            <iframe title="preview" src={url} />
          ) : (
            <img alt="preview" src={url} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default UploadField;
