import { TextField as InputText, TextFieldProps as InputTextProps } from "@mui/material";
import { ReactNode } from "react";

type TextFieldProps = Override<
  InputTextProps,
  {
    prefix?: ReactNode;
    suffix?: ReactNode;
    readOnly?: boolean;
  }
>;

const TextField = ({
  fullWidth = true,
  variant = "outlined",
  size = "small",
  prefix,
  suffix,
  readOnly,
  InputProps,
  ...props
}: TextFieldProps) => {
  return (
    <InputText
      fullWidth={fullWidth}
      variant={variant}
      size={size}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        startAdornment: prefix,
        endAdornment: suffix,
        readOnly: readOnly,
        ...InputProps,
      }}
      {...props}
    />
  );
};

export default TextField;
