import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

interface Params {
  token: string;
}

interface Response {
  code: 200 | 400 | 401 | 404 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "not-found" | "internal-server-error";
}

/*
  사용자 승인
  토큰 정보가 없는 경우 not-found-invite-token, 등록된 사용자가 없는 경우 not-found-user
*/
const usePostUserApprove = () => {
  const navigate = useNavigate();
  const { handleError } = useApiError();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ token }) => {
      const response: AxiosResponse<Response> = await API.default.post(`/users/invite/approve`, {
        token,
      });
      return response.data;
    },
    {
      onSuccess: () => navigate("/login", { replace: true }),
      onError: handleError,
    }
  );

  return result;
};

export default usePostUserApprove;
