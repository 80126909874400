import { Moment } from "moment";
import { useMutation } from "react-query";

import useApiError from "src/hooks/apis/useApiError";
import useToast from "src/hooks/useToast";
import { Report as ReportType, REPORT_ALIAS } from "src/types";
import API from "src/utils/api";

interface Params {
  since: Moment;
  until: Moment;
  reportType: ReportType;
  companyKey: string;
}

// 쿠팡 리포트 데이터 엑셀 다운로드
const usePostCoupangExcel = () => {
  const toast = useToast();
  const { handleError } = useApiError();

  const mutation = useMutation(
    async ({ since, until, reportType, companyKey }: Params) => {
      const startDate = since.format("YYYYMMDD");
      const endDate = until.format("YYYYMMDD");

      return await API.integration.post(
        `/operation/management/coupang/report/download_v2`,
        {
          is_op: false,
          start_date: startDate,
          end_date: endDate,
          report_type: reportType,
          company_key: companyKey,
        },
        {
          responseType: "blob",
        }
      );
    },
    {
      onMutate: () => {
        toast.success("다운로드는 시간이 다소 소요될 수 있습니다.");
      },
      onSuccess: (response, params) => {
        const { since, until, reportType } = params;
        const startDate = since.format("YYYYMMDD");
        const endDate = until.format("YYYYMMDD");

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `쿠팡 ${REPORT_ALIAS[reportType]}_${startDate}_${endDate}.xlsx`
        );
        link.click();
      },
      onError: handleError,
    }
  );

  return mutation;
};

export default usePostCoupangExcel;
