import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Campaign, Status } from "src/types";
import API from "src/utils/api";

interface Params {
  type?: Campaign;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    thirdparties: {
      id: number;
      name: string;
      status: Status;
    }[];
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// DSP 활성상태 조회
// 웹은 사이즈가 너무 많아서 { "id": 0, "status": 10 또는 30 }
// Reward video(4), Interstitial video(5), Non reward video(6) 은 사이즈가 필요없기 때문에 { "id": 0, "status": 10 또는 30 } 와 같이 전달
// ‘status.id’ 는 ‘ap_ssp_static_info.ssp_ad_format_info.ad_format_type’ 참고
const useGetDSPStatus = ({ type }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["thirdparties/useGetDSPStatus", type],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/thirdparties/dsps/status`, {
        params: { type },
      });
      return response.data.data;
    },
    { enabled: !!type, onError: handleError }
  );

  const result = useMemo(() => (data ? data : { thirdparties: [] }), [data]);

  return { data: result, ...rest };
};

export default useGetDSPStatus;
