import { Button, Grid } from "@mui/material";
import { CellClickedEvent, SortChangedEvent } from "ag-grid-community";
import { ICellRendererParams, ValueFormatterParams } from "ag-grid-enterprise";
import { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import DataFormat from "src/assets/formats";
import { BasicTable, DeleteButton, SearchField } from "src/components/commons";
import useDeletePartner from "src/hooks/apis/coupang/useDeletePartner";
import useGetPartnerList from "src/hooks/apis/coupang/useGetPartnerList";
import useOpenModal from "src/hooks/useOpenModal";
import AddPartnerModal from "./AddPartnerModal";
import EditPartnerModal from "./EditPartnerModal";

const PartnerBoard = () => {
  const [search, setSearch] = useState({ temp: "", value: "" });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [orders, setOrders] = useState<string[]>([]);
  const [openAddModal, onShowAddModal, onCloseAddModal] = useOpenModal(null);
  const [openEditModal, onShowEdit, onCloseEditModal] = useOpenModal(0);

  const { data } = useGetPartnerList({ search: search.value, pageNo, pageSize, orders });

  const onChangeSearchWord = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearch({ ...search, temp: e.target.value });
    },
    [search]
  );

  const onSearchPartner = useCallback(() => {
    setSearch({ ...search, value: search.temp });
    setPageNo(1);
  }, [search]);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const onShowEditModal = useCallback(
    (e: CellClickedEvent) => {
      if (e.colDef.field !== "subids" && e.colDef.headerName !== "Delete") {
        onShowEdit(e, e.data.partner_no);
      }
    },
    [onShowEdit]
  );

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, data.pages, onChangePage, pageSize, onChangePageSize]
  );

  const onSortChanged = useCallback((e: SortChangedEvent) => {
    const sortInfo = e.columnApi
      .getColumnState()
      .filter((column) => !!column.sort)
      .map(({ colId, sort }) => {
        if (sort === "desc") return `-${colId}`;
        return `+${colId}`;
      });
    setOrders(sortInfo);
  }, []);

  return (
    <>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={3}>
          <SearchField
            label="검색어"
            placeholder="업체명 / 파트너명"
            value={search.temp}
            onChange={onChangeSearchWord}
            onClickSearchButton={onSearchPartner}
          />
        </Grid>
        <Grid item xs={9} className="register">
          <Button variant="outlined" onClick={onShowAddModal}>
            신규 파트너 등록
          </Button>
        </Grid>
      </Grid>
      <BasicTable
        animateRows
        rowData={data.partners}
        columnDefs={columnDefs}
        onSortChanged={onSortChanged}
        onCellClicked={onShowEditModal}
        {...paginationInfo}
      />
      {openAddModal.isOpen && (
        <AddPartnerModal isOpen={openAddModal.isOpen} onClose={onCloseAddModal} />
      )}
      {openEditModal.isOpen && <EditPartnerModal open={openEditModal} onClose={onCloseEditModal} />}
    </>
  );
};

const columnDefs = [
  { headerName: "업체명", field: "company_name", sortable: true },
  { headerName: "파트너명", field: "partner_name", sortable: true },
  {
    headerName: "쿠팡 X 애드팝콘 수수료율",
    field: "ap_commission_rate",
    sortable: true,
    valueFormatter: (params: ValueFormatterParams) => {
      return DataFormat.commissionRate.formatter(params.value || 0);
    },
  },
  {
    headerName: "쿠팡 X 파트너 수수료율",
    field: "client_commission_rate",
    sortable: true,
    valueFormatter: (params: ValueFormatterParams) => {
      return DataFormat.commissionRate.formatter(params.value || 0);
    },
  },
  {
    headerName: "Sub ID 개수",
    field: "subids",
    sortable: true,
    cellRenderer: (params: ICellRendererParams) => (
      <Link to={`/operation/coupang/subId?id=${params.data.partner_name}&accountNo=100`}>
        {params.data.subids}
      </Link>
    ),
  },
  {
    headerName: "Delete",
    field: "id",
    sortable: true,
    minWidth: 100,
    cellRenderer: (params: ICellRendererParams) => (
      <RemoveButton partnerNo={params.data.partner_no} />
    ),
  },
];

function RemoveButton({ partnerNo }: { partnerNo: number }) {
  const { mutate } = useDeletePartner();

  const onClickDeleteButton = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      mutate({ partnerNo });
    },
    [partnerNo, mutate]
  );

  return (
    <DeleteButton onConfirm={onClickDeleteButton}>
      정말 해당 파트너를 삭제하시겠습니까?
    </DeleteButton>
  );
}

export default PartnerBoard;
