import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  rewardCSNo: number;
  answer: string;
}

interface Response {
  code: 200 | 400 | 401 | 404 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "not-found" | "internal-server-error";
}

// 리워드 문의 답변
const usePutRewardCS = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ rewardCSNo, answer }) => {
      const response: AxiosResponse<Response> = await API.default.put(`/reward-cs/${rewardCSNo}`, {
        reward_cs_no: rewardCSNo,
        response: answer,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("답변이 등록되었습니다.");
        invalidate(["rewardCS/useGetRewardCSDetail", "rewardCS/useGetRewardCSList"]);
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePutRewardCS;
