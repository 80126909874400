import { Box, Button } from "@mui/material";
import { CellClickedEvent, ValueFormatterParams } from "ag-grid-community";
import { useCallback, useMemo, useState } from "react";
import { BasicTable } from "src/components/commons";
import useGetHeaderBiddingList, {
  HeaderBidding,
} from "src/hooks/apis/thirdparties/useGetHeaderBiddingList";
import useOpenModal from "src/hooks/useOpenModal";
import EditHeaderBiddingModal from "./EditHeaderBiddingModal";
import { partnerBoardStyle } from "./styles";
import WebPlacementModal from "./WebPlacementModal";

const HeaderBiddingBoard = () => {
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedHeaderBidding, setSelectedHeaderBidding] = useState<HeaderBidding>();
  const [openEditModal, onShowEditModal, onCloseEditModal] = useOpenModal(null);

  const { data } = useGetHeaderBiddingList({ pageNo, pageSize });

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, data.pages, onChangePage, pageSize, onChangePageSize]
  );

  const onCellClicked = useCallback(
    (e: CellClickedEvent) => {
      e.event?.preventDefault();
      if (e.colDef.field !== "placement_count") {
        onShowEditModal(e.event, null);
        setSelectedHeaderBidding(e.data);
      }
    },
    [onShowEditModal]
  );

  return (
    <Box css={partnerBoardStyle}>
      <BasicTable
        getRowId={(params) => params.data.id}
        animateRows
        rowData={data.thirdparties}
        columnDefs={columnDefs}
        {...paginationInfo}
        onCellClicked={onCellClicked}
      />
      {openEditModal.isOpen && (
        <EditHeaderBiddingModal
          headerBidding={selectedHeaderBidding}
          isOpen={openEditModal.isOpen}
          onClose={onCloseEditModal}
        />
      )}
    </Box>
  );
};

const columnDefs = [
  { headerName: "헤더비딩 명", field: "name", sortable: true },
  {
    headerName: "활성 플레이스먼트",
    field: "placement_count",
    minWidth: 350,
    sortable: true,
    cellRenderer: (params: { data: HeaderBidding }) => <ComapanyButton {...params.data} />,
  },
  {
    headerName: "시스템 수수료",
    field: "system_fee",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const systemFee = param.value;
      return `${systemFee} %`;
    },
  },
];

function ComapanyButton({ id, media_type, placement_count, ...props }: HeaderBidding) {
  const [openPlacementModal, onShowPlacementModal, onClosePlacementModal] = useOpenModal(id);

  return (
    <>
      <Button variant="text" onClick={onShowPlacementModal}>
        {`${placement_count} 개`}
      </Button>
      {openPlacementModal.isOpen && (
        <WebPlacementModal
          {...props}
          id={id}
          media_type={media_type}
          placement_count={placement_count}
          open={openPlacementModal}
          onClose={onClosePlacementModal}
        />
      )}
    </>
  );
}

export default HeaderBiddingBoard;
