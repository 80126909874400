import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { BasicTable, MultipleSelect, Select, TextField } from "src/components/commons";
import useGetCompanies, { CompanyData } from "src/hooks/apis/companies/useGetCompanies";
import useGetWebMediaList, { WebMedia } from "src/hooks/apis/media/useGetWebMediaList";
import useGetWebPlacementList, {
  WebPlacement,
} from "src/hooks/apis/placements/useGetWebPlacementList";
import { HeaderBidding } from "src/hooks/apis/thirdparties/useGetHeaderBiddingList";
import usePatchHeaderBiddingStatus from "src/hooks/apis/thirdparties/usePatchHeaderBiddingStatus";
import usePostHeaderBiddingMatch, {
  MatchKey,
  MatchKeyInfo,
} from "src/hooks/apis/thirdparties/usePostHeaderBiddingMatch";
import useOpenModal from "src/hooks/useOpenModal";
import { COMPANY, STATUS, Status } from "src/types";
import { MATCH, Match, MATCH_ALIAS } from "src/types/match";
import MatchingInfoModal from "./MatchingInfoModal";
import { headerBiddingPlacementModalStyle } from "./styles";

export interface MatchingPlacement {
  thirdpartyId: number;
  headerBiddingName: string;
  media_key: string;
  media_name: string;
  id: string;
  match_key: MatchKey;
  match_key_info: MatchKeyInfo[];
  name: string;
  passback_script: string;
  status: Status;
  type: number;
}

interface WebPlacementModalProps extends HeaderBidding {
  onClose: () => void;
  open: { key: number; isOpen: boolean };
}

const WebPlacementModal = ({ name, open, onClose }: WebPlacementModalProps) => {
  const [company, setCompany] = useState<CompanyData | null>(null);
  const [media, setMedia] = useState<WebMedia[]>([]);
  const [placement, setPlacement] = useState<WebPlacement[]>([]);
  const [matchingType, setMatchingType] = useState<Match>(MATCH.ALL);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const { data: companyData } = useGetCompanies({
    types: [
      COMPANY.CORPORATION,
      COMPANY.INDIVIDUAL,
      COMPANY.SYNDICATION,
      COMPANY.SYNDICATION_CLIENT_CORPORATION,
      COMPANY.SYNDICATION_CLIENT_INDIVIDUAL,
    ],
    pageNo: 1,
  });

  const { data: webMediaData } = useGetWebMediaList({
    companyKey: company?.key,
    disabled: company?.key ? false : true,
  });

  const { data: webPlacementData } = useGetWebPlacementList({
    mediaKeys: media.map((m) => m.key),
  });

  const { data: matchData } = usePostHeaderBiddingMatch({
    thirdpartyId: open.key,
    pageNo,
    pageSize,
    matchType: matchingType !== MATCH.ALL ? matchingType : undefined,
    mediaKeys: media.map((m) => m.key),
    placementIds: placement.map((p) => p.id),
  });

  // 초기 매체 세팅
  useEffect(() => {
    setMedia(webMediaData.media);
  }, [webMediaData.media]);

  // 초기 플레이스먼트 세팅
  useEffect(() => {
    setPlacement(webPlacementData.placements);
  }, [webPlacementData.placements]);

  const onSelectCompany = useCallback((_: SyntheticEvent<unknown>, v: CompanyData | null) => {
    setCompany(v);
  }, []);

  const onChangeMatchingType = useCallback((event: SelectChangeEvent<Match>) => {
    const value = +event.target.value as Match;
    setMatchingType(value);
  }, []);

  const onChangeMedia = useCallback((values: WebMedia[]) => {
    setMedia(values);
  }, []);

  const onChangePlacement = useCallback((values: WebPlacement[]) => {
    setPlacement(values);
  }, []);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: matchData.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, matchData.pages, onChangePage, pageSize, onChangePageSize]
  );

  const rowData = matchData.thirdparties.reduce(
    (acc: MatchingPlacement[], { media_key, media_name, placements }) => {
      if (placements.length > 0) {
        acc = [
          ...acc,
          ...placements.map((placement) => ({
            thirdpartyId: open.key,
            headerBiddingName: name,
            media_key: media_key,
            media_name: media_name,
            ...placement,
          })),
        ];
      }
      return acc;
    },
    []
  );

  return (
    <Dialog
      css={headerBiddingPlacementModalStyle}
      maxWidth="lg"
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-company"
      aria-describedby="change company"
    >
      <DialogTitle id="dialog-title">{name}</DialogTitle>
      <DialogContent>
        <Grid className="ssp-tools" container spacing={2}>
          <Grid item xs={3}>
            <Autocomplete
              className="field"
              size="small"
              options={companyData.companies}
              getOptionLabel={(company) => company.name}
              renderOption={(props, company) => {
                return (
                  <li {...props} key={company.id}>
                    {company.name}
                  </li>
                );
              }}
              renderInput={({ InputLabelProps, ...params }) => (
                <TextField
                  {...params}
                  label="업체 명"
                  placeholder="업체를 선택해주세요."
                  required
                />
              )}
              isOptionEqualToValue={(company, value) => company.id === value.id}
              value={company}
              onChange={onSelectCompany}
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              label="매칭 상태"
              placeholder="매칭 상태"
              onChange={onChangeMatchingType}
              value={matchingType}
            >
              <MenuItem value={MATCH.ALL}>{MATCH_ALIAS[MATCH.ALL]}</MenuItem>
              <MenuItem value={MATCH.MATCHED}>{MATCH_ALIAS[MATCH.MATCHED]}</MenuItem>
              <MenuItem value={MATCH.NOT_MATCHED}>{MATCH_ALIAS[MATCH.NOT_MATCHED]}</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>
            <MultipleSelect
              className="field"
              options={webMediaData.media}
              label="매체"
              placeholder="매체 선택"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.key}
              onChange={onChangeMedia}
              value={media}
            />
          </Grid>
          <Grid item xs={3}>
            <MultipleSelect
              className="field"
              options={webPlacementData.placements}
              label="플레이스먼트"
              placeholder="플레이스먼트 선택"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={onChangePlacement}
              value={placement}
            />
          </Grid>
        </Grid>
        <BasicTable
          animateRows
          rowData={rowData}
          columnDefs={columnDefs}
          {...paginationInfo}
          //   onCellClicked={onCellClicked}
        />
      </DialogContent>
    </Dialog>
  );
};

const columnDefs = [
  {
    headerName: "헤더비딩 명",
    field: "headerBiddingName",
    sortable: true,
  },
  {
    headerName: "매체명",
    field: "media_name",
    sortable: true,
  },
  {
    headerName: "플레이스먼트 명",
    field: "name",
    sortable: true,
  },
  {
    headerName: "매칭정보",
    cellRenderer: (params: { data: MatchingPlacement }) => {
      return params.data.match_key_info && params.data.match_key_info.length > 0 ? (
        <MatchingButton {...params.data} />
      ) : null;
    },
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
  },
  {
    headerName: "상태",
    field: "status",
    sortable: true,
    cellRenderer: (params: { data: MatchingPlacement }) => <StatusSwitch {...params.data} />,
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

function MatchingButton({ thirdpartyId, id, match_key, ...props }: MatchingPlacement) {
  const [openMatchingInfoModal, onShowMatchingInfoModal, onCloseMatchingInfoModal] =
    useOpenModal(id);

  const isInit = useMemo(
    () => !(match_key && Object.values(match_key).some((v) => Boolean(v))),
    [match_key]
  );
  const label = useMemo(() => (isInit ? "등록하기" : "변경하기"), [isInit]);

  return (
    <>
      <Button
        variant="text"
        size="small"
        color={isInit ? "inherit" : "primary"}
        onClick={onShowMatchingInfoModal}
      >
        {label}
      </Button>
      {openMatchingInfoModal.isOpen && (
        <MatchingInfoModal
          {...props}
          open={openMatchingInfoModal}
          id={id}
          thirdpartyId={thirdpartyId}
          match_key={match_key}
          onClose={onCloseMatchingInfoModal}
        />
      )}
    </>
  );
}

function StatusSwitch({ id, thirdpartyId, status }: MatchingPlacement) {
  const { mutate } = usePatchHeaderBiddingStatus();
  const onClickSwitch = useCallback(() => {
    mutate({
      placementId: id,
      thirdpartyId: thirdpartyId,
      status: status === STATUS.ACTIVE ? STATUS.SUSPEND : STATUS.ACTIVE,
    });
  }, [id, mutate, status, thirdpartyId]);

  return <Switch onClick={onClickSwitch} checked={status === STATUS.ACTIVE}></Switch>;
}

export default WebPlacementModal;
