import { Box, Button, Grid, MenuItem, SelectChangeEvent, Switch } from "@mui/material";
import { CellClickedEvent, ValueFormatterParams } from "ag-grid-community";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { BasicTable, Select } from "src/components/commons";
import useGetDSPList, { DSP } from "src/hooks/apis/thirdparties/useGetDSPList";
import usePatchDSPAll from "src/hooks/apis/thirdparties/usePatchDSPAll";
import useOpenModal from "src/hooks/useOpenModal";
import { Campaign, CAMPAIGN_ALIAS, Media, MEDIA, MEDIA_ALIAS, STATUS } from "src/types";
import AppPlacementModal from "./AppPlacementModal";
import DSPStatusModal from "./DSPStatusModal";
import EditDSPModal from "./EditDSPModal";
import { partnerBoardStyle } from "./styles";
import WebPlacementModal from "./WebPlacementModal";

const DSPBoard = () => {
  const [media, setMedia] = useState<Media>(MEDIA.APP);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedDSP, setSelectedDSP] = useState<DSP>();
  const [openEditModal, onShowEditModal, onCloseEditModal] = useOpenModal(null);
  const [openStatusModal, onShowStatusModal, onCloseStatusModal] = useOpenModal(null);

  const { data } = useGetDSPList({ type: media, pageNo, pageSize });

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const onChangeMedia = useCallback((event: SelectChangeEvent<Media>) => {
    const value = +event.target.value as Media;
    setMedia(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, data.pages, onChangePage, pageSize, onChangePageSize]
  );

  const onCellClicked = useCallback(
    (e: CellClickedEvent) => {
      e.event?.preventDefault();
      if (e.colDef.field !== "activated" && e.colDef.field !== "id") {
        onShowEditModal(e.event, null);
        setSelectedDSP(e.data);
      }
    },
    [onShowEditModal]
  );

  return (
    <Box css={partnerBoardStyle}>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={3}>
          <Select
            label="미디어"
            placeholder="미디어를 선택해주세요."
            onChange={onChangeMedia}
            value={media}
          >
            <MenuItem value={MEDIA.APP}>{MEDIA_ALIAS[MEDIA.APP]}</MenuItem>
            <MenuItem value={MEDIA.WEB}>{MEDIA_ALIAS[MEDIA.WEB]}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={9} className="register">
          <Button variant="outlined" onClick={onShowStatusModal}>
            DSP 상태 관리
          </Button>
        </Grid>
      </Grid>
      <BasicTable
        getRowId={(params) => params.data.id}
        animateRows
        rowData={data.thirdparties}
        columnDefs={columnDefs}
        {...paginationInfo}
        onCellClicked={onCellClicked}
      />
      {openEditModal.isOpen && (
        <EditDSPModal dsp={selectedDSP} isOpen={openEditModal.isOpen} onClose={onCloseEditModal} />
      )}
      {openStatusModal.isOpen && (
        <DSPStatusModal isOpen={openStatusModal.isOpen} onClose={onCloseStatusModal} />
      )}
    </Box>
  );
};

const columnDefs = [
  { headerName: "DSP 명", field: "name", sortable: true },
  {
    headerName: "지원 광고 형식",
    field: "campaign_types",
    minWidth: 350,
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const campaignTypes = param.value as Campaign[];
      return campaignTypes.map((campaign) => CAMPAIGN_ALIAS[campaign]).join(" | ");
    },
  },
  {
    headerName: "업체",
    field: "id",
    sortable: true,
    cellRenderer: (params: { data: DSP }) => <ComapanyButton {...params.data} />,
  },
  {
    headerName: "시스템 수수료",
    field: "system_fee",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const systemFee = param.value;
      return `${systemFee} %`;
    },
  },
  {
    headerName: "상태",
    field: "activated",
    sortable: true,
    cellRenderer: (params: { data: DSP }) => <DSPASwitch {...params.data} />,
  },
];

function ComapanyButton({ id, media_type, ...props }: DSP) {
  const [openPlacementModal, onShowPlacementModal, onClosePlacementModal] = useOpenModal(id);

  return (
    <>
      <Button variant="text" onClick={onShowPlacementModal}>
        보기
      </Button>
      {media_type === MEDIA.APP
        ? openPlacementModal.isOpen && (
            <AppPlacementModal
              id={id}
              media_type={media_type}
              {...props}
              open={openPlacementModal}
              onClose={onClosePlacementModal}
            />
          )
        : openPlacementModal.isOpen && (
            <WebPlacementModal
              id={id}
              media_type={media_type}
              {...props}
              open={openPlacementModal}
              onClose={onClosePlacementModal}
            />
          )}
    </>
  );
}

function DSPASwitch({ id, activated }: DSP) {
  const { mutate } = usePatchDSPAll();

  const onChangeStatus = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      // status 10: 일시정지, status 30: 활성
      const value = checked ? STATUS.SUSPEND : STATUS.ACTIVE;
      mutate({ thirdpartyId: id, status: value });
    },
    [id, mutate]
  );

  return <Switch checked={activated} onChange={onChangeStatus} />;
}

export default DSPBoard;
