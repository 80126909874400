import { Box, Card, CardActions, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import useGetActId from "src/hooks/apis/auth/useGetActId";
import { findEmailResultStyle } from "./styles";

interface FindEmailResultProps {
  name: string;
  companyId?: number;
}

const FindEmailResult = ({ name, companyId }: FindEmailResultProps) => {
  const { data } = useGetActId({ name, companyId: companyId ? `${companyId}` : "" });
  return (
    <Box css={findEmailResultStyle}>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography variant="h6" component="div">
            이 계정을 찾으시나요?
          </Typography>
          <Typography className="result" color="text.secondary" component="ul" gutterBottom>
            {data.account_ids.length > 0
              ? data.account_ids.map((v, idx) => (
                  <li key={idx}>{`${v.split("@")[0].slice(0, 6)}${v
                    .split("@")[0]
                    .slice(6)
                    .replace(/./gi, "*")}@${v.split("@")[1].slice(0, 6)}${v
                    .split("@")[1]
                    .slice(6)
                    .replace(/./gi, "*")}`}</li>
                ))
              : "계정이 없습니다."}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            * 소속회사내 동명이인이 있는 경우 본인인증 절차를 진행합니다.
          </Typography>
        </CardContent>
        <CardActions>
          <Link className="forgot" to="/login/find/password">
            비밀번호 찾기
          </Link>
        </CardActions>
      </Card>
    </Box>
  );
};

export default FindEmailResult;
