import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { MutationFunction, useMutation, UseMutationOptions } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";
import { NpayApp } from "./use-npay-app-list";

export type CreateNpayAppValues = Pick<
  NpayApp,
  "company_key" | "key" | "commission_rate" | "daily_cap" | "point" | "status"
>;

type NpayAppMutationKey = "operation/npay/add-app";
type NpayAppMutationFn = MutationFunction<AxiosResponse, CreateNpayAppValues>;

type NpayAppMutationOptions = Omit<
  UseMutationOptions<AxiosResponse, AxiosError, CreateNpayAppValues>,
  "mutationKey" | "mutationFn"
>;

export default function useCreateNpayApp(options?: NpayAppMutationOptions) {
  const { handleError } = useApiError();

  const mutationKey = useMemo<NpayAppMutationKey>(() => "operation/npay/add-app", []);

  const mutateFn = useCallback<NpayAppMutationFn>((values) => {
    const cookie = parseCookies();

    return API.consoleV3.post(`/v3/naverpay/media`, values, {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
    });
  }, []);

  const mutation = useMutation(mutationKey, mutateFn, {
    onError: handleError,
    ...options,
  });

  return mutation;
}
