import { useMemo } from "react";
import { ROLE } from "src/types";
import useGetUserDetail from "./apis/users/useGetUserDetail";

const useUser = () => {
  const { data } = useGetUserDetail({ userId: "me" });

  const isAdmin = useMemo(() => {
    if (data?.user.role === ROLE.IGAWorksAdministrator || data?.user.role === ROLE.IGAWorksOperator)
      return true;
    return false;
  }, [data?.user.role]);

  const isSyndication = useMemo(() => {
    if (
      data?.user.role === ROLE.SyndicationAdministrator ||
      data?.user.role === ROLE.SyndicationOperator
    )
      return true;
    return false;
  }, [data?.user.role]);

  const userId = useMemo(() => data?.user.id, [data?.user.id]);

  return { isAdmin, isSyndication, userId };
};

export default useUser;
