import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  password: string;
  newPassword: string;
}

interface Response {
  code: 200 | 400 | 401 | 403 | 404;
  text: "ok" | "bad-request" | "unauthorized" | "forbidden" | "not-found";
}

// 비밀번호 변경
const usePutPassword = () => {
  const { handleError } = useApiError({
    400: (_error: AxiosError) => {
      toast.error("기존 비밀번호와 동읿합니다. (status: 400)");
    },
  });
  const toast = useToast();

  const result = useMutation<Response, AxiosError, Params>(
    async ({ password, newPassword }) => {
      const response: AxiosResponse<Response> = await API.default.put("/auth/password", {
        current_password: password,
        new_password: newPassword,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("비밀번호가 변경되었습니다.");
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePutPassword;
