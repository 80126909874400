import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { CAMPAIGN, Campaign, STATUS, Status, Template } from "src/types";
import API from "src/utils/api";

interface Params {
  bidfloor?: number;
  bidfloorCurrency?: string;
  bidfloorStatus?: Status;
  checkViewability?: boolean;
  cpcBidfloor?: number;
  cpcBidfloorCurrency?: string;
  cpcBidfloorStatus?: Status;
  ctaTextRequired?: boolean;
  descRequired?: boolean;
  iconImageRequired?: boolean;
  isSDKMediation?: boolean;
  mainImageRequired?: boolean;
  maxDuration?: number;
  mediaKey: string;
  minDuration?: number;
  minViewTime?: number;
  mute?: boolean;
  name: string;
  rewardCurrency?: string;
  rewardValue?: number;
  sizeType?: number;
  titleRequired?: boolean;
  type: Campaign;
  // 컴패니언 혹은 전면/배너 네이티브
  parentId?: string;
  // 컴패니언
  companionUse?: boolean;
  templateNo?: Template;
  // 전면/배너 네이티브
  subNativeUse?: boolean;
  // Campaign 1 ~ 6
  isRewardCpc?: boolean;
}

interface Response {
  code: 200 | 400 | 401 | 409 | 500;
  data?: {
    placement: {
      bidfloor: number;
      bidfloor_currency: string;
      bidfloor_status: Status;
      check_viewability: boolean;
      cpc_bidfloor: number;
      cpc_bidfloor_currency: string;
      cpc_bidfloor_status: Status;
      created_at: number;
      ctatext_required: boolean;
      desc_required: boolean;
      icon_image_required: boolean;
      id: string;
      is_sdk_mediation: boolean;
      main_image_required: boolean;
      maxduration: number;
      media_key: string;
      minduration: number;
      minviewtime: number;
      mute: boolean;
      name: string;
      reward_currency: string;
      reward_value: number;
      size_type: number;
      status: Status;
      title_required: boolean;
      type: Campaign;
      updated_at: number;
      // Campaign 1 ~ 6
      is_reward_cpc?: boolean;
    };
  };
  text: "ok" | "bad-request" | "unauthorized" | "conflict" | "internal-server-error";
}

// 플레이스먼트 신규 생성
// 매체에 같은 플랫폼, 이름의 플레이스먼트가 있을 경우 이름 뒤에 _{EpochTime}을 붙인다.
// 타입별로 필수 데이터가 다름
// Banner(1), Interstitial(2) - {"size_type":1}
// Native(3) - {"size_type":1,"main_image_required":true,"icon_image_required":true,"title_required": true,"desc_required":true,"ctatext_required":true}
// Reward Video(4) - {"reward_value": 1.0,"reward_currency":"\ubcf4\uae30","minduration": 0,"maxduration":100}
// Interstitial Video(6) - {"size_type":1,"minduration": 0,"maxduration":100,"minviewtime":0}
const usePostAppPlacement = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const { data, ...rest } = useMutation<Response, AxiosError, Params>(
    async ({
      bidfloor,
      bidfloorCurrency,
      bidfloorStatus,
      checkViewability,
      cpcBidfloor,
      cpcBidfloorCurrency,
      cpcBidfloorStatus,
      ctaTextRequired,
      descRequired,
      iconImageRequired,
      isSDKMediation,
      mainImageRequired,
      maxDuration,
      mediaKey,
      minDuration,
      minViewTime,
      mute,
      name,
      rewardCurrency,
      rewardValue,
      sizeType,
      titleRequired,
      type,
      // 컴패니언 혹은 전면/배너 네이티브
      parentId,
      templateNo,
      // 컴패니언
      companionUse,
      // 전면/배너 네이티브
      subNativeUse,
      // Campaign 1~6
      isRewardCpc,
    }) => {
      const response: AxiosResponse<Response> = await API.default.post(`/placements/app`, {
        bidfloor: bidfloor,
        bidfloor_currency: bidfloorCurrency,
        bidfloor_status: bidfloorStatus,
        check_viewability: checkViewability,
        cpc_bidfloor: cpcBidfloor,
        cpc_bidfloor_currency: cpcBidfloorCurrency,
        cpc_bidfloor_status: cpcBidfloorStatus,
        ctatext_required: ctaTextRequired,
        desc_required: descRequired,
        icon_image_required: iconImageRequired,
        is_sdk_mediation: isSDKMediation,
        main_image_required: mainImageRequired,
        maxduration: maxDuration,
        media_key: mediaKey,
        minduration: minDuration,
        minviewtime: minViewTime,
        mute: mute,
        name: name,
        reward_currency: rewardCurrency,
        reward_value: rewardValue,
        size_type: sizeType,
        title_required: titleRequired,
        type: type,
        // 컴패니언 혹은 전면/배너 네이티브
        parent_p_id: parentId,
        template_no: templateNo,
        // 컴패니언
        companion_use: companionUse,
        // 전면/배너 네이티브
        sub_native_use: subNativeUse,
        // Campaign 1~6
        is_reward_cpc: isRewardCpc,
      });
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("플레이스먼트가 등록되었습니다.");
        invalidate(["placements/useGetAppPlacementDetail", "placements/useGetAppPlacementList"]);
      },
    }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            placement: {
              bidfloor: 0,
              bidfloor_currency: "USD",
              bidfloor_status: STATUS.SUSPEND,
              check_viewability: false,
              cpc_bidfloor: 0,
              cpc_bidfloor_currency: "USD",
              cpc_bidfloor_status: STATUS.SUSPEND,
              created_at: 0,
              ctatext_required: false,
              desc_required: false,
              icon_image_required: false,
              id: "",
              is_sdk_mediation: false,
              main_image_required: false,
              maxduration: 0,
              media_key: "",
              minduration: 0,
              minviewtime: 0,
              mute: false,
              name: "",
              reward_currency: "",
              reward_value: 0,
              size_type: 0,
              status: STATUS.SUSPEND,
              title_required: false,
              type: CAMPAIGN.Banner,
              updated_at: 0,
              is_reward_cpc: false,
            },
          },
    [data]
  );

  return { data: result, ...rest };
};

export default usePostAppPlacement;
