import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import { MouseEvent, useMemo } from "react";
import { BsPlusSquare } from "react-icons/bs";
import { TextField } from "src/components/commons";
import useGetWebMedia from "src/hooks/apis/media/useGetWebMedia";
import usePatchWeb from "src/hooks/apis/media/usePatchWeb";
import useOpenModal from "src/hooks/useOpenModal";
import { WEB_PLATFORM_ALIAS } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import BlockDomainModal from "./BlockDomainModal";
import { editMediaModalStyle } from "./styles";

interface EditMediaModalProps {
  onClose: (e: MouseEvent) => void;
  open: { key: string; isOpen: boolean };
}

const EditMediaModal = ({ onClose, open }: EditMediaModalProps) => {
  const [openBlockDomainModal, onShowBlockDomainModal, onCloseBlockDomainModal] = useOpenModal(
    open.key
  );

  const { data } = useGetWebMedia({ mediaKey: open.key });
  const { mutate } = usePatchWeb();

  const initialValues = useMemo(
    () => ({
      name: data.media.name,
      platformType: WEB_PLATFORM_ALIAS[data.media.platform_type],
      domain: data.media.domain,
      key: data.media.key,
      placementCount: data.media.placement_count,
      blockDomainListSet: data.media.block_domain_list_set,
      createdAt: moment(data.media.created_at * 1000).format("YYYY-MM-DD"),
    }),
    [
      data.media.block_domain_list_set,
      data.media.created_at,
      data.media.domain,
      data.media.key,
      data.media.name,
      data.media.placement_count,
      data.media.platform_type,
    ]
  );

  const validationSchema = yup.object({
    name: yup.string().required("웹 매체 명을 입력해주세요."),
    domain: yup
      .string()
      .required("도메인을 입력해주세요.")
      .matches(REG_EXP.domain, "올바른 도메인을 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate({
        mediaKey: values.key,
        name: values.name,
        platformType: data.media.platform_type,
        domain: values.domain,
        status: data.media.status,
      });
    },
  });

  return (
    <Dialog
      css={editMediaModalStyle}
      fullWidth
      maxWidth="md"
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-media"
      aria-describedby="edit media"
    >
      <DialogTitle id="dialog-title">{data.media.name}</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="edit-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="웹 매체 명"
                placeholder="등록하실 웹 매체 명을 입력하세요."
                required
                {...getFieldProps("name")}
                error={shouldErrorShows("name", touched, errors)}
                helperText={getHelperText("name", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="플랫폼"
                required
                disabled
                {...getFieldProps("platformType")}
                error={shouldErrorShows("platformType", touched, errors)}
                helperText={getHelperText("platformType", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="메인 도메인"
                placeholder="https://www.example.com"
                required
                {...getFieldProps("domain")}
                error={shouldErrorShows("domain", touched, errors)}
                helperText={getHelperText("domain", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="매체키"
                required
                disabled
                {...getFieldProps("key")}
                error={shouldErrorShows("key", touched, errors)}
                helperText={getHelperText("key", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="플레이스먼트"
                required
                disabled
                {...getFieldProps("placementCount")}
                error={shouldErrorShows("placementCount", touched, errors)}
                helperText={getHelperText("placementCount", touched, errors)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                className="field"
                label="등록일"
                required
                disabled
                {...getFieldProps("createdAt")}
                error={shouldErrorShows("createdAt", touched, errors)}
                helperText={getHelperText("createdAt", touched, errors)}
              />
            </Grid>
            <Grid item xs={12} className="remote-tools">
              <Button variant="contained" size="small" onClick={onShowBlockDomainModal}>
                <BsPlusSquare />
                차단 URL 그룹 설정
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-form" type="submit">
          저장
        </Button>
      </DialogActions>
      {openBlockDomainModal.isOpen && (
        <BlockDomainModal open={openBlockDomainModal} onClose={onCloseBlockDomainModal} />
      )}
    </Dialog>
  );
};

export default EditMediaModal;
