import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { PaymentStatus, Settlement, TaxBillStatus, TAX_BILL_STATUS } from "src/types";
import API from "src/utils/api";

export interface Params {
  paymentId?: number;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    end_date: string;
    exchange_rate: number;
    media_revenue: number;
    month: string;
    payment_commission_rate: number;
    payment_date: number;
    payment_id: number;
    payment_revenue: number;
    payment_status: PaymentStatus;
    payment_type: Settlement;
    payment_user_name: string;
    start_date: string;
    tax_bill_date: number;
    tax_bill_revenue: number;
    tax_bill_status: TaxBillStatus;
    tax_bill_user_name: string;
  }[];
  text: "ok" | "bad-request" | "unauthorized";
}

// 정산관리 > 세부내역 조회
const useGetPaymentDetail = ({ paymentId }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["payments/useGetPaymentDetail", paymentId],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/payments/${paymentId}`);
      return response.data.data;
    },
    { enabled: !!paymentId, onError: handleError }
  );

  const result = useMemo(() => {
    if (data) {
      const sortedData = data.sort((a, b) => (a.month < b.month ? 1 : -1));

      return sortedData.map((v) => ({
        ...v,
        parent_id: paymentId,
        group: false,
        isConfirmed: sortedData[0].tax_bill_status === TAX_BILL_STATUS.CONFIRMED,
      }));
    }
    return [];
  }, [data, paymentId]);

  return { data: result, ...rest };
};

export default useGetPaymentDetail;
