import { Button, Dialog, DialogContent, DialogTitle, Grid, Switch } from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from "react";
import { BasicTable, DeleteButton, TextField } from "src/components/commons";
import useDeleteTestDevice from "src/hooks/apis/testDevices/useDeleteTestDevice";
import useGetTestDeviceList, { TestDevice } from "src/hooks/apis/testDevices/useGetTestDeviceList";
import usePatchTestDevice from "src/hooks/apis/testDevices/usePatchTestDevice";
import usePostTestDevice from "src/hooks/apis/testDevices/usePostTestDevice";
import { STATUS, Status } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { testDeviceModalStyle } from "./styles";

interface EditBlockURLModalProps {
  onClose: () => void;
  open: { key: string; isOpen: boolean };
}

const EditBlockURLModal = ({ onClose, open }: EditBlockURLModalProps) => {
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { data } = useGetTestDeviceList({ mediaKey: open.key, pageNo, pageSize });
  const { mutate: addTestDevice } = usePostTestDevice();

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [data.pages, onChangePage, onChangePageSize, pageNo, pageSize]
  );

  const initialValues = useMemo(() => {
    return { identifier: "" };
  }, []);

  const validationSchema = yup.object({
    identifier: yup
      .string()
      .matches(REG_EXP.testDevice, "올바른 테스트 디바이스를 입력해주세요.")
      .required("테스트 디바이스를 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      addTestDevice({ mediaKey: open.key, identifier: values.identifier });
      onClose();
    },
  });

  return (
    <Dialog
      css={testDeviceModalStyle}
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-test-device"
      aria-describedby="edit test device"
    >
      <DialogTitle id="dialog-title">테스트 디바이스</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                className="field"
                label="테스트 디바이스"
                placeholder="ADID 혹은 IDFA를 입력해주세요."
                required
                suffix={
                  <Button color="primary" aria-label="directions" type="submit">
                    추가하기
                  </Button>
                }
                {...getFieldProps("identifier")}
                error={shouldErrorShows("identifier", touched, errors)}
                helperText={getHelperText("identifier", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <BasicTable rowData={data.test_devices} columnDefs={columnDefs} {...paginationInfo} />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const columnDefs = [
  { headerName: "테스트 디바이스", field: "identifier", sortable: true },
  {
    headerName: "활성 상태",
    field: "status",
    sortable: true,
    cellRenderer: (params: { data: TestDevice }) => (
      <BlockCategorySwitch no={params.data.no} status={params.data.status} />
    ),
  },
  {
    headerName: "Delete",
    field: "no",
    sortable: true,
    cellRenderer: (params: { data: TestDevice }) => <RemoveButton no={params.data.no} />,
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

function RemoveButton({ no }: { no: number }) {
  const { mutate } = useDeleteTestDevice();

  const onClickDeleteButton = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      mutate({ no });
    },
    [mutate, no]
  );

  return (
    <DeleteButton onConfirm={onClickDeleteButton}>
      정말 해당 테스트 디바이스를 삭제하시겠습니까?
    </DeleteButton>
  );
}

function BlockCategorySwitch({ no, status }: { no: number; status: Status }) {
  const toggleValue = useMemo(() => (status === STATUS.ACTIVE ? true : false), [status]);
  const { mutate } = usePatchTestDevice();

  const onUpdateCategoryStatus = useCallback(
    ({ no, status }: { no: number; status: Status }) => {
      mutate({ no, status });
    },
    [mutate]
  );

  const onChangeStatus = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const value = checked ? STATUS.ACTIVE : STATUS.SUSPEND;
      onUpdateCategoryStatus({ no: no, status: value });
    },
    [no, onUpdateCategoryStatus]
  );

  return <Switch checked={toggleValue} onChange={onChangeStatus} />;
}

export default EditBlockURLModal;
