import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Status } from "src/types";
import { Campaign } from "src/types/campaign";
import API from "src/utils/api";

interface Params {
  mediaKeys?: string[];
  name?: string;
  types?: Campaign;
  status?: Status;
  pageNo?: number;
  pageSize?: number;
  orders?: string[];
  forReport?: boolean;
}

export interface AppPlacement {
  bidfloor: number;
  bidfloor_currency: string;
  bidfloor_status: number;
  check_viewability: boolean;
  cpc_bidfloor: number;
  cpc_bidfloor_currency: string;
  cpc_bidfloor_status: number;
  created_at: number;
  ctatext_required: boolean;
  desc_required: boolean;
  icon_image_required: boolean;
  id: string;
  is_sdk_mediation: boolean;
  main_image_required: boolean;
  maxduration: number;
  media_key: string;
  minduration: number;
  minviewtime: number;
  mute: boolean;
  name: string;
  reward_currency: string;
  reward_value: number;
  size_type: number;
  status: Status;
  title_required: boolean;
  type: Campaign;
  updated_at: number;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    pages: number;
    placements: AppPlacement[];
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 플레이스먼트 리스트 조회
// 타입별로 추가 필드가 있음
// Banner(1), Interstitial(2) - {"size_type":1}
// Native(3) - {"size_type":1,"main_image_required":true,"icon_image_required":true,"title_required": true,"desc_required":true,"ctatext_required":true}
// Reward Video(4) - {"reward_value": 1.0,"reward_currency":"\ubcf4\uae30","minduration": 0,"maxduration":100}
// Interstitial Video(5) - {"size_type":1,"minduration": 0,"maxduration":100,"minviewtime":0}

const useGetAppPlacementList = ({
  mediaKeys,
  name,
  types,
  status,
  pageNo = 1,
  pageSize = 100000,
  orders,
  forReport = false,
}: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["placements/useGetAppPlacementList", mediaKeys, name, pageNo, pageSize, forReport, orders],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/placements/app`, {
        params: {
          media_keys: mediaKeys?.join(","),
          name: name,
          types: types,
          status,
          page_no: pageNo,
          page_size: pageSize,
          orders: orders?.join(","),
          for_report: forReport,
        },
      });
      return response.data.data;
    },
    { enabled: !!mediaKeys && mediaKeys.length > 0, onError: handleError }
  );

  const result = useMemo(() => (data ? data : { pages: 0, placements: [] }), [data]);

  return { data: result, ...rest };
};

export default useGetAppPlacementList;
