import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";
import { NpayTransaction } from "./use-npay-transactions";

export type FailedNpayTransaction = Omit<NpayTransaction, "point" | "status" | "updated_at">;

export type FailedNpayTransactionFields =
  | keyof FailedNpayTransaction
  | `-${keyof FailedNpayTransaction}`;

export type FailedNpayTransactionListParams = {
  since: string; // yyyyMMdd
  until: string; // yyyyMMdd
  page_no?: number;
  page_size?: number;
  orders?: FailedNpayTransactionFields[];
};

export enum FailedNpayTransactionStatus {
  Requested = 0,
  Fail = 25,
  Canceled = 30,
  Completed = 99,
}

type FailedNpayTransactionListData = {
  code: number;
  text: string;
  data: {
    page_no: number;
    page_size: number;
    pages: number;
    rows: FailedNpayTransaction[];
  };
};

type FailedNpayTransactionListQueryKey = [
  "operation/npay/failed-transaction-list",
  FailedNpayTransactionListParams
];

type FailedNpayTransactionListQueryFn = QueryFunction<
  AxiosResponse<FailedNpayTransactionListData>,
  FailedNpayTransactionListQueryKey
>;

type FailedNpayTransactionListQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<FailedNpayTransactionListData>,
    AxiosError,
    AxiosResponse<FailedNpayTransactionListData>,
    FailedNpayTransactionListQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: FailedNpayTransactionListData = {
  code: 200,
  text: "OK",
  data: {
    page_no: 1,
    page_size: 1,
    pages: 1,
    rows: [],
  },
};

export default function useFailedNpayTransactionList(
  params: FailedNpayTransactionListParams,
  options?: FailedNpayTransactionListQueryOptions
) {
  const { handleError } = useApiError();

  const queryKey = useMemo<FailedNpayTransactionListQueryKey>(
    () => ["operation/npay/failed-transaction-list", params],
    [params]
  );

  const queryFn = useCallback<FailedNpayTransactionListQueryFn>((context) => {
    const [, params] = context.queryKey;
    const cookie = parseCookies();

    return API.consoleV3.get("/v3/naverpay/transactions/fraud", {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
      params,
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
