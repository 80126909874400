import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation } from "react-query";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import useApiError from "src/hooks/apis/useApiError";
import useToast from "src/hooks/useToast";
import { UserRole } from "src/types";
import { Authority } from "src/types/authority";
import API from "src/utils/api";
import useGetUserDetail from "./useGetUserDetail";

interface Params {
  authority?: Omit<Authority, "id" | "type">;
  email: string;
  message: string;
  role: UserRole;
}

interface Response {
  code: 200 | 400 | 401 | 409 | 500;
  data?: {
    token: string;
  };
  text: "ok" | "bad-request" | "unauthorized" | "conflict" | "internal-server-error";
}

/*
  사용자 초대
  사용자 초대 메일을 전송하고, 수락하기 버튼 클릭 시 회원가입 페이지로 이동할때 생성된 토큰값을 전달한다.
  authority를 전달하지 않을 경우 해당 역할(role)의 기본 권한으로 설정한다. (authority.id, authority.type 전달 필요없음)
*/
const usePostUserInvite = () => {
  const toast = useToast();
  const [company] = useRecoilState(viewerCompany);
  const { data: userData } = useGetUserDetail({ userId: "me" });
  const companyId = company.id || userData.user.company_id;
  const { handleError } = useApiError({
    409: (_erros: AxiosError) => toast.error("이미 초대된 사용자입니다."),
  });
  const { data, ...rest } = useMutation<Response, AxiosError, Params>(
    async ({ authority, email, message, role }) => {
      const response: AxiosResponse<Response> = await API.default.post(`/users/invite`, {
        authority,
        company_id: companyId,
        email, // 통합계정에 가입된 경우, 가입된 이메일 찾아서 이름까지 포함한 템플릿으로 초대 메일 발송
        message,
        role,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("초대 메일이 발송되었습니다.");
      },
      onError: handleError,
    }
  );

  const result = useMemo(() => (data ? data : { token: "" }), [data]);

  return { data: result, ...rest };
};

export default usePostUserInvite;
