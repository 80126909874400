import { Box, Button, Popover, Typography } from "@mui/material";
import { MouseEvent, ReactNode, useCallback, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { deleteButtonStyle, popoverStyle } from "./styles";

interface DeleteButtonProps {
  onConfirm: (e: MouseEvent) => void;
  children: ReactNode;
}

const DeleteButton = ({ onConfirm, children }: DeleteButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onOpen = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onDelete = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onConfirm(e);
      setAnchorEl(null);
    },
    [onConfirm]
  );

  return (
    <>
      <Button
        css={deleteButtonStyle}
        color="error"
        variant="outlined"
        size="small"
        startIcon={<AiOutlineDelete />}
        onClick={onOpen}
      >
        DELETE
      </Button>
      <Popover
        css={popoverStyle}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: "center",
        }}
      >
        <Box className="content">
          <Typography variant="body1" className="message">
            {children}
          </Typography>
          <Box className="footer">
            <Button onClick={onClose} color="inherit">
              취소
            </Button>
            <Button onClick={onDelete} color="error">
              삭제
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default DeleteButton;
