import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useSetRecoilState } from "recoil";
import { uid2Agreement } from "src/atoms/uid2Agreement";
import useApiError from "src/hooks/apis/useApiError";
import { ROLE, STATUS, Status, UserRole } from "src/types";
import API from "src/utils/api";

export interface User {
  account_id: string;
  authority_id: number;
  company_id: number;
  company_key: string;
  created_at: number;
  department: string;
  id: number;
  job: string;
  last_activated_at: number;
  phone_no: string;
  name: string;
  promotion_agreement: boolean;
  role: UserRole;
  status: Status;
  syndication_id: number;
  updated_at: number;
}

interface Params {
  userId: "me" | number;
}

interface Response {
  code: 200 | 400 | 401 | 404;
  data?: {
    user: User;
  };
  text: "ok" | "bad-request" | "unauthorized" | "not-found";
}

/*
  사용자 정보 조회
*/
const useGetUserDetail = ({ userId }: Params) => {
  const { handleError } = useApiError();
  const setUid2Agreement = useSetRecoilState(uid2Agreement);
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["users/useGetUserDetail", userId],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/users/${userId}`);
      return response.data.data;
    },
    {
      enabled: !!userId,
      onError: handleError,
      onSuccess: (data) => {
        if (data) {
          setUid2Agreement((prev) => ({
            ...prev,
            role: data.user.role,
          }));
        }
      },
    }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            user: {
              account_id: "",
              authority_id: 0,
              company_id: 0,
              company_key: "",
              created_at: 0,
              department: "",
              id: 0,
              job: "",
              last_activated_at: 0,
              phone_no: "",
              name: "",
              promotion_agreement: false,
              role: ROLE.UNREGISTERED,
              status: STATUS.SUSPEND,
              syndication_id: 0,
              updated_at: 0,
              uid2_agreement: null,
            },
          },
    [data]
  );

  return { data: result, ...rest };
};

export default useGetUserDetail;
