import { Box, Grid, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import { useCallback, useMemo, useState } from "react";
import { BasicTable, DateField } from "src/components/commons";
import useGetAdminDaily from "src/hooks/apis/dashboard/useGetAdminDaily";
import { DASHBOARD_REPORT, THIRDPARTY } from "src/types";
import { getColumnDefs } from "./PublisherDashboard";
import { publisherStyle } from "./styles";

const AppMediationDashboard = () => {
  const [date, setDate] = useState(moment().subtract(1, "d"));

  const { data, range, calculator } = useGetAdminDaily({
    dataKey: "adv_revenue",
    type: THIRDPARTY.APP_SDK_MEDIATION,
    since: date.clone().subtract(7, "d"),
    until: date,
  });

  const onChangeDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setDate(value);
    }
    return;
  }, []);

  const totalRowData = useMemo(() => {
    if (data && range) {
      const totalData = data.reduce(
        (acc, cur) => acc.map((v, idx) => v + (cur.data[idx] ?? 0)),
        Array.from(range).map(() => 0)
      );
      return {
        name: "총 합계",
        data: data.reduce(
          (acc, cur) => acc.map((v, idx) => v + (cur.data[idx] ?? 0)),
          Array.from(range).map(() => 0)
        ),
        dod: calculator(totalData.slice(-2)),
        wow: calculator([totalData[0], totalData[totalData.length - 1]]),
      };
    }
    return {
      name: "총 합계",
      data: [],
      dod: 0,
      wow: 0,
    };
  }, [calculator, data, range]);

  return (
    <Box css={publisherStyle}>
      <Typography className="title" variant="h5" gutterBottom>
        미디에이션 일자별 리포트
      </Typography>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={4}>
          <DateField
            label="날짜"
            value={date}
            onChange={onChangeDate}
            maxDate={moment().subtract(1, "d")}
          />
        </Grid>
      </Grid>
      <BasicTable
        className="ag-theme-balham"
        domLayout="autoHeight"
        columnDefs={getColumnDefs(DASHBOARD_REPORT.REVENUE, range)}
        rowData={data}
        onGridColumnsChanged={(e) => {
          const lastCol = e.columnApi.getAllDisplayedColumns().slice(-3)[0];
          e.api.ensureColumnVisible(lastCol);
          totalRowData && e.api.setPinnedTopRowData([totalRowData]);
        }}
        getRowStyle={(params) => {
          if (params.node.rowPinned) {
            return { fontWeight: "bold" };
          }
        }}
      />
    </Box>
  );
};

export default AppMediationDashboard;
