import { Button, Paper, Popover } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import { BiUser } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import usePutSignOut from "src/hooks/apis/auth/usePutSignOut";
import useGetCompany from "src/hooks/apis/companies/useGetCompany";
import useGetUserDetail from "src/hooks/apis/users/useGetUserDetail";
import useOpenModal from "src/hooks/useOpenModal";
import useUser from "src/hooks/useUser";
import CompanyModal from "./CompanyModal";
import { profilePopoverStyle, profileStyle } from "./styles";

const Profile = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const [open, onShowCompanyModal, onCloseCompanyModal] = useOpenModal(null);
  const [company] = useRecoilState(viewerCompany);
  const { mutate } = usePutSignOut();
  const { data } = useGetUserDetail({ userId: "me" });
  const { data: companyData } = useGetCompany({ companyId: company.id });
  const { isAdmin, isSyndication } = useUser();

  const onOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const onCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onSignOut = () => {
    mutate();
  };

  return (
    <Box css={profileStyle} sx={{ flexGrow: 1 }}>
      <Tooltip title="Open Profile">
        <IconButton className="avatar" onClick={onOpenUserMenu} sx={{ p: 0 }}>
          <Avatar>
            <BiUser />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Popover
        className="profile-popover"
        open={Boolean(anchorElUser)}
        onClose={onCloseUserMenu}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "center",
        }}
      >
        <Paper css={profilePopoverStyle}>
          <ul>
            <li className="email">
              <Link to="/setting/account">{data?.user.account_id}</Link>
            </li>
            {(isAdmin || isSyndication) && (
              <li className="company">
                업체:
                <Button variant="text" onClick={onShowCompanyModal}>
                  {companyData.company.name || "없음"}
                </Button>
              </li>
            )}
            <li className="logout">
              <Button variant="outlined" color="error" onClick={onSignOut}>
                로그아웃
              </Button>
            </li>
          </ul>
        </Paper>
      </Popover>
      {open.isOpen && <CompanyModal isOpen={open.isOpen} onClose={onCloseCompanyModal} />}
    </Box>
  );
};

export default Profile;
