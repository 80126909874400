import { AuthorityArea, AUTHORITY_AREA } from "src/types/authority";

export interface Route {
  label: string;
  href: string;
  authority: AuthorityArea[];
  subMenu?: Routes;
}

export interface Routes {
  [key: string]: Route;
}

const routes: Routes = {
  login: {
    label: "로그인",
    href: "/login",
    authority: [],
    subMenu: {
      invite: {
        label: "초대 승인",
        href: "/login/invite",
        authority: [],
      },
      register: {
        label: "회원가입",
        href: "/login/register",
        authority: [],
      },
      findEmail: {
        label: "이메일 찾기",
        href: "/login/find/email",
        authority: [],
      },
      findPassword: {
        label: "비밀번호 찾기",
        href: "/login/find/password",
        authority: [],
      },
    },
  },
  home: {
    label: "홈",
    href: "/home",
    authority: [
      AUTHORITY_AREA.dashboard,
      AUTHORITY_AREA.adminDashboard,
      AUTHORITY_AREA.enterpriseDashboard,
      AUTHORITY_AREA.syndication,
    ],
    subMenu: {
      dashboard: {
        label: "대시보드",
        href: "/home/dashboard",
        authority: [AUTHORITY_AREA.dashboard],
      },
      adminDashboard: {
        label: "어드민 대시보드",
        href: "/home/admin",
        authority: [AUTHORITY_AREA.adminDashboard],
      },
      enterpriseDashboard: {
        label: "전사 대시보드",
        href: "/home/enterprise",
        authority: [AUTHORITY_AREA.enterpriseDashboard],
      },
      syndication: {
        label: "신디케이션 관리",
        href: "/home/syndication",
        authority: [AUTHORITY_AREA.syndication],
      },
    },
  },
  app: {
    label: "앱 매체",
    href: "/app",
    authority: [
      AUTHORITY_AREA.home,
      AUTHORITY_AREA.media,
      AUTHORITY_AREA.placement,
      AUTHORITY_AREA.blockDomain,
    ],
    subMenu: {
      home: {
        label: "홈",
        href: "/app/home",
        authority: [AUTHORITY_AREA.home],
      },
      media: {
        label: "매체 관리",
        href: "/app/media",
        authority: [AUTHORITY_AREA.media],
      },
      placement: {
        label: "플레이스먼트 관리",
        href: "/app/placement",
        authority: [AUTHORITY_AREA.placement],
      },
      blockDomain: {
        label: "차단 관리",
        href: "/app/blocklist",
        authority: [AUTHORITY_AREA.blockDomain],
      },
    },
  },
  web: {
    label: "웹 매체",
    href: "/web",
    authority: [
      AUTHORITY_AREA.home,
      AUTHORITY_AREA.media,
      AUTHORITY_AREA.placement,
      AUTHORITY_AREA.blockDomain,
    ],
    subMenu: {
      home: {
        label: "홈",
        href: "/web/home",
        authority: [AUTHORITY_AREA.home],
      },
      media: {
        label: "매체 관리",
        href: "/web/media",
        authority: [AUTHORITY_AREA.media],
      },
      placement: {
        label: "플레이스먼트 관리",
        href: "/web/placement",
        authority: [AUTHORITY_AREA.placement],
      },
      blockDomain: {
        label: "차단 관리",
        href: "/web/blocklist",
        authority: [AUTHORITY_AREA.blockDomain],
      },
    },
  },
  report: {
    label: "리포트",
    href: "/report",
    authority: [AUTHORITY_AREA.app, AUTHORITY_AREA.web],
    subMenu: {
      app: {
        label: "앱",
        href: "/report/app",
        authority: [AUTHORITY_AREA.app],
      },
      web: {
        label: "웹",
        href: "/report/web",
        authority: [AUTHORITY_AREA.web],
      },
      partner: {
        label: "파트너 리포트",
        href: "/report/partner",
        authority: [AUTHORITY_AREA.partners],
      },
    },
  },
  // settlement: {
  //   label: "정산 관리",
  //   href: "/settlement",
  //   authority: [
  //     AUTHORITY_AREA.dashboard,
  //     AUTHORITY_AREA.setting,
  //     AUTHORITY_AREA.transactionHistory,
  //   ],
  //   subMenu: {
  //     dashboard: {
  //       label: "정산 대시보드",
  //       href: "/settlement/dashboard",
  //       authority: [AUTHORITY_AREA.dashboard],
  //     },
  //     history: {
  //       label: "정산 내역",
  //       href: "/settlement/list",
  //       authority: [AUTHORITY_AREA.transactionHistory],
  //     },
  //     setting: {
  //       label: "정산 설정",
  //       href: "/settlement/setting",
  //       authority: [AUTHORITY_AREA.setting],
  //     },
  //   },
  // },
  setting: {
    label: "환경설정",
    href: "/setting",
    authority: [
      AUTHORITY_AREA.account,
      AUTHORITY_AREA.demoTest,
      AUTHORITY_AREA.company,
      AUTHORITY_AREA.members,
      AUTHORITY_AREA.manager,
    ],
    subMenu: {
      account: {
        label: "사용자 설정",
        href: "/setting/account",
        authority: [AUTHORITY_AREA.account],
      },
      demoTest: {
        label: "연동 가이드",
        href: "https://adpopcornssp.gitbook.io/ssp-sdk",
        authority: [AUTHORITY_AREA.demoTest],
      },
      company: {
        label: "회사 정보",
        href: "/setting/company",
        authority: [AUTHORITY_AREA.company],
      },
      member: {
        label: "회원 관리",
        href: "/setting/member",
        authority: [AUTHORITY_AREA.members],
      },
      manager: {
        label: "관리자 등록",
        href: "/setting/manager",
        authority: [AUTHORITY_AREA.manager],
      },
    },
  },
  operation: {
    label: "운영 관리",
    href: "/operation",
    authority: [AUTHORITY_AREA.operation],
    subMenu: {
      company: {
        label: "업체 관리",
        href: "/operation/company",
        authority: [AUTHORITY_AREA.operation],
      },
      approveCompany: {
        label: "업체 승인",
        href: "/operation/approve",
        authority: [AUTHORITY_AREA.operation],
      },
      partner: {
        label: "파트너 관리",
        href: "/operation/partner",
        authority: [AUTHORITY_AREA.operation],
      },
      settlementManagement: {
        label: "정산 관리",
        href: "/operation/settlement",
        authority: [AUTHORITY_AREA.operation],
      },
      customerServiceManagement: {
        label: "CS 관리",
        href: "/operation/service",
        authority: [AUTHORITY_AREA.operation],
      },
      coupangMangement: {
        label: "쿠팡 관리",
        href: "/operation/coupang",
        authority: [AUTHORITY_AREA.operation],
      },
      datatools: {
        label: "데이터 조회",
        href: "/operation/datatools",
        authority: [AUTHORITY_AREA.operation],
      },
      reportKey: {
        label: "리포트 키 관리",
        href: "/operation/report-key",
        authority: [AUTHORITY_AREA.operation],
      },
      naverPay: {
        label: "네이버페이 관리",
        href: "/operation/npay",
        authority: [AUTHORITY_AREA.operation],
      },
    },
  },
};

export const RoutesDfs = (r: Routes): Route[] => {
  return Object.entries(r).reduce((pre, [_key, curr]) => {
    if (curr.subMenu) {
      return [...pre, curr, ...RoutesDfs(curr.subMenu)];
    } else {
      return [...pre, curr];
    }
  }, [] as Route[]);
};

export default routes;
