import { Button, Grid } from "@mui/material";
import { GetGroupRowAggParams, ValueFormatterParams } from "ag-grid-community";
import moment, { Moment } from "moment";
import { MouseEvent, useCallback, useMemo } from "react";

import DataFormat from "src/assets/formats";
import { BasicTable } from "src/components/commons";
import usePostCoupangReport from "src/hooks/apis/coupang/usePostCoupangReport";
import usePostExcel from "src/hooks/apis/coupang/usePostExcel";
import { COUPANG_REPORT, CoupangReport } from "src/types";

interface ReportBoardProps {
  type: CoupangReport;
  since: Moment;
  until: Moment;
}

const ReportBoard = ({ type, since, until }: ReportBoardProps) => {
  const { data } = usePostCoupangReport({ coupangReportType: type, since, until });
  const { mutate } = usePostExcel();
  const isCoupangReport = type === COUPANG_REPORT.COUPANG;

  // 테이블 상단 총합
  const totalRowData = useMemo(
    () => [
      {
        [isCoupangReport ? "subParam" : "pubId"]: "총합",
        click: data.reportData?.reduce((acc, cur) => acc + +cur.click, 0) || 0,
        purchase: data.reportData?.reduce((acc, cur) => acc + +cur.purchase, 0) || 0,
        purchaseRevenue: data.reportData?.reduce((acc, cur) => acc + +cur.purchaseRevenue, 0) || 0,
        cancel: data.reportData?.reduce((acc, cur) => acc + +cur.cancel, 0) || 0,
        cancelRevenue: data.reportData?.reduce((acc, cur) => acc + +cur.cancelRevenue, 0) || 0,
        totalRevenue: data.reportData?.reduce((acc, cur) => acc + +cur.totalRevenue, 0) || 0,
        commission: data.reportData?.reduce((acc, cur) => acc + +(cur?.commission || 0), 0) || 0,
        apCommission:
          data.reportData?.reduce((acc, cur) => acc + +(cur?.apCommission || 0), 0) || 0,
        clientCommission:
          data.reportData?.reduce((acc, cur) => acc + +(cur?.clientCommission || 0), 0) || 0,
      },
    ],
    [data.reportData, isCoupangReport]
  );

  // 테이블 그룹 합계
  const groupRowData = useCallback(
    (params: GetGroupRowAggParams) =>
      params.nodes.reduce(
        (acc, cur) => {
          const data = cur.group ? cur.aggData : cur.data;

          acc.click += +data.click;
          acc.purchase += +data.purchase;
          acc.purchaseRevenue += +data.purchaseRevenue;
          acc.cancel += +data.cancel;
          acc.cancelRevenue += +data.cancelRevenue;
          acc.totalRevenue += +data.totalRevenue;
          acc.commission += +data.commission;
          acc.apCommission += +data.apCommission;
          acc.clientCommission += +data.clientCommission;
          return acc;
        },
        {
          click: 0,
          purchase: 0,
          purchaseRevenue: 0,
          cancel: 0,
          cancelRevenue: 0,
          totalRevenue: 0,
          commission: 0,
          apCommission: 0,
          clientCommission: 0,
        }
      ),
    []
  );

  const onExport = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      mutate({ coupangReportType: type, since, until });
    },
    [mutate, since, type, until]
  );

  return (
    <>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={12} className="download">
          <Button variant="outlined" onClick={onExport}>
            엑셀 다운로드
          </Button>
        </Grid>
      </Grid>
      <BasicTable
        rowData={data.reportData}
        columnDefs={getColumnDefs(isCoupangReport)}
        animateRows={true}
        getGroupRowAgg={groupRowData}
        autoGroupColumnDef={{
          headerName: "",
          field: isCoupangReport ? "subParam" : "pubId",
          pinned: "left",
          minWidth: 250,
          cellRendererParams: {
            suppressCount: true,
          },
          cellStyle: (params) => {
            if (params.node.rowPinned) {
              return {
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              };
            }
            return;
          },
        }}
        onGridColumnsChanged={(e) => {
          e.api.sizeColumnsToFit();
        }}
        pinnedTopRowData={totalRowData}
      />
    </>
  );
};

const getColumnDefs = (isCoupangReport: boolean) => {
  const defaultColumnDefs = [
    {
      headerName: "일자",
      field: "reportDate",
      rowGroup: true,
      hide: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined) return "";
        return moment(params.value).format("YYYY-MM-DD");
      },
    },
    { field: "partnerName", rowGroup: true, hide: true },
    { field: "pubId", rowGroup: isCoupangReport, hide: true },
    {
      headerName: "클릭 수",
      field: "click",
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined) return "";
        return DataFormat["click"].formatter(params.value || 0);
      },
    },
    {
      headerName: "구매 수",
      field: "purchase",
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined) return "";
        return DataFormat["purchase"].formatter(params.value);
      },
    },
    {
      headerName: "구매 금액",
      field: "purchaseRevenue",
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined) return "";
        return DataFormat["purchaseRevenue"].formatter(params.value);
      },
    },
    {
      headerName: "취소 수",
      field: "cancel",
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined) return "";
        return DataFormat["cancel"].formatter(params.value);
      },
    },
    {
      headerName: "취소 금액",
      field: "cancelRevenue",
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined) return "";
        return DataFormat["cancelRevenue"].formatter(params.value);
      },
    },
    {
      headerName: "총 매출 금액",
      field: "totalRevenue",
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined) return "";
        return DataFormat["totalRevenue"].formatter(params.value);
      },
    },
    {
      headerName: "쿠팡) 수수료",
      field: "commission",
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined) return "";
        return DataFormat["commission"].formatter(params.value);
      },
    },
    {
      headerName: "쿠팡) 수수료율",
      field: "commissionRate",
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined) return "";
        return DataFormat["commissionRate"].formatter(params.value);
      },
    },
    {
      headerName: "쿠팡 광고비",
      field: "apCommission",
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined) return "";
        return DataFormat["commission"].formatter(params.value);
      },
    },
    {
      headerName: "쿠팡 X 애드팝콘 수수료율",
      field: "apCommissionRate",
      minWidth: 200,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined) return "";
        return DataFormat["commissionRate"].formatter(params.value);
      },
    },
    {
      headerName: "쿠팡 X 고객사 광고비",
      field: "clientCommission",
      minWidth: 160,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined) return "";
        return DataFormat["commission"].formatter(params.value);
      },
    },
    {
      headerName: "쿠팡 X 고객사 수수료율",
      field: "clientCommissionRate",
      minWidth: 180,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined) return "";
        return DataFormat["commissionRate"].formatter(params.value);
      },
    },
  ];

  return isCoupangReport
    ? defaultColumnDefs.concat([{ field: "subParam", rowGroup: false, hide: true }])
    : defaultColumnDefs;
};

export default ReportBoard;
