export const STATUS = {
  ACTIVE: 10,
  SUSPEND: 30,
  DELETED: 50,
  REJECTED: 70,
} as const;

export type Status = Union<typeof STATUS>;

export const STATUS_ALIAS = {
  [STATUS.ACTIVE]: "활성",
  [STATUS.DELETED]: "삭제",
  [STATUS.SUSPEND]: "비활성",
  [STATUS.REJECTED]: "거부",
};
