import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, FocusEvent, useCallback, useMemo } from "react";
import { TextField } from "src/components/commons";
import { MatchKeyInfo, ThirdpartyInfo } from "src/hooks/apis/placements/useGetAppThirdpartyList";
import usePutAppThirdparty from "src/hooks/apis/placements/usePutAppThirdparty";
import useToast from "src/hooks/useToast";
import { STATUS } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { matchingInfoModalStyle } from "./styles";

interface MediationMatchingInfoModalProps extends ThirdpartyInfo {
  placementId: string;
  onClose: () => void;
  open: { key: number; isOpen: boolean };
  mediations: ThirdpartyInfo[];
}

const MediationMatchingInfoModal = ({
  placementId,
  onClose,
  open,
  match_key,
  match_key_info,
  id,
  match_no,
  bidfloor,
  mediations,
}: MediationMatchingInfoModalProps) => {
  const toast = useToast();
  const { mutate: updateMatchKeyInfo } = usePutAppThirdparty();

  const makeDefaultValue = useCallback(
    (type: "string" | "float" | "integer" | "number" | "boolean") => {
      if (type === "string") return "";
      if (type === "boolean") return false;
      return "";
    },
    []
  );
  const formInfo = useMemo(() => {
    if (match_key_info && match_key_info.length > 0) {
      return match_key_info.reduce((acc: MatchKeyInfo, cur) => {
        return { ...acc, ...cur };
      }, {});
    }
    return;
  }, [match_key_info]);

  const initialValues = useMemo(() => {
    if (formInfo)
      return Object.entries(formInfo).reduce(
        (acc: Record<string, unknown>, [key, condition]) => {
          acc[key] = (match_key && match_key[key]) || makeDefaultValue(condition.type);
          return acc;
        },
        { bidfloor: bidfloor || 0 }
      );
    return { bidfloor: bidfloor || 0 };
  }, [bidfloor, formInfo, makeDefaultValue, match_key]);

  const validationData = useMemo(() => {
    if (formInfo)
      return Object.entries(formInfo).reduce(
        (acc: Record<string, yup.BaseSchema>, [key, condition]) => {
          const { type, required } = condition;
          if (type === "float" || type === "integer" || type === "number") {
            acc = {
              ...acc,
              [key]: yup.string().test(key, "숫자를 입력해주세요.", (value = "") => !isNaN(+value)),
            };
          } else {
            acc = { ...acc, [key]: yup[type]() };
          }
          if (required) {
            acc = { ...acc, [key]: acc[key].required(`${key}을(를) 설정해주세요.`) };
          }
          return acc;
        },
        {
          bidfloor: yup.number(),
        }
      );
    return {
      bidfloor: yup.number(),
    };
  }, [formInfo]);

  const validationSchema = yup.object(validationData);

  const { getFieldProps, handleSubmit, touched, errors, values, setFieldValue, handleBlur } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: ({ bidfloor, ...values }) => {
        if (
          mediations.some(
            (mediation) =>
              mediation.id === id &&
              mediation.bidfloor === parseFloat(bidfloor as string) &&
              mediation.status === STATUS.ACTIVE
          )
        ) {
          toast.error("해당 네트워크는 이미 동일한 Bid Floor가 활성화되었습니다.");
          return;
        }
        updateMatchKeyInfo({
          placementId,
          thirdpartyId: id,
          matchKey: values,
          matchNo: match_no,
          bidfloor: parseFloat(bidfloor as string),
        });
        onClose();
      },
    });

  const onChangeBidfloor = useCallback(
    (field: "bidfloor" | "cpcBidfloor") => (e: ChangeEvent<HTMLInputElement>) => {
      const value =
        e.target.value.replace(
          REG_EXP.bidfloor,
          e.target.value.substring(0, e.target.value.length - 1)
        ) || 0;
      if (+(value || 0) < 1000000) {
        setFieldValue(field, value);
      }
    },
    [setFieldValue]
  );

  const onBlurBidfloor = useCallback(
    (e: FocusEvent) => {
      setFieldValue("bidfloor", parseFloat(`${values.bidfloor || 0}`));
      handleBlur(e);
    },
    [handleBlur, setFieldValue, values]
  );

  const handleChangeTextField = useCallback(
    (key: string, value: string) => {
      const tabNewLineRegex = /[\t\n]+/g;
      const trimmedValue = value.trim().replace(tabNewLineRegex, "");
      setFieldValue(key, trimmedValue);
    },
    [setFieldValue]
  );

  return (
    <Dialog
      css={matchingInfoModalStyle}
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-dsp-matching-info"
      aria-describedby="set dsp matching info"
    >
      <DialogTitle id="dialog-title">매칭정보 변경</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {formInfo &&
              Object.keys(formInfo).map((key) => {
                if (formInfo[key].type === "boolean")
                  return (
                    <Grid key={key} item xs={12}>
                      <FormControlLabel
                        className="switch"
                        control={<Switch color="primary" />}
                        label={key}
                        labelPlacement="start"
                        disabled={!!match_key}
                        {...getFieldProps(key)}
                      />
                    </Grid>
                  );
                return (
                  <Grid key={key} item xs={12}>
                    <TextField
                      className="field"
                      label={key}
                      placeholder={`${key}을(를) 입력하세요.`}
                      required
                      disabled={!!match_key}
                      value={values[key]}
                      onChange={(e) => handleChangeTextField(key, e.target.value)}
                      onBlur={handleBlur(key)}
                      error={shouldErrorShows(key, touched, errors)}
                      helperText={getHelperText(key, touched, errors)}
                    />
                  </Grid>
                );
              })}
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography>Bidfloor (Optional)</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                placeholder="0"
                prefix={<span className="ssp-input-currency-prefix">USD</span>}
                {...getFieldProps("bidfloor")}
                onChange={onChangeBidfloor("bidfloor")}
                onBlur={onBlurBidfloor}
                error={shouldErrorShows("bidfloor", touched, errors)}
                helperText={getHelperText("bidfloor", touched, errors)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MediationMatchingInfoModal;
