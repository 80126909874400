import ResponseForm from "./ResponseForm";
import RewardHistory from "./RewardHistory";
import ServiceClientInfo from "./ServiceClientInfo";

const CustomerServiceDetail = () => {
  return (
    <>
      <ServiceClientInfo />
      <RewardHistory />
      <ResponseForm />
    </>
  );
};

export default CustomerServiceDetail;
