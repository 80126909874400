import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { TextField } from "src/components/commons";
import useGetCompanies, { CompanyData } from "src/hooks/apis/companies/useGetCompanies";
import { COMPANY } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import FindEmailResult from "./FindEmailResult";
import { findFormStyle } from "./styles";

const FindEmailForm = () => {
  const [company, setCompany] = useState<CompanyData | null>();
  const [isOpenResult, setIsOpenResult] = useState(false);
  const { data: companyData } = useGetCompanies({
    types: [
      COMPANY.CORPORATION,
      COMPANY.INDIVIDUAL,
      COMPANY.SYNDICATION,
      COMPANY.SYNDICATION_CLIENT_CORPORATION,
      COMPANY.SYNDICATION_CLIENT_INDIVIDUAL,
    ],
    pageNo: 1,
  });

  const initialValues = useMemo(
    () => ({
      name: "",
      company: 0,
    }),
    []
  );

  const validationSchema = yup.object({
    name: yup
      .string()
      .matches(REG_EXP.name, "한글 또는 영문만 입력해주세요.")
      .required("사용자 이름을 입력해주세요."),
    company: yup.number().moreThan(0, "업체를 선택해주세요.").required("업체를 선택해주세요."),
  });

  const { getFieldProps, setFieldValue, handleSubmit, errors, touched, values } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      setIsOpenResult(true);
    },
  });

  const onSelectCompany = useCallback(
    (_: SyntheticEvent<unknown>, v: CompanyData | null) => {
      setCompany(v);
      setFieldValue("company", v?.id);
    },
    [setFieldValue]
  );

  return (
    <section className="find-form-section" css={findFormStyle}>
      <div className="form-box">
        <h2 className="title">이메일 찾기</h2>
        {isOpenResult ? (
          <FindEmailResult name={values.name} companyId={values.company} />
        ) : (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  label="이름"
                  variant="standard"
                  placeholder="이름을 입력해주세요"
                  {...getFieldProps("name")}
                  error={shouldErrorShows("name", touched, errors)}
                  helperText={getHelperText("name", touched, errors)}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  className="field"
                  size="small"
                  options={companyData.companies}
                  getOptionLabel={(company) => company.name}
                  renderOption={(props, company) => {
                    return (
                      <li {...props} key={company.id}>
                        {company.name}
                      </li>
                    );
                  }}
                  renderInput={({ InputLabelProps, ...params }) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="업체 명"
                      placeholder="업체를 선택해주세요."
                      required
                      error={shouldErrorShows("company", touched, errors)}
                      helperText={getHelperText("company", touched, errors)}
                    />
                  )}
                  isOptionEqualToValue={(company, value) => company.id === value.id}
                  value={company}
                  onChange={onSelectCompany}
                />
              </Grid>
            </Grid>

            <Button className="submit" type="submit" variant="outlined">
              이메일 찾기
            </Button>
            <div className="sign-up">
              <span>{`Don't have an account?`}</span>
              <Link to="/login/register">Sign Up</Link>
            </div>
            <Box className="forgot">
              <Link to="/login/find/password">Forgot Password?</Link>
            </Box>
          </form>
        )}
        <Box className="footer">
          <MuiLink
            href="https://www.adpopcorn.com/policy/media"
            target="_blank"
            rel="noopener noreferrer"
          >
            서비스 이용약관
          </MuiLink>
          <Divider orientation="vertical" variant="middle" flexItem />
          <MuiLink
            href="https://www.adpopcorn.com/policy/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            개인정보처리방침
          </MuiLink>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Typography variant="body1" className="copyright">
            © 2021 adpopcorn
          </Typography>
        </Box>
      </div>
    </section>
  );
};

export default FindEmailForm;
