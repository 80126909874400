import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useMemo, useState } from "react";
import { TextField } from "src/components/commons";
import { AuthorityWithRole } from "src/hooks/apis/users/useGetEditAuthority";
import useGetUserDetail, { User } from "src/hooks/apis/users/useGetUserDetail";
import usePostUserInvite from "src/hooks/apis/users/usePostUserInvite";
import useOpenModal from "src/hooks/useOpenModal";
import { ROLE, ROLE_ALIAS } from "src/types";
import { getSubRoles } from "src/utils/auth-helper";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import AddMemberRoleModal from "./AddMemberRoleModal";
import { addMemberModalStyle } from "./styles";

interface AddMemberModalProps {
  onClose: () => void;
  isOpen: boolean;
  user: Partial<User>;
}

const AddMemberModal = ({ isOpen, onClose, user }: AddMemberModalProps) => {
  const [roleInfo, setRoleInfo] = useState<AuthorityWithRole>();
  const [openAddRoleModal, onShowAddRoleModal, onCloseAddRoleModal] = useOpenModal(null);
  const { data } = useGetUserDetail({ userId: "me" });
  const { mutate } = usePostUserInvite();
  // 미가입자일 경우, 이름 필드과 부서 필드 제외
  const isRegistered = useMemo(
    () => user.created_at !== null && user.role !== ROLE.UNREGISTERED,
    [user.created_at, user.role]
  );

  const initialValues = useMemo(
    () => ({
      email: user.account_id || "",
      name: user.name || "",
      department: user.department || "",
      role: ROLE_ALIAS[getSubRoles(data.user.role)[0]] || "", // 로그인한 사용자 역할이 지닌 서브 역할을 디폴트로 설정
      message: "",
    }),
    [data.user.role, user.account_id, user.department, user.name]
  );

  const validationSchema = yup.object({
    email: yup.string().matches(REG_EXP.email).required("이메일을 입력해주세요."),
    name: yup.string(),
    department: yup.string(),
    role: yup.string(),
    message: yup.string(),
  });

  const { getFieldProps, setFieldValue, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: ({ email, message }) => {
      if (roleInfo) {
        const { role, ...authority } = roleInfo;
        mutate({ email, message, role, authority });
      } else {
        mutate({ email, message, role: getSubRoles(data.user.role)[0] });
      }
      onClose();
    },
  });

  const onSetRoleInfo = useCallback(
    (values: AuthorityWithRole) => {
      setRoleInfo(values);
      setFieldValue("role", ROLE_ALIAS[values.role]);
    },
    [setFieldValue]
  );

  return (
    <Dialog
      css={addMemberModalStyle}
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-member"
      aria-describedby="invite member"
    >
      <DialogTitle id="dialog-title">{user.account_id}</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="invite-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="이메일"
                placeholder="이메일을 입력해주세요."
                disabled
                {...getFieldProps("email")}
              />
            </Grid>
            {isRegistered && (
              <>
                <Grid item xs={12}>
                  <TextField
                    className="field"
                    label="이름"
                    placeholder="이름을 입력해주세요."
                    disabled
                    {...getFieldProps("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="field"
                    label="부서"
                    placeholder="부서명을 입력해주세요."
                    disabled
                    {...getFieldProps("department")}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                className="field"
                label="역할"
                placeholder="역할을 설정해주세요."
                required
                suffix={
                  <Button color="primary" onClick={onShowAddRoleModal}>
                    설정하기
                  </Button>
                }
                readOnly
                {...getFieldProps("role")}
                error={shouldErrorShows("role", touched, errors)}
                helperText={getHelperText("role", touched, errors)}
              />
            </Grid>
            <Grid item xs={12} className="description">
              <TextField
                multiline
                minRows={5}
                className="field"
                label="남기는 말"
                placeholder="ADPOPCORN SSP에 초대합니다."
                InputLabelProps={{ shrink: true }}
                {...getFieldProps("message")}
                error={shouldErrorShows("message", touched, errors)}
                helperText={getHelperText("message", touched, errors)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="invite-form" type="submit">
          저장
        </Button>
      </DialogActions>
      {openAddRoleModal.isOpen && (
        <AddMemberRoleModal
          isOpen={openAddRoleModal.isOpen}
          onClose={onCloseAddRoleModal}
          onSubmit={onSetRoleInfo}
        />
      )}
    </Dialog>
  );
};

export default AddMemberModal;
