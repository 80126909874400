import { AxiosError, AxiosResponse } from "axios";
import { Moment } from "moment";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useToast from "src/hooks/useToast";
import { Media, Report } from "src/types";
import API from "src/utils/api";
import { AppMedia } from "../media/useGetAppMediaList";
import { WebMedia } from "../media/useGetWebMediaList";
import { AppPlacement } from "../placements/useGetAppPlacementList";
import { WebPlacement } from "../placements/useGetWebPlacementList";
import { Mediation } from "../thirdparties/useGetMediationList";

interface Params {
  exportXls?: boolean;
  byCountries: boolean;
  mediaList: (AppMedia | WebMedia)[];
  mediaType: Media;
  placementList: (AppPlacement | WebPlacement)[];
  reportType: Report;
  since: Moment;
  thirdpartyList: Mediation[];
  until: Moment;
}

// 레포트 조회
// 조회 시작일, 종료일을 전달하지 않을 경우, -1일부터 -7일까지 데이터를 조회
// export_xls 파라미터 사용 시 엑셀 내보내기
const usePostExcel = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const result = useMutation<any, AxiosError, Params>(
    async ({
      exportXls = true,
      byCountries,
      mediaList,
      mediaType,
      placementList,
      reportType,
      since,
      thirdpartyList,
      until,
    }) => {
      const response: AxiosResponse<any> = await API.default.post(
        `/report`,
        {
          export_xls: exportXls,
          by_countries: byCountries,
          media_keys: mediaList.map((m) => m.key),
          media_type: mediaType,
          placement_ids: placementList.map((p) => p.id),
          report_type: reportType,
          since: since.format("YYYYMMDD"),
          thirdparty_ids: thirdpartyList.map((m) => m.id),
          until: until.format("YYYYMMDD"),
        },
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${since.format("YYYYMMDD")}_${until.format("YYYYMMDD")}.xlsx`);
      link.click();
    },
    {
      onSuccess: () => {
        toast.success("다운로드가 완료되었습니다.");
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePostExcel;
