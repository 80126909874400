import { AxiosError, AxiosResponse } from "axios";
import { Moment } from "moment";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { AppPlatform, Media } from "src/types";
import API from "src/utils/api";

interface Params {
  type: Media;
  since?: Moment;
  until?: Moment;
  isTest?: boolean;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    media_type: number;
    platform: {
      click: number;
      ecpm: number;
      impression: number;
      request: number;
      response: number;
      revenue: number;
      rpr: number;
      type: AppPlatform;
    }[];
    report: {
      campaign_type: number;
      campaign_type_name: string;
      click: number;
      ecpm: number;
      impression: number;
      request: number;
      response: number;
      revenue: number;
      rpr: number;
      size_type: number;
      size_type_name: string;
    }[];
    total: {
      click: number;
      ecpm: number;
      impression: number;
      request: number;
      response: number;
      revenue: number;
      rpr: number;
    };
    twdd_revenue: number;
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 관리자 대시보드 (상단 합계, 플랫폼, 플레이스먼트별 데이터)
// since, until을 전달하지 않을 경우 -7일 ~ -1일 조회
// type: 앱(1) => platform[ios, android]
// type: 웹(2) => platform[pc, mobile]
const useGetAdminDashboard = ({ isTest, since, until, ...params }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["dashboard/useGetAdminDashboard", params.type, since, until, isTest],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/dashboard/admin`, {
        params: {
          is_test: isTest,
          since: since?.format("YYYY-MM-DD"),
          until: until?.format("YYYY-MM-DD"),
          ...params,
        },
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            media_type: 0,
            platform: [],
            report: [],
            total: {
              click: 0,
              ecpm: 0,
              impression: 0,
              request: 0,
              response: 0,
              revenue: 0,
              rpr: 0,
            },
            twdd_revenue: 0,
          },
    [data]
  );

  return { data: result, ...rest };
};

export default useGetAdminDashboard;
