import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { RowClickedEvent, SortChangedEvent, ValueFormatterParams } from "ag-grid-community";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import { BasicTable } from "src/components/commons";
import useGetUserDetail from "src/hooks/apis/users/useGetUserDetail";
import useGetUsers from "src/hooks/apis/users/useGetUsers";
import useOpenModal from "src/hooks/useOpenModal";
import { ROLE_ALIAS, UserRole } from "src/types";
import { phoneFormatter } from "src/utils/form-helper";
import EditMemberModal from "./EditMemberModal";
import InviteMemberModal from "./InviteMemberModal";

const Management = () => {
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [orders, setOrders] = useState<string[]>([]);
  const [openAddModal, onShowInviteModal, onCloseInviteModal] = useOpenModal(null);
  const [openEditModal, onShowEditModal, onCloseEditModal] = useOpenModal(0);

  const [company] = useRecoilState(viewerCompany);
  const { data: userData } = useGetUserDetail({ userId: "me" });
  const { data } = useGetUsers({
    companyId: company.id || userData?.user.company_id,
    pageNo,
    pageSize,
    orders,
  });

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, data.pages, onChangePage, pageSize, onChangePageSize]
  );

  const onShowEditMemberModal = useCallback(
    (e: RowClickedEvent) => {
      onShowEditModal(e.event, e.data.id);
    },
    [onShowEditModal]
  );

  const onSortChanged = useCallback((e: SortChangedEvent) => {
    const sortInfo = e.columnApi
      .getColumnState()
      .filter((column) => !!column.sort)
      .map(({ colId, sort }) => {
        if (sort === "desc") return `-${colId}`;
        return `+${colId}`;
      });
    setOrders(sortInfo);
  }, []);

  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        회원 관리
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid container className="ssp-tools" spacing={2}>
          <Grid item xs={12} className="register">
            <Button variant="outlined" onClick={onShowInviteModal}>
              <AiOutlineUserAdd style={{ marginRight: "8px" }} /> 회원 초대
            </Button>
          </Grid>
        </Grid>
        <BasicTable
          rowData={data.users}
          columnDefs={columnDefs}
          onRowClicked={onShowEditMemberModal}
          onSortChanged={onSortChanged}
          {...paginationInfo}
        />
      </Paper>
      {openAddModal.isOpen && (
        <InviteMemberModal isOpen={openAddModal.isOpen} onClose={onCloseInviteModal} />
      )}
      {openEditModal.isOpen && <EditMemberModal open={openEditModal} onClose={onCloseEditModal} />}
    </Container>
  );
};

const columnDefs = [
  { headerName: "이메일", field: "account_id", sortable: true },
  { headerName: "사용자 이름", field: "name", sortable: true },
  { headerName: "부서", field: "department", sortable: true },
  {
    headerName: "역할",
    field: "role",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const role = param.data.role as UserRole;
      return ROLE_ALIAS[role];
    },
  },
  {
    headerName: "연락처",
    field: "phone_no",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      if (!param.data.phone_no) return "";
      return phoneFormatter(param.data.phone_no);
    },
  },
  {
    headerName: "가입일",
    field: "created_at",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      return moment(param.data.created_at * 1000).format("YYYY-MM-DD");
    },
  },
];

export default Management;
