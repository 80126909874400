import { Box, Grid, Switch, Typography } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import useGetEditAuthority from "src/hooks/apis/users/useGetEditAuthority";
import useGetUserDetail from "src/hooks/apis/users/useGetUserDetail";
import {
  AuthorityArea,
  AUTHORITY_AREA_ALIAS,
  AuthType,
  AUTH_TYPE,
  MultiRWD,
  RWD,
} from "src/types/authority";
import { initAuthorityRWD } from "src/utils/auth-helper";
import { authSwitchGroupStyle } from "./styles";

interface AuthSwitchGroupProps {
  name: string;
  menu: AuthorityArea;
  mainMenu?: AuthorityArea;
  rwd: RWD;
  type: AuthType;
  onChange: ({ name, rwd }: { name: string; rwd: RWD }) => void;
}

const AuthSwitchGroup = ({ name, menu, mainMenu, rwd, type, onChange }: AuthSwitchGroupProps) => {
  const [auth, setAuth] = useState(rwd);
  const { data: userData } = useGetUserDetail({ userId: "me" });
  const { data: editibleAuthorityData } = useGetEditAuthority({
    role: userData.user.role,
  });

  const isCustom = useMemo(() => type === AUTH_TYPE.CUSTOM, [type]);

  // 초기 권한 세팅
  useEffect(() => {
    setAuth(rwd);
  }, [rwd]);

  // 권한 수정시
  useEffect(() => {
    onChange({ name: name, rwd: auth });
  }, [auth, name, onChange]);

  // 편집할 수 있는 권한이 있는지 체크
  const editibleAuth = useMemo(() => {
    const editibleAuthority = editibleAuthorityData.authority || initAuthorityRWD;
    if (mainMenu) return (editibleAuthority[mainMenu] as MultiRWD)[menu];
    return editibleAuthority[menu];
  }, [editibleAuthorityData.authority, mainMenu, menu]);

  const onChangeAuth = useCallback(
    (type: "read" | "write" | "delete") =>
      (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (type === "read")
          // 읽기 권한 비활성화시 쓰기/삭제 모두 비활성화
          // 읽기 권한 활성화시 쓰기/삭제 기본권한을 바탕으로 설정
          setAuth((prev) => ({
            ...prev,
            read: checked,
            write: rwd.write === undefined ? undefined : checked ? rwd.write : false,
            delete: rwd.delete === undefined ? undefined : checked ? rwd.delete : false,
          }));
        if (type === "write") setAuth((prev) => ({ ...prev, write: checked }));
        if (type === "delete") setAuth((prev) => ({ ...prev, delete: checked }));

        return;
      },
    [rwd.delete, rwd.write]
  );

  return (
    <Grid item xs={6} css={authSwitchGroupStyle}>
      <Box className="header">
        <Typography className="title" variant="body1" gutterBottom>
          {mainMenu
            ? `${AUTHORITY_AREA_ALIAS[mainMenu]} > ${AUTHORITY_AREA_ALIAS[menu]}`
            : AUTHORITY_AREA_ALIAS[menu]}
        </Typography>
        <Switch
          className="switch"
          name={`${name}.read`}
          value={auth.read}
          checked={auth.read}
          disabled={!isCustom || !(editibleAuth.read && rwd.read)}
          onChange={onChangeAuth("read")}
        />
      </Box>
      <Grid container className="content">
        {auth.write !== undefined && (
          <>
            <Grid item xs={6} className="auth-label">
              등록 / 수정
            </Grid>
            <Grid item xs={6} className="auth-switch">
              <Switch
                name={`${name}.write`}
                value={auth.write}
                checked={auth.write}
                disabled={!isCustom || !(editibleAuth.write && rwd.write)}
                onChange={onChangeAuth("write")}
              />
            </Grid>
          </>
        )}
        {auth.delete !== undefined && (
          <>
            <Grid item xs={6} className="auth-label">
              삭제
            </Grid>
            <Grid item xs={6} className="auth-switch">
              <Switch
                value={auth.delete}
                name={`${name}.delete`}
                checked={auth.delete}
                disabled={!isCustom || !(editibleAuth.delete && rwd.delete)}
                onChange={onChangeAuth("delete")}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AuthSwitchGroup;
