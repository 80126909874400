import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Postback as PostbackType } from "src/types/coupang";
import API from "src/utils/api";

export interface Postback {
  postback_no: number;
  partner_name: string;
  subids: { subid_no: number; subid: string }[];
  postback_type: PostbackType;
  campaigns: number;
}

interface Params {
  search?: string;
  pageNo: number;
  pageSize: number;
  orders?: string[];
}

interface Response {
  code?: 200 | 401 | 422;
  data?: Postback[];
  pages?: number;
  text?: "ok" | "unauthorized" | "unprocessable-entity";
}

/*
  포스트백 전체 및 검색 조회
*/
const useGetPostbackList = ({ search, pageNo, pageSize, orders }: Params) => {
  const { handleError } = useApiError();
  const { data: response, ...rest } = useQuery<Response, AxiosError>(
    ["coupang/useGetPostbackList", search, pageNo, pageSize, orders],
    async () => {
      const response: AxiosResponse<Response> = await API.integration.get(
        `/operation/management/coupang/postback`,
        {
          params: { q: search, page_no: pageNo, page_size: pageSize, order: orders?.join(",") },
        }
      );
      return response.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => {
    if (response && response.data)
      return {
        postbacks: response.data,
        pages: response.pages || 0,
      };
    return { postbacks: [], pages: 0 };
  }, [response]);

  return { data: result, ...rest };
};

export default useGetPostbackList;
