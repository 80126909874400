import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  SelectChangeEvent,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Select } from "src/components/commons";
import useGetAuthorityList from "src/hooks/apis/users/useGetAuthorityList";
import { AuthorityWithRole } from "src/hooks/apis/users/useGetEditAuthority";
import useGetUserDetail from "src/hooks/apis/users/useGetUserDetail";
import { ROLE, ROLE_ALIAS, UserRole } from "src/types";
import {
  Authority,
  AuthorityArea,
  AuthType,
  AUTH_TYPE,
  AUTH_TYPE_ALIAS,
  RWD,
} from "src/types/authority";
import {
  createAuthority,
  getSubRoles,
  initAuthority,
  initAuthorityRWD,
} from "src/utils/auth-helper";
import AuthSwitchGroup from "./AuthSwitchGroup";
import { addMemberModalStyle } from "./styles";

interface AddMemberRoleModalProps {
  onClose: () => void;
  isOpen: boolean;
  onSubmit: (values: AuthorityWithRole) => void;
}

const AddMemberRoleModal = ({ isOpen, onClose, onSubmit }: AddMemberRoleModalProps) => {
  const [type, setType] = useState<AuthType>(AUTH_TYPE.DEFAULT);
  const [role, setRole] = useState<UserRole>(ROLE.UNREGISTERED);
  const { data: userData } = useGetUserDetail({ userId: "me" });
  const { data: defaultAuthorityData } = useGetAuthorityList({ type: 1 });

  // 초기 역할 세팅
  useEffect(() => {
    setRole(userData.user.role);
  }, [userData.user.role]);

  // 로그인한 사용자 역할이 지닌 서브 역할을 옵션으로 설정
  const roleOptions = useMemo(() => getSubRoles(userData.user.role), [userData.user.role]);

  const initialValues = useMemo(() => {
    if (
      defaultAuthorityData.authorities &&
      defaultAuthorityData.authorities.length > 0 &&
      defaultAuthorityData.authorities.find((auth) => auth.id === role)
    ) {
      const authority = defaultAuthorityData.authorities.find(
        (auth) => auth.id === role
      ) as Authority;
      return { authority, authorityRWD: createAuthority(authority) };
    }
    return { authority: initAuthority, authorityRWD: initAuthorityRWD };
  }, [defaultAuthorityData.authorities, role]);

  const { handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues.authority,
    enableReinitialize: true,
    onSubmit: ({ id, type, ...values }) => {
      onSubmit({ role, ...values });
      onClose();
    },
  });

  const onChangeType = useCallback((e: ChangeEvent<HTMLInputElement>, v: string) => {
    e.preventDefault();
    setType(+v as 1 | 2);
  }, []);

  const onChangeRole = useCallback((e: SelectChangeEvent<UserRole>) => {
    const value = +e.target.value as UserRole;
    setRole(value);
  }, []);

  const onChangeAuthGroup = useCallback(
    ({ name, rwd }: { name: string; rwd: RWD }) => {
      const read = rwd.read === undefined ? "" : rwd.read ? 1 : 0;
      const write = rwd.read === undefined ? "" : rwd.read ? 1 : 0;
      const del = rwd.read === undefined ? "" : rwd.read ? 1 : 0;
      setFieldValue(name, `${read}${write}${del}`);
    },
    [setFieldValue]
  );

  return (
    <Dialog
      css={addMemberModalStyle}
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-role"
      aria-describedby="set initial role"
    >
      <DialogTitle id="dialog-title">역할 상세</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="add-role-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select
                label="역할"
                placeholder="역할을 선택해주세요."
                value={role}
                onChange={onChangeRole}
              >
                {roleOptions.map((role) => (
                  <MenuItem key={role} value={role}>
                    {ROLE_ALIAS[role]}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                row
                className="field"
                aria-label="filter"
                value={type}
                onChange={onChangeType}
              >
                <FormControlLabel
                  value={AUTH_TYPE.DEFAULT}
                  control={<Radio />}
                  label={AUTH_TYPE_ALIAS[AUTH_TYPE.DEFAULT]}
                />
                <FormControlLabel
                  value={AUTH_TYPE.CUSTOM}
                  control={<Radio />}
                  label={AUTH_TYPE_ALIAS[AUTH_TYPE.CUSTOM]}
                />
              </RadioGroup>
            </Grid>
            <Grid item container xs={12} spacing={4} className="edit-auth-switch-group">
              {Object.entries(initialValues.authorityRWD).map(([key, value]) => {
                const menu = key as AuthorityArea;
                // operation
                if (menu === "operation") {
                  const rwd = value as RWD;
                  return (
                    <AuthSwitchGroup
                      key={menu}
                      name={menu}
                      menu={menu}
                      rwd={rwd}
                      type={type}
                      onChange={onChangeAuthGroup}
                    />
                  );
                } else {
                  // operation이 아닐 경우
                  const subMenuObj = value as Record<string, RWD>;
                  return Object.entries(subMenuObj).map(([subKey, subValue]) => {
                    const subMenu = subKey as AuthorityArea;
                    const subRwd = subValue as RWD;
                    return (
                      <AuthSwitchGroup
                        key={`${menu}_${subMenu}`}
                        name={`${menu}.${subMenu}`}
                        mainMenu={menu}
                        menu={subMenu}
                        rwd={subRwd}
                        type={type}
                        onChange={onChangeAuthGroup}
                      />
                    );
                  });
                }
              })}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="add-role-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddMemberRoleModal;
