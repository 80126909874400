import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  campaignKey: string;
  campaignName?: string;
  landingUrl: string;
}

interface Response {
  code: 200 | 400 | 401 | 404 | 409 | 422;
  text:
    | "Successful Response"
    | "bad-request"
    | "unauthorized"
    | "not-found"
    | "conflict"
    | "unprocessable-entity";
}

// 쿠팡 캠페인 수정
const usePatchCampaign = () => {
  const toast = useToast();
  const { handleError } = useApiError({
    409: (_error: AxiosError) => null,
  });
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ campaignKey, campaignName, landingUrl }) => {
      const response: AxiosResponse<Response> = await API.integration.put(
        `/operation/management/coupang/campaign/${campaignKey}`,
        {
          campaign_name: campaignName,
          landing_url: landingUrl,
        }
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("캠페인이 수정되었습니다.");
        invalidate(["coupang/useGetCampaign", "coupang/useGetCampaignList"]);
      },
    }
  );

  return result;
};

export default usePatchCampaign;
