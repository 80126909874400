import { AxiosError, AxiosResponse } from "axios";
import { stringify } from "qs";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import API from "src/utils/api";
import useApiError from "../useApiError";

export type ReportKey = {
  thirdparty_id: number; // 서드파티 ID
  thirdparty_name: string; // 서드파티명
  placement_count: number; // 지면개수
  placement_id: string; // 리포트 플레이스먼트 ID
  placement_name: string; // 리포트 플레이스먼트명
  media_key: string; // 리포트 매체키
  media_name: string; // 리포트 매체명
  updated_at: number; // UNIX TIMESTAMP // 변경일자
  report_key: Record<string, string>; // 레포트키
};

export type ReportKeyListParams = {
  /**
   * 지면 개수 (0: 전체, 1: 1개, 2: 2개 이상 - default 0)
   */
  placement_count?: number;

  /**
   * 서드파티 ID
   * 콤마로 구분 (1,2,3,4,5, ...)
   * 전체 조회 시 전달할 필요 없음
   */
  thirdparty_id?: number[];

  /**
   * 검색어 (리포트 키, 매체키, 매체명, 플레이스먼트 ID, 플레이스먼트명 중에 검색)
   */
  q?: string;

  /**
   * 페이지
   */
  page_no?: number;

  /**
   * 페이지당 항목 수 (default 100)
   */
  page_size?: number;

  /**
   * 정렬 필드명
   * 콤마,로 구분
   * 내림차순은 필드명 앞에 '-'
   */
  orders?: string[];
};

export type ReportKeyListResponse = {
  code: 200 | 401 | 404;
  data: { keys: ReportKey[] | null; pages: number };
  text: "ok" | "unauthorized" | "not-found";
};

type ReportKeyListQueryKey = ["operation/report-key-list", ReportKeyListParams];

type ReportKeyListQueryFn = QueryFunction<
  AxiosResponse<ReportKeyListResponse>,
  ReportKeyListQueryKey
>;

type ReportKeyListQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<ReportKeyListResponse>,
    AxiosError,
    AxiosResponse<ReportKeyListResponse>,
    ReportKeyListQueryKey
  >,
  "queryKey" | "queryFn"
>;

export default function useReportKeyList(
  params: ReportKeyListParams,
  options?: ReportKeyListQueryOptions
) {
  const { handleError } = useApiError();

  const queryKey = useMemo<ReportKeyListQueryKey>(
    () => ["operation/report-key-list", params],
    [params]
  );

  const queryFn = useCallback<ReportKeyListQueryFn>(({ queryKey }) => {
    const [, params] = queryKey;
    const queryString = stringify(params, { arrayFormat: "comma" });
    return API.default.get(`/mgmt/rptkeys?${queryString}`);
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data.data || { keys: [], pages: 0 };
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
