import {
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useCallback, useMemo } from "react";
import { selectStyle } from "./styles";

type MultiSelectProps<T> = Override<
  SelectProps,
  {
    defaultValue?: T[];
    value: T[];
    label?: string;
    placeholder?: string;
    options: T[];
    getOptionLabel?: (option: T) => string | number;
    getOptionValue?: (option: T) => string | number;
    onChange: (values: T[]) => void;
    fullWidth?: boolean;
  }
>;

const MultipleSelect = <T,>({
  defaultValue,
  multiple = true,
  label,
  placeholder,
  value,
  options,
  getOptionLabel,
  getOptionValue,
  onChange,
  fullWidth = true,
  size = "small",
  ...props
}: MultiSelectProps<T>) => {
  const _getOptionValue = useCallback(
    (option: T) => {
      if (getOptionValue) return getOptionValue(option);
      if (typeof option === "object") return JSON.stringify(option);
      if (typeof option === "string" || typeof option === "number") return option;
      return "";
    },
    [getOptionValue]
  );

  const _getOptionLabel = useCallback(
    (option: T) => {
      if (getOptionLabel) return getOptionLabel(option);
      if (typeof option === "object") return JSON.stringify(option);
      if (typeof option === "string" || typeof option === "number") return option;
      return "";
    },
    [getOptionLabel]
  );

  const renderValue = useCallback(() => <span>{placeholder}</span>, [placeholder]);

  const isSelected = useCallback(
    (option: T) =>
      value.some((v) => {
        if (getOptionValue) return getOptionValue(v) === getOptionValue(option);
        if (typeof option === "object") return JSON.stringify(v) === JSON.stringify(option);
        if (typeof option === "string" || typeof option === "number") return v === option;
        return false;
      }),
    [getOptionValue, value]
  );

  const isAllSelected = useMemo(
    () => value.length === options.length || false,
    [options.length, value.length]
  );

  const onChangeSelect = useCallback(
    (event: SelectChangeEvent<(string | number)[]>) => {
      const value = event.target.value as (string | number)[];
      if (value.includes("select-all") && value.length - 1 !== options.length) {
        onChange(options); //  전체선택
        return;
      }
      if (value.includes("select-all") && value.length - 1 === options.length) {
        onChange([options[0]]); // 전체선택해제
        return;
      }

      const selectedOptions = options.filter((option) => {
        if (getOptionValue) return value.includes(getOptionValue(option));
        if (typeof option === "object") return value.includes(JSON.stringify(option));
        if (typeof option === "string" || typeof option === "number") return value.includes(option);
        return false;
      });

      if (selectedOptions.length > 0) onChange(selectedOptions);
    },
    [getOptionValue, onChange, options]
  );

  const selectedValue = useMemo(
    () =>
      value.map((v) => {
        if (getOptionValue) return getOptionValue(v);
        if (typeof v === "object") return JSON.stringify(v);
        if (typeof v === "string" || typeof v === "number") return v;
        return "";
      }),
    [getOptionValue, value]
  );

  const defaultSelectedValue = useMemo(
    () =>
      defaultValue?.map((v) => {
        if (getOptionValue) return `${getOptionValue(v)}`;
        if (typeof v === "object") return JSON.stringify(v);
        if (typeof v === "string" || typeof v === "number") return `${v}`;
        return "";
      }) || [],
    [getOptionValue, defaultValue]
  );

  return (
    <FormControl fullWidth={fullWidth} css={selectStyle}>
      <InputLabel id="muliple-select-label" shrink>
        {label}
      </InputLabel>
      <Select
        size={size}
        labelId="muliple-select-label"
        multiple
        label={label}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        displayEmpty
        renderValue={renderValue}
        defaultValue={defaultSelectedValue}
        value={selectedValue}
        onChange={onChangeSelect}
        {...props}
      >
        <MenuItem value="select-all" key={"select-all"}>
          <Checkbox checked={isAllSelected} />
          전체선택
        </MenuItem>
        {options.map((option) => (
          <MenuItem value={_getOptionValue(option)} key={_getOptionValue(option)}>
            <Checkbox checked={isSelected(option)} />
            {_getOptionLabel(option)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelect;
