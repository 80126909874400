import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { ChangeEvent, FormEvent, useCallback, useState } from "react";
import { TextField } from "src/components/commons";
import useGetCompany from "src/hooks/apis/companies/useGetCompany";
import usePutCompanyApproval from "src/hooks/apis/companies/usePutCompanyApproval";
import { COMPANY, COMPANY_ALIAS, SETTLEMENT_ALIAS, STATUS } from "src/types";
import { approvalModalStyle } from "./styles";

interface ApprovalModalProps {
  onClose: () => void;
  open: { key: number; isOpen: boolean };
}

const ApprovalModal = ({ open, onClose }: ApprovalModalProps) => {
  const [rejectMessage, setRejectMessage] = useState("");
  const { data: companyData } = useGetCompany({ companyId: open.key });
  const { mutate } = usePutCompanyApproval();

  const onChangeRejectMessage = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setRejectMessage(value);
  }, []);

  const onReject = useCallback(() => {
    mutate({ companyId: open.key, message: rejectMessage, status: STATUS.REJECTED });
    onClose();
  }, [mutate, onClose, open.key, rejectMessage]);

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      mutate({ companyId: open.key, status: STATUS.ACTIVE });
      onClose();
    },
    [mutate, onClose, open.key]
  );

  return (
    <Dialog
      css={approvalModalStyle}
      maxWidth="md"
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-approval"
      aria-describedby="approve company"
    >
      <DialogTitle id="dialog-title">{companyData.company.name}</DialogTitle>
      <DialogContent>
        <form id="company-form" onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      className="field"
                      label="업체 분류"
                      readOnly
                      value={COMPANY_ALIAS[companyData.company.type]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="field"
                      label="업체명"
                      readOnly
                      value={companyData.company.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="field"
                      label="업체 주소"
                      readOnly
                      value={companyData.company.address}
                    />
                  </Grid>
                  {companyData.company.type === COMPANY.INDIVIDUAL ||
                  companyData.company.type === COMPANY.SYNDICATION_CLIENT_INDIVIDUAL ? (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          className="field"
                          label="주민등록번호"
                          readOnly
                          value={companyData.company.registration_no}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className="preview-label" variant="body2">
                          주민등록증
                        </Typography>
                        <Box className="preview-box">
                          {companyData.company.registration_img_url
                            ?.toLowerCase()
                            .includes(".pdf") ? (
                            <iframe
                              title="preview"
                              src={companyData.company.registration_img_url}
                            />
                          ) : (
                            <img alt="preview" src={companyData.company.registration_img_url} />
                          )}
                        </Box>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          className="field"
                          label="사업자등록번호"
                          placeholder="사업자등록번호를 입력해주세요."
                          readOnly
                          value={companyData.company.biz_registration_no}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className="preview-label" variant="body2">
                          사업자등록증
                        </Typography>
                        <Box className="preview-box">
                          {companyData.company.biz_registration_img_url
                            ?.toLowerCase()
                            .includes(".pdf") ? (
                            <iframe
                              title="preview"
                              src={companyData.company.biz_registration_img_url}
                            />
                          ) : (
                            <img alt="preview" src={companyData.company.biz_registration_img_url} />
                          )}
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      className="field"
                      label="관리자"
                      readOnly
                      value={companyData.company.manager_name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="field"
                      label="관리자 이메일"
                      readOnly
                      value={companyData.company.manager_email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="field"
                      label="운영 수수료"
                      readOnly
                      suffix="%"
                      value={companyData.company.settlement_commission_rate}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="field"
                      label="정산 분류"
                      readOnly
                      value={SETTLEMENT_ALIAS[companyData.company.settlement_type]}
                    />
                  </Grid>
                  <Grid item xs={12} className="description">
                    <TextField
                      multiline
                      minRows={8}
                      className="field"
                      label="거절 사유"
                      placeholder="관리자 등록 거절 시 신청자에게 메일로 발송됩니다."
                      value={rejectMessage}
                      onChange={onChangeRejectMessage}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onReject} color="inherit">
          거절
        </Button>
        <Button form="company-form" type="submit">
          승인
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApprovalModal;
