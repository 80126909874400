import { AxiosError, AxiosResponse } from "axios";
import { Moment } from "moment";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

interface Params {
  since: Moment;
  until: Moment;
  mediaKey: string;
  adId: string;
}

interface Response {
  code: 200 | 400 | 401 | 404;
  data?: {
    ad_request_no: string;
    advertise_cost: number;
    advertise_cost_currency: string;
    advertise_cost_raw: number;
    advertising_id: string;
    bid_id: string;
    campaign_id: string;
    campaign_name: string;
    campaign_type: number;
    contents_code: string;
    country: string;
    creative_id: string;
    event_datetime: number;
    idfv: string;
    is_completed: true;
    is_start_request: true;
    media_cost: number;
    media_cost_currency: string;
    media_cost_raw: number;
    media_key: string;
    os_version: string;
    performance_type: number;
    placement_code: string;
    placement_id: string;
    platform: string;
    report_type: number;
    reward_currency: string;
    reward_quantity: number;
    sdk_mediation_thirdparty_no: number;
    sdk_version: string;
    search_key: string;
    thirdparty_id: number;
    usn: string;
  }[];
  text: "ok" | "bad-request" | "unauthorized" | "not-found";
}

// 참여내역 조회
const useGetRewardHistory = ({ since, until, mediaKey, adId }: Params) => {
  const { handleError } = useApiError();
  const start = useMemo(() => since.format("YYYYMMDD"), [since]);
  const end = useMemo(() => until.format("YYYYMMDD"), [until]);
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["rewardCS/useGetRewardHistory", start, end, mediaKey, adId],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/reward-cs/histories`, {
        params: {
          since: start,
          until: end,
          media_key: mediaKey,
          adid: adId,
        },
      });
      return response.data.data;
    },
    { enabled: !!mediaKey && !!adId, onError: handleError }
  );

  const result = useMemo(() => (data ? data : []), [data]);

  return { data: result, ...rest };
};

export default useGetRewardHistory;
