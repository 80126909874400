import { css } from "@emotion/react";

export const partnerBoardStyle = css`
  margin-top: 2rem;
`;

export const addDeepLinkModalStyle = css`
  .dialog-content {
    #add-form {
      padding-top: 20px;
    }
  }
`;
