import { Paper, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { TooltipProps } from "recharts";
import DataFormat from "src/assets/formats";
import { DataKey as DailyDataKey } from "src/hooks/apis/dashboard/useGetAdminDaily";
import { DataKey as HourlyDataKey } from "src/hooks/apis/dashboard/useGetAdminHourly";

import { subDashboardTooltipStyle } from "./styles";

interface DSPTooltipProps extends TooltipProps<any, any> {
  dataKey: HourlyDataKey | DailyDataKey;
}

const SubDashboardTooltip = ({ payload, dataKey }: DSPTooltipProps) => {
  const tickFormatter = useCallback(
    (v: number) => {
      return DataFormat[dataKey].formatter(v);
    },
    [dataKey]
  );

  const data = useMemo(() => payload?.filter((entry) => entry.value > 0), [payload]);

  return (
    <Paper css={subDashboardTooltipStyle} elevation={2}>
      <Typography className="title" variant="h6">
        {DataFormat[dataKey].label}
      </Typography>
      {data?.map((entry, index) => (
        <p className="content" key={index} style={{ color: entry.color }}>
          {`${entry.name}: ${tickFormatter(entry.value)}`}
        </p>
      ))}
    </Paper>
  );
};
export default SubDashboardTooltip;
