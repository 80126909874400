import { MenuItem, Pagination, Select, SelectChangeEvent } from "@mui/material";
import { Box } from "@mui/system";
import { AgGridReact, AgGridReactProps, AgReactUiProps } from "ag-grid-react";
import { ChangeEvent, memo, useCallback } from "react";
import { AG_GRID_LOCALE_KR } from "src/assets/alias/ag-grid-locale";
import { optionStyle, tableStyle } from "./styles";

type BasicTableProps = Omit<AgGridReactProps | AgReactUiProps, "pagination"> & {
  className?: "ag-theme-alpine" | "ag-theme-balham";
  width?: string | number;
  height?: string | number;
  pagination?: {
    page: number;
    count: number;
    onChange: (value: number) => void;
  };
  pageSize?: {
    size: number;
    options: number[];
    onChange: (value: number) => void;
  };
};

const BasicTable = memo(
  ({
    className = "ag-theme-alpine",
    width = "100%",
    height = 400,
    pagination,
    pageSize,
    domLayout = "normal",
    defaultColDef,
    ...props
  }: BasicTableProps) => {
    const onChangePage = useCallback(
      (e: ChangeEvent<unknown>, page: number) => {
        if (pagination) {
          e.preventDefault();
          pagination.onChange(page);
        }
      },
      [pagination]
    );

    const onChangePageSize = useCallback(
      (e: SelectChangeEvent<number>) => {
        if (pageSize) {
          e.preventDefault();
          pageSize.onChange(+e.target.value);
        }
      },
      [pageSize]
    );

    return (
      <>
        <Box
          id={"grid-containe"}
          className={className}
          style={{ width: width, height: domLayout === "autoHeight" ? "fit-content" : height }}
          css={tableStyle}
        >
          <AgGridReact
            {...props}
            localeText={AG_GRID_LOCALE_KR}
            domLayout={domLayout}
            defaultColDef={{
              flex: 1,
              sortable: true,
              resizable: true,
              filter: true,
              ...defaultColDef,
            }}
          ></AgGridReact>
        </Box>
        <Box css={optionStyle}>
          {pagination && (
            <Pagination
              count={pagination.count}
              page={pagination.page}
              onChange={onChangePage}
              shape="rounded"
            />
          )}
          {pageSize && (
            <Select
              className="page-size"
              value={pageSize.size}
              size="small"
              onChange={onChangePageSize}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              {pageSize.options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
      </>
    );
  }
);

BasicTable.displayName = "BasicTable";

export default BasicTable;
