import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { Company, Settlement } from "src/types";
import API from "src/utils/api";
import { createFormData } from "src/utils/form-helper";

export interface Params {
  companyType: Company;
  companyName: string;
  managerUid: number;
  syndicationCompanyId?: number;
  registrationNo: string;
  address: string;
  settlementType?: Settlement;
  settlementCommissionRate?: number;
  useAdserver?: boolean;
  file: File;
}

interface Response {
  code: 200 | 400 | 401 | 403 | 404 | 409 | 500;
  data?: {
    id: number;
  };
  text:
    | "ok"
    | "bad-request"
    | "unauthorized"
    | "forbidden"
    | "not-found"
    | "conflict"
    | "internal-server-error";
}

// 신규 회사 생성
// 작업 완료 후 ssp_biz@igaworks.com으로 메일 발송
// 신디케이션 하위 회사(company_type: 4 or 5) 생성 시 신디케이션 회사 고유번호(syndication_company_id)를 전달
const usePostCompany = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const { data, ...rest } = useMutation<Response["data"], AxiosError, Params>(
    async ({
      companyType,
      companyName,
      managerUid,
      syndicationCompanyId,
      registrationNo,
      address,
      settlementType,
      settlementCommissionRate,
      useAdserver,
      file,
    }) => {
      const response: AxiosResponse<Response> = await API.default.post(
        `/companies`,
        createFormData({
          company_type: companyType,
          company_name: companyName,
          manager_uid: managerUid,
          syndication_company_id: syndicationCompanyId,
          registration_no: registrationNo,
          address: address,
          settlement_type: settlementType,
          settlement_commission_rate: settlementCommissionRate,
          use_adserver: useAdserver,
          file: file,
        }),
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      return response.data.data;
    },
    {
      onSuccess: () => {
        toast.success("업체가 등록되었습니다.");
        invalidate("companies/useGetCompanies");
      },
      onError: handleError,
    }
  );

  const result = useMemo(() => (data ? data : { id: 0 }), [data]);

  return { data: result, ...rest };
};

export default usePostCompany;
