import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { uid2Agreement as uid2AgreementState } from "src/atoms/uid2Agreement";
import { viewerCompany } from "src/atoms/viewerCompany";
import usePatchUid2Agreement from "src/hooks/apis/companies/usePatchUid2Agreement";
import useToast from "src/hooks/useToast";
import { ROLE, UserRole } from "src/types";

export default function Uid2Agreement() {
  const company = useRecoilValue(viewerCompany);
  const uid2Agreement = useRecoilValue(uid2AgreementState);
  const [open, setOpen] = useState(
    uid2Agreement.value === null &&
      ([ROLE.PublisherAdministrator, ROLE.IGAWorksAdministrator] as UserRole[]).includes(
        uid2Agreement.role
      )
  );
  const toast = useToast();

  const { mutate } = usePatchUid2Agreement(company.id);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickUpdate = (value: boolean) => {
    mutate(
      { uid2Agreement: value },
      {
        onSuccess: (response) => {
          if (response.code === 200) {
            toast.success("수정되었습니다.");
          }
        },
      }
    );
  };

  useEffect(() => {
    setOpen(
      uid2Agreement.value === null &&
        ([ROLE.PublisherAdministrator, ROLE.IGAWorksAdministrator] as UserRole[]).includes(
          uid2Agreement.role
        )
    );
  }, [uid2Agreement]);

  if (open) {
    return (
      <Dialog
        fullWidth
        open
        onClose={handleClose}
        aria-labelledby="representative-uid2-agreement"
        aria-describedby="uid2 agreement"
      >
        <DialogTitle id="dialog-title">UID 2.0 활용 동의 안내</DialogTitle>
        <Divider className="divider" />
        <DialogContent className="dialog-content">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                애드팝콘은 사용자 정보 기반 타겟팅 광고 및 광고 효율 향상을 목적으로 UID 2.0
                솔루션을 적용하여 앱 사용자의 개인 정보를 수집 및 활용 할 수 있습니다. 수집된
                개인정보는 암호화 처리 예정이며, 광고 송출 외의 목적으로 사용되지 않습니다.
                <a
                  href="https://www.adpopcorn.com/policy/privacy-optional"
                  target="_blank"
                  rel="noreferrer"
                >
                  개인정보보호 약정서
                </a>
                를 확인하시고 동의 진행 부탁 드립니다. 해당 내용에 동의하지 않으실 경우, 애드팝콘
                연동 가이드 내 setUIDIdentifier API는 활용 불가능 합니다.
              </Typography>
              <ul style={{ paddingLeft: "20px", marginTop: "12px" }}>
                <li style={{ marginBottom: "12px" }}>
                  <Typography
                    component="a"
                    target="_blank"
                    href="https://adpopcornssp.gitbook.io/ssp-sdk/android/undefined#id-6-uid-2.0-optional"
                    rel="noreferrer"
                    variant="body1"
                  >
                    Android UID 설정 연동 가이드 바로가기
                  </Typography>
                </li>
                <li>
                  <Typography
                    component="a"
                    href="https://adpopcornssp.gitbook.io/ssp-sdk/ios/undefined#id-5-uid-2.0"
                    rel="noreferrer"
                    variant="body1"
                  >
                    iOS UID 설정 연동 가이드 바로가기
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid item xs={6} justifyItems="center" mt={2}>
              <Button
                size="large"
                variant="contained"
                sx={{ width: "100%" }}
                onClick={() => handleClickUpdate(true)}
              >
                동의합니다.
              </Button>
            </Grid>
            <Grid item xs={6} justifyItems="center" mt={2}>
              <Button
                size="large"
                variant="outlined"
                sx={{ width: "100%" }}
                onClick={() => handleClickUpdate(false)}
              >
                동의하지 않습니다.
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }

  return null;
}
