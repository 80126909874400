import { css } from "@emotion/react";
import { blue, grey, red, teal } from "@mui/material/colors";

// 플레이스먼트 상세/추가 팝업
export const placementModalStyle = css`
  .dialog-content {
    #edit-form {
      padding-top: 20px;
      .switch {
        margin-left: 0;
      }
      .remote-tools {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
        button {
          min-width: 152px;
          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }
`;

// 광고 형식 추가 팝업
export const addCampaignModalStyle = css`
  .dialog-content {
    .preview {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    #add-campaign-form,
    #edit-campaign-form {
      padding-top: 20px;
      legend {
        max-width: 100%;
      }

      .component {
        margin-top: 1rem;
      }
      .switch {
        margin: 0;
      }
    }
  }
`;

// 히스토리 팝업
export const historyModalStyle = css`
  .dialog-content {
    padding-top: 20px;

    .history-list-container {
      border: 1px solid ${grey[400]};
      border-radius: 4px;
      overflow: auto;
      .history-list-box {
        min-height: 300px;
        max-height: 300px;
        overflow: auto;
      }
    }
  }
`;

export const accordionStyle = css`
  border: 1px solid ${grey[200]};
  color: ${grey[800]};
  &:not(:last-child) {
    border-bottom: 0;
  }
  &:before: {
    display: none;
  }
  .Mui-disabled {
    font-weight: 500;
    color: ${grey["900"]};
  }
`;

export const accordionSummaryStyle = css`
  min-height: initial;
  &.Mui-disabled {
    background-color: ${grey[200]};
    opacity: initial;
    padding-right: 1rem;
  }
`;

export const accordionDetailStyle = css`
  border-top: 1px solid ${grey[300]};
  padding: 1rem;
  color: ${grey[600]};
  .past {
    border-right: 0.5px solid ${grey[400]};
  }
  .current {
    border-left: 0.5px solid ${grey[400]};
  }
`;

export const paginationStyle = css`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  position: relative;
  .page-size {
    position: absolute;
    top: 0;
    right: 0;
    & > div {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
`;

// 히스토리 상세보기
export const historyDetailStyle = css`
  text-align: center;
  .header {
    color: ${teal[500]};
    font-weight: 600;
    text-align: center;
    margin-bottom: 1rem;
  }
  .active {
    color: ${blue[500]};
  }
  .inactive {
    color: ${red[500]};
  }
`;

export const dspMatchingInfoModalStyle = css`
  .dialog-content {
    #form {
      padding-top: 20px;
    }
  }
`;

export const editDspModalStyle = css`
  .back-to-dsp {
    cursor: pointer;
    font-size: 16px;
  }
  .active-dsp {
    cursor: pointer;
    font-size: 24px;
    fill: #52c41a;
  }
  .inactive-dsp {
    cursor: pointer;
    font-size: 24px;
    fill: ${red[500]};
  }
`;

export const editMediationModalStyle = css`
  .switch {
    margin: 1rem 0;
  }
`;
