import EmailManagement from "./EmailManagement";
import SettlementManagement from "./SettlementMangement";

const AdminSettlement = () => {
  return (
    <>
      <SettlementManagement />
      <EmailManagement />
    </>
  );
};

export default AdminSettlement;
