import { css } from "@emotion/react";
import { grey, teal } from "@mui/material/colors";

// 홈
export const homeStyle = css`
  .content {
    small {
      font-size: 1rem;
      margin-left: 0.5rem;
      color: ${grey[500]};
    }

    .month-revenue {
      font-weight: 500;
      color: ${teal[500]};
      margin-top: 1rem;
    }

    .divider {
      margin: 2rem 0;
    }

    .description {
      color: ${grey[600]};
      line-height: 1.5;
    }

    .recent-info {
      margin: auto;

      .revenue {
        margin-top: 1rem;
        color: ${teal[500]};
      }
      .week,
      .yesterday {
        padding: 0;
        margin-top: 1rem;
        padding-left: 1rem;
      }
      .week {
        border-right: 1px solid ${grey[400]};
      }
    }
  }
`;

// 도넛 차트
export const chartStyle = css`
  .recharts-legend-item-text {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 180px;
  }
`;

// 커스텀 툴팁
export const tooltipStyle = css`
  padding: 1rem;

  .title {
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }
  .content {
    color: ${grey[700]};
    margin-top: 0.5rem;
    padding: 0;
  }
`;

// Bidfloor
export const bidfloorStyle = css`
  .content {
    .tabs {
      margin-bottom: 2rem;
    }
  }
`;
