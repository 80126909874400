import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { MutationFunction, useMutation, UseMutationOptions } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";
import { NpayApp } from "./use-npay-app-list";

export type UpdateNpayAppValues = Pick<
  NpayApp,
  "key" | "commission_rate" | "daily_cap" | "point" | "status"
>;

type NpayAppMutationKey = "operation/npay/update-app";
type NpayAppMutationFn = MutationFunction<AxiosResponse, UpdateNpayAppValues>;

type NpayAppMutationOptions = Omit<
  UseMutationOptions<AxiosResponse, AxiosError, UpdateNpayAppValues>,
  "mutationKey" | "mutationFn"
>;

export default function useUpdateNpayApp(options?: NpayAppMutationOptions) {
  const { handleError } = useApiError();

  const mutationKey = useMemo<NpayAppMutationKey>(() => "operation/npay/update-app", []);

  const mutateFn = useCallback<NpayAppMutationFn>((values) => {
    const { key, ...rest } = values;
    const cookie = parseCookies();

    return API.consoleV3.put(`/v3/naverpay/media/${key}`, rest, {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
    });
  }, []);

  const mutation = useMutation(mutationKey, mutateFn, {
    onError: handleError,
    ...options,
  });

  return mutation;
}
