import { Button } from "@mui/material";
import { useFormik } from "formik";
import { useMemo } from "react";
import { TextField } from "src/components/commons";
import usePutDomain from "src/hooks/apis/blocklist/usePutDomain";
import { getHelperText, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";

interface EditGroupNameFormProps {
  id: number;
  name: string;
}

const EditGroupNameForm = ({ id, name = "" }: EditGroupNameFormProps) => {
  const { mutate: updateBlockUrl } = usePutDomain();

  const initialValues = useMemo(() => {
    return { name: name };
  }, [name]);

  const validationSchema = yup.object({
    name: yup.string().required("그룹명을 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateBlockUrl({ domainListId: id, domainListName: values.name });
    },
  });

  return (
    <form id="edit-form" onSubmit={handleSubmit}>
      <TextField
        className="field"
        label="그룹명"
        placeholder="그룹명을 입력하세요."
        required
        suffix={
          <Button color="primary" aria-label="directions" type="submit">
            변경하기
          </Button>
        }
        {...getFieldProps("name")}
        error={shouldErrorShows("name", touched, errors)}
        helperText={getHelperText("name", touched, errors)}
      />
    </form>
  );
};

export default EditGroupNameForm;
