import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useFormik } from "formik";
import { useMemo } from "react";
import { TextField } from "src/components/commons";
import { HeaderBidding } from "src/hooks/apis/thirdparties/useGetHeaderBiddingList";
import usePutHeaderBiddingDetail from "src/hooks/apis/thirdparties/usePutHeaderBiddingDetail";
import { MEDIA, MEDIA_ALIAS } from "src/types";
import { getHelperText, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { editHeaderBiddingModalStyle } from "./styles";

interface EditHeaderBiddingModalProps {
  headerBidding?: HeaderBidding;
  onClose: () => void;
  isOpen: boolean;
}

const EditHeaderBiddingModal = ({
  headerBidding,
  onClose,
  isOpen,
}: EditHeaderBiddingModalProps) => {
  const { mutate } = usePutHeaderBiddingDetail();

  const initialValues = useMemo(
    () => ({
      name: headerBidding?.name || "",
      mediaType: MEDIA_ALIAS[headerBidding?.media_type || MEDIA.APP],
      systemFee: headerBidding?.system_fee ?? 0,
    }),
    [headerBidding?.media_type, headerBidding?.name, headerBidding?.system_fee]
  );

  const validationSchema = yup.object({
    systemFee: yup
      .string()
      .test("systemFee", "숫자를 입력해주세요.", (value = "") => !isNaN(+value))
      .required("시스템 수수료를 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (headerBidding)
        mutate({
          thirdpartyId: headerBidding.id,
          systemFee: +values.systemFee,
        });
    },
  });

  return (
    <Dialog
      css={editHeaderBiddingModalStyle}
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-dsp"
      aria-describedby="edit dsp"
    >
      <DialogTitle id="dialog-title">{headerBidding?.name}</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="edit-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="헤더비딩 명"
                required
                disabled
                {...getFieldProps("name")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="매체 종류"
                required
                disabled
                {...getFieldProps("mediaType")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="시스템 수수료"
                required
                {...getFieldProps("systemFee")}
                error={shouldErrorShows("systemFee", touched, errors)}
                helperText={getHelperText("systemFee", touched, errors)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditHeaderBiddingModal;
