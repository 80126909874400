import { css } from "@emotion/react";

export const approvalModalStyle = css`
  #company-form {
    margin-top: 1rem;

    .preview-label {
      padding-left: 0.5rem;
    }

    .preview-box {
      display: flex;
      margin: 1rem 0;

      iframe,
      img {
        width: 100%;
        height: 150px;
        object-fit: contain;
      }
    }
  }
`;
