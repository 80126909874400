import { css } from "@emotion/react";
import { grey, teal } from "@mui/material/colors";

export const serviceBoardStyle = css`
  a.text {
    font-weight: 500;
    color: ${teal[500]};
    text-decoration: none;
  }
`;

export const clientInfoStyle = css`
  .label {
    font-weight: 500;
  }
  .value {
    color: ${grey[600]};
  }
`;

export const responseFormStyle = css`
  .response {
    margin-top: 1rem;
  }
  .submit {
    display: flex;
    justify-content: flex-end;
  }
`;
