import { Grid, MenuItem, Paper, SelectChangeEvent } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import DataFormat from "src/assets/formats";

import { Select } from "src/components/commons";
import { ReportChartData } from "src/hooks/apis/report/usePostOfferwallReport";
import { reportChartStyles } from "./styles";

interface OfferwallReportChartProps {
  defaultType: string;
  data: ReportChartData[];
}

const OfferwallReportChart = ({ defaultType, data }: OfferwallReportChartProps) => {
  const [type, setType] = useState(defaultType);

  // 초기 리포트 타입 세팅
  useEffect(() => {
    setType(defaultType);
  }, [defaultType]);

  const onChangeType = useCallback((event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setType(value);
  }, []);

  const typeOptions = useMemo(
    () => ["impression", "click", "conversion", "cvr", "uniqueUser", "mediaCost", "ctr", "ecpm"],
    []
  );

  const tickFormatter = useCallback(
    (key: string) => (v: unknown) => {
      return DataFormat[key].formatter(v as number);
    },
    []
  );

  return (
    <Paper css={reportChartStyles} elevation={2} className="chart">
      <Grid className="ssp-tools" container spacing={2}>
        <Grid item xs={4}>
          <Select label="항목" placeholder="항목선택" onChange={onChangeType} value={type}>
            {typeOptions.map((value) => (
              <MenuItem key={value} value={value}>
                {DataFormat[value].label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <ResponsiveContainer className="chart-container" width="100%" height="100%">
        <LineChart
          syncId="report"
          data={data}
          margin={{
            top: 32,
            left: 16,
            right: 16,
            bottom: 16,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" padding={{ left: 30, right: 30 }} />
          <YAxis
            label={{
              value: `(${DataFormat[type].unit})`,
              position: {
                x: 60,
                y: -15,
              },
            }}
            tickFormatter={tickFormatter(type)}
          />
          <Tooltip wrapperStyle={{ fontWeight: "bold" }} formatter={tickFormatter(type)} />
          <Legend verticalAlign="bottom" wrapperStyle={{ fontWeight: "bold", paddingTop: 16 }} />
          <Line
            type="monotone"
            name={DataFormat[type].label}
            dataKey={type}
            stroke="#29B095"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default OfferwallReportChart;
