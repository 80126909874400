import { Button, Grid } from "@mui/material";
import { GetGroupRowAggParams, ValueFormatterParams } from "ag-grid-community";
import { MouseEvent, useCallback, useMemo } from "react";
import DataFormat from "src/assets/formats";
import { BasicTable } from "src/components/commons";
import { ReportData } from "src/hooks/apis/report/usePostCoupangReport";

interface ReportBoardProps {
  onExport: (e: MouseEvent) => void;
  data: ReportData[];
}

const CoupangReportBoard = ({ data, onExport }: ReportBoardProps) => {
  // 테이블 상단 총합
  const totalRowData = useMemo(
    () => [
      {
        subParam: "총합",
        click: data?.reduce((acc, cur) => acc + +cur.click, 0) || 0,
        purchase: data?.reduce((acc, cur) => acc + +cur.purchase, 0) || 0,
        purchaseRevenue: data?.reduce((acc, cur) => acc + +cur.purchaseRevenue, 0) || 0,
        cancel: data?.reduce((acc, cur) => acc + +cur.cancel, 0) || 0,
        cancelRevenue: data?.reduce((acc, cur) => acc + +cur.cancelRevenue, 0) || 0,
        totalRevenue: data?.reduce((acc, cur) => acc + +cur.totalRevenue, 0) || 0,
        clientCommission: data?.reduce((acc, cur) => acc + +(cur?.clientCommission || 0), 0) || 0,
      },
    ],
    [data]
  );

  // 테이블 그룹 합계
  const groupRowData = useCallback(
    (params: GetGroupRowAggParams) =>
      params.nodes.reduce(
        (acc, cur) => {
          const data = cur.group ? cur.aggData : cur.data;

          acc.click += +data.click;
          acc.purchase += +data.purchase;
          acc.purchaseRevenue += +data.purchaseRevenue;
          acc.cancel += +data.cancel;
          acc.cancelRevenue += +data.cancelRevenue;
          acc.totalRevenue += +data.totalRevenue;
          acc.clientCommission += +data.clientCommission;
          return acc;
        },
        {
          click: 0,
          purchase: 0,
          purchaseRevenue: 0,
          cancel: 0,
          cancelRevenue: 0,
          totalRevenue: 0,
          clientCommission: 0,
        }
      ),
    []
  );

  return (
    <>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={12} className="download">
          <Button variant="outlined" onClick={onExport}>
            엑셀 다운로드
          </Button>
        </Grid>
      </Grid>
      <BasicTable
        rowData={data}
        columnDefs={columnDefs}
        animateRows={true}
        getGroupRowAgg={groupRowData}
        autoGroupColumnDef={{
          headerName: "",
          field: "subParam",
          pinned: "left",
          minWidth: 300,
          cellRendererParams: {
            suppressCount: true,
          },
          cellStyle: (params) => {
            if (params.node.rowPinned) {
              return {
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              };
            }
            return;
          },
        }}
        onGridColumnsChanged={(e) => {
          e.api.sizeColumnsToFit();
        }}
        pinnedTopRowData={totalRowData}
      />
    </>
  );
};

const columnDefs = [
  {
    headerName: "일자",
    field: "reportDate",
    rowGroup: true,
    hide: true,
  },
  {
    field: "partnerName",
    rowGroup: true,
    hide: true,
  },
  {
    field: "pubId",
    hide: true,
    rowGroup: true,
  },
  {
    field: "subParam",
    hide: true,
  },
  {
    headerName: "클릭 수",
    field: "click",
    minWidth: 150,
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value === undefined) return "";
      return DataFormat["click"].formatter(params.value || 0);
    },
  },
  {
    headerName: "구매 수",
    field: "purchase",
    minWidth: 150,
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value === undefined) return "";
      return DataFormat["purchase"].formatter(params.value);
    },
  },
  {
    headerName: "구매 금액",
    field: "purchaseRevenue",
    minWidth: 150,
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value === undefined) return "";
      return DataFormat["purchaseRevenue"].formatter(params.value);
    },
  },
  {
    headerName: "취소 수",
    field: "cancel",
    minWidth: 150,
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value === undefined) return "";
      return DataFormat["cancel"].formatter(params.value);
    },
  },
  {
    headerName: "취소 금액",
    field: "cancelRevenue",
    minWidth: 150,
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value === undefined) return "";
      return DataFormat["cancelRevenue"].formatter(params.value);
    },
  },
  {
    headerName: "총 매출 금액",
    field: "totalRevenue",
    minWidth: 150,
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value === undefined) return "";
      return DataFormat["totalRevenue"].formatter(params.value);
    },
  },
  {
    headerName: "순매체비",
    field: "clientCommission",
    minWidth: 150,
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value === undefined) return "";
      return DataFormat["clientCommission"].formatter(params.value);
    },
  },
];

export default CoupangReportBoard;
