import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useFormik } from "formik";
import moment, { Moment } from "moment";
import { useCallback, useMemo } from "react";
import DataFormat from "src/assets/formats";
import { DateField, TextField } from "src/components/commons";
import useGetCriteria from "src/hooks/apis/payments/useGetCriteria";
import usePostCriteria from "src/hooks/apis/payments/usePostCriteria";
import { SettlementRevenueConfirmationDate } from "src/types";
import { criteriaSettingModalStyle } from "./styles";

interface CriteriaSettingProps {
  onClose: () => void;
  isOpen: boolean;
  date: Moment;
}

const CriteriaSettingModal = ({ isOpen, onClose, date }: CriteriaSettingProps) => {
  const { data: criteriaData } = useGetCriteria({ month: date.format("YYYYMM") });
  const { mutate } = usePostCriteria();

  const initialValues = useMemo(
    () => ({
      month: moment(`${criteriaData.month}01`).format("YYYY년 MM월") || "",
      exchangeRate: `${DataFormat.exchangeRate.formatter(criteriaData.exchange_rate)}` || "",
      expirationDate: moment(criteriaData.expiration_date) || moment(),
    }),
    [criteriaData.exchange_rate, criteriaData.expiration_date, criteriaData.month]
  );

  const { getFieldProps, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate({
        exchangeRate: criteriaData.exchange_rate,
        expirationDate: values.expirationDate.format("YYYYMMDD"),
        month: criteriaData.month,
      });
      onClose();
    },
  });

  const onChangeExpirationDate = useCallback(
    (value: Moment | null, _keyboardInputValue?: string) => {
      if (value && value.isValid()) {
        setFieldValue("expirationDate", value);
      }
      return;
    },
    [setFieldValue]
  );

  return (
    <Dialog
      css={criteriaSettingModalStyle}
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-criteira"
      aria-describedby="edit criteira"
    >
      <DialogTitle id="dialog-title">정산 기준 설정</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="criteira-form" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="정산 기준 월"
                disabled
                {...getFieldProps("month")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="기준 환율"
                disabled
                {...getFieldProps("exchangeRate")}
              />
            </Grid>
            <Grid item xs={12}>
              <DateField
                label="세금계산서 발행 마감일"
                disabled={true}
                shouldDisableDate={(d: unknown) => {
                  const day = d as Moment;
                  if (day) {
                    return (
                      date.format("YYYYMM") >= moment(day).format("YYYYMM") ||
                      Number.parseInt(moment(day).format("D")) < SettlementRevenueConfirmationDate
                    );
                  }
                  return false;
                }}
                {...getFieldProps("expirationDate")}
                onChange={onChangeExpirationDate}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CriteriaSettingModal;
