import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  schemeNo: number;
}

interface Response {
  code: 200 | 401 | 404 | 500;
  text: "ok" | "unauthorized" | "not-found" | "internal-server-error";
}

// 스키마 삭제
// 해당 스키마를 사용하는 앱이 있을 경우 삭제 불가
const useDeleteAppScheme = () => {
  const toast = useToast();
  const { handleError } = useApiError({
    500: (_error: AxiosError) => {
      toast.error(`해당 스키마를 사용하는 앱이 존재합니다.`);
    },
  });
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ schemeNo }) => {
      const response: AxiosResponse<Response> = await API.default.delete(
        `/media/app/schemes/${schemeNo}`
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("삭제되었습니다.");
        invalidate("media/useGetAppScheme");
      },
      onError: handleError,
    }
  );

  return result;
};

export default useDeleteAppScheme;
