import { Button, Grid } from "@mui/material";
import { CellClickedEvent, ValueFormatterParams } from "ag-grid-community";
import moment from "moment";
import { MouseEvent, useCallback, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import { BasicTable, DeleteButton } from "src/components/commons";
import useDeleteDomains from "src/hooks/apis/blocklist/useDeleteDomains";
import useGetDomains, { Domain } from "src/hooks/apis/blocklist/useGetDomains";
import useOpenModal from "src/hooks/useOpenModal";
import AddBlockURLModal from "./AddBlockURLModal";
import EditBlockURLModal from "./EditBlockURLModal";

const BlockURLBoard = () => {
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedBlockName, setSelectedBlockName] = useState("");

  const [openAddModal, onShowAddModal, onCloseAddModal] = useOpenModal(null);
  const [openEditModal, onShowEditModal, onCloseEditModal] = useOpenModal(0);

  const [company] = useRecoilState(viewerCompany);

  const { data: categoryData } = useGetDomains({ companyKey: company.key, pageNo, pageSize });

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: categoryData.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, categoryData.pages, onChangePage, pageSize, onChangePageSize]
  );

  const onShowEditGroupModal = useCallback(
    (e: CellClickedEvent) => {
      if (e.colDef.headerName !== "Delete") {
        setSelectedBlockName(e.data.domain_list_name);
        onShowEditModal(e, e.data.domain_list_id);
      }
    },
    [onShowEditModal]
  );

  return (
    <>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={12} className="register">
          <Button variant="outlined" onClick={onShowAddModal}>
            차단 그룹 추가
          </Button>
        </Grid>
      </Grid>
      <BasicTable
        getRowId={(params) => params.data.domain_list_id}
        animateRows
        rowData={categoryData.domain_list}
        columnDefs={columnDefs}
        onCellClicked={onShowEditGroupModal}
        {...paginationInfo}
      />
      {openAddModal.isOpen && (
        <AddBlockURLModal open={openAddModal.isOpen} onClose={onCloseAddModal} />
      )}
      {openEditModal.isOpen && (
        <EditBlockURLModal
          name={selectedBlockName}
          open={openEditModal}
          onClose={onCloseEditModal}
        />
      )}
    </>
  );
};

const columnDefs = [
  { headerName: "그룹명", field: "domain_list_name", sortable: true },
  {
    headerName: "그룹내 차단된 URL 갯수",
    field: "domain_ids",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const count = param.data.domain_ids.length || 0;
      return `${count} 개`;
    },
  },
  {
    headerName: "등록일",
    field: "created_at",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const createdAt = param.value * 1000;
      return moment(createdAt).format("YYYY-MM-DD");
    },
  },
  {
    headerName: "수정일",
    field: "updated_at",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const updatedAt = param.value * 1000;
      return moment(updatedAt).format("YYYY-MM-DD");
    },
  },
  {
    headerName: "Delete",
    field: "domain_list_id",
    cellRenderer: (params: { data: Domain }) => <RemoveButton id={params.data.domain_list_id} />,
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

function RemoveButton({ id }: { id: number }) {
  const { mutate } = useDeleteDomains();

  const onClickDeleteButton = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      mutate({ domainListIds: [id] });
    },
    [id, mutate]
  );

  return (
    <DeleteButton onConfirm={onClickDeleteButton}>정말 해당 그룹을 삭제하시겠습니까?</DeleteButton>
  );
}

export default BlockURLBoard;
