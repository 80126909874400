import moment, { Moment } from "moment";
import { NpayCsType } from "src/hooks/apis/npay/use-npay-cs-list";
import { TestContext } from "yup";
import { AnyObject } from "yup/lib/types";

export const MIN_DATE = moment("2024-08-01").startOf("d");
export const MAX_DATE = moment().startOf("d").subtract(1, "d");

const isValidDateRange = (date: Moment) => {
  return date.isSameOrAfter(MIN_DATE) && date.isSameOrBefore(MAX_DATE);
};

export const SEARCH_PARAMS_VALIDATOR = {
  pageNo(value?: string) {
    if (!value) {
      return true;
    }

    const num = parseInt(value);
    return Number.isInteger(num) && num > 0;
  },
  pageSize(value?: string) {
    if (!value) {
      return true;
    }

    return ["20", "30", "50", "100"].includes(value);
  },
  orders(value?: string | string[]) {
    if (!value) {
      return true;
    }

    return Array.isArray(value) || typeof value === "string";
  },
  since(value: string | undefined, context: TestContext<AnyObject>) {
    if (!value) {
      return false;
    }

    const since = moment(value);
    const until = moment(context.parent.until);

    // MIN~MAX 사이 && sameOrBefore endDate && endDate - startDate < 60
    if (
      since.isValid() &&
      until.isValid() &&
      isValidDateRange(since) &&
      isValidDateRange(until) &&
      since.isSameOrBefore(until)
    ) {
      const daysDiff = until.diff(since, "d");
      return daysDiff < 60;
    }

    return false;
  },
  until(value: string | undefined, context: TestContext<AnyObject>) {
    if (!value) {
      return false;
    }

    const since = moment(context.parent.until);
    const until = moment(value);

    // MIN~MAX 사이 && sameOrBefore endDate && endDate - startDate < 60
    if (
      since.isValid() &&
      until.isValid() &&
      isValidDateRange(since) &&
      isValidDateRange(until) &&
      since.isSameOrBefore(until)
    ) {
      const daysDiff = until.diff(since, "d");
      return daysDiff < 60;
    }

    return false;
  },
};

export const TYPE_OPTIONS = [
  {
    label: "전체 항목",
    value: 0,
  },
  {
    label: "네이버페이 포인트가 적립되지 않아요",
    value: NpayCsType.NO_NAVER_POINT,
  },
  {
    label: "비정상적으로 한도가 초과되었어요",
    value: NpayCsType.NO_ADS,
  },
  {
    label: "참여 가능한 광고가 없어요",
    value: NpayCsType.NO_ADS,
  },
  {
    label: "광고가 종료되지 않아요",
    value: NpayCsType.AD_NOT_ENDED,
  },
  {
    label: "이외 다른 도움이 필요해요",
    value: NpayCsType.OTHER_HELP,
  },
];
