import { Template } from "./campaign";

export const HISTORY = {
  CAMPAIGN: "campaign",
  BIDFLOOR: "bidfloor",
  DSP: "dsp",
  MEDIATION: "mediation",
  HEADER_BIDDING: "headerbidding",
} as const;

export type History = Union<typeof HISTORY>;

export const HISTORY_ALIAS = {
  [HISTORY.CAMPAIGN]: "광고형식",
  [HISTORY.BIDFLOOR]: "Bid Floor",
  [HISTORY.DSP]: "DSP",
  [HISTORY.MEDIATION]: "미디에이션",
  [HISTORY.HEADER_BIDDING]: "헤더비딩",
};

export interface MediationHistory {
  order: number;
  status: number;
  match_info: unknown;
  thirdparty_id: number;
  thirdparty_name: string;
  bidfloor: number;
}

export interface DSPHistory {
  status: number;
  match_info: unknown;
  thirdparty_id: number;
  thirdparty_name: string;
  individual_status: number;
}

export interface HeaderBiddingHistory {
  status: number;
  match_info: unknown;
  thirdparty_id: number;
  thirdparty_name: string;
}

export interface CampaignHistory {
  campaign_type: number;
  placement_name: string;
  is_sdk_mediation: boolean;
  // 전면 비디오, 전면
  minviewtime?: number;
  // 네이티브
  ctatext_required?: boolean;
  desc_required?: boolean;
  icon_image_required?: boolean;
  main_image_required?: boolean;
  title_required?: boolean;
  // 전면 비디오, 리워드 비디오
  mute?: boolean;
  // 전면
  companion_use?: boolean;
}

export interface CompanionHistory {
  campaign_type: number;
  ctatext_required: boolean;
  desc_required: boolean;
  icon_image_required: boolean;
  main_image_required: boolean;
  parent_p_id: string;
  placement_name: string;
  template_no: Template;
  title_required: boolean;
}

export interface BidfloorHistory {
  bidfloor: number;
  bidfloor_status: number;
}

export type HistoryDetailType =
  | MediationHistory[]
  | DSPHistory[]
  | CampaignHistory
  | CompanionHistory
  | BidfloorHistory;
