import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import { useMemo, useCallback, ChangeEvent } from "react";
import { NpayApp } from "src/hooks/apis/npay/use-npay-app-list";
import useUpdateNpayApp from "src/hooks/apis/npay/use-update-npay-app";
import useToast from "src/hooks/useToast";
import { shouldErrorShows, getHelperText } from "src/utils/form-helper";
import { NO_VALUE, numberRegex, numberFormat } from "./const";
import { TextField } from "src/components/commons";
import { number, object, string } from "yup";

type UpdateNpayAppFormValues = Pick<
  NpayApp,
  "company_name" | "name" | "key" | "commission_rate" | "daily_cap" | "point" | "status"
>;

const updateNpayAppFormSchema = object({
  company_name: string().required(),
  name: string().required(),
  key: string().required(),
  commission_rate: number()
    .required("네이버페이 포인트 수수료율을 입력해 주세요.")
    .notOneOf([NO_VALUE], "네이버페이 포인트 수수료율을 입력해 주세요."),
  daily_cap: number()
    .required("일 한도를 입력해 주세요.")
    .notOneOf([NO_VALUE], "일 한도 값을 입력해 주세요."),
  point: number()
    .required("1회 지급 포인트량을 입력해 주세요.")
    .moreThan(0, "0 이상 값을 입력해 주세요.")
    .notOneOf([NO_VALUE], "1회 지급 포인트량을 입력해 주세요."),
});

export default function UpdateNpayAppModal(props: {
  app: NpayApp;
  onUpdate: () => Promise<void>;
  onClose: VoidFunction;
}) {
  const { app, onUpdate, onClose } = props;

  const toast = useToast();
  const { mutate: updateNpayApp, isLoading: isUpdating } = useUpdateNpayApp();

  const initialValues = useMemo<UpdateNpayAppFormValues>(
    () => ({
      company_name: app.company_name,
      name: app.name,
      key: app.key,
      commission_rate: app.commission_rate,
      daily_cap: app.daily_cap,
      point: app.point,
      status: app.status,
    }),
    [app]
  );

  const handleUpdate = (values: UpdateNpayAppFormValues) => {
    updateNpayApp(values, {
      onSuccess: () => {
        toast.success("저장되었습니다.");
        onClose();
        onUpdate();
      },
    });
  };

  const { values, errors, touched, handleSubmit, setFieldValue, handleBlur, isValid } = useFormik({
    initialValues,
    onSubmit: handleUpdate,
    validationSchema: updateNpayAppFormSchema,
  });

  const onChangeValue = useCallback(
    (field: "commission_rate" | "daily_cap" | "point") => (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (value === "") {
        setFieldValue(field, NO_VALUE);
        return;
      }

      const commaRemoved = value
        .split("")
        .filter((char) => char !== ",")
        .join("");

      const isValidNumber = numberRegex.test(commaRemoved);

      if (isValidNumber) {
        setFieldValue(field, Number(commaRemoved));
      }
    },
    [setFieldValue]
  );

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle id="edit-npay-app-modal-title">네이버페이 앱 매체 수정</DialogTitle>
      <DialogContent className="edit-npay-app-modal-dialog-content">
        <Stack
          component="form"
          id="edit-npay-app-modal"
          pt="20px"
          spacing={2}
          onSubmit={handleSubmit}
        >
          {/* 업체 명 */}
          <TextField required label="업체 명" value={values.company_name} aria-readonly disabled />
          {/* 앱 매체 명 */}
          <TextField required label="앱 매체 명" value={values.name} aria-readonly disabled />
          {/* 매체 키 */}
          <TextField required label="매체 키" value={values.key} aria-readonly disabled />
          {/* 네이버페이 포인트 수수료율 */}
          <TextField
            required
            label="네이버페이 포인트 수수료율"
            InputProps={{
              sx: { "& > input": { textAlign: "end" } },
              endAdornment: (
                <Typography component="span" variant="body2" sx={{ ml: 1, flexShrink: 0 }}>
                  %
                </Typography>
              ),
            }}
            placeholder="네이버페이 포인트 수수료율을 입력해 주세요."
            value={values.commission_rate === NO_VALUE ? "" : numberFormat(values.commission_rate)}
            onChange={onChangeValue("commission_rate")}
            onBlur={handleBlur("commission_rate")}
            error={shouldErrorShows("commission_rate", touched, errors)}
            helperText={
              getHelperText("commission_rate", touched, errors) ||
              "수수료율은 입력한 당월 부터 반영 됩니다."
            }
            disabled={isUpdating}
          />
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              required
              label="일 한도(인당)"
              InputProps={{
                sx: { "& > input": { textAlign: "end" } },
                endAdornment: (
                  <Typography component="span" variant="body2" sx={{ ml: 1, flexShrink: 0 }}>
                    회/일
                  </Typography>
                ),
              }}
              placeholder="일 한도를 입력해 주세요."
              value={values.daily_cap === NO_VALUE ? "" : numberFormat(values.daily_cap)}
              onChange={onChangeValue("daily_cap")}
              onBlur={handleBlur("daily_cap")}
              error={shouldErrorShows("daily_cap", touched, errors)}
              helperText={
                getHelperText("daily_cap", touched, errors) ||
                "일 한도 수정은 수정 즉시 반영 됩니다."
              }
              disabled={isUpdating}
            />
            <TextField
              required
              label="1회당 지급 포인트량"
              InputProps={{
                sx: { "& > input": { textAlign: "end" } },
                endAdornment: (
                  <Typography component="span" variant="body2" sx={{ ml: 1, flexShrink: 0 }}>
                    포인트/회
                  </Typography>
                ),
              }}
              placeholder="1회 지급 포인트량을 입력해 주세요."
              value={values.point === NO_VALUE ? "" : numberFormat(values.point)}
              onChange={onChangeValue("point")}
              onBlur={handleBlur("point")}
              error={shouldErrorShows("point", touched, errors)}
              helperText={
                getHelperText("point", touched, errors) ||
                "회당 포인트 지급량은 수정 즉시 반영 됩니다."
              }
              disabled={isUpdating}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button type="button" onClick={onClose} color="inherit" disabled={isUpdating}>
          취소
        </Button>
        <Button type="submit" form="edit-npay-app-modal" disabled={!isValid || isUpdating}>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}
