import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, FocusEvent, SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import useGetAppMediaList, { AppMedia } from "src/hooks/apis/media/useGetAppMediaList";
import usePostAppPlacement from "src/hooks/apis/placements/usePostAppPlacement";
import useOpenModal from "src/hooks/useOpenModal";
// import useUser from "src/hooks/useUser";

import { viewerCompany } from "src/atoms/viewerCompany";
import { TextField } from "src/components/commons";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import {
  APP_PLATFORM_ALIAS,
  CAMPAIGN,
  Campaign,
  CAMPAIGN_ALIAS,
  STATUS,
  Template,
} from "src/types";

import AddCampaignModal from "./AddCampaignModal";
import { placementModalStyle } from "./styles";

export interface CampaignInfo {
  type: Campaign;
  size?: number;
  minViewTime?: number;
  iconImageRequired?: boolean;
  titleRequired?: boolean;
  descRequired?: boolean;
  mainImageRequired?: boolean;
  ctatextRequired?: boolean;
  mute?: boolean;
  templateNo?: Template;
}

interface AddPlacementModalProps {
  onClose: () => void;
  open: boolean;
}

const AddPlacementModal = ({ onClose, open }: AddPlacementModalProps) => {
  // const { isAdmin } = useUser();
  const [media, setMedia] = useState<AppMedia | null>(null);
  const [openAddModal, onShowAddCampaignModal, onCloseAddCampaignModal] = useOpenModal(null);
  const [company] = useRecoilState(viewerCompany);
  const { data: mediaData } = useGetAppMediaList({ companyKey: company.key });
  const { mutate } = usePostAppPlacement();

  /** 광고 형식 정보 */
  const [campaignInfo, setCampaignInfo] = useState<CampaignInfo>({ type: CAMPAIGN.Banner });

  const initialValues = useMemo(
    () => ({
      name: "",
      mediaKey: "",
      campaign: "",
      bidfloorStatus: false,
      bidfloor: 0,
      cpcBidfloorStatus: false,
      cpcBidfloor: 0,
      isRewardCpc: false,
    }),
    []
  );

  const validationSchema = yup.object({
    name: yup.string().required("플레이스먼트 명을 입력해주세요."),
    mediaKey: yup.string().required("매체를 선택해주세요."),
    campaign: yup.string().required("광고 형식을 선택해주세요."),
    bidfloorStatus: yup.boolean(),
    bidfloor: yup.number().when("bidfloorStatus", {
      is: true,
      then: yup.number().required("값을 입력해주세요.").moreThan(0, "값을 입력해주세요."),
    }),
    cpcBidfloorStatus: yup.boolean(),
    cpcBidfloor: yup.number().when("cpcBidfloorStatus", {
      is: true,
      then: yup.number().required("값을 입력해주세요.").moreThan(0, "값을 입력해주세요."),
    }),
    isRewardCpc: yup.boolean(),
  });

  const {
    getFieldProps,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    values,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      mutate({
        bidfloor: +values.bidfloor,
        bidfloorCurrency: "USD",
        bidfloorStatus: values.bidfloorStatus ? STATUS.ACTIVE : STATUS.SUSPEND,
        checkViewability: true,
        cpcBidfloor: +values.cpcBidfloor,
        cpcBidfloorCurrency: "USD",
        cpcBidfloorStatus: values.cpcBidfloorStatus ? STATUS.ACTIVE : STATUS.SUSPEND,
        ctaTextRequired: campaignInfo?.ctatextRequired,
        descRequired: campaignInfo?.descRequired,
        iconImageRequired: campaignInfo?.iconImageRequired,
        isSDKMediation: false,
        mainImageRequired: campaignInfo?.mainImageRequired,
        mediaKey: values.mediaKey,
        minViewTime: campaignInfo?.minViewTime,
        mute: campaignInfo?.mute,
        name: values.name,
        sizeType: campaignInfo?.size,
        titleRequired: campaignInfo?.titleRequired,
        type: campaignInfo.type,
        // NOTE: type === CAMPAIGN.Native인 경우에만 전달
        ...(campaignInfo.type === CAMPAIGN.Native && {
          templateNo: campaignInfo.templateNo,
        }),
        // Campaign 1~6
        isRewardCpc: values.isRewardCpc,
      });
      onClose();
    },
  });

  const onChangeMedia = useCallback(
    (_: SyntheticEvent<unknown>, v: AppMedia | null) => {
      setMedia(v);
      setFieldValue("mediaKey", v?.key);
    },
    [setFieldValue]
  );

  const onSetCampaign = useCallback(
    (values: CampaignInfo) => {
      setCampaignInfo(values);
      setValues((prev) => {
        const campaign = CAMPAIGN_ALIAS[values.type];

        return {
          ...prev,
          campaign: campaign,
          isRewardCpc: prev.campaign === campaign ? prev.isRewardCpc : false,
        };
      });
    },
    [setValues]
  );

  const onChangeBidfloor = useCallback(
    (field: "bidfloor" | "cpcBidfloor") => (e: ChangeEvent<HTMLInputElement>) => {
      const value =
        e.target.value.replace(
          REG_EXP.bidfloor,
          e.target.value.substring(0, e.target.value.length - 1)
        ) || 0;
      if (+(value || 0) < 1000000) {
        setFieldValue(field, value);
      }
    },
    [setFieldValue]
  );

  const onBlurBidfloor = useCallback(
    (field: "bidfloor" | "cpcBidfloor") => (e: FocusEvent) => {
      setFieldValue(field, parseFloat(`${values[field] || 0}`));
      handleBlur(e);
    },
    [handleBlur, setFieldValue, values]
  );

  const isInstreamOrS2S = (
    [
      CAMPAIGN.S2SInstreamVideo,
      // CAMPAIGN.Instream,
      CAMPAIGN.S2SRV,
    ] as Campaign[]
  ).includes(campaignInfo.type);

  return (
    <Dialog
      css={placementModalStyle}
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="representative-placement"
      aria-describedby="add placement"
    >
      <DialogTitle id="dialog-title">신규 플레이스먼트</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="edit-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                className="field"
                size="small"
                options={mediaData.media}
                getOptionLabel={(media) =>
                  `[${APP_PLATFORM_ALIAS[media.platform_type]}] ${media.name}`
                }
                renderInput={({ InputLabelProps, ...params }) => (
                  <TextField
                    {...params}
                    label="매체 명"
                    placeholder="매체를 선택해주세요."
                    required
                    error={shouldErrorShows("mediaKey", touched, errors)}
                    helperText={getHelperText("mediaKey", touched, errors)}
                  />
                )}
                value={media}
                onChange={onChangeMedia}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="플레이스먼트 명"
                placeholder="플레이스먼트 명을 입력하세요."
                required
                {...getFieldProps("name")}
                error={shouldErrorShows("name", touched, errors)}
                helperText={getHelperText("name", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="광고 형식"
                placeholder="광고 형식을 설정해주세요."
                readOnly
                required
                suffix={
                  <Button color="primary" onClick={onShowAddCampaignModal}>
                    설정하기
                  </Button>
                }
                {...getFieldProps("campaign")}
                error={shouldErrorShows("campaign", touched, errors)}
                helperText={getHelperText("campaign", touched, errors)}
              />
            </Grid>
            <Grid item xs={8}>
              {/* 네이버 S2S, 인스트림은 사용 X */}
              {values.campaign !== "" && !isInstreamOrS2S && (
                <FormControlLabel
                  className="switch"
                  control={<Switch color="primary" />}
                  label="BidFloor"
                  labelPlacement="start"
                  {...getFieldProps("bidfloorStatus")}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {values.bidfloorStatus && (
                <TextField
                  className="field"
                  required
                  prefix={<span className="ssp-input-currency-prefix">USD</span>}
                  {...getFieldProps("bidfloor")}
                  onChange={onChangeBidfloor("bidfloor")}
                  onBlur={onBlurBidfloor("bidfloor")}
                  error={shouldErrorShows("bidfloor", touched, errors)}
                  helperText={getHelperText("bidfloor", touched, errors)}
                />
              )}
            </Grid>
            {values.campaign !== "" &&
              (
                [
                  CAMPAIGN.Banner,
                  CAMPAIGN.Interstitial,
                  CAMPAIGN.Native,
                  CAMPAIGN.RewardVideo,
                  CAMPAIGN.FrontVideo,
                  CAMPAIGN.InterstitialVideo,
                ] as Campaign[]
              ).includes(campaignInfo.type) && (
                <Grid item xs={8}>
                  <FormControlLabel
                    className="switch"
                    control={<Switch color="primary" checked={values.isRewardCpc} />}
                    label="Reward Banner"
                    labelPlacement="start"
                    {...getFieldProps("isRewardCpc")}
                  />
                </Grid>
              )}
            {/* {isAdmin && (
              <Grid item xs={8}>
                <FormControlLabel
                  className="switch"
                  control={<Switch color="primary" />}
                  label="CPC BidFloor"
                  labelPlacement="start"
                  {...getFieldProps("cpcBidfloorStatus")}
                />
              </Grid>
            )} */}
            {/* <Grid item xs={4}>
              {values.cpcBidfloorStatus && (
                <TextField
                  className="field"
                  required
                  prefix={<span className="ssp-input-currency-prefix">USD</span>}
                  {...getFieldProps("cpcBidfloor")}
                  onChange={onChangeBidfloor("cpcBidfloor")}
                  error={shouldErrorShows("cpcBidfloor", touched, errors)}
                  helperText={getHelperText("cpcBidfloor", touched, errors)}
                />
              )}
            </Grid> */}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-form" type="submit">
          저장
        </Button>
      </DialogActions>
      {openAddModal.isOpen && (
        <AddCampaignModal
          open={openAddModal.isOpen}
          onClose={onCloseAddCampaignModal}
          onSubmit={onSetCampaign}
        />
      )}
    </Dialog>
  );
};

export default AddPlacementModal;
