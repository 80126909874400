import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { Status, Thirdparty } from "src/types";
import API from "src/utils/api";

interface Params {
  placementId: string;
  isWebMediation?: boolean;
  thirdparties: {
    id: number;
    status: Status;
  }[];
  type: Thirdparty;
}

interface Response {
  code: 200 | 400 | 401 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "internal-server-error";
}

// 서드파티 상태, 순서 설정
// type - DSP(2), 미디에이션(4), 헤더비딩(5)
// “thirdparties” 배열의 순서가 미디에이션 순서 (DSP, 헤더비딩은 순서가 필요 없음)
// "thirdparties.[status]"를 전달하지 않을 경우 비활성상태로 변경
const usePutWebThirdpartyList = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ placementId, isWebMediation, thirdparties, type }) => {
      const response: AxiosResponse<Response> = await API.default.put(
        `/placements/web/${placementId}/thirdparties`,
        {
          is_web_mediation: isWebMediation,
          thirdparties,
          type,
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("수정되었습니다.");
        invalidate("placements/useGetWebThirdpartyList");
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePutWebThirdpartyList;
