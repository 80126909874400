import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FaCheckCircle, FaCircle } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import { BasicTable, MultipleSelect, Select, TextField } from "src/components/commons";
import useGetCompanies, { CompanyData } from "src/hooks/apis/companies/useGetCompanies";
import useGetAppMediaList, { AppMedia } from "src/hooks/apis/media/useGetAppMediaList";
import useGetAppPlacementList, {
  AppPlacement,
} from "src/hooks/apis/placements/useGetAppPlacementList";
import { DSP } from "src/hooks/apis/thirdparties/useGetDSPList";
import usePatchDSP from "src/hooks/apis/thirdparties/usePatchDSP";
import usePostDSPMatch, {
  MatchKey,
  MatchKeyInfo,
} from "src/hooks/apis/thirdparties/usePostDSPMatch";
import useOpenModal from "src/hooks/useOpenModal";
import { COMPANY, Media, STATUS, Status } from "src/types";
import { MATCH, Match, MATCH_ALIAS } from "src/types/match";
import MatchingDSPInfoModal from "./MatchingInfoModal";
import { dspPlacementModalStyle } from "./styles";

export interface MatchingPlacement {
  thirdpartyId: number;
  dsp_name: string;
  media_key: string;
  media_name: string;
  id: string;
  individual_status: Status;
  match_key: MatchKey;
  match_key_info: MatchKeyInfo[];
  name: string;
  status: Status;
  type: Media;
}

interface AppPlacementModalProps extends DSP {
  onClose: () => void;
  open: { key: number; isOpen: boolean };
}

const AppPlacementModal = ({ name, open, onClose }: AppPlacementModalProps) => {
  const [company, setCompany] = useState<CompanyData | null>(null);
  const [media, setMedia] = useState<AppMedia[]>([]);
  const [placement, setPlacement] = useState<AppPlacement[]>([]);
  const [matchingType, setMatchingType] = useState<Match>(MATCH.ALL);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const { data: companyData } = useGetCompanies({
    types: [
      COMPANY.CORPORATION,
      COMPANY.INDIVIDUAL,
      COMPANY.SYNDICATION,
      COMPANY.SYNDICATION_CLIENT_CORPORATION,
      COMPANY.SYNDICATION_CLIENT_INDIVIDUAL,
    ],
    pageNo: 1,
  });

  const { data: appMediaData } = useGetAppMediaList({
    companyKey: company?.key,
    disabled: company?.key ? false : true,
  });

  const { data: appPlacementData } = useGetAppPlacementList({
    mediaKeys: media.map((m) => m.key),
  });

  const { data: matchData } = usePostDSPMatch({
    thirdpartyId: open.key,
    pageNo,
    pageSize,
    matchType: matchingType !== MATCH.ALL ? matchingType : undefined,
    mediaKeys: media.map((m) => m.key),
    placementIds: placement.map((p) => p.id),
  });

  // 초기 매체 세팅
  useEffect(() => {
    setMedia(appMediaData.media);
  }, [appMediaData.media]);

  // 초기 플레이스먼트 세팅
  useEffect(() => {
    setPlacement(appPlacementData.placements);
  }, [appPlacementData.placements]);

  const onSelectCompany = useCallback((_: SyntheticEvent<unknown>, v: CompanyData | null) => {
    setCompany(v);
  }, []);

  const onChangeMatchingType = useCallback((event: SelectChangeEvent<Match>) => {
    const value = +event.target.value as Match;
    setMatchingType(value);
  }, []);

  const onChangeMedia = useCallback((values: AppMedia[]) => {
    setMedia(values);
  }, []);

  const onChangePlacement = useCallback((values: AppPlacement[]) => {
    setPlacement(values);
  }, []);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: matchData.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, matchData.pages, onChangePage, pageSize, onChangePageSize]
  );

  const rowData = useMemo(
    () =>
      matchData.thirdparties.reduce(
        (acc: MatchingPlacement[], { media_key, media_name, placements }) => {
          if (placements.length > 0) {
            acc = [
              ...acc,
              ...placements.map((placement) => ({
                thirdpartyId: open.key,
                dsp_name: name,
                media_key: media_key,
                media_name: media_name,
                ...placement,
              })),
            ];
          }
          return acc;
        },
        []
      ),
    [matchData.thirdparties, name, open.key]
  );

  return (
    <Dialog
      css={dspPlacementModalStyle}
      maxWidth="lg"
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-company"
      aria-describedby="change company"
    >
      <DialogTitle id="dialog-title">{name}</DialogTitle>
      <DialogContent>
        <Grid className="ssp-tools" container spacing={2}>
          <Grid item xs={3}>
            <Autocomplete
              className="field"
              size="small"
              options={companyData.companies}
              getOptionLabel={(company) => company.name}
              renderOption={(props, company) => {
                return (
                  <li {...props} key={company.id}>
                    {company.name}
                  </li>
                );
              }}
              renderInput={({ InputLabelProps, ...params }) => (
                <TextField
                  {...params}
                  label="업체 명"
                  placeholder="업체를 선택해주세요."
                  required
                />
              )}
              isOptionEqualToValue={(company, value) => company.id === value.id}
              value={company}
              onChange={onSelectCompany}
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              label="매칭 상태"
              placeholder="매칭 상태"
              onChange={onChangeMatchingType}
              value={matchingType}
            >
              <MenuItem value={MATCH.ALL}>{MATCH_ALIAS[MATCH.ALL]}</MenuItem>
              <MenuItem value={MATCH.MATCHED}>{MATCH_ALIAS[MATCH.MATCHED]}</MenuItem>
              <MenuItem value={MATCH.NOT_MATCHED}>{MATCH_ALIAS[MATCH.NOT_MATCHED]}</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>
            <MultipleSelect
              className="field"
              options={appMediaData.media}
              label="매체"
              placeholder="매체 선택"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.key}
              onChange={onChangeMedia}
              value={media}
            />
          </Grid>
          <Grid item xs={3}>
            <MultipleSelect
              className="field"
              options={appPlacementData.placements}
              label="플레이스먼트"
              placeholder="플레이스먼트 선택"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={onChangePlacement}
              value={placement}
            />
          </Grid>
        </Grid>
        <BasicTable
          getRowId={(params) => params.data.id}
          animateRows
          rowData={rowData}
          columnDefs={columnDefs}
          {...paginationInfo}
        />
      </DialogContent>
    </Dialog>
  );
};

const columnDefs = [
  {
    headerName: "DSP 명",
    field: "dsp_name",
    sortable: true,
  },
  {
    headerName: "매체명",
    field: "media_name",
    sortable: true,
  },
  {
    headerName: "플레이스먼트 명",
    field: "name",
    sortable: true,
  },
  {
    headerName: "매칭정보",
    cellRenderer: (params: { data: MatchingPlacement }) => {
      return params.data.match_key_info && params.data.match_key_info.length > 0 ? (
        <MatchingButton {...params.data} />
      ) : null;
    },
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
  },
  {
    headerName: "상태",
    field: "individual_status",
    sortable: true,
    cellRenderer: (params: { data: MatchingPlacement }) => <StatusSwitch {...params.data} />,
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

function MatchingButton({ thirdpartyId, id, match_key, ...props }: MatchingPlacement) {
  const [openMatchingInfoModal, onShowMatchingInfoModal, onCloseMatchingInfoModal] =
    useOpenModal(id);

  const isInit = useMemo(
    () => !(match_key && Object.values(match_key).some((v) => Boolean(v))),
    [match_key]
  );
  const label = useMemo(() => (isInit ? "등록하기" : "변경하기"), [isInit]);

  return (
    <>
      <Button
        variant="text"
        size="small"
        color={isInit ? "inherit" : "primary"}
        onClick={onShowMatchingInfoModal}
      >
        {label}
      </Button>
      {openMatchingInfoModal.isOpen && (
        <MatchingDSPInfoModal
          {...props}
          open={openMatchingInfoModal}
          id={id}
          thirdpartyId={thirdpartyId}
          match_key={match_key}
          onClose={onCloseMatchingInfoModal}
        />
      )}
    </>
  );
}

function StatusSwitch({ id, thirdpartyId, individual_status, status }: MatchingPlacement) {
  const { mutate } = usePatchDSP();
  const onClickIndividual = useCallback(() => {
    mutate({
      placementId: id,
      thirdpartyId: thirdpartyId,
      status: individual_status === STATUS.ACTIVE ? STATUS.SUSPEND : STATUS.ACTIVE,
    });
  }, [id, individual_status, mutate, thirdpartyId]);

  const onClickClose = useCallback(() => {
    mutate({
      placementId: id,
      thirdpartyId: thirdpartyId,
      status: STATUS.DELETED,
    });
  }, [id, mutate, thirdpartyId]);

  const onClickDsp = useCallback(() => {
    mutate({
      placementId: id,
      thirdpartyId: thirdpartyId,
      status: STATUS.ACTIVE,
    });
  }, [id, mutate, thirdpartyId]);

  if (individual_status !== STATUS.DELETED)
    return (
      <>
        <Switch onClick={onClickIndividual} checked={individual_status === STATUS.ACTIVE}></Switch>
        <RiCloseCircleFill onClick={onClickClose} className="back-to-dsp" />
      </>
    );
  if (status === STATUS.ACTIVE)
    return <FaCheckCircle onClick={onClickDsp} className="active-dsp" />;
  return <FaCircle onClick={onClickDsp} className="inactive-dsp" />;
}

export default AppPlacementModal;
