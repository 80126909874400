import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { MEDIA, Media, STATUS, Status, WebPlatform, WEB_PLATFORM } from "src/types";
import API from "src/utils/api";
import useGetUserDetail from "../users/useGetUserDetail";

interface Params {
  companyKey?: string;
  domain: string;
  name: string;
  platformType: WebPlatform;
}

interface Response {
  code: 200 | 400 | 401 | 409 | 500;
  data?: {
    media: {
      company_key: string;
      created_at: number;
      domain: string;
      key: string;
      name: string;
      placement_count: number;
      platform_type: WebPlatform;
      script_updated_at: number;
      status: Status;
      type: Media;
      updated_at: number;
    };
  };
  text: "ok" | "bad-request" | "unauthorized" | "conflict" | "internal-server-error";
}

// 신규 웹 매체 생성
const usePostWeb = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const { data: userData } = useGetUserDetail({ userId: "me" });

  const { data, ...rest } = useMutation<Response, AxiosError, Params>(
    async ({ companyKey, domain, name, platformType }) => {
      companyKey = companyKey || userData?.user.company_key;
      const response: AxiosResponse<Response> = await API.default.post(`/media/web`, {
        company_key: companyKey,
        domain: domain,
        name: name,
        platform_type: platformType,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("웹 매체가 등록되었습니다.");
        invalidate("media/useGetWebMediaList");
      },
      onError: handleError,
    }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            media: {
              company_key: "",
              created_at: 0,
              domain: "",
              key: "",
              name: "",
              placement_count: 0,
              platform_type: WEB_PLATFORM.PC,
              script_updated_at: 0,
              status: STATUS.SUSPEND,
              type: MEDIA.WEB,
              updated_at: 0,
            },
          },
    [data]
  );

  return { data: result, ...rest };
};

export default usePostWeb;
