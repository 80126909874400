import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Company, PaymentStatus, TaxBillStatus } from "src/types";
import API from "src/utils/api";

interface Params {
  month: string;
  name?: string;
  types?: Company[];
  status?: TaxBillStatus[];
  pageNo?: number;
  pageSize?: number;
}

interface Response {
  code: 200 | 400 | 401 | 422;
  data?: {
    summary: {
      company_key: string;
      company_name: string;
      company_type: Company;
      margin_sum_revenue_krw: number;
      media_sum_revenue: number;
      month: string;
      payment_date: number;
      payment_id: number;
      payment_status: PaymentStatus;
      payment_user_name: string;
      tax_bill_date: number;
      tax_bill_status: number;
      tax_bill_sum_revenue: number;
      tax_bill_user_name: string;
    }[];
    pages: number;
    total_revenue: number;
  };
  text: "ok" | "bad-request" | "unauthorized" | "unprocessable-entity";
}

// 정산관리 > 내역 조회
// 정산기준을 설정하지 않은 월을 선택하면 422 unprocessable-entity 반환
const useGetPayments = ({ month, name, types, status, pageNo = 1, pageSize = 100000 }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["payments/useGetPayments", month, name, types, status, pageNo, pageSize],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/payments`, {
        params: {
          month,
          name,
          status: status?.join(","),
          types: types?.join(","),
          page_no: pageNo,
          page_size: pageSize,
        },
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => (data ? data : { summary: [], pages: 0, total_revenue: 0 }), [data]);

  return { data: result, ...rest };
};

export default useGetPayments;
