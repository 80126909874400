import { css } from "@emotion/react";
import { grey, red, teal } from "@mui/material/colors";

export const settlementBoardStyle = css`
  .left-tools {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }

  .right-tools {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
  }
`;

export const taxBillModalStyle = css`
  .dialog-content {
    .current-info {
      padding-top: 1rem;

      .remote-tools {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
        button {
          min-width: 100px;
          svg {
            margin-right: 8px;
          }
        }
      }
    }
    #taxbill-form {
      margin-top: 2.5rem;
    }
  }
`;

// 히스토리 팝업
export const historyModalStyle = css`
  .dialog-content {
    padding-top: 20px;
    margin-bottom: 1rem;

    .history-list-container {
      border: 1px solid ${grey[400]};
      border-radius: 4px;
      overflow: auto;
      .history-list-box {
        min-height: 300px;
        max-height: 300px;
        overflow: auto;
      }
    }
  }
`;

export const accordionStyle = css`
  border: 1px solid ${grey[200]};
  color: ${grey[800]};
  &:not(:last-child) {
    border-bottom: 0;
  }
  &:before: {
    display: none;
  }
  .Mui-disabled {
    font-weight: 500;
    color: ${grey["900"]};
  }
`;

export const accordionSummaryStyle = css`
  min-height: initial;
  &.Mui-disabled {
    background-color: ${grey[200]};
    opacity: initial;
    padding-right: 1rem;
  }
`;

export const accordionDetailStyle = css`
  border-top: 1px solid ${grey[300]};
  padding: 1rem;

  .after-revenue {
    color: ${teal[500]};
  }
  .reason {
    color: ${grey[600]};
  }
`;

export const criteriaSettingModalStyle = css`
  .dialog-content {
    #criteira-form {
      padding-top: 20px;
    }
  }
`;
export const historyStyle = css`
  .title {
    padding-top: 16px;
  }

  ul {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    min-height: 260px;
    max-height: 260px;
    overflow: auto;
    border-radius: 4px;
    border: 1px solid ${grey[400]};
    li {
      width: auto;
      margin: 0 1rem;
      border-bottom: 1px solid #aaa;

      .change,
      .reason {
        font-weight: 500;
      }
      .change {
        color: ${teal[500]};
      }
      .reason {
        color: ${grey[500]};
      }
    }
  }
`;

export const registerEmailStyle = css`
  #add-form {
    margin-top: 2rem;
    .ico-delete {
      fill: ${red[500]};
    }
    .submit {
      width: 100%;
    }
  }
`;

export const emailBoardStyle = css`
  .ssp-tools {
    margin-top: 2rem;
  }
`;
