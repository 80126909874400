import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { Postback } from "src/types/coupang";
import API from "src/utils/api";

interface Params {
  postbackNo: number;
  partnerNo: number;
  postbackType: Postback;
  postbackUrl: string;
  subIds: number[];
  mediaKey?: string;
}

interface Response {
  code: 201 | 400 | 401 | 422;
  text: "PARTNER_CREATE_SUCCESS" | "bad-request" | "unauthorized" | "unprocessable-entity";
}

// 포스트백 정보 수정
const usePutPostback = () => {
  const { handleError } = useApiError({ 409: (_error: AxiosError) => null });
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ postbackNo, partnerNo, postbackType, postbackUrl, subIds, mediaKey }) => {
      const response: AxiosResponse<Response> = await API.integration.put(
        `/operation/management/coupang/postback/${postbackNo}`,
        {
          partner_no: partnerNo,
          postback_url: postbackUrl,
          postback_type: postbackType,
          subid_list: subIds,
          media_key: mediaKey,
        }
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("포스트백이 수정되었습니다.");
        invalidate(["coupang/useGetPostback", "coupang/useGetPostbackList"]);
      },
    }
  );

  return result;
};

export default usePutPostback;
