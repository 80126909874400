import { Container, MenuItem, MenuList, Tab } from "@mui/material";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import cx from "classnames";
import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import routes from "src/assets/routes";
import useGetUserAuthority from "src/hooks/apis/users/useGetUserAuthority";
import { REG_EXP } from "src/utils/form-helper";
import { mainMenuStyle, subMenuStyle } from "./styles";

const MainMenu = () => {
  const { pathname } = useLocation();
  const [value, setValue] = useState(`/${pathname.split("/")[1]}`);
  const [hoverMenu, setHoverMenu] = useState(pathname.split("/")[1]);
  const { isAccessible } = useGetUserAuthority({ userId: "me" });

  // 초기 메인 메뉴 활성화
  useEffect(() => {
    setValue(`/${pathname.split("/")[1]}`);
    setHoverMenu(pathname.split("/")[1]);
  }, [pathname]);

  const onHoverMenu = useCallback(
    (menu: string) => (e: MouseEvent) => {
      e.preventDefault();
      setHoverMenu(menu);
    },
    []
  );

  const subMenu = useMemo(
    () => routes[hoverMenu as keyof typeof routes].subMenu || {},
    [hoverMenu]
  );

  return (
    <Box
      css={mainMenuStyle}
      sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
      onMouseLeave={onHoverMenu(pathname.split("/")[1])}
    >
      <Tabs value={value} aria-label="main tabs">
        {Object.entries(routes).map(([menu, menuInfo], idx) => {
          if (menu !== "login" && isAccessible(menu, menuInfo.authority))
            return (
              <Tab
                component={Link}
                className={cx("tab", { hover: menu === hoverMenu })}
                value={menuInfo.href}
                to={menuInfo.href}
                key={menu}
                label={menuInfo.label}
                id={`tab-${idx}`}
                aria-controls={`tabpanel-${idx}`}
                onMouseEnter={onHoverMenu(menu)}
              />
            );
          return null;
        })}
      </Tabs>
      <Box css={subMenuStyle} sx={{ display: { xs: "none", md: "flex" } }}>
        {subMenu && (
          <Container className="sub-container" maxWidth="xl">
            <MenuList className="menu-list">
              {Object.entries(subMenu).map(([menu, menuInfo]) => {
                if (isAccessible(hoverMenu, menuInfo.authority))
                  return (
                    <MenuItem
                      key={menu}
                      className={cx({
                        active: pathname.includes(menuInfo.href),
                      })}
                    >
                      {REG_EXP.domain.test(menuInfo.href) ? (
                        <a href={menuInfo.href} target="_blank" rel="noopener noreferrer">
                          {menuInfo.label}
                        </a>
                      ) : (
                        <Link to={menuInfo.href}>{menuInfo.label}</Link>
                      )}
                    </MenuItem>
                  );
                return null;
              })}
            </MenuList>
          </Container>
        )}
      </Box>
    </Box>
  );
};

export default MainMenu;
