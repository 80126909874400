import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Status } from "src/types";
import API from "src/utils/api";

export interface TestDevice {
  identifier: string;
  no: number;
  status: Status;
}

interface Params {
  mediaKey: string;
  pageNo: number;
  pageSize: number;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    pages: number;
    test_devices: TestDevice[];
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 테스트 디바이스 리스트 조회
const useGetTestDeviceList = ({ mediaKey, pageNo, pageSize }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["testDevices/useGetTestDeviceList", mediaKey, pageNo, pageSize],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/test-devices`, {
        params: {
          media_key: mediaKey,
          page_no: pageNo,
          page_size: pageSize,
        },
      });
      return response.data.data;
    },
    { enabled: !!mediaKey, onError: handleError }
  );

  const result = useMemo(() => (data ? data : { pages: 0, test_devices: [] }), [data]);

  return { data: result, ...rest };
};

export default useGetTestDeviceList;
