import { css } from "@emotion/react";

export const buttonStyle = css`
  & {
    background-color: #ffffff;
  }

  &:hover {
    background-color: #fdf7f7;
  }
`;

export const popoverStyle = css`
  .content {
    white-space: pre-line;
    padding: 0.5rem;
    .message {
      padding: 0.5rem 1rem 1rem;
    }
    .footer {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
