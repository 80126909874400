import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";
import useGetUserDetail from "../users/useGetUserDetail";

export interface Domain {
  company_key: string;
  created_at: number;
  domain_ids: number[];
  domain_list_id: number;
  domain_list_name: string;
  updated_at: number;
}

interface Params {
  companyKey?: string;
  pageNo?: number;
  pageSize?: number;
}

interface Response {
  code: 200 | 401;
  data?: {
    domain_list: Domain[];
    pages: number;
  };
  text: "ok" | "unauthorized";
}

// 차단 도메인 리스트 조회
const useGetDomains = ({ companyKey, pageNo = 1, pageSize = 100000 }: Params) => {
  const { handleError } = useApiError();
  const { data: userData } = useGetUserDetail({ userId: "me" });
  companyKey = companyKey || userData?.user.company_key;
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["blocklist/useGetDomains", companyKey, pageNo, pageSize],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/blocklist/domains`, {
        params: { company_key: companyKey, page_no: pageNo, page_size: pageSize },
      });
      return response.data.data;
    },
    { enabled: !!companyKey, onError: handleError }
  );

  const result = useMemo(() => (data ? data : { domain_list: [], pages: 0 }), [data]);

  return { data: result, ...rest };
};

export default useGetDomains;
