import { atom } from "recoil";
import { cookieEffect } from "./effects";

interface ViewerCompany {
  id: number;
  key: string;
}

export const viewerCompany = atom<ViewerCompany>({
  key: "viewerCompany",
  default: { id: 0, key: "" },
  effects: [cookieEffect("viewerCompany")],
});
