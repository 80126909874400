import { Box, Button, Grid, Typography } from "@mui/material";
import { RowClickedEvent, SortChangedEvent, ValueFormatterParams } from "ag-grid-community";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { BasicTable, MultipleSelect, SearchField } from "src/components/commons";
import useGetCompanies from "src/hooks/apis/companies/useGetCompanies";
import useOpenModal from "src/hooks/useOpenModal";
import useToast from "src/hooks/useToast";
import { Company, COMPANY, COMPANY_ALIAS, Settlement, SETTLEMENT_ALIAS } from "src/types";
import AddSyndicationClientModal from "./AddSyndicationClientModal";
import EditSyndicationClientModal from "./EditSyndicationClientModal";
import { syndicationClientBoardStyle } from "./styles";

interface SyndicationClientBoardProps {
  syndicationId?: number;
}

const SyndicationClientBoard = ({ syndicationId }: SyndicationClientBoardProps) => {
  const [types, setTypes] = useState<Company[]>([
    COMPANY.SYNDICATION_CLIENT_CORPORATION,
    COMPANY.SYNDICATION_CLIENT_INDIVIDUAL,
  ]);
  const [search, setSearch] = useState({ temp: "", value: "" });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [orders, setOrders] = useState<string[]>([]);
  const [openAddModal, onShowAddSyndicationModal, onCloseAddSyndicationModal] = useOpenModal(null);
  const [openEditModal, onShowEditSyndication, onCloseEditSyndicationModal] = useOpenModal(0);
  const toast = useToast();

  const { data } = useGetCompanies({
    name: search.value,
    types,
    pageNo,
    pageSize,
    orders,
    syndicationId: syndicationId,
    disabled: !syndicationId,
  });

  const onChangeSearchWord = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearch({ ...search, temp: e.target.value });
    },
    [search]
  );

  const onSearchSyndication = useCallback(() => {
    if (!syndicationId) {
      toast.warning("신디케이션 테이블에서 서브 퍼블리셔를 먼저 선택해주세요.");
      return;
    }
    setSearch({ ...search, value: search.temp });
    setPageNo(1);
  }, [search, syndicationId, toast]);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const onRowClicked = useCallback(
    (e: RowClickedEvent) => {
      e.event?.preventDefault();
      onShowEditSyndication(e, e.data.id);
    },
    [onShowEditSyndication]
  );

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data?.pages || 0, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [data?.pages, onChangePage, onChangePageSize, pageNo, pageSize]
  );

  const onChangeTypes = useCallback((values: Company[]) => {
    setTypes(values);
  }, []);

  const onSortChanged = useCallback((e: SortChangedEvent) => {
    const sortInfo = e.columnApi
      .getColumnState()
      .filter((column) => !!column.sort)
      .map(({ colId, sort }) => {
        if (sort === "desc") return `-${colId}`;
        return `+${colId}`;
      });
    setOrders(sortInfo);
  }, []);

  return (
    <Box css={syndicationClientBoardStyle}>
      <Typography id="syndication-client" className="title" variant="h5" gutterBottom>
        신디케이션 고객사
      </Typography>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={3}>
          <MultipleSelect
            className="field"
            options={[
              COMPANY.SYNDICATION_CLIENT_CORPORATION,
              COMPANY.SYNDICATION_CLIENT_INDIVIDUAL,
            ]}
            label="업체 분류"
            placeholder="업체 분류"
            getOptionLabel={(option) => COMPANY_ALIAS[option]}
            onChange={onChangeTypes}
            value={types}
          />
        </Grid>
        <Grid item xs={3}>
          <SearchField
            label="검색어"
            placeholder="업체명을 입력해주세요."
            value={search.temp}
            onChange={onChangeSearchWord}
            onClickSearchButton={onSearchSyndication}
          />
        </Grid>
        <Grid item xs={6} className="register">
          <Button variant="outlined" onClick={onShowAddSyndicationModal}>
            신규 업체 등록
          </Button>
        </Grid>
      </Grid>
      <BasicTable
        rowData={data?.companies}
        columnDefs={columnDefs}
        onRowClicked={onRowClicked}
        onSortChanged={onSortChanged}
        {...paginationInfo}
      />
      {openAddModal.isOpen && (
        <AddSyndicationClientModal
          syndicationId={syndicationId}
          isOpen={openAddModal.isOpen}
          onClose={onCloseAddSyndicationModal}
        />
      )}
      {openEditModal.isOpen && (
        <EditSyndicationClientModal open={openEditModal} onClose={onCloseEditSyndicationModal} />
      )}
    </Box>
  );
};

const columnDefs = [
  { headerName: "업체명", field: "name", sortable: true },
  {
    headerName: "업체 분류",
    field: "type",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const company = param.value as Company;
      return COMPANY_ALIAS[company];
    },
  },
  {
    headerName: "ADServer",
    field: "use_adserver",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const use = param.value;
      return use ? "사용" : "사용안함";
    },
  },
  { headerName: "업체 키", field: "key", sortable: true },
  {
    headerName: "정산 분류",
    field: "settlement_type",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const settlementType = param.value as Settlement;
      return SETTLEMENT_ALIAS[settlementType];
    },
  },
  { headerName: "운영 수수료", field: "settlement_commission_rate", sortable: true },
];

export default SyndicationClientBoard;
