import { Container, Paper, Typography } from "@mui/material";
import { RowClickedEvent, ValueFormatterParams } from "ag-grid-community";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { BasicTable } from "src/components/commons";
import useGetAdminRequests from "src/hooks/apis/companies/useGetAdminRequests";
import useOpenModal from "src/hooks/useOpenModal";
import { Company, COMPANY_ALIAS, Settlement, SETTLEMENT_ALIAS } from "src/types";
import ApprovalModal from "./ApprovalModal";

const ApprovalBoard = () => {
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { data } = useGetAdminRequests({ pageNo, pageSize });
  const [openApprovalModal, onShowApproval, onCloseApprovalModal] = useOpenModal(0);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, data.pages, onChangePage, pageSize, onChangePageSize]
  );

  const onShowApprovalModal = useCallback(
    (e: RowClickedEvent) => {
      onShowApproval(e.event, e.data.id);
    },
    [onShowApproval]
  );

  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        업체 승인
      </Typography>
      <Paper className="content" elevation={2}>
        <BasicTable
          rowData={data.companies}
          columnDefs={columnDefs}
          onRowClicked={onShowApprovalModal}
          {...paginationInfo}
        />
      </Paper>
      {openApprovalModal.isOpen && (
        <ApprovalModal open={openApprovalModal} onClose={onCloseApprovalModal} />
      )}
    </Container>
  );
};

const columnDefs = [
  { headerName: "업체명", field: "name", sortable: true },
  {
    headerName: "업체 분류",
    field: "type",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const type = param.value as Company;
      return COMPANY_ALIAS[type];
    },
  },
  {
    headerName: "업체 키",
    field: "key",
    sortable: true,
  },
  {
    headerName: "정산 분류",
    field: "settlement_type",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const type = param.value as Settlement;
      return SETTLEMENT_ALIAS[type];
    },
  },
  {
    headerName: "운영 수수료",
    field: "settlement_commission_rate",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      return `${param.value} %`;
    },
  },
  {
    headerName: "등록일",
    field: "created_at",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const createdAt = param.value * 1000;
      return moment(createdAt).format("YYYY-MM-DD");
    },
  },
];

export default ApprovalBoard;
