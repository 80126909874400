import { useCallback, useState } from "react";
import SyndicationClientBoard from "./SyndicationClientBoard";
import SyndicationPublisherBoard from "./SyndicationPublisherBoard";

const SyndicationBoard = () => {
  const [selectedSyndication, setSelectedSyndication] = useState<number>();

  const onSelectSyndication = useCallback((value: number) => {
    setSelectedSyndication(value);
  }, []);

  return (
    <>
      <SyndicationPublisherBoard onSelectSyndication={onSelectSyndication} />
      <SyndicationClientBoard syndicationId={selectedSyndication} />
    </>
  );
};

export default SyndicationBoard;
