import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { RowClickedEvent, SortChangedEvent, ValueFormatterParams } from "ag-grid-community";
import moment from "moment";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import DataFormat from "src/assets/formats";
import { viewerCompany } from "src/atoms/viewerCompany";
import { BasicTable, SearchField } from "src/components/commons";
import useGetCompanies from "src/hooks/apis/companies/useGetCompanies";
import useGetUserDetail from "src/hooks/apis/users/useGetUserDetail";
import useOpenModal from "src/hooks/useOpenModal";
import { Company, COMPANY, COMPANY_ALIAS } from "src/types";
import AddSyndicationModal from "./AddSyndicationModal";
import EditSyndicationModal from "./EditSyndicationModal";
import { syndicationStyle } from "./styles";

const Syndication = () => {
  const [search, setSearch] = useState({ temp: "", value: "" });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [orders, setOrders] = useState<string[]>([]);
  const [openAddModal, onShowAddSyndicationModal, onCloseAddSyndicationModal] = useOpenModal(null);
  const [openEditModal, onShowEditSyndication, onCloseEditSyndicationModal] = useOpenModal(0);
  const [company] = useRecoilState(viewerCompany);
  const { data: userData } = useGetUserDetail({ userId: "me" });

  const { data } = useGetCompanies({
    name: search.value,
    types: [COMPANY.SYNDICATION_CLIENT_CORPORATION, COMPANY.SYNDICATION_CLIENT_INDIVIDUAL],
    pageNo,
    pageSize,
    orders,
    syndicationId: company.id >= 0 ? company.id : userData?.user.syndication_id,
  });

  const onChangeSearchWord = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearch({ ...search, temp: e.target.value });
    },
    [search]
  );

  const onSearchSyndication = useCallback(() => {
    setSearch({ ...search, value: search.temp });
    setPageNo(1);
  }, [search]);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const onRowClicked = useCallback(
    (e: RowClickedEvent) => {
      e.event?.preventDefault();
      onShowEditSyndication(e, e.data.id);
    },
    [onShowEditSyndication]
  );

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data?.pages || 0, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [data?.pages, onChangePage, onChangePageSize, pageNo, pageSize]
  );

  const onSortChanged = useCallback((e: SortChangedEvent) => {
    const sortInfo = e.columnApi
      .getColumnState()
      .filter((column) => !!column.sort)
      .map(({ colId, sort }) => {
        if (sort === "desc") return `-${colId}`;
        return `+${colId}`;
      });
    setOrders(sortInfo);
  }, []);

  return (
    <Container css={syndicationStyle} component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        신디케이션 관리
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid container className="ssp-tools" spacing={2}>
          <Grid item xs={3}>
            <SearchField
              label="검색어"
              placeholder="업체명을 입력해주세요"
              value={search.temp}
              onChange={onChangeSearchWord}
              onClickSearchButton={onSearchSyndication}
            />
          </Grid>
          <Grid item xs={9} className="register">
            <Button variant="outlined" onClick={onShowAddSyndicationModal}>
              신규 업체 등록
            </Button>
          </Grid>
        </Grid>
        <BasicTable
          rowData={data?.companies}
          columnDefs={columnDefs}
          onRowClicked={onRowClicked}
          onSortChanged={onSortChanged}
          {...paginationInfo}
        />
      </Paper>
      {openAddModal.isOpen && (
        <AddSyndicationModal isOpen={openAddModal.isOpen} onClose={onCloseAddSyndicationModal} />
      )}
      {openEditModal.isOpen && (
        <EditSyndicationModal open={openEditModal} onClose={onCloseEditSyndicationModal} />
      )}
    </Container>
  );
};

const columnDefs = [
  { headerName: "업체명", field: "name", sortable: true },
  {
    headerName: "업체 분류",
    field: "type",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const company = param.value as Company;
      return COMPANY_ALIAS[company];
    },
  },
  {
    headerName: "ADServer",
    field: "use_adserver",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const use = param.value;
      return use ? "사용" : "사용안함";
    },
  },
  { headerName: "업체 키", field: "key", sortable: true },
  {
    headerName: "등록일",
    field: "created_at",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const createdAt = param.value * 1000;
      return moment(createdAt).format("YYYY-MM-DD");
    },
  },
  {
    headerName: "수정일",
    field: "updated_at",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const updatedAt = param.value * 1000;
      return moment(updatedAt).format("YYYY-MM-DD");
    },
  },
  {
    headerName: "운영 수수료",
    field: "settlement_commission_rate",
    sortable: true,
    valueFormatter: (params: ValueFormatterParams) => {
      return DataFormat.commissionRate.formatter(params.value);
    },
  },
];

export default Syndication;
