import cx from "classnames";
import { Location, useLocation } from "react-router-dom";
import { ReactComponent as CloseIcon } from "src/assets/images/ico-close-outlined.svg";
import styles from "./styles";

interface ErrorPageLocation extends Location {
  state: {
    message?: string;
  };
}

const Error = () => {
  const { state } = useLocation() as ErrorPageLocation;
  const message = state?.message || "잠시 후 다시 시도해주세요.";
  return (
    <div css={styles}>
      <div className={cx("status")}>
        <CloseIcon className={cx("w-16 h-16 fill-red-500")} />
      </div>
      <p>{message}</p>
    </div>
  );
};

export default Error;

export { default as NotFound } from "./NotFound";
