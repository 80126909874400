import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import { useMemo, useCallback, ChangeEvent } from "react";
import useNpayDailyCap from "src/hooks/apis/npay/use-npay-daily-cap";
import useUpdateNpayDailyCap from "src/hooks/apis/npay/use-update-npay-daily-cap";
import useToast from "src/hooks/useToast";
import { shouldErrorShows, getHelperText } from "src/utils/form-helper";
import { NO_VALUE, numberRegex, numberFormat } from "./const";
import { TextField } from "src/components/commons";
import { number, object } from "yup";

const editDailyCapFormSchema = object({
  value: number()
    .required("일 한도 값을 입력해 주세요.")
    .moreThan(0, "0 이상 값을 입력해 주세요.")
    .notOneOf([NO_VALUE], "일 한도 값을 입력해 주세요."),
});

export default function EditDailyCapModal(props: { onClose: VoidFunction }) {
  const { onClose } = props;

  const toast = useToast();
  const npayDailyCapQuery = useNpayDailyCap();
  const { mutate: updateNpayDailyCap, isLoading: isUpdating } = useUpdateNpayDailyCap();

  const initialValues = useMemo(
    () => ({ value: npayDailyCapQuery.data }),
    [npayDailyCapQuery.data]
  );

  const handleUpdate = (values: { value: number }) => {
    if (isUpdating) {
      return;
    }

    const { value } = values;

    updateNpayDailyCap(value, {
      onSuccess: () => {
        toast.success("저장되었습니다.");
        onClose();
      },
    });
  };

  const handleClose = () => {
    if (isUpdating) {
      return;
    }

    onClose();
  };

  const { values, errors, touched, setFieldValue, handleBlur, handleSubmit, isValid } = useFormik({
    initialValues,
    onSubmit: handleUpdate,
    validationSchema: editDailyCapFormSchema,
    enableReinitialize: true,
  });

  const onChangeValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (value === "") {
        setFieldValue("value", NO_VALUE);
        return;
      }

      const commaRemoved = value
        .split("")
        .filter((char) => char !== ",")
        .join("");

      const isValidNumber = numberRegex.test(commaRemoved);

      if (isValidNumber) {
        setFieldValue("value", Number(commaRemoved));
      }
    },
    [setFieldValue]
  );

  return (
    <Dialog fullWidth open onClose={handleClose}>
      <DialogTitle id="edit-daily-cap-modal-title">다우기술 - 애드팝콘 일 한도</DialogTitle>
      <DialogContent className="edit-daily-cap-modal-dialog-content">
        <Box component="form" id="edit-daily-cap-modal" sx={{ pt: "20px" }} onSubmit={handleSubmit}>
          <TextField
            required
            label="일 한도"
            InputProps={{
              sx: { "& > input": { textAlign: "end" } },
              endAdornment: (
                <Typography component="span" variant="body2" sx={{ ml: 1, flexShrink: 0 }}>
                  원/일
                </Typography>
              ),
            }}
            placeholder="일 한도 값을 입력해 주세요."
            value={values.value === NO_VALUE ? "" : numberFormat(values.value)}
            onChange={onChangeValue}
            onBlur={handleBlur("value")}
            error={shouldErrorShows("value", touched, errors)}
            helperText={getHelperText("value", touched, errors)}
            disabled={npayDailyCapQuery.isFetching || isUpdating}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button type="button" onClick={handleClose} color="inherit" disabled={isUpdating}>
          취소
        </Button>
        <Button type="submit" form="edit-daily-cap-modal" disabled={!isValid || isUpdating}>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}
