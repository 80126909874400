import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  paymentId: number;
  afterRevenue: number;
  beforeRevenue: number;
  reason: string;
}

interface Response {
  code: 200 | 400 | 401 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "internal-server-error";
}

// 세금계산서 발행액 변경
// 변경 전, 변경 후 금액이 같을 경우 400 에러
const usePutRevenue = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ paymentId, afterRevenue, beforeRevenue, reason }) => {
      const response: AxiosResponse<Response> = await API.default.put(
        `/payments/${paymentId}/change-revenue`,
        {
          after_revenue: afterRevenue,
          before_revenue: beforeRevenue,
          reason: reason,
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("수정되었습니다.");
        invalidate([
          "payments/useGetCriteria",
          "payments/useGetPaymentDetail",
          "payments/useGetPayments",
        ]);
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePutRevenue;
