import { Box, Button, Grid, Typography } from "@mui/material";
import { CellClickedEvent, SortChangedEvent } from "ag-grid-community";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { BasicTable, SearchField } from "src/components/commons";
import useGetCompanies, { CompanyData } from "src/hooks/apis/companies/useGetCompanies";
import useOpenModal from "src/hooks/useOpenModal";
import { COMPANY } from "src/types";
import AddSyndicationModal from "./AddSyndicationModal";
import EditSyndicationModal from "./EditSyndicationModal";
import { syndicationPublisherBoardStyle } from "./styles";

interface SyndicationPublisherBoardProps {
  onSelectSyndication: (value: number) => void;
}

const SyndicationPublisherBoard = ({ onSelectSyndication }: SyndicationPublisherBoardProps) => {
  const [search, setSearch] = useState({ temp: "", value: "" });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [orders, setOrders] = useState<string[]>([]);
  const [openAddModal, onShowAddSyndicationModal, onCloseAddSyndicationModal] = useOpenModal(null);
  const [openEditModal, onShowEditSyndication, onCloseEditSyndicationModal] = useOpenModal(0);

  const { data } = useGetCompanies({
    name: search.value,
    types: [COMPANY.SYNDICATION],
    pageNo,
    pageSize,
    orders,
  });

  const onChangeSearchWord = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearch({ ...search, temp: e.target.value });
    },
    [search]
  );

  const onSearchSyndication = useCallback(() => {
    setSearch({ ...search, value: search.temp });
    setPageNo(1);
  }, [search]);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const onCellClicked = useCallback(
    (e: CellClickedEvent) => {
      e.event?.preventDefault();
      if (e.colDef.field === "sub_company_ids") {
        onSelectSyndication(e.data.id);
      } else {
        onShowEditSyndication(e, e.data.id);
      }
    },
    [onSelectSyndication, onShowEditSyndication]
  );

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data.pages || 0, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [data?.pages, onChangePage, onChangePageSize, pageNo, pageSize]
  );

  const onSortChanged = useCallback((e: SortChangedEvent) => {
    const sortInfo = e.columnApi
      .getColumnState()
      .filter((column) => !!column.sort)
      .map(({ colId, sort }) => {
        if (sort === "desc") return `-${colId}`;
        return `+${colId}`;
      });
    setOrders(sortInfo);
  }, []);

  return (
    <Box css={syndicationPublisherBoardStyle}>
      <Typography className="title" variant="h5" gutterBottom>
        신디케이션
      </Typography>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={3}>
          <SearchField
            label="검색어"
            placeholder="업체명을 입력해주세요."
            value={search.temp}
            onChange={onChangeSearchWord}
            onClickSearchButton={onSearchSyndication}
          />
        </Grid>
        <Grid item xs={9} className="register">
          <Button variant="outlined" onClick={onShowAddSyndicationModal}>
            신규 업체 등록
          </Button>
        </Grid>
      </Grid>
      <BasicTable
        rowData={data?.companies}
        columnDefs={columnDefs}
        onCellClicked={onCellClicked}
        onSortChanged={onSortChanged}
        {...paginationInfo}
      />
      {openAddModal.isOpen && (
        <AddSyndicationModal isOpen={openAddModal.isOpen} onClose={onCloseAddSyndicationModal} />
      )}
      {openEditModal.isOpen && (
        <EditSyndicationModal open={openEditModal} onClose={onCloseEditSyndicationModal} />
      )}
    </Box>
  );
};

const columnDefs = [
  { headerName: "업체명", field: "name", sortable: true },
  { headerName: "업체 키", field: "key", sortable: true },
  {
    headerName: "서브 퍼블리셔",
    field: "sub_company_ids",
    sortable: true,
    cellRenderer: (params: { data: CompanyData }) => {
      const count = params.data.sub_company_ids?.length || 0;
      return <Button variant="text" href="#syndication-client">{`${count} 개`}</Button>;
    },
  },
  { headerName: "운영 수수료", field: "settlement_commission_rate", sortable: true },
];

export default SyndicationPublisherBoard;
