import { css } from "@emotion/react";
import { grey } from "@mui/material/colors";

export const inviteModalStyle = css`
  .ssp-tools {
    margin: 1rem auto;
  }
`;

export const addMemberModalStyle = css`
  .dialog-content {
    form {
      padding-top: 20px;

      .edit-auth-switch-group {
        position: relative;
        max-height: 400px;
        overflow: auto;
        margin: 0;
        padding: 0;
      }
    }
  }
`;

export const authSwitchGroupStyle = css`
  .header {
    display: flex;
    align-items: center;

    .title {
      font-weight: 500;
    }

    .switch {
      margin-left: auto;
    }
  }

  .content {
    color: ${grey[500]};
    padding-left: 1rem;

    .auth-label {
      display: flex;
      align-items: center;
    }

    .auth-switch {
      text-align: right;
    }
  }
`;
