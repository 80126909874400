import { Container } from "@mui/material";
import ManagerForm from "./ManagerForm";
import { managerSettingStyle } from "./styles";

const Setting = () => {
  return (
    <Container component="section" className="ssp-section" css={managerSettingStyle} maxWidth="xl">
      <ManagerForm />
    </Container>
  );
};

export default Setting;
