import { AxiosError, AxiosResponse } from "axios";
import { Moment } from "moment";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { COUPANG_REPORT, CoupangReport } from "src/types";
import API from "src/utils/api";

export interface ReportData {
  pubId?: string;
  partnerName: string;
  reportDate: string;
  click: number;
  purchase: number;
  purchaseRevenue: number;
  cancel: number;
  cancelRevenue: number;
  totalRevenue: number;
  commission?: number;
  commissionRate?: number;
  subParam?: string; // COUPANG_REPORT.COUPANG인 경우에만 존재
}

export interface Report {
  report_date: string;
  pub_id: string;
  click: number;
  conversion: number;
  conversion_revenue: number;
  cancel: number;
  cancel_revenue: number;
  total_revenue: number;
  commission: number;
  commission_rate: number;
  partner_no: number;
  partner_name: string;
  subid_no: number;
  ap_commission_rate: string;
  ap_commission: number;
  client_commission_rate: string;
  client_commission: number;
  sub_param?: string | null; // COUPANG_REPORT.COUPANG인 경우에만 존재
}

interface Params {
  since: Moment;
  until: Moment;
  coupangReportType: CoupangReport;
}

interface Response {
  code: 200 | 400 | 422 | 500;
  text: "ok" | "bad-request" | "field required" | "internal-server-error";
  report: Report[];
}

// 쿠팡 리포트 데이터 조회
const usePostCoupangReport = ({ since, until, coupangReportType }: Params) => {
  const { handleError } = useApiError();
  const startDate = useMemo(() => since.format("YYYYMMDD"), [since]);
  const endDate = useMemo(() => until.format("YYYYMMDD"), [until]);
  const { data, ...rest } = useQuery<Response, AxiosError>(
    ["get/PostCoupangReport", coupangReportType, startDate, endDate],
    async () => {
      const response: AxiosResponse<Response> = await API.integration.post(
        `/operation/management/coupang/report`,
        {
          is_op: true,
          start_date: startDate,
          end_date: endDate,
          coupang_report_type: coupangReportType,
        }
      );
      return response.data;
    },
    {
      onError: handleError,
    }
  );

  const chartData = useMemo(() => {
    const refusedData = data?.report.reduce((acc, cur) => {
      if (acc[cur.report_date]) {
        acc[cur.report_date] = {
          partnerName: cur.partner_name,
          reportDate: cur.report_date,
          click: acc[cur.report_date].click + cur.click,
          purchase: acc[cur.report_date].purchase + cur.conversion,
          purchaseRevenue: acc[cur.report_date].purchaseRevenue + cur.conversion_revenue,
          cancel: acc[cur.report_date].cancel + cur.cancel,
          cancelRevenue: acc[cur.report_date].cancelRevenue + cur.cancel_revenue,
          totalRevenue: acc[cur.report_date].totalRevenue + cur.total_revenue,
        };
        return acc;
      }
      acc[cur.report_date] = {
        partnerName: cur.partner_name,
        reportDate: cur.report_date,
        click: cur.click,
        purchase: cur.conversion,
        purchaseRevenue: cur.conversion_revenue,
        cancel: cur.cancel,
        cancelRevenue: cur.cancel_revenue,
        totalRevenue: cur.total_revenue,
      };
      return acc;
    }, {} as Record<string, Omit<ReportData, "commission" | "comissionRate">>);

    if (refusedData)
      return Object.values(refusedData).sort((a, b) => +a.reportDate - +b.reportDate);
    return [];
  }, [data?.report]);

  const result = useMemo(() => {
    if (data && data.report && data.report.length > 0)
      return {
        chartData: chartData,
        reportData: data.report.map((report) => ({
          partnerName: report.partner_name,
          pubId: report.pub_id,
          reportDate: report.report_date,
          click: report.click,
          purchase: report.conversion,
          purchaseRevenue: report.conversion_revenue,
          cancel: report.cancel,
          cancelRevenue: report.cancel_revenue,
          totalRevenue: report.total_revenue,
          commission: report.commission,
          commissionRate: report.commission_rate,
          apCommissionRate: report.ap_commission_rate,
          apCommission: report.ap_commission,
          clientCommissionRate: report.client_commission_rate,
          clientCommission: report.client_commission,
          ...(coupangReportType === COUPANG_REPORT.COUPANG && {
            subParam: report.sub_param || "-",
          }),
        })),
      };
    return { chartData: [], reportData: [] };
  }, [data, chartData, coupangReportType]);

  return { data: result, ...rest };
};

export default usePostCoupangReport;
