import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { AppPlatform, APP_PLATFORM, MEDIA, Media, STATUS, Status } from "src/types";
import API from "src/utils/api";
import useGetUserDetail from "../users/useGetUserDetail";

interface Params {
  appstoreId?: number;
  companyKey?: string;
  name: string;
  packageName: string;
  platformType: AppPlatform;
  urlScheme?: string;
}

interface Response {
  code: 200 | 400 | 401 | 409 | 500;
  data?: {
    media: {
      block_domain_list_set: number[];
      collect_installed_apps: boolean;
      company_key: string;
      created_at: number;
      hash_key: string;
      key: string;
      name: string;
      package_name: string;
      placement_count: number;
      platform_type: AppPlatform;
      status: Status;
      test_device_count: number;
      type: Media;
      updated_at: number;
      url_scheme: string;
    };
  };
  text: "ok" | "bad-request" | "unauthorized" | "conflict" | "internal-server-error";
}

// 신규 앱 생성
const usePostApp = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const { data: userData } = useGetUserDetail({ userId: "me" });
  const { data, ...rest } = useMutation<Response, AxiosError, Params>(
    async ({ appstoreId, companyKey, name, packageName, platformType, urlScheme }) => {
      const response: AxiosResponse<Response> = await API.default.post(`/media/app`, {
        appstore_id: appstoreId,
        company_key: companyKey || userData?.user.company_key,
        name: name,
        package_name: packageName,
        platform_type: platformType,
        url_scheme: urlScheme,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("앱 매체가 등록되었습니다.");
        invalidate("media/useGetAppMediaList");
      },
      onError: handleError,
    }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            media: {
              block_domain_list_set: [],
              collect_installed_apps: false,
              company_key: "",
              created_at: 0,
              hash_key: "",
              key: "",
              name: "",
              package_name: "",
              placement_count: 0,
              platform_type: APP_PLATFORM.ANDROID,
              status: STATUS.SUSPEND,
              test_device_count: 0,
              type: MEDIA.APP,
              updated_at: 0,
              url_scheme: "",
            },
          },
    [data]
  );

  return { data: result, ...rest };
};

export default usePostApp;
