import { Box, Grid, Typography } from "@mui/material";
import { ICellRendererParams, ValueFormatterParams } from "ag-grid-community";
import { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from "react";
import { BasicTable, DeleteButton, SearchField } from "src/components/commons";
import useDeleteEmail from "src/hooks/apis/payments/useDeleteEmail";
import useGetEmails from "src/hooks/apis/payments/useGetEmails";
import { emailBoardStyle } from "./styles";

const EmailBoard = () => {
  const [search, setSearch] = useState({ temp: "", value: "" });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data } = useGetEmails({ search: search.value, pageNo, pageSize });

  const onChangeSearchWord = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearch({ ...search, temp: e.target.value });
    },
    [search]
  );

  const onSearchEmail = useCallback(() => {
    setSearch({ ...search, value: search.temp });
    setPageNo(1);
  }, [search]);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data?.pages || 0, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [10, 20, 30],
      },
    }),
    [data?.pages, onChangePage, onChangePageSize, pageNo, pageSize]
  );

  return (
    <Box css={emailBoardStyle}>
      <Typography variant="h6">등록된 주소</Typography>
      <Grid className="ssp-tools" container>
        <Grid item xs={6}>
          <SearchField
            label="검색어"
            placeholder="업체명 / 이메일"
            value={search.temp}
            onChange={onChangeSearchWord}
            onClickSearchButton={onSearchEmail}
          />
        </Grid>
      </Grid>
      <BasicTable rowData={data?.items} columnDefs={columnDefs} {...paginationInfo} />
    </Box>
  );
};

const columnDefs = [
  {
    headerName: "Company Key",
    field: "company_key",
    valueFormatter: (params: ValueFormatterParams) => {
      return `${params.value} [${params.data.company_name}]`;
    },
  },

  {
    headerName: "Email",
    field: "email",
  },
  {
    headerName: "Delete",
    field: "no",
    maxWidth: 150,
    cellRenderer: (params: ICellRendererParams) => <RemoveButton {...params.data} />,
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

function RemoveButton({ no }: { no: number }) {
  const { mutate } = useDeleteEmail();

  const onClickDeleteButton = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      mutate({ no });
    },
    [mutate, no]
  );

  return (
    <DeleteButton onConfirm={onClickDeleteButton}>
      정말 해당 이메일을 삭제하시겠습니까?
    </DeleteButton>
  );
}

export default EmailBoard;
