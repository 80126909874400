import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { TextField } from "src/components/commons";
import useGetCompanyList, { Company } from "src/hooks/apis/coupang/useGetCompanyList";
import usePostPartner from "src/hooks/apis/coupang/usePostPartner";
import { getHelperText, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { addPartnerModalStyle } from "./styles";

interface AddPartnerModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const AddPartnerModal = ({ onClose, isOpen }: AddPartnerModalProps) => {
  const [company, setCompany] = useState<Company | null>(null);
  const { data: companyData } = useGetCompanyList();

  const initialValues = useMemo(
    () => ({
      companyKey: companyData.companies.length > 0 ? companyData.companies[0].company_key : "",
      partnerName: "",
      commission: "",
      clientCommission: "",
    }),
    [companyData.companies]
  );
  const { mutate } = usePostPartner();

  const validationSchema = yup.object({
    companyKey: yup.string().required("업체를 선택해주세요."),
    partnerName: yup.string().required("파트너명을 입력해주세요."),
    commission: yup
      .string()
      .test("commission", "숫자를 입력해주세요.", (value = "") => !isNaN(+value))
      .required("애드팝콘에 대한 쿠팡 수수료를 선택해주세요."),
    clientCommission: yup
      .string()
      .test("clientCommission", "숫자를 입력해주세요.", (value = "") => !isNaN(+value))
      .required("고객사 리포트에 적용될 고객사에 대한 쿠팡 수수료를 입력해주세요."),
  });

  const { getFieldProps, setFieldValue, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate({
        companyKey: values.companyKey,
        partnerName: values.partnerName,
        commission: +values.commission,
        clientCommission: +values.clientCommission,
      });
      onClose();
    },
  });

  const onSelectCompany = useCallback(
    (_: SyntheticEvent<unknown>, v: Company | null) => {
      setCompany(v);
      setFieldValue("companyKey", v?.company_key);
    },
    [setFieldValue]
  );

  return (
    <Dialog
      css={addPartnerModalStyle}
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-coupang-partner"
      aria-describedby="add coupang partner"
    >
      <DialogTitle id="dialog-title">신규 파트너 등록</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="add-form" onSubmit={handleSubmit}>
          <Grid container spacing={2} rowSpacing={4}>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                className="field"
                size="small"
                options={companyData.companies}
                getOptionLabel={(company) => `${company.company_name}(${company.company_key})`}
                renderInput={({ InputLabelProps, ...params }) => (
                  <TextField
                    {...params}
                    label="업체"
                    placeholder="업체를 선택해주세요."
                    required
                    error={shouldErrorShows("companyKey", touched, errors)}
                    helperText={getHelperText("companyKey", touched, errors)}
                  />
                )}
                value={company}
                onChange={onSelectCompany}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="파트너명"
                placeholder="사용하실 파트너명을 입력해주세요."
                required
                {...getFieldProps("partnerName")}
                error={shouldErrorShows("partnerName", touched, errors)}
                helperText={getHelperText("partnerName", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="쿠팡 X 애드팝콘 수수료율"
                placeholder="애드팝콘에 대한 쿠팡 수수료"
                required
                {...getFieldProps("commission")}
                error={shouldErrorShows("commission", touched, errors)}
                helperText={
                  shouldErrorShows("commission", touched, errors)
                    ? getHelperText("commission", touched, errors)
                    : "* 해당 수수료율은 쿠팡에 대한 애드팝콘의 매출을 계산하는데 사용됩니다."
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="field"
                label="쿠팡 X 고객사 수수료율"
                placeholder="고객사 리포트에 적용될 고객사에 대한 쿠팡 수수료"
                required
                {...getFieldProps("clientCommission")}
                error={shouldErrorShows("clientCommission", touched, errors)}
                helperText={
                  shouldErrorShows("clientCommission", touched, errors)
                    ? getHelperText("clientCommission", touched, errors)
                    : "* 해당 수수료율은 고객사의 리포트에 노출될 수익을 계산하는데 사용됩니다."
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="add-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPartnerModal;
