export const POSTBACK = {
  PRODUCT: 0,
  ORDERER: 1,
  CPS_REWARD: 2,
};

export type Postback = Union<typeof POSTBACK>;

export const POSTBACK_ALIAS = {
  [POSTBACK.PRODUCT]: "구매 상품별 포스트백(단건)",
  [POSTBACK.ORDERER]: "주문자 기준 포스트백(리스트형)",
  [POSTBACK.CPS_REWARD]: "CPS 리워드 포스트백(CPS형)",
};
