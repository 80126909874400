import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  emails: {
    companyKey: string;
    email: string;
  }[];
}

interface Response {
  code: 200 | 400 | 401 | 409 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "conflict" | "internal-server-error";
}

// 이메일 등록
// 이미 등록된 이메일이 있는 경우에도 200 OK 반환, 등록 실패시에 500 반환
const usePostEmail = () => {
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const { handleError } = useApiError();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ emails }) => {
      const response: AxiosResponse<Response> = await API.default.post(
        `/payments/emails`,
        emails.map(({ companyKey, email }) => ({ company_key: companyKey, email: email }))
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("등록되었습니다.");
        invalidate("payments/useGetEmails");
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePostEmail;
