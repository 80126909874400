import { Paper, Typography } from "@mui/material";
import { useMemo } from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { DASHBOARD_LABEL } from "src/assets/alias/dashboard";
import COLORS from "src/assets/colors";
import {
  AppPlatform,
  APP_PLATFORM_ALIAS,
  MEDIA,
  Media,
  WebPlatform,
  WEB_PLATFORM_ALIAS,
} from "src/types";
import { DataKey } from "./Dashboard";
import MediationTooltip from "./PlatformTooltip";

interface PlatformChartProps {
  type: Media;
  data?: {
    click: number;
    ecpm: number;
    impression: number;
    request: number;
    response: number;
    revenue: number;
    rpr: number;
    type: AppPlatform | WebPlatform;
  }[];
  dataKey: DataKey;
}

const PlatformChart = ({
  type = MEDIA.APP,
  data = [],
  dataKey = "request",
}: PlatformChartProps) => {
  const total = useMemo(() => {
    if (data) return data.reduce((acc, cur) => acc + cur[dataKey], 0);
    return 0;
  }, [dataKey, data]);

  const platformData = useMemo(() => {
    if (data)
      return data.map((platform) => ({
        name:
          type === MEDIA.APP
            ? APP_PLATFORM_ALIAS[platform.type as AppPlatform]
            : WEB_PLATFORM_ALIAS[platform.type as WebPlatform],
        value: platform[dataKey],
      }));
    return [];
  }, [data, type, dataKey]);

  return (
    <>
      <Typography className="title" variant="h5" gutterBottom>
        플랫폼 <small className="sub-title">{`(${DASHBOARD_LABEL[dataKey]})`}</small>
      </Typography>
      <Paper elevation={2} className="chart">
        <ResponsiveContainer height={300}>
          <PieChart>
            <Pie data={platformData} innerRadius={90} outerRadius={120} dataKey={"value"}>
              {platformData?.map((_, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[(index * 5) % COLORS.length]} />
              ))}
            </Pie>
            <Legend iconSize={10} align="left" verticalAlign="middle" layout="vertical" />
            <Tooltip
              label="name"
              content={(params) => <MediationTooltip {...params} total={total} dataKey={dataKey} />}
            />
          </PieChart>
        </ResponsiveContainer>
      </Paper>
    </>
  );
};

export default PlatformChart;
