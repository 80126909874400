import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import { policyStyle } from "./styles";

const PolicyLayout = () => {
  return (
    <Container component="section" className="ssp-section" css={policyStyle}>
      <Outlet />
    </Container>
  );
};

export default PolicyLayout;
