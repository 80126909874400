export const MATCH = {
  MATCHED: 0,
  NOT_MATCHED: 1,
  ALL: 2,
};

export type Match = Union<typeof MATCH>;

export const MATCH_ALIAS = {
  [MATCH.MATCHED]: "매칭완료",
  [MATCH.NOT_MATCHED]: "매칭안됨",
  [MATCH.ALL]: "전체",
};
