import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  domainListId: number;
  domainListName: string;
}

interface Response {
  code: 200 | 400 | 401 | 409;
  text: "ok" | "bad-request" | "unauthorized" | "conflict";
}

// 차단 도메인 그룹명 변경
const usePutDomain = () => {
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const { handleError } = useApiError({
    409: (_error: AxiosError) =>
      toast.error(`해당 그룹명은 이미 존재합니다.
  그룹명을 다시 입력해주세요.`),
  });

  const result = useMutation<Response, AxiosError, Params>(
    async ({ domainListId, domainListName }) => {
      const response: AxiosResponse<Response> = await API.default.put(
        `/blocklist/domains/${domainListId}`,
        {
          domain_list_name: domainListName,
        }
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("수정되었습니다.");
        invalidate(["blocklist/useGetDomain", "blocklist/useGetDomains"]);
      },
    }
  );

  return result;
};

export default usePutDomain;
