import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  placementId: string;
  thirdpartyId: number;
  passbackScript: string;
}

interface Response {
  code: 200 | 400 | 401 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "internal-server-error";
}

// 서드파티 패스백 설정
const usePutWebThirdpartyPassback = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ placementId, thirdpartyId, passbackScript }) => {
      const response: AxiosResponse<Response> = await API.default.put(
        `/placements/web/${placementId}/thirdparties/${thirdpartyId}/passback`,
        { passback_script: passbackScript }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("수정되었습니다.");
        invalidate("placements/useGetWebThirdpartyList");
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePutWebThirdpartyPassback;
