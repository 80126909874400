import { Autocomplete, Box, Button, Grid, IconButton, Typography } from "@mui/material";
import {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  Fragment,
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { TextField } from "src/components/commons";
import useGetCompanies, { CompanyData } from "src/hooks/apis/companies/useGetCompanies";
import usePostEmail from "src/hooks/apis/payments/usePostEmail";
import { COMPANY } from "src/types";
import { REG_EXP } from "src/utils/form-helper";
import { v4 as uuidv4 } from "uuid";
import { registerEmailStyle } from "./styles";

const MAX_EMAIL_COUNT = 5;

const AddEmailForm = () => {
  const [emails, setEmails] = useState<
    {
      key: string;
      email: string;
      company?: CompanyData;
      emailError?: string;
      companyError?: string;
    }[]
  >([
    {
      key: uuidv4(),
      email: "",
    },
  ]);

  const { mutate } = usePostEmail();

  const { data: companyData } = useGetCompanies({
    types: [
      COMPANY.CORPORATION,
      COMPANY.INDIVIDUAL,
      COMPANY.SYNDICATION,
      COMPANY.SYNDICATION_CLIENT_CORPORATION,
      COMPANY.SYNDICATION_CLIENT_INDIVIDUAL,
    ],
    pageNo: 1,
  });

  const onChangeEmail = useCallback(
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const targetIdx = emails.findIndex((v) => v.key === key);
      setEmails((prev) => {
        if (targetIdx >= 0) {
          prev[targetIdx].email = e.target.value;
        }
        return [...prev];
      });
    },
    [emails]
  );

  const onSelectCompany = useCallback(
    (key: string) => (_: SyntheticEvent<unknown>, v: CompanyData | null) => {
      if (v)
        setEmails((prev) => {
          const targetIdx = prev.findIndex((v) => v.key === key);
          if (targetIdx >= 0) {
            prev[targetIdx].company = v;
          }
          return [...prev];
        });
    },
    []
  );

  const onCheckValidation = useCallback(
    (key: string, type: "company" | "email") => (e: FocusEvent) => {
      e.preventDefault();
      const targetIdx = emails.findIndex((v) => v.key === key);
      if (type === "company") {
        if (!REG_EXP.number.test(emails[targetIdx].company?.key || "")) {
          setEmails((prev) => {
            if (targetIdx >= 0) {
              prev[targetIdx].companyError = "업체를 선택해주세요.";
            }
            return [...prev];
          });
        } else {
          setEmails((prev) => {
            if (targetIdx >= 0) {
              prev[targetIdx].companyError = "";
            }
            return [...prev];
          });
        }
      }
      if (type === "email") {
        if (!REG_EXP.email.test(emails[targetIdx].email || "")) {
          setEmails((prev) => {
            if (targetIdx >= 0) {
              prev[targetIdx].emailError = "이메일을 입력해주세요.";
            }
            return [...prev];
          });
        } else {
          setEmails((prev) => {
            if (targetIdx >= 0) {
              prev[targetIdx].emailError = "";
            }
            return [...prev];
          });
        }
      }
    },
    [emails]
  );

  const onAddEmail = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (emails.length < MAX_EMAIL_COUNT) {
        setEmails((prev) => [...prev, { key: uuidv4(), email: "" }]);
      }
    },
    [emails.length]
  );

  const onDeleteEmail = useCallback(
    (key: string) => (e: MouseEvent) => {
      e.preventDefault();
      setEmails((prev) => prev.filter((v) => v.key !== key));
    },
    []
  );

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (
        emails.every(
          ({ email, company }) =>
            REG_EXP.email.test(email) && REG_EXP.number.test(company?.key || "")
        )
      ) {
        mutate({
          emails: emails.map(({ email, company }) => ({ email, companyKey: company?.key || "" })),
        });
        return;
      }
    },
    [emails, mutate]
  );

  return (
    <Box css={registerEmailStyle}>
      <Typography variant="h6" gutterBottom>
        자동 발송 이메일 등록
      </Typography>
      <form id="add-form" onSubmit={onSubmit}>
        <Grid container spacing={2}>
          {emails.map(({ key, email, company, emailError, companyError }) => {
            return (
              <Fragment key={key}>
                <Grid item xs={5.5}>
                  <Autocomplete
                    fullWidth
                    className="field"
                    size="small"
                    options={companyData.companies}
                    getOptionLabel={(company) => `${company.name}(${company.key})`}
                    renderOption={(props, company) => {
                      return (
                        <li {...props} key={company.id}>
                          {`${company.name}(${company.key})`}
                        </li>
                      );
                    }}
                    renderInput={({ InputLabelProps, ...params }) => (
                      <TextField
                        {...params}
                        label="업체 명"
                        placeholder="업체를 선택해주세요."
                        required
                        error={!!companyError}
                        helperText={companyError}
                        onBlur={onCheckValidation(key, "company")}
                      />
                    )}
                    isOptionEqualToValue={(company, value) => company.id === value.id}
                    value={company}
                    onChange={onSelectCompany(key)}
                  />
                </Grid>
                <Grid item xs={5.5}>
                  <TextField
                    type="email"
                    className="field"
                    label="이메일"
                    value={email}
                    placeholder="이메일을 입력하세요."
                    required
                    onChange={onChangeEmail(key)}
                    error={!!emailError}
                    helperText={emailError}
                    onBlur={onCheckValidation(key, "email")}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton aria-label="delete" onClick={onDeleteEmail(key)}>
                    <AiOutlineDelete className="ico-delete" />
                  </IconButton>
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Button onClick={onAddEmail}>+ 주소 추가하기</Button>
          </Grid>
          <Grid item xs={11}>
            <Button type="submit" className="submit" variant="outlined">
              등록하기
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddEmailForm;
