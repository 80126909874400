import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { Status, WebPlatform } from "src/types";
import API from "src/utils/api";

interface Params {
  mediaKey: string;
  domain: string;
  name: string;
  platformType: WebPlatform;
  status: Status;
}

interface Response {
  code: 200 | 400 | 401 | 409 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "conflict" | "internal-server-error";
}

// 웹 매체 수정
const usePatchWeb = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ mediaKey, domain, name, platformType, status }) => {
      const response: AxiosResponse<Response> = await API.default.patch(`/media/web/${mediaKey}`, {
        domain: domain,
        name: name,
        platform_type: platformType,
        status: status,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("수정되었습니다.");
        invalidate(["media/useGetWebMedia", "media/useGetWebMediaList"]);
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePatchWeb;
