import { Container } from "@mui/material";
import CompanyForm from "./CompanyForm";
import Guide from "./Guide";
import UpdateUid2Agreement from "./UpdateUid2Agreement";
import { useRecoilValue } from "recoil";
import { uid2Agreement } from "src/atoms/uid2Agreement";
import { ROLE, UserRole } from "src/types";

const Setting = () => {
  const { role } = useRecoilValue(uid2Agreement);

  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <CompanyForm />
      {([ROLE.PublisherAdministrator, ROLE.IGAWorksAdministrator] as UserRole[]).includes(role) && (
        <UpdateUid2Agreement />
      )}
      <Guide />
    </Container>
  );
};

export default Setting;
