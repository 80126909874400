import { Button, Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import { ValueFormatterParams } from "ag-grid-enterprise";
import moment from "moment";
import qs from "qs";
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { BasicTable, DeleteButton, SearchField, Select } from "src/components/commons";
import useDeleteCoupangSubId from "src/hooks/apis/coupang/useDeleteCoupangSubIds";
import useGetCoupangAccounts from "src/hooks/apis/coupang/useGetCoupangAccounts";
import useGetCoupangSubIds, { SubId } from "src/hooks/apis/coupang/useGetCoupangSubIds";
import useOpenModal from "src/hooks/useOpenModal";
import AddSubIdModal from "./AddSubIdModal";

const SubIdBoard = () => {
  const { search: queryString } = useLocation();
  const [search, setSearch] = useState({ temp: "", value: "" });
  const [pageNo, setPageNo] = useState(1);

  const [pageSize, setPageSize] = useState(20);
  const [account, setAccount] = useState<number>(100);
  const [openAddModal, onShowAddModal, onCloseAddModal] = useOpenModal(null);
  const { data: accountData } = useGetCoupangAccounts();

  useEffect(() => {
    if (queryString) {
      const { id, accountNo } = qs.parse(queryString, { ignoreQueryPrefix: true });
      setSearch({ temp: `${id}`, value: `${id}` });
      setAccount(parseInt(`${accountNo}`));
    }
  }, [queryString]);

  const { data: subIdData } = useGetCoupangSubIds({
    accountNo: account,
    q: search.value,
    pageNo,
    pageSize,
  });

  const onChangeSearchWord = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearch({ ...search, temp: e.target.value });
    },
    [search]
  );

  const onSearchSubId = useCallback(() => {
    setSearch({ ...search, value: search.temp });
    setPageNo(1);
  }, [search]);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: subIdData.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, subIdData.pages, onChangePage, pageSize, onChangePageSize]
  );

  const onChangeAccount = useCallback((event: SelectChangeEvent<string>) => {
    setAccount(+event.target.value);
    setPageNo(1);
  }, []);

  return (
    <>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={3}>
          <Select
            label="계정"
            placeholder="계정을 선택해주세요."
            onChange={onChangeAccount}
            value={`${account}`}
          >
            {accountData.accountList.map((account) => (
              <MenuItem key={account.account_no} value={account.account_no}>
                {account.account_id}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <SearchField
            label="검색어"
            placeholder="업체명 / 파트너명 / Sub ID"
            value={search.temp}
            onChange={onChangeSearchWord}
            onClickSearchButton={onSearchSubId}
          />
        </Grid>
        <Grid item xs={6} className="register">
          <Button variant="outlined" onClick={onShowAddModal}>
            SUB ID 등록
          </Button>
        </Grid>
      </Grid>
      <BasicTable
        getRowId={(params) => params.data.subid}
        animateRows
        rowData={subIdData.subIdList}
        columnDefs={columnDefs}
        {...paginationInfo}
      />
      {openAddModal.isOpen && (
        <AddSubIdModal isOpen={openAddModal.isOpen} onClose={onCloseAddModal} />
      )}
    </>
  );
};

const columnDefs = [
  { headerName: "파트너명", field: "partner_name", sortable: true },
  { headerName: "Sub ID", field: "subid", sortable: true },
  { headerName: "URL", field: "websites", sortable: true },
  {
    headerName: "등록일",
    field: "create_datetime",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      const createdAt = param.value;
      return moment(createdAt).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    headerName: "Delete",
    field: "subid",
    sortable: true,
    cellRenderer: (params: { data: SubId }) => (
      <RemoveButton accountNo={params.data.account_no} subId={params.data.subid} />
    ),
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

function RemoveButton({ accountNo, subId }: { accountNo: number; subId: string }) {
  const { mutate } = useDeleteCoupangSubId({ accountNo, subId });

  const onClickDeleteButton = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      mutate();
    },
    [mutate]
  );

  return (
    <DeleteButton onConfirm={onClickDeleteButton}>
      정말 해당 서브 ID를 삭제하시겠습니까?
    </DeleteButton>
  );
}

export default SubIdBoard;
