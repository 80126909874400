import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { STATUS, Status } from "src/types";
import API from "src/utils/api";

interface Params {
  placementId: string;
  thirdpartyId: number;
  status: Status;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    dsp_status: Status;
    individual_status: Status;
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 서드파티 개별설정 활성화 및 비활성화
// status = 50 (개별설정 사용안함)
const usePutWebThirdpartyStatus = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const { data, ...rest } = useMutation<Response, AxiosError, Params>(
    async ({ placementId, thirdpartyId, status }) => {
      const response: AxiosResponse<Response> = await API.default.put(
        `/placements/web/${placementId}/thirdparties/${thirdpartyId}/individual`,
        { status }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("수정되었습니다.");
        invalidate("placements/useGetWebThirdpartyList");
      },
      onError: handleError,
    }
  );

  const result = useMemo(
    () => (data ? data : { dsp_status: STATUS.SUSPEND, individual_status: STATUS.SUSPEND }),
    [data]
  );

  return { data: result, ...rest };
};

export default usePutWebThirdpartyStatus;
