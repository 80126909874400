import { Container, Paper, Tab, Tabs, Typography } from "@mui/material";
import { SyntheticEvent, useCallback, useState } from "react";
import DeepLinkBoard from "./deeplink";
import DSPBoard from "./dsp";
import ExternalReportSettingBoard from "./external";
import HeaderBiddingBoard from "./headerbidding";
import MediationBoard from "./mediation";

const PartnerManagement = () => {
  const [tabValue, setTabValue] = useState(0);

  const onClickMainMenu = useCallback((_event: SyntheticEvent, value: number) => {
    setTabValue(value);
  }, []);

  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        파트너 관리
      </Typography>
      <Paper className="content" elevation={2}>
        <Tabs className="tabs" value={tabValue} onChange={onClickMainMenu}>
          <Tab label="DSP" value={0} />
          <Tab label="미디에이션" value={1} />
          <Tab label="헤더비딩" value={2} />
          <Tab label="딥링크" value={3} />
          <Tab label="외부 리포트 연동" value={4} />
        </Tabs>
        {tabValue === 0 && <DSPBoard />}
        {tabValue === 1 && <MediationBoard />}
        {tabValue === 2 && <HeaderBiddingBoard />}
        {tabValue === 3 && <DeepLinkBoard />}
        {tabValue === 4 && <ExternalReportSettingBoard />}
      </Paper>
    </Container>
  );
};

export default PartnerManagement;
