import { css } from "@emotion/react";
import { grey } from "@mui/material/colors";

export const globalStyle = css`
  body {
    overflow: hidden;
  }
`;

export const loadingStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  .loading-box {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9;

    img {
      width: 3rem;
    }

    .loading {
      margin-top: 0.5rem;
      font-size: 1.5rem;
    }
  }

  &::after {
    content: "";
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${grey[100]};
    opacity: 0.5;
    z-index: -1;
  }
`;
