import { css } from "@emotion/react";

export const reportChartStyles = css`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  height: 450px;
  padding: 1rem;
  .chart-container {
    flex: 1;
  }
`;
