import CampaignHistory from "./CampaignHistory";
import ResponseForm from "./ResponseForm";
import ServiceClientInfo from "./ServiceClientInfo";

const CoupangServiceDetail = () => {
  return (
    <>
      <ServiceClientInfo />
      <CampaignHistory />
      <ResponseForm />
    </>
  );
};

export default CoupangServiceDetail;
