import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  month: string;
}

interface Response {
  code: 200 | 400 | 401 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "internal-server-error";
}

// 마감
//정산상태(payment_status)를 지급대기로 변경
// 세금계산서 상태(tax_bill_status)가 미확인(50)일 경우 이월(70)로 변경
const usePostClosing = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ month }) => {
      const response: AxiosResponse<Response> = await API.default.post(`/payments/closing`, {
        month,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("마감되었습니다.");
        invalidate([
          "payments/useGetCriteria",
          "payments/useGetPaymentDetail",
          "payments/useGetPayments",
        ]);
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePostClosing;
