import { css } from "@emotion/react";
import { grey } from "@mui/material/colors";

export const uploadFieldStyle = css`
  & > div {
    cursor: pointer;

    &.empty > input {
      color: ${grey[500]};
    }

    &.loaded {
      z-index: 1;
      .file-name {
        z-index: -1;
        position: absolute;
      }
      & > input {
        color: transparent;
      }
    }

    input {
      cursor: pointer;

      &::file-selector-button {
        display: none;
      }
    }
  }
`;

export const previewStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;

  iframe,
  img {
    width: 100%;
    height: 250px;
    object-fit: contain;
  }
`;
