import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, FocusEvent, MouseEvent, useCallback, useMemo, useState } from "react";
import { AiOutlineHistory } from "react-icons/ai";
import { BsPlusSquare } from "react-icons/bs";

import useGetAppPlacementDetail, {
  AppPlacementDetail,
} from "src/hooks/apis/placements/useGetAppPlacementDetail";

import useNativeTemplateList, {
  NativeTemplateType,
} from "src/hooks/apis/placements/useNativeTemplateList";

import useOpenModal from "src/hooks/useOpenModal";
import usePutAppPlacement from "src/hooks/apis/placements/usePutAppPlacement";

import { Select, TextField } from "src/components/commons";
import { CAMPAIGN, STATUS, Template } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";

import EditDspModal from "./EditDspModal";
import HistoryModal from "./HistoryModal";
import { addCampaignModalStyle } from "./styles";
import { getDefaultTemplateOption, getPreviewImgSize, subNativeValidationSchema } from "./const";

interface EditSubNativeModalProps {
  onClose: () => void;
  open: { key: string; isOpen: boolean };
  parentPlacementData: { placement: AppPlacementDetail };
}

const EditSubNativeModal = ({ onClose, open, parentPlacementData }: EditSubNativeModalProps) => {
  const { mutate } = usePutAppPlacement();
  const [openHistoryModal, onShowHistory, onCloseHistoryModal] = useOpenModal<string>("");
  const [openDspModal, onShowEditDsp, onCloseEditDspModal] = useOpenModal<string>("");

  /** 상위 플레이스먼트에 해당하는 네이티브 템플릿 타입 */
  const nativeTemplateType = useMemo(() => {
    const placementType = parentPlacementData.placement.type;
    const placementSize = parentPlacementData.placement.size_type;
    let nativeTemplateType = NativeTemplateType.BANNER_SMALL;

    if (placementType === CAMPAIGN.Banner && placementSize === 1) {
      nativeTemplateType = NativeTemplateType.BANNER_SMALL;
    }
    if (placementType === CAMPAIGN.Banner && placementSize === 2) {
      nativeTemplateType = NativeTemplateType.BANNER_LARGE;
    }
    if (placementType === CAMPAIGN.Banner && placementSize === 3) {
      nativeTemplateType = NativeTemplateType.BANNER_MEDIUM;
    }
    if (placementType === CAMPAIGN.Interstitial) {
      nativeTemplateType = NativeTemplateType.INTERSTITIAL;
    }

    return nativeTemplateType;
  }, [parentPlacementData.placement.size_type, parentPlacementData.placement.type]);

  const defaultTemplateOption = getDefaultTemplateOption(nativeTemplateType);
  const previewImgSize = getPreviewImgSize(defaultTemplateOption.templateNo);

  /** 광고 템플릿 옵션 목록 */
  const [templateOptions, setTemplateOptions] = useState([
    {
      templateNo: defaultTemplateOption.templateNo,
      name: defaultTemplateOption.name,
    },
  ]);

  /** 미리보기 이미지 속성 */
  const [previewImgProps, setPreviewImgProps] = useState({
    src: "", // nativeTemplateList 조회 전까지 알 수 없음
    width: previewImgSize.width,
    height: previewImgSize.height,
  });

  const initialValues = useMemo(
    () => ({
      placementId: "",
      name: parentPlacementData.placement.name ? `${parentPlacementData.placement.name}-N` : "",
      mediaKey: "",
      templateNo: defaultTemplateOption.templateNo,
      companionUse: false,
      subNativeUse: true,
      checkViewability: true,
      bidfloorCurrency: "",
      bidfloorStatus: false,
      bidfloor: 0,
      cpcBidfloorCurrency: "",
      cpcBidfloorStatus: false,
      cpcBidfloor: 0,
      type: CAMPAIGN.Native,
      sizeType: parentPlacementData.placement.size_type,
      minViewTime: 1,
      iconImageRequired: false,
      titleRequired: false,
      ctaTextRequired: false,
      descRequired: false,
      mainImageRequired: false,
      ctatextRequired: false,
      mute: false,
      isSDKMediation: false,
      nativeTemplateType, // validation schema에서 사용
    }),
    [
      defaultTemplateOption.templateNo,
      nativeTemplateType,
      parentPlacementData.placement.name,
      parentPlacementData.placement.size_type,
    ]
  );

  const {
    getFieldProps,
    setFieldValue,
    setValues,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    values,
  } = useFormik({
    initialValues,
    validationSchema: subNativeValidationSchema,
    onSubmit: ({ nativeTemplateType, ...values }) => {
      mutate(
        {
          ...values,
          templateNo: values.templateNo as Template,
          parentId: open.key,
          bidfloor: +values.bidfloor,
          bidfloorStatus: values.bidfloorStatus ? STATUS.ACTIVE : STATUS.SUSPEND,
          cpcBidfloor: +values.cpcBidfloor,
          cpcBidfloorStatus: values.cpcBidfloorStatus ? STATUS.ACTIVE : STATUS.SUSPEND,
        },
        {
          onSuccess: () => {
            mutate(
              {
                placementId: parentPlacementData.placement.id,
                bidfloor: parentPlacementData.placement.bidfloor,
                bidfloorCurrency: parentPlacementData.placement.bidfloor_currency,
                bidfloorStatus: parentPlacementData.placement.bidfloor_status,
                checkViewability: parentPlacementData.placement.check_viewability,
                cpcBidfloor: parentPlacementData.placement.cpc_bidfloor,
                cpcBidfloorCurrency: parentPlacementData.placement.cpc_bidfloor_currency,
                cpcBidfloorStatus: parentPlacementData.placement.cpc_bidfloor_status,
                ctaTextRequired: parentPlacementData.placement.ctatext_required,
                descRequired: parentPlacementData.placement.desc_required,
                iconImageRequired: parentPlacementData.placement.icon_image_required,
                isSDKMediation: parentPlacementData.placement.is_sdk_mediation,
                mainImageRequired: parentPlacementData.placement.main_image_required,
                maxDuration: parentPlacementData.placement.maxduration,
                mediaKey: parentPlacementData.placement.media_key,
                minDuration: parentPlacementData.placement.minduration,
                minViewTime: parentPlacementData.placement.minviewtime,
                mute: parentPlacementData.placement.mute,
                name: parentPlacementData.placement.name,
                rewardCurrency: parentPlacementData.placement.reward_currency,
                rewardValue: parentPlacementData.placement.reward_value,
                sizeType: parentPlacementData.placement.size_type,
                titleRequired: parentPlacementData.placement.title_required,
                type: parentPlacementData.placement.type,
                companionUse: false,
                subNativeUse: true,
              },
              {
                onSuccess: () => {
                  onClose();
                },
              }
            );
          },
        }
      );
    },
  });

  const { data: placementData, isSuccess: isAppPlacementDetailSuccess } = useGetAppPlacementDetail(
    {
      placementId: parentPlacementData.placement.sub_native_p_id,
    },
    {
      onSuccess: (response) => {
        const placementData = response.data.data?.placement;

        if (!placementData) {
          throw new Error("플레이스먼트 정보를 조회할 수 없습니다.");
        }

        setValues((prev) => ({
          ...prev,
          placementId: placementData.id,
          name: placementData.name || `${parentPlacementData.placement.name}-Native`,
          mediaKey: placementData.media_key,
          bidfloorCurrency: placementData.bidfloor_currency,
          bidfloorStatus: placementData.bidfloor_status === STATUS.ACTIVE ? true : false,
          bidfloor: placementData.bidfloor,
          cpcBidfloorCurrency: placementData.cpc_bidfloor_currency,
          cpcBidfloorStatus: placementData.cpc_bidfloor_status === STATUS.ACTIVE ? true : false,
          cpcBidfloor: placementData.cpc_bidfloor,
          templateNo: placementData.template_no || defaultTemplateOption.templateNo,
        }));
      },
    }
  );

  const { nativeTemplateList, isLoading: isNativeTemplateListLoading } = useNativeTemplateList(
    nativeTemplateType,
    {
      enabled: isAppPlacementDetailSuccess,
      // nativeTemplateList 조회 성공 시
      onSuccess: (response) => {
        const { data: nativeTemplateList } = response;
        const templateNo = placementData.placement.template_no;

        const selectedNativeTemplate = nativeTemplateList.find(
          (template) => template.id === templateNo
        );

        if (!selectedNativeTemplate) {
          throw new Error("존재하지 않는 템플릿입니다.");
        }

        // 템플릿 옵션 목록 업데이트 및 form.values.templateNo 값 업데이트
        setTemplateOptions(
          nativeTemplateList.map(({ id, name }) => ({
            templateNo: id,
            name,
          }))
        );

        // 미리보기 이미지 업데이트
        setPreviewImgProps((prev) => ({ ...prev, src: selectedNativeTemplate.iurl }));
      },
    }
  );

  /** 광고 템플릿 옵션 변경 핸들러 */
  const onChangeTemplateNo = useCallback(
    (e: SelectChangeEvent<Template>) => {
      const templateNo = +e.target.value as Template;
      const selectedNativeTemplate = nativeTemplateList.find(({ id }) => id === templateNo);

      if (!selectedNativeTemplate) {
        throw new Error("존재하지 않는 템플릿입니다.");
      }

      setFieldValue("templateNo", templateNo);
      setPreviewImgProps((prev) => ({ ...prev, src: selectedNativeTemplate.iurl }));
    },
    [nativeTemplateList, setFieldValue]
  );

  const onChangeBidfloor = useCallback(
    (field: "bidfloor" | "cpcBidfloor") => (e: ChangeEvent<HTMLInputElement>) => {
      const value =
        e.target.value.replace(
          REG_EXP.bidfloor,
          e.target.value.substring(0, e.target.value.length - 1)
        ) || 0;
      if (+(value || 0) < 1000000) {
        setFieldValue(field, value);
      }
    },
    [setFieldValue]
  );

  const onBlurBidfloor = useCallback(
    (field: "bidfloor" | "cpcBidfloor") => (e: FocusEvent) => {
      setFieldValue(field, parseFloat(`${values[field] || 0}`));
      handleBlur(e);
    },
    [handleBlur, setFieldValue, values]
  );

  const onShowHistoryModal = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      onShowHistory(e, placementData.placement.id);
    },
    [onShowHistory, placementData.placement.id]
  );

  const onShowEditDspModal = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      onShowEditDsp(e, placementData.placement.id);
    },
    [onShowEditDsp, placementData.placement.id]
  );

  const placementTypeStr = parentPlacementData.placement.type === CAMPAIGN.Banner ? "배너" : "전면";

  return (
    <Dialog
      css={addCampaignModalStyle}
      fullWidth
      maxWidth="md"
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-companion"
      aria-describedby="add companion"
    >
      <DialogTitle id="dialog-title">{placementData.placement.name}</DialogTitle>
      <DialogContent className="dialog-content">
        <Grid container>
          <Grid item xs={6} className="preview">
            {isNativeTemplateListLoading ? (
              <CircularProgress />
            ) : (
              <img {...previewImgProps} alt="preview" />
            )}
          </Grid>
          <Grid item xs={6}>
            <form id="add-sub-native-form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Select
                    label={`${placementTypeStr} 네이티브 광고 탬플릿`}
                    {...getFieldProps("templateNo")}
                    onChange={onChangeTemplateNo}
                  >
                    {templateOptions.map((option) => (
                      <MenuItem key={option.templateNo} value={option.templateNo}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="field"
                    label={`${placementTypeStr} 네이티브 광고 플레이스먼트 명`}
                    required
                    {...getFieldProps("name")}
                    error={shouldErrorShows("name", touched, errors)}
                    helperText={getHelperText("name", touched, errors)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="field"
                    label={`${placementTypeStr} 네이티브 광고 플레이스먼트 ID`}
                    required
                    disabled
                    {...getFieldProps("placementId")}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormControlLabel
                    className="switch"
                    control={<Switch color="primary" />}
                    label="BidFloor"
                    labelPlacement="start"
                    {...getFieldProps("bidfloorStatus")}
                    checked={values.bidfloorStatus}
                  />
                </Grid>
                <Grid item xs={4}>
                  {values.bidfloorStatus && (
                    <TextField
                      className="field"
                      required
                      prefix={<span className="ssp-input-currency-prefix">USD</span>}
                      {...getFieldProps("bidfloor")}
                      onChange={onChangeBidfloor("bidfloor")}
                      onBlur={onBlurBidfloor("bidfloor")}
                      error={shouldErrorShows("bidfloor", touched, errors)}
                      helperText={getHelperText("bidfloor", touched, errors)}
                    />
                  )}
                </Grid>
                {/* <Grid item xs={8}>
                  <FormControlLabel
                    className="switch"
                    control={<Switch color="primary" />}
                    label="CPC BidFloor"
                    labelPlacement="start"
                    {...getFieldProps("cpcBidfloorStatus")}
                    checked={values.cpcBidfloorStatus}
                  />
                </Grid>
                <Grid item xs={4}>
                  {values.cpcBidfloorStatus && (
                    <TextField
                      className="field"
                      required
                      prefix={<span className="ssp-input-currency-prefix">USD</span>}
                      {...getFieldProps("cpcBidfloor")}
                      onChange={onChangeBidfloor("cpcBidfloor")}
                      error={shouldErrorShows("cpcBidfloor", touched, errors)}
                      helperText={getHelperText("cpcBidfloor", touched, errors)}
                    />
                  )}
                </Grid> */}
                <Grid item xs={12} className="remote-tools">
                  <Button variant="contained" size="small" onClick={onShowEditDspModal}>
                    <BsPlusSquare />
                    DSP
                  </Button>
                  <Button variant="outlined" size="small" onClick={onShowHistoryModal}>
                    <AiOutlineHistory />
                    히스토리
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="add-sub-native-form" type="submit">
          저장
        </Button>
      </DialogActions>
      {openHistoryModal.isOpen && (
        <HistoryModal open={openHistoryModal} onClose={onCloseHistoryModal} />
      )}
      {openDspModal.isOpen && <EditDspModal open={openDspModal} onClose={onCloseEditDspModal} />}
    </Dialog>
  );
};

export default EditSubNativeModal;
