/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Media, MEDIA_ALIAS } from "src/types";
import API from "src/utils/api";
import useGetUserDetail from "../users/useGetUserDetail";

interface Params {
  companyKey?: string;
  type: Media;
  search?: string;
  pageNo?: number;
  pageSize?: number;
  orders?: string[];
  exports?: boolean;
}

// 매체별 플레이스먼트의 Bidfloor 조회
// Bidfloor가 활성화된 플레이스먼트만 조회
const useGetExcel = ({
  companyKey,
  type,
  search,
  pageNo = 1,
  pageSize = 20,
  orders,
  exports = false,
}: Params) => {
  const { handleError } = useApiError();
  const { data: userData } = useGetUserDetail({ userId: "me" });
  companyKey = companyKey || userData?.user.company_key;
  const { refetch, ...rest } = useQuery<any, AxiosError>(
    ["dashboard/useGetExcel", companyKey, type, search, pageNo, pageSize, orders, exports],
    async () => {
      const response: AxiosResponse<any> = await API.default.get(`/dashboard/bidfloors`, {
        responseType: "blob",
        params: {
          company_key: companyKey,
          type: type,
          q: search,
          page_no: pageNo,
          page_size: pageSize,
          orders: orders?.join(",") || "-bidfloor",
          export_xls: exports,
        },
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${MEDIA_ALIAS[type]}_Bidfloor.xlsx`);
      link.click();
    },
    { enabled: false, onError: handleError }
  );

  return { fetch: refetch, ...rest };
};

export default useGetExcel;
