import { Box, Button, Divider, Grid, Link as MuiLink, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { TextField } from "src/components/commons";
import useGetPassword from "src/hooks/apis/auth/useGetPassword";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { findFormStyle } from "./styles";

const FindPasswordForm = () => {
  const { mutate } = useGetPassword();
  const initialValues = useMemo(() => ({ email: "" }), []);

  const validationSchema = yup.object({
    email: yup
      .string()
      .matches(REG_EXP.email, "이메일을 확인해 주세요.")
      .required("이메일을 입력해 주세요."),
  });

  const { getFieldProps, handleSubmit, errors, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate({ email: values.email });
    },
  });

  return (
    <section className="find-form-section" css={findFormStyle}>
      <div className="form-box">
        <h2 className="title">비밀번호 찾기</h2>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <TextField
                type="email"
                label="Email"
                variant="standard"
                placeholder="Email"
                {...getFieldProps("email")}
                error={shouldErrorShows("email", touched, errors)}
                helperText={getHelperText("email", touched, errors)}
              />
            </Grid>
          </Grid>
          <Button className="submit" type="submit" variant="outlined">
            비밀번호 찾기
          </Button>
          <div className="sign-up">
            <span>{`Don't have an account?`}</span>
            <Link to="/login/register">Sign Up</Link>
          </div>
          <Box className="forgot">
            <Link to="/login/find/email">Forgot Email?</Link>
          </Box>
        </form>
        <Box className="footer">
          <MuiLink
            href="https://www.adpopcorn.com/policy/media"
            target="_blank"
            rel="noopener noreferrer"
          >
            서비스 이용약관
          </MuiLink>
          <Divider orientation="vertical" variant="middle" flexItem />
          <MuiLink
            href="https://www.adpopcorn.com/policy/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            개인정보처리방침
          </MuiLink>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Typography variant="body1" className="copyright">
            © 2021 adpopcorn
          </Typography>
        </Box>
      </div>
    </section>
  );
};

export default FindPasswordForm;
