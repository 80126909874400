import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import qs from "qs";
import { useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { TextField } from "src/components/commons";
import usePostSignUp from "src/hooks/apis/auth/usePostSignUp";
import useGetUserInvite from "src/hooks/apis/users/useGetUserInvite";
import useToast from "src/hooks/useToast";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { signUpFormStyle } from "./styles";

const SignUpForm = () => {
  const toast = useToast();
  const { search } = useLocation();
  const token = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }).token as string,
    [search]
  );
  const { data: inviteData } = useGetUserInvite({ token });
  const { mutate: signUp } = usePostSignUp();

  const initialValues = useMemo(
    () => ({
      companyName: inviteData.company_name || "",
      email: inviteData.user_account_id || "",
      name: "",
      password: "",
      confirmPassword: "",
      promotion: false,
      privacy: false,
      verifyCode: "",
    }),
    [inviteData.company_name, inviteData.user_account_id]
  );

  const validationSchema = yup.object({
    name: yup
      .string()
      .matches(REG_EXP.name, "한글 또는 영문만 입력해주세요.")
      .required("사용자 이름을 입력해주세요."),
    password: yup
      .string()
      .matches(REG_EXP.password, "대, 소문자, 숫자 포함 8글자만 입력 가능합니다.")
      .required("비밀번호를 입력해 주세요."),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "비밀번호가 일치하지 않습니다.")
      .required("비밀번호를 입력해 주세요."),
    promotion: yup.boolean(),
    privacy: yup.boolean().required("개인정보 수집 및 이용에 동의해주세요."),
  });

  const { getFieldProps, handleSubmit, errors, touched, values } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!values.privacy) {
        toast.error("개인정보 수집 및 이용에 동의해주세요.");
        return;
      }
      signUp({
        role: inviteData.role,
        token: inviteData.token,
        email: inviteData.user_account_id,
        username: values.name,
        password: values.password,
        promotionAgreement: values.promotion,
      });
    },
  });

  if (!token) return <Navigate to="/login/register" replace />;

  return (
    <section className="register-form-section" css={signUpFormStyle}>
      <div className="form-box">
        <h2 className="title">회원가입</h2>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                type="text"
                label="업체명"
                variant="standard"
                readOnly
                {...getFieldProps("companyName")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                label="이름"
                variant="standard"
                placeholder="사용자 이름"
                {...getFieldProps("name")}
                error={shouldErrorShows("name", touched, errors)}
                helperText={getHelperText("name", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="email"
                label="계정 이메일"
                variant="standard"
                readOnly
                {...getFieldProps("email")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                label="비밀번호"
                variant="standard"
                placeholder="대, 소문자, 숫자 포함 (8글자 이상)"
                {...getFieldProps("password")}
                error={shouldErrorShows("password", touched, errors)}
                helperText={getHelperText("password", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                label="비밀번호 확인"
                variant="standard"
                placeholder="비밀번호 확인"
                {...getFieldProps("confirmPassword")}
                error={shouldErrorShows("confirmPassword", touched, errors)}
                helperText={getHelperText("confirmPassword", touched, errors)}
              />
            </Grid>
          </Grid>
          <Grid container className="check-list">
            <Grid item xs={12}>
              <FormControlLabel
                label="개인정보 수집 및 이용 동의 (필수)"
                className="privacy"
                control={<Checkbox {...getFieldProps("privacy")} checked={values.privacy} />}
              />
            </Grid>
            <Grid item xs={12} className="description">
              <Typography variant="caption">
                ADPOPCORN SSP의{" "}
                <MuiLink
                  href="https://www.adpopcorn.com/policy/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  개인정보 수집
                </MuiLink>{" "}
                및{" "}
                <MuiLink
                  href="https://www.adpopcorn.com/policy/media"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  서비스 이용약관
                </MuiLink>
                에 동의합니다
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label="프로모션 알림 수신 동의 (선택)"
                className="promotion"
                control={<Checkbox {...getFieldProps("promotion")} checked={values.promotion} />}
              />
            </Grid>
            <Grid item xs={12} className="description">
              <Typography variant="caption">
                ADPOPCORN SSP의 프로모션 알림 수신을 동의합니다.
              </Typography>
            </Grid>
          </Grid>
          <Button className="submit" type="submit" variant="outlined">
            Sign Up
          </Button>
        </form>
      </div>
    </section>
  );
};

export default SignUpForm;
