import { css } from "@emotion/react";
import { grey, red, teal } from "@mui/material/colors";

export const companySettingStyle = css`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: auto;

  .verified {
    display: flex;
    align-items: center;
    color: ${teal[500]};
    margin-left: auto;
    svg {
      margin-top: 2px;
      margin-right: 4px;
    }
  }

  .unverified {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: ${red[500]};
    svg {
      margin-top: 2px;
      margin-right: 4px;
    }
  }

  .submit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
  }
`;

export const guideStyle = css`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: auto;
  margin-top: 2rem;

  .guide-title {
    display: flex;
    align-items: center;
    color: ${teal[500]};

    svg {
      margin-top: 4px;
      margin-right: 8px;
    }
  }

  .guide-list {
    font-size: 0.8rem;
    padding: 1rem;
    color: ${grey[600]};
    line-height: 1.5;
    li {
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: 0;
      }

      .download {
        display: flex;
        align-items: center;
        color: ${teal[500]};
        font-weight: 500;
        text-decoration: none;
        svg {
          margin-right: 4px;
        }
      }
    }
  }
`;
