import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, SyntheticEvent, useCallback, useMemo, useState } from "react";
import { TextField, UploadField } from "src/components/commons";
import usePostCompany from "src/hooks/apis/companies/usePostCompany";
import useGetUserDetail, { User } from "src/hooks/apis/users/useGetUserDetail";
import useGetUsers from "src/hooks/apis/users/useGetUsers";
import { Company, COMPANY, COMPANY_ALIAS } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";

interface AddCompanyParams {
  type: Company;
  brn: string;
  bri: File | string;
  rrn: string;
  rri: File | string;
  companyName: string;
  address: string;
  manager: number;
  phone: string;
  adserver: boolean;
}

interface SyndicationModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const SyndicationModal = ({ isOpen, onClose }: SyndicationModalProps) => {
  const [manager, setManager] = useState<User | null>(null);
  const syndicationId = useGetUserDetail({ userId: "me" }).data?.user.syndication_id;
  const managers = useGetUsers({ syndicationId }).data?.users || [];
  const { mutate: addSyndication } = usePostCompany();

  const initialValues: AddCompanyParams = useMemo(
    () => ({
      type: COMPANY.SYNDICATION_CLIENT_CORPORATION,
      brn: "",
      bri: "",
      rrn: "",
      rri: "",
      companyName: "",
      address: "",
      manager: 0,
      phone: "",
      adserver: false,
    }),
    []
  );

  const validationSchema = yup.object({
    type: yup.number().required("타입을 선택해주세요."),
    brn: yup.string().when("type", {
      is: COMPANY.SYNDICATION_CLIENT_CORPORATION,
      then: yup
        .string()
        .test("brn", "올바른 사업자등록번호를 입력해주세요.", (value) =>
          value?.match(REG_EXP.brn) === null ? false : true
        )
        .required("사업자등록번호를 입력해주세요."),
    }),
    bri: yup.mixed().when("type", {
      is: COMPANY.SYNDICATION_CLIENT_CORPORATION,
      then: yup.mixed().required("사업자 등록증 사본을 업로드해주세요."),
    }),
    rrn: yup.string().when("type", {
      is: COMPANY.SYNDICATION_CLIENT_INDIVIDUAL,
      then: yup
        .string()
        .test("rrn", "올바른 주민등록번호를 입력해주세요.", (value) =>
          value?.match(REG_EXP.rrn) === null ? false : true
        )
        .required("주민등록번호를 입력해주세요."),
    }),
    rri: yup.mixed().when("type", {
      is: COMPANY.SYNDICATION_CLIENT_INDIVIDUAL,
      then: yup.mixed().required("주민등록증 사본을 업로드해주세요."),
    }),
    companyName: yup.string().required("업체명을 입력해주세요."),
    address: yup.string().required("업체 주소를 입력해주세요."),
    manager: yup.number().moreThan(0, "담당자를 선택해주세요.").required("담당자를 선택해주세요."),
    phone: yup.string().matches(REG_EXP.phone, "올바른 연락처를 입력해주세요."),
    adserver: yup.boolean(),
  });

  const { getFieldProps, handleSubmit, setFieldValue, touched, errors, values } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (value) => {
      addSyndication({
        companyType: value.type,
        companyName: value.companyName,
        managerUid: +value.manager,
        syndicationCompanyId: syndicationId,
        registrationNo:
          value.type === COMPANY.SYNDICATION_CLIENT_CORPORATION ? value.brn : value.rrn,
        address: value.address,
        useAdserver: value.adserver,
        file: (value.type === COMPANY.SYNDICATION_CLIENT_CORPORATION
          ? value.bri
          : value.rri) as File,
      });
      onClose();
    },
  });

  const onChangeType = useCallback(
    (e: ChangeEvent<HTMLInputElement>, v: string) => {
      e.preventDefault();
      setFieldValue("type", +v);
    },
    [setFieldValue]
  );

  const onChangeManger = useCallback(
    (_: SyntheticEvent<unknown>, v: User | null) => {
      setManager(v);
      setFieldValue("manager", v?.id);
      setFieldValue("phone", v?.phone_no);
    },
    [setFieldValue]
  );

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-syndication"
      aria-describedby="edit syndication"
    >
      <DialogTitle id="dialog-title">신규 업체</DialogTitle>
      <DialogContent>
        <form id="syndication-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioGroup
                row
                className="field"
                aria-label="filter"
                {...getFieldProps("type")}
                onChange={onChangeType}
              >
                <FormControlLabel
                  value={COMPANY.SYNDICATION_CLIENT_CORPORATION}
                  control={<Radio />}
                  label={COMPANY_ALIAS[COMPANY.SYNDICATION_CLIENT_CORPORATION]}
                />
                <FormControlLabel
                  value={COMPANY.SYNDICATION_CLIENT_INDIVIDUAL}
                  control={<Radio />}
                  label={COMPANY_ALIAS[COMPANY.SYNDICATION_CLIENT_INDIVIDUAL]}
                />
              </RadioGroup>
            </Grid>
            {/* 신디케이션 고객사(기업) */}
            {values.type === COMPANY.SYNDICATION_CLIENT_CORPORATION && (
              <>
                <Grid item xs={12}>
                  <TextField
                    className="field"
                    label="사업자등록번호"
                    placeholder="사업자등록번호를 입력해주세요."
                    {...getFieldProps("brn")}
                    error={shouldErrorShows("brn", touched, errors)}
                    helperText={getHelperText("brn", touched, errors)}
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UploadField
                    fullWidth
                    className="field"
                    label="사업자등록증"
                    required
                    size="small"
                    accept=".jpg, .jpeg, .png, .pdf"
                    preview
                    {...getFieldProps("bri")}
                    onChange={(data: File) => setFieldValue("bri", data)}
                    error={shouldErrorShows("bri", touched, errors)}
                    helperText={getHelperText("bri", touched, errors)}
                  />
                </Grid>
              </>
            )}
            {/* 신디케이션 고객사(개인) */}
            {values.type === COMPANY.SYNDICATION_CLIENT_INDIVIDUAL && (
              <>
                <Grid item xs={12}>
                  <TextField
                    className="field"
                    label="주민등록번호"
                    placeholder="주민등록번호를 입력해주세요."
                    {...getFieldProps("rrn")}
                    error={shouldErrorShows("rrn", touched, errors)}
                    helperText={getHelperText("rrn", touched, errors)}
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UploadField
                    fullWidth
                    className="field"
                    label="주민등록증"
                    required
                    size="small"
                    accept=".jpg, .jpeg, .png, .pdf"
                    preview
                    {...getFieldProps("rri")}
                    onChange={(data: File) => setFieldValue("rri", data)}
                    error={shouldErrorShows("rri", touched, errors)}
                    helperText={getHelperText("rri", touched, errors)}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                className="field"
                label="업체명"
                placeholder="업체명을 입력해주세요."
                {...getFieldProps("companyName")}
                error={shouldErrorShows("companyName", touched, errors)}
                helperText={getHelperText("companyName", touched, errors)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="업체 주소"
                placeholder="업체주소를 입력해주세요."
                {...getFieldProps("address")}
                error={shouldErrorShows("address", touched, errors)}
                helperText={getHelperText("address", touched, errors)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                className="field"
                size="small"
                options={managers}
                getOptionLabel={(manager) => `${manager.name}(${manager.account_id})`}
                renderInput={({ InputLabelProps, ...params }) => (
                  <TextField
                    {...params}
                    label="담당자"
                    placeholder="담당자를 선택해주세요."
                    required
                    error={shouldErrorShows("manager", touched, errors)}
                    helperText={getHelperText("manager", touched, errors)}
                  />
                )}
                value={manager}
                onChange={onChangeManger}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="담당자 연락처"
                placeholder="연락처를 입력해주세요."
                {...getFieldProps("phone")}
                error={shouldErrorShows("phone", touched, errors)}
                helperText={getHelperText("phone", touched, errors)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Switch color="primary" />}
                label="ADServer"
                labelPlacement="start"
                {...getFieldProps("adserver")}
                checked={values.adserver}
              />
            </Grid>
            <Grid item xs={6} className="remote-tools">
              <Typography variant="caption">
                리포트 설정은 업체 등록 후에 설정이 가능합니다.
              </Typography>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="syndication-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SyndicationModal;
