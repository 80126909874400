import { Container, Grid, MenuItem, Paper, SelectChangeEvent, Typography } from "@mui/material";
import { SortChangedEvent, ValueFormatterParams } from "ag-grid-community";
import { ICellRendererParams } from "ag-grid-enterprise";
import moment, { Moment } from "moment";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { BasicTable, DateField, SearchField, Select } from "src/components/commons";
import useGetRewardCSList from "src/hooks/apis/rewardCS/useGetRewardCSList";
import { Status, STATUS } from "src/types";
import { serviceBoardStyle } from "./styles";

const ServiceBoard = () => {
  const [status, setStatus] = useState<Status>(STATUS.ACTIVE);
  const [search, setSearch] = useState({ temp: "", value: "" });
  const [since, setSince] = useState(moment().subtract(6, "d"));
  const [until, setUntil] = useState(moment());
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [orders, setOrders] = useState<string[]>([]);
  const { data } = useGetRewardCSList({
    status,
    since,
    until,
    search: search.value,
    pageNo,
    pageSize,
    orders,
  });

  const onChangeStatus = useCallback((event: SelectChangeEvent<Status>) => {
    const value = +event.target.value as Status;
    setStatus(value);
  }, []);

  const onChangeSearchWord = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearch({ ...search, temp: e.target.value });
    },
    [search]
  );

  const onSearchCS = useCallback(() => {
    setSearch({ ...search, value: search.temp });
    setPageNo(1);
  }, [search]);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [pageNo, data.pages, onChangePage, pageSize, onChangePageSize]
  );

  const onChangeSinceDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setSince(value);
    }
    return;
  }, []);

  const onChangeUntilDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setUntil(value);
    }
    return;
  }, []);

  const onSortChanged = useCallback((e: SortChangedEvent) => {
    const sortInfo = e.columnApi
      .getColumnState()
      .filter((column) => !!column.sort)
      .map(({ colId, sort }) => {
        if (sort === "desc") return `-${colId}`;
        return `+${colId}`;
      });
    setOrders(sortInfo);
  }, []);

  return (
    <Container component="section" className="ssp-section" css={serviceBoardStyle} maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        리워드 CS 관리
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid container className="ssp-tools" spacing={2}>
          <Grid item xs={3}>
            <Select
              label="상태"
              placeholder="상태를 선택해주세요."
              onChange={onChangeStatus}
              value={status}
            >
              <MenuItem value={STATUS.ACTIVE}>처리 중(접수)</MenuItem>
              <MenuItem value={STATUS.DELETED}>처리 완료</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>
            <DateField label="시작일" value={since} maxDate={until} onChange={onChangeSinceDate} />
          </Grid>
          <Grid item xs={3}>
            <DateField
              label="종료일"
              value={until}
              minDate={since}
              maxDate={moment()}
              onChange={onChangeUntilDate}
            />
          </Grid>
          <Grid item xs={3}>
            <SearchField
              label="검색어"
              placeholder="검색어를 입력해주세요."
              value={search.temp}
              onChange={onChangeSearchWord}
              onClickSearchButton={onSearchCS}
            />
          </Grid>
        </Grid>
        <BasicTable
          rowData={data.items}
          columnDefs={columnDefs}
          onSortChanged={onSortChanged}
          {...paginationInfo}
        />
      </Paper>
    </Container>
  );
};

const columnDefs = [
  { headerName: "NO", field: "reward_cs_no", sortable: true, minWidth: 75 },
  {
    headerName: "문의 일자",
    field: "created_at",
    sortable: true,
    minWidth: 120,
    valueFormatter: (param: ValueFormatterParams) => {
      return moment(param.value * 1000).format("YYYY-MM-DD");
    },
  },
  {
    headerName: "미디어",
    field: "media_name",
    sortable: true,
    minWidth: 150,
  },
  {
    headerName: "업체",
    field: "company_key",
    sortable: true,
    minWidth: 130,
    valueFormatter: (param: ValueFormatterParams) => {
      const { company_key, company_name } = param.data;
      return `${company_name}\n(${company_key})`;
    },
  },
  {
    headerName: "ADID",
    field: "adid",
    sortable: true,
    minWidth: 180,
  },
  {
    headerName: "USN",
    field: "usn",
    sortable: true,
    minWidth: 200,
  },
  {
    headerName: "Email",
    field: "email",
    sortable: true,
    minWidth: 200,
  },
  {
    headerName: "문의내용",
    field: "text",
    sortable: true,
    minWidth: 300,
    cellRenderer: (params: ICellRendererParams) => {
      const { text, reward_cs_no } = params.data;
      return (
        <Link className="text" to={`/operation/service/detail/${reward_cs_no}`}>
          {text}
        </Link>
      );
    },
  },
];

export default ServiceBoard;
