import { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "react-query";

import useUser from "src/hooks/useUser";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

import useApiError from "../useApiError";
import useDataToolsHistory from "./use-data-tools-history";

interface Params {
  start_date: string; // yyyyMMdd
  end_date: string; // yyyyMMdd
  advertising_id: string; // ADID | IDFA
  media_key: string;
  placement_ids: string[];
}

interface Response {
  code: number;
  text: string;
  detail?: string;
}

const usePostCreativeReport = () => {
  const { handleError } = useApiError();
  const { userId } = useUser();
  const queryClient = useQueryClient();
  const { queryKey } = useDataToolsHistory({ enabled: false });
  const toast = useToast();

  const mutation = useMutation<Response, AxiosError, Params>(
    "datatools/usePostCreativeReport",
    async (params) => {
      const response: AxiosResponse<Response> = await API.integration.post(
        `/operation/management/datatools/v2/report/creative`,
        {
          ...params,
          customer_no: userId,
        }
      );
      return response.data;
    },
    {
      onSuccess: async () => {
        toast.success("리포트 생성 요청 성공");
        await queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        if (error.response?.data) {
          const msg = error.response.data?.detail || error.response.data.text;
          toast.error(msg);
        } else {
          handleError(error);
        }
      },
    }
  );

  return mutation;
};

export default usePostCreativeReport;
