import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";
import { Status } from "src/types";

export type NpayApp = {
  commission_rate: number;
  company_key: string;
  company_name: string;
  created_at: number; // UNIX TIMESTAMP
  daily_cap: number;
  key: string;
  name: string;
  plcmt_cnt: number;
  point: number;
  status: Status;
  updated_at: number; // UNIX TIMESTAMP
};

export type NpayAppFields = keyof NpayApp | `-${keyof NpayApp}`;

export type NpayAppListParams = {
  query?: string;
  page_no?: number;
  page_size?: number;
  status?: Status;
  orders?: NpayAppFields[];
};

type NpayAppListData = {
  code: number;
  text: string;
  data: {
    page_no: number;
    page_size: number;
    pages: number;
    rows: NpayApp[];
  };
};

type NpayAppListQueryKey = ["operation/npay/app-list", NpayAppListParams];

type NpayAppListQueryFn = QueryFunction<AxiosResponse<NpayAppListData>, NpayAppListQueryKey>;

type NpayAppListQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<NpayAppListData>,
    AxiosError,
    AxiosResponse<NpayAppListData>,
    NpayAppListQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: NpayAppListData = {
  code: 200,
  text: "OK",
  data: {
    page_no: 1,
    page_size: 1,
    pages: 1,
    rows: [],
  },
};

export default function useNpayAppList(
  params: NpayAppListParams,
  options?: NpayAppListQueryOptions
) {
  const { handleError } = useApiError();

  const queryKey = useMemo<NpayAppListQueryKey>(
    () => ["operation/npay/app-list", params],
    [params]
  );

  const queryFn = useCallback<NpayAppListQueryFn>((context) => {
    const [, params] = context.queryKey;
    const cookie = parseCookies();

    return API.consoleV3.get("/v3/naverpay/media", {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
      params,
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
