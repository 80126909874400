import { Container } from "@mui/material";
import PasswordForm from "./PasswordForm";
import { accountSettingStyle } from "./styles";
import UserInfoForm from "./UserInfoForm";

const Setting = () => {
  return (
    <Container component="section" className="ssp-section" css={accountSettingStyle} maxWidth="xl">
      <UserInfoForm />
      <PasswordForm />
    </Container>
  );
};

export default Setting;
