import { AxiosError, AxiosResponse } from "axios";
import { Moment } from "moment";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Partner, Report } from "src/types";
import API from "src/utils/api";
import { PartnerApp } from "./useGetPartnerAppList";

export interface Data {
  report_date: string;
  partner_id: number;
  partner_app_key: string;
  impression: number;
  click: number;
  conversion: number;
  unique_user: number;
  media_cost: number;
  ctr: number;
  cvr: number;
  ecpm: number;
  country: string;
}

interface Params {
  since: Moment;
  until: Moment;
  partnerList: Partner[];
  partnerAppList: PartnerApp[];
  type: Report;
  byCountry: boolean;
}

// 파트너 리포트 엑셀 다운로드
const usePostOfferwallExcel = () => {
  const { handleError } = useApiError();
  const result = useMutation<any, AxiosError, Params>(
    async ({ since, until, partnerList, partnerAppList, type, byCountry }) => {
      const reportType = {
        1: "day",
        2: "week",
        3: "month",
      };
      const dimensions = byCountry ? [reportType[type], "country"] : [reportType[type]];
      const response: AxiosResponse<any> = await API.console.post(
        `/report/partners/offerwall`,
        {
          since: since.format("YYYYMMDD"),
          until: until.format("YYYYMMDD"),
          partner_id: partnerList,
          partner_app_key: partnerAppList.map((v) => v.partner_app_key),
          dimensions,
          output_format: "excel",
        },
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `파트너리포트 ${since.format("YYYYMMDD")}_${until.format("YYYYMMDD")}.xlsx`
      );
      link.click();
    },
    { onError: handleError }
  );

  return result;
};

export default usePostOfferwallExcel;
