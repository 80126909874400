import { Button, FormControlLabel, Grid, Switch } from "@mui/material";
import { ColDef, ColGroupDef, GetGroupRowAggParams, ValueFormatterParams } from "ag-grid-community";
import { MouseEvent, SyntheticEvent, useCallback, useMemo } from "react";
import DataFormat from "src/assets/formats";
import { BasicTable } from "src/components/commons";
import { ReportTableData } from "src/hooks/apis/report/usePostOfferwallReport";
import { getConversionRate, getCtr, getEcpm } from "src/utils/calculator";

interface OfferwallReportBoardProps {
  data: ReportTableData[];
  country: boolean;
  onToggleCountry: (_event: SyntheticEvent<Element, Event>, checked: boolean) => void;
  onExport: (e: MouseEvent) => void;
}

const OfferwallReportBoard = ({
  data,
  country,
  onToggleCountry,
  onExport,
}: OfferwallReportBoardProps) => {
  // 테이블 상단 총합
  const totalRowData = useMemo(() => {
    const total = {
      partnerName: "총합",
      impression: data.reduce((acc, cur) => acc + +cur.impression, 0),
      click: data.reduce((acc, cur) => acc + +cur.click, 0),
      conversion: data.reduce((acc, cur) => acc + +cur.conversion, 0),
      uniqueUser: 0,
      mediaCost: data.reduce((acc, cur) => acc + +cur.mediaCost, 0),
    };

    return [
      {
        ...total,
        ctr: getCtr(total.impression, total.click),
        cvr: getConversionRate(total.conversion, total.click),
        ecpm: getEcpm(total.impression, total.mediaCost),
      },
    ];
  }, [data]);

  // 테이블 그룹 합계
  const groupRowData = useCallback(
    (params: GetGroupRowAggParams) =>
      params.nodes.reduce(
        (acc, cur) => {
          const data = cur.group ? cur.aggData : cur.data;

          acc.impression += +data.impression;
          acc.click += +data.click;
          acc.conversion += +data.conversion;
          acc.uniqueUser += +data.uniqueUser;
          acc.mediaCost += +data.mediaCost;
          acc.cvr = getConversionRate(acc.conversion, acc.click);
          acc.ctr = getCtr(acc.impression, acc.click);
          acc.ecpm = getEcpm(acc.impression, acc.mediaCost);

          return acc;
        },
        {
          impression: 0,
          click: 0,
          conversion: 0,
          uniqueUser: 0,
          mediaCost: 0,
          cvr: 0,
          ctr: 0,
          ecpm: 0,
        }
      ),
    []
  );

  return (
    <>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={12} className="download">
          <FormControlLabel
            className="switch"
            control={<Switch color="primary" />}
            label="국가별 리포트"
            labelPlacement="end"
            checked={country}
            onChange={onToggleCountry}
          />
          <Button variant="outlined" onClick={onExport}>
            엑셀 다운로드
          </Button>
        </Grid>
      </Grid>
      <BasicTable
        rowData={data}
        columnDefs={getColumnDefs(country)}
        animateRows={true}
        getGroupRowAgg={groupRowData}
        autoGroupColumnDef={{
          headerName: "",
          field: "partnerName",
          pinned: "left",
          minWidth: 300,
          cellRendererParams: {
            suppressCount: true,
          },
          cellStyle: (params) => {
            if (params.node.rowPinned) {
              return {
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              };
            }
            return;
          },
        }}
        onGridColumnsChanged={(e) => {
          e.api.sizeColumnsToFit();
        }}
        pinnedTopRowData={totalRowData}
      />
    </>
  );
};

const getColumnDefs = (country: boolean): (ColDef | ColGroupDef)[] => {
  const columnDefs = [
    {
      field: "partnerAppName",
      rowGroup: true,
      hide: true,
    },
    {
      field: "date",
      rowGroup: true,
      hide: true,
    },
    {
      field: "partnerName",
      hide: true,
    },
    {
      headerName: "국가",
      field: "country",
      sortable: true,
      pinned: "left",
      minWidth: 100,
    },
    {
      headerName: DataFormat.impression.label,
      field: "impression",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.impression.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.click.label,
      field: "click",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.click.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.ctr.label,
      field: "ctr",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.ctr.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.conversion.label,
      field: "conversion",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.conversion.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.cvr.label,
      field: "cvr",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.cvr.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.uniqueUser.label,
      field: "uniqueUser",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.uniqueUser.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.mediaCost.label,
      field: "mediaCost",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.mediaCost.formatter(params.value);
        }
        return "-";
      },
    },
    {
      headerName: DataFormat.ecpm.label,
      field: "ecpm",
      sortable: true,
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value > 0) {
          return DataFormat.ecpm.formatter(params.value);
        }
        return "-";
      },
    },
  ];
  if (!country) columnDefs.splice(3, 1);
  return columnDefs;
};

export default OfferwallReportBoard;
