import { css } from "@emotion/react";
import { cyan, grey, teal } from "@mui/material/colors";

export const inviteModalStyle = css`
  .highlight {
    color: ${teal[500]};
  }
`;

export const signUpFormStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  div.form-box {
    width: 50%;
    h2.title {
      color: ${grey[600]};
      font-weight: 600;
      font-size: 1.5em;
      text-transform: uppercase;
      display: inline-block;
      letter-spacing: 1px;
      border-bottom: 4px solid ${teal[500]};
    }
    form {
      margin-top: 1.5rem;

      .check-list {
        margin: 2rem 0;

        .privacy,
        .promotion {
          display: flex;
          align-items: center;
          color: ${grey[700]};
          font-weight: 400;
        }

        .description {
          margin-left: 2rem;
          color: ${grey[500]};
        }
      }

      .submit {
        width: 100%;
      }
    }

    .sign-up {
      margin-top: 1rem;
      color: ${grey[700]};
      font-size: 14px;

      a {
        text-decoration: none;
        margin-left: 8px;
        color: ${teal[700]};
        &:hover {
          color: ${cyan[700]};
        }
      }
    }
    .forgot {
      display: flex;
      align-items: center;
      margin-top: 10px;
      a {
        display: flex;
        text-decoration: none;
        font-size: 14px;
        color: ${teal[700]};
        &:hover {
          color: ${cyan[700]};
        }
      }
      hr {
        display: flex;
        margin: 0 0.5rem;
      }
    }
  }
`;
