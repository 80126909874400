import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  exchangeRate: number;
  expirationDate: string;
  month: string;
}

interface Response {
  code: 200 | 400 | 401 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "internal-server-error";
}

// 정산기준 설정
// 세금계산서 발행 마감일(expiration_date)이 기준월보다 이전일 경우 400
// 해당 월의 세금계산서 발행액이 변경된 후 다시 정산기준을 설정한 경우 금액이 변경되지 않는다.
// 발행액 변경 내역이 없다면 재입력한 기준환율로 재계산한 후 세금계산서 발행액을 변경한다.
const usePostCriteria = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ exchangeRate, expirationDate, month }) => {
      const response: AxiosResponse<Response> = await API.default.post(`/payments/criteria`, {
        exchange_rate: exchangeRate,
        expiration_date: expirationDate,
        month: month,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("정산 기준이 수정되었습니다.");
        invalidate([
          "payments/useGetCriteria",
          "payments/useGetPaymentDetail",
          "payments/useGetPayments",
        ]);
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePostCriteria;
