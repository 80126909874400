import { css } from "@emotion/react";
import { grey } from "@mui/material/colors";

export const managerSettingStyle = css`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: auto;

  .content {
    position: relative;
    .submit {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 1rem;
    }
  }
`;

export const waitingStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  .content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
  }

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${grey[200]};
    opacity: 0.3;
    z-index: -1;
  }
`;

export const refusedModalStyle = css`
  .reason {
    margin-top: 1rem;
    color: black;
  }
`;
