import moment from "moment";
import { parseCookies } from "nookies";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useQuery from "src/hooks/useQuery";

export type RedirectState = { requestedUrl?: string };

const RedirectRoute = () => {
  const cookie = parseCookies();
  const now = moment();
  const token = cookie.access_token || "";
  const expiredDate = +cookie.expires || 0;
  const { pathname } = useLocation();
  const query = useQuery();

  // 토큰 살아있는데 로그인 접근
  if (
    pathname.includes("/login") &&
    !pathname.includes("/login/invite") &&
    token &&
    now.isSameOrBefore(expiredDate)
  ) {
    let returnUrl = query.get("returnUrl") || "";
    if (pathname.includes("/login")) {
      returnUrl = "/home/dashboard";
    }
    return <Navigate to={returnUrl} replace />;
  }

  return <Outlet />;
};

export default RedirectRoute;
