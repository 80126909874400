import { Button, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { SortChangedEvent, ValueFormatterParams } from "ag-grid-community";
import moment from "moment";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { BasicTable, SearchField } from "src/components/commons";
import useGetUserDetail, { User } from "src/hooks/apis/users/useGetUserDetail";
import useGetUsers from "src/hooks/apis/users/useGetUsers";
import useOpenModal from "src/hooks/useOpenModal";
import { ROLE, STATUS, UserRole } from "src/types";
import { REG_EXP } from "src/utils/form-helper";
import AddMemberModal from "./AddMemberModal";
import { inviteModalStyle } from "./styles";

interface InviteMemberModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const InviteMemberModal = ({ isOpen, onClose }: InviteMemberModalProps) => {
  const [pageNo, setPageNo] = useState(1);
  const [roles, setRoles] = useState<UserRole[]>();
  const [pageSize, setPageSize] = useState(10);
  const [orders, setOrders] = useState<string[]>([]);
  const [search, setSearch] = useState({ temp: "", value: "" });

  const { data: userData } = useGetUserDetail({ userId: "me" });
  const { data } = useGetUsers({
    name: search.value,
    pageNo,
    pageSize,
    roles,
    orders,
    invite: true,
  });

  // 초기 검색 세팅: 사용자의 회사 이메일을 검색
  useEffect(() => {
    setSearch({ temp: "", value: `${userData.user.account_id.split("@")[1]}` });
    setRoles([ROLE.noCompany]);
  }, [userData.user.account_id, isOpen]);

  const rowData = useMemo(() => {
    if (data.users.length > 0) return data.users;
    // 이메일 검색결과 없을 시, 미가입 사용자로 노출
    if (search.value.match(REG_EXP.email))
      return [
        {
          company_key: "",
          account_id: search.value,
          authority_id: 0,
          company_id: 0,
          department: "",
          id: 0,
          name: "",
          phone_no: "",
          promotion_agreement: false,
          role: ROLE.UNREGISTERED,
          status: STATUS.ACTIVE,
          created_at: 0,
          updated_at: 0,
        },
      ];
    return [];
  }, [data.users, search.value]);

  const onChangePage = useCallback((value: number) => {
    setPageNo(value);
  }, []);

  const onChangePageSize = useCallback((value: number) => {
    setPageSize(value);
  }, []);

  const onChangeSearchWord = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearch({ ...search, temp: e.target.value });
    },
    [search]
  );

  const onSearchUser = useCallback(() => {
    if (search.temp) {
      setSearch((prev) => ({ ...prev, value: prev.temp }));
      setPageNo(1);
      setRoles([]);
    } else {
      setSearch((prev) => ({ ...prev, value: `${userData.user.account_id.split("@")[1]}` }));
      setPageNo(1);
      setRoles([ROLE.noCompany]);
    }
  }, [search, userData.user.account_id]);

  const paginationInfo = useMemo(
    () => ({
      pagination: { page: pageNo, count: data.pages, onChange: onChangePage },
      pageSize: {
        size: pageSize,
        onChange: onChangePageSize,
        options: [10, 20, 30],
      },
    }),
    [data.pages, onChangePage, onChangePageSize, pageNo, pageSize]
  );

  const onSortChanged = useCallback((e: SortChangedEvent) => {
    const sortInfo = e.columnApi
      .getColumnState()
      .filter((column) => !!column.sort)
      .map(({ colId, sort }) => {
        if (sort === "desc") return `-${colId}`;
        return `+${colId}`;
      });
    setOrders(sortInfo);
  }, []);

  return (
    <Dialog
      css={inviteModalStyle}
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-member"
      aria-describedby="invite member"
    >
      <DialogTitle id="dialog-title">회원 초대</DialogTitle>
      <DialogContent>
        <Grid className="ssp-tools" container>
          <Grid item xs={4}>
            <SearchField
              label="이메일"
              placeholder="초대하실 이메일을 입력해주세요."
              value={search.temp}
              onChange={onChangeSearchWord}
              onClickSearchButton={onSearchUser}
            />
          </Grid>
        </Grid>
        <BasicTable
          columnDefs={columnDefs}
          rowData={rowData}
          onSortChanged={onSortChanged}
          {...paginationInfo}
        />
      </DialogContent>
    </Dialog>
  );
};

const columnDefs = [
  {
    headerName: "가입일",
    field: "created_at",
    sortable: true,
    valueFormatter: (param: ValueFormatterParams) => {
      if (!param.data.created_at) return "미가입";
      return moment(param.data.created_at * 1000).format("YYYY-MM-DD");
    },
  },
  { headerName: "이메일", field: "account_id", sortable: true },
  { headerName: "사용자 이름", field: "name", sortable: true },
  { headerName: "부서", field: "department", sortable: true },
  {
    headerName: "초대",
    cellRenderer: (params: { data: User }) => {
      return <AddButton {...params.data} />;
    },
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
  },
];

function AddButton(props: User) {
  const [open, onShowAddMemberModal, onCloseAddMemberModal] = useOpenModal(null);

  return (
    <>
      <Button variant="text" size="small" color="primary" onClick={onShowAddMemberModal}>
        초대하기
      </Button>
      {open.isOpen && (
        <AddMemberModal isOpen={open.isOpen} onClose={onCloseAddMemberModal} user={props} />
      )}
    </>
  );
}

export default InviteMemberModal;
