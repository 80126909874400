import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { MEDIA, Media, STATUS, Status, WebPlatform, WEB_PLATFORM } from "src/types";
import API from "src/utils/api";

interface Params {
  mediaKey?: string;
}

interface Response {
  code: 200 | 401 | 404;
  data?: {
    media: {
      block_domain_list_set: number[];
      company_key: string;
      created_at: number;
      domain: string;
      key: string;
      name: string;
      placement_count: number;
      platform_type: WebPlatform;
      script_updated_at: number;
      status: Status;
      type: Media;
      updated_at: number;
    };
  };
  text: "ok" | "unauthorized" | "not-found";
}

// 웹 매체 조회
// 플레이스먼트는 갯수를 전달한다. (ID 리스트 전달 시 시간이 오래걸리거나 및 길이 초과로 데이터가 누락됨)
const useGetWebMedia = ({ mediaKey }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["media/useGetWebMedia", mediaKey],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/media/web/${mediaKey}`);
      return response.data.data;
    },
    { enabled: !!mediaKey, onError: handleError }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            media: {
              block_domain_list_set: [],
              company_key: "",
              created_at: 0,
              domain: "",
              key: "",
              name: "",
              placement_count: 0,
              platform_type: WEB_PLATFORM.MOBILE,
              script_updated_at: 0,
              status: STATUS.SUSPEND,
              type: MEDIA.WEB,
              updated_at: 0,
            },
          },
    [data]
  );

  return {
    data: result,
    ...rest,
  };
};

export default useGetWebMedia;
