import { numberFormatter } from "src/utils/form-helper";

type Formatter = (
  value: number,
  prefix?: string | boolean,
  suffix?: string | boolean,
  digit?: number
) => string;

const formatter: Formatter = (value, prefix, suffix, digit = 0) => {
  if (prefix && suffix) return `${prefix} ${numberFormatter(value, digit)} ${suffix}`;
  if (prefix) return `${prefix} ${numberFormatter(value, digit)}`;
  if (suffix) return `${numberFormatter(value, digit)} ${suffix}`;
  return numberFormatter(value, digit);
};

interface DataFormatType {
  [key: string]: {
    label: string;
    digit: number;
    unit: string;
    formatter: Formatter;
  };
}

const DataFormat: DataFormatType = {
  click: {
    label: "클릭수",
    digit: 0,
    unit: "회",
    formatter: (value = 0, prefix = false, suffix = "회", digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  ctr: {
    label: "CTR",
    digit: 2,
    unit: "%",
    formatter: (value = 0, prefix = false, suffix = "%", digit = 2) =>
      formatter(value, prefix, suffix, digit),
  },
  ecpm: {
    label: "eCPM",
    digit: 2,
    unit: "$",
    formatter: (value = 0, prefix = "$", suffix = false, digit = 2) =>
      formatter(value, prefix, suffix, digit),
  },
  advertiseCostEcpm: {
    label: "eCPM",
    digit: 2,
    unit: "$",
    formatter: (value = 0, prefix = "$", suffix = false, digit = 2) =>
      formatter(value, prefix, suffix, digit),
  },
  fillRate: {
    label: "F/R",
    digit: 2,
    unit: "%",
    formatter: (value = 0, prefix = false, suffix = "%", digit = 2) =>
      formatter(value, prefix, suffix, digit),
  },
  impression: {
    label: "노출수",
    digit: 0,
    unit: "회",
    formatter: (value = 0, prefix = false, suffix = "회", digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  request: {
    label: "요청수",
    digit: 0,
    unit: "회",
    formatter: (value = 0, prefix = false, suffix = "회", digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  response: {
    label: "응답수",
    digit: 0,
    unit: "회",
    formatter: (value = 0, prefix = false, suffix = "회", digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  // 수입
  advertiseCost: {
    label: "수입",
    digit: 2,
    unit: "$",
    formatter: (value = 0, prefix = "$", suffix = false, digit = 2) =>
      formatter(value, prefix, suffix, digit),
  },
  // 순매체비
  mediaCost: {
    label: "수입",
    digit: 2,
    unit: "$",
    formatter: (value = 0, prefix = "$", suffix = false, digit = 2) =>
      formatter(value, prefix, suffix, digit),
  },
  impressionRate: {
    label: "I/R",
    digit: 2,
    unit: "%",
    formatter: (value = 0, prefix = false, suffix = "%", digit = 2) =>
      formatter(value, prefix, suffix, digit),
  },
  rpr: {
    label: "RPR",
    digit: 2,
    unit: "$",
    formatter: (value = 0, prefix = "$", suffix = false, digit = 3) =>
      formatter(value, prefix, suffix, digit),
  },
  revenue: {
    label: "수입",
    digit: 2,
    unit: "$",
    formatter: (value = 0, prefix = "$", suffix = false, digit = 2) =>
      formatter(value, prefix, suffix, digit),
  },
  bidfloor: {
    label: "Bid Floor",
    digit: 2,
    unit: "$",
    formatter: (value = 0, prefix = "$", suffix = false, digit = 2) =>
      formatter(value, prefix, suffix, digit),
  },
  purchase: {
    label: "구매 수",
    digit: 0,
    unit: "회",
    formatter: (value = 0, prefix = false, suffix = "회", digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  purchaseRevenue: {
    label: "구매 금액",
    digit: 0,
    unit: "원",
    formatter: (value = 0, prefix = false, suffix = "원", digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  cancel: {
    label: "취소 수",
    digit: 0,
    unit: "회",
    formatter: (value = 0, prefix = false, suffix = "회", digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  cancelRevenue: {
    label: "취소 금액",
    digit: 0,
    unit: "원",
    formatter: (value = 0, prefix = false, suffix = "원", digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  totalRevenue: {
    label: "총 매출 금액",
    digit: 0,
    unit: "원",
    formatter: (value = 0, prefix = false, suffix = "원", digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  commission: {
    label: "수수료",
    digit: 0,
    unit: "원",
    formatter: (value = 0, prefix = false, suffix = "원", digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  commissionRate: {
    label: "수수료율",
    digit: 2,
    unit: "%",
    formatter: (value = 0, prefix = false, suffix = "%", digit = 2) =>
      formatter(value, prefix, suffix, digit),
  },
  taxBillRevenue: {
    label: "취소 수",
    digit: 0,
    unit: "원",
    formatter: (value = 0, prefix = false, suffix = "원", digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  exchangeRate: {
    label: "기준 환율",
    digit: 1,
    unit: "원",
    formatter: (value = 0, prefix = false, suffix = "원", digit = 1) =>
      formatter(value, prefix, suffix, digit),
  },
  conversion: {
    label: "전환수",
    digit: 0,
    unit: "회",
    formatter: (value = 0, prefix = false, suffix = "회", digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  cvr: {
    label: "전환율",
    digit: 2,
    unit: "%",
    formatter: (value = 0, prefix = false, suffix = "%", digit = 2) =>
      formatter(value, prefix, suffix, digit),
  },
  uniqueUser: {
    label: "Unique User",
    digit: 0,
    unit: "명",
    formatter: (value = 0, prefix = false, suffix = "명", digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  // 쿠팡 순매체비
  clientCommission: {
    label: "순매체비",
    digit: 0,
    unit: "원",
    formatter: (value = 0, prefix = false, suffix = "원", digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  adv_revenue: {
    label: "수입",
    digit: 2,
    unit: "$",
    formatter: (value = 0, prefix = "$", suffix = false, digit = 2) =>
      formatter(value, prefix, suffix, digit),
  },
  npay_points: {
    label: "포인트",
    digit: 0,
    unit: "P",
    formatter: (value = 0, prefix = false, suffix = false, digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  npay_canceled_points: {
    label: "포인트",
    digit: 0,
    unit: "P",
    formatter: (value = 0, prefix = false, suffix = false, digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  npay_clicks: {
    label: "클릭",
    digit: 0,
    unit: "클릭",
    formatter: (value = 0, prefix = false, suffix = false, digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  npay_complete_views: {
    label: "RV 완료 수",
    digit: 0,
    unit: "RV",
    formatter: (value = 0, prefix = false, suffix = false, digit = 0) =>
      formatter(value, prefix, suffix, digit),
  },
  npay_revenue: {
    label: "매출액",
    digit: 2,
    unit: "$",
    formatter: (value = 0, prefix = "$", suffix = false, digit = 2) =>
      formatter(value, prefix, suffix, digit),
  },
};

export default DataFormat;
