import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import { useFormik } from "formik";
import moment from "moment";
import { useMemo } from "react";
import DataFormat from "src/assets/formats";
import { TextField } from "src/components/commons";
import useGetPaymentHistory from "src/hooks/apis/payments/useGetPaymentHistory";
import usePutRevenue from "src/hooks/apis/payments/usePutRevenue";
import { SETTLEMENT_HISTORY_TYPE } from "src/types";
import { getHelperText, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { historyStyle, taxBillModalStyle } from "./styles";

interface TaxBillModalProps extends ICellRendererParams {
  onClose: () => void;
  open: { key: number; isOpen: boolean };
  field: "tax_bill_revenue" | "tax_bill_status";
  which?: "confirmed";
}

const TaxBillModal = ({ open, onClose, data, field, which }: TaxBillModalProps) => {
  const { data: historyData } = useGetPaymentHistory({
    paymentId: open.key,
    type: SETTLEMENT_HISTORY_TYPE.TAX_BILL_REVENUE,
  });
  const { mutate } = usePutRevenue();

  const isConfirmed = useMemo(
    () => field === "tax_bill_status" && which === "confirmed",
    [field, which]
  );

  const hasHistory = useMemo(() => historyData.length > 0, [historyData.length]);

  const initialValues = useMemo(
    () => ({
      revenue: "",
      reason: "",
    }),
    []
  );

  const validationSchema = yup.object({
    revenue: yup
      .string()
      .test("revenue", "숫자를 입력해주세요.", (value = "") => !isNaN(+value))
      .required("세금계산서 발행액을 입력해주세요."),
    reason: yup.string().required("변경 사유를 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (isConfirmed) return;
      mutate({
        paymentId: open.key,
        beforeRevenue: data.tax_bill_revenue,
        afterRevenue: +values.revenue,
        reason: values.reason,
      });
      onClose();
    },
  });

  return (
    <Dialog
      css={taxBillModalStyle}
      fullWidth
      maxWidth={hasHistory && !isConfirmed ? "lg" : "sm"}
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-taxbill"
      aria-describedby="edit taxbill"
    >
      <DialogTitle id="dialog-title">세금계산서 발행액 변경</DialogTitle>
      <DialogContent className="dialog-content">
        <Grid container spacing={2}>
          <Grid item xs={hasHistory && !isConfirmed ? 6 : 12}>
            <Grid container spacing={2} className="current-info">
              <Grid item xs={4}>
                <Typography variant="body1" fontWeight={500}>
                  수입 집계 기간
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{`${moment(data.start_date).format(
                  "YYYY.MM.DD"
                )} ~ ${moment(data.end_date).format("YYYY.MM.DD")}`}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" fontWeight={500}>
                  현재 세금계산서 발행액
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{`${DataFormat.taxBillRevenue.formatter(
                  data.tax_bill_revenue || 0
                )}`}</Typography>
              </Grid>
            </Grid>
            {!isConfirmed && (
              <form id="taxbill-form" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      className="field"
                      label="새 세금계산서 발행액"
                      placeholder="세금계산서 발행액을 입력해주세요."
                      required
                      {...getFieldProps("revenue")}
                      error={shouldErrorShows("revenue", touched, errors)}
                      helperText={getHelperText("revenue", touched, errors)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      minRows={5}
                      className="field"
                      label="변경 사유"
                      placeholder="변경 사유를 입력해주세요."
                      required
                      {...getFieldProps("reason")}
                      error={shouldErrorShows("reason", touched, errors)}
                      helperText={getHelperText("reason", touched, errors)}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </Grid>
          {hasHistory && (
            <Grid css={historyStyle} item xs={isConfirmed ? 12 : 6}>
              <Typography className="title" variant="body1" fontWeight={500}>
                변경 내역
              </Typography>
              <List>
                {historyData.map(
                  ({ created_at, before_revenue, after_revenue, reason, user_name }) => (
                    <ListItem key={created_at}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="body1" className="change">
                            {`변경 전 ${DataFormat.taxBillRevenue.formatter(
                              before_revenue || 0
                            )} → 변경 후  ${DataFormat.taxBillRevenue.formatter(
                              after_revenue || 0
                            )}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" className="reason">
                            {reason}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign="right">
                          <Typography variant="body1" className="date">
                            {`${moment(created_at * 1000).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )} (${user_name})`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                  )
                )}
              </List>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {!isConfirmed && (
        <DialogActions sx={{ marginBottom: "1rem" }}>
          <Button onClick={onClose} color="inherit">
            취소
          </Button>
          <Button form="taxbill-form" type="submit">
            저장
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default TaxBillModal;
