import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { Company, Settlement, STATUS, Status } from "src/types";
import API from "src/utils/api";
import { createFormData } from "src/utils/form-helper";

interface Params {
  companyId: number;
  companyType: Company;
  companyName: string;
  managerUid?: number;
  syndicationCompanyId?: number;
  registrationNo: string;
  address: string;
  settlementType?: Settlement;
  settlementCommissionRate?: number;
  useAdserver?: boolean;
  status?: Status;
  file: File;
}

interface Response {
  code: 200 | 400 | 401 | 403 | 404 | 409 | 500;
  text:
    | "ok"
    | "bad-request"
    | "unauthorized"
    | "forbidden"
    | "not-found"
    | "conflict"
    | "internal-server-error";
}

// 회사 정보 수정
const usePatchCompany = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({
      companyId,
      companyType,
      status = STATUS.ACTIVE,
      companyName,
      managerUid,
      syndicationCompanyId,
      registrationNo,
      address,
      settlementType,
      settlementCommissionRate,
      useAdserver,
      file,
    }) => {
      const response: AxiosResponse<Response> = await API.default.patch(
        `/companies/${companyId}`,
        createFormData({
          company_type: companyType,
          status: status,
          company_name: companyName,
          manager_uid: managerUid,
          syndication_company_id: syndicationCompanyId,
          registration_no: registrationNo,
          address: address,
          settlement_type: settlementType,
          settlement_commission_rate: settlementCommissionRate,
          use_adserver: useAdserver,
          file: file,
        }),
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("수정되었습니다.");
        invalidate(["companies/useGetCompanies", "companies/useGetCompany"]);
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePatchCompany;
