import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";

export enum NpayCsStatus {
  InProgress = 10,
  Completed = 50,
}

export enum NpayCsType {
  NO_NAVER_POINT = 1,
  LIMIT_EXCEEDED = 2,
  NO_ADS = 3,
  AD_NOT_ENDED = 4,
  OTHER_HELP = 5,
}

export type NpayCs = {
  company_key: string;
  company_name: string;
  created_at: number;
  email: string;
  id: number;
  image_url: string;
  inquiry_content: string;
  media_key: string;
  media_name: string;
  naver_id: string;
  response_content: string;
  sdk_version: string;
  status: NpayCsStatus;
  type: NpayCsType;
  updated_at: number;
  usn: string;
};

export type NpayCsFields = keyof NpayCs | `-${keyof NpayCs}`;

export type NpayCsListParams = {
  status?: NpayCsStatus | 0;
  since: string; // yyyyMMdd
  until: string; // yyyyMMdd
  type?: NpayCsType | 0;
  media_keys?: string[];
  query?: string;
  page_no?: number;
  page_size?: number;
  orders?: NpayCsFields[];
};

type NpayCsListData = {
  code: number;
  text: string;
  data: {
    page_no: number;
    page_size: number;
    pages: number;
    rows: NpayCs[];
  };
};

type NpayCsListQueryKey = ["operation/npay/cs-list", NpayCsListParams];

type NpayCsListQueryFn = QueryFunction<AxiosResponse<NpayCsListData>, NpayCsListQueryKey>;

type NpayCsListQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<NpayCsListData>,
    AxiosError,
    AxiosResponse<NpayCsListData>,
    NpayCsListQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: NpayCsListData = {
  code: 200,
  text: "OK",
  data: {
    page_no: 1,
    page_size: 1,
    pages: 1,
    rows: [],
  },
};

export default function useNpayCsList(params: NpayCsListParams, options?: NpayCsListQueryOptions) {
  const { handleError } = useApiError();

  const queryKey = useMemo<NpayCsListQueryKey>(() => ["operation/npay/cs-list", params], [params]);

  const queryFn = useCallback<NpayCsListQueryFn>((context) => {
    const [, params] = context.queryKey;
    const cookie = parseCookies();

    return API.consoleV3.get("/v3/customer-services/naverpay", {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
      params,
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
