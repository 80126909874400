import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { TextField, UploadField } from "src/components/commons";
import useGetCompany from "src/hooks/apis/companies/useGetCompany";
import useGetCompanyApproval from "src/hooks/apis/companies/useGetCompanyApproval";
import usePostCompany from "src/hooks/apis/companies/usePostCompany";
import useGetUserDetail from "src/hooks/apis/users/useGetUserDetail";
import useOpenModal from "src/hooks/useOpenModal";
import { Company, COMPANY, COMPANY_ALIAS } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import ApprovalWaitingModal from "./ApprovalWaitingModal";
import RefusedApprovalModal from "./RefusedApprovalModal";

interface ManagerParams {
  type: Company;
  name: string;
  brn: string;
  bri: File | string;
  rrn: string;
  rri: File | string;
  companyName: string;
  address: string;
}

const ManagerForm = () => {
  const { data: userData } = useGetUserDetail({ userId: "me" });
  const { mutate } = usePostCompany();
  const { data: approvalData } = useGetCompanyApproval({ companyId: userData.user.company_id });
  const { data: companyData } = useGetCompany({ companyId: userData.user.company_id });

  const [openRefusedModal, onShowRefusedModal, onCloseRefusedModal] = useOpenModal(null);

  useEffect(() => {
    if (!approvalData.is_popup_closed) onShowRefusedModal();
  }, [approvalData, onShowRefusedModal]);

  const isDisabled = useMemo(
    () => !!userData.user.company_id && !approvalData.reject_message,
    [approvalData.reject_message, userData.user.company_id]
  );

  const initialValues: ManagerParams = useMemo(
    () => ({
      type: COMPANY.INDIVIDUAL,
      name: userData.user.name || "",
      brn: companyData.company.biz_registration_no || "",
      bri: isDisabled ? companyData.company.biz_registration_img_url || "" : "",
      rrn: companyData.company.registration_no || "",
      rri: isDisabled ? companyData.company.registration_img_url || "" : "",
      companyName: companyData.company.name || "",
      address: companyData.company.address || "",
    }),
    [
      isDisabled,
      userData.user.name,
      companyData.company.address,
      companyData.company.biz_registration_img_url,
      companyData.company.biz_registration_no,
      companyData.company.name,
      companyData.company.registration_img_url,
      companyData.company.registration_no,
    ]
  );

  const validationSchema = yup.object({
    brn: yup.string().when("type", {
      is: COMPANY.CORPORATION,
      then: yup
        .string()
        .test("brn", "올바른 사업자등록번호를 입력해주세요.", (value) =>
          value?.match(REG_EXP.brn) === null ? false : true
        )
        .required("사업자등록번호를 입력해주세요."),
    }),
    bri: yup.mixed().when("type", {
      is: COMPANY.CORPORATION,
      then: yup.mixed().required("사업자 등록증 사본을 업로드해주세요."),
    }),
    rrn: yup.string().when("type", {
      is: COMPANY.INDIVIDUAL,
      then: yup
        .string()
        .test("rrn", "올바른 주민등록번호를 입력해주세요.", (value) =>
          value?.match(REG_EXP.rrn) === null ? false : true
        )
        .required("주민등록번호를 입력해주세요."),
    }),
    rri: yup.mixed().when("type", {
      is: COMPANY.INDIVIDUAL,
      then: yup.mixed().required("주민등록증 사본을 업로드해주세요."),
    }),
    companyName: yup.string().required("업체명을 입력해주세요."),
    address: yup.string().required("업체 주소를 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, setFieldValue, touched, errors, values } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate({
        managerUid: userData.user.id,
        companyType: values.type,
        companyName: values.companyName,
        registrationNo: (values.type === COMPANY.CORPORATION ? values.brn : values.rrn) || "",
        address: values.address,
        file: (values.type === COMPANY.CORPORATION ? values.bri : values.rri) as File,
      });
    },
  });

  const onChangeType = useCallback(
    (e: ChangeEvent<HTMLInputElement>, v: string) => {
      e.preventDefault();
      setFieldValue("type", +v);
    },
    [setFieldValue]
  );

  return (
    <Paper className="content" elevation={2}>
      <Typography className="title" variant="h6" gutterBottom>
        관리자 등록
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioGroup
              row
              className="field"
              aria-label="filter"
              {...getFieldProps("type")}
              onChange={onChangeType}
            >
              <FormControlLabel
                value={COMPANY.INDIVIDUAL}
                control={<Radio />}
                label={COMPANY_ALIAS[COMPANY.INDIVIDUAL]}
              />
              <FormControlLabel
                value={COMPANY.CORPORATION}
                control={<Radio />}
                label={COMPANY_ALIAS[COMPANY.CORPORATION]}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="field"
              label="담당자"
              required
              disabled
              {...getFieldProps("name")}
            />
          </Grid>
          {/* 개인 */}
          {values.type === COMPANY.INDIVIDUAL && (
            <>
              <Grid item xs={12}>
                <TextField
                  className="field"
                  label="주민등록번호"
                  placeholder="주민등록번호를 입력해주세요."
                  {...getFieldProps("rrn")}
                  error={shouldErrorShows("rrn", touched, errors)}
                  helperText={getHelperText("rrn", touched, errors)}
                  required
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <UploadField
                  fullWidth
                  className="field"
                  label="주민등록증"
                  required
                  disabled={isDisabled}
                  size="small"
                  accept=".jpg, .jpeg, .png, .pdf"
                  preview
                  {...getFieldProps("rri")}
                  onChange={(data: File) => setFieldValue("rri", data)}
                  error={shouldErrorShows("rri", touched, errors)}
                  helperText={getHelperText("rri", touched, errors)}
                />
              </Grid>
            </>
          )}
          {/* 기업 */}
          {values.type === COMPANY.CORPORATION && (
            <>
              <Grid item xs={12}>
                <TextField
                  className="field"
                  label="사업자등록번호"
                  placeholder="사업자등록번호를 입력해주세요."
                  {...getFieldProps("brn")}
                  error={shouldErrorShows("brn", touched, errors)}
                  helperText={getHelperText("brn", touched, errors)}
                  required
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <UploadField
                  fullWidth
                  className="field"
                  label="사업자등록증"
                  required
                  disabled={isDisabled}
                  size="small"
                  accept=".jpg, .jpeg, .png, .pdf"
                  preview
                  {...getFieldProps("bri")}
                  onChange={(data: File) => setFieldValue("bri", data)}
                  error={shouldErrorShows("bri", touched, errors)}
                  helperText={getHelperText("bri", touched, errors)}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <TextField
              className="field"
              label="사업장 명"
              placeholder="사업장 명을 입력해주세요."
              {...getFieldProps("companyName")}
              error={shouldErrorShows("companyName", touched, errors)}
              helperText={getHelperText("companyName", touched, errors)}
              required
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="field"
              label="사업장 주소"
              placeholder="사업장 주소를 입력해주세요."
              {...getFieldProps("address")}
              error={shouldErrorShows("address", touched, errors)}
              helperText={getHelperText("address", touched, errors)}
              required
              disabled={isDisabled}
            />
          </Grid>
        </Grid>
        <Box className="submit">
          <Button type="submit" variant="text" disabled={isDisabled}>
            제출
          </Button>
        </Box>
      </form>
      {/* 승인 대기 팝업 */}
      {isDisabled && <ApprovalWaitingModal />}
      {/* 승인 거부 팝업 */}
      {openRefusedModal.isOpen && (
        <RefusedApprovalModal isOpen={openRefusedModal.isOpen} onClose={onCloseRefusedModal} />
      )}
    </Paper>
  );
};

export default ManagerForm;
