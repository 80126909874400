import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo } from "react";
import { TextField } from "src/components/commons";
import useGetVerify from "src/hooks/apis/auth/useGetVerify";
import usePostSignUp from "src/hooks/apis/auth/usePostSignUp";
import usePostVerify from "src/hooks/apis/auth/usePostVerify";
import useToast from "src/hooks/useToast";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { signUpFormStyle } from "./styles";

const SignUpForm = () => {
  const toast = useToast();
  const { mutate: signUp } = usePostSignUp();
  const {
    mutate: verifyEmail,
    isSuccess: isValidEmail,
    error: verifyingEmailError,
  } = useGetVerify();
  const { mutate: verifyCode, isSuccess: isValidCode, error: verifyingCodeError } = usePostVerify();

  const initialValues = useMemo(
    () => ({
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      promotion: false,
      privacy: false,
      verifyCode: "",
    }),
    []
  );

  const validationSchema = yup.object({
    name: yup
      .string()
      .matches(REG_EXP.name, "한글 또는 영문만 입력해주세요.")
      .required("사용자 이름을 입력해주세요."),
    email: yup
      .string()
      .matches(REG_EXP.email, "이메일을 확인해 주세요.")
      .required("이메일을 입력해 주세요."),
    password: yup
      .string()
      .matches(REG_EXP.password, "대, 소문자, 숫자 포함 8글자만 입력 가능합니다.")
      .required("비밀번호를 입력해 주세요."),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "비밀번호가 일치하지 않습니다.")
      .required("비밀번호를 입력해 주세요."),
    promotion: yup.boolean(),
    privacy: yup.boolean().required("개인정보 수집 및 이용에 동의해주세요."),
    verifyCode: yup.string().matches(REG_EXP.verifyingCode, "올바른 인증코드를 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, errors, touched, values, setFieldError } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!isValidEmail || !isValidCode) {
        toast.error("이메일 인증을 해주세요.");
        return;
      }
      if (!values.privacy) {
        toast.error("개인정보 수집 및 이용에 동의해주세요.");
        return;
      }
      signUp({
        username: values.name,
        email: values.email,
        password: values.password,
        promotionAgreement: values.promotion,
      });
    },
  });

  const onClickVerifyEmail = useCallback(
    () => verifyEmail({ email: values.email, command: 0 }),
    [values.email, verifyEmail]
  );

  useEffect(() => {
    if (verifyingEmailError?.response?.status === 409) {
      setFieldError("email", "이미 가입된 계정입니다.");
    }
  }, [setFieldError, verifyingEmailError]);

  const onClickVerifyCode = useCallback(
    () => verifyCode({ email: values.email, code: values.verifyCode }),
    [values.email, values.verifyCode, verifyCode]
  );

  useEffect(() => {
    if (verifyingCodeError?.response?.status === 401) {
      setFieldError("verifyCode", "인증에 실패했습니다.");
    }
  }, [setFieldError, verifyingCodeError]);

  return (
    <section className="register-form-section" css={signUpFormStyle}>
      <div className="form-box">
        <h2 className="title">회원가입</h2>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                type="text"
                label="이름"
                variant="standard"
                placeholder="사용자 이름"
                {...getFieldProps("name")}
                error={shouldErrorShows("name", touched, errors)}
                helperText={getHelperText("name", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="email"
                label="계정 이메일"
                variant="standard"
                placeholder="회사 이메일 계정 사용을 권장합니다."
                suffix={
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={onClickVerifyEmail}
                    disabled={!values.email || !!errors.email}
                    sx={{ position: "absolute", right: 0, bottom: 5, width: 70 }}
                  >
                    인증요청
                  </Button>
                }
                {...getFieldProps("email")}
                error={shouldErrorShows("email", touched, errors)}
                helperText={
                  values.email && !errors.email && isValidEmail ? (
                    <Typography variant="caption" color="primary">
                      인증 메일 발송 (30분간 유효)
                    </Typography>
                  ) : (
                    getHelperText("email", touched, errors)
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                label="인증번호"
                variant="standard"
                placeholder="인증번호 입력"
                suffix={
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={onClickVerifyCode}
                    disabled={!values.verifyCode || !!errors.verifyCode}
                    sx={{ position: "absolute", right: 0, bottom: 5, width: 70 }}
                  >
                    확인
                  </Button>
                }
                {...getFieldProps("verifyCode")}
                error={shouldErrorShows("verifyCode", touched, errors)}
                helperText={
                  values.verifyCode && !errors.verifyCode && isValidCode ? (
                    <Typography variant="caption" color="primary">
                      인증되었습니다.
                    </Typography>
                  ) : (
                    getHelperText("verifyCode", touched, errors)
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                label="비밀번호"
                variant="standard"
                placeholder="대, 소문자, 숫자 포함 (8글자 이상)"
                {...getFieldProps("password")}
                error={shouldErrorShows("password", touched, errors)}
                helperText={getHelperText("password", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                label="비밀번호 확인"
                variant="standard"
                placeholder="비밀번호 확인"
                {...getFieldProps("confirmPassword")}
                error={shouldErrorShows("confirmPassword", touched, errors)}
                helperText={getHelperText("confirmPassword", touched, errors)}
              />
            </Grid>
          </Grid>
          <Grid container className="check-list">
            <Grid item xs={12}>
              <FormControlLabel
                label="개인정보 수집 및 이용 동의 (필수)"
                className="privacy"
                control={<Checkbox {...getFieldProps("privacy")} checked={values.privacy} />}
              />
            </Grid>
            <Grid item xs={12} className="description">
              <Typography variant="caption">
                ADPOPCORN SSP의{" "}
                <MuiLink
                  href="https://www.adpopcorn.com/policy/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  개인정보 수집
                </MuiLink>{" "}
                및{" "}
                <MuiLink
                  href="https://www.adpopcorn.com/policy/media"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  서비스 이용약관
                </MuiLink>
                에 동의합니다
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label="프로모션 알림 수신 동의 (선택)"
                className="promotion"
                control={<Checkbox {...getFieldProps("promotion")} checked={values.promotion} />}
              />
            </Grid>
            <Grid item xs={12} className="description">
              <Typography variant="caption">
                ADPOPCORN SSP의 프로모션 알림 수신을 동의합니다.
              </Typography>
            </Grid>
          </Grid>
          <Button className="submit" type="submit" variant="outlined">
            Sign Up
          </Button>
        </form>
      </div>
    </section>
  );
};

export default SignUpForm;
