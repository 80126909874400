import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

interface Account {
  account_id: string;
  account_no: number;
}

interface Response {
  code?: 200 | 500;
  account_list?: Account[];
  text?: "ok" | "internal-server-error";
}

// 쿠팡 계정 조회
const useGetCoupangAccounts = () => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response, AxiosError>(
    "coupang/useGetCoupangAccounts",
    async () => {
      const response: AxiosResponse<Response> = await API.integration.get(
        `/operation/management/coupang/subid/GetCoupangAccounts`
      );
      return response.data;
    },
    {
      onError: handleError,
    }
  );

  const result = useMemo(() => {
    if (data && data.account_list && data.account_list.length > 0)
      return { accountList: [{ account_id: "전체 선택", account_no: 100 }, ...data.account_list] };
    return { accountList: [{ account_id: "전체 선택", account_no: 100 }] };
  }, [data]);

  return { data: result, ...rest };
};

export default useGetCoupangAccounts;
