import { css } from "@emotion/react";

export const campaignModalStyle = css`
  .dialog-content {
    #add-form,
    #edit-form {
      padding-top: 20px;
    }
  }
`;

export const partnerOptionStyle = css`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
