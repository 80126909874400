import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { Campaign, Status } from "src/types";
import API from "src/utils/api";

interface Params {
  thirdparties: {
    id: number;
    name: string;
    status: Status;
  }[];
  type: Campaign;
}

interface Response {
  code: 200 | 400 | 401 | 500;
  data?: {
    thirdparties: {
      formats: {
        id: number;
        label: string;
        status: Status;
      }[];
      id: string;
      name: string;
    }[];
  };
  text: "ok" | "bad-request" | "unauthorized" | "internal-server-error";
}

// DSP 활성상태 저장
// 웹은 사이즈가 너무 많아서 제외
const usePutDSPStatus = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const { data, ...rest } = useMutation<Response, AxiosError, Params>(
    async (payload) => {
      const response: AxiosResponse<Response> = await API.default.put(
        `/thirdparties/dsps/status`,
        payload
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("수정되었습니다.");
        invalidate("thirdparties/useGetDSPStatus");
      },
      onError: handleError,
    }
  );

  const result = useMemo(() => (data ? data : { thirdparties: [] }), [data]);

  return { data: result, ...rest };
};

export default usePutDSPStatus;
