import { useEffect } from "react";

const ServiceTermPage = () => {
  useEffect(() => {
    window.location.href = "https://adpopcorn.com/policy/media";
  }, []);

  return null;
};

export default ServiceTermPage;
