import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

export interface Partner {
  postback_no: number;
  partner_name: string;
  subids: string;
}

interface Response {
  code?: 200 | 401;
  data?: Partner[];
  pages?: number;
  text?: "ok" | "unauthorized";
}

/*
  캠페인 등록용 파트너 및 등록 SubId 검색
*/
const useGetCampaignPartnerList = () => {
  const { handleError } = useApiError();
  const { data: response, ...rest } = useQuery<Response, AxiosError>(
    ["coupang/useGetCampaignPartnerList"],
    async () => {
      const response: AxiosResponse<Response> = await API.integration.get(
        `/operation/management/coupang/campaign/partners`
      );
      return response.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => {
    if (response && response.data)
      return {
        partners: response.data,
      };
    return { partners: [] };
  }, [response]);

  return { data: result, ...rest };
};

export default useGetCampaignPartnerList;
