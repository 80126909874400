import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

interface Params {
  companyId: number;
}

interface Response {
  code: 200 | 401 | 404;
  text: "ok" | "unauthorized" | "not-found";
}

// 관리자 등록화면에서 거절메세지 팝업 상태 저장
const usePatchRefusedPopup = () => {
  const { handleError } = useApiError();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ companyId }) => {
      const response: AxiosResponse<Response> = await API.default.patch(
        `/companies/${companyId}/close-popup`
      );
      return response.data;
    },
    { onError: handleError }
  );

  return result;
};

export default usePatchRefusedPopup;
