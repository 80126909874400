import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { AppPlatform, APP_PLATFORM, Media, Status } from "src/types";
import API from "src/utils/api";
import useGetUserDetail from "../users/useGetUserDetail";

interface Params {
  companyKey?: string;
  name?: string;
  platform?: AppPlatform;
  status?: Status;
  pageNo?: number;
  pageSize?: number;
  orders?: string[];
  disabled?: boolean;
}

export interface AppMedia {
  block_domain_list_set: number[];
  collect_installed_apps: boolean;
  company_key: string;
  created_at: number;
  hash_key: string;
  key: string;
  name: string;
  package_name: string;
  placement_count: number;
  platform_type: AppPlatform;
  status: Status;
  test_device_count: number;
  type: Media;
  updated_at: number;
  url_scheme: string;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    media: AppMedia[];
    pages: number;
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 앱 매체 리스트 조회
// 플레이스먼트, 테스트 디바이스는 갯수를 전달한다. (ID 리스트 전달 시 시간이 오래걸리거나 및 길이 초과로 데이터가 누락됨)
const useGetAppMediaList = ({
  companyKey,
  name,
  platform,
  status,
  pageNo = 1,
  pageSize = 100000,
  orders,
  disabled = false,
}: Params) => {
  const { handleError } = useApiError();
  const { data: userData } = useGetUserDetail({ userId: "me" });
  companyKey = companyKey || userData.user.company_key;
  platform = platform === APP_PLATFORM.ALL ? undefined : platform;

  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    [
      "media/useGetAppMediaList",
      companyKey,
      name,
      pageNo,
      pageSize,
      platform,
      status,
      disabled,
      orders,
    ],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/media/app`, {
        params: {
          company_key: companyKey,
          name: name,
          types: platform,
          status: status,
          page_no: pageNo,
          page_size: pageSize,
          orders: orders?.join(","),
        },
      });
      return response.data.data;
    },
    {
      enabled: !disabled && !!companyKey,
      onError: handleError,
    }
  );

  const result = useMemo(() => (data ? data : { media: [], pages: 0 }), [data]);

  return { data: result, ...rest };
};

export default useGetAppMediaList;
