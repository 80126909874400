import { Container, Paper, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import { useMemo, useState } from "react";
import { COMPANY, Company, TaxBillStatus, TAX_BILL_STATUS } from "src/types";
import SettlementBoard from "./SettlementBoard";
import SettlementFilter from "./SettlementFilter";
import { settlementBoardStyle } from "./styles";

const SettlementManagement = () => {
  const [search, setSearch] = useState({ temp: "", value: "" });
  const [date, setDate] = useState(moment().subtract(1, "M"));
  const [taxBillStatus, setTaxBillStatus] = useState<TaxBillStatus[]>([
    TAX_BILL_STATUS.DEFAULT,
    TAX_BILL_STATUS.CONFIRMED,
    TAX_BILL_STATUS.CARRIED,
  ]);
  const [companyTypes, setComapanyTypes] = useState<Company[]>([
    COMPANY.INDIVIDUAL,
    COMPANY.CORPORATION,
    COMPANY.SYNDICATION,
    COMPANY.SYNDICATION_CLIENT_CORPORATION,
    COMPANY.SYNDICATION_CLIENT_INDIVIDUAL,
  ]);

  const filterProps = useMemo(
    () => ({
      search: {
        get: () => search,
        set: ({ temp, value }: { temp: string; value: string }) => setSearch({ temp, value }),
      },
      date: {
        get: () => date,
        set: (v: Moment) => setDate(v),
      },
      taxBillStatus: {
        get: () => taxBillStatus,
        set: (v: TaxBillStatus[]) => setTaxBillStatus(v),
      },
      companyTypes: {
        get: () => companyTypes,
        set: (v: Company[]) => setComapanyTypes(v),
      },
    }),
    [companyTypes, date, search, taxBillStatus]
  );

  return (
    <Container css={settlementBoardStyle} component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        정산 관리
      </Typography>
      <Paper className="content" elevation={2}>
        <SettlementFilter {...filterProps} />
        <SettlementBoard
          search={search.value}
          date={date}
          taxBillStatus={taxBillStatus}
          companyTypes={companyTypes}
        />
      </Paper>
    </Container>
  );
};

export default SettlementManagement;
