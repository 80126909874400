import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { AppPlatform } from "src/types";
import API from "src/utils/api";

interface Params {
  search?: string;
  pageNo: number;
  pageSize: number;
  orders?: string[];
}

export interface PartenrApp {
  company_key: string;
  created_at: string;
  os_type: AppPlatform;
  partner_app_id: number;
  partner_app_key: string;
  partner_app_name: string;
  partner_id: number;
  status: number;
  updated_at: string;
}

export interface Record {
  company_key: string;
  company_name: string;
  created_at: string;
  partner_apps: PartenrApp[];
  partner_id: number;
  updated_at: string;
}

interface Response {
  max_page_size: number;
  records: Record[];
}

/* 오퍼월 파트너 (Fyber, Tapjoy)별 연동 정보 리스트 조회 */
const useGetOfferwallList = ({ search, pageNo, pageSize, orders }: Params) => {
  const { handleError } = useApiError();
  const { data: response, ...rest } = useQuery<Response, AxiosError>(
    ["partners/useGetOfferwallList", search, pageNo, pageSize, orders],
    async () => {
      const response: AxiosResponse<Response> = await API.console.get(
        `/partners/interop/offerwall`,
        {
          params: { q: search, page_no: pageNo, page_size: pageSize, order: orders?.join(",") },
        }
      );
      return response.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => {
    if (response && response.records)
      return {
        records: response.records || [],
        pages: response.max_page_size || 0,
      };
    return { records: [], pages: 0 };
  }, [response]);

  return { data: result, ...rest };
};

export default useGetOfferwallList;
