import { Box, Button, Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import { ValueFormatterParams } from "ag-grid-community";
import { MouseEvent, useCallback, useState } from "react";
import { BasicTable, DeleteButton, Select } from "src/components/commons";
import useDeleteAppScheme from "src/hooks/apis/media/useDeleteAppScheme";
import useGetAppScheme, { Scheme } from "src/hooks/apis/media/useGetAppScheme";
import useOpenModal from "src/hooks/useOpenModal";
import { AppPlatform, APP_PLATFORM, APP_PLATFORM_ALIAS } from "src/types";
import AddDeepLinkModal from "./AddDeepLinkModal";
import { partnerBoardStyle } from "./styles";

const DeepLinkBoard = () => {
  const [platform, setPlatform] = useState<AppPlatform>(APP_PLATFORM.ALL);
  const [openAddModal, onShowAddModal, onCloseAddModal] = useOpenModal(null);

  const { data } = useGetAppScheme({
    platformType: platform !== APP_PLATFORM.ALL ? platform : undefined,
  });

  const onChangePlatform = useCallback((event: SelectChangeEvent<AppPlatform>) => {
    const value = +event.target.value as AppPlatform;
    setPlatform(value);
  }, []);

  return (
    <Box css={partnerBoardStyle}>
      <Grid container className="ssp-tools" spacing={2}>
        <Grid item xs={3}>
          <Select
            label="플랫폼"
            placeholder="플랫폼을 선택해주세요."
            onChange={onChangePlatform}
            value={platform}
          >
            <MenuItem value={APP_PLATFORM.ALL}>{APP_PLATFORM_ALIAS[APP_PLATFORM.ALL]}</MenuItem>
            <MenuItem value={APP_PLATFORM.iOS}>{APP_PLATFORM_ALIAS[APP_PLATFORM.iOS]}</MenuItem>
            <MenuItem value={APP_PLATFORM.ANDROID}>
              {APP_PLATFORM_ALIAS[APP_PLATFORM.ANDROID]}
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={9} className="register">
          <Button variant="outlined" onClick={onShowAddModal}>
            DeepLink 추가
          </Button>
        </Grid>
      </Grid>
      <BasicTable rowData={data.schemes} columnDefs={columnDefs} />
      {openAddModal.isOpen && (
        <AddDeepLinkModal isOpen={openAddModal.isOpen} onClose={onCloseAddModal} />
      )}
    </Box>
  );
};

const columnDefs = [
  {
    headerName: "Deep Link URL",
    field: "scheme",
    sortable: true,
  },
  {
    headerName: "플랫폼",
    field: "platform_type",
    sortable: true,
    valueFormatter: (params: ValueFormatterParams) => {
      const platform = params.data.platform_type as AppPlatform;
      return APP_PLATFORM_ALIAS[platform];
    },
  },
  {
    headerName: "Delete",
    field: "no",
    sortable: true,
    cellRenderer: (params: { data: Scheme }) => <RemoveButton {...params.data} />,
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

function RemoveButton({ no }: Scheme) {
  const { mutate } = useDeleteAppScheme();

  const onClickDeleteButton = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      mutate({ schemeNo: no });
    },
    [mutate, no]
  );

  return (
    <DeleteButton onConfirm={onClickDeleteButton}>
      정말 해당 딥링크를 삭제하시겠습니까?
    </DeleteButton>
  );
}

export default DeepLinkBoard;
