import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { useSetRecoilState } from "recoil";
import { uid2Agreement } from "src/atoms/uid2Agreement";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

interface Params {
  uid2Agreement: boolean;
}

interface Response {
  code: number;
  text: "ok" | "unauthorized" | "not-found";
}

// UID 2.0 상태 업데이트
const usePatchUid2Agreement = (companyId: number) => {
  const { handleError } = useApiError();
  const setUid2Agreement = useSetRecoilState(uid2Agreement);

  const mutation = useMutation<Response, AxiosError, Params>(
    async (params) => {
      const response: AxiosResponse<Response> = await API.default.patch(
        `/companies/${companyId}/uid2`,
        params
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: (response, params) => {
        if (response.code === 200) {
          setUid2Agreement((prev) => ({
            ...prev,
            value: params.uid2Agreement,
          }));
        }
      },
    }
  );

  return mutation;
};

export default usePatchUid2Agreement;
