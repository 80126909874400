export const AG_GRID_LOCALE_KR = {
  selectAll: "(모두 선택)",
  selectAllSearchResults: "(모든 검색 결과 선택)",
  searchOoo: "검색...",
  blanks: "(공백)",
  noMatches: "일치하지 않음",
  filterOoo: "Filter...",
  equals: "Equals",
  notEqual: "같지 않음",
  blank: "공백",
  notBlank: "공백이 아님",
  empty: "하나 선택",
  lessThan: "보다 작음",
  greaterThan: "보다 큼",
  lessThanOrEqual: "작거나 같음",
  greaterThanOrEqual: "크거나 같음",
  inRange: "범위 내",
  inRangeStart: "from",
  inRangeEnd: "to",
  contains: "포함",
  notContains: "포함하지 않음",
  startsWith: "다음으로 시작",
  endsWith: "다음으로 종료",
  dateFormatOoo: "yyyy-mm-dd",
  andCondition: "AND",
  orCondition: "또는",
  applyFilter: "적용",
  resetFilter: "재설정",
  clearFilter: "지우기",
  cancelFilter: "취소",
  textFilter: "텍스트 필터",
  numberFilter: "숫자 필터",
  dateFilter: "날짜 필터",
  setFilter: "필터 설정",
  columns: "Columns",
  filters: "Filters",
  pivotMode: "피벗 모드 ",
  groups: "행 그룹",
  rowGroupColumnsEmptyMessage: "여기로 드래그하여 행 그룹 설정",
  values: "Values",
  valueColumnsEmptyMessage: "여기로 드래그하여 집계",
  pivots: "Column Labels",
  pivotColumnsEmptyMessage: "여기로 드래그하여 열 레이블 설정",
  group: "Group",
  rowDragRows: "rows",
  loadingOoo: "Loading...",
  noRowsToShow: "표시할 행 없음",
  enabled: "Enabled",
  pinColumn: "열 고정",
  pinLeft: "왼쪽 고정",
  pinRight: "오른쪽 고정",
  noPin: "핀 없음",
  valueAggregation: "값 집계",
  autosizeThiscolumn: "이 열 크기 자동 조정",
  autosizeAllColumns: "모든 열 자동 크기 조정",
  groupBy: "그룹화 기준",
  ungroupBy: "그룹 해제 기준",
  addToValues: "값에 ${변수} 추가",
  removeFromValues: "값에서 ${변수} 제거",
  addToLabels: "레이블에 ${변수} 추가",
  removeFromLabels: "레이블에서 ${변수} 제거",
  resetColumns: "열 재설정",
  expandAll: "모두 확장",
  collapseAll: "모두 닫기 ",
  copy: "복사",
  ctrlC: "Ctrl+C",
  copyWithHeaders: "머리글과 함께 복사",
  copyWithGroupHeaders: "그룹 머리글과 함께 복사",
  paste: "붙여넣기",
  ctrlV: "Ctrl+V",
  export: "내보내기",
  csvExport: "CSV 내보내기",
  excelExport: "Excel 내보내기",
  sum: "Sum",
  min: "Min",
  max: "Max",
  none: "None",
  count: "Count",
  avg: "Average",
  filteredRows: "Filtered",
  selectedRows: "Selected",
  totalRows: "Total Rows",
  totalAndFilteredRows: "Rows",
  more: "More",
  to: "to",
  of: "of",
  page: "Page",
  nextPage: "다음 페이지",
  lastPage: "마지막 페이지",
  firstPage: "첫 페이지",
  previousPage: "이전 페이지",
  pivotColumnGroupTotals: "Total",
  pivotChartAndPivotMode: "피벗 차트 및피벗 모드",
  pivotChart: "피벗 차트",
  chartRange: "차트 범위",
  columnChart: "Column",
  groupedColumn: "Grouped",
  stackedColumn: "Stacked",
  normalizedColumn: "100% 누적",
  barChart: "막대",
  groupedBar: "그룹화됨",
  stackedBar: "Stacked",
  normalizedBar: "100% 누적",
  pieChart: "파이",
  pie: "파이",
  doughnut: "도넛",
  line: "선",
  xyChart: "XY(분산)",
  scatter: "분포",
  bubble: "거품",
  areaChart: "영역",
  area: "영역",
  stackedArea: "누적",
  normalizedArea: "100% 누적",
  histogramChart: "히스토그램",
  combinationChart: "조합",
  columnLineCombo: "열 및 선",
  AreaColumnCombo: "영역 및 열",
  pivotChartTitle: "피벗 차트",
  rangeChartTitle: "범위 차트",
  settings: "설정",
  data: "데이터",
  format: "형식",
  categories: "범주",
  defaultCategory: "(없음)",
  series: "시리즈",
  xyValues: "XY 값",
  paired: "페어링된 모드",
  axis: "축",
  navigator: "네비게이터",
  color: "색상",
  thickness: "두께",
  xType: "X 유형",
  automatic: "자동",
  category: "범주",
  number: "숫자",
  time: "시간",
  autoRotate: "자동 회전",
  xRotation: "X 회전",
  yRotation: "Y 회전",
  ticks: "틱",
  width: "너비",
  height: "높이",
  length: " Length",
  padding: "Padding",
  spacing: "Spacing",
  chart: "Chart",
  title: "Title",
  titlePlaceholder: "차트 제목 - 편집하려면 두 번 클릭",
  background: "배경",
  font: "글꼴",
  top: "상단",
  right: "Right",
  bottom: "Bottom",
  left: "Left",
  labels: "Labels",
  size: "Size",
  minSize: "Minimum Size",
  maxSize: "최대 크기",
  legend: "범례",
  position: "위치",
  markerSize: "마커 크기",
  markerStroke: "마커 획",
  markerPadding: "마커 패딩",
  itemSpacing: "Item Spacing",
  itemPaddingX: "Item Padding X",
  itemPaddingY: "Item Padding Y",
  layoutHorizontalSpacing: "Horizontal Spacing",
  layoutVerticalSpacing: "Vertical Spacing",
  strokeWidth: "획 너비 ",
  offset: "오프셋",
  offsets: "오프셋",
  tooltips: "도구 설명",
  callout: "설명선",
  markers: "마커",
  shadow: "그림자",
  blur: "흐림 효과",
  xOffset: "X 오프셋",
  yOffset: "Y 오프셋",
  lineWidth: "선 너비",
  normal: "Normal",
  bold: "Bold",
  italic: "Italic",
  boldItalic: "굵게 기울임꼴",
  predefined: "사전 정의됨",
  fillOpacity: "채우기 불투명도",
  strokeOpacity: "선 불투명도",
  histogramBinCount: "빈 개수",
  columnGroup: "열",
  barGroup: "바",
  pieGroup: "파이",
  lineGroup: "Line",
  scatterGroup: "XY(Scatter)",
  areaGroup: "Area",
  histogramGroup: "Histogram",
  combinationGroup: "Combination",
  groupedColumnTooltip: "Grouped",
  stackedColumnTooltip: "Stacked",
  normalizedColumnTooltip: "100% Stacked",
  groupedBarTooltip: "Grouped",
  stackedBarTooltip: "Stacked",
  normalizedBarTooltip: "100% Stacked",
  pieTooltip: "파이",
  doughnutTooltip: "도넛",
  lineTooltip: "선",
  groupedAreaTooltip: "Area",
  stackedAreaTooltip: "Stacked",
  normalizedAreaTooltip: "100% Stacked",
  scatterTooltip: "Scatter",
  bubbleTooltip: "버블",
  histogramTooltip: "히스토그램",
  columnLineComboTooltip: "열 및 선",
  areaColumnComboTooltip: "영역 및 열",
  customComboTooltip: "사용자 지정 조합",
  noDataToChart: "차트에 사용할 수 있는 데이터가 없습니다.",
  pivotChartRequiresPivotMode: "피벗 차트에는 피벗 모드가 활성화되어 있어야 합니다.",
  chartSettingsToolbarTooltip: "메뉴",
  chartLinkToolbarTooltip: "그리드에 연결됨",
  chartUnlinkToolbarTooltip: "연결 해제됨 그리드에서",
  chartDownloadToolbarTooltip: "차트 다운로드",
  seriesChartType: "시리즈 차트 유형",
  seriesType: "시리즈 유형",
  secondaryAxis: "보조 축",
  ariaChecked: "checked",
  ariaColumn: "Column",
  ariaColumnGroup: "Column Group",
  ariaColumnList: "Column List",
  ariaColumnSelectAll: "Toggle Select All Columns",
  ariaDateFilterInput: "날짜 필터 입력",
  ariaDefaultListName: "목록",
  ariaFilterColumnsInput: "필터 열 입력",
  ariaFilterFromValue: "값에서 필터링",
  ariaFilterInput: "필터 입력",
  ariaFilterList: "필터 목록",
  ariaFilterToValue: "값으로 필터링",
  ariaFilterValue: "필터 값",
  ariaFilteringOperator: "필터링 연산자",
  ariaHidden: "숨김",
  ariaIndeterminate: "indeterminate",
  ariaInputEditor: "입력 편집기",
  ariaMenuColumn: "열 메뉴를 열려면 CTRL ENTER를 누르십시오.",
  ariaRowDeselect: "이 행을 선택 취소하려면 SPACE를 누르십시오",
  ariaRowSelectAll: " 누르십시오 모든 행 선택을 토글하려면 스페이스바",
  ariaRowToggleSelection: "행 선택을 토글하려면 스페이스바를 누르십시오.",
  ariaRowSelect: "이 행을 선택하려면 스페이스바를 누르십시오.",
  ariaSearch: "검색",
  ariaSortableColumn: "Enter 키를 눌러 sort",
  ariaToggleVisibility: "가시성을 토글하려면 스페이스바를 누르십시오.",
  ariaUnchecked: "unchecked",
  ariaVisible: "visible",
  ariaSearchFilterValues: "검색 필터 값",
  ariaRowGroupDropZonePanelLabel: "행 그룹",
  ariaValuesDropZonePanelLabel: "값",
  ariaPivotDropZonePanelLabel: "열 레이블",
  ariaDropZoneColumnComponentDescription: "삭제를 눌러 유형을 제거",
  ariaDropZoneColumnValueItemDescription:
    "ValuePItemDropZoneColumn을 변경하려면 DELETE 키를 누르십시오.",
  ariaLabelColumnMenu: "열 메뉴",
  ariaLabelCellEditor: "셀 편집기",
  ariaLabelDialog: "대화 상자",
  ariaLabelSelectField: "필드 선택",
  ariaLabelTooltip: "도구 설명",
  ariaLabelContextMenu: "상황에 맞는 메뉴",
  ariaLabelSubMenu: "SubMenu",
  ariaLabelAggregationFunction: "집계 함수",
  thousandSeparator: ",",
  decimalSeparator: ".",
};
