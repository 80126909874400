import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import { MouseEvent, SyntheticEvent, useCallback, useMemo, useState } from "react";
import * as yup from "yup";

import useUser from "src/hooks/useUser";
import useGetAdFormatInfo from "src/hooks/apis/info/useGetAdFormatInfo";
import useGetAppPlacementDetail from "src/hooks/apis/placements/useGetAppPlacementDetail";
import usePutAppPlacement from "src/hooks/apis/placements/usePutAppPlacement";
import useOpenModal from "src/hooks/useOpenModal";
import useToast from "src/hooks/useToast";
import useNativeTemplateList, {
  NativeTemplateType,
} from "src/hooks/apis/placements/useNativeTemplateList";

import { Select, TextField } from "src/components/commons";
import { Campaign, CAMPAIGN, CAMPAIGN_ALIAS, Template, TEMPLATE, TEMPLATE_ALIAS } from "src/types";

import AddCompanionModal from "./AddCompanionModal";
import AddSubNativeModal from "./AddSubNativeModal";
import EditCompanionModal from "./EditCompanionModal";
import { CampaignInfo } from "./EditPlacementModal";
import EditSubNativeModal from "./EditSubNativeModal";
import { addCampaignModalStyle } from "./styles";
import {
  getPreviewImgSize,
  isNativeCampaign,
  isComponentRequired,
  isMinViewTimeRequired,
  isMuteRequired,
  isSizeRequired,
  previewImgByCampaign,
} from "./const";

interface EditCampaignModalProps {
  onClose: () => void;
  open: { key: string; isOpen: boolean };
  onSubmit: (values: CampaignInfo) => void;
}

const validationSchema = yup.object({
  minViewTime: yup.number().when("type", {
    is: isMinViewTimeRequired,
    then: yup.number().required("최소 광고 유지 시간을 선택해주세요."),
  }),
  iconImageRequired: yup.boolean(),
  titleRequired: yup.boolean(),
  descRequired: yup.boolean(),
  mainImageRequired: yup.boolean(),
  ctatextRequired: yup.boolean(),
  mute: yup.boolean(),
});

const defaultPreviewImgSize = { width: 180, height: 385 };

const EditCampaignModal = ({ onClose, open, onSubmit }: EditCampaignModalProps) => {
  const toast = useToast();
  const { mutate } = usePutAppPlacement();
  const { isAdmin } = useUser();
  const [openAddCompanionModal, onShowAddCompanionModal, onCloseAddCompanionModal] = useOpenModal(
    open.key
  );
  const [openEditCompanionModal, onShowEditCompanion, onCloseEditCompanionModal] = useOpenModal(
    open.key
  );
  const [openAddSubNativeModal, onShowAddSubNativeModal, onCloseAddSubNativeModal] = useOpenModal(
    open.key
  );
  const [openEditSubNativeModal, onShowEditSubNative, onCloseEditSubNativeModal] = useOpenModal(
    open.key
  );

  const { data: adFormatData } = useGetAdFormatInfo({
    types: [
      CAMPAIGN.Banner,
      CAMPAIGN.Interstitial,
      CAMPAIGN.Native,
      CAMPAIGN.RewardVideo,
      CAMPAIGN.InterstitialVideo,
    ],
  });

  /** 네이티브 템플릿 옵션 목록 */
  const [templateOptions, setTemplateOptions] = useState([
    {
      templateNo: TEMPLATE.NATIVE_NO_TEMPLATE as Template,
      name: TEMPLATE_ALIAS[TEMPLATE.NATIVE_NO_TEMPLATE],
    },
  ]);

  /** 미리보기 이미지 속성 */
  const [previewImgProps, setPreviewImgProps] = useState({
    ...defaultPreviewImgSize,
    src: previewImgByCampaign[CAMPAIGN.Banner](),
  });

  const {
    data: placementData,
    isLoading: isAppPlacementDetailLoading,
    isSuccess: isAppPlacementDetailSuccess,
  } = useGetAppPlacementDetail(
    { placementId: open.key },
    {
      onSuccess: (response) => {
        const placement = response.data.data?.placement;

        if (!placement) {
          throw new Error("플레이스먼트 정보를 조회할 수 없습니다.");
        }

        setPreviewImgProps((prev) => {
          const type = placement.type;
          const sizeType = placement.size_type;

          if (type === CAMPAIGN.Banner) {
            return { ...defaultPreviewImgSize, src: previewImgByCampaign[type](sizeType) };
          }

          if (([CAMPAIGN.Splash, CAMPAIGN.BottomModal] as Campaign[]).includes(type)) {
            // TODO: 스플래시 타입 처리 필요
            return { src: previewImgByCampaign[type], width: 320, height: 385 };
          }

          if (type !== CAMPAIGN.Native) {
            // TODO: 스플래시 타입 처리 필요
            return { ...defaultPreviewImgSize, src: previewImgByCampaign[type] };
          }

          return prev;
        });
      },
    }
  );

  const initialValues = useMemo(() => {
    const { placement } = placementData;
    return {
      type: placement.type,
      campaign: CAMPAIGN_ALIAS[placement.type],
      size: adFormatData.formats.find((format) => placement.size_type === format.id)?.label || "",
      minViewTime: placement.minviewtime,
      iconImageRequired: placement.icon_image_required,
      titleRequired: placement.title_required,
      descRequired: placement.desc_required,
      mainImageRequired: placement.main_image_required,
      ctatextRequired: placement.ctatext_required,
      mute: placement.mute,
      // 컴패니언 네이티브 사용중일 경우,
      companionId: placement.companion_p_id,
      companionUse: placement.companion_use,
      companionName: placement.companion_p_name,
      // 배너/전면 네이티브 사용중일 경우,
      subNativeId: placement.sub_native_p_id,
      subNativeUse: placement.sub_native_use,
      subNativeName: placement.sub_native_p_name,
      // "광고 형식: 네이티브"에서만 사용
      templateNo: placement.template_no || TEMPLATE.NATIVE_NO_TEMPLATE,
    };
  }, [adFormatData.formats, placementData]);

  const isCompanionRequired = useCallback((type: Campaign) => type === CAMPAIGN.Interstitial, []);

  const isSubNativeRequired = useCallback(
    (type: Campaign) => {
      if (type === CAMPAIGN.Banner && placementData.placement.size_type === 1)
        // 배너 네이티브
        return true;
      if (type === CAMPAIGN.Banner && placementData.placement.size_type === 2) return true;
      if (type === CAMPAIGN.Banner && placementData.placement.size_type === 3) return true;
      // 전면 네이티브
      if (type === CAMPAIGN.Interstitial && placementData.placement.size_type === 11) return true;
      if (type === CAMPAIGN.Interstitial && placementData.placement.size_type === 12) return true;
      if (type === CAMPAIGN.Interstitial && placementData.placement.size_type === 13) return true;
      return false;
    },
    [placementData.placement.size_type]
  );

  const { getFieldProps, setFieldValue, setValues, handleSubmit, values } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const { placement } = placementData;
      onSubmit(values);
      mutate({
        // 기존 placement 정보
        placementId: open.key,
        bidfloor: placement.bidfloor,
        bidfloorCurrency: placement.bidfloor_currency,
        bidfloorStatus: placement.bidfloor_status,
        checkViewability: placement.check_viewability,
        cpcBidfloor: placement.cpc_bidfloor,
        cpcBidfloorCurrency: placement.cpc_bidfloor_currency,
        cpcBidfloorStatus: placement.cpc_bidfloor_status,
        isSDKMediation: placement.is_sdk_mediation,
        mediaKey: placement.media_key,
        name: placement.name,
        sizeType: placement.size_type,
        type: placement.type,
        // 수정한 campaign 정보
        ctaTextRequired: values.ctatextRequired,
        descRequired: values.descRequired,
        iconImageRequired: values.iconImageRequired,
        mainImageRequired: values.mainImageRequired,
        titleRequired: values.titleRequired,
        minViewTime: values.minViewTime,
        mute: values.mute,
        // 컴패니언 네이티브
        companionUse: values.companionUse,
        // 전면 네이티브
        subNativeUse: values.subNativeUse,
        // "광고 형식: 네이티브"에서만 사용
        ...(placement.type === CAMPAIGN.Native && {
          templateNo: values.templateNo,
        }),
      });
      onClose();
    },
  });

  const { nativeTemplateList, isLoading: isNativeTemplateListLoading } = useNativeTemplateList(
    NativeTemplateType.NATIVE,
    {
      enabled: isAppPlacementDetailSuccess && placementData.placement.type === CAMPAIGN.Native,
      onSuccess: (response) => {
        const { data: nativeTemplateList } = response;
        const templateNo = values.templateNo;
        const selectedNativeTemplate = nativeTemplateList.find(({ id }) => id === templateNo);

        if (!selectedNativeTemplate) {
          throw new Error("존재하지 않는 템플릿입니다.");
        }

        // 템플릿 옵션 목록 업데이트
        setTemplateOptions(
          nativeTemplateList.map(({ id, name }) => ({
            templateNo: id,
            name,
          }))
        );

        // 미리보기 이미지 업데이트
        setPreviewImgProps({
          ...getPreviewImgSize(selectedNativeTemplate.id),
          src:
            selectedNativeTemplate.id === TEMPLATE.NATIVE_NO_TEMPLATE
              ? previewImgByCampaign[CAMPAIGN.Native]
              : selectedNativeTemplate.iurl,
        });
      },
    }
  );

  const onChangeMinHoldingTime = useCallback(
    (e: SelectChangeEvent<number>) => {
      setFieldValue("minViewTime", +e.target.value);
    },
    [setFieldValue]
  );

  const onChangeCompanionStatus = useCallback(
    (e: SyntheticEvent<Element, Event>, checked: boolean) => {
      if (values.subNativeUse) {
        toast.warning(`전면 광고를 먼저 생성 및 저장을 하신 이후에 컴패니언 네이티브 광고를 생성하실 수 있습니다.
          
          먼저 전면 광고를 셋팅 및 저장한 이후에, 광고 형식에서 적용 해주실 수 있습니다.`);
        return;
      }
      if (checked && !values.companionId) {
        onShowAddCompanionModal(e, open.key);
        return;
      }
      setFieldValue("companionUse", checked);
    },
    [
      onShowAddCompanionModal,
      open.key,
      setFieldValue,
      toast,
      values.companionId,
      values.subNativeUse,
    ]
  );

  const onChangeSubNativeStatus = useCallback(
    (e: SyntheticEvent<Element, Event>, checked: boolean) => {
      if (values.companionUse) {
        toast.warning(`전면 네이티브 광고와 컴패니언 네이티브 광고는 동시에 적용할 수 없습니다.
          
          전면 네이티브 광고를 OFF한 이후에 컴패니언 네이티브 광고를 적용해주세요.`);
        return;
      }
      if (checked && !values.subNativeId) {
        onShowAddSubNativeModal(e, open.key);
        return;
      }
      setFieldValue("subNativeUse", checked);
    },
    [
      onShowAddSubNativeModal,
      open.key,
      setFieldValue,
      toast,
      values.companionUse,
      values.subNativeId,
    ]
  );

  const onShowEditCompanionModal = useCallback(
    (e: MouseEvent) => {
      onShowEditCompanion(e, open.key);
    },
    [onShowEditCompanion, open.key]
  );

  const onShowEditSubNativeModal = useCallback(
    (e: MouseEvent) => {
      onShowEditSubNative(e, open.key);
    },
    [onShowEditSubNative, open.key]
  );

  /** 네이티브 광고 템플릿 옵션 변경 핸들러 */
  const onChangeTemplateNo = useCallback(
    (e: SelectChangeEvent<Template>) => {
      const templateNo = +e.target.value as Template;
      const selectedNativeTemplate = nativeTemplateList.find(({ id }) => id === templateNo);

      if (!selectedNativeTemplate) {
        throw new Error("존재하지 않는 템플릿입니다.");
      }

      setFieldValue("templateNo", selectedNativeTemplate.id);
      setValues((prev) => ({
        ...prev,
        templateNo: selectedNativeTemplate.id,
        // 광고형식: 네이티브에 "Default" 템플릿이 아닌 경우 컴포넌트 영역의 값을 초기화
        ...(selectedNativeTemplate.id !== TEMPLATE.NATIVE_NO_TEMPLATE && {
          iconImageRequired: false,
          titleRequired: false,
          descRequired: false,
          mainImageRequired: false,
          ctatextRequired: false,
        }),
      }));

      setPreviewImgProps({
        ...getPreviewImgSize(selectedNativeTemplate.id),
        src:
          selectedNativeTemplate.id === TEMPLATE.NATIVE_NO_TEMPLATE
            ? previewImgByCampaign[CAMPAIGN.Native]
            : selectedNativeTemplate.iurl,
      });
    },
    [nativeTemplateList, setFieldValue, setValues]
  );

  return (
    <Dialog
      css={addCampaignModalStyle}
      fullWidth
      maxWidth="md"
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-campaign"
      aria-describedby="edit campaign"
    >
      <DialogTitle id="dialog-title">광고 형식 설정</DialogTitle>
      <DialogContent className="dialog-content">
        <Grid container>
          {/* 미리보기 영역 */}
          <Grid item xs={6} className="preview" sx={{ minHeight: defaultPreviewImgSize.height }}>
            {isAppPlacementDetailLoading || isNativeTemplateListLoading ? (
              <CircularProgress />
            ) : (
              <img {...previewImgProps} alt="preview" />
            )}
          </Grid>
          <Grid item xs={6}>
            <form id="edit-campaign-form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* 캠페인 타입 */}
                <Grid item xs={12}>
                  <TextField
                    className="field"
                    label="광고형식"
                    required
                    disabled
                    {...getFieldProps("campaign")}
                  />
                </Grid>

                {/* 사이즈 */}
                {isSizeRequired(values.type) && (
                  <Grid item xs={12}>
                    <TextField
                      className="field"
                      label="사이즈"
                      required
                      disabled
                      {...getFieldProps("size")}
                    />
                  </Grid>
                )}

                {/* 네이티브 광고 템플릿 */}
                {isNativeCampaign(values.type) && (
                  <Grid item xs={12}>
                    <Select
                      label="네이티브 광고 템플릿"
                      disabled={isNativeTemplateListLoading}
                      {...getFieldProps("templateNo")}
                      onChange={onChangeTemplateNo}
                    >
                      {templateOptions.map((option) => (
                        <MenuItem key={option.templateNo} value={option.templateNo}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}

                {/* 최소 시청 시간 */}
                {isMinViewTimeRequired(values.type) && (
                  <Grid item xs={12}>
                    <Select
                      label="최소 광고 유지 시간"
                      {...getFieldProps("minViewTime")}
                      onChange={onChangeMinHoldingTime}
                    >
                      {[1, 3, 5, 15].map((minHoldingTime) => (
                        <MenuItem key={minHoldingTime} value={minHoldingTime}>
                          {`${minHoldingTime} 초`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}

                {/* 네이티브 캠페인 컴포넌트 구성 */}
                {isComponentRequired(values.type, values.templateNo) && (
                  <Grid item xs={12} className="component">
                    <FormControl component="fieldset" variant="standard">
                      <FormLabel component="legend">컴포넌트</FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={<Checkbox {...getFieldProps("iconImageRequired")} />}
                          label="APP ICON"
                          checked={values.iconImageRequired}
                        />
                        <FormControlLabel
                          control={<Checkbox {...getFieldProps("titleRequired")} />}
                          label="TITLE"
                          checked={values.titleRequired}
                        />
                        <FormControlLabel
                          control={<Checkbox {...getFieldProps("descRequired")} />}
                          label="TEXT"
                          checked={values.descRequired}
                        />
                        <FormControlLabel
                          control={<Checkbox {...getFieldProps("mainImageRequired")} />}
                          label="IMAGE"
                          checked={values.mainImageRequired}
                        />
                        <FormControlLabel
                          control={<Checkbox {...getFieldProps("ctatextRequired")} />}
                          label="CTA TITLE"
                          checked={values.ctatextRequired}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                )}

                {/* 음소거 설정 */}
                {isMuteRequired(values.type) && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      className="switch"
                      control={<Switch color="primary" checked={values.mute} />}
                      label="비디오 음소거"
                      labelPlacement="start"
                      {...getFieldProps("mute")}
                    />
                  </Grid>
                )}

                {/* 컴패니언 네이티브 */}
                {isAdmin && isCompanionRequired(values.type) && (
                  <>
                    <Grid item xs={12} className="companion-native">
                      <FormControlLabel
                        className="switch"
                        control={<Switch color="primary" />}
                        label="컴패니언 네이티브 광고"
                        labelPlacement="start"
                        {...getFieldProps("companionUse")}
                        checked={values.companionUse}
                        onChange={onChangeCompanionStatus}
                      />
                    </Grid>
                    {values.companionUse && !!values.companionId && (
                      <Grid item xs={12}>
                        <TextField
                          label="컴패니언 네이티브 플레이스먼트"
                          readOnly
                          suffix={
                            <Button color="primary" onClick={onShowEditCompanionModal}>
                              설정하기
                            </Button>
                          }
                          {...getFieldProps("companionName")}
                        />
                      </Grid>
                    )}
                  </>
                )}

                {/* 서브 네이티브 */}
                {isAdmin && isSubNativeRequired(values.type) && (
                  <>
                    <Grid item xs={12} className="sub-native">
                      <FormControlLabel
                        className="switch"
                        control={<Switch color="primary" />}
                        label={`${values.type === CAMPAIGN.Banner ? "배너" : "전면"} 네이티브 광고`}
                        labelPlacement="start"
                        {...getFieldProps("subNativeUse")}
                        checked={values.subNativeUse}
                        onChange={onChangeSubNativeStatus}
                      />
                    </Grid>
                    {values.subNativeUse && !!values.subNativeId && (
                      <Grid item xs={12}>
                        <TextField
                          label={`${
                            values.type === CAMPAIGN.Banner ? "배너" : "전면"
                          } 네이티브 플레이스먼트`}
                          readOnly
                          suffix={
                            <Button color="primary" onClick={onShowEditSubNativeModal}>
                              설정하기
                            </Button>
                          }
                          {...getFieldProps("subNativeName")}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-campaign-form" type="submit">
          저장
        </Button>
      </DialogActions>
      {openAddCompanionModal.isOpen && (
        <AddCompanionModal
          open={openAddCompanionModal}
          onClose={onCloseAddCompanionModal}
          parentPlacementData={placementData}
        />
      )}
      {openEditCompanionModal.isOpen && (
        <EditCompanionModal
          open={openEditCompanionModal}
          onClose={onCloseEditCompanionModal}
          parentPlacementData={placementData}
        />
      )}
      {openAddSubNativeModal.isOpen && (
        <AddSubNativeModal
          open={openAddSubNativeModal}
          onClose={onCloseAddSubNativeModal}
          parentPlacementData={placementData}
        />
      )}
      {openEditSubNativeModal.isOpen && (
        <EditSubNativeModal
          open={openEditSubNativeModal}
          onClose={onCloseEditSubNativeModal}
          parentPlacementData={placementData}
        />
      )}
    </Dialog>
  );
};

export default EditCampaignModal;
