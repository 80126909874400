import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { AppPlatform, APP_PLATFORM, Partner, Status } from "src/types";
import API from "src/utils/api";
import useGetUserDetail from "../users/useGetUserDetail";

export interface PartnerApp {
  company_key: string;
  partner_id: number;
  partner_app_key: string;
  partner_app_name: string;
  os_type: AppPlatform;
  status: Status;
  created_at: number;
  updated_at: number;
}

interface Params {
  partnerList?: Partner[];
  platform?: AppPlatform;
  pageNo?: number;
  pageSize?: number;
  companyKey?: string;
}

interface Response {
  max_page_size: number;
  records: PartnerApp[];
}

//  파트너 리포트 앱 리스트 조회
const useGetPartnerAppList = ({ partnerList, platform, pageNo, pageSize, companyKey }: Params) => {
  const { handleError } = useApiError();
  const { data: userData } = useGetUserDetail({ userId: "me" });
  companyKey = companyKey || userData?.user.company_key;
  const { data, ...rest } = useQuery<Response, AxiosError>(
    ["report/useGetPartnerAppList", partnerList, platform, pageNo, pageSize, companyKey],
    async () => {
      const response: AxiosResponse<Response> = await API.console.get(`/partners/offerwall`, {
        params: {
          partner_id: partnerList?.join(","),
          os_type:
            platform === APP_PLATFORM.ALL
              ? [APP_PLATFORM.iOS, APP_PLATFORM.ANDROID].join(",")
              : platform,
          page_no: pageNo,
          page_size: pageSize,
          company_key: companyKey,
        },
      });
      return response.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => (data ? data : { records: [], max_page_size: 0 }), [data]);

  return { data: result, ...rest };
};

export default useGetPartnerAppList;
