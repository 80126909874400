import { Box, Paper, Typography } from "@mui/material";
import { waitingStyle } from "./styles";

const ApprovalWaitingPopup = () => {
  return (
    <Box css={waitingStyle}>
      <Paper className="content">
        <Typography id="modal-modal-title" variant="h6" component="h2" gutterBottom>
          관리자 승인 대기 중
        </Typography>
        <Typography id="modal-modal-description" variant="body1">
          입력하신 정보를 IGAWorks 담당자가 직접 검수하는 중입니다.
        </Typography>
      </Paper>
    </Box>
  );
};

export default ApprovalWaitingPopup;
