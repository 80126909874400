import DSPDailyReport from "./DSPDailyReport";
import DSPHourlyReport from "./DSPHourlyReport";

const DSPDashboard = () => {
  return (
    <>
      <DSPDailyReport />
      <DSPHourlyReport />
    </>
  );
};

export default DSPDashboard;
