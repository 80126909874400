import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { CellClassParams, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import moment, { Moment } from "moment";
import { MouseEventHandler, useCallback, useState } from "react";
import { DASHBOARD_LABEL } from "src/assets/alias/dashboard";
import DataFormat from "src/assets/formats";
import { DateField } from "src/components/commons";
import useGetEnterpriseDashboard from "src/hooks/apis/dashboard/useGetEnterpriseDashboard";
import AppDashboard from "./AppDashboard";
import { dashboardStyle } from "./styles";
import WebDashboard from "./WebDashboard";

type DataKey = keyof Pick<
  typeof DataFormat,
  "click" | "ecpm" | "impression" | "request" | "response" | "revenue"
>;

const Dashboard = () => {
  const [since, setSince] = useState({
    temp: moment().subtract(7, "d"),
    current: moment().subtract(7, "d"),
  });

  const [until, setUntil] = useState({
    temp: moment().subtract(1, "d"),
    current: moment().subtract(1, "d"),
  });

  const { data, cardData } = useGetEnterpriseDashboard({
    since: since.current,
    until: until.current,
  });

  const onChangeSinceDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setSince((prev) => ({ ...prev, temp: value }));
    }
    return;
  }, []);

  const onChangeUntilDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setUntil((prev) => ({ ...prev, temp: value }));
    }
    return;
  }, []);

  const onClickSearch = useCallback<MouseEventHandler>(() => {
    setSince((prev) => ({ ...prev, current: prev.temp }));
    setUntil((prev) => ({ ...prev, current: prev.temp }));
  }, []);

  const cardInfo = useCallback(
    (type: "app" | "web") => {
      const { request, click, ecpm, impression, response, revenue } = cardData[type];
      return [
        {
          key: "request",
          label: DASHBOARD_LABEL["request"],
          value: DataFormat.request.formatter(request),
        },
        {
          key: "response",
          label: DASHBOARD_LABEL["response"],
          value: DataFormat.response.formatter(response),
        },
        {
          key: "impression",
          label: DASHBOARD_LABEL["impression"],
          value: DataFormat.impression.formatter(impression),
        },
        {
          key: "click",
          label: DASHBOARD_LABEL["click"],
          value: DataFormat.click.formatter(click),
        },
        {
          key: "ecpm",
          label: DASHBOARD_LABEL["ecpm"],
          value: DataFormat.ecpm.formatter(ecpm),
        },
        {
          key: "revenue",
          label: DASHBOARD_LABEL["revenue"],
          value: DataFormat.revenue.formatter(revenue),
        },
      ];
    },
    [cardData]
  );

  return (
    <Container css={dashboardStyle} component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        대시보드
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid container className="ssp-tools" spacing={2}>
          <Grid item xs={4}>
            <DateField
              label="시작일"
              value={since.temp}
              maxDate={until.temp}
              onChange={onChangeSinceDate}
            />
          </Grid>
          <Grid item xs={4}>
            <DateField
              label="종료일"
              value={until.temp}
              minDate={since.temp}
              maxDate={moment().subtract(1, "d")}
              onChange={onChangeUntilDate}
            />
          </Grid>
          <Grid item xs={2}>
            <Button type="button" variant="contained" onClick={onClickSearch}>
              검색
            </Button>
          </Grid>
        </Grid>
        <AppDashboard
          since={since.current}
          until={until.current}
          data={data.apps}
          cardInfo={cardInfo("app")}
        />
        <WebDashboard
          since={since.current}
          until={until.current}
          data={data.webs}
          cardInfo={cardInfo("web")}
        />
      </Paper>
    </Container>
  );
};

export const getColumnDefs = (dataKey: DataKey) => [
  {
    headerName: "#",
    sortable: true,
    valueGetter: (params: ValueGetterParams) => params.node?.rowIndex ?? 0,
    minWidth: 80,
    maxWidth: 80,
  },
  {
    headerName: "매체명",
    field: "media_name",
    sortable: true,
  },
  {
    headerName: DASHBOARD_LABEL[dataKey],
    field: dataKey,
    valueFormatter: (params: ValueFormatterParams) => {
      try {
        if (Number.isNaN(Number.parseFloat(params.value))) {
          throw new Error("@value should be a number or string presenting a number.");
        }
        if (params.value > -1 && params.value < 1) {
          return `${Math.floor(Math.abs(params.value))}`;
        } else {
          return DataFormat[dataKey].formatter(params.value);
        }
      } catch {
        return "-";
      }
    },
    type: "rightAligned",
    minWidth: 150,
    maxWidth: 150,
    cellStyle: (param: CellClassParams) => {
      const opacity = 30 / (30 * ((param.node?.rowIndex ?? 0) + 1));
      return {
        background: `rgba(54, 115, 63, ${opacity})`,
        color: opacity >= 0.8 ? "#fff" : "#000",
      };
    },
    sort: "desc" as const,
  },
];

export default Dashboard;
