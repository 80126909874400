import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { AppPlatform, APP_PLATFORM, MEDIA, Media, STATUS, Status } from "src/types";
import API from "src/utils/api";

interface Params {
  mediaKey?: string;
}

interface Response {
  code: 200 | 401 | 404;
  data?: {
    media: {
      block_domain_list_set: number[];
      collect_installed_apps: boolean;
      company_key: string;
      created_at: number;
      hash_key: string;
      key: string;
      name: string;
      package_name: string;
      placement_count: number;
      platform_type: AppPlatform;
      status: Status;
      test_device_count: number;
      type: Media;
      updated_at: number;
      url_scheme: string;
    };
  };
  text: "ok" | "unauthorized" | "not-found";
}

// 앱 매체 조회
const useGetAppMedia = ({ mediaKey }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["media/useGetAppMedia", mediaKey],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/media/app/${mediaKey}`);
      return response.data.data;
    },
    {
      enabled: !!mediaKey,
      onError: handleError,
    }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            media: {
              block_domain_list_set: [],
              collect_installed_apps: false,
              company_key: "",
              created_at: 0,
              hash_key: "",
              key: "",
              name: "",
              package_name: "",
              placement_count: 0,
              platform_type: APP_PLATFORM.ANDROID,
              status: STATUS.ACTIVE,
              test_device_count: 0,
              type: MEDIA.APP,
              updated_at: 0,
              url_scheme: "",
            },
          },
    [data]
  );
  return { data: result, ...rest };
};

export default useGetAppMedia;
