import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

export interface Company {
  company_key: string;
  company_name: string;
}

interface Response {
  code?: 200 | 40;
  data?: Company[];
  pages?: number;
  text?: "ok" | "unauthorized";
}

/*
  파트너 등록용 업체 검색
*/
const useGetCompanyList = () => {
  const { handleError } = useApiError();
  const { data: response, ...rest } = useQuery<Response, AxiosError>(
    ["coupang/useGetCompanyList"],
    async () => {
      const response: AxiosResponse<Response> = await API.integration.get(
        `/operation/management/coupang/partner/companies`
      );
      return response.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => {
    if (response && response.data)
      return {
        companies: response.data,
      };
    return { companies: [] };
  }, [response]);

  return { data: result, ...rest };
};

export default useGetCompanyList;
