import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  identifier: string;
  mediaKey: string;
}

interface Response {
  code: 200 | 400 | 401 | 409 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "conflict" | "internal-server-error";
}

// 테스트 디바이스 등록
const usePostTestDevice = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ identifier, mediaKey }) => {
      const response: AxiosResponse<Response> = await API.default.post(`/test-devices`, {
        identifier,
        media_key: mediaKey,
      });
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("테스트 디바이스가 추가되었습니다.");
        invalidate("testDevices/useGetTestDeviceList");
      },
    }
  );

  return result;
};

export default usePostTestDevice;
