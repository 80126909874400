import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  csNo: string;
  answer: string;
}

interface Response {
  code: 201 | 400 | 401 | 404 | 422;
  text:
    | "CS_REPLY_CREATE_SUCCESS"
    | "bad-request"
    | "unauthorized"
    | "not-found"
    | "unprocessable-entity";
}

// 쿠팡 CS 문의 답변
const usePatchCustomerService = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ csNo, answer }) => {
      const response: AxiosResponse<Response> = await API.integration.patch(
        `/operation/management/coupang/cs/${csNo}`,
        {
          reply_message: answer,
        }
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("답변이 등록되었습니다.");
        invalidate(["coupang/useGetCustomerService", "coupang/useGetCustomerServiceList"]);
      },
    }
  );

  return result;
};

export default usePatchCustomerService;
