import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { MutationFunction, useMutation, UseMutationOptions } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";

export type ReplyNpayCsValues = {
  id: string;
  response_content: string;
};

type ReplyNpayCsMutationKey = "operation/npay/reply-cs";
type ReplyNpayCsMutationFn = MutationFunction<AxiosResponse, ReplyNpayCsValues>;

type ReplyNpayCsMutationOptions = Omit<
  UseMutationOptions<AxiosResponse, AxiosError, ReplyNpayCsValues>,
  "mutationKey" | "mutationFn"
>;

export default function useReplyNpayCs(options?: ReplyNpayCsMutationOptions) {
  const { handleError } = useApiError();

  const mutationKey = useMemo<ReplyNpayCsMutationKey>(() => "operation/npay/reply-cs", []);

  const mutateFn = useCallback<ReplyNpayCsMutationFn>((values) => {
    const { id, ...rest } = values;
    const cookie = parseCookies();

    return API.consoleV3.put(`/v3/customer-services/naverpay/${id}`, rest, {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
    });
  }, []);

  const mutation = useMutation(mutationKey, mutateFn, {
    onError: handleError,
    ...options,
  });

  return mutation;
}
