import { Box, Button, Grid, MenuItem, Paper, SelectChangeEvent } from "@mui/material";
import moment, { Moment } from "moment";
import { MouseEvent, useCallback, useState } from "react";
import { useRecoilState } from "recoil";

import { viewerCompany } from "src/atoms/viewerCompany";
import { DateField, Select } from "src/components/commons";
import usePostCoupangExcel from "src/hooks/apis/report/usePostCoupangExcel";
import usePostCoupangReport from "src/hooks/apis/report/usePostCoupangReport";
import { REPORT, Report, REPORT_ALIAS } from "src/types";

import CoupangReportBoard from "./CoupangReportBoard";
import CoupangReportChart from "./CoupangReportChart";
import { coupangStyle } from "./styles";

const CoupangReport = () => {
  const [reportType, setReportType] = useState<Report>(REPORT.DAILY);
  const [since, setSince] = useState(moment().subtract(7, "d"));
  const [until, setUntil] = useState(moment().subtract(1, "d"));

  const [company] = useRecoilState(viewerCompany);

  const { mutate: search, data } = usePostCoupangReport();
  const { mutate } = usePostCoupangExcel();

  const onChangeReportType = useCallback((event: SelectChangeEvent<Report>) => {
    const value = +event.target.value as Report;
    setReportType(value);
  }, []);

  const onChangeSinceDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setSince(value);
    }
    return;
  }, []);

  const onChangeUntilDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setUntil(value);
    }
    return;
  }, []);

  const onClickSearch = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      search({
        since,
        until,
        reportType,
        companyKey: company.key,
      });
    },
    [company.key, reportType, search, since, until]
  );

  const onExport = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      mutate({
        since,
        until,
        reportType,
        companyKey: company.key,
      });
    },
    [company.key, mutate, reportType, since, until]
  );

  return (
    <Box css={coupangStyle}>
      <Paper className="content" elevation={2}>
        <Grid className="ssp-tools" container spacing={2}>
          <Grid item xs={2}>
            <Select
              label="리포트"
              placeholder="리포트를 선택해주세요."
              onChange={onChangeReportType}
              value={reportType}
            >
              {Object.entries(REPORT_ALIAS).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <DateField label="시작일" value={since} maxDate={until} onChange={onChangeSinceDate} />
          </Grid>
          <Grid item xs={4}>
            <DateField
              label="종료일"
              value={until}
              minDate={since}
              maxDate={moment().subtract(1, "d")}
              onChange={onChangeUntilDate}
            />
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" sx={{ width: "100%" }} onClick={onClickSearch}>
              조회하기
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CoupangReportChart defaultType="clientCommission" data={data.chartData} />
          </Grid>
          <Grid item xs={6}>
            <CoupangReportChart defaultType="click" data={data.chartData} />
          </Grid>
          <Grid item xs={12}>
            <CoupangReportBoard data={data.reportData} onExport={onExport} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default CoupangReport;
