import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useFormik } from "formik";
import { useMemo } from "react";
import { TextField } from "src/components/commons";
import { Mediation } from "src/hooks/apis/thirdparties/useGetMediationList";
import usePutMediationDetail from "src/hooks/apis/thirdparties/usePutMediationDetail";
import { MEDIA, MEDIA_ALIAS } from "src/types";
import { getHelperText, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { editMediationModalStyle } from "./styles";

interface EditMediationModalProps {
  mediation?: Mediation;
  onClose: () => void;
  isOpen: boolean;
}

const EditMediationModal = ({ mediation, onClose, isOpen }: EditMediationModalProps) => {
  const { mutate } = usePutMediationDetail();

  const initialValues = useMemo(
    () => ({
      name: mediation?.name || "",
      mediaType: MEDIA_ALIAS[mediation?.media_type || MEDIA.APP],
      systemFee: mediation?.system_fee ?? 0,
    }),
    [mediation?.media_type, mediation?.name, mediation?.system_fee]
  );

  const validationSchema = yup.object({
    systemFee: yup
      .string()
      .test("systemFee", "숫자를 입력해주세요.", (value = "") => !isNaN(+value))
      .required("시스템 수수료를 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (mediation)
        mutate({
          thirdpartyId: mediation.id,
          systemFee: +values.systemFee,
        });
    },
  });

  return (
    <Dialog
      css={editMediationModalStyle}
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-dsp"
      aria-describedby="edit dsp"
    >
      <DialogTitle id="dialog-title">{mediation?.name}</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="edit-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="미디에이션 명"
                required
                disabled
                InputLabelProps={{ shrink: true }}
                {...getFieldProps("name")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="매체 종류"
                required
                disabled
                {...getFieldProps("mediaType")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="시스템 수수료"
                required
                {...getFieldProps("systemFee")}
                error={shouldErrorShows("systemFee", touched, errors)}
                helperText={getHelperText("systemFee", touched, errors)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMediationModal;
