export const SETTLEMENT = {
  GROSS: 1,
  NET: 2,
  CUSTOM_GROSS: 3,
  CUSTOM_NET: 4,
} as const;

export const SETTLEMENT_ALIAS = {
  [SETTLEMENT.GROSS]: "GROSS",
  [SETTLEMENT.NET]: "NET",
  [SETTLEMENT.CUSTOM_GROSS]: "별도정산(GROSS)",
  [SETTLEMENT.CUSTOM_NET]: "별도정산(NET)",
} as const;

export const TAX_BILL_STATUS = {
  DEFAULT: 50, // 발행확인
  CONFIRMED: 60, // 발행완료
  CARRIED: 70, // 이월
  // HELD: 80, // 보류
};

export const TAX_BILL_STATUS_ALIAS = {
  [TAX_BILL_STATUS.DEFAULT]: "발행 확인", // 발행확인
  [TAX_BILL_STATUS.CONFIRMED]: "발행 완료", // 발행완료
  [TAX_BILL_STATUS.CARRIED]: "이월", // 이월
  // [TAX_BILL_STATUS.HELD]: "보류", // 보류
};

export const PAYMENT_STATUS = {
  DEFAULT: 50,
  WAITING: 60,
  PAID: 70,
  REJECTED: 80,
};

export const PAYMENT_STATUS_ALIAS = {
  [PAYMENT_STATUS.DEFAULT]: "기본",
  [PAYMENT_STATUS.WAITING]: "지급대기",
  [PAYMENT_STATUS.PAID]: "지급완료",
  [PAYMENT_STATUS.REJECTED]: "지급거절",
};

export const TRANSACTION_HISTORY_EVENT = {
  TAX_BILL_CONFIRMED: 1, // 세금계산서 발행완료
  // TAX_BILL_HELD: 2, // 보류
  // TAX_BILL_RESTORED: 3, // 보류 취소됨
  CARRIED: 4, // 이월됨
  WAITING: 5, // 지급 대기
  PAID: 6, // 지급 완료
  REJECTED: 7, // 지급 거절
};

export const SETTLEMENT_HISTORY_TYPE = {
  TAX_BILL_REVENUE: 1, // 발행액 변경 내역
  // HOLDING: 2, // 보류내역
};

export type Settlement = Union<typeof SETTLEMENT>;
export type TaxBillStatus = Union<typeof TAX_BILL_STATUS>;
export type PaymentStatus = Union<typeof PAYMENT_STATUS>;
export type TransactionHistoryEvent = Union<typeof TRANSACTION_HISTORY_EVENT>;
export type SettlementHistoryType = Union<typeof SETTLEMENT_HISTORY_TYPE>;

/**
 * 롯데 멤버스 등의 예외로 인해 수익 확정일을 4일에서 2일로 단축 조정
 */
export const SettlementRevenueConfirmationDate = 2 as const; // 매달 ${SettlementRevenueConfirmationDate}일에 media revenue (순매체비)가 확정된다.
