import moment from "moment";
import { parseCookies } from "nookies";
import { useMemo } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import routes, { Route, RoutesDfs } from "src/assets/routes";
import useGetUserAuthority from "src/hooks/apis/users/useGetUserAuthority";

const AuthRoute = () => {
  const cookie = parseCookies();
  const token = cookie.access_token || "";
  const expiredDate = +cookie.expires || 0;
  const { pathname } = useLocation();

  const { isAccessible, data, initialRoute, isLoading } = useGetUserAuthority({ userId: "me" });

  const route = useMemo(
    () =>
      RoutesDfs(routes)
        .sort((a, b) => b.href.length - a.href.length)
        .find((route: Route) => pathname.includes(route.href)),
    [pathname]
  );

  if (!route) return <Navigate to="/error" replace />;

  if (isLoading) return null;

  // 토큰 없을 시 로그인으로 리다이렉트
  if (!token || !expiredDate || (token && expiredDate && moment().isAfter(expiredDate)) || !data)
    return <Navigate to={`/login?returnUrl=${pathname || "/"}`} replace />;

  if (route && !isAccessible(pathname.split("/")[1], route.authority))
    return <Navigate to={initialRoute || "/"} replace />;

  return <Outlet />;
};

export default AuthRoute;
