import { AxiosError, AxiosResponse } from "axios";
import { Moment } from "moment";
import { useMemo } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Media } from "src/types";
import API from "src/utils/api";

interface Params {
  mediaKeys: string[];
  placementIds: string[];
  type: Media;
  since: Moment;
  until: Moment;
  isTest?: boolean;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    report: {
      click: number;
      ctr: number;
      ecpm: number;
      impression: number;
      report_date: string;
      revenue: number;
    }[];
    total: {
      click: number;
      ctr: number;
      ecpm: number;
      impression: number;
      revenue: number;
    };
  };
  text: "ok" | "bad-request" | "unauthorized";
}

/**
  앱/웹 일별 데이터 조회
  앱 = DSP+미디에이션
  웹 = 헤더비딩(크리테오, 애드믹서 등)+DSP(와이더플래닛, 인라이플 등)+미디에이션
  AWS 로드밸런서의 쿼리스트링 최대 허용 길이 초과로 414 오류를 반환해서 GET=>POST로 변경
 */
const usePostMediaDaily = () => {
  const { handleError } = useApiError();
  const { data, ...rest } = useMutation<Response["data"], AxiosError, Params>(
    async ({ mediaKeys, placementIds, type, since, until, isTest = false }) => {
      const response: AxiosResponse<Response> = await API.default.post(`/dashboard/daily`, {
        media_keys: mediaKeys,
        placement_ids: placementIds,
        type: type,
        since: since.format("YYYYMMDD"),
        until: until.format("YYYYMMDD"),
        is_test: isTest,
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            report: [],
            total: {
              click: 0,
              ctr: 0,
              ecpm: 0,
              impression: 0,
              revenue: 0,
            },
          },
    [data]
  );

  return { data: result, ...rest };
};

export default usePostMediaDaily;
