import { Card, CardContent, CardHeader, Container, Grid, Paper, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { DASHBOARD_LABEL } from "src/assets/alias/dashboard";
import DataFormat from "src/assets/formats";
import { viewerCompany } from "src/atoms/viewerCompany";
import { DateField, MultipleSelect } from "src/components/commons";
import usePostMediaDaily from "src/hooks/apis/dashboard/usePostMediaDaily";
import useGetAppMediaList, { AppMedia } from "src/hooks/apis/media/useGetAppMediaList";
import useGetAppPlacementList, {
  AppPlacement,
} from "src/hooks/apis/placements/useGetAppPlacementList";
import { MEDIA } from "src/types";
import DashboardReportChart from "./DashboardReportChart";
import { cardStyle, dashboardReportStyle } from "./styles";

const HeaderBiddingDashboard = () => {
  const [mediaList, setMediaList] = useState<AppMedia[]>([]);
  const [placementList, setPlacementList] = useState<AppPlacement[]>([]);
  const [since, setSince] = useState(moment().subtract(7, "d"));
  const [until, setUntil] = useState(moment().subtract(1, "d"));
  const [company] = useRecoilState(viewerCompany);

  const { data: mediaData } = useGetAppMediaList({ companyKey: company.key });
  const { data: placementData } = useGetAppPlacementList({
    mediaKeys: mediaData.media.map((m) => m.key),
  });
  const { data: mediaDailyData, mutate } = usePostMediaDaily();

  // 초기 매체필터 데이터 세팅
  useEffect(() => {
    setMediaList(mediaData.media);
  }, [mediaData.media]);

  // 초기 플레이스먼트필터 데이터 세팅
  useEffect(() => {
    setPlacementList(placementData.placements);
  }, [placementData.placements]);

  // 매체, 플레이스먼트, 시작일, 종료일 필터 변경시 데이터 요청
  useEffect(() => {
    if (mediaList.length > 0 && placementList.length > 0)
      mutate({
        type: MEDIA.APP,
        mediaKeys: mediaList.map((m) => m.key),
        placementIds: placementList.map((p) => p.id),
        since: since,
        until: until,
      });
  }, [mediaList, mutate, placementList, since, until]);

  const onChangeSinceDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setSince(value);
    }
    return;
  }, []);

  const onChangeUntilDate = useCallback((value: Moment | null, _keyboardInputValue?: string) => {
    if (value && value.isValid()) {
      setUntil(value);
    }
    return;
  }, []);

  const onChangeMediaList = useCallback((values: AppMedia[]) => {
    setMediaList(values);
  }, []);

  const onChangePlacementList = useCallback((values: AppPlacement[]) => {
    setPlacementList(values);
  }, []);

  const cardInfo: {
    key: "ctr" | "ecpm" | "impression" | "revenue";
    label: string;
    value: string;
  }[] = useMemo(() => {
    const { ctr, ecpm, impression, revenue } = mediaDailyData.total;
    return [
      {
        key: "impression",
        label: DASHBOARD_LABEL["impression"],
        value: DataFormat.impression.formatter(impression),
      },
      {
        key: "revenue",
        label: DASHBOARD_LABEL["revenue"],
        value: DataFormat.revenue.formatter(revenue),
      },
      {
        key: "ctr",
        label: DASHBOARD_LABEL["ctr"],
        value: DataFormat.ctr.formatter(ctr),
      },
      {
        key: "ecpm",
        label: DASHBOARD_LABEL["ecpm"],
        value: DataFormat.ecpm.formatter(ecpm),
      },
    ];
  }, [mediaDailyData.total]);

  return (
    <Container css={dashboardReportStyle} component="section" className="ssp-section" maxWidth="xl">
      <Paper className="content" elevation={2}>
        <Grid container className="ssp-tools" spacing={2}>
          <Grid item xs={3}>
            <MultipleSelect
              className="field"
              options={mediaData.media}
              label="매체"
              placeholder="매체 선택"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.key}
              onChange={onChangeMediaList}
              value={mediaList}
            />
          </Grid>
          <Grid item xs={3}>
            <MultipleSelect
              className="field"
              options={placementData.placements}
              label="플레이스먼트"
              placeholder="플레이스먼트 선택"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={onChangePlacementList}
              value={placementList}
            />
          </Grid>
          <Grid item xs={3}>
            <DateField label="시작일" value={since} maxDate={until} onChange={onChangeSinceDate} />
          </Grid>
          <Grid item xs={3}>
            <DateField
              label="종료일"
              value={until}
              minDate={since}
              maxDate={moment().subtract(1, "d")}
              onChange={onChangeUntilDate}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {cardInfo.map(({ label, value }) => (
            <Grid key={label} item xs={3}>
              <Card css={cardStyle} elevation={2}>
                <CardHeader className="card-title" title={label} />
                <CardContent>
                  <Typography className="card-content" variant="h5" gutterBottom>
                    {value}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <DashboardReportChart data={mediaDailyData.report} />
      </Paper>
    </Container>
  );
};

export default HeaderBiddingDashboard;
