import { css } from "@emotion/react";

import { cyan, grey } from "@mui/material/colors";

export const mainStyle = css`
  display: flex;
  flex-direction: column;
  flex: 1;

  .container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 32px 48px;
  }
`;

export const loginStyle = css`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;

  & > div {
    height: 100%;
  }
`;

export const welcomeStyle = css`
  position: relative;
  width: 100%;
  height: 100%;

  .logo {
    z-index: 3;
    position: absolute;
    top: 40px;
    left: 60px;
    cursor: pointer;
    object-fit: none;
  }

  .main-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .description {
    position: relative;
    height: 100%;
    color: ${grey[100]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 128px;
    text-shadow: 0.5px 0.5px 1px ${grey[900]};
    z-index: 2;
    .main {
      font-size: 32px;
      font-weight: 500;
      line-height: 1.3;
    }
    .sub {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.3;
      margin-top: 64px;
    }
    .detail {
      text-decoration: none;
      color: ${cyan["A200"]};
      font-size: 14px;
      margin: 32px auto 0 0;
    }
  }
  .hidden {
    display: none;
    opacity: 0;
  }

  .appear {
    animation: fade-in 2.5s;
    animation-fill-mode: forwards;
    display: flex;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .logo {
      display: none;
    }
    .description {
      display: none;
    }
  }
`;

export const policyStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  line-height: 1.5;

  .contents {
    font-size: 14px;
    max-width: 800px;
    min-width: 320px;
    padding: 2.5rem;

    section {
      margin: 30px auto;

      .title {
        font-size: 1rem;
        margin: 1rem 0;
        text-align: left;
      }
      .description {
        margin: 1rem 0;
      }
      .content {
        margin: 1rem;
        padding: 0;

        ol,
        ul {
          padding-left: 1rem;
          li {
            margin: 0.5rem 0;
          }
        }

        table {
          border-collapse: collapse;
          width: 100%;
          border-spacing: 2px;
          border-color: grey;
          th,
          td {
            text-align: center;
            border: 2px solid lightgray;

            ul {
              padding-left: 1rem;
              li {
                margin: 0.5rem 0;
              }
            }
          }
        }

        table:nth-child(1) {
          width: 100%;
          td {
            text-align: left;
            border: 2px solid lightgray;
            padding: 5px;
          }
          td.table-title {
            text-align: center;
            white-space: nowrap;
          }
        }
      }

      .browser-device {
        margin-top: 20px;
      }
    }
  }
`;

export const coupangStyle = css`
  .tabs {
    margin-bottom: 2rem;
  }
`;
