import { Card, CardContent, Container, Divider, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import { useRecoilState } from "recoil";
import DataFormat from "src/assets/formats";
import { viewerCompany } from "src/atoms/viewerCompany";
import useGetRevenue from "src/hooks/apis/dashboard/useGetRevenue";
import { MEDIA } from "src/types";
import MediationChart from "./MediationChart";
import { homeStyle } from "./styles";

const Dashboard = () => {
  const [company] = useRecoilState(viewerCompany);
  const { data: revenueData } = useGetRevenue({ companyKey: company.key, type: MEDIA.WEB });

  const recentRevenueLabel = useMemo(
    () => ({
      month: `${moment().format("M")}월 예상 수입`,
      term: `(${moment().startOf("month").format("MM.DD")} ~ ${moment()
        .subtract(1, "d")
        .format("MM.DD")})`,
      week: `최근 7일 수입 (${moment().subtract(7, "d").format("YYYY.MM.DD")} - ${moment()
        .subtract(1, "d")
        .format("MM.DD")})`,
      yesterday: `어제의 수입 (${moment().subtract(1, "d").format("YYYY.MM.DD")})`,
    }),
    []
  );

  const recentRevenueValue = useMemo(
    () => ({
      month: `US ${DataFormat.revenue.formatter(revenueData.revenue_summary.month || 0)}`,
      week: `US ${DataFormat.revenue.formatter(revenueData.revenue_summary.week || 0)}`,
      yesterday: `US ${DataFormat.revenue.formatter(revenueData.revenue_summary.yesterday || 0)}`,
    }),
    [
      revenueData.revenue_summary.month,
      revenueData.revenue_summary.week,
      revenueData.revenue_summary.yesterday,
    ]
  );

  return (
    <Container css={homeStyle} component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        대시보드
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid container spacing={3}>
          {/* 월 예상 수입 */}
          <Grid item xs={6}>
            <Typography variant="h5" gutterBottom>
              {recentRevenueLabel.month}
              <small>{recentRevenueLabel.term}</small>
            </Typography>
            <Typography className="month-revenue" variant="h4" component="div">
              {recentRevenueValue.month}
            </Typography>
            <Divider className="divider" />
            <p className="description">
              정산 시 수입금은 월별 환율을 기준으로 계산되며, 미디에이션 파트너별 수입금 확정 일자에
              따라 예상수입 금액과 차이가 발생할 수 있습니다.
            </p>
          </Grid>
          {/* 미디에이션 */}
          <Grid item xs={6}>
            <Typography variant="h5" gutterBottom>
              미디에이션
            </Typography>
            <MediationChart />
          </Grid>
          {/* 최근 일주일 및 전일 수입 */}
          <Grid item xs={12} className="recent-info">
            <Card elevation={2}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6} className="week">
                    <Typography variant="body1" component="div">
                      {recentRevenueLabel.week}
                    </Typography>
                    <Typography className="revenue" variant="h6">
                      {recentRevenueValue.week}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className="yesterday">
                    <Typography variant="body1" component="div">
                      {recentRevenueLabel.yesterday}
                    </Typography>
                    <Typography className="revenue" variant="h6">
                      {recentRevenueValue.yesterday}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Dashboard;
