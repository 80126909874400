import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

interface Params {
  search?: string;
  pageNo: number;
  pageSize: number;
  orders?: string[];
}

interface Response {
  code?: 200 | 401 | 422;
  data?: {
    campaign_key: string;
    campaign_name: string;
    campaign_no: number;
    landing_url: string;
    media_key: string;
    partner_item_key: string;
    partner_nmae: string;
    partner_no: number;
    regist_datetime: string;
    trigger_revenue: number;
  }[];
  pages?: number;
  text?: "ok" | "unauthorized" | "unprocessable-entity";
}

/*
  쿠팡 캠페인 전체 조회 및 캠페인명 검색
*/
const useGetCampaignList = ({ search, pageNo, pageSize, orders }: Params) => {
  const { handleError } = useApiError();
  const { data: response, ...rest } = useQuery<Response, AxiosError>(
    ["coupang/useGetCampaignList", search, pageNo, pageSize, orders],
    async () => {
      const response: AxiosResponse<Response> = await API.integration.get(
        `/operation/management/coupang/campaign`,
        {
          params: { q: search, page_no: pageNo, page_size: pageSize, order: orders?.join(",") },
        }
      );
      return response.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => {
    if (response && response.data)
      return {
        campaigns: response.data,
        pages: response.pages || 0,
      };
    return { campaigns: [], pages: 0 };
  }, [response]);

  return { data: result, ...rest };
};

export default useGetCampaignList;
