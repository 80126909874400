import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

interface Params {
  month: string;
}

interface Response {
  code: 200 | 400 | 401 | 404;
  data?: {
    exchange_rate: number;
    expiration_date: string;
    is_closing: boolean;
    month: string;
    not_closing_months: string[] | null;
    sent_mail: boolean;
    sent_mail_datetime: number;
  };
  text: "ok" | "bad-request" | "unauthorized" | "not-found";
}

// 정산기준 조회
const useGetCriteria = ({ month }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["payments/useGetCriteria", month],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/payments/criteria`, {
        params: { month },
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            exchange_rate: 0,
            expiration_date: "",
            is_closing: false,
            month: "",
            not_closing_months: [],
            sent_mail: false,
            sent_mail_datetime: 0,
          },
    [data]
  );

  return { data: result, ...rest };
};

export default useGetCriteria;
