import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  code: string;
  email: string;
}

interface Response {
  code: 200 | 400 | 401 | 404 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "not-found" | "internal-server-error";
}

// 메일로 발송한 이메일, 인증코드를 검사
const usePostVerify = () => {
  const { handleError } = useApiError({ 401: (_error: AxiosError) => null });
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async (params) => {
      const response: AxiosResponse<Response> = await API.default.post("/auth/verify", params);
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("인증되었습니다.");
        invalidate();
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePostVerify;
