import { Container, Paper, Tab, Tabs } from "@mui/material";
import { SyntheticEvent, useCallback, useState } from "react";
import AppMediationDashboard from "./AppMediationDashboard";
import DSPDashboard from "./DSPDashboard";
import HeaderBiddingDashboard from "./HeaderBiddingDashboard";
import PublisherDashboard from "./PublisherDashboard";
import { dashboardStyle } from "./styles";
import WebMediationDashboard from "./WebMediationDashboard";

const SubDashboard = () => {
  const [tabValue, setTabValue] = useState(0);

  const onClickMainMenu = useCallback((_event: SyntheticEvent, value: number) => {
    setTabValue(value);
  }, []);

  return (
    <Container css={dashboardStyle} component="section" className="ssp-section" maxWidth="xl">
      <Paper className="content" elevation={2}>
        <Tabs value={tabValue} sx={{ marginBottom: "2rem" }} onChange={onClickMainMenu}>
          <Tab label="[APP] 퍼블리셔 대시보드" value={0} />
          <Tab label="[APP] DSP 대시보드" value={1} />
          <Tab label="[APP] 미디에이션 대시보드" value={2} />
          <Tab label="[WEB] 헤더비딩 대시보드" value={3} />
          <Tab label="[WEB] 미디에이션 대시보드" value={4} />
        </Tabs>

        {tabValue === 0 && <PublisherDashboard />}
        {tabValue === 1 && <DSPDashboard />}
        {tabValue === 2 && <AppMediationDashboard />}
        {tabValue === 3 && <HeaderBiddingDashboard />}
        {tabValue === 4 && <WebMediationDashboard />}
      </Paper>
    </Container>
  );
};

export default SubDashboard;
