import { Alert, AlertTitle, IconButton } from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useSetRecoilState } from "recoil";
import { Toast, toast } from "src/atoms/toast";
import { toastStyle } from "./styles";

const ToastMsg = ({ id, className, type, message, duration = 3000, onClose }: Toast) => {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const setToastList = useSetRecoilState(toast);

  useEffect(() => {
    timer.current = setTimeout(() => {
      setToastList((prev) => prev.filter((toast) => toast.id !== id));
      onClose && onClose();
    }, duration);
    return () => {
      timer.current = null;
    };
  }, []);

  const onCloseToast = useCallback(() => {
    timer.current = null;
    onClose && onClose();
    setToastList((prev) => prev.filter((toast) => toast.id !== id));
    onClose && onClose();
  }, [id, onClose, setToastList]);

  if (type === "success")
    return (
      <Alert
        severity="success"
        css={toastStyle}
        className={className}
        action={
          <IconButton className="close-btn" size="small" onClick={onCloseToast}>
            <AiOutlineClose />
          </IconButton>
        }
      >
        <AlertTitle>Success</AlertTitle>
        {message}
      </Alert>
    );
  if (type === "info")
    return (
      <Alert
        severity="info"
        css={toastStyle}
        className={className}
        action={
          <IconButton className="close-btn" size="small" onClick={onCloseToast}>
            <AiOutlineClose />
          </IconButton>
        }
      >
        <AlertTitle>Info</AlertTitle>
        {message}
      </Alert>
    );
  if (type === "error")
    return (
      <Alert
        severity="error"
        css={toastStyle}
        className={className}
        action={
          <IconButton className="close-btn" size="small" onClick={onCloseToast}>
            <AiOutlineClose />
          </IconButton>
        }
      >
        <AlertTitle>Error</AlertTitle>
        {message}
      </Alert>
    );
  if (type === "warning")
    return (
      <Alert
        severity="warning"
        css={toastStyle}
        className={className}
        action={
          <IconButton className="close-btn" size="small" onClick={onCloseToast}>
            <AiOutlineClose />
          </IconButton>
        }
      >
        <AlertTitle>Warning</AlertTitle>
        {message}
      </Alert>
    );

  return (
    <Alert
      severity="error"
      className={className}
      action={
        <IconButton className="close-btn" size="small" onClick={onCloseToast}>
          <AiOutlineClose />
        </IconButton>
      }
    >
      <AlertTitle>Error</AlertTitle>
      {message}
    </Alert>
  );
};

export default ToastMsg;
