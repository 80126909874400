import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import { Template } from "src/types";
import API from "src/utils/api";

// 1: 컴패니언, 2: 배너(320x50), 3: 배너(320x100), 4: 배너(300x250), 5: 전면, 6: 네이티브
export enum NativeTemplateType {
  COMPANION = 1,
  BANNER_SMALL = 2,
  BANNER_MEDIUM = 3,
  BANNER_LARGE = 4,
  INTERSTITIAL = 5,
  NATIVE = 6,
}

export type NativeTemplate = {
  id: Template;
  name: string;
  type: NativeTemplateType;
  assetids: number[];
  iurl: string;
};

// 여러개 선택 시 콤마로 구분 (1,2,3)
// 전달하지 않을 경우 전체 조회
export type NativeTemplateListParams = {
  type?: string | NativeTemplateType;
};

type NativeTemplateListQueryKey = ["nativeTemplateList", NativeTemplateListParams];

type NativeTemplateListQueryFn = QueryFunction<
  AxiosResponse<NativeTemplate[]>,
  NativeTemplateListQueryKey
>;

type NativeTemplateListQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<NativeTemplate[]>,
    AxiosError,
    AxiosResponse<NativeTemplate[]>,
    NativeTemplateListQueryKey
  >,
  "queryKey" | "queryFn"
>;

const useNativeTemplateList = (
  type?: NativeTemplateType | NativeTemplateType[],
  options?: NativeTemplateListQueryOptions
) => {
  const queryKey = useMemo<NativeTemplateListQueryKey>(() => {
    const _type = Array.isArray(type) ? type.join(",") : type;
    return ["nativeTemplateList", { type: _type }];
  }, [type]);

  const queryFn = useCallback<NativeTemplateListQueryFn>(({ queryKey }) => {
    const [, params] = queryKey;
    return API.console.get("/info/native-templates", {
      params,
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, options);

  const nativeTemplateList = useMemo(() => {
    return response ? response.data : [];
  }, [response]);

  return {
    nativeTemplateList,
    ...rest,
  };
};

export default useNativeTemplateList;
