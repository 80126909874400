import { css } from "@emotion/react";

export const accountSettingStyle = css`
  .content {
    display: flex;
    flex-direction: column;
    width: 600px;
    margin: auto;
    &:last-child {
      margin-top: 2rem;
    }

    .switch {
      margin: 0 0 2rem;
    }

    .submit {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 1rem;
    }
  }
`;
