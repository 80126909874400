import { css } from "@emotion/react";
import { grey, red } from "@mui/material/colors";

export const partnerBoardStyle = css`
  margin-top: 2rem;
`;

export const mediationMatchingInfoModalStyle = css`
  .dialog-content {
    #form {
      padding-top: 20px;
    }
  }
`;

export const mediationPlacementModalStyle = css`
  .ssp-tools {
    margin-top: 1rem;
  }
  .back-to-dsp {
    cursor: pointer;
    font-size: 16px;
    fill: ${grey[600]};
  }
  .active-dsp {
    cursor: pointer;
    font-size: 24px;
    fill: #52c41a;
  }
  .inactive-dsp {
    cursor: pointer;
    font-size: 24px;
    fill: ${red[500]};
  }
`;

export const editMediationModalStyle = css`
  .dialog-content {
    #edit-form,
    #edit-status-form {
      padding-top: 20px;
    }
  }
`;
