import { useMutation } from "react-query";
import { useCallback } from "react";

import API from "src/utils/api";
import useApiError from "../useApiError";
import { PlacementListByReportKeyPayload } from "./use-placement-list-by-report-key";

export type UpdateReportKeyData = PlacementListByReportKeyPayload & {
  placement_id: string;
};

export default function useUpdateReportKey() {
  const { handleError } = useApiError();

  const mutateFn = useCallback((data: UpdateReportKeyData) => {
    return API.default.patch("/mgmt/rptkeys/plcmts", data);
  }, []);

  const mutation = useMutation(mutateFn, {
    onError: handleError,
  });

  return mutation;
}
