import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import useApiError from "src/hooks/apis/useApiError";
import useToast from "src/hooks/useToast";
import { ROLE, UserRole } from "src/types";
import API from "src/utils/api";

interface Params {
  token?: string;
}

interface Response {
  code: 200 | 401 | 404 | 409;
  data?: {
    authority_id: number;
    company_id: number;
    company_name: string;
    created_at: number;
    is_registered: number;
    no: number;
    role: UserRole;
    sender_user_account_id: string;
    sender_user_id: number;
    token: string;
    user_account_id: string;
  };
  text: "ok" | "unauthorized" | "not-found" | "conflict";
}

// 초대 정보 조회
// 초대 정보가 없을 경우 404, not-found-invite-token 반환
// 이미 초대 처리가 완료된 경우 409, registered-user 반환
const useGetUserInvite = ({ token }: Params) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { handleError } = useApiError({
    400: (_error: AxiosError) => {
      toast.error("토큰이 유효하지 않습니다.");
      navigate("/login");
    },
    401: (_error: AxiosError) => toast.error("초대 승인 기간이 만료되었습니다."),
    409: (_error: AxiosError) => toast.error("이미 승인되었습니다."),
  });
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["users/useGetUserInvite", token],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/users/invite`, {
        params: { token },
      });
      return response.data.data;
    },
    { enabled: !!token, onError: handleError }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            authority_id: 0,
            company_id: 0,
            company_name: "",
            created_at: 0,
            is_registered: 0,
            no: 0,
            role: ROLE.UNREGISTERED,
            sender_user_account_id: "",
            sender_user_id: 0,
            token: "",
            user_account_id: "",
          },
    [data]
  );

  return { data: result, ...rest };
};

export default useGetUserInvite;
