import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { Status, WebTag } from "src/types";
import API from "src/utils/api";

interface Params {
  placementId: string;
  bidfloor?: number;
  bidfloorCurrency?: string;
  bidfloorStatus?: Status;
  cpcBidfloor?: number;
  cpcBidfloorCurrency?: string;
  cpcBidfloorStatus?: Status;
  customId?: string;
  mediaKey: string;
  name: string;
  sizeType: number;
  tagType: WebTag;
}

interface Response {
  code: 200 | 401 | 404 | 409;
  text: "ok" | "unauthorized" | "not-found" | "conflict";
}

// 플레이스먼트 정보 수정
// 매체에 같은 이름의 플레이스먼트가 있을 경우 이름 뒤에 _{EpochTime}을 붙인다.
const usePutWebPlacement = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({
      placementId,
      bidfloor,
      bidfloorCurrency,
      bidfloorStatus,
      cpcBidfloor,
      cpcBidfloorCurrency,
      cpcBidfloorStatus,
      customId,
      mediaKey,
      name,
      sizeType,
      tagType,
    }) => {
      const response: AxiosResponse<Response> = await API.default.put(
        `/placements/web/${placementId}`,
        {
          bidfloor: bidfloor,
          bidfloor_currency: bidfloorCurrency,
          bidfloor_status: bidfloorStatus,
          cpc_bidfloor: cpcBidfloor,
          cpc_bidfloor_currency: cpcBidfloorCurrency,
          cpc_bidfloor_status: cpcBidfloorStatus,
          custom_id: customId,
          media_key: mediaKey,
          name: name,
          size_type: sizeType,
          tag_type: tagType,
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("수정되었습니다.");
        invalidate(["placements/useGetWebPlacementDetail", "placements/useGetWebPlacementList"]);
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePutWebPlacement;
