import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";
import { AppPlatform, Campaign } from "src/types";

export type NpayTransaction = {
  ad_request_no: string;
  ad_type: Campaign;
  created_at: number;
  daou_tx_no: string;
  identifier: string;
  media_key: string;
  media_name: string;
  naver_id: string;
  placement_id: string;
  placement_name: string;
  platform: AppPlatform;
  point: number;
  ssp_tx_no: string;
  status: NpayTransactionStatus;
  updated_at: number;
  usn: string;
};

export type NpayTransactionFields = keyof NpayTransaction | `-${keyof NpayTransaction}`;

export type NpayTransactionListParams = {
  media_key: string;
  usn: string;
  placement_id?: string;
  since: string; // yyyyMMdd
  until: string; // yyyyMMdd
  page_no?: number;
  page_size?: number;
  orders?: NpayTransactionFields[];
};

export enum NpayTransactionStatus {
  Requested = 0,
  Fail = 25,
  Canceled = 30,
  Completed = 99,
}

type NpayTransactionListData = {
  code: number;
  text: string;
  data: {
    page_no: number;
    page_size: number;
    pages: number;
    rows: NpayTransaction[];
  };
};

type NpayTransactionListQueryKey = ["operation/npay/transaction-list", NpayTransactionListParams];

type NpayTransactionListQueryFn = QueryFunction<
  AxiosResponse<NpayTransactionListData>,
  NpayTransactionListQueryKey
>;

type NpayTransactionListQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<NpayTransactionListData>,
    AxiosError,
    AxiosResponse<NpayTransactionListData>,
    NpayTransactionListQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: NpayTransactionListData = {
  code: 200,
  text: "OK",
  data: {
    page_no: 1,
    page_size: 1,
    pages: 1,
    rows: [],
  },
};

export default function useNpayTransactionList(
  params: NpayTransactionListParams,
  options?: NpayTransactionListQueryOptions
) {
  const { handleError } = useApiError();

  const queryKey = useMemo<NpayTransactionListQueryKey>(
    () => ["operation/npay/transaction-list", params],
    [params]
  );

  const queryFn = useCallback<NpayTransactionListQueryFn>((context) => {
    const [, params] = context.queryKey;
    const cookie = parseCookies();

    return API.consoleV3.get("/v3/naverpay/transactions", {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
      params,
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
