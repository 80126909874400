import { css } from "@emotion/react";
import { grey, teal } from "@mui/material/colors";

// 수입
export const revenueStyle = css`
  .content {
    small {
      font-size: 1rem;
      margin-left: 0.5rem;
      color: ${grey[500]};
    }

    .month-revenue {
      font-weight: 500;
      color: ${teal[500]};
      margin-top: 1rem;
    }

    .divider {
      margin: 2rem 0;
    }

    .description {
      color: ${grey[600]};
      line-height: 1.5;
    }
  }
`;
