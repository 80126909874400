import { css } from "@emotion/react";
import { Container, Typography, Paper, Button, Grid } from "@mui/material";
import { FormEvent, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TextField } from "src/components/commons";
import useNpayCsDetail from "src/hooks/apis/npay/use-npay-cs-detail";
import { NpayCsStatus } from "src/hooks/apis/npay/use-npay-cs-list";
import useReplyNpayCs from "src/hooks/apis/npay/use-replay-npay-cs";
import useToast from "src/hooks/useToast";

const responseFormStyle = css`
  .response {
    margin-top: 1rem;
  }
  .submit {
    display: flex;
    justify-content: flex-end;
  }
`;

export default function ResponseForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [value, setValue] = useState("");

  const query = useNpayCsDetail(
    { id: id as string },
    {
      onError: (error) => {
        if (error.response?.status === 404) {
          navigate("/404", { replace: true });
        }
      },
      onSuccess: (response) => {
        const { data } = response.data;

        if (data) {
          setValue(data.response_content || "");
        }
      },
    }
  );

  const { mutate: replyNpayCs, isLoading: isUpdating } = useReplyNpayCs();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const trimmedValue = value.trim();

    if (trimmedValue.length <= 2) {
      toast.error("2글자 이상 작성해주세요.");
      return;
    }

    if (query.data?.status === NpayCsStatus.Completed) {
      toast.error("이미 답변 완료된 문의입니다.");
      return;
    }

    replyNpayCs(
      { id: id as string, response_content: value },
      {
        onSuccess: async () => {
          await query.refetch();
          toast.success("저장되었습니다.");
        },
      }
    );
  };

  return (
    <Container component="section" className="ssp-section" css={responseFormStyle} maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        문의 내용 및 답변
      </Typography>
      <Paper className="content" elevation={2}>
        <form id="service-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="문의 내용"
                value={query.data ? query.data.inquiry_content : ""}
                required
                readOnly
                disabled
              />
            </Grid>
            <Grid item xs={12} className="response">
              <TextField
                multiline
                minRows={8}
                fullWidth
                className="field"
                label="답변"
                placeholder="답변을 입력해주세요."
                required
                readOnly={query.data?.status === NpayCsStatus.Completed}
                disabled={query.data?.status === NpayCsStatus.Completed}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </Grid>
            {query.data?.status === NpayCsStatus.InProgress && (
              <Grid item xs={12} className="submit">
                <Button
                  type="submit"
                  disabled={isUpdating || !value.trim() || value.trim().length < 2}
                >
                  전송
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}
