import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import { MouseEvent, useCallback, useMemo } from "react";
import { TextField } from "src/components/commons";
import { MatchKeyInfo, ThirdpartyInfo } from "src/hooks/apis/placements/useGetWebThirdpartyList";
import usePutWebThirdparty from "src/hooks/apis/placements/usePutWebThirdparty";
import usePutWebThirdpartyPassback from "src/hooks/apis/placements/usePutWebThirdpartyPassback";
import useDeleteMatchInfo from "src/hooks/apis/thirdparties/useDeleteMatchInfo";
import { THIRDPARTY, Thirdparty } from "src/types";
import { getHelperText, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { dspMatchingInfoModalStyle } from "./styles";

interface MatchingInfoModalProps extends ThirdpartyInfo {
  placementId: string;
  thirdpartyType?: Thirdparty;
  onClose: () => void;
  open: { key: number; isOpen: boolean };
}

const MatchingInfoModal = ({
  placementId,
  thirdpartyType,
  onClose,
  open,
  match_key,
  match_key_info,
  id,
  passback_script,
}: MatchingInfoModalProps) => {
  const { mutate: updateMatchKeyInfo } = usePutWebThirdparty();
  const { mutate: deleteMatchKeyInfo } = useDeleteMatchInfo();
  const { mutate: updatePassbackScript } = usePutWebThirdpartyPassback();

  const makeDefaultValue = useCallback(
    (type: "string" | "float" | "integer" | "number" | "boolean") => {
      if (type === "string") return "";
      if (type === "boolean") return false;
      return "";
    },
    []
  );
  const formInfo = useMemo(() => {
    if (match_key_info && match_key_info.length > 0) {
      return match_key_info.reduce((acc: MatchKeyInfo, cur) => {
        return { ...acc, ...cur };
      }, {});
    }
    return;
  }, [match_key_info]);

  const initialValues = useMemo(() => {
    if (formInfo)
      return Object.entries(formInfo).reduce(
        (acc: Record<string, unknown>, [key, condition]) => {
          acc[key] = (match_key && match_key[key]) || makeDefaultValue(condition.type);
          return acc;
        },
        { passbackScript: passback_script || "" }
      );
    return { passbackScript: passback_script || "" };
  }, [formInfo, makeDefaultValue, match_key, passback_script]);

  const validationData = useMemo(() => {
    if (formInfo)
      return Object.entries(formInfo).reduce(
        (acc: Record<string, yup.BaseSchema>, [key, condition]) => {
          const { type, required } = condition;
          if (type === "float" || type === "integer" || type === "number") {
            acc = {
              ...acc,
              [key]: yup.string().test(key, "숫자를 입력해주세요.", (value = "") => !isNaN(+value)),
            };
          } else {
            acc = { ...acc, [key]: yup[type]() };
          }
          if (required) {
            acc = { ...acc, [key]: acc[key].required(`${key}을(를) 설정해주세요.`) };
          }
          // if (related && related.length > 0) {
          //   related.forEach((relation) => {
          //     acc = {
          //       ...acc,
          //       [key]: acc[key].when(relation, {
          //         is: (v: unknown) => !!v,
          //         then: acc[key].required(`${key}은(는) ${relation}과 함께 설정되어야 합니다.`),
          //         otherwise: acc[key],
          //       }),
          //     };
          //   });
          // }
          return acc;
        },
        { passbackScript: yup.string() }
      );
    return {};
  }, [formInfo]);

  const validationSchema = yup.object(validationData);

  const { getFieldProps, handleSubmit, touched, errors, setFieldValue, handleBlur, values } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: ({ passbackScript, ...values }) => {
        Object.keys(values).length > 0 &&
          updateMatchKeyInfo({
            placementId,
            thirdpartyId: id,
            matchKey: values,
          });
        passbackScript &&
          updatePassbackScript({
            placementId,
            thirdpartyId: id,
            passbackScript: passbackScript as string,
          });
        onClose();
      },
    });

  const onDelete = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      deleteMatchKeyInfo({
        thirdpartyId: id,
        placementId: placementId,
      });
      onClose();
    },
    [deleteMatchKeyInfo, id, onClose, placementId]
  );

  const handleChangeTextField = useCallback(
    (key: string, value: string) => {
      const tabNewLineRegex = /[\t\n]+/g;
      const trimmedValue = value.trim().replace(tabNewLineRegex, "");
      setFieldValue(key, trimmedValue);
    },
    [setFieldValue]
  );

  return (
    <Dialog
      css={dspMatchingInfoModalStyle}
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-dsp-matching-info"
      aria-describedby="set dsp matching info"
    >
      <DialogTitle id="dialog-title">매칭정보 변경</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {formInfo &&
              Object.keys(formInfo).map((key) => {
                if (formInfo[key].type === "boolean")
                  return (
                    <Grid key={key} item xs={12}>
                      <FormControlLabel
                        className="switch"
                        control={<Switch color="primary" />}
                        label={key}
                        labelPlacement="start"
                        {...getFieldProps(key)}
                      />
                    </Grid>
                  );
                return (
                  <Grid key={key} item xs={12}>
                    <TextField
                      className="field"
                      label={key}
                      placeholder={`${key}을(를) 입력하세요.`}
                      required
                      value={values[key]}
                      onChange={(e) => handleChangeTextField(key, e.target.value)}
                      onBlur={handleBlur(key)}
                      error={shouldErrorShows(key, touched, errors)}
                      helperText={getHelperText(key, touched, errors)}
                    />
                  </Grid>
                );
              })}
            {/* 미디에이션일 경우 passbackScript 입력필드 추가 */}
            {thirdpartyType === THIRDPARTY.WEB_MEDIATION && (
              <Grid item xs={12}>
                <TextField
                  multiline
                  className="field"
                  label="Passback Script"
                  placeholder="Passback Script를 입력하세요."
                  {...getFieldProps("passbackScript")}
                  error={shouldErrorShows("passbackScript", touched, errors)}
                  helperText={getHelperText("passbackScript", touched, errors)}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        {match_key ? (
          <Button onClick={onDelete} color="error">
            삭제
          </Button>
        ) : (
          <Button onClick={onClose} color="inherit">
            취소
          </Button>
        )}
        <Button form="form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MatchingInfoModal;
