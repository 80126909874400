import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";

interface Params {
  search?: string;
  pageNo?: number;
  pageSize?: number;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    items: {
      company_key: string;
      company_name: string;
      email: string;
      no: number;
    }[];
    pages: number;
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 정산메일 발송 리스트 조회
const useGetEmails = ({ search, pageNo = 1, pageSize = 30 }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["payments/useGetEmails", search, pageNo, pageSize],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/payments/emails`, {
        params: {
          q: search,
          page_no: pageNo,
          page_size: pageSize,
        },
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => (data ? data : { items: [], pages: 0 }), [data]);

  return { data: result, ...rest };
};

export default useGetEmails;
