import { AxiosError, AxiosResponse } from "axios";
import { destroyCookie } from "nookies";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import useApiError from "src/hooks/apis/useApiError";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Response {
  code: 200 | 400 | 401 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "internal-server-error";
}

// 로그아웃
const usePutSignOut = () => {
  const navigate = useNavigate();
  const { handleError } = useApiError();
  const toast = useToast();
  const [, setCompany] = useRecoilState(viewerCompany);
  const result = useMutation<Response, AxiosError>(
    async () => {
      const response: AxiosResponse<Response> = await API.default.put("/auth/signout");
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("정상적으로 로그아웃되었습니다.");
        setCompany({ id: 0, key: "" });
        destroyCookie(null, "access_token", { path: "/" });
        destroyCookie(null, "expires", { path: "/" });
        destroyCookie(null, "viewerCompany", { path: "/" });

        navigate("/login");
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePutSignOut;
