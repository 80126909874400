import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";
import { Campaign, Status } from "src/types";

export type NpayPlacement = {
  ad_type: Campaign;
  company_key: string;
  company_name: string;
  media_key: string;
  media_name: string;
  id: string;
  name: string;
  status: Status;
};

export type NpayPlacementFields = keyof NpayPlacement | `-${keyof NpayPlacement}`;

export type NpayPlacementListParams = {
  media_key: string;
  query?: string;
  page_no?: number;
  page_size?: number;
  status?: Status;
  orders?: NpayPlacementFields[];
};

type NpayPlacementListData = {
  code: number;
  text: string;
  data: {
    page_no: number;
    page_size: number;
    pages: number;
    rows: NpayPlacement[];
  };
};

type NpayPlacementListQueryKey = ["operation/npay/placements-list", NpayPlacementListParams];

type NpayPlacementListQueryFn = QueryFunction<
  AxiosResponse<NpayPlacementListData>,
  NpayPlacementListQueryKey
>;

type NpayPlacementListQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<NpayPlacementListData>,
    AxiosError,
    AxiosResponse<NpayPlacementListData>,
    NpayPlacementListQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: NpayPlacementListData = {
  code: 200,
  text: "OK",
  data: {
    page_no: 1,
    page_size: 1,
    pages: 1,
    rows: [],
  },
};

export default function useNpayPlacementList(
  params: NpayPlacementListParams,
  options?: NpayPlacementListQueryOptions
) {
  const { handleError } = useApiError();

  const queryKey = useMemo<NpayPlacementListQueryKey>(
    () => ["operation/npay/placements-list", params],
    [params]
  );

  const queryFn = useCallback<NpayPlacementListQueryFn>((context) => {
    const [, { media_key, ...params }] = context.queryKey;
    const cookie = parseCookies();

    return API.consoleV3.get(`/v3/naverpay/media/${media_key}/placements`, {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
      params,
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
