import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  email: string;
  command: 0 | 1;
}

interface Response {
  code: 200 | 400 | 404 | 409;
  data?: {
    code: string;
  };
  text: "ok" | "bad-request" | "not-found" | "conflict";
}

/*
    사용자 등록 여부를 확인하고 인증코드 발송
    인증코드는 7자리 숫자로 구성되고, 30분 후에 만료된다.
    command [0] 회원 가입 시 등록여부를 확인하고, 등록이 되어 있는 사용자의 경우 409, registered-user를 반환한다.
    command [1] ID 찾기는 이미 등록된 사용자의 본인 확인을 하기 때문에 등록 여부를 확인하지 않는다.
*/
const useGetVerify = () => {
  const { handleError } = useApiError({
    409: (_error: AxiosError) => null,
  });
  const toast = useToast();
  const invalidate = useInvalidateQueries();

  const result = useMutation<Response["data"], AxiosError, Params>(
    async ({ email, command }) => {
      const response: AxiosResponse<Response> = await API.default.get(`/auth/verify`, {
        params: { email, command },
      });
      return response.data.data;
    },
    {
      onSuccess: () => {
        toast.success("인증번호가 발송되었습니다.");
        invalidate();
      },
      onError: handleError,
    }
  );

  return result;
};

export default useGetVerify;
