import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  domainListIds: number[];
}

interface Response {
  code: 200 | 400 | 401 | 404 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "not-found" | "internal-server-error";
}

// 차단 도메인 그룹 삭제
const useDeleteDomains = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ domainListIds }) => {
      const response: AxiosResponse<Response> = await API.default.delete("/blocklist/domains", {
        data: {
          domain_list_ids: domainListIds,
        },
      });
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("삭제되었습니다.");
        invalidate("blocklist/useGetDomains");
      },
    }
  );

  return result;
};

export default useDeleteDomains;
