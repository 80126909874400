import { AxiosError, AxiosResponse } from "axios";
import { Moment } from "moment";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import API from "src/utils/api";
import { getEcpm } from "src/utils/calculator";

interface Params {
  since: Moment;
  until: Moment;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    apps: {
      click: number;
      ecpm: number;
      impression: number;
      media_key: string;
      media_name: string;
      request: number;
      response: number;
      revenue: number;
    }[];
    webs: {
      click: number;
      ecpm: number;
      impression: number;
      media_key: string;
      media_name: string;
      request: number;
      response: number;
      revenue: number;
    }[];
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 전사 대시보드
// since, until을 전달하지 않을 경우 -7일 ~ -1일 조회
const useGetEnterpriseDashboard = ({ since, until }: Params) => {
  const { handleError } = useApiError();
  const start = useMemo(() => since.format("YYYY-MM-DD"), [since]);
  const end = useMemo(() => until.format("YYYY-MM-DD"), [until]);
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["dashboard/useGetEnterpriseDashboard", start, end],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/dashboard/enterprise`, {
        params: {
          since: start,
          until: end,
        },
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => {
    if (data) {
      const appCardData = data.apps.reduce(
        (acc, cur) => {
          acc.request += cur.request;
          acc.response += cur.response;
          acc.impression += cur.impression;
          acc.click += cur.click;
          acc.revenue += cur.revenue;
          return acc;
        },
        {
          request: 0,
          response: 0,
          impression: 0,
          click: 0,
          ecpm: 0,
          revenue: 0,
        }
      );

      appCardData.ecpm = getEcpm(appCardData.impression, appCardData.revenue);

      const webCardData = data.webs.reduce(
        (acc, cur) => {
          acc.request += cur.request;
          acc.response += cur.response;
          acc.impression += cur.impression;
          acc.click += cur.click;
          acc.revenue += cur.revenue;
          return acc;
        },
        {
          request: 0,
          response: 0,
          impression: 0,
          click: 0,
          ecpm: 0,
          revenue: 0,
        }
      );

      webCardData.ecpm = getEcpm(webCardData.impression, webCardData.revenue);

      return { data, cardData: { app: appCardData, web: webCardData } };
    }

    return {
      data: { apps: [], webs: [] },
      cardData: {
        app: {
          request: 0,
          response: 0,
          impression: 0,
          click: 0,
          ecpm: 0,
          revenue: 0,
        },
        web: {
          request: 0,
          response: 0,
          impression: 0,
          click: 0,
          ecpm: 0,
          revenue: 0,
        },
      },
    };
  }, [data]);

  return { ...result, ...rest };
};

export default useGetEnterpriseDashboard;
