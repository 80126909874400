import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  thirdpartyId: number;
  matchKey: Record<string, unknown>;
  placementId: string;
}

interface Response {
  code: 200 | 400 | 401 | 409 | 500;
  text: "ok" | "bad-request" | "unauthorized" | "conflict" | "internal-server-error";
}

// 플레이스먼트 매칭정보 등록 및 변경
// 웹은 미디에이션 매칭 정보가 필요없음… (패스백 스크립트)
const usePutMediationMatch = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ thirdpartyId, matchKey, placementId }) => {
      const response: AxiosResponse<Response> = await API.default.put(
        `/thirdparties/mediations/${thirdpartyId}/match-info`,
        { match_key: matchKey, placement_id: placementId }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("매칭정보가 수정되었습니다.");
        invalidate("thirdparties/usePostMediationMatch");
      },
      onError: handleError,
    }
  );

  return result;
};

export default usePutMediationMatch;
