import { Box, Button, Grid } from "@mui/material";
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  GridApi,
  ICellRendererParams,
  IServerSideGetRowsParams,
  RowClassParams,
  ValueFormatterParams,
} from "ag-grid-enterprise";
import moment, { Moment } from "moment";
import { MouseEvent, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import DataFormat from "src/assets/formats";
import { BasicTable, PopoverButton } from "src/components/commons";
import useGetCriteria from "src/hooks/apis/payments/useGetCriteria";
import useGetExcel from "src/hooks/apis/payments/useGetExcel";
import useGetPaymentDetail from "src/hooks/apis/payments/useGetPaymentDetail";
import useGetPayments from "src/hooks/apis/payments/useGetPayments";
import usePostClosing from "src/hooks/apis/payments/usePostClosing";
import usePostSendEmail from "src/hooks/apis/payments/usePostSendEmail";
import usePutBatchConfirm from "src/hooks/apis/payments/usePutBatchConfirm";
import useOpenModal from "src/hooks/useOpenModal";
import useToast from "src/hooks/useToast";
import {
  Company,
  COMPANY_ALIAS,
  PAYMENT_STATUS,
  Settlement,
  SETTLEMENT_ALIAS,
  TaxBillStatus,
  TAX_BILL_STATUS,
  TAX_BILL_STATUS_ALIAS,
} from "src/types";
import CriteriaSettingModal from "./CriteriaSettingModal";
import { settlementBoardStyle } from "./styles";
import TaxBillConfirmationModal from "./TaxBillConfirmationModal";
import TaxBillModal from "./TaxBillModal";

export interface SettlementBoardProps {
  search: string;
  date: Moment;
  taxBillStatus: TaxBillStatus[];
  companyTypes: Company[];
}

const SettlementBoard = ({ search, date, companyTypes, taxBillStatus }: SettlementBoardProps) => {
  const toast = useToast();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [paymentId, setPaymentId] = useState<number>();
  const [params, setParams] = useState<IServerSideGetRowsParams>();
  const [openCriteraiModal, onShowCriteriaModal, onCloseCriteriaMoal] = useOpenModal(null);

  const { data: criteriaData } = useGetCriteria({ month: date.format("YYYYMM") });
  const { data } = useGetPayments({
    month: date.format("YYYYMM"),
    name: search,
    status: taxBillStatus,
    types: companyTypes,
    pageNo: 1,
  });
  const { data: detailData } = useGetPaymentDetail({ paymentId });

  // 메일 발송
  const { mutate: sendMail } = usePostSendEmail();
  const onSendEmail = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      sendMail({ month: date.format("YYYYMM") });
    },
    [date, sendMail]
  );
  const isDisabledEmail = useMemo(
    () => !!criteriaData.sent_mail || !criteriaData.expiration_date || criteriaData.is_closing,
    [criteriaData.expiration_date, criteriaData.is_closing, criteriaData.sent_mail]
  );
  const emailLabel = useMemo(
    () =>
      criteriaData.sent_mail
        ? `메일 발송 (${moment(criteriaData.sent_mail_datetime * 1000).format(
            "YYYY.MM.DD HH:mm:ss"
          )})`
        : "메일 발송",
    [criteriaData.sent_mail, criteriaData.sent_mail_datetime]
  );

  // 일괄 발행
  const { mutate: batchConfirm } = usePutBatchConfirm();
  const onBatchConfirm = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      const selectedNodes = gridApi?.getSelectedNodes() ?? [];
      if (selectedNodes.length <= 0) {
        toast.warning("일괄 발행하려는 업체를 먼저 선택해주세요.");
        return;
      }
      batchConfirm({ paymentIds: selectedNodes.map((v) => v.data.payment_id) });
    },
    [batchConfirm, gridApi, toast]
  );
  const isDisabledConfirm = useMemo(
    () => !criteriaData.expiration_date,
    [criteriaData.expiration_date]
  );

  // 마감
  const { mutate: close } = usePostClosing();
  const onClose = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      close({ month: date.format("YYYYMM") });
    },
    [close, date]
  );
  const isDisabledClose = useMemo(
    () => criteriaData.is_closing || !criteriaData.expiration_date,
    [criteriaData.expiration_date, criteriaData.is_closing]
  );

  // 엑셀 다운로드
  const { fetch } = useGetExcel({ month: date.format("YYYYMM") });
  const onExport = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      fetch();
    },
    [fetch]
  );

  useEffect(() => {
    if (gridApi) {
      const memo: { [key: string]: number } = {};
      const dataSource = {
        getRows: (rowsParams: IServerSideGetRowsParams) => {
          const { api, request, parentNode, success } = rowsParams;

          // 상세보기 OFF
          if (request.groupKeys.length <= 0) {
            const settlementData = data.summary.slice(request.startRow);

            success({
              rowData: settlementData.map((v) => ({ ...v, group: true })),
              rowCount: (request.endRow || 0) >= data.summary.length ? data.summary.length : -1,
            });
          } else {
            // 상세보기 ON
            if (memo[request.groupKeys[0]] !== undefined) {
              total.tax_bill_sum_revenue =
                total.tax_bill_sum_revenue -
                memo[request.groupKeys[0]] +
                parentNode.data.tax_bill_sum_revenue;

              api.setPinnedTopRowData([total]);
            }

            memo[request.groupKeys[0]] = parentNode.data.tax_bill_sum_revenue;
            const paymentId = parseInt(request.groupKeys[0]);
            setPaymentId(paymentId);
            setParams(rowsParams);
          }
        },
      };

      const total = {
        media_sum_revenue: data.summary.reduce(
          (acc, { media_sum_revenue }) => acc + +media_sum_revenue,
          0
        ),
        margin_sum_revenue_krw: data.summary.reduce(
          (acc, { margin_sum_revenue_krw }) => acc + +margin_sum_revenue_krw,
          0
        ),
        tax_bill_sum_revenue: data.summary.reduce(
          (acc, { tax_bill_sum_revenue }) => acc + +tax_bill_sum_revenue,
          0
        ),
      };

      gridApi.setServerSideDatasource(dataSource);
      gridApi.setPinnedTopRowData([total]);
    }
  }, [data.summary, gridApi]);

  useEffect(() => {
    if (params && detailData && detailData.length > 0) {
      const { request, success } = params;
      success({
        rowData: detailData,
        rowCount: (request.endRow || 0) >= detailData.length ? detailData.length : -1,
      });
    }
  }, [detailData, params]);

  return (
    <Box css={settlementBoardStyle}>
      <Grid className="ssp-tools" container spacing={2}>
        <Grid item xs={6} className="left-tools">
          <Button variant="text" onClick={onShowCriteriaModal}>
            정산 기준 설정
          </Button>
          <PopoverButton
            variant="text"
            disabled={isDisabledEmail}
            cancelButtonProps={{ color: "inherit", children: "취소" }}
            confirmButtonProps={{ color: "primary", children: "발송", onClick: onSendEmail }}
            popoverContent={`자동 정산 업체에 대해 세금계산서 발행 안내 메일을
            발송하시겠습니까?`}
          >
            {emailLabel}
          </PopoverButton>
        </Grid>
        <Grid item xs={6} className="right-tools">
          {!criteriaData.is_closing && (
            <PopoverButton
              variant="text"
              disabled={isDisabledConfirm}
              cancelButtonProps={{ color: "inherit", children: "취소" }}
              confirmButtonProps={{ color: "primary", children: "확인", onClick: onBatchConfirm }}
              popoverContent="선택된 업체들의 세금계산서 발행을 완료 처리하시겠습니까?"
            >
              발행 확인
            </PopoverButton>
          )}
          <PopoverButton
            variant="text"
            disabled={isDisabledClose}
            cancelButtonProps={{ color: "inherit", children: "취소" }}
            confirmButtonProps={{ color: "primary", children: "확인", onClick: onClose }}
            popoverContent={`해당 월 세금계산서 발행 확인을 마감하시겠습니까?
            발행 완료되지 않은 내역은 이월됩니다.`}
          >
            마감
          </PopoverButton>
          <Button variant="text" onClick={onExport}>
            엑셀 다운로드
          </Button>
        </Grid>
      </Grid>
      <BasicTable
        rowSelection="multiple"
        rowModelType="serverSide"
        cacheBlockSize={30}
        suppressAggFuncInHeader
        suppressRowClickSelection
        treeData
        animateRows
        serverSideStoreType="partial"
        groupDisplayType="custom"
        getDataPath={(data) => data.orgHierarchy}
        isServerSideGroup={(data) => data.group}
        getServerSideGroupKey={(data) => data.payment_id.toString()}
        getRowId={(params) => `${params.data.group ? "P" : "C"}${params.data.payment_id}`}
        onGridReady={(e) => setGridApi(e.api)}
        getRowStyle={(params: RowClassParams) => {
          if (params.node.rowPinned) {
            return { fontWeight: 500 };
          }
        }}
        columnDefs={getColumnDefs(criteriaData.is_closing)}
      />
      {openCriteraiModal.isOpen && (
        <CriteriaSettingModal
          date={date}
          isOpen={openCriteraiModal.isOpen}
          onClose={onCloseCriteriaMoal}
        />
      )}
    </Box>
  );
};

const getColumnDefs = (isClosed: boolean): (ColDef | ColGroupDef)[] => [
  {
    headerName: "업체명",
    field: "company_name",
    minWidth: 250,
    cellRenderer: "agGroupCellRenderer",
    showRowGroup: true,
    sortable: true,
    pinned: true,
    cellRendererSelector: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return { component: () => <>합계</> };
      }
    },
    cellStyle: (param: CellClassParams) => {
      if (!param.value)
        return {
          textAlign: "center",
        };
    },
    cellRendererParams: {
      checkbox: (params: ICellRendererParams) => {
        if (
          !(
            params.data.payment_status === PAYMENT_STATUS.DEFAULT ||
            params.data.payment_status === PAYMENT_STATUS.WAITING ||
            params.data.tax_bill_status === TAX_BILL_STATUS.DEFAULT
          )
        )
          return false;
        return params.node.group === true;
      },
    },
  },
  {
    headerName: "업체 분류",
    field: "company_type",
    sortable: true,
    minWidth: 120,
    valueFormatter: (params: ValueFormatterParams) => {
      const type = params.data.company_type as Company;
      return COMPANY_ALIAS[type];
    },
  },
  {
    headerName: "수입 집계 기간",
    sortable: true,
    minWidth: 200,
    cellStyle: { textAlign: "center" },
    valueFormatter: (params: ValueFormatterParams) => {
      const startDate = params.data.start_date;
      const endDate = params.data.end_date;

      if (startDate && endDate) {
        return `${moment(startDate).format("YYYY.MM.DD")} ~ ${moment(endDate).format(
          "YYYY.MM.DD"
        )}`;
      }
      return "";
    },
  },
  {
    headerName: "기준 환율",
    field: "exchange_rate",
    sortable: true,
    minWidth: 150,
    cellStyle: { textAlign: "right" },
    valueFormatter: (params: ValueFormatterParams) => {
      const exchangeRate = params.data.exchange_rate || 0;
      if (exchangeRate) return `${DataFormat.exchangeRate.formatter(exchangeRate)}`;
      return "";
    },
  },
  {
    headerName: "정산 방식",
    field: "payment_type",
    sortable: true,
    minWidth: 150,
    valueFormatter: (params: ValueFormatterParams) => {
      const paymentType = params.data.payment_type as Settlement;
      if (paymentType) return SETTLEMENT_ALIAS[paymentType];
      return "";
    },
  },
  {
    headerName: "순매체비",
    field: "media_sum_revenue",
    sortable: true,
    minWidth: 250,
    cellStyle: { textAlign: "right" },
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.node?.rowPinned === "top") {
        const revenue = params.data.media_sum_revenue || 0;
        return `${DataFormat.revenue.formatter(revenue)}`;
      }
      if (params.data.media_sum_revenue !== undefined) {
        const summary = params.data.media_sum_revenue;
        return `${DataFormat.revenue.formatter(summary)}`;
      }
      if (params.data.media_revenue !== undefined && params.data.exchange_rate !== undefined) {
        const revenue = params.data.media_revenue;
        const exchangeRage = params.data.exchange_rate;

        return `${DataFormat.revenue.formatter(revenue)} (${DataFormat.taxBillRevenue.formatter(
          exchangeRage * revenue
        )})`;
      }
      return "";
    },
  },
  {
    headerName: "운영 수수료",
    field: "payment_commission_rate",
    sortable: true,
    minWidth: 200,
    cellStyle: { textAlign: "right" },
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.node?.rowPinned === "top") {
        const commisionRate = params.data.margin_sum_revenue_krw || 0;
        return `${DataFormat.taxBillRevenue.formatter(commisionRate || 0)}`;
      }
      if (params.data.margin_sum_revenue_krw !== undefined) {
        const summary = params.data.margin_sum_revenue_krw;
        return `${DataFormat.taxBillRevenue.formatter(summary || 0)}`;
      }
      if (
        params.data.margin_revenue_krw !== undefined &&
        params.data.payment_commission_rate !== undefined
      ) {
        const revenue = params.data.margin_revenue_krw;
        const commissionRate = params.data.payment_commission_rate;
        return `${DataFormat.taxBillRevenue.formatter(
          revenue || 0
        )} (${DataFormat.commissionRate.formatter(commissionRate)})`;
      }
      return "";
    },
  },
  {
    headerName: "세금계산서 발행액",
    field: "tax_bill_revenue",
    sortable: true,
    minWidth: 200,
    cellStyle: { textAlign: "right" },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node?.rowPinned === "top") {
        const taxBillSumRevenue = params.data.tax_bill_sum_revenue || 0;
        return `${DataFormat.taxBillRevenue.formatter(taxBillSumRevenue || 0)}`;
      }
      if (params.data.tax_bill_sum_revenue !== undefined) {
        const summary = params.data.tax_bill_sum_revenue;
        return `${DataFormat.taxBillRevenue.formatter(summary || 0)}`;
      }
      if (params.data.tax_bill_revenue !== undefined) {
        const revenue = params.data.tax_bill_revenue || 0;
        if (isClosed && params.data.isConfirmed) {
          return (
            <TaxBillButton {...params} field="tax_bill_status" which="confirmed">
              {DataFormat.taxBillRevenue.formatter(revenue || 0)}
            </TaxBillButton>
          );
        }
        return (
          <TaxBillButton {...params} field="tax_bill_revenue">
            {DataFormat.taxBillRevenue.formatter(revenue || 0)}
          </TaxBillButton>
        );
      }
      return "";
    },
  },
  {
    headerName: "발행 상태",
    field: "tax_bill_status",
    sortable: true,
    minWidth: 260,
    cellRenderer: (params: ICellRendererParams) => {
      const status = params.data.tax_bill_status as TaxBillStatus;
      if (params.data.group) {
        switch (status) {
          case TAX_BILL_STATUS.DEFAULT:
            return isClosed ? null : (
              <TaxBillButton {...params} field="tax_bill_status" which="confirmation">
                발행확인
              </TaxBillButton>
            );
          case TAX_BILL_STATUS.CONFIRMED: // 발행
            return `${TAX_BILL_STATUS_ALIAS[status]} ${moment(
              params.data.tax_bill_date * 1000
            ).format("YYYY.MM.DD HH:mm")} (${params.data.tax_bill_user_name})`;
          case TAX_BILL_STATUS.CARRIED: // 이월
            return TAX_BILL_STATUS_ALIAS[status];
        }
      }
      switch (status) {
        case TAX_BILL_STATUS.CARRIED:
          return TAX_BILL_STATUS_ALIAS[status]; // 이월
        default:
          return null;
      }
    },
  },
];

interface TaxBillButtonProps extends ICellRendererParams {
  children: ReactNode;
  field: "tax_bill_revenue" | "tax_bill_status";
  which?: "confirmed" | "confirmation";
}

export function TaxBillButton({ children, field, which, ...props }: TaxBillButtonProps) {
  const [openModal, onShowModal, onCloseModal] = useOpenModal(props.data.payment_id);

  return (
    <>
      <Button color="primary" variant="text" size="small" onClick={onShowModal}>
        {children}
      </Button>
      {/* 세금 계산서 발행액 확인 및 수정 */}
      {openModal.isOpen && field === "tax_bill_revenue" && (
        <TaxBillModal open={openModal} onClose={onCloseModal} field={field} {...props} />
      )}
      {/* 세금 계산서 발행액 확인 */}
      {openModal.isOpen && field === "tax_bill_status" && which === "confirmed" && (
        <TaxBillModal
          open={openModal}
          onClose={onCloseModal}
          field={field}
          which={which}
          {...props}
        />
      )}
      {/* 발행 확인 */}
      {openModal.isOpen && field === "tax_bill_status" && which === "confirmation" && (
        <TaxBillConfirmationModal
          open={openModal}
          onClose={onCloseModal}
          field={field}
          which={which}
          {...props}
        />
      )}
    </>
  );
}

export default SettlementBoard;
