import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import { ChangeEvent, useCallback, useMemo } from "react";
import { TextField } from "src/components/commons";
import useGetUserDetail from "src/hooks/apis/users/useGetUserDetail";
import usePatchUser from "src/hooks/apis/users/usePatchUser";
import { ROLE_ALIAS } from "src/types";
import { getHelperText, phoneFormatter, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";

const UserInfoForm = () => {
  const { data } = useGetUserDetail({ userId: "me" });
  const { mutate } = usePatchUser();

  const initialValues = useMemo(
    () => ({
      accountId: data.user.account_id || "",
      name: data.user.name || "",
      department: data.user.department || "",
      role: ROLE_ALIAS[data.user.role] || "",
      phoneNo: phoneFormatter(data.user.phone_no || "") || "",
      createdAt: moment((data.user.created_at || 0) * 1000).format("YYYY-MM-DD"),
    }),
    [
      data.user.account_id,
      data.user.created_at,
      data.user.department,
      data.user.name,
      data.user.phone_no,
      data.user.role,
    ]
  );

  const validationSchema = yup.object({
    name: yup.string().required("이름을 입력해주세요."),
    department: yup.string().required("부서명을 입력해주세요."),
    phoneNo: yup
      .string()
      .required("연락처를 입력해주세요.")
      .matches(REG_EXP.phone, "올바른 연락처를 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, setFieldValue, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate({
        userId: data.user.id,
        name: values.name,
        phoneNo: values.phoneNo.replace(/-/g, ""),
        department: values.department,
      });
    },
  });

  const onChangePhoneNo = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replace(/-/g, "");
      setFieldValue("phoneNo", phoneFormatter(value));
    },
    [setFieldValue]
  );

  return (
    <Paper className="content" elevation={2}>
      <Typography className="title" variant="h6" gutterBottom>
        사용자 설정
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              className="field"
              label="이메일"
              InputLabelProps={{ shrink: true }}
              disabled
              {...getFieldProps("accountId")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="field"
              label="이름"
              placeholder="이름을 입력해주세요."
              required
              {...getFieldProps("name")}
              error={shouldErrorShows("name", touched, errors)}
              helperText={getHelperText("name", touched, errors)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="field"
              label="부서"
              placeholder="부서명을 입력해주세요."
              required
              {...getFieldProps("department")}
              error={shouldErrorShows("department", touched, errors)}
              helperText={getHelperText("department", touched, errors)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField className="field" label="역할" disabled {...getFieldProps("role")} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="field"
              label="연락처"
              placeholder="연락처를 입력해주세요. ('-'제외)"
              required
              {...getFieldProps("phoneNo")}
              onChange={onChangePhoneNo}
              error={shouldErrorShows("phoneNo", touched, errors)}
              helperText={getHelperText("phoneNo", touched, errors)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="field"
              label="가입일"
              required
              disabled
              {...getFieldProps("createdAt")}
            />
          </Grid>
        </Grid>
        <Box className="submit">
          <Button type="submit" variant="text">
            저장
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default UserInfoForm;
