import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import { TextField } from "src/components/commons";
import usePostWeb from "src/hooks/apis/media/usePostWeb";
import { WEB_PLATFORM, WEB_PLATFORM_ALIAS } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { addMediaModalStyle } from "./styles";

interface AddMediaModalProps {
  onClose: () => void;
  open: boolean;
}

const AddMediaModal = ({ open, onClose }: AddMediaModalProps) => {
  const { mutate: createMedia } = usePostWeb();
  const [company] = useRecoilState(viewerCompany);

  const initialValues = useMemo(
    () => ({
      platformType: WEB_PLATFORM.PC,
      name: "",
      domain: "",
    }),
    []
  );

  const validationSchema = yup.object({
    name: yup.string().required("웹 매체 명을 입력해주세요."),
    platformType: yup.number().required("플랫폼을 선택해주세요."),
    domain: yup
      .string()
      .required("도메인을 입력해주세요.")
      .matches(REG_EXP.domain, "올바른 도메인을 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, setFieldValue, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      createMedia({ ...values, companyKey: company.key });
      onClose();
    },
  });

  return (
    <Dialog
      css={addMediaModalStyle}
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="representative-media"
      aria-describedby="edit media"
    >
      <DialogTitle id="dialog-title">신규 매체</DialogTitle>
      <DialogContent>
        <form id="direct-form" onSubmit={handleSubmit}>
          <RadioGroup
            row
            className="field"
            aria-labelledby="platform-group-label"
            {...getFieldProps("platformType")}
            onChange={(e, v) => {
              e.preventDefault();
              setFieldValue("platformType", +v);
            }}
          >
            <FormControlLabel
              value={WEB_PLATFORM.PC}
              control={<Radio />}
              label={WEB_PLATFORM_ALIAS[WEB_PLATFORM.PC]}
            />
            <FormControlLabel
              value={WEB_PLATFORM.MOBILE}
              control={<Radio />}
              label={WEB_PLATFORM_ALIAS[WEB_PLATFORM.MOBILE]}
            />
          </RadioGroup>
          <TextField
            className="field"
            label="웹 매체 명"
            placeholder="등록하실 웹 매체 명을 입력하세요."
            required
            {...getFieldProps("name")}
            error={shouldErrorShows("name", touched, errors)}
            helperText={getHelperText("name", touched, errors)}
          />
          <TextField
            className="field"
            label="메인 도메인"
            placeholder="https://www.example.com"
            required
            InputLabelProps={{ shrink: true }}
            {...getFieldProps("domain")}
            error={shouldErrorShows("domain", touched, errors)}
            helperText={getHelperText("domain", touched, errors)}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="direct-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMediaModal;
