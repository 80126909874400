import moment from "moment";
import { useCallback } from "react";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";

import COLORS from "src/assets/colors";
import DataFormat from "src/assets/formats";
import { NpayReport } from "src/hooks/apis/npay/use-npay-report";

type ReportChartProps = {
  dataKey: keyof NpayReport;
  data: {
    report_date: string;
    points: number;
    canceled_points: number;
    clicks: number;
    complete_views: number;
    revenue: number;
  }[];
};

export default function ReportChart(props: ReportChartProps) {
  const { data, dataKey } = props;

  const _dataKey = `npay_${dataKey}`;

  const _data = data.map((item) => {
    return Object.entries(item).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [`npay_${key}`]: value,
      }),
      {}
    );
  });

  const tickFormatter = useCallback(
    (key: string) => (v: unknown) => {
      return DataFormat[key].formatter(v as number);
    },
    []
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={_data}
        margin={{
          top: 32,
          left: 16,
          right: 16,
          bottom: 16,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="npay_report_date"
          tickFormatter={(v) => {
            if (v) {
              const date = moment(v);
              return date.isValid() ? date.format("YYYY-MM-DD") : "N/A";
            }

            return v;
          }}
        />
        <YAxis
          allowDecimals={false}
          dataKey={_dataKey}
          label={{
            value: `(${DataFormat[_dataKey].unit})`,
            position: {
              x: 60,
              y: -15,
            },
          }}
          tickFormatter={tickFormatter(_dataKey)}
        />
        <Tooltip wrapperStyle={{ fontWeight: "bold" }} formatter={tickFormatter(_dataKey)} />
        <Legend verticalAlign="bottom" wrapperStyle={{ fontWeight: "bold", paddingTop: 16 }} />
        <Line
          type="monotone"
          name={DataFormat[_dataKey].label}
          dataKey={_dataKey}
          stroke={COLORS[8]}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
