import { atom } from "recoil";
import { ROLE, UserRole } from "src/types";

export const uid2Agreement = atom<{
  value: boolean | null;
  role: UserRole;
}>({
  key: "uid2Agreement",
  default: {
    value: false,
    role: ROLE.UNREGISTERED,
  },
});
