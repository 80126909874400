import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useMemo } from "react";
import { TextField } from "src/components/commons";
import usePutPassword from "src/hooks/apis/auth/usePutPassword";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";

const PasswordForm = () => {
  const { mutate } = usePutPassword();

  const initialValues = useMemo(
    () => ({
      password: "",
      newPassword: "",
      confirmPassword: "",
    }),
    []
  );

  const validationSchema = yup.object({
    password: yup.string().required("기존 비밀번호를 입력해주세요."),
    newPassword: yup
      .string()
      .required("변경하실 비밀번호를 입력해주세요.")
      .matches(REG_EXP.password, "영문 대소문자와 숫자를 포함해 8글자 이상 입력해주세요."),
    confirmPassword: yup
      .string()
      .required("비밀번호가 일치하지 않습니다.")
      .oneOf([yup.ref("newPassword"), null], "비밀번호가 일치하지 않습니다."),
  });

  const { getFieldProps, handleSubmit, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      mutate({
        password: values.password,
        newPassword: values.newPassword,
      });
      resetForm();
    },
  });

  return (
    <Paper className="content" elevation={2}>
      <Typography className="title" variant="h6" gutterBottom>
        비밀번호 재설정
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              type="password"
              className="field"
              label="기존 비밀번호"
              placeholder="기존 비밀번호를 입력해주세요."
              required
              {...getFieldProps("password")}
              error={shouldErrorShows("password", touched, errors)}
              helperText={getHelperText("password", touched, errors)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="password"
              className="field"
              label="새 비밀번호"
              placeholder="변경하실 비밀번호를 입력해주세요."
              required
              {...getFieldProps("newPassword")}
              error={shouldErrorShows("newPassword", touched, errors)}
              helperText={getHelperText("newPassword", touched, errors)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="password"
              className="field"
              label="새 비밀번호 확인"
              placeholder="비밀번호를 다시 한번 입력해주세요."
              required
              {...getFieldProps("confirmPassword")}
              error={shouldErrorShows("confirmPassword", touched, errors)}
              helperText={getHelperText("confirmPassword", touched, errors)}
            />
          </Grid>
        </Grid>
        <Box className="submit">
          <Button type="submit" variant="text">
            저장
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default PasswordForm;
