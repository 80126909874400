import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useFormik } from "formik";
import { FocusEvent, useMemo } from "react";
import { TextField } from "src/components/commons";
import usePostAppScheme from "src/hooks/apis/media/usePostAppScheme";
import { APP_PLATFORM, APP_PLATFORM_ALIAS } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { addDeepLinkModalStyle } from "./styles";

interface AddDeepLinkModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const AddDeepLinkModal = ({ isOpen, onClose }: AddDeepLinkModalProps) => {
  const { mutate } = usePostAppScheme();
  const initialValues = useMemo(
    () => ({
      platformType: APP_PLATFORM.ANDROID,
      scheme: "",
    }),
    []
  );

  const validationSchema = yup.object({
    scheme: yup
      .string()
      .required("프로토콜을 입력해주세요.")
      .matches(REG_EXP.urlScheme, "올바른 프로토콜을 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, setFieldValue, touched, errors, handleBlur, values } =
    useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit: (values) => {
        mutate(values);
        onClose();
      },
    });

  const onBlurDeepLinkUrl = (e: FocusEvent) => {
    // 문자열 뒤에 "://"을 입력하지 않았을 때 자동으로 붙여줌.
    if (
      values.scheme &&
      !REG_EXP.urlScheme.test(values.scheme) &&
      REG_EXP.word.test(values.scheme)
    ) {
      setFieldValue("scheme", `${values.scheme}://`);
      return;
    }

    handleBlur(e);
  };

  return (
    <Dialog
      css={addDeepLinkModalStyle}
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="representative-deeplink"
      aria-describedby="add deeplink"
    >
      <DialogTitle id="dialog-title">신규 DeepLink</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="add-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioGroup
                row
                className="field"
                aria-labelledby="platform-group-label"
                {...getFieldProps("platformType")}
                onChange={(e, v) => {
                  e.preventDefault();
                  setFieldValue("platformType", +v);
                }}
              >
                <FormControlLabel
                  value={APP_PLATFORM.ANDROID}
                  control={<Radio />}
                  label={APP_PLATFORM_ALIAS[APP_PLATFORM.ANDROID]}
                />
                <FormControlLabel
                  value={APP_PLATFORM.iOS}
                  control={<Radio />}
                  label={APP_PLATFORM_ALIAS[APP_PLATFORM.iOS]}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="Deep Link URL"
                placeholder="ex) http://, https://"
                required
                {...getFieldProps("scheme")}
                error={shouldErrorShows("scheme", touched, errors)}
                helperText={getHelperText("scheme", touched, errors)}
                onBlur={onBlurDeepLinkUrl}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="add-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDeepLinkModal;
