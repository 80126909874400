import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, useCallback, useMemo } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import { TextField, UploadField } from "src/components/commons";
import useGetCompany from "src/hooks/apis/companies/useGetCompany";
import usePatchCompany from "src/hooks/apis/companies/usePatchCompany";
import useGetUserDetail from "src/hooks/apis/users/useGetUserDetail";
import { COMPANY, COMPANY_ALIAS } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { companySettingStyle } from "./styles";

const CompanyForm = () => {
  const [company] = useRecoilState(viewerCompany);
  const { data: userData } = useGetUserDetail({ userId: "me" });
  const { data } = useGetCompany({ companyId: company.id || userData?.user.company_id });
  const { mutate } = usePatchCompany();

  const initialValues = useMemo(
    () => ({
      type: data.company.type || COMPANY.INDIVIDUAL,
      name: data.company.name || "",
      address: data.company.address || "",
      brn: data.company.biz_registration_no || "",
      bri: (data.company.biz_registration_img_url || "") as File | string,
      rrn: data.company.registration_no || "",
      rri: (data.company.registration_img_url || "") as File | string,
    }),
    [
      data.company.address,
      data.company.biz_registration_img_url,
      data.company.biz_registration_no,
      data.company.name,
      data.company.registration_img_url,
      data.company.registration_no,
      data.company.type,
    ]
  );

  const validationSchema = yup.object({
    type: yup.number().required("타입을 선택해주세요."),
    brn: yup.string().when("type", {
      is: COMPANY.CORPORATION,
      then: yup
        .string()
        .test("brn", "올바른 사업자등록번호를 입력해주세요.", (value) =>
          value?.match(REG_EXP.brn) === null ? false : true
        )
        .required("사업자등록번호를 입력해주세요."),
    }),
    bri: yup.mixed().when("type", {
      is: COMPANY.CORPORATION,
      then: yup.mixed().required("사업자 등록증 사본을 업로드해주세요."),
    }),
    rrn: yup.string().when("type", {
      is: COMPANY.INDIVIDUAL,
      then: yup
        .string()
        .test("rrn", "올바른 주민등록번호를 입력해주세요.", (value) =>
          value?.match(REG_EXP.rrn) === null ? false : true
        )
        .required("주민등록번호를 입력해주세요."),
    }),
    rri: yup.mixed().when("type", {
      is: COMPANY.INDIVIDUAL,
      then: yup.mixed().required("주민등록증 사본을 업로드해주세요."),
    }),
    name: yup.string().required("업체명을 입력해주세요."),
    address: yup.string().required("업체 주소를 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, setFieldValue, touched, errors, values } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      mutate({
        companyId: company.id || userData?.user.company_id,
        companyType: values.type,
        companyName: values.name,
        registrationNo: values.type === COMPANY.CORPORATION ? values.brn : values.rrn,
        address: values.address,
        file: (values.type === COMPANY.CORPORATION ? values.bri : values.rri) as File,
      });
      resetForm();
    },
  });

  const onChangeType = useCallback(
    (e: ChangeEvent<HTMLInputElement>, v: string) => {
      e.preventDefault();
      setFieldValue("type", +v);
    },
    [setFieldValue]
  );

  const getVerifiedImgHelperText = useCallback(
    (type: "bri" | "rri") => {
      // 유효성 검사
      if (getHelperText(type, touched, errors)) return getHelperText(type, touched, errors);
      // 인증 완료
      if (
        (type === "bri" &&
          data.company.biz_registration_img_url &&
          data.company.biz_registration_confirmed) ||
        (type === "rri" && data.company.registration_img_url && data.company.registration_confirmed)
      )
        return (
          <span className="verified">
            <FaCheckCircle />
            인증완료
          </span>
        );
      // 인증되지 않음
      if (
        (type === "bri" &&
          data.company.biz_registration_img_url &&
          !data.company.biz_registration_confirmed) ||
        (type === "rri" &&
          data.company.registration_img_url &&
          !data.company.registration_confirmed)
      )
        return (
          <span className="unverified">
            <FiAlertCircle />
            인증되지 않음
          </span>
        );
      return null;
    },
    [
      data.company.biz_registration_confirmed,
      data.company.biz_registration_img_url,
      data.company.registration_confirmed,
      data.company.registration_img_url,
      errors,
      touched,
    ]
  );

  return (
    <Paper className="content" elevation={2} css={companySettingStyle}>
      <Typography className="title" variant="h6" gutterBottom>
        회사 정보
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioGroup
              row
              className="field"
              aria-label="filter"
              {...getFieldProps("type")}
              onChange={onChangeType}
            >
              <FormControlLabel
                value={COMPANY.INDIVIDUAL}
                control={<Radio />}
                label={COMPANY_ALIAS[COMPANY.INDIVIDUAL]}
              />
              <FormControlLabel
                value={COMPANY.CORPORATION}
                control={<Radio />}
                label={COMPANY_ALIAS[COMPANY.CORPORATION]}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="field"
              label="사업장명"
              placeholder="사업장명 입력해주세요."
              {...getFieldProps("name")}
              error={shouldErrorShows("name", touched, errors)}
              helperText={getHelperText("name", touched, errors)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="field"
              label="사업장 주소"
              placeholder="사업장 주소를 입력해주세요."
              {...getFieldProps("address")}
              error={shouldErrorShows("address", touched, errors)}
              helperText={getHelperText("address", touched, errors)}
              required
            />
          </Grid>
          {/* 기업 */}
          {values.type === COMPANY.CORPORATION && (
            <>
              <Grid item xs={12}>
                <TextField
                  className="field"
                  label="사업자등록번호"
                  placeholder="사업자등록번호를 입력해주세요."
                  {...getFieldProps("brn")}
                  error={shouldErrorShows("brn", touched, errors)}
                  helperText={getHelperText("brn", touched, errors)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <UploadField
                  fullWidth
                  className="field"
                  label="사업자등록증"
                  required
                  size="small"
                  accept=".jpg, .jpeg, .png, .pdf"
                  preview
                  {...getFieldProps("bri")}
                  value={data?.company.biz_registration_img_url}
                  onChange={(data: File) => setFieldValue("bri", data)}
                  error={shouldErrorShows("bri", touched, errors)}
                  helperText={getVerifiedImgHelperText("bri")}
                />
              </Grid>
            </>
          )}
          {/* 개인 */}
          {values.type === COMPANY.INDIVIDUAL && (
            <>
              <Grid item xs={12}>
                <TextField
                  className="field"
                  label="주민등록번호"
                  placeholder="주민등록번호를 입력해주세요."
                  {...getFieldProps("rrn")}
                  error={shouldErrorShows("rrn", touched, errors)}
                  helperText={getHelperText("rrn", touched, errors)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <UploadField
                  fullWidth
                  className="field"
                  label="신분증"
                  required
                  size="small"
                  accept=".jpg, .jpeg, .png, .pdf"
                  preview
                  {...getFieldProps("rri")}
                  value={data?.company.registration_img_url}
                  onChange={(data: File) => setFieldValue("rri", data)}
                  error={shouldErrorShows("rri", touched, errors)}
                  helperText={getVerifiedImgHelperText("rri")}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Box className="submit">
          <Button type="submit" variant="text">
            저장
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default CompanyForm;
