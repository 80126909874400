import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { STATUS, Status, WebTag, WEB_TAG } from "src/types";
import API from "src/utils/api";

interface Params {
  placementId: string;
}

interface Response {
  code: 200 | 401 | 404;
  data?: {
    placement: {
      bidfloor: number;
      bidfloor_currency: string;
      bidfloor_status: Status;
      cpc_bidfloor: number;
      cpc_bidfloor_currency: string;
      cpc_bidfloor_status: Status;
      created_at: number;
      custom_id: string;
      id: string;
      is_web_mediation: boolean;
      media_key: string;
      media_name: string;
      name: string;
      passback_script: string;
      size_type: number;
      status: Status;
      tag_type: WebTag;
      updated_at: number;
    };
  };
  text: "ok" | "unauthorized" | "not-found";
}

// 플레이스먼트 정보 조회
const useGetWebPlacementDetail = ({ placementId }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["placements/useGetWebPlacementDetail", placementId],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(
        `/placements/web/${placementId}`
      );
      return response.data.data;
    },
    { enabled: !!placementId, onError: handleError }
  );

  const result = useMemo(
    () =>
      data
        ? data
        : {
            placement: {
              bidfloor: 0,
              bidfloor_currency: "",
              bidfloor_status: STATUS.SUSPEND,
              cpc_bidfloor: 0,
              cpc_bidfloor_currency: "",
              cpc_bidfloor_status: STATUS.SUSPEND,
              created_at: 0,
              custom_id: "",
              id: "",
              is_web_mediation: false,
              media_key: "",
              media_name: "",
              name: "",
              passback_script: "",
              size_type: 0,
              status: STATUS.SUSPEND,
              tag_type: WEB_TAG.IFRAME,
              updated_at: 0,
            },
          },
    [data]
  );

  return { data: result, ...rest };
};

export default useGetWebPlacementDetail;
