import { Container, Paper, Tab, Tabs, Typography } from "@mui/material";
import { SyntheticEvent, useCallback, useState } from "react";
import BlockCategoryBoard from "./BlockCategoryBoard";
import BlockURLBoard from "./BlockURLBoard";

const BlockManagement = () => {
  const [tabValue, setTabValue] = useState(0);

  const onClickMainMenu = useCallback((_event: SyntheticEvent, value: number) => {
    setTabValue(value);
  }, []);

  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        웹 차단 관리
      </Typography>
      <Paper className="content" elevation={2}>
        <Tabs className="tabs" value={tabValue} onChange={onClickMainMenu}>
          <Tab label="카테고리별 차단" value={0} />
          <Tab label="URL 차단" value={1} />
        </Tabs>
        {tabValue === 0 && <BlockCategoryBoard />}
        {tabValue === 1 && <BlockURLBoard />}
      </Paper>
    </Container>
  );
};

export default BlockManagement;
