import { css } from "@emotion/react";
import { grey } from "@mui/material/colors";
import { Container, Typography, Paper, Stack, Box, Button } from "@mui/material";
import { SortChangedEvent, ColDef } from "ag-grid-community";
import { useFormik } from "formik";
import moment, { Moment } from "moment";
import { useState, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DateField, BasicTable } from "src/components/commons";
import useNpayCsTransactionHistory, {
  NpayCsTransactionHistoryParams,
} from "src/hooks/apis/npay/use-npay-cs-transaction-history";
import { NpayTransactionStatus } from "src/hooks/apis/npay/use-npay-transactions";
import useToast from "src/hooks/useToast";
import { CAMPAIGN_ALIAS, Campaign, APP_PLATFORM_ALIAS, AppPlatform } from "src/types";
import { shouldErrorShows } from "src/utils/form-helper";
import { object, mixed } from "yup";
import { MIN_DATE, MAX_DATE } from "../const";

type FormValues = {
  since: Moment | null;
  until: Moment | null;
};

const validationSchema = object({
  since: mixed().required(),
  until: mixed().required(),
});

const initialFormValues: FormValues = {
  since: moment().startOf("d").subtract(7, "d"),
  until: moment().startOf("d").subtract(1, "d"),
};

const transactionHistoryStyle = css`
  .label {
    font-weight: 500;
  }
  .value {
    color: ${grey[600]};
  }
`;

// use clientInfoStyle
export default function TransactionHistory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [params, setParams] = useState<NpayCsTransactionHistoryParams>({
    id: id as string,
    since: initialFormValues.since?.format("YYYYMMDD") || "",
    until: initialFormValues.until?.format("YYYYMMDD") || "",
    page_no: 1,
    page_size: 20,
    orders: ["-created_at"],
  });

  // cs 목록 query 조회 여부
  const [enableQuery, setEnableQuery] = useState(false);

  // 조회하기 버튼 핸들러
  const handleSubmitForm = (values: FormValues) => {
    setParams((prev) => ({
      ...prev,
      ...values,
      since: values.since?.format("YYYYMMDD") || "",
      until: values.until?.format("YYYYMMDD") || "",
      page_no: 1,
    }));

    setEnableQuery(true);
  };

  // formik
  const {
    setValues,
    values: formValues,
    isValid,
    errors,
    touched,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: initialFormValues,
    onSubmit: handleSubmitForm,
    validationSchema: validationSchema,
    validateOnMount: true,
  });

  const query = useNpayCsTransactionHistory(params, {
    enabled: enableQuery,
    onSuccess: () => {
      setEnableQuery(false);
    },
    onError: (error) => {
      if (error.response?.status === 404) {
        navigate("/404", { replace: true });
      }
    },
  });

  const handleChangeDates = useCallback(
    (field: "since" | "until") => (value: Moment | null) => {
      setValues((prev) => {
        if (value === null) {
          toast.error("날짜를 선택해 주세요.");

          return {
            ...prev,
            [field]: value,
          };
        }

        if (!value.isValid()) {
          return prev;
        }

        const withinRange = value.isSameOrAfter(MIN_DATE) && value.isSameOrBefore(MAX_DATE);

        if (!withinRange) {
          toast.error("선택 가능한 범위를 확인해 주세요.");
          return prev;
        }

        const isEndDate = field === "until";
        const isEndDateBeforeStartDate = isEndDate && value.isBefore(prev.since);
        const isStartDateAfterEndDate = !isEndDate && value.isAfter(prev.until);

        if (isEndDateBeforeStartDate) {
          const newStartDate = moment(value).subtract(6, "d");

          return {
            since: newStartDate.isBefore(MIN_DATE) ? MIN_DATE : newStartDate,
            until: value,
          };
        }

        if (isStartDateAfterEndDate) {
          const newEndDate = moment(value).add(6, "d");

          return {
            since: value,
            until: newEndDate.isAfter(MAX_DATE) ? MAX_DATE : newEndDate,
          };
        }

        return {
          ...prev,
          [field]: value,
        };
      });
    },
    [setValues, toast]
  );

  const handleChangePage = useCallback((page: number) => {
    setParams((prev) => ({ ...prev, page_no: page }));
    setEnableQuery(true);
  }, []);

  const handleChangePageSize = useCallback((pageSize: number) => {
    setParams((prev) => ({ ...prev, page_no: 1, page_size: pageSize }));
    setEnableQuery(true);
  }, []);

  const handleSortChange = useCallback((e: SortChangedEvent) => {
    const sortedColumns = e.columnApi
      .getColumnState()
      .filter((column) => Boolean(column.sort))
      .map(({ colId, sort }) => (sort === "desc" ? `-${colId}` : colId));

    setParams((prev) => ({ ...prev, page_no: 1, orders: sortedColumns }));
    setEnableQuery(true);
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: {
        page: params.page_no as number,
        count: query.data.pages,
        onChange: handleChangePage,
      },
      pageSize: {
        size: params.page_size as number,
        onChange: handleChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [handleChangePage, handleChangePageSize, query.data.pages, params.page_no, params.page_size]
  );

  return (
    <Container
      component="section"
      className="ssp-section"
      css={transactionHistoryStyle}
      maxWidth="xl"
    >
      <Typography className="title" variant="h5" gutterBottom>
        네이버페이 포인트 지급 내역
      </Typography>
      <Paper className="content" elevation={2}>
        <Stack spacing={2}>
          <Stack component="form" onSubmit={handleSubmit} spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={2}>
                {/* 시작일 */}
                <Box sx={{ width: 300 }}>
                  <DateField
                    label="시작일"
                    minDate={MIN_DATE}
                    maxDate={MAX_DATE}
                    value={formValues.since}
                    onChange={handleChangeDates("since")}
                    InputProps={{
                      onBlur: handleBlur("since"),
                      error: shouldErrorShows("since", touched, errors),
                    }}
                    disabled={query.isFetching}
                  />
                </Box>
                {/* 종료일 */}
                <Box sx={{ width: 300 }}>
                  <DateField
                    label="종료일"
                    minDate={MIN_DATE}
                    maxDate={MAX_DATE}
                    value={formValues.until}
                    onChange={handleChangeDates("until")}
                    InputProps={{
                      onBlur: handleBlur("until"),
                      error: shouldErrorShows("until", touched, errors),
                    }}
                    disabled={query.isFetching}
                  />
                </Box>
                <Button type="submit" variant="contained" disabled={!isValid || query.isFetching}>
                  조회하기
                </Button>
              </Stack>
              <Button href="/operation/npay/transactions" target="_blank">
                거래내역 확인 바로가기
              </Button>
            </Stack>
          </Stack>

          <BasicTable
            getRowId={(params) => params.data.ad_request_no}
            animateRows
            rowData={query.data.rows}
            columnDefs={columnDef}
            {...paginationInfo}
            onSortChanged={handleSortChange}
          />
        </Stack>
      </Paper>
    </Container>
  );
}

const columnDef: ColDef[] = [
  {
    headerName: "날짜",
    field: "created_at",
    sortable: true,
    sort: "desc",
    valueFormatter: (params) => {
      return moment.unix(params.value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    headerName: "상태",
    field: "status",
    sortable: true,
    valueFormatter: (params) => {
      return NpayTransactionStatus[params.value];
    },
  },
  {
    headerName: "미디어키",
    field: "media_key",
    sortable: true,
  },
  {
    headerName: "플레이스먼트 ID",
    field: "placement_id",
    sortable: true,
  },
  {
    headerName: "광고 타입",
    field: "ad_type",
    sortable: true,
    valueFormatter: (params) => {
      return CAMPAIGN_ALIAS[params.value as Campaign];
    },
  },
  {
    headerName: "리워드 단위",
    field: "point",
    sortable: true,
  },
  {
    headerName: "네이버 ID",
    field: "naver_id",
    sortable: true,
  },
  {
    headerName: "USN",
    field: "usn",
    sortable: true,
  },
  {
    headerName: "ADID/IDFA",
    field: "identifier",
    sortable: true,
  },
  {
    headerName: "제휴사 거래번호",
    field: "ssp_tx_no",
    sortable: true,
  },
  {
    headerName: "다우 거래번호",
    field: "daou_tx_no",
    sortable: true,
  },
  {
    headerName: "플랫폼",
    field: "platform",
    sortable: true,
    valueFormatter: (params) => {
      return APP_PLATFORM_ALIAS[params.value as AppPlatform];
    },
  },
];
