import { Divider, Paper, Typography } from "@mui/material";
import { AiFillQuestionCircle } from "react-icons/ai";
import { RiFileDownloadLine } from "react-icons/ri";
import { guideStyle } from "./styles";

const Guide = () => {
  return (
    <Paper className="content" elevation={2} css={guideStyle}>
      <Typography className="guide-title" variant="h6" gutterBottom>
        <AiFillQuestionCircle />
        <span>app-ads.txt를 설정하셨나요?</span>
      </Typography>
      <Divider />
      <ol className="guide-list">
        <li>
          app-ads.txt / ads.txt <br />
          <a href="/app-ads.txt" className="download" download>
            <RiFileDownloadLine /> 첨부파일 다운로드
          </a>
        </li>
        <li>
          개발자 웹사이트의 루트에 app-ads.txt 파일을 게시합니다.
          <br />
          (예: sampledomain.com/app-ads.txt)
          <br />
          Google Play 또는 App Store에 게시된 도메인을 그대로 입력해야 합니다.
        </li>
      </ol>
    </Paper>
  );
};

export default Guide;
