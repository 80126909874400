import { css } from "@emotion/react";

export const blockCategoryBoardStyle = css`
  margin-top: 1rem;
`;

export const addBlockURLModalStyle = css`
  .dialog-content {
    #add-form {
      padding-top: 20px;
    }
  }
`;

export const editBlockURLModalStyle = css`
  .dialog-content {
    #edit-form {
      padding-top: 20px;
    }
  }
`;
