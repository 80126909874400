import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { AppPlatform } from "src/types";
import API from "src/utils/api";

export interface Scheme {
  platform_type: AppPlatform;
  scheme: string;
  no: number;
}

interface Params {
  platformType?: AppPlatform;
}

interface Response {
  code: 200 | 401;
  data?: {
    schemes: Scheme[];
  };
  text: "ok" | "unauthorized";
}

// 스키마 리스트 조회
// platform_type을 전달하지 않을 경우 전체 조회
const useGetAppScheme = ({ platformType }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["media/useGetAppScheme", platformType],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/media/app/schemes`, {
        params: {
          platform_type: platformType,
        },
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => (data ? data : { schemes: [] }), [data]);

  return { data: result, ...rest };
};

export default useGetAppScheme;
