import { AxiosError, AxiosResponse } from "axios";
import moment, { Moment } from "moment";
import { useCallback, useMemo, useState } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Campaign, MEDIA, Media, REPORT, Report } from "src/types";
import API from "src/utils/api";
import { getCtr, getEcpm, getFillRate, getIR, getRpr } from "src/utils/calculator";
import { AppMedia } from "../media/useGetAppMediaList";
import { WebMedia } from "../media/useGetWebMediaList";
import { AppPlacement } from "../placements/useGetAppPlacementList";
import { WebPlacement } from "../placements/useGetWebPlacementList";
import { Mediation } from "../thirdparties/useGetMediationList";

export interface ReportChartData {
  date: string;
  request: number;
  response: number;
  impression: number;
  click: number;
  advertiseCost: number;
  mediaCost: number;
  fillRate: number;
  ctr: number;
  ecpm: number;
  advertiseCostEcpm: number;
}

export interface ReportTableData extends ReportChartData {
  country: string;
  mediaKey?: string;
  mediaName?: string;
  placementId?: string;
  placementName?: string;
  campaignType?: Campaign;
  mediationId?: number;
  mediationName?: string;
  impressionRate: number;
  rpr: number;
}

export interface Data {
  advertise_cost: string;
  click: number;
  ctr: number;
  ecpm: string;
  impression: number;
  media_cost: string;
  media_key: string;
  placement_id: string;
  thirdparty_id: number;
  request: number;
  response: number;
  rpr: number;
  ymd: string;
  country: string;
}

interface Params {
  byCountries: boolean;
  mediaList: (AppMedia | WebMedia)[];
  mediaType: Media;
  placementList: (AppPlacement | WebPlacement)[];
  reportType: Report;
  since: Moment;
  thirdpartyList: Mediation[];
  until: Moment;
}

interface Response {
  code: 200 | 400 | 401 | 500;
  data?: {
    media_type: Media;
    report: Data[];
    report_type: Report;
  };
  text: "ok" | "bad-request" | "unauthorized" | "internal-server-error";
}

// 레포트 조회
// 조회 시작일, 종료일을 전달하지 않을 경우, -1일부터 -7일까지 데이터를 조회
const usePostReport = () => {
  const [type, setType] = useState<Report>();
  const [media, setMedia] = useState<(AppMedia | WebMedia)[]>([]);
  const [placement, setPlacement] = useState<(AppPlacement | WebPlacement)[]>([]);
  const [thirdparty, setThirdparty] = useState<Mediation[]>([]);
  const { handleError } = useApiError();
  const { data, ...rest } = useMutation<Response["data"], AxiosError, Params>(
    async ({
      byCountries,
      mediaList,
      mediaType,
      placementList,
      reportType,
      since,
      thirdpartyList,
      until,
    }) => {
      setType(reportType);
      setMedia(mediaList);
      setPlacement(placementList);
      setThirdparty(thirdpartyList);
      const response: AxiosResponse<Response> = await API.default.post(`/report`, {
        by_countries: byCountries,
        media_keys: mediaList.map((m) => m.key),
        media_type: mediaType,
        placement_ids: placementList.map((p) => p.id),
        report_type: reportType,
        since: since.format("YYYYMMDD"),
        thirdparty_ids: thirdpartyList.map((m) => m.id),
        until: until.format("YYYYMMDD"),
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  const getDate = useCallback(
    (date: string) => {
      if (type === REPORT.DAILY) {
        return moment(date).format("YYYY-MM-DD");
      }
      if (type === REPORT.WEEKLY) {
        return date.replace(/^(\d{4})(\d{2})$/, `$1-w$2`);
      }
      if (type === REPORT.MONTHLY) {
        return date.replace(/^(\d{4})(\d{2})$/, `$1-$2`);
      }
      return "";
    },
    [type]
  );

  const getTableData = useCallback(
    (report: Data[], byCountry: boolean) => {
      // 테이블 데이터
      return report.reduce((acc: { [key: string]: ReportTableData }, cur) => {
        const {
          ymd,
          request,
          response,
          impression,
          click,
          advertise_cost,
          media_cost,
          country,
          media_key,
          placement_id,
          thirdparty_id,
        } = cur;
        const m = media.find((v) => v.key === media_key);
        const p = placement.find(({ id }) => id === placement_id);
        const t = thirdparty.find(({ id }) => id === thirdparty_id);

        const key = byCountry
          ? `${ymd}-${country}-${m?.key}-${p?.id}-${t?.id}`
          : `${ymd}-${m?.key}-${p?.id}-${t?.id}`;

        if (!acc[key]) {
          acc[key] = {
            country: country,
            mediaKey: m?.key,
            mediaName: m?.name,
            placementId: p?.id,
            placementName: p?.name,
            mediationId: t?.id,
            mediationName: t?.name,
            date: getDate(ymd),
            request: +request,
            response: +response,
            impression: +impression,
            click: +click,
            advertiseCost: +advertise_cost,
            mediaCost: +media_cost,
            fillRate: getFillRate(+request, +response),
            impressionRate: getIR(+impression, +response),
            ctr: getCtr(+impression, +click),
            ecpm: getEcpm(+impression, +media_cost),
            advertiseCostEcpm: getEcpm(+impression, +advertise_cost),
            rpr: getRpr(+media_cost, +request),
          };
        } else {
          acc[key].request += +request;
          acc[key].response += +response;
          acc[key].impression += +impression;
          acc[key].click += +click;
          acc[key].advertiseCost += +advertise_cost;
          acc[key].mediaCost += +media_cost;
          acc[key].fillRate = getFillRate(acc[key].request, acc[key].response);
          acc[key].ctr = getCtr(acc[key].impression, acc[key].click);
          acc[key].ecpm = getEcpm(acc[key].impression, acc[key].mediaCost);
          acc[key].advertiseCostEcpm = getEcpm(acc[key].impression, acc[key].advertiseCost);
          acc[key].impressionRate = getIR(acc[key].impression, acc[key].response);
          acc[key].rpr = getRpr(acc[key].mediaCost, acc[key].request);
        }

        return acc;
      }, {});
    },
    [getDate, media, placement, thirdparty]
  );

  const result = useMemo(() => {
    if (data) {
      // 차트 데이터
      const chartData = data.report.reduce((acc: { [key: string]: ReportChartData }, cur) => {
        const { ymd, request, response, impression, click, advertise_cost, media_cost } = cur;
        const key = getDate(ymd);
        if (!acc[key]) {
          acc[key] = {
            date: getDate(ymd),
            request: +request,
            response: +response,
            impression: +impression,
            click: +click,
            advertiseCost: +advertise_cost,
            mediaCost: +media_cost,
            fillRate: getFillRate(+request, +response),
            ctr: getCtr(+impression, +click),
            ecpm: getEcpm(+impression, +media_cost),
            advertiseCostEcpm: getEcpm(+impression, +advertise_cost),
          };
        } else {
          acc[key].request += +request;
          acc[key].response += +response;
          acc[key].impression += +impression;
          acc[key].click += +click;
          acc[key].advertiseCost += +advertise_cost;
          acc[key].mediaCost += +media_cost;
          acc[key].fillRate = getFillRate(acc[key].request, acc[key].response);
          acc[key].ctr = getCtr(acc[key].impression, acc[key].click);
          acc[key].ecpm = getEcpm(acc[key].impression, acc[key].mediaCost);
          acc[key].advertiseCostEcpm = getEcpm(acc[key].impression, acc[key].advertiseCost);
        }
        return acc;
      }, {});

      const tableData = getTableData(data.report, false);
      const countryTableData = getTableData(data.report, true);

      return {
        ...data,
        chart: Object.entries(chartData)
          .map(([_key, value]) => value)
          .sort((a, b) => {
            if (moment(a.date).isAfter(moment(b.date))) return 1;
            if (moment(a.date).isBefore(moment(b.date))) return -1;
            return 0;
          }),
        table: Object.entries(tableData)
          .map(([_key, value]) => value)
          .sort((a, b) => {
            if (moment(b.date).isAfter(moment(a.date))) return 1;
            if (moment(b.date).isBefore(moment(a.date))) return -1;
            if (a.impression < b.impression) return 1;
            if (a.impression > b.impression) return -1;
            if (a.mediationId && b.mediationId && a.mediationId < b.mediationId) return -1;
            if (a.mediationId && b.mediationId && a.mediationId > b.mediationId) return 1;
            return 0;
          }),
        countryTable: Object.entries(countryTableData)
          .map(([_key, value]) => value)
          .sort((a, b) => {
            if (moment(b.date).isAfter(moment(a.date))) return 1;
            if (moment(b.date).isBefore(moment(a.date))) return -1;
            if (a.impression < b.impression) return 1;
            if (a.impression > b.impression) return -1;
            if (a.mediationId && b.mediationId && a.mediationId < b.mediationId) return -1;
            if (a.mediationId && b.mediationId && a.mediationId > b.mediationId) return 1;
            return 0;
          }),
      };
    }
    return {
      media_type: MEDIA.ALL,
      chart: [] as ReportChartData[],
      table: [] as ReportTableData[],
      countryTable: [] as ReportTableData[],
      report: [] as Data[],
      report_type: REPORT.DAILY,
    };
  }, [data, getDate, getTableData]);

  return { data: result, ...rest };
};

export default usePostReport;
