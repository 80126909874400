import Dashboard from "./Dashboard";
import SubDashboard from "./SubDashboard";

const AdminDashboard = () => {
  return (
    <>
      <Dashboard />
      <SubDashboard />
    </>
  );
};

export default AdminDashboard;
