import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { STATUS, Status } from "src/types";
import API from "src/utils/api";

interface Params {
  no: number;
  status: Status;
}

interface Response {
  code: 200 | 401 | 500;
  text: "ok" | "unauthorized" | "internal-server-error";
}

// 테스트 디바이스 활성화 / 비활성화
const usePatchTestDevice = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ no, status = STATUS.ACTIVE }) => {
      const response: AxiosResponse<Response> = await API.default.patch(
        `/test-devices/${no}/${status === STATUS.ACTIVE ? "activate" : "deactivate"}`,
        { no }
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("수정되었습니다.");
        invalidate("testDevices/useGetTestDeviceList");
      },
    }
  );

  return result;
};

export default usePatchTestDevice;
