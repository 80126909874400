import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Media } from "src/types";
import API from "src/utils/api";

export interface Mediation {
  id: number;
  media_type: Media;
  name: string;
  placement_count?: number; // params.type === 0으로 요청 시 X
  system_fee: number;
}

interface Params {
  type: Media;
  name?: string;
  pageNo?: number;
  pageSize?: number;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    pages: number;
    thirdparties: Mediation[];
  };
  text: "ok" | "bad-request" | "unauthorized";
}

type QueryOptions = Omit<
  UseQueryOptions<Response["data"], AxiosError, Response["data"], QueryKey>,
  "queryKey" | "queryFn"
>;

// 파트너 관리 > 타입별 서드파티 리스트 조회 (미디에이션)
const useGetMediationList = (
  { type, name, pageNo = 1, pageSize = 100000 }: Params,
  options?: QueryOptions
) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["thirdparties/useGetMediationList", type, name, pageNo, pageSize],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/thirdparties/mediations`, {
        params: {
          type,
          name,
          page_no: pageNo,
          page_size: pageSize,
        },
      });
      return response.data.data;
    },
    { onError: handleError, ...options }
  );

  const result = useMemo(() => (data ? data : { pages: 0, thirdparties: [] }), [data]);

  return { data: result, ...rest };
};

export default useGetMediationList;
