import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, FocusEvent, SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import { TextField } from "src/components/commons";
import useGetAdFormatInfo from "src/hooks/apis/info/useGetAdFormatInfo";
import useGetWebMediaList, { WebMedia } from "src/hooks/apis/media/useGetWebMediaList";
import usePostWebPlacement from "src/hooks/apis/placements/usePostWebPlacement";
import useOpenModal from "src/hooks/useOpenModal";
// import useUser from "src/hooks/useUser";
import { CAMPAIGN, STATUS, WEB_TAG, WEB_TAG_ALIAS } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import AddCampaignModal from "./AddCampaignModal";
import { placementModalStyle } from "./styles";

interface AddPlacementModalProps {
  onClose: () => void;
  open: boolean;
}

const AddPlacementModal = ({ onClose, open }: AddPlacementModalProps) => {
  const [media, setMedia] = useState<WebMedia | null>();
  // const { isAdmin } = useUser();
  const [campaignSizeType, setCampaignSizeType] = useState<number>();
  const [openAddModal, onShowAddCampaignModal, onCloseAddCampaignModal] = useOpenModal(null);
  const [company] = useRecoilState(viewerCompany);
  const { data: mediaData } = useGetWebMediaList({ companyKey: company.key });
  const { data: adFormatData } = useGetAdFormatInfo({ types: [CAMPAIGN.WebBanner] });
  const { mutate } = usePostWebPlacement();

  const initialValues = useMemo(
    () => ({
      name: "",
      mediaKey: "",
      campaign: "",
      tagType: WEB_TAG.IFRAME,
      sizeType: 0,
      bidfloorStatus: false,
      bidfloor: 0,
      cpcBidfloorStatus: false,
      cpcBidfloor: 0,
    }),
    []
  );

  const validationSchema = yup.object({
    name: yup.string().required("플레이스먼트 명을 입력해주세요."),
    mediaKey: yup.string().required("매체를 선택해주세요."),
    tagType: yup.number().required("태그 형식을 선택해주세요."),
    campaign: yup.string().required("광고 형식을 선택해주세요."),
    bidfloorStatus: yup.boolean(),
    bidfloor: yup.number().when("bidfloorStatus", {
      is: true,
      then: yup.number().required("값을 입력해주세요.").moreThan(0, "값을 입력해주세요."),
    }),
    cpcBidfloorStatus: yup.boolean(),
    cpcBidfloor: yup.number().when("cpcBidfloorStatus", {
      is: true,
      then: yup.number().required("값을 입력해주세요.").moreThan(0, "값을 입력해주세요."),
    }),
  });

  const { getFieldProps, handleSubmit, touched, errors, setFieldValue, values, handleBlur } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        mutate({
          bidfloor: +values.bidfloor,
          bidfloorCurrency: "USD",
          bidfloorStatus: values.bidfloorStatus ? STATUS.ACTIVE : STATUS.SUSPEND,
          cpcBidfloor: +values.cpcBidfloor,
          cpcBidfloorCurrency: "USD",
          cpcBidfloorStatus: values.cpcBidfloorStatus ? STATUS.ACTIVE : STATUS.SUSPEND,
          mediaKey: values.mediaKey,
          name: values.name,
          sizeType: campaignSizeType,
          tagType: values.tagType,
        });
        onClose();
      },
    });

  const onChangeMedia = useCallback(
    (_: SyntheticEvent<unknown>, v: WebMedia | null) => {
      setMedia(v);
      setFieldValue("mediaKey", v?.key);
    },
    [setFieldValue]
  );

  const onSetCampaign = useCallback(
    (value: number) => {
      setCampaignSizeType(value);
      setFieldValue(
        "campaign",
        adFormatData.formats.find((format) => format.id === value)?.label || ""
      );
    },
    [adFormatData.formats, setFieldValue]
  );

  const onChangeBidfloor = useCallback(
    (field: "bidfloor" | "cpcBidfloor") => (e: ChangeEvent<HTMLInputElement>) => {
      const value =
        e.target.value.replace(
          REG_EXP.bidfloor,
          e.target.value.substring(0, e.target.value.length - 1)
        ) || 0;
      if (+(value || 0) < 1000000) {
        setFieldValue(field, value);
      }
    },
    [setFieldValue]
  );

  const onBlurBidfloor = useCallback(
    (field: "bidfloor" | "cpcBidfloor") => (e: FocusEvent) => {
      setFieldValue(field, parseFloat(`${values[field] || 0}`));
      handleBlur(e);
    },
    [handleBlur, setFieldValue, values]
  );

  return (
    <Dialog
      css={placementModalStyle}
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="representative-placement"
      aria-describedby="add placement"
    >
      <DialogTitle id="dialog-title">신규 플레이스먼트</DialogTitle>
      <DialogContent className="dialog-content">
        <form id="edit-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                className="field"
                size="small"
                options={mediaData.media}
                getOptionLabel={(media) => media.name}
                renderInput={({ InputLabelProps, ...params }) => (
                  <TextField
                    {...params}
                    label="매체 명"
                    placeholder="매체를 선택해주세요."
                    required
                    error={shouldErrorShows("mediaKey", touched, errors)}
                    helperText={getHelperText("mediaKey", touched, errors)}
                  />
                )}
                value={media}
                onChange={onChangeMedia}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="플레이스먼트 명"
                placeholder="플레이스먼트 명을 입력하세요."
                required
                {...getFieldProps("name")}
                error={shouldErrorShows("name", touched, errors)}
                helperText={getHelperText("name", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="광고 형식"
                placeholder="광고 형식을 설정해주세요."
                required
                suffix={
                  <Button color="primary" onClick={onShowAddCampaignModal}>
                    설정하기
                  </Button>
                }
                readOnly={true}
                {...getFieldProps("campaign")}
                error={shouldErrorShows("campaign", touched, errors)}
                helperText={getHelperText("campaign", touched, errors)}
              />
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                row
                className="field"
                aria-labelledby="tag-group-label"
                {...getFieldProps("tagType")}
                onChange={(e, v) => {
                  e.preventDefault();
                  setFieldValue("tagType", +v);
                }}
              >
                <FormControlLabel
                  value={WEB_TAG.IFRAME}
                  control={<Radio />}
                  label={WEB_TAG_ALIAS[WEB_TAG.IFRAME]}
                />
                <FormControlLabel
                  value={WEB_TAG.SCRIPT}
                  control={<Radio />}
                  label={WEB_TAG_ALIAS[WEB_TAG.SCRIPT]}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={8}>
              <FormControlLabel
                className="switch"
                control={<Switch color="primary" />}
                label="BidFloor"
                labelPlacement="start"
                {...getFieldProps("bidfloorStatus")}
              />
            </Grid>
            <Grid item xs={4}>
              {values.bidfloorStatus && (
                <TextField
                  className="field"
                  required
                  prefix={<span className="ssp-input-currency-prefix">USD</span>}
                  {...getFieldProps("bidfloor")}
                  onChange={onChangeBidfloor("bidfloor")}
                  onBlur={onBlurBidfloor("bidfloor")}
                  error={shouldErrorShows("bidfloor", touched, errors)}
                  helperText={getHelperText("bidfloor", touched, errors)}
                />
              )}
            </Grid>
            {/* {isAdmin && (
              <>
                <Grid item xs={8}>
                  <FormControlLabel
                    className="switch"
                    control={<Switch color="primary" />}
                    label="CPC BidFloor"
                    labelPlacement="start"
                    {...getFieldProps("cpcBidfloorStatus")}
                  />
                </Grid>
                <Grid item xs={4}>
                  {values.cpcBidfloorStatus && (
                    <TextField
                      className="field"
                      required
                      prefix={<span className="ssp-input-currency-prefix">USD</span>}
                      {...getFieldProps("cpcBidfloor")}
                      onChange={onChangeBidfloor("cpcBidfloor")}
                      error={shouldErrorShows("cpcBidfloor", touched, errors)}
                      helperText={getHelperText("cpcBidfloor", touched, errors)}
                    />
                  )}
                </Grid>
              </>
            )} */}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="edit-form" type="submit">
          저장
        </Button>
      </DialogActions>
      {openAddModal.isOpen && (
        <AddCampaignModal
          open={openAddModal.isOpen}
          onClose={onCloseAddCampaignModal}
          onSubmit={onSetCampaign}
        />
      )}
    </Dialog>
  );
};

export default AddPlacementModal;
