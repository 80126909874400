import Bidfloor from "./Bidfloor";
import Dashboard from "./Dashboard";
import DashboardReport from "./DashboardReport";

const AppHome = () => {
  return (
    <>
      <Dashboard />
      <DashboardReport />
      <Bidfloor />
    </>
  );
};

export default AppHome;
