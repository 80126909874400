import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

// import API from "src/utils/api";
import useApiError from "../useApiError";
// import moment from "moment";
import API from "src/utils/api";
import { parseCookies } from "nookies";

export type NpayDailyCap = number;

type NpayDailyCapData = {
  code: number;
  text: string;
  data: NpayDailyCap;
};

type NpayDailyCapQueryKey = "operation/npay/daily-cap";

type NpayDailyCapQueryFn = QueryFunction<AxiosResponse<NpayDailyCapData>, NpayDailyCapQueryKey>;

type NpayDailyCapQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<NpayDailyCapData>,
    AxiosError,
    AxiosResponse<NpayDailyCapData>,
    NpayDailyCapQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: NpayDailyCapData = {
  code: 200,
  text: "OK",
  data: 0,
};

export default function useNpayDailyCap(options?: NpayDailyCapQueryOptions) {
  const { handleError } = useApiError();

  const queryKey = useMemo<NpayDailyCapQueryKey>(() => "operation/npay/daily-cap", []);

  const queryFn = useCallback<NpayDailyCapQueryFn>(() => {
    const cookie = parseCookies();

    return API.consoleV3.get("/v3/naverpay/dailycap", {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
