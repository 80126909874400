import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, FocusEvent, useCallback, useMemo, useState } from "react";

import { AppPlacementDetail } from "src/hooks/apis/placements/useGetAppPlacementDetail";
import usePostAppPlacement from "src/hooks/apis/placements/usePostAppPlacement";
import usePutAppPlacement from "src/hooks/apis/placements/usePutAppPlacement";
// import useUser from "src/hooks/useUser";
import useNativeTemplateList, {
  NativeTemplateType,
} from "src/hooks/apis/placements/useNativeTemplateList";

import { Select, TextField } from "src/components/commons";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import { CAMPAIGN, STATUS, Template } from "src/types";

import { addCampaignModalStyle } from "./styles";
import { companionValidationSchema, getDefaultTemplateOption, getPreviewImgSize } from "./const";

interface AddCompanionModalProps {
  onClose: () => void;
  open: { key: string; isOpen: boolean };
  parentPlacementData: { placement: AppPlacementDetail };
}

const AddCompanionModal = ({ onClose, open, parentPlacementData }: AddCompanionModalProps) => {
  const { mutate } = usePostAppPlacement();
  const { mutate: updateParentPlacement } = usePutAppPlacement();
  // const { isAdmin } = useUser();

  const defaultTemplateOption = getDefaultTemplateOption(NativeTemplateType.COMPANION);
  const previewImgSize = getPreviewImgSize(defaultTemplateOption.templateNo);

  /** 광고 템플릿 옵션 목록 */
  const [templateOptions, setTemplateOptions] = useState([
    {
      templateNo: defaultTemplateOption.templateNo,
      name: defaultTemplateOption.name,
    },
  ]);

  /** 미리보기 이미지 속성 */
  const [previewImgProps, setPreviewImgProps] = useState({
    src: "", // nativeTemplateList 조회 전까지 알 수 없음
    width: previewImgSize.width,
    height: previewImgSize.height,
  });

  const initialValues = useMemo(
    () => ({
      name: `${parentPlacementData.placement.name}-N` || "",
      mediaKey: parentPlacementData.placement.media_key || "",
      sizeType: parentPlacementData.placement.size_type,
      templateNo: defaultTemplateOption.templateNo,
      companionUse: true,
      checkViewability: true,
      bidfloorCurrency: "USD",
      bidfloorStatus: false,
      bidfloor: 0,
      cpcBidfloorCurrency: "USD",
      cpcBidfloorStatus: false,
      cpcBidfloor: 0,
      type: CAMPAIGN.Native,
      minViewTime: 1,
      iconImageRequired: false,
      titleRequired: false,
      ctaTextRequired: false,
      descRequired: false,
      mainImageRequired: false,
      ctatextRequired: false,
      mute: false,
      isSDKMediation: false,
    }),
    [
      parentPlacementData.placement.name,
      parentPlacementData.placement.media_key,
      parentPlacementData.placement.size_type,
      defaultTemplateOption.templateNo,
    ]
  );

  const { getFieldProps, setFieldValue, handleSubmit, handleBlur, touched, errors, values } =
    useFormik({
      initialValues,
      validationSchema: companionValidationSchema,
      onSubmit: (values) => {
        mutate(
          {
            ...values,
            parentId: open.key,
            bidfloor: +values.bidfloor,
            bidfloorStatus: values.bidfloorStatus ? STATUS.ACTIVE : STATUS.SUSPEND,
            cpcBidfloor: +values.cpcBidfloor,
            cpcBidfloorStatus: values.cpcBidfloorStatus ? STATUS.ACTIVE : STATUS.SUSPEND,
          },
          {
            onSuccess: () => {
              updateParentPlacement(
                {
                  placementId: parentPlacementData.placement.id,
                  bidfloor: parentPlacementData.placement.bidfloor,
                  bidfloorCurrency: parentPlacementData.placement.bidfloor_currency,
                  bidfloorStatus: parentPlacementData.placement.bidfloor_status,
                  checkViewability: parentPlacementData.placement.check_viewability,
                  cpcBidfloor: parentPlacementData.placement.cpc_bidfloor,
                  cpcBidfloorCurrency: parentPlacementData.placement.cpc_bidfloor_currency,
                  cpcBidfloorStatus: parentPlacementData.placement.cpc_bidfloor_status,
                  ctaTextRequired: parentPlacementData.placement.ctatext_required,
                  descRequired: parentPlacementData.placement.desc_required,
                  iconImageRequired: parentPlacementData.placement.icon_image_required,
                  isSDKMediation: parentPlacementData.placement.is_sdk_mediation,
                  mainImageRequired: parentPlacementData.placement.main_image_required,
                  maxDuration: parentPlacementData.placement.maxduration,
                  mediaKey: parentPlacementData.placement.media_key,
                  minDuration: parentPlacementData.placement.minduration,
                  minViewTime: parentPlacementData.placement.minviewtime,
                  mute: parentPlacementData.placement.mute,
                  name: parentPlacementData.placement.name,
                  rewardCurrency: parentPlacementData.placement.reward_currency,
                  rewardValue: parentPlacementData.placement.reward_value,
                  sizeType: parentPlacementData.placement.size_type,
                  titleRequired: parentPlacementData.placement.title_required,
                  type: parentPlacementData.placement.type,
                  companionUse: true,
                  subNativeUse: false,
                },
                {
                  onSuccess: () => {
                    onClose();
                  },
                }
              );
            },
          }
        );
      },
    });

  const { nativeTemplateList, isLoading: isNativeTemplateListLoading } = useNativeTemplateList(
    NativeTemplateType.COMPANION,
    {
      onSuccess: (response) => {
        // nativeTemplateList 조회 성공 시
        const { data: nativeTemplateList } = response;

        const selectedNativeTemplate = nativeTemplateList.find(
          (template) => template.id === values.templateNo
        );

        if (!selectedNativeTemplate) {
          throw new Error("존재하지 않는 템플릿입니다.");
        }

        // 템플릿 옵션 목록 업데이트
        setTemplateOptions(
          nativeTemplateList.map(({ id, name }) => ({
            templateNo: id,
            name,
          }))
        );

        // 미리보기 이미지 업데이트
        setPreviewImgProps((prev) => ({ ...prev, src: selectedNativeTemplate.iurl }));
      },
    }
  );

  /** 광고 템플릿 옵션 변경 핸들러 */
  const onChangeTemplateNo = useCallback(
    (e: SelectChangeEvent<Template>) => {
      const templateNo = +e.target.value as Template;
      const selectedNativeTemplate = nativeTemplateList.find(({ id }) => id === templateNo);

      if (!selectedNativeTemplate) {
        throw new Error("존재하지 않는 템플릿입니다.");
      }

      setFieldValue("templateNo", selectedNativeTemplate.id);
      setPreviewImgProps((prev) => ({ ...prev, src: selectedNativeTemplate.iurl }));
    },
    [nativeTemplateList, setFieldValue]
  );

  const onChangeBidfloor = useCallback(
    (field: "bidfloor" | "cpcBidfloor") => (e: ChangeEvent<HTMLInputElement>) => {
      const value =
        e.target.value.replace(
          REG_EXP.bidfloor,
          e.target.value.substring(0, e.target.value.length - 1)
        ) || 0;
      if (+(value || 0) < 1000000) {
        setFieldValue(field, value);
      }
    },
    [setFieldValue]
  );

  const onBlurBidfloor = useCallback(
    (field: "bidfloor" | "cpcBidfloor") => (e: FocusEvent) => {
      setFieldValue(field, parseFloat(`${values[field] || 0}`));
      handleBlur(e);
    },
    [handleBlur, setFieldValue, values]
  );

  return (
    <Dialog
      css={addCampaignModalStyle}
      fullWidth
      maxWidth="md"
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-companion"
      aria-describedby="add companion"
    >
      <DialogTitle id="dialog-title">컴패니언 광고 플레이스먼트</DialogTitle>
      <DialogContent className="dialog-content">
        <Grid container>
          <Grid item xs={6} className="preview">
            {isNativeTemplateListLoading ? (
              <CircularProgress />
            ) : (
              <img {...previewImgProps} alt="preview" />
            )}
          </Grid>
          <Grid item xs={6}>
            <form id="add-companion-form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Select
                    label="컴패니언 네이티브 광고 탬플릿"
                    {...getFieldProps("templateNo")}
                    onChange={onChangeTemplateNo}
                  >
                    {templateOptions.map((option) => (
                      <MenuItem key={option.templateNo} value={option.templateNo}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="field"
                    label="컴패니언 네이티브 광고 플레이스먼트 명"
                    required
                    InputLabelProps={{ shrink: true }}
                    {...getFieldProps("name")}
                    error={shouldErrorShows("name", touched, errors)}
                    helperText={getHelperText("name", touched, errors)}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormControlLabel
                    className="switch"
                    control={<Switch color="primary" />}
                    label="BidFloor"
                    labelPlacement="start"
                    {...getFieldProps("bidfloorStatus")}
                  />
                </Grid>
                <Grid item xs={4}>
                  {values.bidfloorStatus && (
                    <TextField
                      className="field"
                      required
                      prefix={<span className="ssp-input-currency-prefix">USD</span>}
                      {...getFieldProps("bidfloor")}
                      onChange={onChangeBidfloor("bidfloor")}
                      onBlur={onBlurBidfloor("bidfloor")}
                      error={shouldErrorShows("bidfloor", touched, errors)}
                      helperText={getHelperText("bidfloor", touched, errors)}
                    />
                  )}
                </Grid>
                {/* {isAdmin && (
                  <>
                    <Grid item xs={8}>
                      <FormControlLabel
                        className="switch"
                        control={<Switch color="primary" />}
                        label="CPC BidFloor"
                        labelPlacement="start"
                        {...getFieldProps("cpcBidfloorStatus")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      {values.cpcBidfloorStatus && (
                        <TextField
                          className="field"
                          required
                          prefix={<span className="ssp-input-currency-prefix">USD</span>}
                          {...getFieldProps("cpcBidfloor")}
                          onChange={onChangeBidfloor("cpcBidfloor")}
                          error={shouldErrorShows("cpcBidfloor", touched, errors)}
                          helperText={getHelperText("cpcBidfloor", touched, errors)}
                        />
                      )}
                    </Grid>
                  </>
                )} */}
              </Grid>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="add-companion-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCompanionModal;
