import { css } from "@emotion/react";
import { red } from "@mui/material/colors";

const styles = css`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;

  .status {
    font-weight: 800;
    color: ${red[500]};
    font-size: 4rem;
    margin-bottom: 2rem;
  }
`;

export default styles;
