import { FormControl, InputLabel, Select as MuiSelect, SelectProps } from "@mui/material";
import { selectStyle } from "./styles";

const Select = <T,>({
  label,
  fullWidth = true,
  size = "small",
  children,
  ...props
}: SelectProps<T>) => {
  return (
    <FormControl fullWidth={fullWidth} css={selectStyle}>
      <InputLabel id="select-label" shrink>
        {label}
      </InputLabel>
      <MuiSelect
        size={size}
        labelId="select-label"
        label={label}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        {...props}
      >
        {children}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
