import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch } from "@mui/material";
import { ColDef, ColGroupDef, RowDragEvent } from "ag-grid-community";
import { MouseEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { BasicTable } from "src/components/commons";
import useGetWebThirdpartyList, {
  ThirdpartyInfo,
} from "src/hooks/apis/placements/useGetWebThirdpartyList";
import usePutWebThirdpartyList from "src/hooks/apis/placements/usePutWebThirdpartyList";
import useOpenModal from "src/hooks/useOpenModal";
import { STATUS, THIRDPARTY } from "src/types";
import MatchingInfoModal from "./MatchingInfoModal";

interface EditHeaderBiddingModalProps {
  onClose: () => void;
  open: { key: string; isOpen: boolean };
}

const EditHeaderBiddingModal = ({ onClose, open }: EditHeaderBiddingModalProps) => {
  const { mutate } = usePutWebThirdpartyList();
  const { data } = useGetWebThirdpartyList({
    placementId: open.key,
    type: THIRDPARTY.WEB_HEADER_BIDDING,
  });
  const [headerBiddingList, setHeaderBiddingList] = useState<ThirdpartyInfo[]>([]);

  // 초기 헤더비딩 데이터 세팅
  useEffect(() => {
    if (data.thirdparties) setHeaderBiddingList(data.thirdparties);
  }, [data.thirdparties]);

  const onRowDragEnd = useCallback((e: RowDragEvent) => {
    const nodeId = e.node.id;
    setHeaderBiddingList((prev) => {
      const idx = prev.findIndex((v) => `${v.id}` === nodeId);
      const removed = prev?.splice(idx, 1);
      prev.splice(e.node.rowIndex ?? 0, 0, ...removed);
      return [...prev];
    });
  }, []);

  const onSave = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      mutate({
        placementId: open.key,
        thirdparties: headerBiddingList,
        type: THIRDPARTY.WEB_HEADER_BIDDING,
      });
    },
    [headerBiddingList, mutate, open.key]
  );

  const onChangeIndividualStatus = useCallback((id: number, checked: boolean) => {
    setHeaderBiddingList((prev) => {
      const idx = prev.findIndex((v) => v.id === id);
      prev[idx]["status"] = checked ? STATUS.ACTIVE : STATUS.SUSPEND;
      return prev;
    });
  }, []);

  return (
    <Dialog
      fullWidth
      open={open.isOpen}
      onClose={onClose}
      aria-labelledby="representative-headerBidding"
      aria-describedby="edit headerBidding"
    >
      <DialogTitle id="dialog-title">헤더비딩 설정</DialogTitle>
      <DialogContent className="dialog-content">
        <BasicTable
          getRowId={(params) => params.data.match_no}
          animateRows
          rowData={headerBiddingList}
          columnDefs={getColumnDefs(open.key, onChangeIndividualStatus)}
          rowDragManaged
          onRowDragEnd={onRowDragEnd}
          onRowDragLeave={onRowDragEnd}
        />
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button onClick={onSave}>저장</Button>
      </DialogActions>
    </Dialog>
  );
};

const getColumnDefs = (
  placementId: string,
  onChangeStatus: (matchNo: number, checked: boolean) => void
): (ColDef | ColGroupDef)[] => [
  {
    headerName: "헤더비딩 명",
    field: "name",
    sortable: true,
    rowDrag: true,
  },
  {
    headerName: "매칭정보",
    cellRenderer: (params: { data: ThirdpartyInfo }) => {
      return params.data.match_key_info && params.data.match_key_info.length > 0 ? (
        <MatchingButton data={params.data} placementId={placementId} />
      ) : null;
    },
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
  },
  {
    headerName: "상태",
    field: "status",
    sortable: true,
    cellRenderer: (params: { data: ThirdpartyInfo }) => (
      <StatusSwitch data={params.data} placementId={placementId} onChange={onChangeStatus} />
    ),
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

function MatchingButton({ data, placementId }: { data: ThirdpartyInfo; placementId: string }) {
  const [openMatchingInfoModal, onShowMatchingInfoModal, onCloseMatchingInfoModal] = useOpenModal(
    data.id
  );

  const isInit = useMemo(
    () => !(data.match_key && Object.values(data.match_key).some((v) => Boolean(v))),
    [data.match_key]
  );
  const label = useMemo(() => (isInit ? "등록하기" : "변경하기"), [isInit]);

  return (
    <>
      <Button
        variant="text"
        size="small"
        color={isInit ? "inherit" : "primary"}
        onClick={onShowMatchingInfoModal}
      >
        {label}
      </Button>
      {openMatchingInfoModal.isOpen && (
        <MatchingInfoModal
          placementId={placementId}
          open={openMatchingInfoModal}
          onClose={onCloseMatchingInfoModal}
          {...data}
        />
      )}
    </>
  );
}

function StatusSwitch({
  data,
  onChange,
}: {
  data: ThirdpartyInfo;
  placementId: string;
  onChange: (id: number, checked: boolean) => void;
}) {
  const [checked, setChecked] = useState(data.status === STATUS.ACTIVE);
  const onChangeStatus = useCallback(
    (id: number) => (_event: SyntheticEvent<Element, Event>, checked: boolean) => {
      setChecked(checked);
      onChange(id, checked);
    },
    [onChange]
  );

  return <Switch onChange={onChangeStatus(data.id)} checked={checked}></Switch>;
}

export default EditHeaderBiddingModal;
