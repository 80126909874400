import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";
import { createFormData } from "src/utils/form-helper";

interface Params {
  domainListId: number;
  domain?: string;
  file?: Blob;
}

interface Response {
  code: 200 | 400 | 401;
  data?: {
    domain_ids: number[];
  };
  text: "ok" | "bad-request" | "unauthorized";
}

// 차단 도메인 등록
const usePostDomain = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const { data, ...rest } = useMutation<Response["data"], AxiosError, Params>(
    async ({ domainListId, ...params }) => {
      const response: AxiosResponse<Response> = await API.default.post(
        `/blocklist/domains/${domainListId}`,
        createFormData({ ...params }),
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      return response.data.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("URL이 추가되었습니다.");
        invalidate(["blocklist/useGetDomain", "blocklist/useGetDomains"]);
      },
    }
  );

  const result = useMemo(() => (data ? data : { domain_ids: [] }), [data]);

  return { data: result, ...rest };
};

export default usePostDomain;
