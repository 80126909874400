import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  postbackNo: number;
  campaignName: string;
  triggerRevenue: number;
  landingUrl: string;
}

interface Response {
  code: 200 | 201 | 401 | 422;
  text: "ok" | "PARTNER_CREATE_SUCCESS" | "unauthorized" | "unprocessable-entity";
}

// 쿠팡 캠페인 생성
const usePostCampaign = () => {
  const toast = useToast();
  const { handleError } = useApiError({
    409: (_error: AxiosError) => null,
  });
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ postbackNo, campaignName, triggerRevenue, landingUrl }) => {
      const response: AxiosResponse<Response> = await API.integration.post(
        `/operation/management/coupang/campaign`,
        {
          postback_no: postbackNo,
          campaign_name: campaignName,
          trigger_revenue: triggerRevenue,
          landing_url: landingUrl,
        }
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("캠페인이 생성되었습니다.");
        invalidate("coupang/useGetCampaignList");
      },
    }
  );

  return result;
};

export default usePostCampaign;
