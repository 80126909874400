import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import API from "src/utils/api";

interface Params {
  campaignKey: string;
}

interface Response {
  code: 204 | 401 | 404 | 422;
  text: "Successful Response" | "unauthorized" | "not-found" | "unprocessable-entity";
}

// 쿠팡 캠페인 삭제
const useDeleteCampaign = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const result = useMutation<Response, AxiosError, Params>(
    async ({ campaignKey }) => {
      const response: AxiosResponse<Response> = await API.integration.delete(
        `/operation/management/coupang/campaign/${campaignKey}`
      );
      return response.data;
    },
    {
      onError: handleError,
      onSuccess: () => {
        toast.success("캠페인이 삭제되었습니다.");
        invalidate("coupang/useGetCampaignList");
      },
    }
  );

  return result;
};

export default useDeleteCampaign;
