import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Status } from "src/types";
import API from "src/utils/api";

export interface BlockCategory extends SubBlockCategory {
  sub_categories: SubBlockCategory[];
}

export interface SubBlockCategory {
  code: string;
  name: string;
  status: Status;
}

interface Params {
  placementId?: string;
}

interface Response {
  code: 200 | 401;
  data?: BlockCategory[];
  text: "ok" | "unauthorized";
}

// 차단 카테고리 리스트 조회
const useGetCategories = ({ placementId }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["blocklist/useGetCategories", placementId],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/blocklist/categories`, {
        params: { placement_id: placementId },
      });
      return response.data.data;
    },
    {
      enabled: !!placementId,
      onError: handleError,
      select: (data) => {
        return data?.sort((a, b) => (a.name < b.name ? -1 : 1));
      },
    }
  );

  const result = useMemo(() => (data ? data : []), [data]);

  return { data: result, ...rest };
};

export default useGetCategories;
