import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import useInvalidateQueries from "src/hooks/useInvalidateQueries";
import useToast from "src/hooks/useToast";
import { Authority } from "src/types/authority";
import API from "src/utils/api";

interface Params {
  userId: "me" | number;
  authority: Omit<Authority, "id" | "type">;
  isCustom: boolean;
}

interface Response {
  code: 200 | 400 | 401 | 404 | 500;
  data?: {
    id: number;
  };
  text: "ok" | "bad-request" | "unauthorized" | "not-found" | "internal-server-error";
}

/*
  계정별 역할 및 권한 설정
  id, type 전달 필요 없음… (조회랑 같은 모델을 써서 표시됨 -_-;;)
  커스텀 권한 저장 시 입력한 데이터가 해당 역할의 기본 권한과 같다면 기본 권한 ID를 사용한다.
*/
const usePostUserAuthority = () => {
  const { handleError } = useApiError();
  const toast = useToast();
  const invalidate = useInvalidateQueries();
  const { data, ...rest } = useMutation<Response, AxiosError, Params>(
    async ({ userId, authority, isCustom }) => {
      const response: AxiosResponse<Response> = await API.default.post(
        `/users/${userId}/authority`,
        {
          authority: {
            app: {
              block_domain: authority.app.block_domain,
              home: authority.app.home,
              media: authority.app.media,
              placement: authority.app.placement,
            },
            home: {
              adm_dashboard: authority.home.adm_dashboard,
              dashboard: authority.home.dashboard,
              ent_dashboard: authority.home.ent_dashboard,
              syndication: authority.home.syndication,
            },
            operation: authority.operation,
            report: {
              app: authority.report.app,
              web: authority.report.web,
            },
            setting: {
              account: authority.setting.account,
              company: authority.setting.company,
              demo_test: authority.setting.demo_test,
              manager: authority.setting.manager,
              members: authority.setting.members,
            },
            settlement: {
              dashboard: authority.settlement.dashboard,
              setting: authority.settlement.setting,
              tx_history: authority.settlement.tx_history,
            },
            web: {
              block_domain: authority.web.block_domain,
              home: authority.web.home,
              media: authority.web.media,
              placement: authority.web.placement,
            },
          },
          is_custom: isCustom,
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("역할이 수정되었습니다.");
        invalidate();
      },
      onError: handleError,
    }
  );

  const result = useMemo(() => (data ? data : { id: 0 }), [data]);

  return { data: result, ...rest };
};

export default usePostUserAuthority;
