import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import SSPLogo from "src/assets/images/ssp-logo-symbol.svg";
import MainMenu from "./MainMenu";
import MobileMenu from "./MobileMenu";
import Profile from "./Profile";
import { headerStyle } from "./styles";

const Header = () => {
  return (
    <AppBar
      position="static"
      color="transparent"
      css={headerStyle}
      sx={{ marginBottom: { xs: 0, md: "52px" } }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ position: "static" }}>
          <Link to="/" className="logo">
            <Typography variant="h5" noWrap sx={{ mr: 2, display: { xs: "none", md: "flex" } }}>
              <img src={SSPLogo} alt="ssp-logo" />
            </Typography>
          </Link>
          {/* 메뉴 */}
          <MainMenu />
          {/* 모바일 메뉴 */}
          <MobileMenu />
          {/* 프로필 */}
          <Profile />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
