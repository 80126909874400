import Bidfloor from "./Bidfloor";
import Home from "./Home";

const HomeDashboard = () => {
  return (
    <>
      <Home />
      <Bidfloor />
    </>
  );
};

export default HomeDashboard;
