import {
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useRecoilState } from "recoil";
import { viewerCompany } from "src/atoms/viewerCompany";
import { TextField } from "src/components/commons";
import usePostApp from "src/hooks/apis/media/usePostApp";
import { APP_PLATFORM, APP_PLATFORM_ALIAS } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import * as yup from "yup";
import { addDirectFormStyle } from "./styles";

interface AddDirectFormProps {
  onClose: () => void;
}

const AddDirectForm = ({ onClose }: AddDirectFormProps) => {
  const { mutate: createMedia } = usePostApp();
  const [company] = useRecoilState(viewerCompany);

  const initialValues = useMemo(
    () => ({
      platformType: APP_PLATFORM.ANDROID,
      name: "",
      packageName: "",
    }),
    []
  );

  const validationSchema = yup.object({
    platformType: yup.number().required("플랫폼을 선택해주세요."),
    name: yup.string().required("앱 매체 명을 입력해주세요."),
    packageName: yup
      .string()
      .required("Package Name을 입력해주세요.")
      .matches(REG_EXP.packageName, "올바른 Package Name 값을 입력해주세요."),
  });

  const { getFieldProps, handleSubmit, setFieldValue, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      createMedia({ ...values, companyKey: company.key });
      onClose();
    },
  });

  return (
    <>
      <DialogContent css={addDirectFormStyle}>
        <form id="direct-form" onSubmit={handleSubmit}>
          <RadioGroup
            row
            className="field"
            aria-labelledby="platform-group-label"
            {...getFieldProps("platformType")}
            onChange={(e, v) => {
              e.preventDefault();
              setFieldValue("platformType", +v);
            }}
          >
            <FormControlLabel
              value={APP_PLATFORM.ANDROID}
              control={<Radio />}
              label={APP_PLATFORM_ALIAS[APP_PLATFORM.ANDROID]}
            />
            <FormControlLabel
              value={APP_PLATFORM.iOS}
              control={<Radio />}
              label={APP_PLATFORM_ALIAS[APP_PLATFORM.iOS]}
            />
          </RadioGroup>
          <TextField
            className="field"
            label="앱 매체 명"
            placeholder="등록하실 앱 매체 명을 입력하세요."
            required
            {...getFieldProps("name")}
            error={shouldErrorShows("name", touched, errors)}
            helperText={getHelperText("name", touched, errors)}
          />
          <TextField
            className="field"
            label="Package Name"
            placeholder="iOS는 Bundle ID 혹은 App Store ID"
            required
            {...getFieldProps("packageName")}
            error={shouldErrorShows("packageName", touched, errors)}
            helperText={getHelperText("packageName", touched, errors)}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
        <Button form="direct-form" type="submit">
          저장
        </Button>
      </DialogActions>
    </>
  );
};

export default AddDirectForm;
