import moment from "moment";
import { useCallback } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import DataFormat from "src/assets/formats";
import { ReportData } from "src/hooks/apis/coupang/usePostCoupangReport";

interface ReportChartProps {
  item: string;
  data: ReportData[];
}

const ReportChart = ({ item, data }: ReportChartProps) => {
  const tickFormatter = useCallback(
    (key: string) => (v: unknown) => {
      if (key === "reportDate") return moment(v as string).format("YYYY-MM-DD");
      return DataFormat[key].formatter(v as number, false, false);
    },
    []
  );

  return (
    <>
      <ResponsiveContainer className="chart-container" height={500}>
        <LineChart
          syncId="report"
          data={data}
          margin={{
            top: 48,
            left: 48,
            right: 32,
            bottom: 16,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="reportDate"
            padding={{ left: 30, right: 30 }}
            tickFormatter={tickFormatter("reportDate")}
          />
          <YAxis
            label={{
              value: `(${DataFormat[item].unit})`,
              position: {
                x: 60,
                y: -15,
              },
            }}
            tickFormatter={tickFormatter(item)}
          />
          <Tooltip
            wrapperStyle={{ fontWeight: "bold" }}
            formatter={tickFormatter(item)}
            labelFormatter={tickFormatter("reportDate")}
          />
          <Legend verticalAlign="bottom" wrapperStyle={{ fontWeight: "bold", paddingTop: 16 }} />
          <Line
            type="monotone"
            name={DataFormat[item].label}
            dataKey={item}
            stroke="#29B095"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default ReportChart;
