import { Dialog, DialogContent, DialogTitle, Tab, Tabs } from "@mui/material";
import { SyntheticEvent, useCallback, useState } from "react";
import AddDirectForm from "./AddDirectForm";
import AddSearchForm from "./AddSearchForm";
import { addMediaModalStyle } from "./styles";

interface AddMediaModalProps {
  onClose: () => void;
  open: boolean;
}

const AddMediaModal = ({ open, onClose }: AddMediaModalProps) => {
  const [tabValue, setTabValue] = useState<0 | 1>(0);

  const onClickMainMenu = useCallback((_event: SyntheticEvent, value: 0 | 1) => {
    setTabValue(value);
  }, []);

  return (
    <Dialog
      css={addMediaModalStyle}
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="representative-media"
      aria-describedby="edit media"
    >
      <DialogTitle id="dialog-title">신규 매체</DialogTitle>
      <DialogContent className="tabs">
        <Tabs value={tabValue} onChange={onClickMainMenu}>
          <Tab label="앱 검색" value={0} />
          <Tab label="직접등록" value={1} />
        </Tabs>
      </DialogContent>
      {tabValue === 0 && <AddSearchForm onClose={onClose} />}
      {tabValue === 1 && <AddDirectForm onClose={onClose} />}
    </Dialog>
  );
};

export default AddMediaModal;
