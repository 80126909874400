import { Container, Paper, Tab, Tabs, Typography } from "@mui/material";
import { SyntheticEvent, useCallback, useState } from "react";
import CoupangReport from "./CoupangReport";
import OfferwallReport from "./OfferwallReport";

const PartnerReport = () => {
  const [tabValue, setTabValue] = useState(0);

  const onClickMainMenu = useCallback((_event: SyntheticEvent, value: number) => {
    setTabValue(value);
  }, []);

  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        파트너 리포트
      </Typography>
      <Paper className="content" elevation={2}>
        <Tabs className="tabs" value={tabValue} onChange={onClickMainMenu}>
          <Tab label="오퍼월" value={0} />
          <Tab label="쿠팡" value={1} />
        </Tabs>
        {tabValue === 0 && <OfferwallReport />}
        {tabValue === 1 && <CoupangReport />}
      </Paper>
    </Container>
  );
};

export default PartnerReport;
