import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";

export type NpayReportParams = {
  since: string;
  until: string;
  media_keys?: string[];
};

export type NpayReport = {
  media_key: string;
  media_name: string;
  report_date: string; // 날짜
  points: number; // 일간 포인트 지급량
  canceled_points: number; // 일간 포인트 취소량
  clicks: number; // 포인트 지급 플레이스먼트 클릭 수
  complete_views: number; // 포인트 지급 플레이스먼트 RV 완료 수
  revenue: number; // 포인트 지급 플레이스먼트 매출액
  partner_revenue: number;
  total_clicks: number;
  total_partner_revenue: number;
  total_revenue: number;
};

type NpayReportData = {
  data: NpayReport[];
  message: string;
};

type NpayReportQueryKey = ["operation/npay/report", NpayReportParams];

type NpayReportQueryFn = QueryFunction<AxiosResponse<NpayReportData>, NpayReportQueryKey>;

type NpayReportQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<NpayReportData>,
    AxiosError,
    AxiosResponse<NpayReportData>,
    NpayReportQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: NpayReportData = {
  data: [],
  message: "OK",
};

export default function useNpayReport(params: NpayReportParams, options?: NpayReportQueryOptions) {
  const { handleError } = useApiError();

  const queryKey = useMemo<NpayReportQueryKey>(() => ["operation/npay/report", params], [params]);

  const queryFn = useCallback<NpayReportQueryFn>((context) => {
    const [, params] = context.queryKey;
    const cookie = parseCookies();

    return API.consoleV3.get("/v3/naverpay/report", {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
      params,
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
