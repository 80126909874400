import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useApiError from "src/hooks/apis/useApiError";
import { Company, Status } from "src/types";
import API from "src/utils/api";

interface Params {
  pageNo: number;
  pageSize: number;
}

interface Response {
  code: 200 | 401;
  data?: {
    companies: {
      address: string;
      biz_registration_confirmed: boolean;
      biz_registration_img_url: string;
      biz_registration_no: string;
      created_at: number;
      id: number;
      key: string;
      manager_email: string;
      manager_name: string;
      manager_phone_no: string;
      manager_uid: number;
      name: string;
      reject_message: string;
      reject_popup_closed: boolean;
      settlement_commission_rate: number;
      settlement_type: number;
      status: Status;
      sub_company_ids: number[];
      syndication_id: number;
      tw_company_id: number;
      type: Company;
      updated_at: number;
      use_adserver: boolean;
    }[];
    pages: number;
  };
  text: "ok" | "unauthorized";
}

// 관리자 요청 리스트 조회
const useGetAdminRequests = ({ pageNo, pageSize }: Params) => {
  const { handleError } = useApiError();
  const { data, ...rest } = useQuery<Response["data"], AxiosError>(
    ["companies/useGetAdminRequests", pageNo, pageSize],
    async () => {
      const response: AxiosResponse<Response> = await API.default.get(`/companies/admin-requests`, {
        params: {
          page_no: pageNo,
          page_size: pageSize,
        },
      });
      return response.data.data;
    },
    { onError: handleError }
  );

  const result = useMemo(() => (data ? data : { companies: [], pages: 0 }), [data]);

  return { data: result, ...rest };
};

export default useGetAdminRequests;
